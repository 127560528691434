import React, {} from 'react';
import { Route, } from 'react-router-dom';
import { Upload } from './upload';
import Trimmer from './trimmer2';
import { Chroma } from './masking/chroma';
import { WalkingPreview } from './walking_preview/walking_preview';

const Creator = (_props) => {

  return (
    <React.Fragment>
      <Route path="/creator/upload" component={Upload}/>
      <Route path="/creator/trimmer/:url" component={Trimmer}/>
      <Route path="/creator/trimmer" component={Trimmer}/>
      <Route path="/creator/chroma/:idx" component={Chroma}/>
      <Route path="/creator/chroma" component={Chroma}/>
      <Route path="/creator/walk" component={WalkingPreview}/>
    </React.Fragment>
  )

}

export default Creator;

