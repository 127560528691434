import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'api/agent';
import { LOGOUT } from 'constants/actionTypes';
import owl from 'images/glitch/ilmenskie/owl_1.svg';
import './header.scss';
import { UserAttributes, isAdmin } from 'models/user';
import {login_path, register_path} from 'utils/urls';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const { appName, currentUser } = useSelector((state: any) => state.common);

  const onLogout = () => {
    dispatch({ type: LOGOUT, payload: Auth.logout() });
  };

  const admin = isAdmin(currentUser);

  return (
    <div className="main-navbar">
      <nav className="navbar navbar-light navbar-expand-lg" id="header">
        <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#general-navbar" aria-expanded="false">
            <span className='navbar-toggler-icon'></span>
          </button>

          <Link to="/" className="navbar-brand">
            <img src={owl} style={{maxWidth: '1.5rem'}} className="mx-auto d-inline-block mr-4" id='owl' alt="ViWoC"></img>
            <span className="d-inline-block pl-3">{appName}</span>
          </Link>

          <div id="general-navbar" className='collapse navbar-collapse in'>
            <ul className="nav navbar-nav pull-xs-right">
              <li className="nav-item">
                <Link to="/games" className="nav-link"> Games </Link>
              </li>

              {currentUser ? (
                <>
                  {admin && (
                    <li className="nav-item">
                      <Link to="/creator/upload" className="nav-link"> Create Sprite </Link>
                    </li>
                  )}

                  {admin && (
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Assets
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link to="/avatars" className="dropdown-item"> Avatars </Link>
                        <Link to="/npcs" className="dropdown-item">Npcs</Link>
                        <Link to="/sounds" className="dropdown-item"> Sounds </Link>
                        <Link to="/pictures" className="dropdown-item"> Pictures </Link>
                        <Link to="/videos" className="dropdown-item"> Videos </Link>
                        <Link to="/texts" className="dropdown-item"> Texts </Link>
                        <Link to="/scripts" className="dropdown-item"> Scripts </Link>
                      </div>
                    </li>
                  )}

                  {admin && (
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Admin
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a href="/admin" className="dropdown-item"> Admin </a>
                        <a href="/admin/games" className="dropdown-item">Games</a>
                        <a href="/admin/spaces" className="dropdown-item">Spaces</a>
                        <a href="/admin/users" className="dropdown-item">Users</a>
                      </div>
                    </li>
                  )}

                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={onLogout}>
                      Sign out
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <a href={`#${login_path()}`} className="nav-link">
                      Sign in
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`#${register_path()}`} className="nav-link">
                      Sign up
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
