// modifiers should be a dictionary
// keys are prepositions
// values are NounTypes.
// example:  { "from" : City, "to" : City, "on" : Day }
import { NounType } from "./noun";
import * as _ from "underscore";
import { ParsedSentence } from "./parsed_sentence";
import { unquote } from "actions/verbs";
import { AvatarView } from "maps/views/avatar_view";
import { Avatar } from "models/avatar";

const WordsThatReferToSelection = ["this", "that", "it", "selection", "him", "her", "them"];

type Prepositions = {[preposition: string]: NounType};

class Verb {

  public editor = false;
  public name:any;
  public direct_object:{label:string, type:NounType};
  public modifiers: any;

  constructor(opts:any = {}) {

    this.editor = opts.editor || false;

    if (opts.name) {
      this.name = opts.name;
    }
    if (opts.direct_object) {
      this.direct_object = opts.direct_object;
    }
    if (opts.modifiers) {
      this.modifiers = opts.modifiers;
    }
  }

  public get available():boolean {
    if (this.editor) {
      return this.editor //(window.current_user?.roles || []).indexOf('admin') > -1;
    } else {
      return true;
    }
  }

  public get_name() {
    if (typeof this.name === "function") {
      return this.name();
    } else {
      return this.name;
    }
  }

  // returns a string describing what the sentence will do if executed
  public getDescription(directObject, prepositionPhrases) {
    let desc = "Hit enter to do " + this.name + " with direct object " + directObject.label;
    for (let x in prepositionPhrases) {
      desc = desc + ", " + x + " " + prepositionPhrases[x];
    }
    return desc;
  }

  public get hasDirectObject() {
    return !!this.direct_object;
  }

  private parseDirectObject(unusedWords:string[],filledMods:Prepositions, unfilledMods:Prepositions, context:any,promises:Array<Promise<Array<any>>>):ParsedSentence[]{
    if (!this.hasDirectObject || !unusedWords.length)
      // No direct object, either because there are no words left, to use, or because the verb can't take a direct object. Try parsing sentence without them.
      return [new ParsedSentence(this, this.direct_object?.type || new NounType({label:''}), filledMods)];

    // Transitive verb, can have direct object. Try to use the remaining words in that slot.
    const directObject = unquote(unusedWords.join(" "));

    if (!this.direct_object.type.match(directObject, context) && !this.direct_object.type.fuzzy)
      return [];


    // Make a sentence for each possible noun completion based on it; return them all.
    const more_promises = [];
    const parsed = this.direct_object.type.suggest(directObject, context, more_promises).map(suggestion => new ParsedSentence(this, new NounType(suggestion), filledMods));

    promises.push(... more_promises.map(p => p.then((suggestions) => suggestions.map(suggestion => new ParsedSentence(this, new NounType(suggestion), filledMods)))));
    return parsed; // word is invalid direct object.  Fail!
  }

  // returns Array<ParsedSentence>
  // @unusedWords Array<String>
  // @filledMods {[preposition: string]: suggestion:Noun}
  // @unfilledMods {[preposition: string]: Noun }
  // @promises Promise<Array<ParsedSentence>>
  // @context any
  public recursiveParse(unusedWords:string[], filledMods:Prepositions, unfilledMods:Prepositions, context:any, promises:Array<Promise<Array<any>>>):ParsedSentence[] {

    unfilledMods = {...unfilledMods}; // use a clone of unfilledMods so to not modify the original

    if (_.isEmpty(unfilledMods))
      // Done with modifiers, try to parse direct object.
      return this.parseDirectObject(unusedWords,filledMods, unfilledMods, context,promises);

    const completions = [];

    const preposition = Object.keys(unfilledMods)[0];
    const modifierNoun = unfilledMods[preposition];

    delete unfilledMods[preposition];
    // Match for the preposition at index i followed by an appropriate noun at index i+1
    unusedWords
      .map( (word,i) => preposition.startsWith(word) && (modifierNoun.match(unusedWords[i + 1], context) || modifierNoun.fuzzy) ? i : -1 )
      .filter(x => x > -1)
      .forEach((preposition_idx) => {
        // Matches found for this preposition! 
        // Add to the completions list all sentences that can be formed using these matches for this preposition.
        const wordsLeft = [...unusedWords.slice(0,preposition_idx), ...unusedWords.slice(preposition_idx+2)]
        const more_promises = [];

        modifierNoun
          .suggest(unusedWords[preposition_idx + 1], context, more_promises)
          .forEach((sugg) => {
            completions.push(...this.recursiveParse(wordsLeft, Object.assign({[preposition]: sugg}, filledMods), unfilledMods, context, promises));
          })

        promises.push(...more_promises.map((p) => p.then((suggestions) => suggestions.map(suggestion => new ParsedSentence(this, suggestion, Object.assign({[preposition]: suggestion}, filledMods))))));
      })

    // If no match was found, all we'll return is one sentence formed by leaving that preposition blank.
    // But even if a match was found, we still want to include this sentence as an additional possibility.
    return completions.concat(...this.recursiveParse(unusedWords, {...filledMods, [preposition]:null}, unfilledMods, context, promises));
  }

  // For now, we disable selectios,
  // but it would be nice to have phrases that can refer to selections, for example:
  // you have selected an NPC, then you could say "/save this to bag"
  // or if you have selected some other Avatar, you could "/invite him to videoconference"
  public getSelection() {
    return "";
  }

  // returns a list of ParsedSentences.
  //   @words Array<string> is an array of words that were space-separated.
  //   The first word, which matched this verb, has already been removed.
  //   Everything after that is either:
  //   1. my direct object
  //   2. a preposition
  //   3. a noun following a preposition.

  public getCompletions(words:string[], context:any, promises:Promise<any>[]):ParsedSentence[] {
    // Look for words that refer to selection: 
    const subbedWords = words.slice();
    let selectionUsed = false;
    const completions = [];
    const selection = this.getSelection();

    if (selection) {
      WordsThatReferToSelection.forEach((referrer)=>{
        const index = subbedWords.indexOf(referrer);
        if (index == -1)
          return;

        subbedWords.splice(index, 1, selection);
        // Notice the above line doesn't do what I want if selection is more than one word.
        selectionUsed = true;
      })
      if (selectionUsed)
        completions.push(...this.recursiveParse(subbedWords, {}, this.modifiers, context, promises));
    }

    // Also parse without that substitution, return both ways: 
    completions.push(...this.recursiveParse(words, {}, this.modifiers, context, promises));
    return completions;
  }

  //returns the words that would be implied before the noun could makes sense,
  //i.e. put these words before the noun and try again.
  public canPossiblyUseNounType(nounType) {
    if (this.direct_object?.type === nounType) {
      return this.name;
    }
    for (let prep in this.modifiers) {
      if (this.modifiers[prep] === nounType) {
        return this.name + " " + prep;
      }
    }
    return false;
  }

  // returns a float from 0 to 1 telling how good of a match the input is to this verb.
  public match(sentence:string, context:any):number {
    if (!sentence) {
      return 1;
    }
    if (this.name.indexOf(sentence) === 0) {
      // verb starts with the sentence, i.e. you may be typing this verb but haven't typed the full thing yet.
      return sentence.length / this.name.length;
    } else {
      return 0.0;
    }
  }

  public async execute(directObject, modifiers, context:AvatarView<Avatar>) {
    const arri = [];
    for (let key in modifiers) {
      arri.push(key + " " + modifiers[key]);
    }
    console.log("doing:" + this.name + " to " + directObject + " with " + arri.join(" "));
  }

  toJSON():any{
    return {name: this.name}
  }

  toString():string{
    return this.name;
  }


};

export { Verb, Prepositions};
