import React, { Component} from "react";
import Card  from 'react-bootstrap/Card';
import { SpriteProps } from "components/sprites/sprite";
import { SpriteCard } from "components/sprites/sprite_card";
import { NpcAttributes } from "models/npc";

type ClazzProps = {
  id: string,
  name: string,
  description: string,
  sprites: Array<SpriteProps>,
  npc: NpcAttributes
}

class Clazz extends Component<ClazzProps & {onClick: Function},{isMouseInside:boolean}> { 

  constructor(props:ClazzProps & {onClick: Function}) {
    super(props)
    this.state = { isMouseInside: false };
  }

  render() {
    return (<Card>
      <Card.Header>
        <strong>{this.props.name}</strong>
      </Card.Header>

      <Card.Body onClick={(e) =>this.props.onClick()}>

        {this.props.sprites.filter(sp => sp.map?.sequences).map((sprite) =>
            <SpriteCard sprite={sprite} html={{className:"card-img"}} key={sprite.id}></SpriteCard>
        )}

        <Card.Text>{this.props.description}</Card.Text>
      </Card.Body>

      </Card>)
  }
}
export {Clazz, ClazzProps}
