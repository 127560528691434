enum Events {

    ZOOM= 'niconitos:zoom', 
    INITIALIZE_MAP_VIEW= 'initialize:MapView', 
    ARRIVED= 'arrived', 
    NOTIFY= 'notify', 
    NAVIGATE='navigate',

    ASK_QUESTION='ask_question',

    CREATE_ANCHOR='createAnchor',
    CREATED_PICTURE='created:Picture',
    CREATED_TEXT='created:Text',
    CREATED_VIDEO='created:Video',
    CREATED_SOUND='created:Sound',

    NEW_MODEL='newModel',
    EDIT_MODEL='editModel',

    SHOW_PICTURE='showPicture',
    SHOW_TEXT='showText',
    SHOW_WEB_RESOURCE='showWebResource',

    EDIT_CSS='editCss',
    SET_SPACE='setSpace',
    SELECT_AVATAR='selectAvatar',
    MOVE_USER='moveUser',
    SWITCH_AVATAR='switchAvatar',
    ADD_NPC_VIEW='addNpcView',
    RENDERED_WINDOW_VIEW="rendered:WindowView",
    INITIALIZE_HUDVIEW="initialize:HudView",
    START_MULTISELECT="startMultiSelect",
    INITIALIZE_PERSONAVIEW="initialize:PersonaView",
    TILE_ENTERED='tileEntered',
    TILE_EXIT='tileExit',
    PLAY_SCRIPT='playScript',
    SCRIPT_PLAYER='scriptPlayer',
    SET_UUID='set_uuid',
    TRANSMUTE='transmute',
    VISIBILITY='visibility',
    PLAY_VIDEO='playVideo',
    PLAY_SOUND='playSound',
    PERSONA_WALK='personaWalk',
    TRAVEL_TO_ANCHOR='travelToAnchor',
    TRAVEL_TO='travelTo',
    PERSONA_SAY='personaSay',
    SET_LOCAL_AVATARVIEW="setLocalAvatarView",
    PLACE_AVATAR="placeAvatar",
    REMOVE_AVATAR='removeAvatar',
    TELETRANSPORT_CURRENT='teletransportCurrent',
    MOVE_CURRENT="moveCurrent",

    LOCAL_MOVED="localMoved",
    LOCAL_SAYS="localSays",

    START_BRANCH="startRecordingBranch",
    PLAY_BRANCH="playBranch",
    START_RECORDING="startRecording",
    SAVE_RECORDING="saveRecording",
    STOP_RECORDING="stopRecording",

    PLAY_SCENE="playScene",

    INITIALIZE_MAPVIEW="initialize:MapView",
    INITIALIZE_SOCKET_MANAGER="initialize:SocketManager",

    PLACING_SPRITE="placingSprite",
    NPC_PLAYING="npcPlaying",
    SAY="say",
}

export {Events}
