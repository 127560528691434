import {fabric} from 'fabric'
import BaseHandler from './BaseHandler';

export const colorControlA = '#1536e1';
export const colorControlB = '#1536e1';
export const controlFillStyle = '#ffffff';

const FabricControl = (fabric as any).Control;
const {controlsUtils} = fabric as any;

// TODO customize this
export function circleIcon(ctx: CanvasRenderingContext2D, left, top, __styleOverride, fabricObject) {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.beginPath()
  ctx.lineCap = 'round'
  ctx.lineWidth = 5
  ctx.arc(0, 0, 5, 0, 2 * Math.PI)
  ctx.strokeStyle = colorControlA
  ctx.stroke()
  ctx.fillStyle = controlFillStyle;
  ctx.fill()
  ctx.restore()
}

function verticalLineIcon(ctx: CanvasRenderingContext2D, left, top, _styleOverride, fabricObject) {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  roundedRect(ctx, -2, -14, 4, 18, 2)
  ctx.lineWidth = 4
  ctx.strokeStyle = colorControlA
  ctx.stroke()
  ctx.fillStyle = controlFillStyle;
  ctx.fill()
  ctx.restore()
}

function roundedRect(ctx: CanvasRenderingContext2D, x, y, w, h, radius) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.arcTo(x + w, y, x + w, y + h, radius)
  ctx.arcTo(x + w, y + h, x, y + h, radius)
  ctx.arcTo(x, y + h, x, y, radius)
  ctx.arcTo(x, y, x + w, y, radius)
  ctx.closePath()
}

export function drawHorizontalLineIcon(ctx, left, top, _styleOverride, fabricObject) {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  roundedRect(ctx, -14, -2, 18, 4, 2)
  ctx.lineWidth = 4
  ctx.strokeStyle = colorControlA;
  ctx.stroke()
  ctx.fillStyle = controlFillStyle;
  ctx.fill()
  ctx.restore()
}

const rotateSVGicon = "data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMycHgnIHdpZHRoPSczMnB4JyBmaWxsPScjMDAwMDAwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB2ZXJzaW9uPScxLjEnIHg9JzBweCcgeT0nMHB4JyB2aWV3Qm94PScwIDAgMzMuMzE3IDI4JyBlbmFibGUtYmFja2dyb3VuZD0nbmV3IDAgMCAzMy4zMTcgMjgnIHhtbDpzcGFjZT0ncHJlc2VydmUnPjxwYXRoIGQ9J00xNi42NTksMjRjLTUuMDc4LDAtOS4yMTMtMy45ODctOS40NzUtOWgyLjk3NWwtNC41LTVsLTQuNSw1aDMuMDI1YzAuMjY0LDYuNjcxLDUuNzQsMTIsMTIuNDc1LDEyYzMuMTk3LDAsNi4xMDQtMS4yMSw4LjMxNS0zLjE4NWwtMi4xMjItMi4xMjJDMjEuMTg4LDIzLjEyNywxOS4wMjcsMjQsMTYuNjU5LDI0eic+PC9wYXRoPjxwYXRoIGZpbGwtcnVsZT0nZXZlbm9kZCcgY2xpcC1ydWxlPSdldmVub2RkJyBkPSdNMjkuMTMzLDE0Yy0wLjI2NS02LjY2OS01Ljc0LTEyLTEyLjQ3NS0xMmMtMy4xOTcsMC02LjEwNCwxLjIxLTguMzE1LDMuMTg1bDIuMTIyLDIuMTIyQzEyLjEyOSw1Ljg3MywxNC4yOSw1LDE2LjY1OSw1YzUuMDc3LDAsOS4yMTMsMy45ODcsOS40NzUsOWgtMi45NzVsNC41LDVsNC41LTVIMjkuMTMzeic+PC9wYXRoPjwvc3ZnPg=="


const SelectedIconImage = new Image();
SelectedIconImage.src = rotateSVGicon;

export function drawRotateIcon(ctx, left, top, _styleOverride, _fabricObject) {
  ctx.save()
  ctx.drawImage(SelectedIconImage, left-16, top-16, 32, 32);
  ctx.restore()
  return this
}

class ControlsCustomizationHandler extends BaseHandler {

  constructor(props: any) {
    super(props)
    this.initialize()
  }

  initialize() {
    const proto:any = fabric.Object.prototype;

    proto.transparentCorners = false
    proto.cornerColor = colorControlB;
    proto.cornerStyle = 'circle'
    proto.borderColor = colorControlA;
    proto.cornerSize = 12
    proto.borderScaleFactor = 2.75
    proto.borderOpacityWhenMoving = 0
    proto.borderOpacity = 1

    proto.controls.tr = new FabricControl({
      x: 0.5,
      y: -0.5,
      actionHandler: controlsUtils.scalingEqually,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: circleIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.tl = new FabricControl({
      x: -0.5,
      y: -0.5,
      actionHandler: controlsUtils.scalingEqually,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: circleIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.bl = new FabricControl({
      x: -0.5,
      y: 0.5,
      actionHandler: controlsUtils.scalingEqually,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: circleIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.br = new FabricControl({
      x: 0.5,
      y: 0.5,
      actionHandler: controlsUtils.scalingEqually,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: circleIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.ml = new FabricControl({
      x: -0.5,
      y: 0,
      actionHandler: controlsUtils.scalingXOrSkewingY,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: verticalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.mt = new FabricControl({
      x: 0,
      y: -0.5,
      actionHandler: controlsUtils.scalingYOrSkewingX,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.mb = new FabricControl({
      x: 0,
      y: 0.5,
      actionHandler: controlsUtils.scalingYOrSkewingX,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.mr = new FabricControl({
      x: 0.5,
      y: 0,
      actionHandler: controlsUtils.scalingXOrSkewingY,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: controlsUtils.scaleOrSkewActionName,
      render: verticalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    proto.controls.mtr = new FabricControl({
      x: 0,
      y: -0.5,
      offsetY: -40,
      actionHandler: controlsUtils.rotationWithSnapping,
      cursorStyleHandler: controlsUtils.rotationStyleHandler,
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 28,
      withConnection: false
    })

    // Texbox controls
    const text_proto:any = fabric.Textbox.prototype;
    text_proto.controls.tl = proto.controls.tl
    text_proto.controls.tr = proto.controls.tr
    text_proto.controls.bl = proto.controls.bl
    text_proto.controls.br = proto.controls.br

    text_proto.controls.mt = new FabricControl({ render: () => false })
    text_proto.controls.mb = text_proto.controls.mt

    text_proto.controls.mr = new FabricControl({
      x: 0.5,
      y: 0,
      actionHandler: controlsUtils.changeWidth,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: 'resizing',
      render: verticalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    text_proto.controls.ml = new FabricControl({
      x: -0.5,
      y: 0,
      actionHandler: controlsUtils.changeWidth,
      cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
      actionName: 'resizing',
      render: verticalLineIcon,
      cornerSize: 28,
      withConnection: true
    })

    text_proto.controls.mtr = new FabricControl({
      x: 0,
      y: -0.5,
      offsetY: -40,
      actionHandler: controlsUtils.rotationWithSnapping,
      cursorStyleHandler: controlsUtils.rotationStyleHandler,
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 28,
      withConnection: false
    })

    this.controller.canvas.selectionColor = 'rgba(55, 130, 247, 0.15)'
    this.controller.canvas.selectionBorderColor = colorControlA;
    this.controller.canvas.selectionLineWidth = 1.5
    // this.controller.canvas.on('selection:created', ev => {
    //   //const objects = this.controller.canvas.getActiveObjects()
    //   const objects = ev.selected;
    //   if (objects.length < 2)
    //     return;

    //   const activeSelection = new fabric.ActiveSelection(objects, { canvas: this.controller.canvas, });

    //   activeSelection.setControlsVisibility({
    //     mt: false,
    //     mb: false,
    //     mr: false,
    //     ml: false
    //   })
    //   activeSelection.borderDashArray = [7]
    // })
    // TODO consider this auto-highlight of anything on hover
    // this.controller.canvas.on('mouse:over', event => {
    //   const target = event.target
    //   const activeObjects = this.controller.canvas.getActiveObject()
    //   if (target && activeObjects !== target && target.type !== 'Background') {
    //     const bound = target.getBoundingRect()
    //     const ctx = this.controller.canvas.getContext()
    //     ctx.strokeStyle = colorControlA
    //     ctx.lineWidth = 2.5
    //     ctx.strokeRect(bound.left, bound.top, bound.width, bound.height)
    //   }
    // })
  }
}

export default ControlsCustomizationHandler

