enum Keys {
  ESC = 27,
  UP = 38,
  DOWN = 40,
  ENTER = 13,
  TAB = 9,
  SPACE = 32,
  BACKSPACE = 8,
}

export {Keys}
