import { BaseModel } from "./base_model";

type TextAttrs = {
  id:string,
  name:string,
  description:string
  status:string,
  user_id: string, 
  created_at:string,
  updated_at: string,
  url: string,
  game_id: string,
}

class Text extends BaseModel {
  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Text');
  }

  _className() {
    return "Text";
  }

  initialize(atts, opts) {
    return super.initialize(atts,opts);
  }

  protectedAttributes() {
    return super.protectedAttributes().concat(["user_id"]);
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "texts", this.id || ''].join('/');
  }

};

Text.prototype.schema = {
  name: "Text",
  description: "Text",
  content: "Html"
};

export { Text };
