import {Howl, Howler} from 'howler'
import SiriWave from 'siriwave'
import axios from 'axios';
import { SoundProps} from "./sound";

import './styles'
import React, { Component } from 'react';
import { PaginationAttrs } from 'shared/components/pagination';

class SoundIndex extends Component<{history?:any, match?:any,xs?:number,md?:number, onClick?:Function, }, {pagination?:PaginationAttrs, sounds: Array<any>, query:string,loading:boolean,message:string}> {

  audioControl:any=React.createRef();

  constructor(props){
    super(props)
    this.state = {sounds: [], query: null, loading: true, message: '', pagination:null};
  }

  componentDidMount() {
    let url;
    if(this.props.match.params.p)
      url = `/sounds.json?page=${this.props.match.params.p}`
    else
      url = `/sounds.json`


    axios.request<Array<any>>({url}).then(({data, headers}) =>{
      let pagination;
      if(headers['x-pagination']){
        pagination = JSON.parse(headers['x-pagination'])
      }

      this.setState({ sounds: data, pagination })
    });
  }

  render(){
    return (<>
      <div>
        {!!this.state.sounds.length ?
          <AudioControl ref={this.audioControl} playlist={this.state.sounds}></AudioControl>
          :
          <h1 className="text-center muted mt-4">No sounds</h1>
        }
      </div>

      <div className="row">
        {this.state.sounds.map((snd) =>
        <div className="col-12 col-sm-6 col-md-2 mt-2">
          <a href="javascript:void(0)" onClick={(e) => this.audioControl.current.skipTo(this.state.sounds.indexOf(snd))} >{snd.name}</a>
        </div>
         )}
      </div>
    </>)
  }

}

class SoundSearch extends Component<any, {sounds: Array<any>, query:string,loading:boolean,message:string}> {

  audioControl:any=React.createRef();

  constructor(props={}){
    super(props)
    this.state = {sounds: [], query: null, loading: true, message: ''};
  }

  componentDidMount() {
    axios.request<Array<any>>({url: '/sounds.json'}).then(({data}) =>
      this.setState({ sounds: data })
    );
  }

  render(){
    return (
      <>
      <div>
        {this.state.sounds.length && <AudioControl ref={this.audioControl} playlist={this.state.sounds}></AudioControl>}
      </div>

      <div className="row">
        {this.state.sounds.map((snd) =>
        <div className="col-12 col-sm-6 col-md-2 mt-2">
          <a href="javascript:void(0)" onClick={(e) => this.audioControl.current.skipTo(this.state.sounds.indexOf(snd))} >{snd.name}</a>
        </div>
         )}
  </div>
  </>
    )
  }

}


class AudioControl extends Component<{playlist: Array<any>}> {

  thePlayer:any= React.createRef();
  track:any= React.createRef();
  waveform:any= React.createRef();
  timer:any= React.createRef();
  duration :any= React.createRef();
  playBtn:any= React.createRef();     
  pauseBtn:any= React.createRef();
  progress:any= React.createRef();
  bar:any= React.createRef();
  loading:any= React.createRef();
  playlistElem:any= React.createRef();
  volumeEl:any= React.createRef();    
  barEmpty:any= React.createRef();
  barFull:any= React.createRef();
  sliderBtn:any= React.createRef();
  index:number=0;
  playlist:Array<SoundProps & {howl: any}>;
  sliderDown:boolean;
  wave: any;

  constructor(props){
    super(props);

    this.playlist = this.props.playlist;
  }

  componentDidMount(){

    const move = (event) => {
      if (!this.sliderDown)
        return
      var x = event.clientX || event.touches[0].clientX;
      var startX = this.thePlayer.current.clientWidth * 0.05;
      var layerX = x - startX;
      var per = Math.min(1, Math.max(0, layerX / this.barEmpty.current.scrollWidth));
      this.volume(per);
    };

    this.volumeEl.current.addEventListener('mousemove', move);
    this.volumeEl.current.addEventListener('touchmove', move);

    // Setup the "waveform" animation.
    this.wave = new SiriWave({
      container: this.waveform.current,
      width: this.thePlayer.current.clientWidth,
      height: this.thePlayer.current.clientHeight,
      cover: false,
      speed: 0.03,
      amplitude: 0.7,
      frequency: 2
    });

    this.wave.start();

    // Update the height of the wave animation.
    // These are basically some hacks to get SiriWave.js to do what we want.
    // @ts-ignore
    const resize = () => {
      var height = this.thePlayer.current.clientHeight;
      var width = this.thePlayer.current.clientWidth;
      this.wave.height = height;
      this.wave.width = width;
      this.wave.canvas.height = height*2;
      this.wave.canvas.width = width;

      // Update the position of the slider.
      if (this.sound.howl) {
        var vol = this.sound.howl.volume();
        var barWidth = (vol * 0.9);
        this.sliderBtn.current.style.left = (this.thePlayer.current.clientWidth * barWidth + this.thePlayer.current.clientWidth * 0.05 - 25) + 'px';
      }
    }
  }

  play(index:number) {
    this.index = index;

    if (!this.sound.howl) {
      this.sound.howl = new Howl({
        src: [this.sound.sound_url],
        html5: true,
        onplay: () => {
          this.duration.current.innerHTML = this.formatTime(Math.round(this.sound.howl.duration()));
          requestAnimationFrame(this.step.bind(this));
          this.wave.container.style.display = 'block';
          this.bar.current.style.display = 'none';
          this.pauseBtn.current.style.display = 'block';
        },
        onload: () => {
          this.wave.container.style.display = 'block';
          this.bar.current.style.display = 'none';
          this.loading.current.style.display = 'none';
        },
        onend: () => {
          this.wave.container.style.display = 'none';
          this.bar.current.style.display = 'block';
          this.skip('next');
        },
        onpause: () => {
          this.wave.container.style.display = 'none';
          this.bar.current.style.display = 'block';
        },
        onstop: () => {
          this.wave.container.style.display = 'none';
          this.bar.current.style.display = 'block';
        },
        onseek: () => {
          requestAnimationFrame(this.step.bind(this));
        }
      });
    }

    this.sound.howl.play();
    this.track.current.innerHTML = (index + 1) + '. ' + this.sound.name;

    if (this.sound.howl.state() === 'loaded') {
      this.playBtn.current.style.display = 'none';
      this.pauseBtn.current.style.display = 'block';
    } else {
      this.loading.current.style.display = 'block';
      this.playBtn.current.style.display = 'none';
      this.pauseBtn.current.style.display = 'none';
    }

  }

  pause() {
    this.sound.howl.pause();
    this.playBtn.current.style.display = 'block';
    this.pauseBtn.current.style.display = 'none';
  }

  skip(direction) {
    var index = (this.index + (direction == 'prev' ? -1 : +1) + this.playlist.length) % this.playlist.length;
    this.skipTo(index);
  }

  skipTo(index) {

    if (this.sound.howl) {
      this.sound.howl.stop();
    }

    this.progress.current.style.width = '0%';
    this.play(index);
  }

  volume(val) {
    Howler.volume(val);

    var barWidth = (val * 90) / 100;
    this.barFull.current.style.width = (barWidth * 100) + '%';
    this.sliderBtn.current.style.left = (this.thePlayer.current.clientWidth * barWidth + this.thePlayer.current.clientWidth * 0.05 - 25) + 'px';
  }

  seek(per) {
    if (this.sound.howl.playing()) {
      this.sound.howl.seek(this.sound.howl.duration() * per);
    }
  }

  get sound() {
    return this.playlist[this.index];
  }

  step() {
    var seek = this.sound.howl.seek() || 0;
    this.timer.current.innerHTML = this.formatTime(Math.round(seek));
    this.progress.current.style.width = (((seek / this.sound.howl.duration()) * 100) || 0) + '%';

    // If the sound is still playing, continue stepping.
    if (this.sound.howl.playing()) {
      requestAnimationFrame(this.step.bind(this));
    }
  }

  togglePlaylist() {
    var display = (this.playlistElem.current.style.display === 'block') ? 'none' : 'block';

    setTimeout(() => {
      this.playlistElem.current.style.display = display;
    }, (display === 'block') ? 0 : 500);

    this.playlistElem.current.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  toggleVolume() {
    var display = (this.volumeEl.current.style.display === 'block') ? 'none' : 'block';

    setTimeout(() => {
      this.volumeEl.current.style.display = display;
    }, (display === 'block') ? 0 : 500);

    this.volumeEl.current.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  formatTime(secs) {
    var minutes = Math.floor(secs / 60) || 0;
    var seconds = (secs - minutes * 60) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  render(){
    return (
      <div className="the-player" ref={this.thePlayer}>
        <div className="title">
          <span className="track" ref={this.track}></span>
          <div className="timer" ref={this.timer}>0:00</div>
          <div className="duration" ref={this.duration}>0:00</div>
        </div>

        <div className="controlsOuter">
          <div className="controlsInner">
            <div className="loading" ref={this.loading}></div>
            <div className="boton playBtn" ref={this.playBtn} onClick={(e) => this.play(this.index)}></div>
            <div className="boton pauseBtn" ref={this.pauseBtn} onClick={(e) => this.pause()}></div>
            <div className="boton prevBtn" onClick={(e) => this.skip('prev')}></div>
            <div className="boton nextBtn" onClick={(e) => this.skip('next')}></div>
          </div>
          <div className="boton playlistBtn" onClick={(e) => this.togglePlaylist()}></div>

          <div className="boton volumeBtn" onClick={(e) => this.toggleVolume()} ></div>
        </div>

        <div className="seeker" onClick={(event) => this.seek(event.clientX / this.thePlayer.current.clientWidth)}></div>
        <div className="waveform" ref={this.waveform}></div>

        <div className="barId" ref={this.bar}></div>
        <div className="progress" ref={this.progress}></div>

        <div className="playlist" ref={this.playlistElem}>
          <div className="list">
            {this.playlist.map((song) => (<div className="list-song" key={song.id} onClick={(ev)=> this.skipTo(this.playlist.indexOf(song))}>{song.name}</div>))}
          </div>
        </div>

        <div className="volume fadeout" ref={this.volumeEl} onClick={(e) => this.toggleVolume()} >

          <div className="barFull bar" ref={this.barFull}></div>
          <div className="barEmpty bar" ref={this.barEmpty} onClick={(event) => this.volume((event as any).layerX / parseFloat(this.barEmpty.current.scrollWidth))}></div>

          <div className="sliderBtn" ref={this.sliderBtn} onMouseDown={(e) => this.sliderDown = true}  onMouseUp={(e) => this.sliderDown = false}></div>

        </div>
      </div>
    )
  }
}

export { AudioControl, SoundIndex, SoundSearch }
