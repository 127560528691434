import {InteractionModes} from "../utils";
import CanvasController from "./CanvasController";
import {store} from 'redux/store';

export const isDrawingMode = (mode?) => {
  mode = mode || store.getState().editor.interactionMode;
  return [InteractionModes.LINK, InteractionModes.ARROW, InteractionModes.LINE, InteractionModes.POLYGON].includes(mode)
};

class BaseHandler {

  controller: CanvasController;
  public store:any;
  public unsubscribe: any;
  public editor_state: any;

  constructor(controller: CanvasController){
    this.controller = controller;
    this.store = store;

    this.unsubscribe = this.store.subscribe(() => {
      const newState = this.store.getState();
      this.handleStateChange(newState);
    });

  }

  public handleStateChange(newState){
    //no-op
  }

  public destroy(){
    this.unsubscribe();
  }

  /**
   * Whether is drawing mode
   * @returns
   */
  public isDrawingMode = () => {
    return isDrawingMode();
  };


}

export default BaseHandler;
