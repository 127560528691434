import React, { useEffect } from 'react'
import './panels.less';
import PanelItem from './item';
//import TextPanel from './text_panel';
//import ImagePanel from './image_panel';
import BackgroundPanel from './background_panel';
//import FontFamilyPanel from './font_family_panel';
import LayersPanel from './layers_panel';
//import ShadowPanel from './shadow_panel';
//import FiltersPanel from './filters_panel';
import {PanelKinds, PanelTitles, useDesignContext} from 'contexts/design_context';
import {DesignCanvas} from 'components/canvas';
import ElementsPanel from './elements_panel';
import TemplatesPanel from './templates_panel';
import LinesPanel from './lines_panel';
import GenerativeAiPanel from './generative_ai_panel';
import {Box} from '@material-ui/core';
import _NpcsPanel from './npcs_panel';
import NpcsPanel from './npcs_panel';
import AiLayersPanel from './ai_layers_panel';
import {useDispatch, useSelector} from 'react-redux';
//import AnchorsPanel from './anchors_panel';
import StickerPickerPanel from './sticker_picker_panel';
import {setPinPanel} from 'redux/reducers/editor';
import {T} from 'components/utils/t';

//function OtherPanel({onChange,canvasRef, selectedObject}){
//  return <></>
//}

function Panels(props?:any) {

  const {selectedObject} = useDesignContext();
  const features = useSelector((state:any) => state.common?.features);

  if(!features)
    return null;

  return <div className="rde-panel-container">
    <PanelItem id={PanelKinds.GENERATIVE_AI}   />
    <PanelItem id={PanelKinds.STICKERS}   />
    {/*<PanelItem id={PanelKinds.TEMPLATES}   />*/}
    <PanelItem id={PanelKinds.ELEMENTS}   />
    <PanelItem id={PanelKinds.BACKGROUND} />
    <PanelItem id={PanelKinds.LAYERS}     />
    {/*<PanelItem id={PanelKinds.LINES}     />*/}
    {/*<PanelItem id={PanelKinds.ANCHORS}     />*/}
    {features.npc_panel_show && <PanelItem id={PanelKinds.NPCS}     />}
    {features.ai_layers_panel_show && <PanelItem id={PanelKinds.AI_LAYERS}     />}
    {selectedObject?.type == 'image' && <PanelItem id={PanelKinds.FILTERS}     />}
  </div>
}

function EditorPanel({onChange}:{canvasRef:{current:DesignCanvas}, onChange:(a,b,c)=>void}){

  const selectedPanel = useSelector((state:any)=>state.editor.selectedPanel);
  const pinned = useSelector((state:any)=>state.editor.pinPanel);
  const dispatch = useDispatch();

  const features = useSelector((state:any) => state.common?.features);

  const display = (panel) => {
    return (panel == selectedPanel ? 'block' : 'none');
  }

  return <div className={`rde-panel-main ${selectedPanel ? 'd-block' : 'd-none'}`}>
    <h4 className="mb-4 pt-2 px-2" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <span><T k={`panels.titles.${PanelTitles[selectedPanel]}`}>{PanelTitles[selectedPanel]}</T></span>
      <span style={{cursor: 'pointer'}} onClick={() => dispatch(setPinPanel(!pinned))}>
        {pinned ? <i className='fa-thumbtack fas'/> : <i className='fa-thumbtack-slash far'/>}
      </span>
    </h4>

    {<Box style={{ display: display(PanelKinds.STICKERS)}}>      <StickerPickerPanel onHitClick={onChange} /> </Box>}
    {<Box style={{ display: display(PanelKinds.GENERATIVE_AI)}}> <GenerativeAiPanel onHitClick={onChange} /> </Box>}
  {/*<Box style={{ display: display(PanelKinds.TEMPLATES)}}>     <TemplatesPanel onHitClick={onChange}    /> </Box>*/}
    {<Box style={{ display: display(PanelKinds.ELEMENTS)}}>      <ElementsPanel                           /> </Box>}
    {<Box style={{ display: display(PanelKinds.LAYERS)}}>        <LayersPanel onChange={onChange}         /> </Box>}
  {/*<Box style={{ display: display(PanelKinds.LINES)}}>         <LinesPanel onChange={onChange}          /> </Box>*/}
    {<Box style={{ display: display(PanelKinds.BACKGROUND)}}>    <BackgroundPanel onChange={onChange}     /> </Box>}
    {/*<Box style={{ display: display(PanelKinds.ANCHORS)}}>      <AnchorsPanel onChange={onChange}     /> </Box>*/}
    {features.npc_panel_show && <Box style={{ display: display(PanelKinds.NPCS)}}>          <NpcsPanel                           /> </Box>}
    {features.ai_layers_panel_show && <Box style={{ display: display(PanelKinds.AI_LAYERS)}}>    <AiLayersPanel                           /> </Box>}
    {/*(selectedPanel == PanelKinds.TEXT) && <TextPanel onChange={onChange}/>*/}
    {/*(selectedPanel == PanelKinds.UPLOADS) && <ImagePanel onChange={onChange}/>*/}
    {/*(selectedPanel == PanelKinds.TYPOGRAPHY) && <FontFamilyPanel onChange={onChange}/>*/}
    {/*(selectedPanel == PanelKinds.FILTERS) && <FiltersPanel onChange={onChange}/>*/}
    {/*(selectedPanel == PanelKinds.SHADOW) && <ShadowPanel onChange={onChange}/>*/}
    {/*(selectedPanel == PanelKinds.OTHER) && <OtherPanel onChange={onChange} canvasRef={canvasRef.current} selectedObject={selectedObject}/>*/}
  </div>
}

export default Panels
export {EditorPanel};
