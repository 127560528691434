import * as _ from "underscore";
import * as Backbone from "backbone";

const HtmlMaker = require('../../../../vendor/assets/javascripts/html-maker');
const SelfClosingTags = 'area base br col command embed hr img input keygen link meta param source track wbr'.split(/\s+/)
var delegateEventSplitter = /^(\S+)\s*(.*)$/;

var viewOptions = ['model', 'collection', 'el', 'id', 'attributes', 'className', 'tagName', 'events'];

function dasherize (str) {
  if (!str) {
    return '';
  }
  str = str[0].toLowerCase() + str.slice(1);
  return str.replace(/([A-Z])|(_)/g, function(m, letter) {
    if (letter) {
      return "-" + letter.toLowerCase();
    } else {
      return "-";
    }
  });
};


abstract class BaseView<T extends Backbone.Model> {
  cid: string;
  $el: any;
  el: any;
  id: any;
  model: T;

  a$(...args: any[]): void { this.tag("a",...args);};
  abbr$(...args: any[]): void { this.tag("abbr",...args);};
  address$(...args: any[]): void { this.tag("address",...args);};
  article$(...args: any[]): void { this.tag("article",...args);};
  aside$(...args: any[]): void { this.tag("aside",...args);};
  audio$(...args: any[]): void { this.tag("audio",...args);};
  b$(...args: any[]): void { this.tag("b",...args);};
  bdi$(...args: any[]): void { this.tag("bdi",...args);};
  bdo$(...args: any[]): void { this.tag("bdo",...args);};
  blockquote$(...args: any[]): void { this.tag("blockquote",...args);};
  body$(...args: any[]): void { this.tag("body",...args);};
  button$(...args: any[]): void { this.tag("button",...args);};
  canvas$(...args: any[]): void { this.tag("canvas",...args);};
  caption$(...args: any[]): void { this.tag("caption",...args);};
  cite$(...args: any[]): void { this.tag("cite",...args);};
  code$(...args: any[]): void { this.tag("code",...args);};
  colgroup$(...args: any[]): void { this.tag("colgroup",...args);};
  datalist$(...args: any[]): void { this.tag("datalist",...args);};
  dd$(...args: any[]): void { this.tag("dd",...args);};
  del$(...args: any[]): void { this.tag("del",...args);};
  details$(...args: any[]): void { this.tag("details",...args);};
  dfn$(...args: any[]): void { this.tag("dfn",...args);};
  dialog$(...args: any[]): void { this.tag("dialog",...args);};
  div$(...args: any[]): void { this.tag("div",...args);};
  dl$(...args: any[]): void { this.tag("dl",...args);};
  dt$(...args: any[]): void { this.tag("dt",...args);};
  em$(...args: any[]): void { this.tag("em",...args);};
  fieldset$(...args: any[]): void { this.tag("fieldset",...args);};
  figcaption$(...args: any[]): void { this.tag("figcaption",...args);};
  figure$(...args: any[]): void { this.tag("figure",...args);};
  footer$(...args: any[]): void { this.tag("footer",...args);};
  form$(...args: any[]): void { this.tag("form",...args);};
  h1$(...args: any[]): void { this.tag("h1",...args);};
  h2$(...args: any[]): void { this.tag("h2",...args);};
  h3$(...args: any[]): void { this.tag("h3",...args);};
  h4$(...args: any[]): void { this.tag("h4",...args);};
  h5$(...args: any[]): void { this.tag("h5",...args);};
  h6$(...args: any[]): void { this.tag("h6",...args);};
  head$(...args: any[]): void { this.tag("head",...args);};
  header$(...args: any[]): void { this.tag("header",...args);};
  html$(...args: any[]): void { this.tag("html",...args);};
  i$(...args: any[]): void { this.tag("html",...args);};
  iframe$(...args: any[]): void { this.tag("iframe",...args);};
  ins$(...args: any[]): void { this.tag("ins",...args);};
  kbd$(...args: any[]): void { this.tag("kbd",...args);};
  label$(...args: any[]): void { this.tag("label",...args);};
  legend$(...args: any[]): void { this.tag("legend",...args);};
  li$(...args: any[]): void { this.tag("li",...args);};
  main$(...args: any[]): void { this.tag("main",...args);};
  map$(...args: any[]): void { this.tag("map",...args);};
  mark$(...args: any[]): void { this.tag("mark",...args);};
  menu$(...args: any[]): void { this.tag("menu",...args);};
  meter$(...args: any[]): void { this.tag("meter",...args);};
  nav$(...args: any[]): void { this.tag("nav",...args);};
  noscript$(...args: any[]): void { this.tag("noscript",...args);};
  object$(...args: any[]): void { this.tag("object",...args);};
  ol$(...args: any[]): void { this.tag("ol",...args);};
  optgroup$(...args: any[]): void { this.tag("optgroup",...args);};
  option$(...args: any[]): void { this.tag("option",...args);};
  output$(...args: any[]): void { this.tag("output",...args);};
  p$(...args: any[]): void { this.tag("p",...args);};
  pre$(...args: any[]): void { this.tag("pre",...args);};
  progress$(...args: any[]): void { this.tag("progress",...args);};
  q$(...args: any[]): void { this.tag("q",...args);};
  rp$(...args: any[]): void { this.tag("rp",...args);};
  rt$(...args: any[]): void { this.tag("rt",...args);};
  ruby$(...args: any[]): void { this.tag("ruby",...args);};
  s$(...args: any[]): void { this.tag("s",...args);};
  samp$(...args: any[]): void { this.tag("samp",...args);};
  script$(...args: any[]): void { this.tag("script",...args);};
  section$(...args: any[]): void { this.tag("section",...args);};
  select$(...args: any[]): void { this.tag("select",...args);};
  small$(...args: any[]): void { this.tag("small",...args);};
  span$(...args: any[]): void { this.tag("span",...args);};
  strong$(...args: any[]): void { this.tag("strong",...args);};
  style$(...args: any[]): void { this.tag("style",...args);};
  sub$(...args: any[]): void { this.tag("sub",...args);};
  summary$(...args: any[]): void { this.tag("summary",...args);};
  sup$(...args: any[]): void { this.tag("sup",...args);};
  table$(...args: any[]): void { this.tag("table",...args);};
  tbody$(...args: any[]): void { this.tag("tbody",...args);};
  td$(...args: any[]): void { this.tag("td",...args);};
  textarea$(...args: any[]): void { this.tag("textarea",...args);};
  tfoot$(...args: any[]): void { this.tag("tfoot",...args);};
  th$(...args: any[]): void { this.tag("th",...args);};
  thead$(...args: any[]): void { this.tag("thead",...args);};
  time$(...args: any[]): void { this.tag("time",...args);};
  title$(...args: any[]): void { this.tag("title",...args);};
  tr$(...args: any[]): void { this.tag("tr",...args);};
  u$(...args: any[]): void { this.tag("u",...args);};
  ul$(...args: any[]): void { this.tag("ul",...args);};
  var$(...args: any[]): void { this.tag("var",...args);};
  video$(...args: any[]): void { this.tag("var",...args);};
  area$(...args: any[]): void { this.tag("area",...args);};
  base$(...args: any[]): void { this.tag("base",...args);};
  br$(...args: any[]): void { this.tag("br",...args);};
  col$(...args: any[]): void { this.tag("col",...args);};
  command$(...args: any[]): void { this.tag("command",...args);};
  embed$(...args: any[]): void { this.tag("embed",...args);};
  hr$(...args: any[]): void { this.tag("hr",...args);};
  img$(...args: any[]): void { this.tag("img",...args);};
  input$(...args: any[]): void { this.tag("input",...args);};
  keygen$(...args: any[]): void { this.tag("keygen",...args);};
  link$(...args: any[]): void { this.tag("link",...args);};
  meta$(...args: any[]): void { this.tag("meta",...args);};
  param$(...args: any[]): void { this.tag("param",...args);};
  source$(...args: any[]): void { this.tag("source",...args);};
  track$(...args: any[]): void { this.tag("track",...args);};
  wbr$(...args: any[]): void { this.tag("wbr",...args);};

  render$(view, ...args) {
    return view.call(this, ...args);
  }

  raw$(str: string) { return this.raw(str)}

  dispatcher: any;
  subviews: any;
  menu: any;
  pagination: any;
  document: any[];

  static define_promise(name) {
    return (this as any).define(name, {
      set: function(val) {
        this[`_${name}`] = val;
        return this.trigger(`${name}:change`);
      },
      get: function() {
        return this[`_${name}`];
      }
    });
  }

  constructor(options?) {

    if (!options) {
      return;
    }
    this.model=options?.model;
    this.cid = _.uniqueId('view');

    var viewOptions = ['model', 'collection', 'el', 'id', 'attributes', 'className', 'tagName', 'events'];
    _.extend(this, _.pick(options, viewOptions));
    this._ensureElement();
    this.dispatcher = options.dispatcher;
    this.dispatchCreated('BaseView');
    this.on('addedToDom', () => {
      var id, ref, view;
      ref = this.subviews;
      for (id in ref) {
        view = ref[id];
        view.trigger('addedToDom');
      }
    });
    this.$el.addClass(this.classNames())
  }

  detach() {}

  initialize(settings) {
    ({dispatcher: this.dispatcher} = settings);

    //this.$el.addClass(this.classNames())

    //super.initialize(settings);
  }

  // The default `tagName` of a View's element is `"div"`.
  tagName= 'div'

  // jQuery delegate for element lookup, scoped to DOM elements within the
  // current view. This should be preferred to global lookups where possible.
  $(selector) {
    return this.$el.find(selector);
  }

  // Remove this view's element from the document and all event listeners
  // attached to it. Exposed for subclasses using an alternative DOM
  // manipulation API.
  _removeElement() {
    this.$el.remove();
  }

  // Change the view's element (`this.el` property) and re-delegate the
  // view's events on the new element.
  setElement(element) {
    this.undelegateEvents();
    this._setElement(element);
    this.delegateEvents();
    return this;
  }

  // Creates the `this.el` and `this.$el` references for this view using the
  // given `el`. `el` can be a CSS selector or an HTML string, a jQuery
  // context or an element. Subclasses can override this to utilize an
  // alternative DOM manipulation API and are only required to set the
  // `this.el` property.
  _setElement(el) {
    this.$el = el instanceof Backbone.$ ? el : Backbone.$(el);
    this.el = this.$el[0];
  }

  // Set callbacks, where `this.events` is a hash of
  //
  // *{"event selector": "callback"}*
  //
  //     {
  //       'mousedown .title':  'edit',
  //       'click .button':     'save',
  //       'click .open':       function(e) { ... }
  //     }
  //
  // pairs. Callbacks will be bound to the view, with `this` set properly.
  // Uses event delegation for efficiency.
  // Omitting the selector binds the event to `this.el`.
  delegateEvents(events=null) {
    events || (events = _.result(this, 'events'));
    if (!events) return this;
    this.undelegateEvents();
    for (var key in events) {
      var method = events[key];
      if (!_.isFunction(method)) method = this[method];
      if (!method) continue;
      var match = key.match(delegateEventSplitter);
      this.delegate(match[1], match[2], method.bind(this));
    }
    return this;
  }

  // Add a single event listener to the view's element (or a child element
  // using `selector`). This only works for delegate-able events: not `focus`,
  // `blur`, and not `change`, `submit`, and `reset` in Internet Explorer.
  delegate(eventName, selector, listener) {
    this.$el.on(eventName + '.delegateEvents' + this.cid, selector, listener);
    return this;
  }

  // Clears all callbacks previously bound to the view by `delegateEvents`.
  // You usually don't need to use this, but may wish to if you have multiple
  // Backbone views attached to the same DOM element.
  undelegateEvents() {
    if (this.$el) this.$el.off('.delegateEvents' + this.cid);
    return this;
  }

  // A finer-grained `undelegateEvents` for removing a single delegated event.
  // `selector` and `listener` are both optional.
  undelegate(eventName, selector, listener) {
    this.$el.off(eventName + '.delegateEvents' + this.cid, selector, listener);
    return this;
  }

  // Produces a DOM element to be assigned to your view. Exposed for
  // subclasses using an alternative DOM manipulation API.
  _createElement(tagName) {
    return document.createElement(tagName);
  }

  // Ensure that the View has a DOM element to render into.
  // If `this.el` is a string, pass it through `$()`, take the first
  // matching element, and re-assign it to `el`. Otherwise, create
  // an element from the `id`, `className` and `tagName` properties.
  _ensureElement() {
    if (!this.el) {
      var attrs = _.extend({}, _.result(this, 'attributes'));
      if (this.id) attrs.id = _.result(this, 'id');
      if (this.className) attrs['class'] = _.result(this, 'className');
      this.setElement(this._createElement(_.result(this, 'tagName')));
      this._setAttributes(attrs);
    } else {
      this.setElement(_.result(this, 'el'));
    }
  }

  // Set attributes from a hash on this view's element.  Exposed for
  // subclasses using an alternative DOM manipulation API.
  _setAttributes(attributes) {
    this.$el.attr(attributes);
  }

  template() {
    return '';
  }

  render() {
    this.$el.html(this.template());
    if (!_.isEmpty(this.subviews)) {
      this.render_subviews();
    }
    return this;
  }

  setMenu(menu) {
    if (this.menu) {
      this.menu.remove();
    }
    this.menu = menu;
    this.$el.append(this.menu.render().el);
    this.$el.find(".context-menu").hide();
  }

  dispatchCreated(clazz) {
    if (this.dispatcher) {
      this.dispatcher.trigger("initialize:" + clazz, { target: this});
    }
  }

  _className() {
    throw 'Not Supported'; //@constructor.toString().match(/function +([^\( ]*)\(/)[1]
  }

  remove() {
    this._removeElement();
    this.stopListening();
    this.trigger("remove");
    return this;
  }

  classNames() { return 'BaseView' }

  className = 'BaseView'

  t(key, opts:any = {}, opts2 = {}) {
    if (key[0] === '.') {
      key = `js.${_.result(this, '_className')}${key}`;
    }
    if (_.isString(opts)) {
      opts = _.extend(opts2, {
        _: opts
      });
    }
    if (!window.polyglot.phrases[key]) {
      window.polyglot.missing = window.polyglot.missing || {};
      window.polyglot.missing[key] = opts._;
    }
    if (opts.nohtml) {
      return window.polyglot.t(key, opts);
    } else {
      return `<span class='inibon' data-inibon-key='${window.i18n_locale}.${key}'>${window.polyglot.t(key, opts)}</span>`;
    }
  }

  remove_subview(id) {
    return delete this.subviews[id];
  }

  remove_subviews() {
    const ref = this.subviews;
    for (let id in ref) {
      const view = ref[id];
      view.remove();
      delete this.subviews[id]
    }
  }

  render_subviews() {
    const ref = this.subviews;
    for (let id in ref) {
      if (this.$(`[data-subview=${id}]`).length) {
        const view = ref[id];
        view.setElement(this.$(`[data-subview=${id}]`)).render();
      }
    }
  }

  render_subview(...args) {
    var id, options;
    options = this.extractOptions(args);
    id = options.attributes.id;
    options.attributes.data = options.attributes.data || {};
    options.attributes.data.subview = id;
    this.subviews = this.subviews || {};
    if (!this.subviews[id]) {
      this.subviews[id] = options.content();
      this.subviews[id].on('removed', (() => {
        delete this.subviews[id];
        return this.render();
      }));
    }
    return this.div$(_.omit(options.attributes, 'content'));
  }

  paginate$(pagination) {
    return this.nav$(() => {
      return this.ul$('.pagination', () => {
        var i, len, page, ref;
        if (this.pagination.previous_page()) {
          this.li$('.disabled', () => {
            return this.a$('.disabled', {
              href: 'javascript:void(0)'
            }, () => {
              return this.raw$('&laquo;');
            });
          });
        } else {
          this.li$(() => {
            return this.a$('.paginate', {
              href: 'javascript:void(0)',
              data: {
                page: this.pagination.previous_page()
              }
            }, () => {
              return this.raw$('&laquo;');
            });
          });
        }
        ref = this.pagination.windowed_page_numbers(2, 2);
        for (i = 0, len = ref.length; i < len; i++) {
          page = ref[i];
          if (_.isNumber(page)) {
            this.li$(`.${page === this.pagination.current_page ? 'active' : 'notactive'}`, () => {
              return this.a$(".paginate", {
                href: 'javascript:void(0)',
                data: {
                  page: page
                }
              }, `${page}`);
            });
          } else {
            this.li$(() => {
              return this.a$('.disabled', {
                href: 'javascript:void(0)'
              }, '...');
            });
          }
        }
        if (this.pagination.next_page()) {
          return this.li$(() => {
            return this.a$('.paginate', {
              href: 'javascript:void(0)',
              data: {
                page: this.pagination.next_page()
              }
            }, () => {
              return this.raw$('&raquo;');
            });
          });
        } else {
          return this.li$('.disabled', () => {
            return this.a$('.disabled', {
              href: 'javascript:void(0)'
            }, () => {
              return this.raw$('&raquo;');
            });
          });
        }
      });
    });
  }

  render_promise(promise_name, attrs, states) {
    var promise_id, ref, render;
    if (!states) {
      states = attrs;
      attrs = {};
    }
    console.log('rendering promise', promise_name);
    promise_id = _.uniqueId('promise');
    render = () => {
      var fun, ref;
      this.document = [];
      fun = states[((ref = this[promise_name]) != null ? ref.state() : void 0) || 'pending'];
      if (fun) {
        this.render$(fun);
      }
      this.$(`[data-promise=${promise_id}]`).html(this.document.join(''));
      this.trigger(`${promise_name}:rendered`);
      return this.render_subviews();
    };
    this.on(`${promise_name}:change`, () => {
      this[promise_name].done(render);
      this[promise_name].fail(render);
      return render();
    });
    if (((ref = this[promise_name]) != null ? ref.state() : void 0) === 'pending') {
      this[promise_name].done(render);
      this[promise_name].fail(render);
    }
    return this.div$('', _.extend(attrs, {
      data: {
        promise: promise_id
      }
    }), () => {
      var ref1;
      return this.render$(states[((ref1 = this[promise_name]) != null ? ref1.state() : void 0) || 'pending']);
    });
  }

  tag(name, ...args) {
    var options;
    options = this.extractOptions(args);
    this.openTag(name, options.attributes);
    if (SelfClosingTags.hasOwnProperty(name)) {
      if ((options.text != null) || (options.content != null)) {
        throw new Error(`Self-closing tag ${name} cannot have text or content`);
      }
      return this.endTag(name);
    } else {
      if (typeof options.content === "function") {
        options.content();
      }
      if (options.text) {
        this.text(options.text);
      }
      return this.closeTag(name);
    }
  }

  openTag(name, attributes) {
    var attributeName, attributePairs, attributesString, dataName, dataValue, style, value;
    if (this.document.length === 0) {
      if (attributes == null) {
        attributes = {};
      }
    }
    attributePairs = (function() {
      const results = [];
      for (attributeName in attributes) {
        value = attributes[attributeName];
        if (value != null) {
          if (attributeName === 'data' && typeof value === 'object') {
            results.push(((function() {
              var results1;
              results1 = [];
              for (dataName in value) {
                dataValue = value[dataName];
                if (dataValue != null) {
                  results1.push(`${attributeName}-${dasherize(dataName)}=\"${dataValue}\"`);
                }
              }
              return results1;
            })()).join(" "));
          } else if (attributeName === 'style' && typeof value === 'object') {
            style = ((function() {
              var results1;
              results1 = [];
              for (dataName in value) {
                dataValue = value[dataName];
                if (dataValue != null) {
                  results1.push(`${dasherize(dataName)}: ${dataValue}`);
                }
              }
              return results1;
            })()).join("; ");
            results.push(`style=\"${style}\"`);
          } else {
            results.push(`${attributeName}=\"${value}\"`);
          }
        }
      }
      return results;
    })();
    attributesString = attributePairs.length ? " " + attributePairs.join(" ") : "";
    return this.document.push(`<${name}${attributesString}>`);
  }

  closeTag(name) {
    return this.document.push(`</${name}>`);
  }

  endTag(name) {}

  rawText(string) {
    return this.document.push(string);
  }

  text(string) {
    var escapedString;
    if (string) {
      escapedString = string.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return this.rawText(escapedString);
    }
  }

  raw(string) {
    return this.rawText(string);
  }

  extractOptions(args) {
    var arg, i, item, j, k, len, len1, options, ref, v;
    options = {
      attributes: {}
    };
    if (typeof args[0] === 'string' && args[0].match(/^([#\.][-a-zA-Z0-9_]+)+$/)) {
      ref = args.shift().split(/(?=[#\.])/);
      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];
        switch (item[0]) {
          case '#':
            options.attributes.id = item.slice(1);
            break;
          case '.':
            options.attributes.class = (options.attributes.class || '') + ` ${item.slice(1)}`;
        }
      }
    }
    for (j = 0, len1 = args.length; j < len1; j++) {
      arg = args[j];
      switch (typeof arg) {
        case 'function':
          options.content = arg;
          break;
        case 'string':
        case 'number':
          options.text = arg.toString();
          break;
        default:
          for (k in arg) {
            v = arg[k];
            options.attributes[k] = v;
          }
      }
    }
    return options;
  }

  on = Backbone.Events.on
  off = Backbone.Events.off
  trigger = Backbone.Events.trigger
  once = Backbone.Events.once
  listenTo = Backbone.Events.listenTo
  stopListening = Backbone.Events.stopListening
  listenToOnce = Backbone.Events.listenToOnce
  bind = Backbone.Events.bind
  unbind = Backbone.Events.unbind

};
BaseView.prototype.render$ = HtmlMaker.prototype.render;
BaseView.prototype.tag = HtmlMaker.prototype.tag;
BaseView.prototype.openTag = HtmlMaker.prototype.openTag;
BaseView.prototype.closeTag = HtmlMaker.prototype.closeTag;
BaseView.prototype.endTag = HtmlMaker.prototype.endTag;
BaseView.prototype.text = HtmlMaker.prototype.text;
BaseView.prototype.raw = HtmlMaker.prototype.raw;
BaseView.prototype.raw$ = HtmlMaker.prototype.raw;
BaseView.prototype.rawText = HtmlMaker.prototype.rawText;
BaseView.prototype.extractOptions = HtmlMaker.prototype.extractOptions;


_.keys(HtmlMaker.prototype).forEach(function(key) {
  BaseView.prototype[`${key}$`] = HtmlMaker.prototype[key];
});

export default BaseView;
