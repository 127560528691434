/* To be used like this:
 * Example how to use this filter:
 * https://codepen.io/elibai/pen/XWQNWba
 *
 *
 */
import { fabric } from 'fabric';
const filters = fabric.Image.filters;
const createClass = fabric.util.createClass;

const BlendImage2 = createClass(filters.BaseFilter, {
  type: 'BlendImage2',

  image: null,

  /**
   * Blend mode for the filter (one of "multiply", "mask")
   * @type String
   * @default
   **/
  mode: 'overlay',

  alpha: 100,

  vertexSource: `
   attribute vec2 aPosition;
   varying vec2 vTexCoord;
   varying vec2 vTexCoord2;
   uniform mat3 uTransformMatrix;

   void main() {
     vTexCoord = aPosition;
     vTexCoord2 = (uTransformMatrix * vec3(aPosition, 1.0)).xy;
     gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);
   }`,

  /**
   * Fragment source for the Multiply program
   */
  fragmentSource: {
    multiply: `
    precision highp float;
    uniform sampler2D uTexture;
    uniform sampler2D uImage;
    uniform vec4 uColor;
    varying vec2 vTexCoord;
    varying vec2 vTexCoord2;
    void main() {
      vec4 color = texture2D(uTexture, vTexCoord);
      vec4 color2 = texture2D(uImage, vTexCoord2);
      color.rgba *= color2.rgba;
      gl_FragColor = color;
    }`,
    mask: `
    precision highp float;
    uniform sampler2D uTexture;
    uniform sampler2D uImage;
    uniform vec4 uColor;
    varying vec2 vTexCoord;
    varying vec2 vTexCoord2;
    void main() {
      vec4 color = texture2D(uTexture, vTexCoord);
      vec4 color2 = texture2D(uImage, vTexCoord2);
      color.a = color2.a;
      gl_FragColor = color;
    }`,
    visibility: `
    precision highp float;
    uniform sampler2D uTexture;
    uniform sampler2D uImage;
    uniform vec4 uColor;
    varying vec2 vTexCoord;
    varying vec2 vTexCoord2;
    void main() {
      vec4 color = texture2D(uTexture, vTexCoord);
      vec4 color2 = texture2D(uImage, vTexCoord2);
      if(color2.a > 0.5) {
        gl_FragColor = color2;
      } else {
        if(color.a < 0.2){
          gl_FragColor = color;
        }else{
          gl_FragColor = vec4(color.rgb, 1.0);
        }
      }
    }`,

  },

  /**
   * Retrieves the cached shader.
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  retrieveShader: function(options) {
    var cacheKey = this.type + '_' + this.mode;
    var shaderSource = this.fragmentSource[this.mode];
    if (!options.programCache.hasOwnProperty(cacheKey)) {
      options.programCache[cacheKey] = this.createProgram(options.context, shaderSource);
    }
    return options.programCache[cacheKey];
  },

  applyToWebGL: function(options) {
    // load texture to blend.
    var gl = options.context,
      texture = this.createTexture(options.filterBackend, this.image);
    this.bindAdditionalTexture(gl, texture, gl.TEXTURE1);
    this.callSuper('applyToWebGL', options);
    this.unbindAdditionalTexture(gl, gl.TEXTURE1);
  },

  createTexture: function(backend, image) {
    return backend.getCachedTexture(image.cacheKey, image._element);
  },

  /**
   * Calculate a transformMatrix to adapt the image to blend over
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  calculateMatrix: function() {
    var image = this.image,
      width = image._element.width,
      height = image._element.height;
    return [
      1 / image.scaleX, 0, 0,
      0, 1 / image.scaleY, 0,
      -image.left / width, -image.top / height, 1
    ];
  },

  /**
   * Apply the Blend operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d: function(options) {
    var imageData = options.imageData,
      resources = options.filterBackend.resources,
      data = imageData.data, iLen = data.length,
      width = imageData.width,
      height = imageData.height,
      tr, tg, tb, ta,
      r, g, b, a,
      canvas1, context, image = this.image, blendData;

    if (!resources.blendImage) {
      resources.blendImage = fabric.util.createCanvasElement();
    }
    canvas1 = resources.blendImage;
    context = canvas1.getContext('2d');
    if (canvas1.width !== width || canvas1.height !== height) {
      canvas1.width = width;
      canvas1.height = height;
    }
    else {
      context.clearRect(0, 0, width, height);
    }
    context.setTransform(image.scaleX, 0, 0, image.scaleY, image.left, image.top);
    context.drawImage(image._element, 0, 0, width, height);
    blendData = context.getImageData(0, 0, width, height).data;
    for (var i = 0; i < iLen; i += 4) {

      r = data[i];
      g = data[i + 1];
      b = data[i + 2];
      a = data[i + 3];

      tr = blendData[i];
      tg = blendData[i + 1];
      tb = blendData[i + 2];
      ta = blendData[i + 3];

      switch (this.mode) {
	case 'multiply':
	  data[i] = r * tr / 255;
	  data[i + 1] = g * tg / 255;
	  data[i + 2] = b * tb / 255;
	  data[i + 3] = a * ta / 255;
	  break;
	case 'mask':
	  data[i + 3] = ta;
	  break;
	case 'visibility':
          if(ta < this.alpha){
            data[i] = r
            data[i + 1] = g;
            data[i + 2] = b;
            data[i + 3] = a;
          } else {
            data[i] = tr;
            data[i + 1] = tg;
            data[i + 2] = tb;
            if(a < 0.2){
              data[i + 3] = ta;
            } else {
              data[i + 3] = 255;
            }
          }
      }
    }
  },

  /**
   * Return WebGL uniform locations for this filter's shader.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {WebGLShaderProgram} program This filter's compiled shader program.
   */
  getUniformLocations: function(gl, program) {
    return {
      uTransformMatrix: gl.getUniformLocation(program, 'uTransformMatrix'),
      uImage: gl.getUniformLocation(program, 'uImage'),
    };
  },

  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData: function(gl, uniformLocations) {
    var matrix = this.calculateMatrix();
    gl.uniform1i(uniformLocations.uImage, 1); // texture unit 1.
    gl.uniformMatrix3fv(uniformLocations.uTransformMatrix, false, matrix);
  },

  /**
   * Returns object representation of an instance
   * @return {Object} Object representation of an instance
   */
  toObject: function() {
    return {
      type: this.type,
      image: this.image && this.image.toObject(),
      mode: this.mode,
      alpha: this.alpha
    };
  }
});

/**
 * Returns filter instance from an object representation
 * @static
 * @param {Object} object Object to create an instance from
 * @param {function} callback to be invoked after filter creation
 * @return {fabric.Image.filters.BlendImage} Instance of fabric.Image.filters.BlendImage
 */
BlendImage2.fromObject = function(object, callback) {
  //@ts-ignore
  fabric.Image.fromObject(object.image, function(image) {
    var options = fabric.util.object.clone(object);
    options.image = image;
    callback(new BlendImage2(options));
  });
};

//@ts-ignore
fabric.Image.filters.BlendImage2 = BlendImage2;

export default BlendImage2;
