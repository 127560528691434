import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

type PopoverButtonProps = {
  children: any,
    content: any,
    placement?: any,
    title?: string,
    wrapComponent?: 'div' | 'span',
}
function PopoverButton({ children, content, placement, title, wrapComponent }: PopoverButtonProps ) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const defaultPlacement = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  const derivedPlacement = placement ? {
    vertical: placement.split("-")[0],
    horizontal: placement.split("-")[1],
  } : defaultPlacement;

  const popo = <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{ vertical: derivedPlacement.vertical || 'bottom', horizontal: derivedPlacement.horizontal || 'center' }}
    transformOrigin={{ vertical: derivedPlacement.vertical === 'bottom' ? 'top' : 'bottom', horizontal: derivedPlacement.horizontal || 'center', }}
  >
    {title && <Typography style={{ padding: 8, fontWeight: 'bold' }}>{title}</Typography>}
    <Typography style={{ padding: 8 }}>{content(handleClose)}</Typography>
  </Popover>;


  if(wrapComponent == 'span')
    return <span>
      <span onClick={handleClick}>{children}</span>
      {popo}
    </span>;

  return (
    <div>
      <div onClick={handleClick}> {children} </div>
    </div>
  );
}

export default PopoverButton;

