import * as _ from 'underscore';
import BaseView from '../../views/base_view';
import {BaseModel} from '../../models/base_model';
import {HipView} from '../../hip/hip_view';

import verbs from '../../actions/verbs';
import { NounType } from '../../hip/noun';
  import { Questions } from 'api/agent';
  import { CommandProps } from 'hip/command';
const nouns:NounType[] = Object.values(require('actions/nouns'));

class QuestionAnswersView extends BaseView<BaseModel> {

  classNames() { return super.classNames() + ' QuestionAnswersView' }

  public question:string;
  public answers:string[];
  hips: {[answer:string]: HipView}={};
  commands:{ [answer:string]: CommandProps } = {};

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    super.initialize(settings)
    this.question=settings.question;
    this.answers=settings.answers;
    this.id=settings.id;
  }

  events() {
    return {
      'click .btn': ()=>this.handle_submit()
    };
  }

  handle_submit(){
    console.log('saving:', this.commands);

    if(this.id){
      Questions.update(this.id, {
        title: this.$('input[name=question]').val() as string,
        answers: Object.keys(this.commands).map((a) => ({label: a, command: this.commands[a]}) )
      })

    }else{
      Questions.create({
        title: this.question,
        answers: Object.keys(this.commands).map((a) => ({label: a, command: this.commands[a]}) )
      })
    }
  }

  template() {
    return `
    <div>
      <div>
        <div class="row mb-3">
          <label for="question" class="col-sm-2 col-form-label">Question</label>
          <div class="col-sm-10">
            <input name="question" class="form-control" value="${_.escape(this.question)}" ${this.id ? '' : 'disabled'} >
          </div>
        </div> ` +
        this.answers.map((answer, i) => `
          <div class="row mb-3">
            <label for="answer_${i}" class="col-sm-2 col-form-label">Answer: ${_.escape(answer)}</label>
            <div class="col-sm-10" id="hip_${i}">
              <div id="hip_content_${i}">
              </div>
            </div>
          </div>
        `).join('') + `
        <div class="row mb-3">
          <div class="col-sm-10 offset-sm-2">
            <button class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
    `
  }

  save_command(ctx, command:CommandProps, i){
    this.commands[ctx.answer] = command;
    console.log('command saved:',ctx.answer,command, i)
    this.hips[ctx.answer].remove();
    this.$(`#hip_${i}`).html(command.toString());
    this.$(`#hip_${i+1}`).focus();
  }

  render() {
    this.$el.html(this.template());

    for(let i in this.answers){
      const ctx = {
        answer: this.answers[i],
        dispatcher: this.dispatcher,
        save_command: ((i)=> (ctx,cmd)=> this.save_command(ctx,cmd,i))(i)
      };
      const hip = new HipView({verbs, nouns, context: ctx, el: this.$(`#hip_content_${i}`)[0]})
      this.hips[this.answers[i]]=hip;
    }

    return this;
  }

};

export {QuestionAnswersView};

