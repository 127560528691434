import React, { Component } from "react"
import { AvatarCard } from "../avatar_card";
import axios from 'axios';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Avatars } from "api/agent";
import { AvatarAttributes } from "models/avatar";

class AvatarSearch extends Component<{xs:number,md:number, onClick?:Function}, {avatars: Array<AvatarAttributes>, query:string,loading:boolean,message:string}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}

  cancel:any;

  constructor(props) {
    super(props);

    this.state = {avatars: [], query: '', loading: true, message: ''};
  }
  
  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, avatars: [], loading: false, message: '' } );
    } else {
      this.setState({ query, avatars: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    Avatars.search(query,updatedPageNo,this.cancel.token).then((avatars) => {
      const resultNotFoundMsg = !avatars.length
        ? 'There are no more search results. Please try a new search.'
        : '';
      this.setState({
        avatars,
        message: resultNotFoundMsg,
        loading: false,
      });
    })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };

  componentDidMount() {
    Avatars.all().then((avatars) => {
      //axios.request<Array<AvatarProps>>({url: '/avatars.json'}).then(({data}) =>
      this.setState({ avatars })
    });
  }

  render () {
    return (
      <>
        <input
          type="text"
          value={this.state.query}
          id="search-input"
          placeholder="Search..."
          onChange={this.handleOnInputChange}
        />
        <Row>
          {this.state.avatars.filter(avatar => avatar.sprites.length && avatar.sprites[0]).map(avatar => (
            <Col xs={this.props.xs} md={this.props.md} key={avatar.id}>
              <AvatarCard {...avatar} onClick={(e)=> this.props.onClick(avatar)}></AvatarCard>
            </Col>
        ))}
      </Row>
    </>
  )
  }

}

export {AvatarSearch}
