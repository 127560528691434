import * as _ from 'underscore';

import BaseView from 'views/base_view';
import { WebResource } from 'models/web_resource';

const css_editable = require('modules/css_editable');

class WebResourceView extends BaseView<WebResource> {

  css_editable = {
    color: "Color",
    backgroundColor: "Color",
    fontSize: "Number"
  };

  toggle_editable: any;
  x: string;
  y: string;

  constructor(options) {
    super(options);
    this.dispatchCreated('WebResourceView');
  }

  events() {
    return {
      dblclick: "handle_dblclick",
      click: function(ev) {
        ev.preventDefault();
        return false;
      },
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "web_resource";

  template = _.template(`<div class="picture">
  <div class="mymodal-header">
    <button type="button" class="close">&times;</button>
    <h4 class="modal-title">WebResource <%= model.get("name") %></h4>
  </div>
  <div class="mymodal-body">
    <iframe src="<%= model.get("url") %>"></iframe>
  </div>
</div>`);


  handle_dblclick(ev) {
    this.toggle_editable(true);
    ev.preventDefault();
    return false;
  }

  initialize(settings) {}

  render() {
    var css;
    css = _.extend({
      position: "absolute",
      left: this.x + "px",
      top: this.y + "px",
      width: "300px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }, this.model.get("css"));
    this.$el.css(css);
    if (this.model.get("weblink")) {
      this.$el.html(this.model.get("embed"));
    } else {
      this.$el.html(this.template(this));
    }
    return this;
  }

};


_.extend(WebResourceView.prototype, css_editable);

export {WebResourceView};

