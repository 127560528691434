import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
} from 'constants/actionTypes';
import { Auth } from 'api/agent';
import {Button, createStyles, makeStyles, Theme, Typography, TextField, Box, Tabs, Tab} from '@material-ui/core';
import AccountLayout from './Layout';
import Alert from '@material-ui/lab/Alert';
import {T} from 'components/utils/t';

const prefix='pages.login';

const useStyles = makeStyles((theme: Theme) => createStyles({
  socialButton: {
    marginTop: theme.spacing(2),
  },
  googleButton: {
    backgroundColor: '#DB4437',
    color: theme.palette.common.white,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
})
);

const LoginForm = ({ classes, errors }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const onChangeEmail = (value) => {
    setEmail(value);
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value });
  };

  const onChangePassword = (value) => {
    setPassword(value);
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setInProgress(true);

    Auth.login(email, password).then((result) => {
      dispatch({type: LOGIN, payload: result});
      setInProgress(false);
    }, (error) => {
      dispatch({type: LOGIN, error: true, payload: error.response.body});
      setInProgress(false);
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => onChangeEmail(e.target.value)}
        error={errors && errors.email ? true : false}
        helperText={errors && errors.email ? errors.email[0] : ''}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => onChangePassword(e.target.value)}
        error={errors && errors.password ? true : false}
        helperText={errors && errors.password ? errors.password[0] : ''}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={inProgress}
      >
        Sign In
      </Button>
    </form>
  );
};

const RegisterForm = ({ classes }) => (
  <form className={classes.form} noValidate>
    <TextField
      margin="normal"
      required
      fullWidth
      id="register-email"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="register-password"
      autoComplete="new-password"
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Register
    </Button>
  </form>
);

export function RouterTabs() {
  const location = useLocation(); // To get the current route for active tab highlighting

  return (
    <Tabs value={location.pathname} aria-label="router tabs">
      <Tab label="SignIn" value="/login" component={Link} to="/login" />
      <Tab label="Register" value="/register" component={Link} to="/register" />
    </Tabs>
  );
}


const Login = () => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);

  const { from, sign_in_reason_key } = location.state || { from: { pathname: '/' }, sign_in_reason_key: 'login'};

  const { errors } = useSelector((state:any) => state.auth);

  const showSocialLogin = (window as any).debug || window.location.toString().match(/dev.viwoc/);

  const renderErrors = () => {
    if (!errors) return null;
    
    const errorKeys = Object.keys(errors).filter(key => key !== 'email' && key !== 'password');
    if (errorKeys.length === 0) return null;

    return (
      <Box className={classes.errorBox}>
        {errorKeys.map((key) => (
          <Alert severity="error" key={key}>
            {errors[key]}
          </Alert>
        ))}
      </Box>
    );
  };

  const nice_content = <><div className="container">
              <div className="row">
                <div className="col-12 col-md-6 offset-md-3 heading" style={{marginTop: '10rem'}}>
                  <Typography component="h2" variant="h2" align="center">
                    <T k={`${prefix}.illustration.header`}>Login</T>
                  </Typography>
                  <Typography component="h3" variant="h3" align="center">
                    <T k={sign_in_reason_key}> </T>
                  </Typography>
                </div>
              </div>
            </div></>;

  return (
    <AccountLayout nice_content={nice_content}>
      <RouterTabs />

      {renderErrors()}

      <LoginForm classes={classes} errors={errors} />

      {showSocialLogin && (
        <>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Or sign in with
          </Typography>

          <Button
            variant="contained"
            fullWidth
            startIcon={<i className="fa fa-google" />}
            className={[classes.socialButton, classes.googleButton].join(' ')}
            onClick={() => Auth.google()}
          >
            Sign in with Google
          </Button>
        </>
      )}
    </AccountLayout>
  );
};

export default Login;
