/*<reference path=”app/javascript/interfaces.d.ts” />*/
/* Optimizely te un editor de CSS que te 6 pestanyes (i per cada pestanya: mode avançat o basic) */

import _ from 'underscore';
import { dotry } from 'utils/dotry';
import BaseView from 'views/base_view';
import * as Backbone from 'backbone';

const css_editable = require('modules/css_editable');

class CssEditorView extends BaseView<any> {
  megaschema: any;
  forms: any;
  events () {
    return {
      "click .enviar": "handle_submit",
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "css_editor_view";

  tabs = [
    {
      id: "text",
      label: "Text",
      schema: {
        fontFamily: {
          title: "Font Family",
          type: "Text"
        },
        fontSize: {
          title: "Font Size",
          type: "Text"
        },
        fontStyle: {
          title: "Font Style",
          type: "Select",
          options: ["normal",
            "italic",
            "oblique",
            "inherit"]
        },
        textAlign: {
          title: "Text Alignment",
          type: "Select",
          options: ["center",
            "left",
            "right",
            "justify",
            "start",
            "end",
            "inherit"]
        },
        textDecoration: {
          title: "Text Decoration",
          type: "Select",
          options: ["none",
            "inherit",
            "underline",
            "line-through",
            "overline",
            "blink"]
        },
        fontWeight: {
          title: "Font Weight",
          type: "Select",
          options: ["normal",
            "bold",
            "thin",
            "100",
            "200",
            "900",
            "thick"]
        }
      }
    },
    {
      id: "color-background",
      label: "Color&Background",
      schema: {
        color: {
          title: "Font Color",
          type: "Color"
        },
        backgroundColor: {
          title: "Background Color",
          type: "Color"
        },
        backgroundImage: {
          title: "Background Image",
          type: "Text"
        },
        backgroundPosition: {
          title: "Background Position",
          type: "Text"
        },
        backgroundRepeat: {
          title: "Background Repeat",
          type: "Select",
          options: ["repeat",
            "repeat-x",
            "repeat-y",
            "no-repeat",
            "inherit"]
        }
      }
    },
    {
      id: "dimensions",
      label: "Dimensions",
      schema: {
        height: {
          title: "Height",
          type: "Text"
        },
        width: {
          title: "Width",
          type: "Text"
        },
        margin: {
          title: "Margin",
          type: "Text"
        },
        padding: {
          title: "Padding",
          type: "Text"
        }
      }
    },
    {
      id: "borders",
      label: "Borders",
      schema: {
        borderColor: {
          title: "Border Color",
          type: "Color"
        },
        borderStyle: {
          title: "Border Style",
          type: "Select",
          options: ["solid",
            "dotted",
            "dashed",
            "outset",
            "inset",
            "groove",
            "ridge",
            "inherit",
            "none"]
        },
        borderWidth: {
          title: "Border Width",
          type: "Text"
        }
      }
    },
    {
      id: "layout",
      label: "Layout",
      schema: {
        top: {
          title: "Top",
          type: "Text"
        },
        bottom: {
          title: "Bottom",
          type: "Text"
        },
        left: {
          title: "Left",
          type: "Text"
        },
        right: {
          title: "Right",
          type: "Text"
        },
        zIndex: {
          title: "Z-Index",
          type: "Text"
        },
        position: {
          title: "Position",
          type: "Select",
          options: ["absolute",
            "fixed",
            "relative",
            "static",
            "inherit"]
        },
        float: {
          title: "Float",
          type: "Select",
          options: ["right",
            "left",
            "none"]
        },
        clear: {
          title: "Clear",
          type: "Select",
          options: ["right",
            "left",
            "none"]
        }
      }
    },
    {
      id: "other",
      label: "Other",
      schema: {
        listStyleType: {
          title: "ListStyleType",
          type: "Select",
          options: ["Armenian",
            "circle",
            "decimal",
            "Georgian",
            "katanaka",
            "none",
            "square"]
        }
      }
    }
  ];

  template = _.template(`
<div class="css_editor">
  <div class="modal-header">
    <button type="button" class="close">&times;</button>
    <h4 class="modal-title"><%= "name" %></h4>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs">
      <% for(var i=0;i< tabs.length;i++){ %>
        <li class="<%= i== 0 ? "active" : "" %>">
          <a href="#<%= tabs[i].id %>" data-bs-toggle="tab"><%= tabs[i].label %></a>
        </li>
      <% } %>
    </ul>
    <div class="formPlaceholder">
    </div>
    <button type="button" class="enviar">Enviar</button>
  </div>
</div>`);

  field_template = _.template(`<div class="control-group">
  <label for="<%= editorId %>" class="control-label"><%= title %></label>
  <div class="controls">
    <span data-editor></span>
    <div data-error></div>
    <div><%= help %></div>
  </div>
</div> `);

  forms_template = _.template(`
<form class="form-horizontal tab-content">
  <% for(var i=0;i< this.tabs.length;i++){ %>
    <div class="tab-pane fade <%= i== 0 ? "in active" : "" %>" id="<%= this.tabs[i].id %>">
      <% for(var j in this.tabs[i].schema){ %>
        <div data-fields="<%= j %>"></div>
      <% } %>
    </div>
  <% } %>
  </form>
  `);

  constructor(options) {
    super(...Array.from(arguments));
    this.dispatchCreated('CssEditorView');
  }

  initialize(settings) {
    this.megaschema = _.extend.apply(this, [{}].concat(_.pluck(this.tabs, "schema")));
    console.log(this);
    this.forms = new (Backbone as any).Form({
      schema: _(this.megaschema).chain().pairs().map(function(v) {
        v[1].template = this.field_template;
        return v;
      }, this).object().value(),
      data: _(this.megaschema).chain().keys().map(function(k) {
        return [k, this.model.$el.css(k)];
      }, this).object().value(),
      template: _.template(this.forms_template(this))
    });
    this.forms.on("change", (function(form) {
      this.model.updateCss(this.forms.getValue());
    }), this);
  }

  handle_submit(ev) {
    if ("commit" in this.model) {
      this.forms.commit();
    } else {
      console.log(this.model);
      window[this.model.cid] = this.model;
    }
    this.trigger("commit", this.forms.getValue());
    dotry(this, "model", "model", [
      "save",
      null,
      {
        error: function(jqXHR, text) {
          console.log("AJAX error:", text);
        },
        success: _.bind(function(data, textStatus, jqXHR) {
          console.log("AJAX success:", textStatus);
          // TODO fix this dynamic event name and register it in Events enum
          this.dispatcher.trigger("created:" + this.model.model._className(), this.model.model);
        },
          this)
      }
    ]);
  }

  handle_dblclick(ev) {
    this.toggle_editable();
    ev.preventDefault();
    return false;
  }
  toggle_editable() {
  }
  render() {
    var css;
    css = {
      position: "absolute",
      left: "100px",
      top: "100px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    };
    this.$el.css(css).html(this.template(this)).find(".formPlaceholder").html(this.forms.render().el);
    (this.$el as any).draggable();
    this.toggle_editable();
    this.$(".spectrum").spectrum({
      allowEmpty: true,
      showInput: true,
      className: "full-spectrum",
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "rgb",
      localStorageKey: "spectrum.demo",
      move: function(color) {},
      show: function() {},
      hide: function(color) {},
      palette: [ //"rgb(153, 153, 153)","rgb(183, 183, 183)",
        [
          "rgb(0, 0, 0)",
          "rgb(67, 67, 67)",
          "rgb(102, 102, 102)",
          "rgb(204, 204, 204)", //"rgb(239, 239, 239)", "rgb(243, 243, 243)",
          "rgb(217, 217, 217)",
          "rgb(255, 255, 255)"
        ],
        ["rgb(152, 0, 0)",
          "rgb(255, 0, 0)",
          "rgb(255, 153, 0)",
          "rgb(255, 255, 0)",
          "rgb(0, 255, 0)",
          "rgb(0, 255, 255)",
          "rgb(74, 134, 232)",
          "rgb(0, 0, 255)",
          "rgb(153, 0, 255)",
          "rgb(255, 0, 255)"],
        [
          "rgb(230, 184, 175)",
          "rgb(244, 204, 204)",
          "rgb(252, 229, 205)",
          "rgb(255, 242, 204)",
          "rgb(217, 234, 211)",
          "rgb(208, 224, 227)",
          "rgb(201, 218, 248)",
          "rgb(207, 226, 243)",
          "rgb(217, 210, 233)",
          "rgb(234, 209, 220)",
          "rgb(221, 126, 107)",
          "rgb(234, 153, 153)",
          "rgb(249, 203, 156)",
          "rgb(255, 229, 153)",
          "rgb(182, 215, 168)",
          "rgb(162, 196, 201)",
          "rgb(164, 194, 244)",
          "rgb(159, 197, 232)",
          "rgb(180, 167, 214)",
          "rgb(213, 166, 189)",
          "rgb(204, 65, 37)",
          "rgb(224, 102, 102)",
          "rgb(246, 178, 107)",
          "rgb(255, 217, 102)",
          "rgb(147, 196, 125)",
          "rgb(118, 165, 175)",
          "rgb(109, 158, 235)",
          "rgb(111, 168, 220)",
          "rgb(142, 124, 195)",
          "rgb(194, 123, 160)",
          "rgb(166, 28, 0)",
          "rgb(204, 0, 0)",
          "rgb(230, 145, 56)",
          "rgb(241, 194, 50)",
          "rgb(106, 168, 79)",
          "rgb(69, 129, 142)",
          "rgb(60, 120, 216)",
          "rgb(61, 133, 198)",
          "rgb(103, 78, 167)",
          "rgb(166, 77, 121)",

          //"rgb(133, 32, 12)", "rgb(153, 0, 0)", "rgb(180, 95, 6)", "rgb(191, 144, 0)", "rgb(56, 118, 29)",
          //            "rgb(19, 79, 92)", "rgb(17, 85, 204)", "rgb(11, 83, 148)", "rgb(53, 28, 117)", "rgb(116, 27, 71)",
          "rgb(91, 15, 0)",
          "rgb(102, 0, 0)",
          "rgb(120, 63, 4)",
          "rgb(127, 96, 0)",
          "rgb(39, 78, 19)",
          "rgb(12, 52, 61)",
          "rgb(28, 69, 135)",
          "rgb(7, 55, 99)",
          "rgb(32, 18, 77)",
          "rgb(76, 17, 48)"
        ]
      ]
    });
    return this;
  }

};

_.extend(CssEditorView.prototype, css_editable);

export {CssEditorView};
