import { fabric } from 'fabric';
import { FabricObject, toObject, FabricObjectOption, FabricGroup } from '../utils';

//export type SvgObject = FabricGroup | FabricObject;

export interface ISvgOption extends FabricObjectOption {
  svg?: string;
  loadType?: 'file' | 'svg';
}

class SvgObject extends fabric.Group {

  static type= 'svg'
  svg:string
  loadType:'file' | 'svg'
  loaded:boolean=false;
  load:Promise<boolean|void>;

  get replacedColors(){
    //@ts-ignore
    if(!this.metadata.replaceColors)
      //@ts-ignore
      this.metadata.replaceColors={};

    //@ts-ignore
    return this.metadata.replaceColors
  }
  palette: Set<string>;
  
  //@ts-ignore
  initialize(option: ISvgOption = {}) {
    const { svg, loadType } = option;
    //@ts-ignore
    super.initialize([], option);

    this.load = this.loadSvg(svg, loadType, option).then((group) => {
      const {width,height}=option;
      let {scaleX,scaleY}=option;

      if(width && !scaleX){
        scaleX= option.width / this.width;
        this.set('scaleX',scaleX)
      }

      if(height && !scaleY){
        scaleY= option.height / this.height;
        this.set('scaleY', scaleY)
      }

      Object.keys(option.metadata?.replaceColors || {}).forEach((color) => {
        this.replaceColor(color, option.metadata.replaceColors[color])
      })

      this.loaded=true

    });
  }

  replaceColor(original:string, replacement:string):FabricObject[] {
    original = new fabric.Color(original).toRgb();

    const replaced=this.getObjects().map((ob) => {
      const {originalFill,originalStroke}=(ob as any);

      if(![originalFill,originalStroke].includes(original))
        return null;

      if(originalFill == original)
        ob.set('fill', replacement);

      if(originalStroke == original)
        ob.set('stroke', replacement);

      return ob
    }).filter(Boolean);

    this.replacedColors[original] = replacement;

    if(replaced.length){
      this.set('dirty', true)
    }

    return replaced
  }

  addSvgElements(objects: FabricObject[], options: any, path: string, originalOption:any):fabric.Group {

    this.palette = new Set();

    objects.forEach((ob) => {
      const {fill,stroke}=ob;

      if(typeof(fill) == 'string'){
        const x= new fabric.Color(fill).toRgb()
        ob.set('originalFill',x)
        this.palette.add(x);
      }
      if(typeof(stroke) == 'string'){
        const x= new fabric.Color(stroke).toRgb();
        ob.set('originalStroke',x)
        this.palette.add(x);
      }
    })

    const createdObj = fabric.util.groupSVGElements(objects, options, path) as fabric.Group;

    this.set(options);

    if (createdObj.getObjects) {
      (createdObj as FabricGroup).getObjects().forEach(obj => this.add(obj));
    } else {
      createdObj.set({ originX: 'top', originY: 'left' });

      this.add(createdObj);
    }
    this.setCoords();
    if (this.canvas) {
      this.canvas.requestRenderAll();
    }
    return createdObj;
  }

  loadSvg(svg: string, loadType: 'file' | 'svg', originalOption:any) {
    return new Promise<fabric.Group>((resolve) => {
      if (loadType === 'svg') {
        fabric.loadSVGFromString(svg, (objects, options) => {
          resolve(this.addSvgElements(objects, options, svg, originalOption));
        });
      } else {
        fabric.loadSVGFromURL(svg, (objects, options) => {
          resolve(this.addSvgElements(objects, options, svg, originalOption));
        });
      }
    });
  }

  toObject(propertiesToInclude: string[]) {
    return toObject(this, propertiesToInclude, {
      svg: this.get('svg'),
      loadType: this.get('loadType'),
    });
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render(ctx);
  }

  static fromObject(option: ISvgOption, callback: (obj: SvgObject) => any) {
    //@ts-ignore
    return callback(new fabric.Svg(option));
  };

};

fabric.Svg = fabric.util.createClass(SvgObject, {
  type: SvgObject.type
});

//@ts-ignore
fabric.Svg.fromObject = SvgObject.fromObject;

export {SvgObject};

//declare module 'fabric' {
//  namespace fabric {
//    class Svg extends SvgObject {
//      constructor(options: ISvgOption)
//    }
//  }
//}
export default fabric.Svg;
