import { useState } from 'react';
import ListErrors from 'components/shared/ListErrors';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClazzAttributes } from 'models/clazz';
import React from 'react';
import { pick_npc } from 'components/shared/pick_model';
import { SpriteProps } from 'components/sprites/sprite';
import { NpcAttributes } from 'models/npc';

export default function ClazzForm({errors, submitForm, creation_status, clazz}:{errors:string[],submitForm:(any)=>void,creation_status:string,clazz?:Partial<ClazzAttributes>}){

  const [state,setState] = useState(clazz || {});

  return <>
  <ListErrors errors={errors} />

  <form className="form-horizontal" method="post" onSubmit={ev => {ev.preventDefault();submitForm(state)}}>
    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="space_Name">Name:</label>
      <div className="col-md-8">
        <input className="form-control" type="text" value={state.name} name="space[name]" onChange={(e) => setState({...state, name:e.target.value})} />
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
      <div className="col-md-8">
        <textarea className="form-control" value={state.description} name="space[description]" onChange={(e) => setState({...state, description:e.target.value})}></textarea>
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="space_sprite">Sprite:</label>
      <div className="col-md-8">
        <input className="form-control" value={state.npc?.name} name="space[npc]" disabled={true}></input>
        <div className="btn btn-info-outline" onClick={(e)=>pick_npc({ }).then((npc) => setState({...state,npc:npc.attributes as NpcAttributes}))}>Pick sprite</div>
      </div>
    </div>

    <Modal
      open={!!creation_status}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {
        (creation_status == 'creating' ?
        <CircularProgress />
        : 
        <div className="alert alert-danger">
          Error creating
        </div>
        )
      }
    </Modal>

    <div className="form-group">
      <div className="col-md-8 col-md-offset-4">
        <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
      </div>
    </div>

  </form>
</>
}
