import React, { MouseEventHandler } from "react";
import Card  from 'react-bootstrap/Card';
import { SpriteCard } from "components/sprites/sprite_card";
import { AvatarAttributes } from "models/avatar";
import {avatar_url} from "utils/urls";

const AvatarCard: React.FC<AvatarAttributes & {onClick: MouseEventHandler<HTMLDivElement>}> = (props) => { 
  const url = '/#'+avatar_url(props.uuid);

  return (<Card>
    <Card.Header>
      <strong><a href={url}>{props.name}</a></strong>
    </Card.Header>

    <Card.Body onClick={props.onClick}>

      {props.sprites.filter(sp => sp.map?.sequences).map((sprite) =>
        <SpriteCard sprite={sprite} html={{className:"card-img"}} key={sprite.id}></SpriteCard>
      )}

      <Card.Text>{props.description}</Card.Text>
    </Card.Body>

  </Card>)
}

export {AvatarCard }
