import * as _ from 'underscore';
import BaseView from 'views/base_view';
import { Anchor } from 'models/anchor';
import { MapView } from './map_view';
import { store } from 'redux/store';
import { Menu } from 'views/menu';
import { pick_model } from 'components/shared/pick_model';
import { MODES } from 'components/world/constants';
import { Events } from 'maps/map_events';
import { ScriptTarget, ScriptProps } from 'models/script';
import { AvatarView } from './avatar_view';
import { Avatar } from 'models/avatar';
import { ScriptExecutor } from 'models/script_executor';
import { MAP_EDIT_NPC_PLACEMENT, MAP_EDIT_ANCHOR } from 'constants/actionTypes';

const css_editable = require('modules/css_editable');

class AnchorView extends BaseView<Anchor> implements ScriptTarget {

  scenes: { [name:string]: ScriptProps }
  script_avatars: Array<AvatarView<Avatar>>=[];
  executor: ScriptExecutor;
  is_placeable: boolean;
  map: MapView;
  top: number;
  left: number;
  cb: Function;
  $outer: JQuery<Element>;
  classNames() { return super.classNames() +  " anchor_view"};

  constructor(options) {
    super(options)
    if (!options) {
      return;
    }
    this.dispatchCreated('AnchorView');
  }

  initialize(settings) {

    super.initialize(settings)

    const repeat = (this.model.get("events") || []).find(({event,command,args}) => event == 'repeat');
    if (repeat) {
      this[repeat.command].apply(this, repeat.args);
    }

    this.$el.attr('id', `anchor-${this.anchor.id}`);
    this.$el.data('anchor',this);

  }

  get anchor():Anchor {
    return this.model as Anchor;
  }

  extract_events(evs:{[name:string]: any[]}) {
    return Object.keys(evs || {}).reduce((events:{}, event_name:string) => {

      let args:any[] = evs[event_name]

      if(event_name == 'hover')
        event_name = 'mouseenter'

      events[event_name + " .playable"] = (ev) => {
        const mode = store.getState().map.mode;
        if (mode === MODES.Placing) {
          return true;
        }
        this[args[0]].apply(this, args.slice(1));
        ev.preventDefault();
        ev.stopImmediatePropagation();
        return false;
      };
      return events;
    }, {});
  }

  get showEditor():boolean {
    return store.getState().map.space.canEdit;
  }

  events() {
    let events = this.model.get("events");
    let real_events = this.extract_events(events);

    if (this.showEditor) {
      return Object.assign(real_events, {
        "contextmenu": (e) => {
          this.show_menu(e, { remove_cb: () => {} });
          return false;
        },
        "dblclick": e => this.handle_dblclick(e),

        "click": (e) => {

          if (this.is_placeable) {
            this.unset_placeable();
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
          } else {
            return true;
          }
        }

      })
    }
  }

  handle_dblclick(ev) {
    const mode = store.getState().map.mode;

    if (! [MODES.Editing,MODES.Placing].includes(mode))  {
      return;
    }

    store.dispatch({ type: MAP_EDIT_ANCHOR, anchor: this.model});

    return false;
  }


  remove() {
    this.script_avatars.forEach(ch => ch.remove());
    return super.remove();
  }

  show_menu(ev, opts:any = {}) {
    this.setMenu(new Menu({
      collection: {
        play: "Play",
        destroy: "Eliminar",
      },
      remove_cb: opts.remove_cb,
      x: this.x + ev.offsetX,
      y: this.y + ev.offsetY,
      dispatcher: this.dispatcher,

      callback: {
        play: this.play_menu.bind(this),

        destroy: () => {
          if(prompt('Are you sure?'))
            this.model.destroy();
        }
      }
    }));
  }

  play_menu(value, label, x, y) {
    this.setMenu(new Menu({
      collection: {
        hover: "Hover",
        repeat: "Repeat",
      },
      x: x,
      y: y,
      dispatcher: this.dispatcher,
      callback:  {
        hover: (value, label, x, y) => {
          pick_model('Script', { dispatcher: this.dispatcher }).then((script) => {
            this.menu.remove();
            this.addEvent('hover', ["play_script", script.get('id'), {repeat: false}]);
          });
        },

        repeat: (value, label, x, y) => {
          pick_model('Script', { dispatcher: this.dispatcher }).then((script) => {
            this.menu.remove();
            this.addEvent('repeat', ["play_script", script.get('id'), {repeat: true}]);
          });
        },


      }
    }));
  }

  addEvent(event, args) {
    const evs = this.model.get("events") || {};
    evs[event] = args;
    this.model.set({ events: evs });
    this.model.save(null, {
      error: (jqXHR, text) => {
        console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        console.log("AJAX success:", textStatus);
        this.delegateEvents();
      }
    });
  }


  get x(){ return this.model.get('x') }
  get y(){ return this.model.get('y') }

  unset_placeable(save=false) {
    if (!this.is_placeable)
      return;

    this.model.set({x: this.$outer.css('left'), y: this.$outer.css('top')});
    this.cb(this.model)

    $(document).off(`mousemove.${this.cid}`);

    this.is_placeable = false;
  }

  getPosition(e) {
    return {
      left: (e.pageX - this.left)/(this.map.zoomFactor || 1) - this.outer_width/2,
      top:  (e.pageY - this.top )/(this.map.zoomFactor || 1)  - this.outer_height/2
    }
  }


  set_placeable(cb:Function=null) {
    if (this.is_placeable)
      return;

    this.cb = cb;

    this.is_placeable = true;

    const top = this.map.$content.offset().top;
    const left = this.map.$content.offset().left;

    this.top = top - this.$el.position().top;
    this.left = left - this.$el.position().left;

    this.$el.css({opacity: 'none'});

    $(document).on(`mousemove.${this.cid}`, (e) => {
      const pos = this.getPosition(e)
      this.$outer.css(pos);
    })

    this.$el.css({ zIndex: 1000 })
  }

  get outer_width():number{
    return parseFloat(this.$outer.css('width'))
  }
  get outer_height():number{
    return parseFloat(this.$outer.css('height'))
  }

  play_script(id, opts:any={}) {
    this.dispatcher.trigger(Events.PLAY_SCRIPT, { id: id, where: this, repeat: opts.repeat });
  }

  render() {
    const content = `
      <div class="anchor-content playable">
        <i class="fal fa-anchor fa-2x"></i>
        <span class="bubble">${this.model.get("name")}</span>
      </div>
    `;

    this.$el.html($(content).css({
      left: this.model.get("x") + "px",
      top: this.model.get("y") + "px",
      position: "absolute"
    })[0]);
    this.$outer = this.$el.find('.anchor-content');

    return this;
  }



};

_.extend(AnchorView.prototype, css_editable);

export {AnchorView};
