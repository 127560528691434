import {map} from 'underscore';

class Recorder {

  originals:{[method:string]:Function};
  obj: any;
  own_methods: any[];
  calls: any[];
  inherited_methods: any[];
  previous_time: number;

  constructor(obj,methods:string[],fn=null){
    this.obj = obj;

    this.own_methods = [];
    this.inherited_methods = [];
    this.calls = [];
    this.originals ={};

    methods.forEach((method) => {

      (obj.hasOwnProperty(method) ? this.own_methods : this.inherited_methods).push(method);

      this.originals[method] = obj[method];

      this.previous_time = new Date().getTime();

      obj[method] = this.createRecorder(this,obj,method);
    });

  }

  createRecorder(recorder,target,name) {
    return function() {
      if(typeof(recorder.previous_time) === "undefined"){
        recorder.previous_time = new Date().getTime();
      }
      var args = map(arguments, (a)=>a);
      recorder.calls.push([new Date().getTime() - recorder.previous_time,name, args]);
      // @ts-ignore
      return recorder.originals[name].apply(target,args);
    };
  }

  detach(){

    for(let i=0;i<this.own_methods.length;i++){
      this.obj[this.own_methods[i]] = this.obj[this.own_methods[i]].original;
    }

    for(let j=0;j<this.inherited_methods.length;j++){
      delete this.obj[this.inherited_methods[j]];
    }
  }
}

export default Recorder;
