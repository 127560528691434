import React from 'react';
import _ from 'underscore';
import { MapController } from 'maps/controller';
import { install_event_handlers } from 'maps/events';
import { Space } from 'models/space';
import { Avatar } from 'models/avatar';
import { User } from 'models/user';
import { PersonaView } from 'maps/views/persona_view';
import { Events } from 'maps/map_events';
import { dotry } from 'utils/dotry';
import { connect } from 'react-redux';

import 'stylesheets/map.scss' 
import 'stylesheets/modal-fullscreen.scss' 

import { Button} from '@material-ui/core';
import { pick_avatar } from 'components/shared/pick_model';
import { MAP_SET_MODE, MAP_SET_MUTE, MAP_SET_FULLSCREEN, MAP_SHOW_ANCHORS, MAP_SET_ZOOM, MAP_SET_CLICK_AND_MOVE, MAP_SHOW_ZONES } from 'constants/actionTypes';
import { MODES } from './constants';
import { SpriteCard } from 'components/sprites/sprite_card';
import { ItemAttributes } from 'models/item';

type InventoryProps = {
  inventory: ItemAttributes[],
  onMode: Function,
}

class InventoryClass extends React.Component<InventoryProps,{}> {

  constructor(props){
    super(props)
  }

  public render() {
    const props = this.props;

    const inventory_items = (this.props.inventory || []).reduce((acc,item) => {
      const slot = acc.find(([clazz,count]) => clazz.id == item.clazz.id)
      if(slot)
        slot[1] = slot[1]+1;
      else
        acc.push([item.clazz,1])
      return acc
    },[]).map(([clazz,count]) => (
      <div  className="inventory-item" key={clazz.id}>
        <SpriteCard hideBtns={true} display_width={50} sprite={clazz.sprites[0]}></SpriteCard>
        <span className="badge badge-pill badge-light" style={{position:'absolute', bottom: 0, right: 0}}>{count}</span>
      </div>))

    const extra_slots = Array.from(new Array(18 - inventory_items.length)).map((x,i) => <div className="inventory-item" key={i}></div>)

    return (
      <div className="inventory">
        {inventory_items}
        {extra_slots}
      </div>
    )
  }

}

const mapStateToProps = ({map, common}) => ({
  inventory: map.inventory,
})

const mapDispatchToProps = (dispatch) => ({

  onMode: (mode) => dispatch({ type: MAP_SET_MODE, mode }),

})
const Inventory = connect(mapStateToProps,mapDispatchToProps)(InventoryClass)

export { Inventory }
