import { BaseModel } from "./base_model";

type VideoAttrs = {
  id:string,
  uuid:string,
  name:string,
  description:string
  status:string,
  user_id: string, 
  created_at:string,
  updated_at: string,
  url: string,
  game_id: string,
}

class Video extends BaseModel {

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Video');
  }

  protectedAttributes() {
    return super.protectedAttributes().concat(["video_content_type", "embed_full", "video_url"]);
  }

  _className() { return "Video"; }

  initialize(attributes, options){
    return super.initialize(attributes, options);
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "videos", this.id || ''].join('/');
  }

};

Video.prototype.schema = {
  name: "Text",
  description: "Text",
  temp_file_key: "Filepicker"
};

export { Video, VideoAttrs };
