import {Verb} from './verb'
import Verbs from '../actions/verbs'
import * as Nouns from '../actions/nouns'

type CommandProps = {
  verb: {name:string}, 
  directObject: {label:string}, 
  modifiers: {[mod:string]: {label:string}}, 
}

class Command {

  static Name2verb:any = Verbs.reduce((acc,verb) => ({...acc, [verb.name]:verb}), {})

  verb: Verb;
  directObject: any;
  modifiers: any;

  constructor(verb:Verb,directObject,modifiers){
    this.verb=verb;
    this.directObject=directObject;
    this.modifiers=modifiers;
  }

  toJSON():CommandProps{
    return {
      verb: this.verb,
      directObject: this.directObject ? this.directObject : undefined,
      modifiers: Object.keys(this.modifiers).length ? this.modifiers : undefined,
    }
  }

  static fromJSON(object:CommandProps):Command{
    const verb = Command.Name2verb[object.verb.name];
    const directObject = object.directObject;
    const modifiers ={};

    for(let k in object.modifiers){
      modifiers[k] = object.modifiers[k];
    }
    return new Command(verb,directObject,modifiers)
  }

  execute(context){
    this.verb.execute(this.directObject, this.modifiers, context)
  }

  toString():string{
    return `${this.verb} ${this.directObject ? this.directObject.toString() : ''} ${Object.keys(this.modifiers).map((k)=>`${k} ${this.modifiers[k].label || this.modifiers[k]}`)}`
  }

}

export { Command, CommandProps }
