import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {useDesignContext} from 'contexts/design_context';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@material-ui/core';
import {Games } from 'api/agent';
import {SoundAttrs} from 'models/sound';
import {VideoAttrs} from 'models/video';
import {GlitchEventAction, GlitchSignpost} from './glitch/space';
import {SpaceAttributes} from 'models/space';
import {useDispatch, useSelector} from 'react-redux';
import SelectSoundModal from './sounds/select_sound_modal';
import SelectVideoModal from './videos/select_video_modal';
import {APPLY_EFFECT, CLICK_EVENT, COLLISION_END_EVENT, COLLISION_START_EVENT, HOVER_EVENT, PLAY_SOUND_ACTION, PLAY_VIDEO_ACTION, SHOW_TEXT_ACTION,SHOW_WEB_RESOURCE_ACTION, TRAVEL_ACTION} from 'game/game_constants';
import {effects} from 'game/effects';
import {saveGame} from './canvas/Header';
import {setOpenProperties} from 'redux/reducers/editor';
import SelectWebResourceModal from './web_resources/select_web_resource_modal';
import {WebResourceAttributes} from 'models/web_resource';
import {T} from './utils/t';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  card: {
    width: '100%',
    maxWidth: 400,
  },
  songInfo: {
    marginBottom: theme.spacing(1),
  },
  selectButton: {
    marginTop: theme.spacing(1),
  },

  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const ConfigureEffect = ({classes, eventConfig, setEventConfig}:any & {setEventConfig:any, eventConfig: GlitchEventAction}) => {

  return (<>
  <Typography variant="h6"><T k="actions_config.select_effect.title">Select Effect</T></Typography>
  <FormControl className={classes.formControl} fullWidth>
    <InputLabel id="effect-select-label">Effect</InputLabel>
    <Select labelId="effect-select-label" value={eventConfig.parameters?.effect || ''} onChange={(event) => setEventConfig({ ...eventConfig, parameters: {effect: event.target.value} })}>
      {Object.keys(effects).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</>)

}
const SelectSound = ({classes, eventConfig, setEventConfig}:any & {setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const sounds:Array<SoundAttrs> = useSelector((state:any) => state.editor?.sounds);
  const [selectedSong, setSelectedSong] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedSong(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            <T k="actions_config.select_sound.selected_sound">Selected Song</T>
          </Typography>
          {selectedSong ? (
            <div>
              <Typography variant="subtitle1" className={classes.songInfo}>
                {selectedSong.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedSong.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Duration: {selectedSong.duration} sec
              </Typography>
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No song selected
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>setModalOpen(true)}
            className={classes.selectButton}
          >
            {selectedSong ? 'Change Song' : 'Select Song'}
          </Button>
        </CardActions>
      </Card>

      <SelectSoundModal
        open={modalOpen}
        onClose={()=>setModalOpen(false)}
        onSelect={(sound: SoundAttrs) => {
          setEventConfig({ ...eventConfig, parameters: sound });
          setModalOpen(false)
        }}
      />
  </FormControl>
  </>)
}

const SelectVideo = ({classes, eventConfig, setEventConfig,createVideo}:any & {classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const videos:Array<VideoAttrs> = useSelector((state:any) => state.editor?.videos);
  const [selectedVideo, setSelectedVideo] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedVideo(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
    <Card className={classes.card}>
      <CardContent>
        {selectedVideo ? (
          <div>
            <Typography variant="subtitle1" className={classes.songInfo}>
              {selectedVideo.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedVideo.description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Duration: {selectedVideo.duration} sec
            </Typography>
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No video selected
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setModalOpen(true)}
          className={classes.selectButton}
        >
          {selectedVideo ? 'Change Video' : 'Select Video'}
        </Button>
      </CardActions>
    </Card>

    <SelectVideoModal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      onSelect={(video: VideoAttrs) => {
        setEventConfig({ ...eventConfig, parameters: video });
        setModalOpen(false)
      }}
    />

</FormControl>
</>)
}

const SelectWebResource = ({classes, eventConfig, setEventConfig}:any & {classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const web_resources:Array<VideoAttrs> = useSelector((state:any) => state.editor?.web_resources);
  const [selectedWebResource, setSelectedWebResource] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedWebResource(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
    <Card className={classes.card}>
      <CardContent>
        {selectedWebResource ? (
          <div>
            <Typography variant="subtitle1" className={classes.songInfo}>
              {selectedWebResource.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedWebResource.description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              URL: {selectedWebResource.url} sec
            </Typography>
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No web resource selected
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setModalOpen(true)}
          className={classes.selectButton}
        >
          {selectedWebResource ? 'Change Web Resource' : 'Select Web Resource'}
        </Button>
      </CardActions>
    </Card>

    <SelectWebResourceModal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      onSelect={(web_resource: WebResourceAttributes) => {
        setEventConfig({ ...eventConfig, parameters: web_resource });
        setModalOpen(false)
      }}
    />

</FormControl>
</>)
}

export type SpotAttrs = {
  space: SpaceAttributes
  signpost: GlitchSignpost
}

const SelectSpot = ({classes, eventConfig, setEventConfig}:{classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const {game} = useSelector((state:any) => state.editor)

  const [spots, setSpots] = useState<SpotAttrs[]>([]);

  useEffect(() => {
    Games.list_spots(game.uuid).then((spots) => {
      setSpots(spots.data);
    });
  }, []);

  return (<>
  <Typography variant="h6"><T k="actions_config.select_spot.title">Select Spot</T></Typography>
  <FormControl className={classes.formControl} fullWidth>
    <InputLabel id="spot-select-label">Spot</InputLabel>
    <Select labelId="spot-select-label" value={eventConfig.parameters?.signpost?.id || ''} onChange={(event) => setEventConfig({ ...eventConfig, parameters: spots.find(({signpost:{id}})=>id==event.target.value) })}>
      {spots.map((spot) => (
        <MenuItem key={spot.signpost.id} value={spot.signpost.id}>
          {spot.space.name} / {spot.signpost.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</>)
}



const EventActionConfig = ({classes, eventConfig, setEventConfig, onRemove}) => {

  const handleEventChange = (event) => {
    setEventConfig({ ...eventConfig, event: event.target.value });
  };

  const handleActionChange = (event) => {
    setEventConfig({ ...eventConfig, action: event.target.value });
  };

  return (<>
  <Card elevation={6} variant='outlined'>


    <CardHeader title={<T k="actions_config.panel.title">Configure Object Event</T>} titleTypographyProps={{variant:'h6'}}>
    </CardHeader>

    <CardContent>

    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="event-select-label"><T k="actions_config.event.label">Event</T></InputLabel>
      <Select labelId="event-select-label" value={eventConfig.event || ''} onChange={handleEventChange} >
        <MenuItem value={CLICK_EVENT}><T k="actions_config.buttons.click_event">Click</T></MenuItem>
        <MenuItem value={HOVER_EVENT}><T k="actions_config.buttons.hover_event">Hover</T></MenuItem>
        <MenuItem value={COLLISION_START_EVENT}><T k="actions_config.buttons.collision_start">Collision Start</T></MenuItem>
        <MenuItem value={COLLISION_END_EVENT}><T k="actions_config.buttons.collision_end">Collision End</T></MenuItem>
      </Select>
    </FormControl>

    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="action-select-label"><T k="actions_config.action.label">Action</T></InputLabel>
      <Select
        labelId="action-select-label"
        value={eventConfig.action || ''}
        onChange={handleActionChange}
      >
        <MenuItem value={PLAY_SOUND_ACTION}><T k="actions_config.actions.play_sound">Play Sound</T></MenuItem>
        <MenuItem value={APPLY_EFFECT}><T k="actions_config.actions.apply_effect">Apply Effect</T></MenuItem>
        <MenuItem value={PLAY_VIDEO_ACTION}><T k="actions_config.actions.play_video">Play Video</T></MenuItem>
        <MenuItem value={SHOW_WEB_RESOURCE_ACTION}><T k="actions_config.actions.show_web_resource">Show Web Resource</T></MenuItem>
        <MenuItem value={SHOW_TEXT_ACTION}><T k="actions_config.actions.show_text">Show Text</T></MenuItem>
        <MenuItem value={TRAVEL_ACTION}><T k="actions_config.actions.travel">Travel</T></MenuItem>
      </Select>
    </FormControl>

    {eventConfig.action === PLAY_SOUND_ACTION && 
      <SelectSound classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === APPLY_EFFECT && 
      <ConfigureEffect classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === PLAY_VIDEO_ACTION &&
      <SelectVideo classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === SHOW_WEB_RESOURCE_ACTION &&
      <SelectWebResource classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === TRAVEL_ACTION &&
      <SelectSpot classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig} />
      }
    </CardContent>
    <CardActions>
      <Button onClick={onRemove} color="secondary"><T k="actions_config.buttons.remove">Remove</T></Button>
    </CardActions>
    </Card>
  </>)

}

const ActionsConfig = (props:any) => {

  const context = useDesignContext();
  const { selectedObject } = context;
  const [eventActions, setEventActions] = useState(selectedObject?.item?.event_actions || []);
  const classes=useStyles();
  const background_music = useSelector((state:any) => state.editor.background_music);
  const spaceId = useSelector((state:any) => state.editor.spaceId);
  const dispatch=useDispatch();

  const handleSave = () => {
    selectedObject.item = {
      ...selectedObject.item,
      event_actions: eventActions
    };

    saveGame(context.fabricCanvas);
    dispatch(setOpenProperties(false));
  };

  const handleClear = () => {
    setEventActions([]);
  };

  const addEventAction = () => {
    setEventActions([...eventActions, {
      id: v4(),
      event: COLLISION_START_EVENT,
      action: PLAY_SOUND_ACTION,
      parameters: {}
    }]);
  }

  return (<>

  {eventActions.map((eventConfig, index) => (
    <EventActionConfig key={eventConfig.id} eventConfig={eventConfig} classes={classes} setEventConfig={(newConfig) => {
      setEventActions((oldActions) => [...oldActions.slice(0, index), newConfig, ...oldActions.slice(index + 1)]);
    }} onRemove={() => {
      setEventActions((oldActions) => [...oldActions.slice(0, index), ...oldActions.slice(index + 1)]);
    }} />
  ))}

  <div className={classes.buttonGroup}>
    <Button variant="contained" color="primary" onClick={addEventAction}>
      <T k="actions_config.buttons.add">Add</T>
    </Button>
    <Button variant="contained" color="primary" onClick={handleSave}>
      <T k="actions_config.buttons.save_configuration">Save Configuration</T>
    </Button>
    <Button variant="outlined" color="secondary" onClick={handleClear} style={{ marginLeft: 8 }} >
      <T k="actions_config.buttons.clear_configuration">Clear Configuration</T>
    </Button>
  </div>

</>);
}

export default ActionsConfig;
