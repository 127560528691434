import React from 'react';
import { connect } from 'react-redux';
import { SET_PAGE } from 'constants/actionTypes';

export type PaginationInfo = {
  total_entries: number,
  per_page: number,
  current_page: number,
  offset: number,
}

const mapDispatchToProps = dispatch => ({
  onSetPage: (page, payload) =>
    dispatch({ type: SET_PAGE, page, payload })
});

let ListPagination: React.FC<any> = (props) => {

  const pagination:PaginationInfo = props.pagination

  if (pagination.total_entries <= pagination.per_page) {
    return null;
  }

  const range = [];

  for (let i = 0; i < Math.ceil(pagination.total_entries / pagination.per_page); i++) {
    range.push(i+1);
  }

  const setPage = (page) => {
    if(props.pager) {
      props.onSetPage(page, props.pager(page));
    }else {
      props.onSetPage(page, {});
    }
  };

  return (
    <nav>
      <ul className="pagination">

        {
          range.map(v => {
            const isCurrent = v === pagination.current_page;
            const onClick = ev => {
              ev.preventDefault();
              setPage(v);
            };
            return (
              <li
                className={ isCurrent ? 'page-item active' : 'page-item' }
                onClick={onClick}
                key={v.toString()}>

                <a className="page-link" href="">{v}</a>

              </li>
            );
          })
        }

      </ul>
    </nav>
  );
};

ListPagination = connect(() => ({}), mapDispatchToProps)(ListPagination);

export { ListPagination }
