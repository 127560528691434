import React from 'react';
import './brand_nav.scss'
import owl from 'images/glitch/ilmenskie/owl_1.svg';
  import { connect } from 'react-redux';
function BrandNavComponent(props) {
  return <div className="viwoc-brand">
    <div className="logo">
      <img src={owl} className='owl icon'></img>
    </div>

    {props.canEdit ? 
    <a href="#/games" className="home-link">
      <i className="fa fa-chevron-left mr-2"></i>
      Back to games
    </a>
    : 
    <a href={`#/games/${props.game.uuid}`} className="home-link">
      <i className="fa fa-chevron-left mr-2"></i>
      Back to game "{props.game.name}"
    </a>
  }

  </div>
};


const mapStateToProps = ({map, common}, ownProps) => { 
  return {
    ...ownProps, 
    canEdit: map.space?.canEdit,
    game: map.game,
  }
}

const mapDispatchToProps = (dispatch) => ({
});


const BrandNav = connect(mapStateToProps,mapDispatchToProps)(BrandNavComponent)

export default BrandNav;


