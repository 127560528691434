import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Box, IconButton } from '@material-ui/core';
import { CloudUpload, Mic, PlayArrow, Stop } from '@material-ui/icons';
import { DndProvider, useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import {Sounds} from 'api/agent';

const ItemTypes = {
  AUDIO: 'audio',
};

const AudioDropZone: React.FC<{ onDrop: (file: File) => void }> = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.AUDIO, NativeTypes.FILE],
    drop: (item: { files: FileList }) => {
      const file = item.files[0];
      if (file && file.type.startsWith('audio/')) {
        onDrop(file);
      } else {
        alert('Please upload a valid audio file.');
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        padding: 20,
        textAlign: 'center',
        backgroundColor: isOver ? '#e0e0e0' : 'white',
      }}
    >
      <input
        accept="audio/*"
        style={{ display: 'none' }}
        id="audio-file-upload"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file && file.type.startsWith('audio/')) {
            onDrop(file);
          } else {
            alert('Please upload a valid audio file.');
          }
        }}
      />
      <label htmlFor="audio-file-upload">
        <Button variant="contained" component="span" startIcon={<CloudUpload />}>
          Upload Audio
        </Button>
      </label>
      <p>Drag 'n' drop an audio file here, or click to select one</p>
    </div>
  );
};

const AudioUploadForm: React.FC<any> = ({onCreated}:any) => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [uploading, setUploading] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  useEffect(() => {
    if (audioFile) {
      const objectUrl = URL.createObjectURL(audioFile);
      setAudioSrc(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      setAudioSrc(null);
    }
  }, [audioFile]);

  const handleFileUpload = (file: File) => {
    setAudioFile(file);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks: Blob[] = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        setAudioFile(new File([blob], 'recorded_audio.ogg', { type: 'audio/ogg' }));
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const togglePlayback = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSave = async () => {
    if (!audioFile) return;

    setUploading(true);

    try {
      const response = await Sounds.create(prompt('Name?'), audioFile);

      onCreated?.(response);

      console.log('Audio file uploaded successfully!');
      // Optionally reset the form or update state
      setAudioFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: 16 }}>
        <AudioDropZone onDrop={handleFileUpload} />

        <Button
          variant="contained"
          onClick={isRecording ? stopRecording : startRecording}
          startIcon={isRecording ? <Stop /> : <Mic />}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </Button>

        {audioFile && (
          <Box>
            <Typography variant="subtitle1">{audioFile.name}</Typography>
            <audio ref={audioRef} src={audioSrc || ''} controls />
            <Box display="flex" alignItems="center" style={{ gap: 8 }}>
              <IconButton onClick={togglePlayback}>
                {isPlaying ? <Stop /> : <PlayArrow />}
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={uploading}
                startIcon={<CloudUpload />}
              >
                {uploading ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default AudioUploadForm;

