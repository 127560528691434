import { fabric } from 'fabric';

import CanvasController from './CanvasController';
import VideoObject from '../objects/Video';
import { FabricObject, InteractionModes } from '../utils';
import BaseHandler from './BaseHandler';

class ZoomHandler extends BaseHandler {

  constructor(handler: CanvasController) {
    super(handler)
    this.controller = handler;
  }

  /**
   * Zoom to point
   *
   * @param {fabric.Point} point
   * @param {number} zoom ex) 0 ~ 1. Not percentage value.
   */
  public zoomToPoint = (point: fabric.Point, zoom: number) => {
    const { minZoom, maxZoom } = this.controller;
    let zoomRatio = zoom;
    if (zoom <= minZoom / 100) {
      zoomRatio = minZoom / 100;
    } else if (zoom >= maxZoom / 100) {
      zoomRatio = maxZoom / 100;
    }
    this.controller.canvas.zoomToPoint(point, zoomRatio);
    this.controller.getObjects().forEach(obj => {
      if (obj.superType !== 'element')
        return
      const { id, width, height, player } = obj as unknown as VideoObject;
      const el = this.controller.elementHandler.findById(id);
      // update the element
      this.controller.elementHandler.setScaleOrAngle(el, obj);
      this.controller.elementHandler.setSize(el, obj);
      this.controller.elementHandler.setPosition(el, obj);
      if (player) {
        player.setPlayerSize(width, height);
      }
    });

    this.controller.zoom = zoomRatio
    this.controller.frameHandler?.onZoom(zoomRatio);

    if (this.controller.onZoom) {
      this.controller.onZoom(zoomRatio);
    }
    this.controller.canvas.requestRenderAll();
  };

  /**
   * Zoom one to one
   *
   */
  public zoomOneToOne = () => {
    const center = this.controller.canvas.getCenter();
    this.controller.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1);
  };

  public zoomToFitScrolled = () => {
    let scrollCanvas = document.querySelector('.rde-canvas')
    let scaleX = this.controller.canvas.getWidth() / scrollCanvas.clientWidth;
    const scaleY = this.controller.canvas.getHeight() / scrollCanvas.clientHeight;
    if (scrollCanvas.clientHeight >= scrollCanvas.clientWidth) {
      scaleX = scaleY;
      if (this.controller.canvas.getWidth() < scrollCanvas.clientWidth * scaleX) {
        scaleX = scaleX * (this.controller.canvas.getWidth() / (scrollCanvas.clientWidth * scaleX));
      }
    } else {
      if (this.controller.canvas.getHeight() < scrollCanvas.clientHeight * scaleX) {
        scaleX = scaleX * (this.controller.canvas.getHeight() / (scrollCanvas.clientHeight * scaleX));
      }
    }
    const center = this.controller.canvas.getCenter();
    this.controller.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1/scaleX);
  }

  /**
   * Zoom to fit
   *
   */
  public zoomToFit = () => {
    let scaleX = this.controller.canvas.getWidth() / this.controller.workarea.width;
    const scaleY = this.controller.canvas.getHeight() / this.controller.workarea.height;
    if (this.controller.workarea.height >= this.controller.workarea.width) {
      scaleX = scaleY;
      if (this.controller.canvas.getWidth() < this.controller.workarea.width * scaleX) {
        scaleX = scaleX * (this.controller.canvas.getWidth() / (this.controller.workarea.width * scaleX));
      }
    } else {
      if (this.controller.canvas.getHeight() < this.controller.workarea.height * scaleX) {
        scaleX = scaleX * (this.controller.canvas.getHeight() / (this.controller.workarea.height * scaleX));
      }
    }
    const center = this.controller.canvas.getCenter();
    this.controller.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), scaleX);
  };

  /**
   * Zoom in
   *
   */
  public zoomIn = () => {
    if(this.controller.interactionMode == InteractionModes.CROP)
      return;
    let zoomRatio = this.controller.canvas.getZoom();
    zoomRatio += 0.05;
    const center = this.controller.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
  };

  /**
   * Zoom out
   *
   */
  public zoomOut = () => {
    if(this.controller.interactionMode == InteractionModes.CROP)
      return;
    let zoomRatio = this.controller.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.controller.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
  };

  /**
   * Zoom to center with object
   *
   * @param {FabricObject} target If zoomFit true, rescaled canvas zoom.
   */
  public zoomToCenterWithObject = (target: FabricObject, zoomFit?: boolean) => {
    const { left: canvasLeft, top: canvasTop } = this.controller.canvas.getCenter();
    const { left, top, width, height } = target;
    const diffTop = canvasTop - (top + height / 2);
    const diffLeft = canvasLeft - (left + width / 2);
    if (zoomFit) {
      let scaleX;
      let scaleY;
      scaleX = this.controller.canvas.getWidth() / width;
      scaleY = this.controller.canvas.getHeight() / height;
      if (height > width) {
        scaleX = scaleY;
        if (this.controller.canvas.getWidth() < width * scaleX) {
          scaleX = scaleX * (this.controller.canvas.getWidth() / (width * scaleX));
        }
      } else {
        scaleY = scaleX;
        if (this.controller.canvas.getHeight() < height * scaleX) {
          scaleX = scaleX * (this.controller.canvas.getHeight() / (height * scaleX));
        }
      }
      this.controller.canvas.setViewportTransform([1, 0, 0, 1, diffLeft, diffTop]);
      this.zoomToPoint(new fabric.Point(canvasLeft, canvasTop), scaleX);
    } else {
      const zoom = this.controller.canvas.getZoom();
      this.controller.canvas.setViewportTransform([1, 0, 0, 1, diffLeft, diffTop]);
      this.zoomToPoint(new fabric.Point(canvasLeft, canvasTop), zoom);
    }
  };

  /**
   * Zoom to center with objects
   *
   * @param {boolean} [zoomFit] If zoomFit true, rescaled canvas zoom.
   * @returns
   */
  public zoomToCenter = (zoomFit?: boolean) => {
    const activeObject = this.controller.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    this.zoomToCenterWithObject(activeObject, zoomFit);
  };
}

export default ZoomHandler;
