import * as _ from 'underscore';
import { AvatarView } from './avatar_view';
import { Events } from 'maps/map_events';
import { Avatar } from 'models/avatar';
import { ScriptProps, ScriptTarget } from 'models/script';
import { ScriptExecutor } from 'models/script_executor';
import { Tile } from 'models/tile';

class PersonaView extends AvatarView<Avatar> implements ScriptTarget {
  user_uuid: string;
  user: any;
  script_avatars: AvatarView<Avatar>[];
  executor: ScriptExecutor;
  scenes: { [name:string]: ScriptProps };

  tile: Tile;

  recording:any;

  constructor(options?) {
    super(options);

    ({user_uuid: this.user_uuid, isLocal: this.isLocal} = options);
    this.initialize(options);

    if(this.dispatcher)
      this.dispatcher.trigger(Events.INITIALIZE_PERSONAVIEW, {target: this});
  }

  initialize(settings) {
    //super.initialize(settings);
    this.user = settings.user;
    this.callback = this.arrived.bind(this);
    this.script_avatars = [];
    this.scenes = {};
  }

  switch_avatar(backward, whom) {
    const all = [this as AvatarView<Avatar>].concat(...this.script_avatars);
    let i = all.indexOf(whom);
    if (i !== -1) {
      i = (i + (backward ? all.length - 1 : 1)) % all.length;
      all[i].select();
    }
  }

  arrived() {
    this.dispatcher.trigger(Events.ARRIVED, { x: this.left, y: this.top, avatar_view: this });
  }

  say(what, shutupTimeout) {
    if (this.isLocal) {
      this.dispatcher.trigger(Events.LOCAL_SAYS, {
        what: what,
        avatar: this
      });
    }
    return super.say(what, shutupTimeout);
  }

  events() {
    if (this.isLocal) {
      return _.extend(super.events(), {});
    } else {
      return {};
    }
  }

};

export {PersonaView};
