import React from "react";
import {useDesignContext} from "contexts/design_context";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {GlitchLayer} from "components/glitch/space";
import {sortBy} from "lodash";

export default function LayerPlacementTools({close}){

  const {designController, gameData, selectedObject,} = useDesignContext();

  const layers:GlitchLayer[] = Object.keys(gameData?.dynamic?.layers || {}).map(k=>({...gameData.dynamic.layers[k], id: k}));
  const sortedLayers:GlitchLayer[] = sortBy(layers, l=> l.z);

  const [objectLayer, setObjectLayer] = React.useState<string>(selectedObject.layer_id);

  const moveItemToLayer = (layerId:string) => {
    close();
    designController.gameHandler.moveItemToLayer(layerId);
    setObjectLayer(layerId);
  };

  return <List>
    {sortedLayers.map((layer) => {
      return <ListItem key={layer.id} button onClick={(e) => moveItemToLayer(layer.id)} component="a">
        <ListItemText
          primaryTypographyProps={{style: {color: objectLayer === layer.id ? 'blue' : 'black', cursor: 'pointer'}}}>
          {layer.name} 
        </ListItemText>
      </ListItem>
    })}
  </List>
}
