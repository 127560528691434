import React, { Component } from "react"
import { NpcProps, Npc } from "../npc";
import axios from 'axios';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class NpcSearch extends Component<{xs:number,md:number, onClick?:Function}, {npcs: Array<NpcProps>, query:string,loading:boolean,message:string}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}

  cancel:any;

  constructor(props) {
    super(props);

    this.state = {npcs: [], query: '', loading: true, message: ''};
  }
  
  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, npcs: [], loading: false, message: '' } );
    } else {
      this.setState({ query, npcs: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/npcs/search.json?models=Npc&query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? 'There are no more search results. Please try a new search.'
          : '';
        this.setState({
          npcs: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };

  componentDidMount() {
    axios.request<Array<NpcProps>>({url: '/npcs.json'}).then(({data}) =>
      this.setState({ npcs: data })
    );
  }

  render () {
    return (
      <>
        <input className="form-control mb-4" type="text" value={this.state.query} id="search-input" placeholder="Search..." onChange={this.handleOnInputChange} />
        <Row>
          {this.state.npcs.filter(npc => npc.sprites.length && npc.sprites[0]).map(npc => (
            <Col xs={this.props.xs} md={this.props.md} key={npc.id}>
              <Npc {...npc} onClick={(e)=> this.props.onClick(npc)}></Npc>
            </Col>
        ))}
      </Row>
    </>
  )
  }

}

export {NpcSearch}

