import React, { useRef, useState, useEffect } from 'react';
import { fabric } from 'fabric';
import {trace, setParam, getParam} from 'utils/potrace';

const VerticalSobelEdgeDetection: React.FC = () => {
  const [param, setP] = useState({
    threshold:0.625,
    turdSize:3,
    turnPolicy:1,
    alphamax:0.9,
    precision:-1
  });
  const setParameters = (applyTo, newp) => {
    setApply(applyTo);
    setP(newp);
    setParam(newp);
    setTimeout(()=>processImage(imgElement))
  }
  const [applyTo, setApply] = useState<string>('sobel');
  const [image, setImage] = useState<File | null>(null);
  const [imgElement, setImgElement] = useState<HTMLImageElement | null>(null);
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
  const alphaCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const sobelCanvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!fabricCanvasRef.current) {
      fabricCanvasRef.current = new fabric.Canvas('fabric-canvas', {
        enableRetinaScaling: false, // Disable WebGL and retina scaling
      });
    }

    if (image) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imgElement = new Image();
        imgElement.src = event.target?.result as string;
        setImgElement(imgElement)
        imgElement.onload = () => {
          processImage(imgElement);
        };
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  // Function to process the image and display results
  const processImage = (imgElement: HTMLImageElement) => {
    const fabricImage = new fabric.Image(imgElement, {
      crossOrigin: 'anonymous',
    });
    fabricCanvasRef.current?.clear();
    alphaCanvasRef.current?.getContext('2d')?.clearRect(0, 0, alphaCanvasRef.current.width, alphaCanvasRef.current.height);
    sobelCanvasRef.current?.getContext('2d')?.clearRect(0, 0, sobelCanvasRef.current.width, sobelCanvasRef.current.height);

    const canvasElement = imgCanvasRef.current!;
    canvasElement.width = fabricImage.width || 0;
    canvasElement.height = fabricImage.height || 0;
    const context = canvasElement.getContext('2d')!;
    context.drawImage(fabricImage.getElement(), 0, 0);

    const imageData = context.getImageData(0, 0, canvasElement.width, canvasElement.height);
    const pixels = imageData.data;

    // Extract and show the alpha channel
    const alphaData = extractAlphaChannel(pixels, canvasElement.width, canvasElement.height);
    paintAlphaChannelOnCanvas(alphaData, canvasElement.width, canvasElement.height);

    // Apply the Sobel filter and show the result
    const sobelEdges = applyVerticalSobel(alphaData, canvasElement.width, canvasElement.height);
    paintSobelOnCanvas(sobelEdges, canvasElement.width, canvasElement.height);

    let svg;
    if(applyTo=='image')
      svg = trace(imgCanvasRef.current)
    else if(applyTo=='sobel')
      svg = trace(sobelCanvasRef.current)
    else if(applyTo=='alpha')
      svg = trace(alphaCanvasRef.current)

    svg.toPathElements().childNodes.forEach((path: SVGPathElement) => {
      //if(path.classList.contains('odd'))
      //  return ;
      var fabricPath = new fabric.Path(path.getAttribute('d'), {
        fill: '',        // No fill
        stroke: path.classList.contains('odd') ? 'red' : 'blue',      // You can customize the stroke color
        strokeWidth: 2        // You can set the stroke width
      });
      fabricCanvasRef.current?.add(fabricPath);
    })

  };

  // Function to extract the alpha channel
  const extractAlphaChannel = (pixels: Uint8ClampedArray, width: number, height: number): Uint8ClampedArray => {
    const alphaData = new Uint8ClampedArray(width * height * 4); // Same size as image data (RGBA)

    // for each column
    for(let x = 0; x < width; x++) {
      let makeColumnWhite = false;
      // for each row
      for(let y = 0; y < height; y++) {
        const i = (y * width + x) * 4;
        const alphaValue = pixels[i + 3];
        alphaData[i + 3] = 255;
        if(makeColumnWhite){
          alphaData[i] = 255;
          alphaData[i + 1] = 255;
          alphaData[i + 2] = 255;
        }
        if(alphaValue > 50){
          makeColumnWhite = true;
        }
      }
    }

    return alphaData;
  };

  // Function to paint alpha channel on a separate canvas
  const paintAlphaChannelOnCanvas = (alphaData: Uint8ClampedArray, width: number, height: number) => {
    const alphaCanvas = alphaCanvasRef.current;
    if (!alphaCanvas) return;
    const ctx = alphaCanvas.getContext('2d')!;
    const imageData = new ImageData(alphaData, width, height);
    ctx.putImageData(imageData, 0, 0);
  };

  // Vertical Sobel filter implementation
  const applyVerticalSobel = (pixels: Uint8ClampedArray, width: number, height: number): number[] => {
    const sobelX = [-1, 0, 1,
                    -2, 0, 2,
                    -1, 0, 1];
    const sobelY = [-1, -2, -1,
                     0, 0, 0,
                     1, 2, 1];
    const sobelEdges = new Array(width * height).fill(0);

    for (let x = 1; x < width - 1; x++) {
      for (let y = 1; y < height - 1; y++) {
        const idx = (y * width + x);
        let gy = 0;

        for (let dx = -1; dx <= 1; dx++) {
          for (let dy = -1; dy <= 1; dy++) {
            const pixelIdx = ((y + dy) * width + (x + dx)) * 4 + 1; // Red index
            const alphaValue = pixels[pixelIdx];
            gy += sobelY[(dy + 1) * 3 + (dx + 1)] * alphaValue;
            //gy += sobelX[(dy + 1) * 3 + (dx + 1)] * alphaValue;
          }
        }

        // Store the gradient magnitude (just gy here since we are doing vertical detection)
        sobelEdges[idx] = Math.abs(gy);
      }
    }

    return sobelEdges;
  };

  // Function to paint Sobel filter result on a separate canvas
  const paintSobelOnCanvas = (sobelEdges: number[], width: number, height: number) => {
    const sobelCanvas = sobelCanvasRef.current;
    if (!sobelCanvas) return;
    const ctx = sobelCanvas.getContext('2d')!;
    const sobelData = new Uint8ClampedArray(width * height * 4); // RGBA data

    for (let i = 0; i < sobelEdges.length; i++) {
      const value = sobelEdges[i]; // Sobel gradient
      const pixelIndex = i * 4;

      // Set R, G, B channels to the Sobel value to visualize as grayscale
      sobelData[pixelIndex] = value;
      sobelData[pixelIndex + 1] = value;
      sobelData[pixelIndex + 2] = value;
      sobelData[pixelIndex + 3] = 255; // Fully opaque for display
    }

    const imageData = new ImageData(sobelData, width, height);
    ctx.putImageData(imageData, 0, 0);
  };

  const findClosestActivePixel = (pixels, x, y, width,height) => {
    for(let distance = 1; distance < 15; distance++){
      for(let i=x-distance; i< x+distance; i++){
        for(let j=y-distance; j< y+distance; j++){
          const v = j*width+i;
          if(pixels[Math.min(Math.max(v, 0), width*height)] > 0){
            return v;
          }
        }
      }
    }
    return -1;
  }

  // Function to draw a polyline on the fabric canvas
  const drawPolyline = (edgePoints: { x: number; y: number }[]) => {
    const canvas = fabricCanvasRef.current;
    if (!canvas) return;

    // Clear previous drawings
    canvas.clear();

    // Create a new polyline with the edge points
    const polyline = new fabric.Polyline(edgePoints, {
      stroke: 'red',
      strokeWidth: 2,
      fill: 'transparent',
    });

    canvas.add(polyline);
    canvas.requestRenderAll();
  };


  return (
    <div className="row">
      <div className="col-12">
        <h2>Upload a PNG to Visualize Alpha and Sobel Results</h2>
      </div>
      <div className="col-12 col-md-4">
        <input
          type="file"
          accept="image/png"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              setImage(e.target.files[0]);
            }
          }}
        />
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label>Threshold: {param.threshold}</label>
          <input className="form-control" name="param.threshold" type="range" min="0" max="1000" step="1" value={param.threshold*1000} onChange={(e) => setParameters(applyTo,{...param,threshold: parseInt(e.target.value)/1000})} />
        </div>
        <div className="form-group">
          <label>Turd Size: {param.turdSize}</label>
          <input className="form-control" name="param.turdSize" type="range" min="1" max="50" step="1" value={param.turdSize} onChange={(e) => setParameters(applyTo,{...param,turdSize: parseInt(e.target.value)})} />
        </div>
        <div className="form-group">
          <label>Alpha Max: {param.alphamax}</label>
          <input className="form-control" name="param.alphamax" type="range" min="1" max="100" step="1" value={param.alphamax*100} onChange={(e) => setParameters(applyTo,{...param,alphamax: parseInt(e.target.value)/100})} />
        </div>
        <div className="form-group">
          <label>Apply to (checkboxes):</label>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="apply-path" checked={applyTo=='image'} onChange={(e) => setParameters('image', param)} />
            <label className="form-check-label" htmlFor="apply-path">
              Image
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="apply-path" checked={applyTo=='alpha'} onChange={(e) => setParameters('alpha',param)} />
            <label className="form-check-label" htmlFor="apply-path">
              Alphachannel
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="apply-path" checked={applyTo=='sobel'} onChange={(e) => setParameters('sobel',param)} />
            <label className="form-check-label" htmlFor="apply-path">
              sobel
            </label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Path</h3>
            <canvas id="fabric-canvas" width={800} height={600} style={{ border: '1px solid black' }} />
          </div>
          <div className="col-12 col-md-6">
            <h3>Image</h3>
            <canvas ref={imgCanvasRef} width={800} height={600} style={{ border: '1px solid black' }} />
          </div>
          <div className="col-12 col-md-6">
            <h3>Alpha Channel</h3>
            <canvas ref={alphaCanvasRef} width={800} height={600} style={{ border: '1px solid black' }} />
          </div>
          <div className="col-12 col-md-6">
            <h3>Sobel Edge Detection</h3>
            <canvas ref={sobelCanvasRef} width={800} height={600} style={{ border: '1px solid black' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalSobelEdgeDetection;



