import { fabric } from 'fabric';

import { toObject, FabricElement } from '../utils';

export interface IIframeObject extends FabricElement {
  setSource: (source: string) => void;
  setSrc: (src: string) => void;
  src: string;
  iframeElement: HTMLIFrameElement;
}

class IframeObject extends fabric.Rect implements IIframeObject {
  static type= 'iframe'
  static superType= 'element'

  hasRotatingPoint= false
  src:string
  fill:string= 'rgba(255, 255, 255, 0)'
  stroke:string= 'rgba(255, 255, 255, 0)'
  iframeElement: HTMLIFrameElement;
  element:HTMLDivElement
  id:string
  container:HTMLDivElement
  editable:boolean

  //@ts-ignore
  initialize(src: string = '', options: any) {
    options = options || {};
    super.initialize(options);
    //@ts-ignore
    this.set({
      src,
      fill: 'rgba(255, 255, 255, 0)',
      stroke: 'rgba(255, 255, 255, 0)',
    });
  }

  setSource(source: any) {
    this.setSrc(source);
  }

  setSrc(src: string) {
    //@ts-ignore
    this.set({src});
    this.iframeElement.src = src;
  }

  toObject(propertiesToInclude: string[]) {
    return toObject(this, propertiesToInclude, {
      src: this.get('src'),
      container: this.get('container'),
      editable: this.get('editable'),
    });
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render(ctx);
    if (!this.element) {
      const { id, scaleX, scaleY, width, height, angle, editable, src } = this;
      const zoom = this.canvas.getZoom();
      const left = this.calcCoords().tl.x;
      const top = this.calcCoords().tl.y;
      const padLeft = (width * scaleX * zoom - width) / 2;
      const padTop = (height * scaleY * zoom - height) / 2;
      this.iframeElement = fabric.util.makeElement('iframe', {
        id,
        src,
        width: '100%',
        height: '100%',
      }) as HTMLIFrameElement;
      this.element = fabric.util.wrapElement(this.iframeElement, 'div', {
        id: `${id}_container`,
        style: `transform: rotate(${angle}deg) scale(${scaleX * zoom}, ${scaleY * zoom});
                        width: ${width}px;
                        height: ${height}px;
                        left: ${left + padLeft}px;
                        top: ${top + padTop}px;
                        position: absolute;
                        user-select: ${editable ? 'none' : 'auto'};
                        pointer-events: ${editable ? 'none' : 'auto'};`,
      }) as HTMLDivElement;
      //@ts-ignore
      const container = document.getElementById(this.container);
      container.appendChild(this.element);
    }
  }

  static fromObject(options: IframeObject, callback: (obj: IframeObject) => any) {
    //@ts-ignore
    return callback(new fabric.Iframe(options.src, options));
  };

};

fabric.Iframe = fabric.util.createClass(IframeObject, {
  type: IframeObject.type
});

fabric.Iframe.fromObject = IframeObject.fromObject;

declare module 'fabric' {
  namespace fabric {
    class Iframe extends IframeObject {
      constructor(src, options: any)
    }
  }
}
export default fabric.Iframe
