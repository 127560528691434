;(function(Form) {

  Form.editors.Color = Form.editors.Text.extend({
    events: { },

    initialize: function(options) {
      Form.editors.Base.prototype.initialize.call(this, options);

      var schema = this.schema;

      //Allow customising text type (email, phone etc.) for HTML5 browsers
      var type = 'text';

      if (schema && schema.editorAttrs && schema.editorAttrs.type) type = schema.editorAttrs.type;
      if (schema && schema.dataType) type = schema.dataType;

      this.$el.addClass('spectrum');
  }

});

})(Backbone.Form);
