import {Button, Card, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {Games} from 'api/agent';
import { Link } from "react-router-dom";
import {SpotAttrs} from 'components/actions_config';
import {GlitchSignpost} from 'components/glitch/space';
import {T} from 'components/utils/t';
import {useDesignContext} from 'contexts/design_context';
import {groupBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {new_creatix_space_url} from 'utils/urls';
import SpacePreview from './preview';
import {saveGame} from 'components/canvas/Header';

// A Dialog to select a space.
//
type SelectSpaceModalProps = {
  open: boolean;
  onClose: () => void;
  signpost?: GlitchSignpost,
  selectedObject: any
}

const SelectSpaceModal: React.FC<SelectSpaceModalProps> = ({ open, onClose, selectedObject}) => {

  const {game} = useSelector((state: any) => state.editor);
  const [allSpots, setSpots] = useState<SpotAttrs[]>([]);
  const [connected, setConnected] = useState<SpotAttrs|null>(null);
  const {designController, fabricCanvas} = useDesignContext();
  const [doorName, setDoorName] = useState(selectedObject?.item?.name);
  const signpost = selectedObject?.glitch_type == 'signpost' ? selectedObject.item : null;

  const saveAndClose = () => {
    designController.gameHandler.handleSelectDoor(connected, doorName);
    saveGame(fabricCanvas);
    onClose();
  }

  // get the spaces of the current game
  useEffect(() => {
    Games.list_spots(game.uuid).then((spots) => {
      setSpots(spots.data)
    })
  }, [game]);


  useEffect(()=>{
    if(!signpost || !allSpots)return;
    allSpots.find((spot) => {
      if (signpost.connects?.[0]?.signpost_id !== spot.signpost.id)return false;
      setConnected(spot);
      return true;
    })
  },[signpost,allSpots]);

  if(!selectedObject || selectedObject.glitch_type != 'signpost')
    return null;

  const spots = allSpots.filter(({space,signpost}) => signpost.id != selectedObject.item.id)

  const create_space_url = new_creatix_space_url(game.uuid);

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle><T k="spaces.select_space_modal.title">Select a Space</T></DialogTitle>
      <DialogContent>
        <TextField
          label="Door Name"
          value={doorName}
          onChange={(e) => setDoorName(e.target.value)}
          fullWidth
          margin="normal"
        />
        {connected && (
          <div>
            <h4>Current Target Door:</h4>
            <p>
              <b>{connected.signpost.name}</b> in <i>{connected.space.name}</i>
            </p>
          </div>
      )}
      <div className="row">
        {spots.length ?
        Object.entries(groupBy(spots, ({space})=>space.uuid)).map(([uuid,spots]) => {
          return <div className="col-12 col-md-3" key={`container-${uuid}`} >
            <SpacePreview
              space={spots[0].space}
              onClick={() => {}}
              truncNames={20}
              hideActions={true}
              className={connected?.space?.uuid == uuid ? 'border border-primary border-2' : ''}>

              {spots.map(({space,signpost})=> <CardActions>
                <Button key={signpost.id}
                  onClick={() => setConnected({space, signpost})}
                  variant={signpost.id == connected?.signpost?.id ? "contained" : "outlined"}
                  color="primary">
                  {signpost.name || space.name.slice(0,10)}
                </Button>
              </CardActions>
          )}
        </SpacePreview>
      </div>
      })
          :
          <div className="col-12">
            <p>No spaces found</p>
            <Card>
              <CardActions>
                <Link to={create_space_url} className="btn btn-primary">Create a Space</Link>
                <Button onClick={onClose} variant="outlined" color="primary">Cancel</Button>
              </CardActions>
            </Card>
          </div>
        }
  </div>

</DialogContent>

<DialogActions>
  <Button onClick={onClose} variant="outlined" color="primary">Cancel</Button>
  <Button onClick={saveAndClose} variant="outlined" color="primary">Save</Button>
</DialogActions>
  </Dialog>
}

export default SelectSpaceModal;
