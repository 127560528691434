import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './style.scss'

import React, {useState}  from 'react';
import GameBackdrop from "components/shared/backdrop";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Title from './title';
import Intro from './intro';
import Scenario from './scenario';
import Size from './size';
import {new_creatix_space_url} from 'utils/urls';
import {resetState, setCurrentStep, setGameId} from 'redux/reducers/creatix';
import {T} from 'components/utils/t';

/* define a react context that will hold all the onboarding steps: title, scenario, etc */
export const CreatixContext = React.createContext<any>({});

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundImage: `url(/images/ilmenskie.png)`,
    position: 'fixed',
    width: '100%',
    height: '100%'
  }
}));

const Creatix = (props:{ match:any }) => {
  // get the :step param from the url
  let { step, uuid } = props.match.params;
  step = step || 0;
  const history = useHistory();
  const currentStep = useSelector((state:any) => state.creatix.currentStep);
  const gameId = useSelector((state:any) => state.creatix.gameId);
  const background = useSelector((state:any) => state.creatix.background);
  const backdrop = useSelector((state:any) => state.creatix.backdrop);
  const loading = useSelector((state:any) => state.creatix.loading);
  const dispatch = useDispatch();

  const classes = useStyles();

  const logo_url = '/glitch/assets/npc_garden_gnome.svg';

  if(step != currentStep){
    dispatch(setCurrentStep(parseInt(step)));
    if(step == 0)
      dispatch(resetState())

    return null;
  }

  if(!gameId && uuid)
    dispatch(setGameId(uuid));

  const handleNextAndNavigate = (n:number) => {
    dispatch(setCurrentStep(n));
    if(uuid?.length)
      history.push(new_creatix_space_url(uuid, n));
    else
      history.push(`/creatix/${n}`);
  }

  return (<div>
    <div className={`creatix-welcome-bg ${classes.bg}`} style={background ? {backgroundImage: background}:{}}>
	<GameBackdrop open={backdrop}>
          {currentStep == 0 && <Intro idx={0} logo_url={logo_url} handleNext={handleNextAndNavigate}></Intro>}
          {currentStep == 1 && <Title idx={1} handleNext={handleNextAndNavigate}></Title>}
          {currentStep == 2 && <Size idx={2} handleNext={handleNextAndNavigate}></Size>}
          {currentStep == 3 && <Scenario idx={3} handleNext={handleNextAndNavigate}></Scenario>}
          {currentStep == 4 && <Scenario idx={4} handleNext={handleNextAndNavigate}></Scenario>}
        </GameBackdrop>

        {loading && <div style={{position: 'fixed', bottom: '5%', width: '100%'}}>
          <h1 className="text-center"><T k='loading'>Loading...</T></h1>
          <CircularProgress className="d-block mx-auto" size={80}></CircularProgress>
        </div>
        }
    </div>
    </div>);
}

Creatix.sign_in_required = true;
Creatix.sign_in_reason_key = "sign_in.creatix.reason";

export default Creatix;

