import SpacePreview from './preview';
import { Redirect } from "react-router-dom";
import React from 'react';
import {Spaces} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import {DirectUploadProvider} from 'react-activestorage-provider';
import { Games } from 'api/agent'

import * as ActiveStorage from 'activestorage';
import { GameAttributes } from 'models/game';
import GameForm from './form';

type GameNewState = {
  name:string,
  description:string,
  errors:string[],
  created_game?: GameAttributes,
  creation_status?: string,
};

class GameNew extends React.Component<{errors:any[]},GameNewState> {

  static defaultProps = {
  }

  state:GameNewState = {
    name:"",
    description:"",
    errors:null,
    creation_status: null,
  }

  render(){
    if (this.state.created_game) {
      return <Redirect to={`/games/${this.state.created_game.uuid}`} />
    }

    return (<>
    <div className="row">
      <div className="col-md-6 offset-md-3 col-xs-12">
        <h1 className="text-xs-center">New Game</h1>
      </div>
    </div>
    <GameForm submitForm={this.submitForm.bind(this)} errors={this.state.errors} creation_status={this.state.creation_status} className="mt-4"></GameForm>
    </>);
  }

  async submitForm({name, description, clone_space}): Promise<any> {

    const game = { name, description, clone_space_id: clone_space?.uuid};

    this.setState({...this.state, creation_status: 'creating'})

    Games.create(game).then((e) => {
      this.setState({...this.state, created_game:e, creation_status: null});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}


export default GameNew;
