import React from "react";
import { fabric} from "fabric";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import _ from "underscore";
  import { ChromaFrame } from "./chroma";
  import { loadImg, loadFabricImg, opaque } from "shared/util/image-pixels";
export type MyFabricCanvas = fabric.Canvas & {width: number, height: number, pan: boolean, getActiveGroup:Function};


const RED = 0;
const GREEN = 1;
const BLUE = 2;
const ALPHA = 3;

/* Optimizations:
 * * Remove output_canvas (not being used)
 */
class FabricRemover extends React.Component<{frame:ChromaFrame} & React.HTMLAttributes<HTMLDivElement>,{drawing:boolean}> {

  the_canvas: HTMLCanvasElement;

  input_canvas: MyFabricCanvas;
  output_canvas: HTMLCanvasElement;

  background_color: number[];
  foreground_color: number[];

  devicePixelRatio: number = 2;

  masking:boolean = true;
  current_mode: string = 'foreground';
  image_url:string;
  recompute:boolean;
  yax: any;
  status: string;
  mouse_move_handler: (options: any) => void;
  delta_top: any;
  delta_left: any;
  freeDrawingMode: any;
  drawingLineWidth: number;

  canvas_height: number = 100;
  canvas_width: number = 100;
  canvas_wrapper: any;

  constructor(props){
    super(props)
    this.state = {
      drawing: false
    }

    this.background_color = [255, 0, 0];
    this.foreground_color = [ 0, 255, 0];
  }

  toggleDrawing():void{
    this.setState({
      ...this.state,
      drawing: !this.state.drawing
    })
  }

  componentDidUpdate(prevProps) {
    this.input_canvas.isDrawingMode=this.state.drawing
  }

  segment_render ({target})  {
    if (!target.isType('path')) {
      return;
    }
    if (this.masking) {
      this.consolidateMask();
    } else {
      console.log('segment not implemented');
      //throttled_segment();
      //this.render();
    }
  };


  async componentDidMount() {

    this.input_canvas = new fabric.Canvas(this.the_canvas, ({
      width: this.the_canvas.width,
      height: this.the_canvas.height
    } as any)) as MyFabricCanvas;

    this.canvas_width=this.the_canvas.width;
    this.canvas_height=this.the_canvas.height;

    this.output_canvas = $(`<canvas class=output_canvas width=${this.canvas_width * this.devicePixelRatio} height=${this.canvas_height * this.devicePixelRatio}>`)[0] as HTMLCanvasElement;

    this.input_canvas.isDrawingMode = true;

    this.input_canvas.on('object:added', function(o) {
        const target:any=o.target;

        target.selectable = false;
        target.my = target.my || {};
        if (target.my.kind && target.my.ground) {
          return;
        }
        if (this.masking) {
          target.my.kind = 'mask';
          target.my.ground = this.current_mode === 'foreground' ? 'fore' : 'back';
        } else if (target.isType('path')) {
          target.my.kind = 'segment';
        }
      }
    );

    this.input_canvas.on('mouse:down', function({e}) {
        const evt:any = e;
        let clientX = evt.clientX || evt.touches[0].clientX;
        let clientY = evt.clientY || evt.touches[0].clientY;
        if (evt.altKey === true || this.pan) {
          this.isDrawingMode=false;
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = clientX;
          this.lastPosY = clientY;
        } 
        return false;
      })

    this.input_canvas.on('mouse:wheel', function({e}) {
        const evt:any = e;
        if(!evt.ctrlKey == true)
          return

        var delta = evt.deltaY;
        //var pointer = this.getPointer(evt);
        var zoom = this.getZoom();
        zoom = zoom + delta/200;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        this.zoomToPoint({ x: evt.offsetX, y: evt.offsetY }, zoom);
        evt.preventDefault();
        evt.stopPropagation();
        if (zoom < 0.4) {
          this.viewportTransform[4] = 200 - 1000 * zoom / 2;
          this.viewportTransform[5] = 200 - 1000 * zoom / 2;
        } else {
          if (this.viewportTransform[4] >= 0) {
            this.viewportTransform[4] = 0;
          } else if (this.viewportTransform[4] < this.getWidth() - 1000 * zoom) {
            this.viewportTransform[4] = this.getWidth() - 1000 * zoom;
          }
          if (this.viewportTransform[5] >= 0) {
            this.viewportTransform[5] = 0;
          } else if (this.viewportTransform[5] < this.getHeight() - 1000 * zoom) {
            this.viewportTransform[5] = this.getHeight() - 1000 * zoom;
          }
        }
      });


      //'touch:gesture': (e) ->
      //  if e.e.touches and e.e.touches.length == 2
      //    pausePanning = true
      //    point = new fabric.Point(e.self.x, e.self.y)
      //    if e.self.state == 'start'
      //      zoomStartScale = canvas.getZoom()
      //    delta = zoomStartScale * e.self.scale
      //    @zoomToPoint point, delta
      //    pausePanning = false
      //'touch:drag': (e) ->
      //  if pausePanning == false and undefined != e.self.x and undefined != e.self.x
      //    currentX = e.self.x
      //    currentY = e.self.y
      //    xChange = currentX - lastX
      //    yChange = currentY - lastY
      //    if Math.abs(currentX - lastX) <= 50 and Math.abs(currentY - lastY) <= 50
      //      delta = new fabric.Point(xChange, yChange)
      //      canvas.relativePan delta
      //    lastX = e.self.x
      //    lastY = e.self.y
      this.input_canvas.on('mouse:move', function({e}) {
	if (!this.isDragging) {
	  return;
	}
	const ev:any = e;
	var zoom = this.getZoom();
	if (zoom < 0.4) {
	  this.viewportTransform[4] = 200 - 1000 * zoom / 2;
	  this.viewportTransform[5] = 200 - 1000 * zoom / 2;
	} else {
	  this.viewportTransform[4] += ev.clientX - this.lastPosX;
	  this.viewportTransform[5] += ev.clientY - this.lastPosY;
	  if (this.viewportTransform[4] >= 0) {
            //this.viewportTransform[4] = 0;
	  } else if (this.viewportTransform[4] < this.getWidth() - 1000 * zoom) {
	    this.viewportTransform[4] = this.getWidth() - 1000 * zoom;
	  }
	  if (this.viewportTransform[5] >= 0) {
            //this.viewportTransform[5] = 0;
	  } else if (this.viewportTransform[5] < this.getHeight() - 1000 * zoom) {
	    this.viewportTransform[5] = this.getHeight() - 1000 * zoom;
	  }
	}
	this.requestRenderAll();
	this.lastPosX = ev.clientX;
	this.lastPosY = ev.clientY;

	//clientX = opt.ev.clientX || opt.ev.touches[0].clientX;
	//clientY = opt.ev.clientY || opt.ev.touches[0].clientY;
	//this.viewportTransform[4] += clientX - this.lastPosX;
	//this.viewportTransform[5] += clientY - this.lastPosY;
	//this.requestRenderAll();
	//this.lastPosX = clientX;
	//this.lastPosY = clientY;
     })
     this.input_canvas.on('mouse:up', function(opt) {
       this.isDragging = false;
       this.selection = true;
     });
     this.input_canvas.on('object:added', this.segment_render.bind(this));
     this.input_canvas.on('object:removed', this.segment_render.bind(this));
      //'object:selected': this.render.bind(this),
      //'selection:created': this.render.bind(this),
      //'selection:cleared': this.render.bind(this),
      //'selection:updated': this.render.bind(this)
      //});

    this.yax = $('#yaxis').length ? $('#yaxis') : $('<div id=yaxis>').appendTo('body');
    const w = 5;

    $('#yaxis').css({
      border: '1px solid black',
      height: w,
      width: w,
      borderRadius: '50%',
      marginTop: -w / 2,
      marginLeft: -w / 2
    });

    $(window).off('scroll.segment');
    $(window).on('scroll.segment', () => {
      this.delta_left = $(window).scrollLeft();
      this.delta_top = $(window).scrollTop();
    });

    const img = await this.set_image_url(this.props.frame.url);

    await this.setMaskImg(this.props.frame.mask_url || this.props.frame.url, ALPHA, 0);

    //this.input_canvas.forEachObject((obj:any) => {
    //  const top=this.props.frame.top || 0
    //  const left=this.props.frame.left || 0

    //  if(obj.my.kind === 'mask'){
    //    obj.top=top*img.width;
    //    obj.left=left*img.width;
    //  }else if(obj.my.kind === 'source'){
    //    obj.top=top*img.width;
    //    obj.left=left*img.width;
    //  }
    //})

    this.consolidateMask();
  }

  resetMask(){
    this.input_canvas.forEachObject((o:any, i) => { if (o.my.kind === 'mask') this.input_canvas.remove(o)});
  }

  async setMaskImg(mask_url:string, channel:number,value_if_bg:number):Promise<any> {

    const mask = await loadImg(mask_url)

    this.output_canvas.width = this.input_canvas.getWidth() * this.devicePixelRatio;
    this.output_canvas.height = this.input_canvas.getHeight() * this.devicePixelRatio;

    const green = this.output_canvas.getContext('2d').createImageData(this.output_canvas.width, this.output_canvas.height);
    green.data.fill(0);

    const red = this.output_canvas.getContext('2d').createImageData(this.output_canvas.width, this.output_canvas.height);
    red.data.fill(0);

    this.output_canvas.getContext('2d').drawImage(mask, 0, 0, this.output_canvas.width, this.output_canvas.height);
    const imgd = this.output_canvas.getContext('2d').getImageData(0, 0, this.output_canvas.width, this.output_canvas.height);

    for (let i=0, len=imgd.data.length; i < len; i += 4) {
      if (imgd.data[i + channel] === value_if_bg) {
        red.data.set(this.background_color.concat(255), i);
      } else {
        green.data.set(this.foreground_color.concat(255), i);
      }
    }

    this.output_canvas.getContext('2d').putImageData(red, 0, 0);
    const red_mask = await loadFabricImg(this.output_canvas.toDataURL());
    (red_mask as any).my = { kind: 'mask', ground: 'back' };
    red_mask.scaleToWidth(this.input_canvas.getWidth());
    this.input_canvas.add(red_mask);

    this.output_canvas.getContext('2d').putImageData(green, 0, 0);
    const green_mask = await loadFabricImg(this.output_canvas.toDataURL());
    (green_mask as any).my = { kind: 'mask', ground: 'fore' };
    green_mask.scaleToWidth(this.input_canvas.getWidth());
    this.input_canvas.add(green_mask);

    this.rerender([ { kind: 'source' }, 1 ], [ { kind: 'mask' }, .6 ], [ { kind: 'segment' }, 0 ]);
    this.input_canvas.renderAll();
  }

  async set_image_url(url, clear_input = true, clear_output = false) {
    this.image_url = url;

    if (clear_output)
      this.output_canvas.width = this.output_canvas.width;

    if (clear_input) {
      this.input_canvas.clear();
    } else {
      this.input_canvas.getObjects().forEach((o:any) => {if (o.my.kind === 'source') this.input_canvas.remove(o) });
    }
    const img = await loadImg(url);
    await this.appendImage(img);
    this.recompute = true;
    this.setFreeDrawingMode(true, 'foreground');
    this.setDrawingLineWidth(5);
    this.render();
    return img;
  }

  getResult():string[] {

    //this.consolidateMask(false);
    this.rerender([ { kind: 'source' }, 1 ], [ { kind: 'mask' }, 0 ], [ { kind: 'segment' }, 0 ]);
    const url = this.input_canvas.toDataURL();

    const imgd = this.input_canvas.renderAll().getContext().getImageData(0, 0, this.input_canvas.width * this.devicePixelRatio, this.input_canvas.height * this.devicePixelRatio);
    this.rerender([ { kind: 'source' }, 0 ], [ { kind: 'mask' }, 1 ], [ { kind: 'segment' }, 0 ]);
    const mask_url = this.getMask(this.foreground_color.concat(255), imgd);

    return [url,mask_url];
  }

  getMask(rgba:number[], src?, imgd?):string {

    const TOLERANCE = 15;

    if (!imgd) {
      this.input_canvas.renderAll();
      imgd = this.input_canvas.getContext().getImageData(0, 0, this.input_canvas.width * this.devicePixelRatio, this.input_canvas.height * this.devicePixelRatio);
    }

    const dest = this.input_canvas.getContext().createImageData(imgd.width, imgd.height);
    dest.data.fill(0);

    for (let i = 0, len = imgd.data.length; i < len; i += 4) {
      if (rgba.every((x, j) => imgd.data[i + j] > x - TOLERANCE && imgd.data[i + j] < x + TOLERANCE)) {
        if (src) {
          dest.data.set(src.data.slice(i, i + 4), i);
        } else {
          dest.data.set(rgba, i);
        }
      }
    }
    const canvas = $(`<canvas width=${this.input_canvas.width * this.devicePixelRatio} height=${this.input_canvas.height * this.devicePixelRatio}>`)[0] as HTMLCanvasElement;
    canvas.getContext('2d').putImageData(dest, 0, 0);
    return canvas.toDataURL();
  }


  // ground is one of 'fore' or 'back'
  consolidateMask(detransform=true):Promise<HTMLImageElement> {
    const transform = this.input_canvas.viewportTransform.slice(0);
    if(detransform)
      this.input_canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
    this.rerender([ { kind: 'source' }, 1 ], [ { kind: 'mask' }, 0 ], [ { kind: 'segment' }, 0 ]);

    const imgd = this.input_canvas.renderAll().getContext().getImageData(0, 0, this.input_canvas.width * this.devicePixelRatio, this.input_canvas.height * this.devicePixelRatio);
    this.rerender([ { kind: 'source' }, 0 ], [ { kind: 'mask' }, 1 ], [ { kind: 'segment' }, 0 ]);

    const green = this.getMask(this.foreground_color.concat(255));
    const red = this.getMask(this.background_color.concat(255));
    const source = this.getMask(this.foreground_color.concat(255), imgd);

    this.input_canvas.forEachObject((obj:any) => {
      if (obj.my.kind !== 'mask')
        return;

      if (obj.isType('image')) {
        obj.setSrc(obj.my.ground === 'fore' ? green : red);
      } else {
        this.input_canvas.remove(obj);
      }
    });

    if(detransform)
      this.input_canvas.viewportTransform = transform;
    this.rerender([ { kind: 'source' }, 1 ], [ { kind: 'mask' }, .6 ], [ { kind: 'segment' }, 0 ]);

    return new Promise((accept,reject) => {
      const img = new Image();
      img.onload = () => {
        this.output_canvas.width = this.output_canvas.width;
        this.output_canvas.getContext('2d').drawImage(img, 0, 0);
        this.input_canvas.renderAll();
        accept(img)
      };
      img.src = source;
    })
  }

  rerender(...props) {
    this.input_canvas.forEachObject(function(obj:any) {
      const o=_.chain(props).find(([v, k]) => _.isMatch(obj.my, v)).value();
      if (o)
        obj.opacity = o[1];
    });
    this.input_canvas.renderAll();
  }

  async appendImage(img:HTMLImageElement, removeAlpha:boolean=false) {
    const ratio = img.width / img.height;
    const w = this.the_canvas.width;

    let fimg = new fabric.Image(img, {
      left: 0,
      top: 0,
      scaleX: w / img.width,
      scaleY: (w / ratio) / img.height,
      crossOrigin: 'anonymous'
    });

    if(removeAlpha){
      fimg = await opaque(fimg.getElement() as HTMLImageElement);
    }

    //fimg.setCrossOrigin('anonymous');

    (fimg as any).my = {
      kind: 'source',
      ground: true
    };
    this.input_canvas.add(fimg);
    fimg.sendToBack();
    if (this.input_canvas.getObjects().length === 1) {
      this.input_canvas.setWidth(w);
      this.input_canvas.setHeight(w / ratio);
      this.output_canvas.width = w * this.devicePixelRatio;
      this.output_canvas.height = (w / ratio) * this.devicePixelRatio;
      $(this.output_canvas).css({
        width: w,
        height: w / ratio
      });
    }
    this.input_canvas.renderAll();
  }

  getFreeDrawingMode(mode?) {
    if (mode) {
      return this.input_canvas.isDrawingMode && mode === this.current_mode;
    } else {
      return this.input_canvas.isDrawingMode;
    }
  }

  setFreeDrawingMode(drawing:boolean, mode:'foreground'|'background') {
    
    this.input_canvas.isDrawingMode = !!drawing;
    this.input_canvas.freeDrawingBrush.color = mode === 'foreground' ? `rgb(${this.foreground_color.join(',')})` : `rgb(${this.background_color.join(',')})`;

    this.yax.css({
      backgroundColor: this.input_canvas.freeDrawingBrush.color,
      opacity: .5
    });

    if (drawing && mode === 'foreground') {
      this.status = 'Drawing foreground, click segment to update results.';
    } else if (drawing) {
      this.status = 'Drawing background, click segment to update results.';
    }
    if (this.input_canvas.isDrawingMode) {
      this.yax.show();
      this.mouse_move_handler= (options) => {
        this.yax.css({
          'top': options.e.y + this.delta_top,
          'left': options.e.x + this.delta_left
        });
      };
      this.input_canvas.on('mouse:move',this.mouse_move_handler);
    } else {
      this.yax.hide();
      this.input_canvas.off('mouse:move',this.mouse_move_handler);
    }

    this.current_mode = mode;

    this.input_canvas.renderAll();
  }

  getDrawingMode() {
    return this.freeDrawingMode;
  }

  setDrawingMode(type) {
    return this.freeDrawingMode = type;
  }

  getDrawingLineWidth() {
    if (this.input_canvas.freeDrawingBrush) {
      return this.input_canvas.freeDrawingBrush.width;
    }
  }

  addZoom(amount){
    const newZoom = this.input_canvas.getZoom() + amount;
    if (newZoom <= 1) {
      this.input_canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
      this.input_canvas.renderAll();
    } else if (newZoom < 10) {
      this.input_canvas.setZoom(newZoom);
    }
  }

  togglePan(){
    this.input_canvas.isDrawingMode = !this.input_canvas.isDrawingMode;
    this.input_canvas.selection = !this.input_canvas.selection;
    this.input_canvas.pan = !this.input_canvas.pan;

    if (this.input_canvas.pan) {
      $(this.canvas_wrapper).append(`<div class=' btn btn-xs btn-primary pan' style='position: absolute; bottom: 5%; left: 50%; margin-left: -15%;'>Back to edit</div>`);
    } else {
      $(this.canvas_wrapper).find(".pan").remove();
    }
    //this.$('.input-canvas-toolbar .zoom, .input-canvas-toolbar .sizes').css({
    //  opacity: (this.input_canvas.pan ? .5 : 1)
    //});

    $('#yaxis')[this.input_canvas.pan ? 'hide' : 'show']();
  }

  setDrawingLineWidth(value) {
    this.drawingLineWidth = parseFloat(value);
    if (this.input_canvas.freeDrawingBrush) {
      this.input_canvas.freeDrawingBrush.width = this.drawingLineWidth || 1;
    }
  }

  getDrawingLineColor() {
    if (this.input_canvas.freeDrawingBrush) {
      return this.input_canvas.freeDrawingBrush.color;
    }
  }

  setDrawingLineColor(value) {
    if (this.input_canvas.freeDrawingBrush) {
      return this.input_canvas.freeDrawingBrush.color = value;
    }
  }


  render(){
    return (
      <div className="fabric-remover">
        <div className="row fabric-remover">
          <div className="col-12 col-md-6 offset-md-3 canvas-holder mt-3" ref={(c) => this.canvas_wrapper = c}>
            <canvas id="c" ref={node => this.the_canvas = node }/>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-2 offset-md-3">
            <div className="draw draw-background" onClick={(e) => this.setFreeDrawingMode(true,'background')}></div>
            <div className="draw draw-foreground" onClick={(e) => this.setFreeDrawingMode(true,'foreground')}></div>
          </div>

          <div className="col-12 col-md-2 text-center">
            <div className="draw draw-sm" onClick={(e) => this.setDrawingLineWidth(5)}></div>
            <div className="draw draw-md" onClick={(e) => this.setDrawingLineWidth(10)}></div>
            <div className="draw draw-lg" onClick={(e) => this.setDrawingLineWidth(15)}></div>
          </div>

          <div className="col-12 col-md-2 text-right">
            <div className="btn btn-link"><i className="fa fa-arrows-alt" onClick={(e) => this.togglePan()}></i></div>
            <div className="btn btn-link"><i className="fa fa-search-plus" onClick={(e) => this.addZoom(0.1)}></i></div>
            <div className="btn btn-link"><i className="fa fa-search-minus" onClick={(e) => this.addZoom(-0.1)}></i></div>
          </div>

        </div>

      </div>
    )
  }
  
}

export { FabricRemover, loadFabricImg, loadImg }

