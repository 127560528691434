import {createConsumer} from '@rails/actioncable'
import { applyMask } from './frame-tools';

const REMOVER_API= 'https://www.frakie.com/remover/';

export const parseFile = file =>
  new Promise((resolve, reject) => {

    const cable = createConsumer();
    const frames = []

    const params = { channel: "VideoChannel" };

    const subscription = cable.subscriptions.create(params, {
      connected: () => {
        const fileReader = new FileReader()
        fileReader.onload = () =>
          subscription.perform('get_frames', {data_url: fileReader.result})
        fileReader.readAsDataURL(file)
      },
      disconnected: function() {
        console.log('Disconnect');
        resolve(frames)
      },
      rejected: function() {
        console.log('Rejected');
      },
      received: ({action, payload}) => {
        if(action == 'have_frame')
          frames.push(payload.data_url)

        if(action == 'finished'){
          resolve(frames)
          cable.disconnect()
        }
      }
    });

  })

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;
}

export const parseBlobUrl = url =>
  new Promise((resolve, reject) => {
    if(url.match(/^data:/)){
      resolve(dataURItoBlob(url))
    }else{
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = () => resolve(xhr.response)
      xhr.onerror = (e) => reject(e)
      xhr.send()
    }
  })

export const removeBackground = url => new Promise<{mask_url:string}>((accept,reject) => parseBlobUrl(url).then((blob:Blob) => {
    const fd = new FormData();
    fd.append('data', blob, 'image.jpeg');

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if(xhr.readyState == 4 && xhr.status == 200){
        const fr =new FileReader()
        fr.onload = async (e)=> {
          const mask_url = await applyMask(url, e.target.result)
          accept({mask_url})
        }
        fr.readAsDataURL(xhr.response)
      }
    }
    xhr.open('POST', REMOVER_API);
    xhr.responseType = 'blob';
    xhr.send(fd);
  }))

export const trimVideo = (video, start, duration) =>
  new Promise((resolve, reject) => {

    const cable = createConsumer();
    const frames = []

    const params = { channel: "VideoChannel" };

    const subscription = cable.subscriptions.create(params, {

      connected: () => {
        if(video instanceof File){
          const fileReader = new FileReader()
          fileReader.onload = () =>
            subscription.perform('trim_video', {data_url: fileReader.result, start, duration})
          fileReader.readAsDataURL(video)
        } else if(video.file.match(/^blob:/)){
          const xhr = new XMLHttpRequest()
          xhr.open('GET', video.file, true)
          xhr.responseType = 'blob'
          xhr.onload = () => {
            const fileReader = new FileReader()
            fileReader.onload = () =>
              subscription.perform('trim_video', {data_url: fileReader.result, start, duration})
            fileReader.readAsDataURL(xhr.response)
          }
          xhr.send()

        } else if(video.type == 'url' && ['youtube','vimeo'].indexOf(video.fileType) > -1){
          subscription.perform('trim_video', {external_url: video.file, start, duration})
        } else {
          subscription.perform('trim_video', {video_url: video.file, start, duration})
        }
      },
      disconnected: function() {
        console.log('Disconnect');
        resolve({frames})
      },
      rejected: function() {
        console.log('Rejected');
      },
      received: ({action, payload}) => {
        if(action == 'have_frame')
          frames.push(payload.data_url)

        if(action == 'finished'){
          resolve({frames})
          cable.disconnect()
        }
      }
    });

  })


export default {
  parseFile,
  parseBlobUrl,
  trimVideo,
  dataURItoBlob
}
