import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    //width: 300,
  },
});

function valueLabel(value: number, index:number) {
  if(index == 1)
    return `end at ${value}`
  else
    return `start at ${value}`
}
function valueText(value: number) {
  return `${value}s`;
}

type RangeProps = {
  fps:number,
  duration:number,
  maxDuration:number, //seconds
  start:number,
  min: number, // seconds
  max:number, // seconds
  isReady:boolean,
  onStartChange:Function,
  onDurationChange:Function
}

const RangeSlider:React.FC<RangeProps> = ({ fps, duration, start, min, max, isReady, onStartChange, onDurationChange, maxDuration}) => {

  console.log('start,end=',[start,start+duration], [min,max]);

  const classes = useStyles();
  const [value, setValue] = React.useState<number[]>([start, start+duration]);
  const getNFrames = () => value[1]-value[0]

  const handleChange = (event: any, newValue: number[]) => {

    if(newValue[0] != value[0]){
      newValue = [newValue[0], newValue[0] + getNFrames()]
    } else if(newValue[1] != value[1]){
      const max = Math.min(maxDuration, newValue[1]-newValue[0]);
      newValue[1] = newValue[0]+max;
    }
    newValue[1] = Math.min(newValue[1],max);
    setValue(newValue);
    console.log('start,end=',newValue, [min,max]);

    onStartChange(newValue[0])
    onDurationChange(newValue[1]-newValue[0])
  };

  return (
    <div className={classes.root}>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valueText}
        valueLabelFormat={valueLabel}
      />
    </div>
  );
}

 export default RangeSlider;
