import { BaseModel } from "./base_model";

type AnswerAttributes = {
  label: string,
  command: any
}
type QuestionAttributes = {
  id?: string,
  uuid?: string,
  title: string,
  answers: AnswerAttributes[],
  game_id?:string,
}

class Question extends BaseModel {

  constructor(attributes, options) {
    super(attributes,options);
    this.dispatchCreated('Question');
  }

  _className() {
    return "Question";
  }

  initialize(attrs, options) {
    return super.initialize(attrs, options);
  }

  get answers(){
    return this.get('answers')
  }
  get title(){
    return this.get('title')
  }

  url = () => {
    return [(window as any).Niconitos.config.api, "questions", this.id || ''].join('/');
  }

  fullName() {
    return this.get('name')
  }

};

export { Question, QuestionAttributes};
