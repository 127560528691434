import { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from "react";
import axios from 'axios';
import { AvatarCard } from "./avatar_card";
import { PaginationAttrs } from "shared/components/pagination";
import Pagination from '@material-ui/lab/Pagination';
import { AvatarAttributes } from "models/avatar";

class AvatarIndex extends Component<{history:any, match:any, xs:number,md:number, onClick?:Function}, {avatars: Array<AvatarAttributes>, pagination?:PaginationAttrs, query:string,loading:boolean,message:string}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}

  cancel:any;

  constructor(props) {
    super(props);

    this.state = {avatars: [], query: '', loading: true, message: ''};
  }
  
  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, avatars: [], loading: false, message: '' } );
    } else {
      this.setState({ query, avatars: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/avatars/search.json?models=Avatar&query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? 'There are no more search results. Please try a new search.'
          : '';
        this.setState({
          avatars: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };

  componentDidMount() {

    let url;
    if(this.props.match.params.p)
      url = `/avatars.json?page=${this.props.match.params.p}`
    else
      url = `/avatars.json`

    axios.request<Array<AvatarAttributes>>({url}).then(({data, headers}) =>{
      let pagination;
      if(headers['x-pagination']){
        pagination = JSON.parse(headers['x-pagination'])
      }
      this.setState({ avatars: data, pagination })
    });
  }

  render () {
    return (
      <>

      <Row>
        <div className="col-12 col-md-6 offset-md-3 my-4">
          <input
            className="form-control form-control-lg"
            type="text"
            value={this.state.query}
            id="search-input"
            placeholder="Search..."
            onChange={this.handleOnInputChange}
          />
        </div>
      </Row>

      <Row>
        {this.state.avatars.filter(avatar => avatar.sprites.length && avatar.sprites[0]).map(avatar => (
          <Col xs={this.props.xs} md={this.props.md} key={avatar.id}>
            <AvatarCard {...avatar} onClick={(e)=> this.props.onClick(avatar)}></AvatarCard>
          </Col>
        ))}
      </Row>

      <Row>
        {this.state.pagination &&
        <Pagination
          count={~~(this.state.pagination.total_entries/this.state.pagination.offset)}
          color="primary" 
          page={this.state.pagination.current_page}
          onChange={(e,num)=>this.props.history.push(`/avatars/${num}`)} />
        }
      </Row>
    </>
  )
  }

}

export {AvatarIndex}
