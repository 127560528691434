import {MapSize} from 'game/typings/custom'
import Player from './player/player'
import Player2 from './player/player2'

export default class MiniMap {

  camera: Phaser.Cameras.Scene2D.BaseCamera

  constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number, map_size: MapSize) {
    this.camera = scene.cameras
      .add(x, y, width, height)
      .setZoom(1 / 8)
      .setName('mini')
      .setBounds(map_size.x, map_size.y, map_size.width, map_size.height)
      .setBackgroundColor(0x81bdd2ff)
      .setAlpha(0.75)
  }

  setIgnore(gameObject: any[]) {
    gameObject.forEach(obj => {
      this.camera.ignore(obj)
    })
  }

  update(player: Player|Player2) {
    this.camera.scrollX = player.x
    this.camera.scrollY = player.y
  }
}
