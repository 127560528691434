import * as _ from 'underscore';
import { PersonaView } from 'maps/views/persona_view';
import { Avatar, AvatarAttributes } from 'models/avatar';
import { Events } from 'maps/map_events';
import { ModelFetchOptions } from 'backbone';
import { store } from 'redux/store';
import { Avatars } from 'api/agent';
import { MAP_SET_PEOPLE } from 'constants/actionTypes';
import { AvatarHolder } from 'maps/views/avatar_view';

const ActionCable =  require('@rails/actioncable')

class SocketManager implements AvatarHolder {

  people: {[user_uuid:string]:AvatarAttributes} = {};
  avatar_views: PersonaView[];
  dispatcher: Backbone.Events;
  cable: any; // TODO type
  space_id: any;
  room: any;
  uuid: string;

  callbacks:{ [x:string]: Function } = {

    ping: function() {
      this.room.perform('pong');
    },
    become: function(data) {
      var ava = this.avatar_views.find(x => x.user_uuid === data.uuid );
      console.log("Become:", data);
      if (!ava)
        return;
      this.dispatcher.trigger(Events.TRANSMUTE, {
        id: data.avatar_id,
        target: ava
      });
    },
    leave: function(data) {
      console.log("Left:", data);
      const idx = this.avatar_views.findIndex(x => x.user_uuid === data.uuid )
      if (idx==-1) return;
      this.avatar_views[idx].remove();
      this.avatar_views.splice(idx,1);
      delete this.people[data.uuid];
      store.dispatch({ type: MAP_SET_PEOPLE, people: {...this.people}});
    },
    action: function(data) {
      const name = data.name;
      const settings = JSON.parse(data["settings"]);
      if (this.localAvatars.indexOf(name) !== -1) {
        return;
      }
      const ch = null;
      ch.addAction(settings);
      console.log("action:" + name + " settings:" + data["settings"]);
    },
    say: function(data) {
      this.dispatcher.trigger(Events.PERSONA_SAY, data);
    },
    moved: function(data) {
      this.dispatcher.trigger(Events.PERSONA_WALK, data);
    },
    join: async function(data) {
      if (!data.avatar_id) {
        return;
      }

      const ava = await Avatars.show(data.avatar_id)
      ava.user_uuid = data.uuid;
      const mr = new PersonaView({
        user_uuid: data.uuid,
        model: new Avatar(ava, { dispatcher: this.dispatcher }),
        dispatcher: this.dispatcher,
        isLocal: false
      });
      this.dispatcher.trigger(Events.PLACE_AVATAR, {
        who: mr,
        x: data.x,
        y: data.y,
        target: this
      });

      this.people[data.uuid]=ava;

      store.dispatch({ type: MAP_SET_PEOPLE, people: {...this.people}});
    }

  };

  constructor(options) {
    // this will get populated by the controller with the views
    this.avatar_views = [];
    this.dispatcher = options.dispatcher;
    this.dispatcher.trigger(Events.INITIALIZE_SOCKET_MANAGER, { target: this });
    this.cable = ActionCable.createConsumer();
  }

  leave() {
    this.room.unsubscribe();
  }

  setSpace(id) {
    this.space_id = id;
    if (this.room) this.leave();
    this.initialize_room();
  }

  initialize_room() {
    const params = {
      channel: "SpaceChannel",
      space_id: this.space_id
    };

    this.room = this.cable.subscriptions.create(params, {
      connected: function() {
        return console.log('Connected');
      },
      disconnected: function() {
        return console.log('DisConnected');
      },
      rejected: function() {
        return console.log('Rejected');
      },
      received: (data) => {
        console.log('received:', data);
        if (data.action === 'set_uuid') {
          this.uuid = data.payload.uuid;
          this.dispatcher.trigger(Events.SET_UUID, this.uuid);
          return;
        }
        if (data.uuid === this.uuid) return;

        const meth = this.callbacks[data.action];
        if (meth) {
          return meth.apply(this, [data.payload]);
        }
      }
    });
  }

  localBecome(avatar_id) {
    if (!this.room) return;

    this.room.perform('become', {
      avatar_id: avatar_id,
      uuid: this.uuid
    });
  }

  localMoved(x, y, avatar) {
    if (!avatar.isLocal || !this.room)
      return;

    this.room.perform('moved', {
      x: x,
      y: y,
      user_id: avatar.user.id,
      uuid: this.uuid
    });
  }

  localSays(what, avatar) {
    if (!avatar.isLocal || !this.room)
      return;

    this.room.perform("say", {
      message: what,
      user_id: avatar.user.id,
      uuid: this.uuid
    });
  }

  setLocalAvatar(x, y, avatar) {
    if (!avatar.isLocal) {
      return;
    }
    if (!this.room) {
      return;
    }
    const fun = () => {
      if (!this.uuid) {
        setTimeout(fun, 1000);
        return;
      }
      this.room.perform('join', {
        space_id: this.space_id,
        x: x,
        y: y,
        user_id: avatar.user.id,
        avatar_id: avatar.model.id,
        uuid: this.uuid
      });
    };
    return fun();
  }

};


export { SocketManager};
