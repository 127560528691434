import * as _ from 'underscore';
import BaseView from 'views/base_view';
import { Text } from "models/text";

const css_editable = require('modules/css_editable')

class TextView extends BaseView<Text> {

  css_editable = {
    color: "Color",
    backgroundColor: "Color",
    fontSize: "Number"
  };
  toggle_editable: any;
  x: string;
  y: string;
  events() {
    return {
      dblclick: "handle_dblclick",
      click: function(ev) {
        ev.preventDefault();
        return false;
      },
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "text_view";

  template = _.template(`
  <div class="texto">
  <div class="mymodal-header">
    <button type="button" class="close">&times;</button>
    <h4 class="modal-title"><%= model.get("name") %></h4>
  </div>
  <div class="mymodal-body">
    <%= model.get('content') %>
  </div>
</div>
`);

  constructor(options) {
    super(...Array.from(arguments));
    this.dispatchCreated('TextView');
  }

  initialize(settings) {}

  handle_dblclick(ev) {
    this.toggle_editable(true);
    ev.preventDefault();
    return false;
  }

  render() {
    var css;
    css = _.extend({
      position: "absolute",
      left: this.x + "px",
      top: this.y + "px",
      width: "300px",
      height: "200px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }, this.model.get("css"));
    this.$el.css(css).html(this.template(this));
    return this;
  }

};

_.extend(TextView.prototype, css_editable);

export {TextView};
