import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import VideoPreview from './preview/video2';
import { parseUrl } from 'shared/components/inputs/url-input';
import RangeSlider from 'shared/components/inputs/range-slider';
import { throttle } from 'underscore';
import { secondsToTime } from 'shared/util/numbers';
import { CREATOR_SET_TRIM, CREATOR_SET_FRAMES, CREATOR_START_REMOVE_BG, CREATOR_REMOVED_BG } from 'constants/actionTypes';
import { push } from 'connected-react-router';
import { trimVideo } from 'shared/util/video-parse';

export const FPS = 15;
const MAX_FRAMES = 60;
const DEFAULT_FRAMES = 2 * FPS;
const SLIDE_DELAY = 800;
const PREVIEW_WIDTH = 400;

const checkIfVideoEmbed = ({ type, fileType }) =>
  type === 'url' && (fileType === 'youtube' || fileType === 'vimeo');

const extractTime = ({ type, fileType, file: sourceUrl }) => {
  if (type !== 'url') return null;

  const url = new URL(sourceUrl);

  if (fileType === 'youtube') {
    return parseInt(url.searchParams.get('t') || '0');
  } else if (fileType === 'vimeo') {
    return parseInt(
      url.hash
        .slice(1)
        .split('&')
        .reduce((acc, x) => ({ ...acc, [x.split('=')[0]]: x.split('=')[1] }), {})['t'] || '0'
    );
  }
};

const Trimmer = ({
  match,
  isProcessing = false,
  isCompact = false,
  onChange,
  onReady,
  onFinish,
  startFrame: propsStartFrame,
  nframes: propsNframes,
  file: propsFile,
  history,
  children
}) => {
  const [file, setFile] = useState(null);
  const [startFrame, setStartFrame] = useState(0);
  const [nframes, setNframes] = useState(DEFAULT_FRAMES);
  const [maxFrames, setMaxFrames] = useState(MAX_FRAMES);
  const [pauseFrame, setPauseFrame] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoEmbed, setIsVideoEmbed] = useState(false);

  const slideTimeout = useRef(null);
  const isReadyRef = useRef(false);

  useEffect(() => {
    let file = propsFile;
    let startFrame = propsStartFrame || 0;
    let nframes = propsNframes || DEFAULT_FRAMES;

    if (match?.params?.url) {
      file = parseUrl(decodeURIComponent(match.params.url));
      if (!file) file = propsFile;
    }

    if (!file) {
      history.push('/creator/upload');
      return;
    }

    const isVideoEmbed = checkIfVideoEmbed(file);

    if (isVideoEmbed) {
      startFrame = extractTime(file) * FPS;
      nframes = DEFAULT_FRAMES;
    }

    if (file instanceof File) {
      // TODO fix this
      (file as any).file = window.URL.createObjectURL(file);
    }

    setFile(file);
    setIsVideoEmbed(isVideoEmbed);
    setStartFrame(startFrame);
    setNframes(nframes);
  }, [propsFile, propsStartFrame, propsNframes, match, history]);

  useEffect(() => {
    if (isReady || startFrame !== 0 || nframes !== DEFAULT_FRAMES) {
      onChange({
        startFrame,
        nframes,
        isVideoEmbed
      });
    }
  }, [startFrame, nframes, isReady, isVideoEmbed, onChange]);

  const startSlideTimeout = () => {
    clearTimeout(slideTimeout.current);

    slideTimeout.current = setTimeout(() => {
      setIsPlaying(nframes > 1);
    }, SLIDE_DELAY);
  };

  const handleStartChange = (newStartFrame) => {
    if (newStartFrame + nframes < maxFrames) {
      setIsPlaying(false);
      setPauseFrame(newStartFrame);
      setStartFrame(newStartFrame);
      startSlideTimeout();
    }
  };

  const handleDurationChange = (newNframes) => {
    const isTooLong = startFrame + newNframes > maxFrames;
    setIsPlaying(false);
    setPauseFrame(startFrame + newNframes);
    setStartFrame(isTooLong ? maxFrames - newNframes : startFrame);
    setNframes(newNframes);
    startSlideTimeout();
  };

  const handleVideoLoaded = (loadedMaxFrames) => {
    if (isReadyRef.current) return;
    isReadyRef.current = true;

    setIsReady(true);
    setIsPlaying(true);
    setNframes(Math.min(nframes, loadedMaxFrames));
    setMaxFrames(loadedMaxFrames);

    if (onReady) onReady(loadedMaxFrames);
  };

  const back = () => {
    history.push('/creator/upload');
  };

  const nextStep = () => {
    onFinish({
      video: file,
      start: startFrame / FPS,
      duration: nframes / FPS
    });
  };

  if (!file) return <div></div>;

  const aspectRatio = file.height / file.width;
  const previewHeight = Math.min(600, PREVIEW_WIDTH * aspectRatio) || 400;

  return (
    <div className="trim-container col-12 col-md-6 offset-md-3" data-loading={!isReady || isProcessing}>
      <div className="trim-preview" style={{ height: previewHeight }}>
        <VideoPreview
          className="trim-frame"
          file={file}
          fps={FPS}
          startFrame={startFrame}
          pauseFrame={pauseFrame}
          duration={nframes}
          height={previewHeight}
          isPlaying={isPlaying}
          isProcessing={isProcessing}
          onReady={handleVideoLoaded}
        />

        <div className="row">
          <div className="col-12">
            Start: {secondsToTime(startFrame / FPS)} Duration: {secondsToTime(nframes / FPS)}
          </div>
        </div>

        <RangeSlider
          duration={nframes}
          min={0}
          max={maxFrames}
          maxDuration={Math.min(MAX_FRAMES, maxFrames)}
          fps={FPS}
          start={startFrame}
          isReady={true}
          onDurationChange={throttle(handleDurationChange, 1000)}
          onStartChange={throttle(handleStartChange, 1000)}
        />

        <div className="btn btn-link float-left" onClick={back}>
          <i className="fa fa-arrow-left"></i>
          Cambiar video
        </div>

        <div className="btn btn-link float-right" onClick={nextStep}>
          Continuar
          <i className="fa fa-arrow-right"></i>
        </div>
      </div>
      {children}
    </div>
  );
};

const mapStateToProps = ({ creator }) => ({
  file: creator.file
});

const mapDispatchToProps = (dispatch) => ({
  onFinish: ({ video, start, duration }) => {
    dispatch({ type: CREATOR_SET_TRIM, start, duration });

    trimVideo(video, start, duration).then(({ frames }) => {
      dispatch({ type: CREATOR_SET_FRAMES, frames });
    });

    dispatch(push('/creator/chroma'));
  },

  onChange: (e) => console.log('Changed', e)
});

export default connect(mapStateToProps, mapDispatchToProps)(Trimmer);

