// [ a  c  e ]   [ x ]   [ax+cy+e]
// [ b  d  f ] x [ y ] = [bx+dy+f]
// [ 0  0  1 ]   [ 1 ]   [0 +0 +1]
// transform: matrix(a,b,c,d,e,f)
//
// Rotation matrix:
// [cosX -sinX]
// [sinX  cosX]

var _T = {
    rotate: function(deg){
        var rad = parseFloat(deg) * (Math.PI/180),
            costheta = Math.cos(rad),
            sintheta = Math.sin(rad);
 
        var a = costheta,
            b = sintheta,
            c = -sintheta,
            d = costheta;
 
        return [
          [a, c, 0],
          [b, d, 0],
          [0, 0, 1]
        ];
     },
 
    skew: function(dx, dy){
        var radX = parseFloat(dx) * (Math.PI/180),
            radY = parseFloat(dy) * (Math.PI/180),
            c = Math.tan(radX),
            b = Math.tan(radY);
 
 
        return [
          [1, c, 0],
          [b, 1, 0],
          [0, 0, 1]
        ];
     },
 
    translate: function(x,y) {
        var e = x || 0,
            f = y || 0;
 
        return [
          [1, 0, e],
          [0, 1, f],
          [0, 0, 1]
        ];
     },
 
    scale: function(x, y){
        var a = x || 0,
            d = y || 0;
 
        return [
          [a, 0, 0],
          [0, d, 0],
          [0, 0, 1]
        ];
     },
    toString: function(m){ return 'matrix('+[m[0][0],m[1][0],m[0][1],m[1][1],m[0][2],m[1][2]].join(',')+')'; },
// [ a  b  c ]   [ r  s  t]   [ar+bu+cx as+bv+cy at+bw+cz ]
// [ d  e  f ] x [ u  v  w] = [dr+eu+fx ds+ev+fy dt+ew+fz ]
// [ g  h  i ]   [ x  y  z]   [gr+hu+ix gs+hv+iz gt+hw+iz ]
 AxB_vector: function (a,b){
               return _(_.zip(a,b))
      .chain()
      .map(function(args){return args[0]*args[1];})
      .inject(function(memo,v){return memo+v;},0)
      .value();
             },

AxB_matrix : function (a,b){
  return [
        [this.AxB_vector(a[0],_(b).pluck(0)),this.AxB_vector(a[0],_(b).pluck(1)),this.AxB_vector(a[0],_(b).pluck(2))],
        [this.AxB_vector(a[1],_(b).pluck(0)),this.AxB_vector(a[1],_(b).pluck(1)),this.AxB_vector(a[1],_(b).pluck(2))],
        [this.AxB_vector(a[2],_(b).pluck(0)),this.AxB_vector(a[2],_(b).pluck(1)),this.AxB_vector(a[2],_(b).pluck(2))]
      ];
},
 
 fromString: function(s){
    var t = /^matrix\((\S*), (\S*), (\S*), (\S*), (\S*), (\S*)\)$/g.exec(s),
        a = parseFloat(!t ? 1 : t[1]),
        b = parseFloat(!t ? 0 : t[2]),
        c = parseFloat(!t ? 0 : t[3]),
        d = parseFloat(!t ? 1 : t[4]),
        e = parseFloat(!t ? 0 : t[5]),
        f = parseFloat(!t ? 0 : t[6]);
 
    return [
      [a, c, e],
      [b, d, f],
      [0, 0, 1]
    ];
 }
};

if(typeof module != 'undefined')
  module.exports = _T
else
  window._T=_T
