import React, { useRef, useState, useEffect } from 'react';
import {trace, setParam } from 'utils/potrace';

const PotraceComponent = () => {
  const [imageFile, setImageFile] = useState(null);
  const [parameters, setParameters] = useState({
    threshold:0.625,
    turdSize:3,
    turnPolicy:1,
    alphamax:0.9,
    precision:-1
  });
  const [svgContent, setSvgContent] = useState('');

  const handleFileDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith('image/')) {
        setImageFile(file);
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type.startsWith('image/')) {
        setImageFile(file);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleParameterChange = (e) => {
    const { name, value } = e.target;
    setParameters((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
	const imageDataUrl = e.target.result;

	// Set parameters
	setParam({
	  turdSize: Number(parameters.turdSize),
	  threshold: Number(parameters.threshold),
	  turnPolicy: parameters.turnPolicy,
	  alphaMax: Number(parameters.alphamax),
	  precision: Number(parameters.precision)
	});

	const img = new Image();
	img.onload = ()=> setSvgContent(trace(img));
	img.src = imageDataUrl as string
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile, parameters]);

  return (
    <div className="container">
      <div
        className="border p-3 mb-3"
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        style={{ textAlign: 'center' }}
      >
        <p>Drag and drop an image file here, or click to select a file.</p>
        <input type="file" accept="image/*" onChange={handleFileChange} />
      </div>

      <form className="mb-3">
        <div className="mb-3">
          <label className="form-label">turdSize</label>
          <input
            type="number"
            className="form-control"
            name="turdSize"
            value={parameters.turdSize}
            onChange={handleParameterChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">threshold</label>
          <input
            type="number"
            className="form-control"
            name="threshold"
            value={parameters.threshold}
            onChange={handleParameterChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">turnPolicy</label>
          <select
            className="form-select"
            name="turnPolicy"
            value={parameters.turnPolicy}
            onChange={handleParameterChange}
          >
            <option value="3">black</option>
            <option value="1">white</option>
            <option value="2">left</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">alphamax</label>
          <input
            type="number"
            className="form-control"
            name="alphamax"
            value={parameters.alphamax}
            onChange={handleParameterChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">precision</label>
          <input
            type="number"
            className="form-control"
            name="precision"
            value={parameters.precision}
            onChange={handleParameterChange}
          />
        </div>
      </form>

      {svgContent && (
        <div className="border p-3">
          <h3>SVG Output:</h3>
          <div dangerouslySetInnerHTML={{ __html: svgContent }} />
        </div>
      )}
    </div>
  );
};

export default PotraceComponent;

