export const CREATIX_STEP = 'CREATIX_STEP';
export const GAME_WELCOME_STEP = 'GAME_WELCOME_STEP';
export const APP_LOAD = 'APP_LOAD';
export const SET_CSRF = 'SET_CSRF';
export const SET_PAGE = 'SET_PAGE';
export const REDIRECT = 'REDIRECT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOAD_FEATURES = 'LOAD_FEATURES';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED ';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED ';
export const CHANGE_TAB = 'CHANGE_TAB ';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';

export const CREATOR_LOAD_URL = 'CREATOR_LOAD_URL';
export const CREATOR_SET_TRIM = 'CREATOR_SET_TRIM';
export const CREATOR_SET_FRAMES = 'CREATOR_SET_FRAMES';
export const CREATOR_REMOVE_FRAME = 'CREATOR_REMOVE_FRAME';
export const CREATOR_OBJECT_MOVED = 'CREATOR_OBJECT_MOVED';
export const CREATOR_INVERTED_CHANGED = 'CREATOR_INVERTED_CHANGED';
export const CREATOR_VELOCITY_RATIO = 'CREATOR_VELOCITY_RATIO';
export const CREATOR_FINISHED = 'CREATOR_FINISHED';

export const CREATOR_START_REMOVE_BG = 'CREATOR_START_REMOVE_BG';
export const CREATOR_REMOVED_BG = 'CREATOR_REMOVED_BG';

export const MAP_EDIT_ANCHOR = 'MAP_EDIT_ANCHOR';
export const MAP_EDIT_NPC_PLACEMENT = 'MAP_EDIT_NPC_PLACEMENT';
export const MAP_EDIT_SCRIPT = 'MAP_EDIT_SCRIPT';
export const MAP_SET_PEOPLE = 'MAP_SET_PEOPLE';
export const MAP_SET_MUTE = 'MAP_SET_MUTE';
export const MAP_SET_SPACE = 'MAP_SET_SPACE';
export const MAP_SET_MODE = 'MAP_SET_MODE';
export const MAP_SET_AVATAR = 'MAP_SET_AVATAR';
export const MAP_SET_CLICK_AND_MOVE = 'MAP_SET_CLICK_AND_MOVE';
export const MAP_SET_FULLSCREEN = 'MAP_SET_FULLSCREEN';
export const MAP_SET_INVENTORY = 'MAP_SET_INVENTORY';
export const MAP_SHOW_ANCHORS = 'MAP_SHOW_ANCHORS';
export const MAP_SHOW_ZONES = 'MAP_SHOW_ZONES';
export const MAP_SHOW_WEB_RESOURCE = 'MAP_SHOW_WEB_RESOURCE';
export const MAP_SET_RECORDING = 'MAP_SET_RECORDING';
export const MAP_SET_ZOOM = 'MAP_SET_ZOOM';

export const EDITOR_SET_AI_GENERATIONS = 'EDITOR_SET_AI_GENERATIONS';
export const EDITOR_SET_PROMPT_SUGGESTIONS = 'EDITOR_SET_PROMPT_SUGGESTIONS';
