const defaultOption = {
  link: {
  },
  tooltip: {
  },
  animation: {
  },
};

const changeHandler= (selectedItem, changedValues, allValues, canvasRef) => {
  const changedKey = Object.keys(changedValues)[0];
  const changedValue = changedValues[changedKey];

  switch(changedKey){
    case 'width':
    case 'height': {
      canvasRef.current.controller.scaleToResize(allValues.width, allValues.height);
      break;
    }
    case 'angle':{
      canvasRef.current.controller.rotate(allValues.angle);
      break;
    }
    case 'locked':{
      canvasRef.current.controller.setObject({
        lockMovementX: changedValue,
        lockMovementY: changedValue,
        hasControls: !changedValue,
        hoverCursor: changedValue ? 'pointer' : 'move',
        editable: !changedValue,
        locked: changedValue,
      });
      break;
    }
    case 'file':
    case 'src':
    case 'code': {
      if (selectedItem.type === 'image') {
        canvasRef.current.controller.setImageById(selectedItem.id, changedValue);
      } else if (selectedItem.superType === 'element') {
        canvasRef.current.controller.elementHandler.setById(selectedItem.id, changedValue);
      }
      break;
    }
    case 'tooltip': {
      const tooltip = Object.assign({}, defaultOption.tooltip, allValues.tooltip);
      canvasRef.current.controller.set(changedKey, tooltip);
      break;
    }
    case 'shadow': {
      if (allValues.shadow.enabled) {
        if ('blur' in allValues.shadow) {
          canvasRef.current.controller.setShadow(allValues.shadow);
        } else {
          //@ts-ignore
          canvasRef.current.controller.setShadow({
            enabled: true,
            blur: 15,
            offsetX: 10,
            offsetY: 10,
          } as any);
        }
      } else {
        canvasRef.current.controller.setShadow(null);
      }
      break;
    }
    case 'fontWeight': {
      canvasRef.current.controller.set(changedKey, changedValue ? 'bold' : 'normal');
      break;
    }
    case 'fontStyle': {
      canvasRef.current.controller.set(changedKey, changedValue ? 'italic' : 'normal');
      break;
    }
    case 'textAlign': {
      canvasRef.current.controller.set(changedKey, changedValue);
      break;
    }
    case 'filters': {
      const filterKey = Object.keys(changedValue)[0];
      const filterValue = allValues.filters[filterKey];
      switch(filterKey){
        case 'gamma': {
          const rgb = [filterValue.r, filterValue.g, filterValue.b];
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, { gamma: rgb, });
          break;
        }
        case 'brightness': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, { brightness: filterValue.brightness, });
          break;
        }
        case 'contrast': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, { contrast: filterValue.contrast, });
          break;
        }
        case 'saturation': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            saturation: filterValue.saturation,
          });
          break;
        }
        case 'hue':{
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            rotation: filterValue.rotation,
          });
          break;
        }
        case 'noise': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            noise: filterValue.noise,
          });
          break;
        }
        case 'pixelate': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            blocksize: filterValue.blocksize,
          });
          break;
        }
        case 'blur': {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            blur: filterValue.blur,
          });
          break;
        }
        default: {
          canvasRef.current.controller.imageHandler.applyFilterByType(filterKey, changedValue[filterKey]);
          break;
        }
      }
      break;
    }
    default: {
      const replaceColor = changedKey.match(/replaceColors\[([^\]]*)\]/)
      if(replaceColor){
        console.log(`replacing ${replaceColor[1]} for ${changedValue}`)
      }else{
        canvasRef.current.controller.set(changedKey, changedValue);
      }
    }
  }
  canvasRef.current.canvas.requestRenderAll();
};

const workareaChangeHandler=(changedKey, changedValue, allValues, canvasRef) => {
  switch(changedKey){
    case 'layout':{
      canvasRef.current.controller.workareaHandler.setLayout(changedValue);
      break;
    }
    case 'file' :
    case 'src': {
      canvasRef.current.controller.workareaHandler.setImage(changedValue);
      break;
    }
    case 'backgroundColor':{
      canvasRef.current.controller.workareaHandler.setBackgroundColor(changedValue);
      break;
    }
    case 'width':
    case 'height': {
      canvasRef.current.controller.originScaleToResize(
        canvasRef.current.controller.workarea,
        allValues.width,
        allValues.height,
      );
      canvasRef.current.canvas.centerObject(canvasRef.current.controller.workarea);
      break;
    }
    default: {
      canvasRef.current.controller.workarea.set(changedKey, changedValue);
    }
  }
  canvasRef.current.canvas.requestRenderAll();
}

export {changeHandler, workareaChangeHandler}


