import {GlitchDeco, GlitchGame, GlitchSignPoint} from 'components/glitch/space'
import {effects} from 'game/effects'
import {APPLY_EFFECT, PLAY_SOUND_ACTION, SHOW_WEB_RESOURCE_ACTION, PLAY_VIDEO_ACTION, TRAVEL_ACTION, CLICK_EVENT, HOVER_EVENT, COLLISION_START_EVENT, COLLISION_END_EVENT} from 'game/game_constants'
import MainScene, {CATEGORY_PLATFORM} from 'game/scenes/mainScene'
import {SpaceAttributes} from 'models/space'
import {getGameData} from 'packs/game'
import Phaser from 'phaser'
import Decorable from './decorable'
import PlatformLine from './platform_line'
import {nextLevel, SignPostable, Spot} from './sign_post'

export const performAction = (deco:Deco|PlatformLine, action: string, parameters: any, offsets:[number,number]) => {

  const scene = deco.scene as MainScene

  if(action == PLAY_SOUND_ACTION){
    const sound = scene.sound.add(parameters.id)
    sound.play()
    return;
  }

  if(action == APPLY_EFFECT){
    effects[parameters.effect](scene, deco)
    return;
  }

  if(action == PLAY_VIDEO_ACTION){
    if(deco.isPlaying)
      return;
    deco.isPlaying = true;
    console.log(action,parameters)
    const video = document.createElement('video');

    video.playsInline = true;
    video.src = parameters.url;
    video.width = 800;
    video.height = 450;
    video.autoplay = true;

    const element = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY), video);
    element.setVisible(true);
    element.setDepth(1000);
    element.setOrigin(0.5, 0.5);

    video.addEventListener('ended', (event) => {
      element.setVisible(false);
      deco.isPlaying=false;
    });
    return;
  }

  if(action == SHOW_WEB_RESOURCE_ACTION){
    if(deco.isPlaying)
      return;
    deco.isPlaying = true;
    console.log(action,parameters)
    const iframe = document.createElement('iframe');

    iframe.src = parameters.url;
    iframe.width = "800";
    iframe.height = "450";

    const element = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY), iframe);
    element.setVisible(true);
    element.setDepth(1000);
    element.setOrigin(0.5, 0.5);

    return;
  }


  if(action == TRAVEL_ACTION){
    if(!parameters?.space?.uuid) return

    if(scene.startTime && (scene.time.now - scene.startTime) < 5000) return;

    scene.player.halt();

    getGameData(parameters.space.uuid).then(([location_json, space]) => {
      nextLevel(scene, {
        nextGame: location_json,
        nextSpace: space,
        nextSpot: [parameters.signpost.x+offsets[0], parameters.signpost.y+offsets[1]],
        _loadNextLevel: true
      })
    }).catch(error => {
      console.error('Error:', error)
    })
    return;
  }
}


export default class Deco extends Decorable implements SignPostable{

  public signpoint: GlitchSignPoint
  public nextGame: GlitchGame
  public nextSpace: SpaceAttributes
  public nextSpot: Spot
  public deco: GlitchDeco

  collisionHandlers: Array<any> = [];
  collisionEndHandlers: Array<any> = [];
  isPlaying: any

  constructor(scene: Phaser.Scene, deco: GlitchDeco, offset_x: number, offset_y: number) {
    super(scene, deco, offset_x, offset_y);

    this.deco = deco;

    if(window.location.toString().match(/dev.viwoc.com/)){
      this.setInteractive()

      this.on('pointerdown', () => {
        console.log('pointerdown', this)
      })
    }

    this.doPosition();

    if(deco.signpoint) {
      this.signpoint=deco.signpoint;
      this.setInteractive()
      //this.setStatic(true)

      const {tsid:_tsid, space_id, x,y} = this.signpoint;
      const tsid = _tsid.length < 36 ? _tsid.replace(/^L/, 'G') : _tsid

      getGameData(space_id || tsid).then(([location_json, space]) => {
        this.nextGame = location_json
        this.nextSpace = space
        this.nextSpot = [x+offset_x, y+offset_y]
      }).catch(error => {
        console.error('Error:', error)
      })
    }

    (deco.event_actions||[]).forEach(({event,action,parameters}) => {
      if(event == CLICK_EVENT){
        this.setInteractive();
        this.on('pointerdown', () => {
          performAction(this, action,parameters, [offset_x,offset_y])
        })
      }
      if(event == HOVER_EVENT){
        this.setInteractive();
        this.on('pointerover', () => {
          performAction(this, action,parameters, [offset_x,offset_y])
        })
      }
      if(event == COLLISION_START_EVENT){
        this.setCollisionable();
        this.collisionHandlers.push(({bodyA, bodyB}) => {
          if(bodyA === this.body || bodyB === this.body){
            performAction(this, action,parameters, [offset_x,offset_y])
          }
        })
      }
      if(event == COLLISION_END_EVENT){
        this.setCollisionable();
        this.collisionEndHandlers.push(({bodyA, bodyB}) => {
          if(bodyA === this.body || bodyB === this.body){
            performAction(this, action,parameters, [offset_x,offset_y])
          }
        })
      }
    })

    scene.add.existing(this)
  }

  setCollisionable() {
    const b = this.scene.matter.add.rectangle(this.x, this.y, this.displayWidth, this.displayHeight, {
      isStatic: true,
      isSensor: true,
      label: 'deco',
      collisionFilter: {
        category: CATEGORY_PLATFORM,
      },
      onCollideCallback: ({bodyA, bodyB}) => {
        console.log('AQUI collision', bodyA, bodyB)
        this.collisionHandlers.forEach(handler => handler({bodyA, bodyB}))
      },
      onCollideEndCallback: ({bodyA, bodyB}) => {
        console.log('AQUI collision end', bodyA, bodyB)
        this.collisionEndHandlers.forEach(handler => handler({bodyA, bodyB}))
      }
    });

    // TODO Phaser.GameObjects.Sprite invokes destroy on the body when the sprite is destroyed
    const x = b as any;
    x.destroy = x.destroy || (() => {})

    this.body=b;

  }

  _loadNextLevel: boolean
}

