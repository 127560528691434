import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { WebResources } from 'api/agent';
import { WebResourceAttributes } from 'models/web_resource';

// Import Material-UI components
import {
  TextField,
  Button,
  Typography,
  Container,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: theme.spacing(8),
    },
    form: {
      marginTop: theme.spacing(4),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    errorMessages: {
      marginTop: theme.spacing(2),
      color: theme.palette.error.main,
    },
  })
);

const WebResourceNew: React.FC<any & {onCreated:(wr:WebResourceAttributes)=>void}> = (props) => {
  const classes = useStyles();

  const [name, setName] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const [creationStatus, setCreationStatus] = useState<string | null>(null);

  const submitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const webResource = { name, url, description };

    setCreationStatus('creating');

    try {
      const response = await WebResources.create(webResource);
      setCreationStatus(null);
      props.onCreated?.(response);
    } catch (err) {
      setCreationStatus('error');

      // Extract error messages from the response if available
      if (err.response && err.response.data && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        setErrors(['An unexpected error occurred']);
      }
    }
  };

  return (
    <Container maxWidth="sm" className={classes.formContainer}>
      <Typography variant="h4" align="center">
        New Web Resource
      </Typography>

      {errors && errors.length > 0 && (
        <Typography component="ul" className={classes.errorMessages}>
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </Typography>
      )}

      <form onSubmit={submitForm} className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="URL"
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={creationStatus === 'creating'}
        >
          {creationStatus === 'creating' ? 'Creating...' : 'Create Web Resource'}
        </Button>
      </form>
    </Container>
  );
};

export default WebResourceNew;

