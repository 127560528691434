import React from 'react';
import { connect } from 'react-redux';
import { MAP_SET_MODE, MAP_SET_FULLSCREEN, MAP_SET_ZOOM, MAP_SET_CLICK_AND_MOVE, MAP_SHOW_ZONES } from 'constants/actionTypes';
import './map_menu.scss'

const MapMenuComponent = (props) => {
  return <div className="map-menu">
    <ul className="list-inline">
      <li className='list-inline-item'>
        <a className="nav-link click-and-move" onClick={e => props.onClickAndMove(! props.clickAndMove)}>
          {props.clickAndMove ?
          <i className="fal fa-map-marked-alt"  style={{color:"red" }}></i>
            :
            <i className="fal fa-map-marked-alt" style={{color:"black"}}></i>
          }
        </a>
      </li>

      <li className='list-inline-item'>
        <a className="nav-link fullscreen" onClick={e => props.onFullscreen(! props.fullscreen)}>
          {props.fullscreen ?
          <i className="fal fa-compress-arrows-alt"></i>
            :
            <i className="fal fa-expand-arrows"></i>
          }
        </a>
      </li>

      <li className='list-inline-item'>
        <a className="nav-link zoom-in" onClick={e => props.onZoom(props.zoom-0.1)}>
          <i className="fal fa-search-minus"></i>
        </a>
      </li>

      <li className='list-inline-item'>
        <a className="nav-link zoom-out" onClick={e => props.onZoom(props.zoom+0.1)}>
          <i className="fal fa-search-plus"></i>
        </a>
      </li>
    </ul>
  </div>
}

const mapStateToProps = ({map, common}) => ({
  clickAndMove: map.clickAndMove,
  fullscreen: map.fullscreen,
  show_zones: map.show_zones,
  zoom: map.zoom,
  currentUser: common.currentUser,
})

const mapDispatchToProps = (dispatch) => ({

  onClickAndMove: (clickAndMove) => dispatch({ type: MAP_SET_CLICK_AND_MOVE, clickAndMove }),

  onFullscreen: (fullscreen) => dispatch({ type: MAP_SET_FULLSCREEN, fullscreen }),

  onZones: (show_zones) => dispatch({ type: MAP_SHOW_ZONES, show_zones }),

  onZoom: (level) => dispatch({ type: MAP_SET_ZOOM, zoom: level }),

});

const MapMenu = connect(mapStateToProps,mapDispatchToProps)(MapMenuComponent);

export {MapMenu}
