import Phaser from 'phaser'
import {GlitchDeco, GlitchGame, GlitchSignPoint} from 'components/glitch/space';
import {SpaceAttributes} from 'models/space';
import {AvatarAttributes} from 'models/avatar';
import {LoadingCallbacks, ViwocScene} from 'packs/game';

type PreloadProps = {
  gameData: GlitchGame,
  space?: SpaceAttributes,
  avatar?:AvatarAttributes,
  path_template:string,
  spot: GlitchSignPoint,
  loadingCallbacks: LoadingCallbacks
};

export default class PreloadScene extends Phaser.Scene implements ViwocScene {

  avatar:AvatarAttributes
  path_template: any;
  spot: GlitchSignPoint;
  
  gameData:GlitchGame
  space: SpaceAttributes
  loadingCallbacks: LoadingCallbacks;

  constructor() {
    super({ key: 'PreloadScene' })
  }

  init({gameData, space, avatar, path_template, spot, loadingCallbacks}: PreloadProps) {

    this.loadingCallbacks=loadingCallbacks;

    if(avatar)
      this.avatar=avatar;

    this.spot=spot;

    if(space){
      this.space = space;
      this.gameData = space.location.metadata;
      this.path_template=path_template;
    } else {
      this.gameData = gameData;
    }
  }

  preloadGraphics(){
    this.load.on('progress', (value) => {
      this.loadingCallbacks.setProgress(value);
    });

    // Remove progress bar when loading is complete
    this.load.on('complete', () => {
      this.loadingCallbacks.setLoading(false);
      this.scene.start('MainScene', {
        gameData: this.gameData,
        space: this.space,
        spot: this.spot,
        path_template: this.path_template,
        avatar: this.avatar, 
        loadingCallbacks: this.loadingCallbacks})
    });
  }

  async loadAudioURL(url: string, key: string){
    try {
      const binkey = `bin-${key}`;

      this.load.binary(binkey, url, Uint8Array);

      this.load.on(`filecomplete-binary-${binkey}`, (k, type, data) => {
        const blob = new Blob([data]);
        const blobUrl = URL.createObjectURL(blob);
        console.log('Blob URL:', blobUrl);
        this.load.audio(key, blobUrl)
        this.load.off(`filecomplete-binary-${binkey}`);
      });
    } catch (error) {
      console.error('Error fetching and creating Blob URL:', error);
    }
  }

  preload() {
    this.preloadGraphics();
    this.loadingCallbacks.setLoading(true);

    ['controls.png', 'goal.png'].forEach(img => { this.load.image(img.split('.')[0], `/game/${img}`) })

    if(this.avatar){
      this.avatar.sprites.forEach((sprite, i) => {
        this.load.aseprite(`avatar-${i}`, sprite.public_filename, sprite.aseprite_url)
      })
    }

    if(this.gameData.background_music){
      //this.load.audio('bgMusic', this.gameData.background_music.url.replace('http://', 'https://'))
      this.sound.removeByKey('bgMusic');
      this.cache.audio.remove('bgMusic');
      this.loadAudioURL(this.gameData.background_music.url.replace('http://', 'https://'), 'bgMusic')
    }

    if(this.gameData.background_image){
      this.textures.remove('bgImage')
      this.load.image('bgImage', this.gameData.background_image.url.replace('http://', 'https://'))
    }

    // Process sprites and event_actions for each deco
    Object.values(this.gameData.dynamic.layers).map(l=>l.decos).flat().forEach((deco)=>{
      if(deco.sprites){
        deco.sprites.forEach((sprite, i) => {
          this.load.aseprite(`${deco.filename}-${i}`, sprite.public_filename, sprite.aseprite_url)
        })
      }

      if(deco.event_actions){
        deco.event_actions.forEach(ea => {
          if(ea.action == 'play-sound'){
            this.loadAudioURL(ea.parameters.sound_url.replace('http://', 'https://'),ea.parameters.id)
          }
          if(ea.action == 'play-video'){
            this.load.video(ea.parameters.id || ea.parameters.uuid, ea.parameters.video_url.replace('http://', 'https'))
          }
        })
      }
    })

    // Now, deduplicate all the Decos by filename, find the biggest versions of each SVG because they get rendered to bitmap and 
    // we need to find the biggest version.
    const decos=Object.values(this.gameData.dynamic.layers).map(l=>l.decos).flat().reduce((acc, deco)=>{
      if(!acc[deco.filename] || acc[deco.filename].w < deco.w)
        acc[deco.filename] = deco
      return acc
    }, {});

    // Now that it's deduped, load all the images
    Object.values(decos).forEach((deco:GlitchDeco)=>{
      if(deco.url){
        this.load.image(deco.filename, deco.url.replace('http://', 'https://'))
      } else {
        this.load.svg(deco.filename, '/glitch/assets/' + deco.filename + '.svg', {width: deco.w, height: deco.h})
      }
    })

    Object.values(this.gameData.dynamic.layers).map(l=>l.signposts).flat().forEach((signpost)=>{
      if(!signpost.url) return 

      if(signpost.url.match(/\.svg$/)){
        this.load.svg(signpost.filename, signpost.url.replace('http://', 'https://'), {width: signpost.w, height: signpost.h})
      } else {
        this.load.image(signpost.filename, signpost.url);
      }
    });

  }

  async create() {
    //this.textures.addBase64('black_pixel', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/w8AAwAB/7+o9gAAAABJRU5ErkJggg==')

    //this.scene.start('MainScene', {gameData: this.gameData, space: this.space, path_template: this.path_template, avatar: this.avatar})
  }
}
