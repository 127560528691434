import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CSRF, APP_LOAD, LOAD_FEATURES, REDIRECT } from 'constants/actionTypes';
import Landing1 from '../components/Home/landing1';
import { push } from 'react-router-redux';
import { Auth } from 'api/agent';
import { store } from 'redux/store';
import Header from './shared/Header';
import Login from './account/Login';
import Register from './account/Register';
import GameWelcome from './game_welcome';
import Creatix from './creatix';
import { AvatarIndex } from './avatars';
import { ArtworkIndex } from './artwork';
import { NpcIndex } from './npcs';
import { ClazzIndex } from './clazzes';
import { SoundIndex } from './sounds';
import SoundNew from './sounds/new';
import { PictureIndex } from './pictures';
import { VideoIndex } from './videos';
import VideoNew from './videos/new';
import { TextIndex } from './texts';
import TextNew from './texts/new';

import { WorldMap } from './world/map';
import ScriptList from './scripts/list';
import SpaceList from './spaces/list';
import SpaceNew from './spaces/new';
import WebResourceList from './web_resources/list';
import WebResourceNew from './web_resources/new';
import WebResourceEdit from './web_resources/edit';
import WebResourceShow from './web_resources/show';
import GameList from './games/list';
import GameShow from './games/show';
import GameNew from './games/new';
import { ScriptShow } from './scripts/show';
import { GlitchSpace } from './glitch/locations/space'
import ErrorBoundary from './ErrorBoundary';
import GlitchHtml from './glitch/html_space';
import { FabricGlitch } from './glitch/fabric';
import GameEdit from './games/edit';
import ClazzNew from './clazzes/new';
import BrowserVoiceRecorder from './recorder';

import GlitchEditor from './glitch_editor';
import {GlitchEditorSingle} from './glitch/editor_single';
import {GlitchEditorPower} from './glitch/editor_power';
import { GlitchEditorOld } from './glitch/editor_old';

import GameComponent from 'game/component';
import SearchkitTest from './searchkit_test';
import GlitchAssetsList from './glitch/assets/list';

import CreatorComponent from './creator/Creator';
import AvatarShow from './avatars/show';
import VerticalSobelEdgeDetection from './edge_detection';
import DepthMapComponent from './depth_map';
import {getFeatures} from 'utils/flipper';
import PotraceComponent from './pruebas/potrace';
import ProtectedRoute from './ProtectedRoute';

function withDiv(WrappedComponent, classNames) {
  return function(props) {
    return (
      <>
        <Header />
        <div className={classNames}>
          <WrappedComponent {...props}/>
        </div> 
      </>
    );
  };
}

const withLogin = (WrappedComponent, reason_key)  => {
  const currentUser = useSelector((state:any) => state.common.currentUser);

  return (props) => {
    if(currentUser){
      return <WrappedComponent {...props}/>
    }else{
      return <Redirect to={{ pathname: '/login', state: { from: props.location, reason_key }, }} />
    }
  }

}

function withHeader(WrappedComponent) {
  return function(props) {
    return (
      <>
        <Header />
        <WrappedComponent {...props}/>
      </>
    );
  };
}

function withErrorBoundary(WrappedComponent) {
  return function(props) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props}/>
      </ErrorBoundary>
    );
  };
}

/* begin
 * This is for WorldMap */
window.Backbone = require('backbone');
window._ = require('underscore');

require('../../../vendor/assets/javascripts/backbone-forms/backbone-forms');
require('../../../vendor/assets/javascripts/backbone-forms/adapters/backbone.bootstrap-modal');
require('../../../vendor/assets/javascripts/backbone-forms/editors/list');
require('backbone-forms/backbone-forms-html');
require('backbone-forms/backbone-forms-file');
require('backbone-forms/backbone-forms-color');
require('backbone-forms/backbone-forms-number');
require('backbone-forms/backbone-forms-json');
/* end */

const Router = () => {
  const dispatch = useDispatch();
  const { appLoaded, redirectTo } = useSelector((state:any) => state.common);

  useEffect(() => {
    const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    dispatch({ type: APP_LOAD, payload: Auth.current(), skipTracking: true, csrf_token, });
    const features = getFeatures();
    dispatch({ type: LOAD_FEATURES, payload: features, skipTracking: true });
  }, []);

  useEffect(() => {
    if (redirectTo) {
      store.dispatch(push(redirectTo));
      dispatch({ type: REDIRECT });
    }
  }, [redirectTo]);

  if (appLoaded) {
    return (
      <div className="router">
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={withHeader(Landing1)}/>

            <ProtectedRoute exact path="/recorder" component={withErrorBoundary(BrowserVoiceRecorder)}/>
            <ProtectedRoute exact path="/searchkit" component={withErrorBoundary(SearchkitTest)}/>

            <ProtectedRoute exact path="/avatars" component={withErrorBoundary(withDiv(AvatarIndex, 'container'))}/>
            <ProtectedRoute path="/avatar/:uuid" component={withErrorBoundary(withDiv(AvatarShow, 'container'))}/>
            <ProtectedRoute path="/avatars/:p" component={withErrorBoundary(withDiv(AvatarIndex, 'container'))}/>

            <ProtectedRoute exact path="/npcs" component={withErrorBoundary(withDiv(NpcIndex, 'container'))}/>
            <ProtectedRoute path="/npcs/:p" component={withErrorBoundary(withDiv(NpcIndex, 'container'))}/>

            <ProtectedRoute exact path="/clazzes" component={withErrorBoundary(withDiv(ClazzIndex, 'container'))}/>
            <ProtectedRoute exact path="/clazzes/new" component={withErrorBoundary(withDiv(ClazzNew, 'container'))}/>
            <ProtectedRoute path="/clazzes/:p" component={withErrorBoundary(withDiv(ClazzIndex, 'container'))}/>

            <ProtectedRoute exact path="/sounds" component={withErrorBoundary(withDiv(SoundIndex, 'container'))}/>
            <ProtectedRoute exact path="/sounds/new" component={withErrorBoundary(withDiv(SoundNew, 'container'))}/>
            <ProtectedRoute exact path="/pictures" component={withErrorBoundary(withDiv(PictureIndex, 'container'))}/>
            <ProtectedRoute exact path="/videos" component={withErrorBoundary(withDiv(VideoIndex, 'container'))}/>
            <ProtectedRoute exact path="/videos/new" component={withErrorBoundary(withDiv(VideoNew, 'container'))}/>

            <ProtectedRoute exact path="/scripts" component={withErrorBoundary(withDiv(ScriptList, 'container'))}/>

            <ProtectedRoute exact path="/glitch" component={withErrorBoundary(GlitchSpace)}/>
            <ProtectedRoute exact path="/glitch/assets" component={withErrorBoundary(GlitchAssetsList)}/>
            <ProtectedRoute exact path="/glitch/:id" component={withErrorBoundary(GlitchSpace)}/>

            <ProtectedRoute exact path="/glitch/edit/:id" component={withErrorBoundary(GlitchEditor)}/>

            <ProtectedRoute exact path="/glitch/power/:id" component={withErrorBoundary(withDiv(GlitchEditorPower, 'glitch-edit'))}/>
            <ProtectedRoute exact path="/glitch/single/:id" component={withErrorBoundary(withDiv(GlitchEditorSingle, 'glitch-edit'))}/>
            <ProtectedRoute exact path="/glitch/old/:id" component={withErrorBoundary(withDiv(GlitchEditorOld, 'glitch-edit'))}/>
            <ProtectedRoute exact path="/glitch/html/:id" component={withErrorBoundary(GlitchHtml)}/>
            <ProtectedRoute exact path="/glitch/fabric/:id" component={withErrorBoundary(FabricGlitch)}/>

            <ProtectedRoute path="/scripts/:uuid" component={withErrorBoundary(withDiv(ScriptShow, 'container'))}/>

            <ProtectedRoute exact path="/texts" component={withErrorBoundary(withDiv(TextIndex, 'container'))}/>
            <ProtectedRoute exact path="/texts/new" component={withErrorBoundary(withDiv(TextNew, 'container'))}/>

            <ProtectedRoute exact path="/artwork" component={withErrorBoundary(withDiv(ArtworkIndex, 'container'))}/>

            <ProtectedRoute path="/creator" component={withDiv(CreatorComponent, 'container')}/>

            <Route exact path="/games" component={withErrorBoundary(withDiv(GameList, 'container'))}/>
            <ProtectedRoute exact path="/games/new" component={withErrorBoundary(withDiv(GameNew, 'container'))}/>
            <ProtectedRoute exact path="/games/:uuid/edit" component={withErrorBoundary(withDiv(GameEdit, 'container'))}/>

            <ProtectedRoute exact path="/web_resources" component={withErrorBoundary(withDiv(WebResourceList, 'container'))}/>
            <ProtectedRoute exact path="/web_resources/new" component={withErrorBoundary(withDiv(WebResourceNew, 'container'))}/>
            <ProtectedRoute exact path="/web_resources/:uuid/edit" component={withErrorBoundary(withDiv(WebResourceEdit, 'container'))}/>
            <ProtectedRoute exact path="/web_resources/:uuid" component={withErrorBoundary(withDiv(WebResourceShow, 'container'))}/>

            <ProtectedRoute path="/games/:uuid/spaces/new" component={withErrorBoundary(withDiv(SpaceNew, 'container'))}/>
            <ProtectedRoute exact path="/games/:game_id/scripts" component={withErrorBoundary(withDiv(ScriptList, 'container'))}/>
            <Route path="/games/:uuid" component={withErrorBoundary(withDiv(GameShow, 'container'))}/>

            <ProtectedRoute exact path="/spaces" component={withErrorBoundary(withDiv(SpaceList, 'container'))}/>

            <Route path="/spaces/play/:uuid/:avatar_id" component={({match}) => (<GameComponent key={match.params.uuid} match={match}/>)}/>
            <Route path="/spaces/play/:uuid" component={({match}) => (<GameComponent key={match.params.uuid} match={match}/>)}/>
            <Route path="/spaces/:uuid/:x,:y" component={({match}) => (<WorldMap key={match.params.uuid} match={match}/>)}/>
            <Route path="/spaces/:uuid" component={({match}) => (<WorldMap key={match.params.uuid} match={match}/>)}/>

            <Route path="/game_welcome/:uuid/:step" component={GameWelcome}/>
            <Route path="/game_welcome/:uuid" component={GameWelcome}/>

            <ProtectedRoute exact path="/creatix/:step" component={Creatix}/>
            <ProtectedRoute exact path="/creatix/space/:uuid/:step" component={Creatix}/>
            <ProtectedRoute exact path="/creatix/space/:uuid" component={Creatix}/>

            <ProtectedRoute exact path="/sobel" component={VerticalSobelEdgeDetection}/>
            <ProtectedRoute exact path="/depth" component={DepthMapComponent}/>
            <ProtectedRoute exact path="/potrace" component={PotraceComponent}/>
            <ProtectedRoute exact path="/depth/:id" component={DepthMapComponent}/>

            <Route path="/login" component={withErrorBoundary(Login)} />
            <Route path="/register" component={withErrorBoundary(Register)} />
            <Route component={withErrorBoundary(Landing1)}/>
          </Switch>
        </Suspense>
      </div>
    );
  } else {
    return (
      <div>
        <Header/>
      </div>
    );
  }
};

export default Router;
