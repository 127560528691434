import React, {useEffect, useState} from 'react';
import { fabric } from 'fabric';
import { GlitchGame, GlitchLayer, GlitchDeco, GlitchWall, GlitchPlatformLine, GlitchLadder} from './space';
import { sortBy} from 'lodash';
import { CUSTOM_PROPERTIES, deco2Fabric, getDecoImageUrl, highlightSelectedObject, loadFabricImage2, renderLayer, serializeItems } from './utils';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Spaces, GlitchLocations } from 'api/agent';
import { isAdmin, UserAttributes } from 'models/user';
import { connect } from 'react-redux';
import BlendImage2 from '../../utils/fabric_outline_filter';
import SpotlightSearch from './spotlight_search';
import {stageCSS} from './utils_html';
import {play_game_url} from 'utils/urls';

export const clone:(obj:fabric.Object)=>Promise<fabric.Object> = async (obj) => {
  return new Promise((accept,reject) => {
    obj.clone((c) => { accept(c) }, CUSTOM_PROPERTIES)
  })
}

export const layerStyle = (layer) => {
  return {
    position: 'absolute',
    width: layer.w,
    height: layer.h,
    left: 0,
  } as any;
}


export const findLayerByName = (gameData:GlitchGame, name:string) => {
  return Object.keys(gameData.dynamic.layers).find(id => gameData.dynamic.layers[id].name === name);
}

export const onAddSprite = async (gameData, layerId, name, monolayer, pos) => {
  if(!layerId)
    return;

  const layer = gameData.dynamic.layers[layerId];

  let z;
  if(monolayer instanceof fabric.Canvas){
    z = monolayer.getObjects()
      .filter((o:any) => o.glitch_type == 'deco' && o.layer_id === layerId)
      .reduce((max,o:any) => Math.max(max,o.item.z),0) + 1;
  } else if(monolayer) {
    z = monolayer;
  } else {
    z=100;
  }

  const url = `/glitch/assets/${name}.svg`
  const img = await loadFabricImage2(url);

  const middlegroundHeight = gameData.dynamic.layers.middleground.h;
  const heightDiff = layer.h - middlegroundHeight;
  const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];

  const item:GlitchDeco = {
    x: pos.x - offset_x,
    y: pos.y - offset_y,
    w: img.width,
    h: img.height,
    r: 0,
    h_flip: false,
    filename: name,
    z,
  }

  const im = await loadFabricImage2(getDecoImageUrl(item));
  const image = deco2Fabric(item,gameData, layerId,im);
  return image
};

function GlitchEditorSingleComponent(props:{match?:any, currentUser: UserAttributes}) {

  const [gameData, setGameData] = useState<GlitchGame|null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [hidden, setHidden] = useState({})
  const [editing, setEditing] = useState(null);
  const [space_id, setSpaceId] = useState(null);
  const [tsid, setTsid] = useState(null);
  const [pos, setMousePosition] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    options: [],
  });

  let [monolayer, setMonolayer] = useState<fabric.Canvas|null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement|null>(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [doParallax, setDoParallax] = useState(true);

  const isEditing = (name) => editing == name;

  useEffect(()=> {
    (async () => {
      const uuid = props.match?.params?.id || 'GM410QQ0CHARO';

      let game_json:GlitchGame;
      let tsid:string=null;

      if(uuid.length < 36){
        game_json = await $.get('/locations/' + uuid + '.json')
      }else{
        const space_json = await Spaces.show(uuid)
        if(space_json.location){
          game_json = space_json.location.metadata
          tsid = space_json.location.tsid
        }
      }

      if(game_json){
        setGameData(game_json);
        setTsid(tsid)
        setSpaceId(uuid)
      }
    })()
  },[])

  const updateHidden = (hid) => {
    monolayer.getObjects().forEach((obj:any) => {
      const layer_name = obj.layer_name;
      obj.set({visible: !hid[layer_name]});
    })
    monolayer.requestRenderAll()
    setHidden(hid)
  }

  const saveGame=(): void =>{
    const newData = serializeItems(gameData, monolayer);
    GlitchLocations.update(space_id, newData);
  }

  const resetLayers=(): void => {
    const newData = serializeItems(gameData, monolayer);
    monolayer.clear();

    setGameData(newData);
  }

  const startEditing = (layer_name: string):void => {
    if(editing == layer_name){
      setEditing(null);
      monolayer.getObjects().forEach((o:any) => {
        o.set({selectable:false, opacity: 1});
      })
      monolayer.discardActiveObject();
      monolayer.requestRenderAll();
      return;
    }

    setEditing(layer_name);
    monolayer.getObjects().forEach((o:any) => {
      if(o.layer_name == layer_name){
        o.set({selectable:true, opacity:1});
      }else{
        o.set({selectable:false, opacity:0.5});
      }
    })

    var selection = new fabric.ActiveSelection(monolayer.getObjects(), { canvas:monolayer });
    monolayer.setActiveObject(selection);   //selecting all objects...
    monolayer.requestRenderAll();
    monolayer.discardActiveObject();
  }

  const updateParallaxOnScroll = (event: React.UIEvent<HTMLDivElement>):any => {
    if(!doParallax)
      return;
    const scroll = event.currentTarget.scrollLeft;
    //console.log('scroll', scroll);
    setCurrentScroll(scroll);

    const totalWidth = monolayer.width;
    const viewportWidth = window.innerWidth;

    const currentPercent = scroll / (totalWidth - viewportWidth);

    monolayer.forEachObject((o:any) => {
      if(!o.parallax)return

      const parallaxOffset = (totalWidth - viewportWidth) * currentPercent * o.parallax;

      o.left = o.item.x + o.offset_x + parallaxOffset;
    })

    monolayer.requestRenderAll()
  }

  const showContextMenu = (e, clickedObject) => {
    const { clientX, clientY } = e;
    const { left, top } = (monolayer as any).upperCanvasEl.getBoundingClientRect();

    const contextMenuItems = [];

    contextMenuItems.push({
      label: `Add sprite`,
      onClick: async () => {
        setShowSearch(true);
        hideContextMenu()
      },
    })

    if(clickedObject && clickedObject.glitch_type == 'deco'){
      const { item } = clickedObject;
      contextMenuItems.push({
        label: `Delete ${item.filename}`,
        onClick: () => {
          console.log(`Delete ${item.filename}`);
          monolayer.remove(clickedObject);
          monolayer.requestRenderAll();
          hideContextMenu();
          //saveGame();
        },
      }, 
        {
          label: `Duplicate ${item.filename}`,
          onClick: async () => {
            console.log(`Duplicate ${item.filename}`);
            const newDeco = await clone(clickedObject);
            const offsetX = 10;
            const offsetY = 10;
            newDeco.set({
              left: clickedObject.left + offsetX,
              top: clickedObject.top + offsetY,
            });
            monolayer.add(newDeco);
            monolayer.requestRenderAll();
            hideContextMenu();
          },
        }) 
    }

    if(contextMenuItems.length){
      setContextMenu({
        x: clientX - left,
        y: clientY - top,
        visible: true,
        options: contextMenuItems
      })
    }
  }

  const hideContextMenu = () => {
    setContextMenu({
      ...contextMenu,
      visible: false,
    });
  }

  const setupKeyHandler = (canvas:HTMLCanvasElement) => {
    canvas.tabIndex = 1000;
    // Style to outline the canvas when it is focused
    canvas.style.outline = 'none';

    // Function to handle keydown events
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log('keydown', event.key);
      if (['backspace', 'delete'].includes(event.key.toLowerCase()) || event.keyCode === 46) {
        const activeObject = monolayer.getActiveObject();
        if (activeObject instanceof fabric.ActiveSelection) {
          activeObject.forEachObject((object) => {
            monolayer.remove(object);
          });
          monolayer.discardActiveObject();
        } else {
          monolayer.remove(activeObject);
        }
      } else if (event.key === 'Escape') {
        monolayer.discardActiveObject();
      }
      monolayer.requestRenderAll()
    };
    canvas.addEventListener('keydown', handleKeyDown);
    canvas.focus();
  }

  const setupRightClickHandler = (canvas) => {
    canvas.on('mouse:down', (options) => {
      console.log('mouse:down', options);
      if (options.button === 3) { // Right mouse button
        const clickedObject = options.target;
        const ptr = monolayer.getPointer(options.e, true);
        const { x, y } = ptr;
        setMousePosition({ x, y });
        if (!contextMenu.visible) {
          showContextMenu(options.e, clickedObject);
        } else {
          hideContextMenu();
        }
        options.e.preventDefault(); // Prevent default context menu
      } else {
        hideContextMenu();
      }
    });
  };

  const handleModified = (e) => {
    if(e.target.type != 'image')return;
    //TODO take care of lines etc
    const obj:any = e.target;
    const el:HTMLImageElement = obj.getElement() as HTMLImageElement;
    const {top,left,scaleX,scaleY,angle} =obj;

    const totalWidth = monolayer.width;
    const viewportWidth = window.innerWidth;
    const currentPercent = currentScroll / (totalWidth - viewportWidth);

    const parallaxOffset = (totalWidth - viewportWidth) * currentPercent * obj.parallax;

    const j= fabric.util.qrDecompose(obj.calcTransformMatrix())
    obj.item.w = j.scaleX * el.naturalWidth;
    obj.item.h = j.scaleY * el.naturalHeight;

    obj.item.x = obj.left - obj.offset_x - parallaxOffset;
    obj.item.y = obj.top - obj.offset_y;
    obj.item.r = obj.angle;

    console.log('Modified:',
      (e.target as any).idx,
      (e.target as any).item,
      {top,left,scaleX,scaleY,angle},
      fabric.util.qrDecompose(e.target.calcTransformMatrix()));
  }

  useEffect(() => {
    if(!gameData) return;
    if(!canvas) return;

    if(!monolayer){
      monolayer = new fabric.Canvas(canvas, {enableRetinaScaling: false, 
        fireRightClick: true,
        fireMiddleClick: true,
        stopContextMenu: true,
      });

      setupKeyHandler((monolayer as any).upperCanvasEl)
      highlightSelectedObject(monolayer)
      setupRightClickHandler(monolayer);
      monolayer.preserveObjectStacking=true;
      monolayer.perPixelTargetFind=true;

      monolayer.on('object:modified',handleModified);
      setMonolayer(monolayer);
    }

    (window as any).monolayer=monolayer;
    (window as any).gameData=gameData;

    const width= gameData.dynamic.r - gameData.dynamic.l;
    const height= gameData.dynamic.b - gameData.dynamic.t;
    monolayer.setHeight(height);
    monolayer.setWidth(width);

    Promise.all(Object.keys(gameData.dynamic.layers).map((id,i) => renderLayer(gameData,id,monolayer))).then(() => {

      sortBy(monolayer.getObjects(),(o:any) => o.hasOwnProperty('z_idx') ? o.z_idx : 60000).forEach((o,i) => {
        monolayer.moveTo(o, i);
      })

      monolayer.requestRenderAll()
    })
  }, [gameData, canvas])

  const onHitClick = async ({name})=>{
    const img = await onAddSprite(gameData, editing||'middleground', name, pos, monolayer);
    monolayer.add(img);
    monolayer.calcOffset();
    monolayer.setActiveObject(img);
    monolayer.requestRenderAll();
  }

  if(!gameData)
    return <h1>Loading...</h1>;

  return <div className="glitcher">
    {showSearch ? <SpotlightSearch showSearch={showSearch} setShowSearch={setShowSearch} onHitClick={onHitClick}/> : null}

    <LayersDrawer spaceId={space_id} gameData={gameData} setHidden={updateHidden} hidden={hidden} startEditing={startEditing} isEditing={isEditing} resetLayers={resetLayers} saveGame={saveGame} setShowSearch={setShowSearch} />

    <div className="location_holder" style={{overflow: 'scroll'}} onScroll={(e) => { updateParallaxOnScroll(e); return true}}>
      <div className="location" style={stageCSS(gameData)}>
        <ContextMenu {...contextMenu} />
        <div id={`layer-mono`} style={layerStyle(gameData.dynamic.layers.middleground)}>
          <canvas ref={(e) => setCanvas(e)} id={`canvas-mono`} style={{width: gameData.dynamic.layers.middleground.w, height: gameData.dynamic.layers.middleground.h}}></canvas>
        </div> 
      </div>

    </div>
  </div>
}

const ContextMenu = ({ x, y, visible, options }) => {
  if (!visible) return null;

  const style: React.CSSProperties = {
    position: 'absolute',
    top: `${y}px`,
    left: `${x}px`,
    zIndex: 1000,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
  };

  return (
    <div style={style}>
      {options.map((option, index) => (
        <div key={index} onClick={option.onClick} style={{ padding: '5px', cursor: 'pointer' }}>
          {option.label}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state,ownProps) => ({
  ...ownProps, 
  currentUser: state.common.currentUser,
});

const LayersDrawer = connect(mapStateToProps)((props:any) => {

  const {gameData, spaceId, setHidden, hidden, startEditing, isEditing, currentUser, resetLayers, saveGame, setShowSearch} = props;

  const sortedLayers:GlitchLayer[] = sortBy(Object.values(gameData?.dynamic?.layers||{}), layer=> layer.z);

  const isHidden = (layer:string):boolean => hidden[layer];

  return <Drawer
    variant="permanent"
    anchor='left'
    open={true}
    PaperProps={{ style: { position: 'fixed' } }}
    BackdropProps={{ style: { display: 'none' } }}
  >
    <List>
      {sortedLayers.map((layer) => {
        return <ListItem key={layer.name}>
          <ListItemIcon onClick={(e) => setHidden({...hidden, [layer.name]: !isHidden(layer.name)})}>
            <i className={`fal ${isHidden(layer.name) ? 'fa-eye-slash' : 'fa-eye'}`}/>
          </ListItemIcon>
          <ListItemIcon onClick={(e) => startEditing(layer.name)}>
            <i className={`fal fa-edit`} style={{opacity: isEditing(layer.name) ? 1 : 0.5}}/>
          </ListItemIcon>
          <ListItemText primary={layer.name} />
        </ListItem>
      })}

  {
    isAdmin(currentUser) ? <ListItem key="test">
      <ListItemIcon onClick={(e) => resetLayers()}>
        <i className={`fal fa-play`}/>
      </ListItemIcon>
      <ListItemText primary="Test Serialization" />
  </ListItem> : null}

  <ListItem key="save">
    <ListItemIcon onClick={(e) => saveGame()}>
      <i className={`fal fa-save`}/>
    </ListItemIcon>
    <ListItemText primary="Save" />
  </ListItem>

  <ListItem key="showSearch" onClick={(e) => setShowSearch(true)}>
    <ListItemIcon>
      <i className={`fal fa-search`}/>
    </ListItemIcon>
    <ListItemText primary="Search" />
  </ListItem>

  <ListItem button component="a" href={`/#${play_game_url(spaceId)}`} target="_blank" rel="noopener noreferrer">
    <ListItemIcon>
      <i className={`fal fa-external-link`}/>
    </ListItemIcon>
    <ListItemText primary="View Game" />
  </ListItem>

</List>
</Drawer>
})

const GlitchEditorPower= connect(mapStateToProps)(GlitchEditorSingleComponent);

export {GlitchEditorPower}

