import { BaseModel } from "./base_model";
import * as _ from "underscore";
import { NpcAttributes, Npc } from "./npc";
import { v4 as uuidv4 } from 'uuid';

interface NpcPlacementAttributes {
  id: string,
  uuid: string,
  x: number,
  y: number,
  css: string,
  events: Array<{event:string,command:string, args:any[]}>, //{string: string[]} | [string,string,any],
  npc_id: string,
  npc?: NpcAttributes,
}

type InteractionEvent = {
  event:string,
  command:string,
  args:any[],
  id:string
}

interface IEventable {
  addEvent(event, command, args, id?),

  removeEvent(id: string),
}

class NpcPlacement extends BaseModel implements IEventable {

  private _npc: any;

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('NpcPlacement');
  }

  initialize(attrs, opts) {
    super.initialize(attrs, opts);
  }

  _className() {
    return "NpcPlacement";
  }

  getCss(prop) {
    var ref = this.get('css');
    return ref != null ? ref[prop] : void 0;
  }

  clone(){
    return new NpcPlacement(JSON.parse(JSON.stringify(this.attributes)), {dispatcher: this.dispatcher})
  }

  setCss(prop, val) {
    var v;
    const css = JSON.parse(JSON.stringify(this.get('css'))) || {};
    if (_.isObject(prop)) {
      for (let k in prop) {
        v = prop[k];
        css[k] = v;
      }
    } else {
      css[prop] = val;
    }
    return this.set('css', css);
  }

  parse(resp, options) {
    const x = super.parse(resp, options);
    if(resp.events instanceof Array){
      x.events = resp.events;
    }else{
      x.events = Object.keys(resp.events || {}).map((k) => ({event: k, command: resp.events[k][0], args: resp.events[k].slice(1)}));
    }
    return x;
  }

  //@ts-ignore
  get id():string {
    return this.get('id');
  }

  get x():number {
    return this.get('x')
  }

  get y():number {
    return this.get('y')
  }

  get name():string {
    return this.get('name')
  }

  get uuid():string {
    return this.get('uuid')
  }

  get css():string /*{[name:string]:any}*/{
    return this.get('css')
  }

  get events():Array<{event:string,command:string, args:any[], id: string}> {
    return this.get('events') || []
  }

  /* TODO keep in sync with anchor.ts #updateEvent */
  updateEvent(id: string, event:Partial<InteractionEvent>) {
    Object.assign(this.events.find((e) => e.id==id), event)
    this.set({ events: this.events });
  }

  removeEvent(id:string) {
    const evs = this.events.filter((e) => !(e.event == id))
    this.set({ events: evs });
  }

  addEvent(event, command, args, id=null) {
    const evs = this.events;
    evs.push({event, command, args, id: id || uuidv4()});
    this.set({ events: evs });
  }

  get npc():Npc{
    if(!this._npc)
      this._npc = new Npc(this.get('npc'),{dispatcher: this.dispatcher});

    return this._npc
  }

  url = () => {
    let url = (window as any).Niconitos.config.api;
    if (this.get("space_id")) {
      url += "/spaces/" + this.get("space_id");
    }
    return url + "/npc_placements" + (this.id ? "/" + this.id : "");
  }

  //belongsTo() {
  //  return {
  //    npc: this.dispatcher.cache.collections.NpcCollection
  //  };
  //}

};

export { NpcPlacement,NpcPlacementAttributes, IEventable, InteractionEvent};
