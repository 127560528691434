// AssetCarousel.tsx
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography, Tabs, Tab } from '@material-ui/core';
import React, {useState} from 'react';
import PlusIcon from '@material-ui/icons/Add';
import {useDispatch, useSelector} from 'react-redux';
import {GeneratedView, getDefaultConfig, LOCAL_STORAGE_KEY, prefixPrompt} from './panels/generative_ai_panel';
import {useDesignContext} from 'contexts/design_context';
import {addItem} from './canvas/DropHandler';
import {sprite_url} from 'utils/urls';
import {addAiGenerations} from 'redux/reducers/editor';
import ClearIcon from '@material-ui/icons/Clear';
import ClearableTextField from './clearable_text_field';
import PictureUploadForm from './pictures/new';
import {ListPictures} from './pictures/select_picture_modal';

interface Asset {
  id: number;
  name: string;
  src: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselContainer: {
      zIndex: 5000,
      width: '100vw',
      position: 'fixed',
      bottom: 60,
      backgroundColor: '#f5f5f5',
      padding: theme.spacing(1),
      boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
      display: 'flex',
      overflowX: 'auto',
      scrollbarWidth: 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none', // Chrome, Safari, Opera
      },
    },
    fixedButton: {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: '#f5f5f5',
      paddingRight: theme.spacing(2),
      minWidth: 100,
      minHeight: 100,
      marginRight: theme.spacing(2),
      borderRadius: 8,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollContainer: {
      display: 'flex',
      overflowX: 'auto',
      scrollbarWidth: 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none', // Chrome, Safari, Opera
      },
    },
    assetItem: {
      minWidth: 100,
      minHeight: 100,
      marginRight: theme.spacing(2),
      backgroundColor: '#fff',
      borderRadius: 8,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'grab',
      userSelect: 'none',
      transition: 'transform 0.2s',
      '&:active': {
        transform: 'scale(1.05)',
        cursor: 'grabbing',
      },
    },
    assetImage: {
      maxWidth: '80%',
      maxHeight: '80%',
    },
  })
);


export const findCurrentCenter = (container, canvas) => {
  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;
  const scrollLeft = container.scrollLeft;
  const scrollTop = container.scrollTop;
  console.log('scrollLeft', scrollLeft, 'scrollTop', scrollTop);

  // Step 2: Calculate the center point of the visible area in container coordinates
  const centerX = scrollLeft + containerWidth / 2;
  const centerY = scrollTop + containerHeight / 2;
  return {x: centerX, y: centerY};

  //// Step 3: Get the inverse of the current viewport transform
  //const viewportTransform = canvas.viewportTransform; // [a, b, c, d, e, f]
  //const invertedTransform = fabric.util.invertTransform(viewportTransform);

  //// Step 4: Convert the center point to canvas coordinates
  //const canvasCenterPoint = fabric.util.transformPoint( { x: centerX, y: centerY }, invertedTransform);
  //return canvasCenterPoint;

}

const AssetCarousel: React.FC = () => {
  const classes = useStyles();
  const ai_assets = useSelector((state: any) => state.editor.ai_generations);
  const spaceId = useSelector((state: any) => state.editor.spaceId);
  const context = useDesignContext();
  const {designController, generativePrompt} = context
  const [creatingAsset, setCreatingAsset] = React.useState(false);
  const [value, setValue] = useState(getDefaultConfig(generativePrompt, LOCAL_STORAGE_KEY));
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);

  const addAsset = (item) => addItem(item,true, designController);

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const onCreateAsset = async () => {
    //... create asset
    const pl = JSON.stringify(value);
    localStorage.setItem(LOCAL_STORAGE_KEY,pl);

    const url = sprite_url({space_id: spaceId});

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        prompt: prefixPrompt(value.prompt), 
        params: {...value, sprite: true}})
    });

    const data = await resp.json();

    dispatch(addAiGenerations([{id: data.uuid, prompt: value.prompt}]))
    setCreatingAsset(false)
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return <>
  <Dialog open={creatingAsset} onClose={() => setCreatingAsset(false)} fullWidth maxWidth="md">
    <DialogTitle>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Create Asset" />
        <Tab label="Your Pictures" />
        <Tab label="Upload Picture" />
      </Tabs>
    </DialogTitle>

    <DialogContent>
      {tabValue === 0 && (
        <>
          <ClearableTextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            margin="normal"
            value={value.prompt}
            onChange={(e) => setValue({...value, prompt:e.target.value})}
          />
          <Button variant="contained" color="primary" onClick={() => onCreateAsset()}>
            Create
          </Button>
        </>
      )}
          {tabValue === 1 && (
            <ListPictures onSelect={(p)=>{addItem({url: p.image_url}, true, designController);setCreatingAsset(false)} } />
          )}

          {tabValue === 2 && (
            <PictureUploadForm onCreated={(picture)=>{addItem({url: picture.image_url}, true, designController);setCreatingAsset(false)}} />
          )}


    </DialogContent>
  </Dialog>

    <Box className={classes.carouselContainer}>
      <Box className={classes.fixedButton}>
        <Button onClick={() => setCreatingAsset(true)} variant="contained" color="primary" style={{ width: '100%', height: '100%' }}>
          <PlusIcon />
        </Button>
      </Box>
      <Box className={classes.scrollContainer}>
        {ai_assets.map((asset) => (
          <Box key={asset.uuid} className={classes.assetItem} >
            {asset.url ? 
            <GeneratedView result={asset} onClickPrompt={(p) => {setValue({...value, prompt:p}); setCreatingAsset(true)}} onClickAsset={(item)=>addAsset(item)}/>
            :
            <CircularProgress></CircularProgress>
            }
          </Box>
        ))}
      </Box>
    </Box>
  </>;
};

export default AssetCarousel;
