
export const loadImage = (url) => new Promise<HTMLImageElement>((accept,reject) => {
  const img =new Image()
  img.onload = (e) => accept(img)
  img.onerror = (e)=> reject(e)
  img.src=url;
})

const createCanvasContext = (img:HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img,0,0);
  return ctx;
}

const loadImageData = (img:HTMLImageElement, canvas?:HTMLCanvasElement) => {
  let ctx:CanvasRenderingContext2D;
  if(!canvas){
    ctx = createCanvasContext(img)
  }else{
    ctx = canvas.getContext('2d');
    ctx.drawImage(img,0,0, canvas.width, canvas.height);
  }

  return ctx.getImageData(0,0,ctx.canvas.width,ctx.canvas.height);
  
}

export const applyMask = async (url,mask_url) => {
  const img = await loadImage(url);
  const ctx = createCanvasContext(img)
  const imgd = ctx.getImageData(0,0,ctx.canvas.width,ctx.canvas.height);

  const mask = await loadImage(mask_url);
  const maskd = loadImageData(mask, ctx.canvas);

  for(let i=0,len=imgd.data.length;i<len;i+=4){
    imgd.data[i+3]= maskd.data.slice(i,i+3).reduce((a,x) =>a+x)/3;
  }
  ctx.putImageData(imgd,0,0);
  return ctx.canvas.toDataURL() as string
}
