import _ from "underscore";
import {NounType} from 'hip/noun';

const makeMatcher = suggestions => (fragment, context) => {
  const suggester = context.dispatcher.request(suggestions);
  const results = []
  suggester.search(fragment, (videos) => videos.forEach((v) => results.push(v.name)))
  return !!results.length;
};

const makeSuggester = <T extends {}> (suggestions:string) => (fragment, context, promises:Array<Promise<Array<T>>>) => {

  const retval:Array<{label:string, id?:string}> = [];
  const suggester = context.dispatcher.request(suggestions);

  if (promises) {
    promises.push(new Promise((accept, reject) => {
      suggester.search(fragment, (videos) => {
        retval.push(...videos);
      }, (videos) => {
        suggester.add(videos.map((v)=>({ label: v.name, id: v.id })));
        accept(videos.map((v) => ({ label: v.name, id: v.id })));
      })}));
  } else {
    suggester.search(fragment, (videos) => videos.forEach((v) => retval.push(v.name)))
  }
  return retval;
};

class QuotedString extends NounType {

  name = "string";
  label = "some string"

  match(fragment) {
    return fragment != null ? fragment.match(/.*/) : void 0;
  }

  suggest(fragment, context) {
    return [{label:fragment}];
  }

};


class Coords extends NounType {

  name = "coords";
  label = "(some coordinates)"

  match(frag) {
    return frag != null ? frag.match(/@?[+-]?[0-9]+,[+-]?[0-9]+/) : void 0;
  }

  suggest(frag, context) {
    return [{label:frag}];
  }

};

class ZoomFactor extends NounType {
  name = "zoomFactor";
  label = "(a zoom number)";

  match(frag) {
    return !!(frag != null ? frag.match(/[0-9.]+/) : void 0) && parseFloat(frag) < 3 && parseFloat(frag) > 0.1;
  }

  suggest(frag, context) {
    return [{label:frag}];
  }

};

class Model extends NounType {

  models = ["Text", "Sound", "Video", "Picture", "Avatar", "Space", "Anchor", "Npc", "Question"];

  name = "model";
  label = "(a model name)";

  match(frag, context) {
    return this.suggest(frag, context).length !== 0;
  }

  suggest(fragment, context):{label:string}[] {
    if (fragment) {
      return this.models.filter(name => name.toLowerCase().match(fragment.toLowerCase())).map(x => ({label: x}))
    } else {
      return this.models.map(x => ({label: x}));
    }
  }

};


class Scene extends NounType {

  name = "scene";
  label = "(some scene)"

  match(frag, context) {
    return this.suggest(frag, context).length !== 0;
  }

  suggest(fragment, context) {
    const scenes = context.scenes || {};

    if (fragment) {
      return Object.keys(scenes).filter(name => name.match(fragment)).map(x => ({label:x}))
    } else {
      return _(scenes).keys().map(x => ({label:x}));
    }
  }

};


class Animation extends NounType {

  public name = "animation";
  label = "(some animation)"

  public match(frag, context) {
    return this.suggest(frag, context).length !== 0;
  }

  public suggest(fragment, context) {

    const keys = context.animations ? Object.keys(context.animations) : ['up','down','left','right'];

    if (fragment) {
      return keys.filter(k => k.match(fragment)).map(x => ({label:x}));
    } else {
      return keys.map(x => ({label:x}));
    }
  }

};


class SomeTime extends NounType {

  public name = "seconds";
  label = "(some amount of seconds)"

  public match(frag, context) {
    return (frag != null ? frag.match(/[0-9]+/) : void 0) && parseFloat(frag) < 1000000 && parseFloat(frag) >= 1;
  }

  public suggest(frag, context) {
    return [{label: frag}];
  }

};

class Fps extends NounType {
  public name = "fps";
  label = "(some frames-per-second)"
  public match(frag, context) {
    return (frag != null ? frag.match(/[0-9]+/) : void 0) && parseFloat(frag) < 1000000 && parseFloat(frag) >= 1;
  }

  public suggest(frag, context) {
    return [{label: frag}];
  }

};

class Question extends NounType {
  public name = "question";
  public label = "(some question)";

  public fuzzy = true;

  public match = makeMatcher('QuestionSuggestions');

  public suggest = makeSuggester<Question>('QuestionSuggestions');
}


class Clazz extends NounType {
  public name = "clazz";
  public label = "(an item name)";

  public fuzzy = true;

  public match = makeMatcher('ClazzSuggestions');

  public suggest = makeSuggester<Clazz>('ClazzSuggestions');
}

//class Item extends NounType {
//  public name = "item";
//  public label = "(an item name)";
//
//  public fuzzy = true;
//
//  public match = makeMatcher('ItemSuggestions');
//
//  public suggest = makeSuggester<Item>('ItemSuggestions');
//}
//
class WebResource extends NounType {
  public name = "web resource";
  public label = "(an web resource)";

  public fuzzy = true;

  public match = makeMatcher('WebResourceSuggestions');

  public suggest = makeSuggester<WebResource>('WebResourceSuggestions');
}


class Anchor extends NounType {
  public name = "anchor";
  public label = "(an anchor name )";

  public fuzzy = true;

  public match = makeMatcher('AnchorSuggestions');

  public suggest = makeSuggester<Anchor>('AnchorSuggestions');
}

class Branch extends NounType {

  public name = "branch";
  label = "(some branch)"

  public match(frag, context) {
    return Boolean(frag);
  }

  public suggest(frag, context) {
    return [{label: frag}];
  }

};

class Script extends NounType {

  public name = "script";
  public label = "(a script)";

  public fuzzy = true;

  public match = makeMatcher('ScriptSuggestions');

  public suggest = makeSuggester<Script>('ScriptSuggestions');
};

class Video extends NounType {

  public name = "video";
  public label = "(a video)";

  public fuzzy = true;

  public match = makeMatcher('VideoSuggestions');

  public suggest = makeSuggester<Video>('VideoSuggestions');
};

class Picture extends NounType {

  public name = "picture";
  public label = "(a picture)";

  public fuzzy = true;

  public match = makeMatcher('PictureSuggestions');

  public suggest = makeSuggester<Picture>('PictureSuggestions');
};

class Text extends NounType {

  public name = "text";
  public label = "(a text)";

  public fuzzy = true;

  public match = makeMatcher('TextSuggestions');

  public suggest = makeSuggester<Text>('TextSuggestions');
};

class Sound extends NounType {

  public name = "sound";
  public label = "(a sound)";

  public fuzzy = true;

  public match = makeMatcher('SoundSuggestions');

  public suggest = makeSuggester<Sound>('SoundSuggestions');
};

class Avatar extends NounType {

  public name = "avatar";
  public label = "(an avatar)";

  public fuzzy = true;

  public match = makeMatcher('AvatarSuggestions');

  public suggest = makeSuggester<Avatar>('AvatarSuggestions');
};

class Space extends NounType {

  public name = "space";
  public label = "(a space)";

  public fuzzy = true;

  public match = makeMatcher('SpaceSuggestions');

  public suggest = makeSuggester<Space>('SpaceSuggestions');
};

//const url = new NounType({name: "url", expectedWords: ["/spaces", "/sprites", "/avatars", "/tiles", ".*"]});

//const lang = new NounType({name: "lang", expectedWords: ["spanish", "english", "swedish"]});

class AnyWord extends NounType {

  public name = "text";
  public label = "(any word)";

  match(fragment) {
    return true;
  }

  suggest(fragment, context) {
    return [{label: fragment}];
  }

};

class DateNounType extends NounType {

  match(fragment, context) {
    return this.suggest(fragment, context).length > 0;
  }

  suggest(fragment, context) {
    var date;
    if (!fragment) {
      return [];
    }
    date = Date.parse(fragment);
    if (!date) {
      return [];
    }
    return [{label: "parsed date: " + date.toString()}];
  }

};

const aPicture= new Picture();
const aSound= new Sound();
const aText= new Text();
const aVideo= new Video();
const aModel= new Model();
const aAvatar= new Avatar();
const aScript= new Script();
const aBranch= new Branch();
const aZoomFactor= new ZoomFactor();
const someTime= new SomeTime();
const anAnchor= new Anchor();
//const anItem= new Item();
const aWebResource= new WebResource();
const aClazz= new Clazz();
const aQuestion= new Question();
const anAnimation= new Animation();
const aScene= new Scene();
const fps= new Fps();
const quotedString= new QuotedString();
const coords= new Coords();
const space= new Space();
const visibility= new NounType({name: "visibility", label:"(on/off)", expectedWords: ["on", "off"]});
const trueFalse= new NounType({name: "boolean", label:"(true/false)", expectedWords: ["true", "false"]});
const anyWord= new AnyWord();
const aDate= new DateNounType();

export {
aPicture,
aSound,
aText,
aVideo,
aModel,
aAvatar,
aScript,
aBranch,
aZoomFactor,
someTime,
anAnchor,
//anItem,
aClazz,
aWebResource,
aQuestion,
anAnimation,
aScene,
fps,
quotedString,
coords,
space,
visibility,
trueFalse,
anyWord,
aDate
};
