import { Redirect } from "react-router-dom";
import React from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import { WebResources } from 'api/agent'

import * as ActiveStorage from 'activestorage';
import { WebResourceAttributes } from 'models/web_resource';
import WebResourceForm from './form';

type WebResourceEditState = {
  web_resource?: WebResourceAttributes,
  creation_status?: string,
  errors:any[],
};

class WebResourceEdit extends React.Component<{match:any},WebResourceEditState> {

  state:WebResourceEditState = {
    errors:null,
    creation_status: null,
  }

  async componentDidMount(){
    if(!this.state.web_resource){
      const web_resource = await WebResources.show(this.props.match.params.uuid)
      this.setState({...this.state, web_resource});
    }
  }
  render(){
    if (this.state.creation_status == 'created') {
      return <Redirect to={`/web_resources/${this.props.match.params.uuid}`} />
    }

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          {this.state.web_resource && <>
          <h1 className="text-xs-center">Edit WebResource</h1>
          <WebResourceForm submitForm={this.submitForm.bind(this)} web_resource={this.state.web_resource} errors={this.state.errors} creation_status={this.state.creation_status}></WebResourceForm>
      </>}
        </div>
      </div>
    );
  }

  async submitForm({name, description}): Promise<any> {

    const web_resource = { name, description};

    this.setState({...this.state, creation_status: 'creating'})

    WebResources.update(this.props.match.params.uuid, web_resource).then((e) => {
      this.setState({...this.state, creation_status: 'created'});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}


export default WebResourceEdit;

