import * as _ from 'underscore';

import { AnchorView } from './anchor_view';
import { NpcView } from './npc_view';
import { AvatarView, AvatarHolder } from './avatar_view';
import { PersonaView } from './persona_view';
import { TileView } from './tile_view';

import BaseView from 'views/base_view';
import { Npc } from 'models/npc';
import { Space, LayerAttributes } from 'models/space';
import { Events } from 'maps/map_events';
import { Avatar } from 'models/avatar';
import { Script, ScriptTarget } from 'models/script';
import { isTouchScreen } from 'utils/mobile';
import { store } from 'redux/store'
import { MODES } from 'components/world/constants';
import { MAP_SET_PEOPLE } from 'constants/actionTypes';
import { Anchors, Npcs } from 'api/agent';
import { Anchor } from 'models/anchor';
import { MapController } from 'maps/controller';
import { Tile } from 'models/tile';
import { sortBy } from 'underscore';
import { ScriptExecutor } from 'models/script_executor';
import { dist } from 'utils/dist';

const intersect = require('path-intersection');

const MAP_TILES_OFFSET = 3;


const makeSVG = (tag, attrs) => {
  const el= document.createElementNS('http://www.w3.org/2000/svg', tag);
  for (var k in attrs)
    el.setAttribute(k, attrs[k]);
  return el;
}


function process(svgElement:SVGSVGElement):Array<Array<{x:number,y:number}>>{
  //var parser = new DOMParser();
  //var docu = (parser.parseFromString(svg, "image/svg+xml") as XMLDocument);

  //const root:SVGSVGElement = docu.documentElement as any;
  const point = svgElement.createSVGPoint();
  const docu = svgElement.ownerDocument;

  return Array.from(docu.querySelectorAll("polygon")).map((path:SVGPolygonElement)=> {
    return Array.from(path.points).map((pt) => {
      point.x = pt.x;  // replace this with the x co-ordinate of the path segment
      point.y = pt.y;  // replace this with the y co-ordinate of the path segment
      const {x,y}=svgElement.viewBox.baseVal;
      var matrix = (path as any).getTransformToElement(svgElement);
      var position = point.matrixTransform(matrix);
      return {x: position.x - x, y: position.y - y};
    })
  })

}

type TileInfo = {
  width:number,
  height:number,

  width_px:number,
  height_px:number,

  origin_x:number,
  origin_y:number,

  grid: Array<Array<TileView>>
};

class MapView extends BaseView<Space> implements AvatarHolder {

  $viewport: JQuery<HTMLElement>;
  $content: JQuery<HTMLElement>;
  $overlay: JQuery<HTMLElement>;
  $tiles: JQuery<HTMLElement>;
  $sprites: JQuery<HTMLElement>;
  $avatars: JQuery<HTMLElement>;
  space: Space;
  zoomFactor: number=1;
  current_avatar: AvatarView<Avatar>;
  min_x: number;
  min_y: number;

  anchor_views: AnchorView[]=[];
  avatar_views: AvatarView<Avatar>[]=[];
  npcs: NpcView[]=[];
  max_x: number;
  max_y: number;
  $zones: JQuery<HTMLElement>;
  middleground: LayerView;

  layers: LayerView[];
  $layers: JQuery<HTMLElement>;

  get tile_width():number{
    return this.space.tile_width
  }

  get tile_height():number{
    return this.space.tile_height
  }

  constructor(options?) {
    super(options);
    this.initialize(options)
    this.dispatchCreated('MapView');
    (window as any).mapa=this;
  }

  className = 'map_viewport';

  classNames() {
    return super.classNames() + " map_viewport ";
  }

  events()  {
    return {
      [Events.ZOOM]: (e) => this.handle_zoom(e),
      "drag .map_content": (e, ui?) => this.constrain_drag(e, ui),
      "dragstop .map_content": (e, ui?) => this.handle_drag_stop(e, ui),
      "dragstart .map_content": (e, ui?) => this.handle_drag_start(e, ui),
      "click": (e) => {

        const mapProps = store.getState().map as any;

        if(isTouchScreen() || mapProps.clickAndMove){
          this.mapMove(e);
        }else{
          this.userMove(e)
        }
      },
      "dblclick": (e) => this.mapMove(e),
      "scroll .map_viewport": (e) => this.handle_scroll(e)
    }
  };

  constrain(ob) {
    const left = Math.min(0, Math.max(ob.left, -this.total_width*this.zoomFactor + this.$viewport.width()));
    const top = Math.min(0, Math.max(ob.top, -this.total_height*this.zoomFactor + this.$viewport.height()));

    return { left, top };
  }

  get debug():boolean{
    return store.getState().map.show_zones;
  }

  center_on(avatar:AvatarView<Avatar>){
    this.move_to(avatar.position.x*this.zoomFactor - (this.$viewport.width() / 2), avatar.position.y*this.zoomFactor - (this.$viewport.height() / 2), 0);
  }

  initialize(settings) {
    this.render();
    this.$viewport = this.$el;
    this.$content = this.$(".map_content");
    this.$overlay = this.$(".map_overlay");
    this.$layers = this.$(".map_layers");
    this.$zones = this.$(".map_zones");
    this.$tiles = this.$(".map_tiles");
    this.$sprites = this.$(".map_sprites");
    this.$avatars = this.$(".map_avatars");
    this.space = this.model;

    this.dispatcher=settings.dispatcher;
    this.middleground = new LayerView({map_view:this, tiles: this.space.tiles, el: this.$tiles[0], layer: null})

    this.layers = this.space.layers ? sortBy(this.space.layers, (l)=> l.z).map((layer) => {

      const top = this.space.height_px - layer.total_height;

      const newLayer = ($('<div>') as JQuery<HTMLDivElement>)
        .attr('id', layer.id).css({
        position: 'absolute',
        width: layer.total_width,
        height: layer.total_height,
        left: 0,
        top: top,
        'z-index': layer.z
      }).appendTo(this.$layers);

      return new LayerView({el: newLayer[0], map_view: this, layer, tiles: layer.tiles.map((ta)=>new Tile(ta, {dispatcher: this.space.dispatcher, layer:layer}))});
    }) : [];

    this.$content.addClass(this.layers.length ? 'side-scroll' : 'top-down');
    this.$content.removeClass(!this.layers.length ? 'side-scroll' : 'top-down');

    if(this.space.masks){

      this.$zones.html(this.space.masks)
      this.$zones.append('<svg class=debug xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="5600" height="3200"xml:space="preserve" style="position:absolute;top:0;left:0;">')
    }
    this.zoomFactor = 1;
    this.current_avatar = null;
    (this.$content as any).draggable({ cursor: "crosshair" });
    this.min_x = this.max_x = 0; // to which X we have already downloaded the tiles
    this.min_y = this.max_y = 0; // to which Y we have downloaded the tiles

    // Width and height of a tile in pixels
    this.avatar_views = new Array();
    this.npcs = new Array();

    // collections (injected) by map
    return super.initialize(settings)
  }

  reset_avatars() {
    this.avatar_views.forEach(cv => cv.remove());
    this.avatar_views = new Array();
    this.$avatars.children().remove();
  }

  async paint_anchors() {
    const anchors = await Anchors.all(this.space.get('id'))

    const models = anchors.map((anchor) => {
      const model = new Anchor(anchor, {dispatcher: this.dispatcher})
      const anchorview = new AnchorView({ model, dispatcher: this.dispatcher });
      this.anchor_views.push(anchorview);
      this.$sprites.append(anchorview.render().el);
      return model;
    });

    this.space.anchors = models;
  }

  reset_npcs() {

    this.space.npc_placements.forEach((npc_placement) => {
      Npcs.get(npc_placement.get("npc_id")).then((attributes) => {
        const npc = new Npc(attributes, { dispatcher: this.dispatcher });
        const npcviw = new NpcView({
          model: npc,
          placement: npc_placement,
          dispatcher: this.dispatcher
        });
        this.addNpcView(npcviw);
      });
    });

  }

  async playScript(script:Script, x, y, where:ScriptTarget, opts) {

    if (!where.script_avatars) {
      where.script_avatars = [];
    }

    const s = script//.relativize(x, y);

    if(where.executor){
      where.executor.stop();
      where.executor.remove_chars();
    }
    where.executor = new ScriptExecutor({ model: s, where }, {dispatcher: this.dispatcher});
    await where.executor.place_chars();
    where.executor.play2();

  }

  switch_avatar(backward) {
    let i = this.avatar_views.indexOf(this.current_avatar);
    if (i !== -1) {
      i += (backward ? this.avatar_views.length - 1 : 1);
      i = i % this.avatar_views.length;
      this.avatar_views[i].select();
    }
  }

  zoom(factor) {
    this.zoomFactor = parseFloat(factor);
    this.$el.trigger(Events.ZOOM);
    this.npcs.forEach((npc:NpcView) => {
      npc.$el.trigger(Events.ZOOM);
    });
    console.log("zooming:" + factor);
  }

  selectAvatar(avatar) {
    if (this.current_avatar) {
      this.current_avatar.unselect();
    }
    this.current_avatar = avatar;
  }

  move_to(x, y, animate_time=1000) {
    const constrained = this.constrain({ top: -y, left: -x });
    //console.log('constrained', constrained, this.zoomFactor);
    this.$content.stop(true,false);

    this.$content.animate({
      left: constrained.left + "px",
      top: constrained.top + "px"
    }, {
      duration: animate_time,
      progress: this.parallax.bind(this),
      complete: ()=>setTimeout(this.parallax.bind(this),50),
      easing: 'linear'
    });

    this.handle_drag_stop(null, { position: constrained });
  }

  init_tiles(){
    this.middleground.init_tiles();
    this.layers.forEach((l) => {
      l.init_tiles()
    })
  }

  handle_zoom(e) {
    if (e.target !== this.$el[0]) {
      return;
    }
    this.middleground.removeAll(); 
    const transformOrigin= `50% 50%`;
    this.$content.css({ transform: `scale(${this.zoomFactor})`, transformOrigin});
    this.middleground.init_tiles();

    this.center_on(this.current_avatar)
  }

  parallax(time){
    const middleGroundOffset = Math.max(0, Math.min(this.total_width - this.viewport_width, this.current_avatar.position.x - (this.viewport_width / 2)))

    const currentPercent = middleGroundOffset / (this.total_width - this.viewport_width);
    //console.log(middleGroundOffset, Math.round(currentPercent*100)+"%", this.total_width,this.viewport_width, this.current_avatar.position)

    this.layers.forEach((layer) => {
      try {
        const offset = (this.total_width - layer.layer.total_width ) * currentPercent;
        $(layer.el).css('transform', 'translateX(' + offset + 'px)' );
        //console.log(layer.layer.id, offset);
      } catch(er) {
        console.error(er)
      }
    })

  }

  restrict(dest:{x:number,y:number}) {

    const start = this.current_avatar.position

    const path0 = `M${start.x},${start.y} L${dest.x},${dest.y}`;
    this.$zones.find('.tmp').remove();

    if(this.debug)
      this.$zones.find('svg.debug')[0].appendChild(makeSVG('path',{class: 'tmp',d: path0, stroke: "red", "stroke-width": 3}));

    const processed = process(this.$zones.find('svg')[0]);

    const intersections = processed.map((path) => {
      const path1 = 'M '+path.map((pt)=>`${pt.x} ${pt.y}`).join(' L')+' Z'
      const intersection = intersect(path0,path1)
      if(this.debug){
        this.$zones.find('svg.debug')[0].appendChild(makeSVG('path',{class: 'tmp',d: path1, fill:'red', "fill-opacity": 0.5}));
      }
      return intersection;
    }).flat().filter(x => Boolean(x)).sort((a,b) => dist(a,start) - dist(b,start));

    if(!intersections.length)
      return dest

    const pt=intersections[0];
    const segment = makeSVG('path',{d: `M ${pt.x},${pt.y} L ${start.x},${start.y}`}) as SVGPathElement;
    let retval;

    if(segment.getTotalLength()>10)
      retval=segment.getPointAtLength(10)
    else
      retval=segment.getPointAtLength(segment.getTotalLength())

    if(this.debug)
      this.$zones.find('svg.debug')[0].appendChild(makeSVG('circle',{class: 'tmp', x: retval.x, y:retval.y, r: 2, fill: 'blue'}))

    return retval
  }

  offsetTo(e) {
    // jQuery offset gives the distance in px from the topLeft of the Document to the element
    const tile_offset = $(e.target).offset();

    const offset = {
      left: tile_offset.left - this.$content.offset().left,
      top: tile_offset.top - this.$content.offset().top
    };

    // FFox does not have offset{X,Y}
    const offX = e.pageX - tile_offset.left;
    const offY = e.pageY - tile_offset.top;
    return {
      x: offset.left + offX,
      y: offset.top + offY
    };
  }

  userMove(e) {
    const editable = this.$(".editable").data("npc");

    if (editable?.recording) {
      let off = this.offsetTo(e);
      editable.walk(off.x / this.zoomFactor, off.y / this.zoomFactor, 1000, 15, true);
      return;
    }
    if (e.shiftKey) {
      e.preventDefault();
      return false;
    }

    const mode = store.getState().map.mode;

    if ([MODES.Editing,MODES.Placing].includes(mode))  {
      return;
    }
    if (this.current_avatar) {
      this.move_avatar(e);
    }
    return true;
  }

  move_avatar(e):[{x:number,y:number}, number]{
    const off = this.offsetTo(e);
    let off2 = {x: off.x/this.zoomFactor, y: off.y / this.zoomFactor};

    if(this.space.masks)
      off2 = this.restrict(off2)

    const [dist,time] = this.current_avatar.time_to_walk(off2.x, off2.y);
    this.current_avatar.walk(off2.x, off2.y);

    return [off2,time];

  }

  handle_scroll(e) {
    console.log("preventing scroll");
    e.preventDefault();
    return false;
  }

  mapMove(e) {
    const mode = store.getState().map.mode;

    if ([MODES.Editing,MODES.Placing].includes(mode))  {
      return;
    }

    if (this.current_avatar) {
      const [off, time] = this.move_avatar(e)
      this.move_to(off.x * this.zoomFactor - (this.$viewport.width() / 2), off.y * this.zoomFactor - (this.$viewport.height() / 2), time);
    }
    return true;
  }

  addNpcView(npcview:NpcView) {
    this.npcs.push(npcview);
    this.$sprites.append(npcview.el);
    npcview.addedToMap(this);
  }

  addAvatar(avatar, attrs) {
    const yetAdded = this.avatar_views.find((e) => e === avatar);
    if (avatar instanceof PersonaView && yetAdded) {
      console.log("NOT re-adding avatar", avatar);
      return;
    }

    console.log("Avatar at", attrs);
    this.$avatars.append(avatar.render().el);
    avatar.addedToMap(this, attrs);
    if (this.avatar_views.length === 0) {
      avatar.select();
    }
    this.avatar_views.push(avatar);
  }

  constrain_drag(e, ui) {
    if ($(e.target).closest('.npc_view').length) {
      return;
    }
    const constrained = this.constrain(ui.position);
    ui.position.left = constrained.left;
    ui.position.top = constrained.top;
  }

  //   * Xxxxxxxxxxxxxxx-+---+---+---+                                                                 
  //   * x   |   z   |   |   |   |   |                                                           
  //   * x---+---z---+---+---+---+---+                                                           
  //   * x   |   z   |   |   |   |   |                                                           
  //   * xzzzzzzzZ@@@@@@@@@@@@@@@@---+                                                        
  //   * x   |   @   | Oooooo|   @   |                                                         
  //   * +---+---@---+-o-+--o+---@---+                                                         
  //   * |   |   @   | o |  o|   @   |                                                         
  //   * +---+---@---+-oo+ooo+---@---+                                                         
  //   * |   |   @   |   |   |   @   |                                                         
  //   * +---+---@@@@@@@@@@@@@@@@@---+                                                         
  //   * |   |   |   |   |   |   |   |                                                           
  //   * +---+---+---+---+---+---+---+                                                           
  //   *
  //   *     o => the viewport
  //   *     @ => the tile_grid
  //   *     +- => the map coordinates with tiles depicted.
  //   *
  //   *     X is the offset from map origin to viewport origin (O)  (xxxxx, always negative)
  //   *     Z is the offset from tile_grid origin to map origin (X)  (multiple of tile_width or tile_height)
  //   *     O is the viewport origin (0,0)
  //   *
  //   *     so X + Z = offset from tile_grid origin to viewport origin
  //   *

  handle_drag_stop(ev, ui) {
    if ($(ev != null ? ev.target : void 0).closest('.npc_view').length) {
      return;
    }

    // The position of top-left corner of the map, in pixels relative to the viewport.
    //     * usually negative values !.
    //     * var map_origin_x = ui.position.left
    //     * var map_origin_y = ui.position.top
    //     * 

    // this is        X   +   Z  + offset
    const missing_x_px = ui.position.left / this.zoomFactor + this.middleground.tile_grid.origin_x + Math.floor(MAP_TILES_OFFSET / 2) * this.tile_width;
    const missing_y_px = ui.position.top  / this.zoomFactor + this.middleground.tile_grid.origin_y + Math.floor(MAP_TILES_OFFSET / 2) * this.tile_height;

    this.middleground.move_tiles_x(-Math.ceil(missing_x_px / this.tile_width));
    this.middleground.move_tiles_y(-Math.ceil(missing_y_px / this.tile_height));
    this.trigger("iconitos:move", ui.position.left, ui.position.top);
  }

  handle_drag_start(ev, ui) {
    if (ev.shiftKey) {
      const off = this.offsetTo(ev);
      this.dispatcher.trigger(Events.START_MULTISELECT, off);
      ev.preventDefault();
      return false;
    }
  }

  get x() {
    return parseInt(this.$content.css("left"), 10);
  }

  get y() {
    return parseInt(this.$content.css("top"), 10);
  }

  get total_height():number {
    return this.space.height * this.space.tile_height;
  }

  get total_width():number {
    return this.space.width * this.space.tile_width;
  }

  get viewport_height():number {
    return this.$viewport.height();
  }
  get viewport_width():number {
    return this.$viewport.width();
  }


  move(delta_x, delta_y) {
    const x = parseInt(this.$content.css("left"));
    const y = parseInt(this.$content.css("top"));

    this.$content.css("left", x + delta_x + "px");
    this.$content.css("top", y + delta_y + "px");
    this.trigger("iconitos:move", x + delta_x, y + delta_y);
  }

  remove() {
    if (!this.middleground) {
      return;
    }

    if(this.middleground)
      this.middleground.removeAll()

    this.npcs.forEach((npc) => npc?.remove());

    this.avatar_views.forEach((ava) => ava?.remove());

    return super.remove();
  }

  template() {
    this.document = [];
    this.render$(() => {
      this.div$('.map_content', { style: { zIndex: 200, position: "absolute", cursor: "-moz-grab" } }, () => {
        this.div$('.map_overlay', { style: { zIndex: 200, position: "absolute", left: 0, top: 0 } }, () => {
          this.div$('.map_tiles', { style: {  left: 0, top: 0 } });
          this.div$('.map_layers', { style: {  left: 0, top: 0, position: "absolute" , pointerEvents: 'none'} });
          this.div$('.map_zones', { style: {  left: 0, top: 0, position: "absolute" } });
          this.div$('.map_sprites', { style: {  left: 0, top: 0 } });
          this.div$('.map_avatars', { style: {  left: 0, top: 0 } });
        });
      });
    });
    return this.document.join('');
  }

  render() {
    this.$el.css({
      zIndex: 200,
      overflow: "hidden",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%"
    });
    this.$el.html(this.template());
    return this;
  }

};

class LayerView {

  tiles:Tile[];

  removeAll() {
    this.tile_grid.grid.forEach(row => row.forEach(tile => tile?.remove()));
  }

  el: HTMLElement;
  tile_grid: TileInfo;
  map_view: MapView;
  dispatcher: MapController;
  layer:LayerAttributes;

  constructor(options:{map_view:MapView, tiles: Tile[], el: HTMLElement, layer: LayerAttributes}){
    //super(options)
    this.el=options.el;
    this.tiles=options.tiles;
    this.layer=options.layer;
    this.map_view=options.map_view;
    this.dispatcher=options.map_view.dispatcher;
    this.tile_grid = {origin_x: 0, origin_y: 0, grid:[], width: 0, height: 0, width_px:0, height_px:0};
  }

  get space():Space{
    return this.map_view.space
  }
  get tile_height():number{
    return this.map_view.tile_height
  }
  get tile_width():number{
    return this.map_view.tile_width
  }

  findTile(x,y):TileView{
    return this.tile_grid.grid.flat().filter(Boolean).find((t) => t.model.x == x && t.model.y==y)
  }

  init_tiles() {
    let tile_grid_width = Math.ceil(this.map_view.viewport_width / this.map_view.zoomFactor / this.tile_width) + MAP_TILES_OFFSET;
    let tile_grid_height = Math.ceil(this.map_view.viewport_height / this.map_view.zoomFactor / this.tile_height) + MAP_TILES_OFFSET;

    // Prepare tile matrix 
    this.tile_grid.grid = new Array(tile_grid_height);

    for (let i=0;i < tile_grid_height;i++) {
      this.tile_grid.grid[i] = new Array(tile_grid_width);
    }

    this.tile_grid.origin_x = this.tile_grid.origin_y = 0; //in pixels
    this.tile_grid.width = tile_grid_width; //in tiles
    this.tile_grid.height = tile_grid_height; //in tiles
    this.tile_grid.width_px = (tile_grid_width * this.tile_width) ; //in px
    this.tile_grid.height_px = (tile_grid_height * this.tile_height); //in px

    for (let j = 0, maxh = this.tile_grid.height; j < maxh; j++) {
      for (let i = 0, maxw = this.tile_grid.width; i < maxw; i++) {
        this.tile_grid.grid[j][i] = this.place_tile(i * this.tile_width, j * this.tile_height);
      }
    }
  }

  move_tiles_x(count) {
    if (count === 0)
      return;

    let sign = 1;
    if (count < 0) {
      sign = -1;
      count = -count;
    }

    for (let row=0;row < this.tile_grid.height; row++) {
      if (sign === -1) {
        //if step is negative, we reverse the current row so we can apply the same algorithms
        this.tile_grid.grid[row].reverse();
      }
      const removed = this.tile_grid.grid[row].splice(0, count);
      removed.forEach((el) => el?.remove());

      Array.from(Array(count)).forEach((x,i) => {
        let offset_x;
        if (sign === 1) {
          offset_x = this.tile_grid.width_px + i * this.tile_width;
        } else {
          offset_x = (-i - 1) * this.tile_width;
        }
        this.tile_grid.grid[row].push(this.place_tile(this.tile_grid.origin_x + offset_x, this.tile_grid.origin_y + row * this.tile_height));
      });

      if (sign === -1) {
        this.tile_grid.grid[row].reverse();
      }
    }
    this.tile_grid.origin_x += sign * count * this.tile_width;
  }

  move_tiles_y(count) {

    const my_reverse_column = (matrix, col) => {
      const height = matrix.length;
      let z = void 0;
      let row = 0;
      const results = [];
      while (row < Math.floor(height / 2)) {
        z = matrix[row][col];
        matrix[row][col] = matrix[height - row - 1][col];
        matrix[height - row - 1][col] = z;
        results.push(row++);
      }
      return results;
    };

    let sign = 1;
    if (count === 0) {
      return;
    }
    if (count < 0) {
      sign = -1;
      count = -count;
    }
    if (sign === -1) {
      for (let i=0; i < this.tile_grid.width; i++) {
        my_reverse_column(this.tile_grid.grid, i);
      }
    }

    const removed = this.tile_grid.grid.splice(0, count);

    removed.forEach((row:any[]) => row.forEach((el:any) => el?.remove()))

    Array.from(Array(count)).forEach( (x,i)=> {
      const arri = [];
      let offset_y;

      for (let col=0;col < this.tile_grid.width; col++) {
        if (sign === 1) {
          offset_y = this.tile_grid.height_px + i * this.tile_height;
        } else {
          offset_y = (-i - 1) * this.tile_height;
        }
        arri.push(this.place_tile(this.tile_grid.origin_x + col * this.tile_width, this.tile_grid.origin_y + offset_y));
      }
      this.tile_grid.grid.push(arri);
    });

    if (sign === -1) {
      for (let i=0; i< this.tile_grid.width; i++) {
        my_reverse_column(this.tile_grid.grid, i);
      }
    }
    this.tile_grid.origin_y += sign * count * this.tile_height;
  }

  create_tile_view(x, y) {
    const tile = this.tiles.find(t => t.x == x && t.y==y );
    if (!tile) {
      return null;
    }
    tile.space = this.space;
    return new TileView({ model: tile, dispatcher: this.dispatcher });
  }

  place_tile(i, j) {
    const tile_view = this.create_tile_view(i, j);
    if (!tile_view) {
      return null;
    }
    tile_view.$el.css({
      left: i + "px",
      top: j + "px",
      border: "0px none",
      margin: "0px",
      padding: "0px",
      position: "absolute",
    });
    this.el.append(tile_view.el);
    return tile_view;
  }

}

export {MapView, dist};
