import * as _ from "underscore";

class NounType {

  name: string;
  fuzzy: boolean;
  label: string;
  placeholder: string;
  id: any;
  expectedWords: string[];

  constructor(opts:any = {}) {
    if (opts.id)
      this.id = opts.id;

    if (opts.name)
      this.name = opts.name;

    if (opts.expectedWords)
      this.expectedWords = opts.expectedWords;

    if(opts.label)
      this.label=opts.label;
  }

  match(fragment, context) {
    return this.suggest(fragment, context).length > 0;
  }

  suggest(fragment:string, context:any, promises?:Promise<any>[]):{label:string,id?:string}[] {
    if(!fragment || !this.expectedWords)
      return [];

    return this.expectedWords.reduce((acc,word) => {
      if (word.indexOf(fragment) > -1) {
        acc.push(word);
      }
      return acc;
    }, []).map((suggestion) => ({label: suggestion}));
  }

  toString():string {
    return this.label || ''
  }

};

export { NounType };

