import * as _ from 'underscore';
import { timestamp } from 'utils/dotry';

import BaseView from 'views/base_view';
import { ScriptExecutor, PlayStates } from 'models/script_executor';
import * as Backbone from 'backbone';
import { Script, fromTimeline, parseScript, ScriptTarget, parseScriptV2} from 'models/script';

const css_editable = require('modules/css_editable');

class ScriptView extends BaseView<Script> {

  slot_ms: number=250;
  ms_per_px: number=10;
  slot_px:number = this.slot_ms / this.ms_per_px;

  instance: ScriptExecutor;

  $player: JQuery<HTMLElement>;
  $position: JQuery<HTMLElement>;
  $playBtn: JQuery<HTMLElement>;
  $stopBtn: JQuery<HTMLElement>;
  $track: JQuery<HTMLElement>;
  $timeline: JQuery<HTMLElement>;
  $timeline_wrap: JQuery<HTMLElement>;
  $text_timeline: JQuery<HTMLElement>;
  $thumb: JQuery<HTMLElement>;
  $progress: JQuery<HTMLElement>;

  positionInterval: any;
  seeking: boolean;
  where: ScriptTarget;

  constructor(settings:{model:Script, instance:ScriptExecutor, dispatcher:any, where:ScriptTarget}) {
    super(settings);
    this.dispatchCreated('ScriptView');
    this.where=settings.where;

    if(location.toString().match('dev.viwoc.com'))
      (window as any)[this.cid] = this;

    this.instance = settings.instance;

    this.$el.html(this.template(this));

    this.$player = this.$(".player");
    this.$position = this.$(".position").css("display", "none");
    this.$playBtn = this.$(".playPauseBtn").attr("disabled", "disabled");
    this.$stopBtn = this.$(".stopBtn").attr("disabled", "disabled");
    this.$track = this.$(".track").css("display", "none");
    this.$timeline = this.$(".timeline").css({
      "white-space": "nowrap",
      display: "table-row"
    });
    this.$timeline_wrap = this.$(".timeline_wrap");
    this.$text_timeline = this.$(".text_timeline");
    this.$thumb = this.$(".thumb");
    this.$progress = this.$(".progress");

    this.model.on("complete", ((instance) => {
      clearInterval(this.positionInterval);
      this.$(".playBtn i").removeClass("fa-pause").addClass("fa-play");
      this.$(".stopBtn").attr("disabled", "disabled");
    }));
  }

  events() {
    return {
      "hover .script_char": (e) => this.highlight_avatar(e),
      "click .script_char": (e) => this.handle_click_char(e),
      "click .playBtn": (e) => this.togglePlay(e),
      "click .stopBtn": (e) => this.stop(e),
      "click .saveBtn": (e) => this.save(e),
      "mousedown .thumb": (e) => this.handle_thumb(e),
      "click .close": (e) => this.dispose(e),
      "keyup textarea": (e) => this.reparse()
    }
  };

  className = "script_view";

  reparse = _.throttle(ScriptView.prototype.doParse, 1000);

  action_template = _.template(`<span class="script_act <%= name %>"> <%= name + "." + action[0] + "(" + action[1] + ")" %> </span>`);

  slots_template = _.template(`
    <% if(_.any(people)){ %>
      <div class="<%= ms %>ms" style="width: <%=this.slot_px - 2%>px;background-color:red;display:inline-block;border:1px solid black;border-left:0;">
        <% for(var name in people){ %>
          <% for(var ac in people[name]){ %>
            <div class="script_char " data-character-name="<%=name%>" style="width: 100%;height:10px" data-content='<%= this.action_template(_.extend(obj,{name:name,action:people[name][ac]})) %>' data-action="<%=people[name][ac]%>" title="<%= ms %> ms">
            </div>
          <% } %>
        <% } %>
      </div>
    <% }else{ %>
      <div class="<%= ms %>ms empty" style="width: <%=this.slot_px - 2%>px;height:10px;display:inline-block;border:1px solid black;border-left:0;">
      </div>
    <% } %>
  `);

  template = _.template(`
    <div class="player">
      <div class="modal-header">
        <button type="button" class="close">&times;</button>
        <h4 class="modal-title">Script <%= model.get("name") %></h4>
      </div>
      <div class="modal-body">

        <span class="button playBtn"> <i class="fa fa-play"></i> </span>
        <span class="button stopBtn"> <i class="fa fa-stop"></i> </span>
        <span class="button saveBtn"> <i class="fa fa-arrow-circle-up"></i> </span>

        <div class="labels">
          <label class="script">Script: <strong><%= this.model.get("name") %> </strong></label>
          <label class="description">Description: <strong><%= this.model.get("description") %></strong></label>
        </div>

        <div class="track" style="width: 100px">
          <div class="progress"></div>
          <div class="thumb"></div>
        </div>
        <div class="timeline_wrap" style="width: 100%;overflow:auto;border:1px solid black;">
          <div class="timeline">
          </div>
        </div>
        <textarea wrap="off" class="text_timeline form-control" style="overflow:scroll;width:100%;height: <%= this.slot_px * 5 %>px; line-height:<%= this.slot_px %>px; font-size: <%= this.slot_px / 2 %>px;">
        </textarea>
      </div>
    </div>
`);

  _className() {
    return 'ScriptView';
  }

  dispose(ev) {
    ev.preventDefault();
    this.where.script_avatars.forEach((ch) => ch.remove());
    this.remove();
  }

  trackTime() {
    this.positionInterval = setInterval((event) => {
      const px = (timestamp() - this.instance.startTime) / this.ms_per_px;
      if (px >= this.$timeline.width()) {
        clearInterval(this.positionInterval);
      }
      this.$timeline_wrap.scrollLeft(px);
    }, this.ms_per_px);
  }

  handle_thumb(event) {
    this.$player.append($("<div id='blocker'></div>"));
    this.seeking = true;
    this.$player.mousemove((event) => {
      var targetOffset;
      if (typeof event.offsetX === "undefined") { // || typeof event.offsetY === "undefined") {
        targetOffset = $(event.target).offset();
        event.offsetX = event.pageX - targetOffset.left;
      }
      console.log(event.offsetX);
      this.$thumb.css("left", Math.max(0, Math.min(this.$track.width() - this.$thumb.width(), event.offsetX)));
    });

    this.$player.mouseup((event) => {
      var pos;
      this.seeking = false;
      this.$player.unbind("mouseup mousemove");
      pos = this.$thumb.position().left / this.$track.width();
      this.instance.setPosition(pos * this.instance.getDuration());
      return $("#blocker").remove();
    });
  }

  save(event) {

    this.model.derelativize(this.where.x,this.where.y);

    return this.model.save(null, {
      error: (jqXHR, text) => {
        return console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        return console.log("AJAX success:", textStatus);
      }
    });
  }

  stop(event) {
    this.instance.stop();
    this.$timeline_wrap.scrollLeft(0);
    clearInterval(this.positionInterval);
    this.$(".playBtn i").removeClass("fa-pause").addClass("fa-play");
    this.$thumb.css("left", 0);
  }

  get_char(event) {
    const name = $(event.target).data("character-name");
    return this.where.script_avatars.find((v) => v.model.get("name") === name);
  }

  handle_click_char(event) {
    //here, use textarea.setRangeText() and setSelectionRange() to select the line in the textarea that corresponds to this action
    this.action_modal(event);
  }

  action_modal(event) {
    const char_view = this.get_char(event);

    new (Backbone as any).BootstrapModal({
      content: char_view.model.get("name") + " " + $(event.target).data("action").toString()
    }).open();
  }

  highlight_avatar(event) {
    const char_view = this.get_char(event);
    char_view.$outer.toggleClass("hilited");
  }

  doParse() {
    var tl;
    try {
      //tl = parseScript(this.$text_timeline.val());
      tl = parseScriptV2(this.$text_timeline.val());
    } catch (error) {
      console.log(error);
      this.$text_timeline.css({ backgroundColor: 'lightcoral' });
      return;
    }
    console.log(tl)
    const script = fromTimeline(tl, this.slot_ms);
    console.log(script)
    this.model.set('script', script);

    this.$text_timeline.css({ backgroundColor: 'lightgreen' });
    this.render_timeline(this.slot_ms);
  }

  togglePlay(event) {
    switch (this.instance.playState) {
      case PlayStates.FINISHED:
        this.instance.setPosition(0);
        this.instance.play2();
        this.$(".playBtn i").toggleClass("fa-play fa-pause");
        this.trackTime();
        break;
      case PlayStates.READY:
        this.instance.play2();
        this.$(".playBtn i").toggleClass("fa-play fa-pause");
        this.trackTime();
        break;
      case PlayStates.PAUSED:
        this.instance.resume();
        this.trackTime();
        this.$(".playBtn i").toggleClass("fa-play fa-pause");
        break;
      case PlayStates.PLAYING:
        this.instance.pause();
        clearInterval(this.positionInterval);
        this.$(".playBtn i").toggleClass("fa-play fa-pause");
    }
  }

  handleLoadComplete(instance) {
    this.$timeline.css({
      display: "block",
      overflowX: "scroll",
      width: "100%",
      "white-space": "nowrap"
    });
    this.$track.css("display", "block");
    this.$progress.css("display", "none");
    this.$position.css("display", "block");
    this.$playBtn.attr("disabled", null);
    this.trackTime();
  }

  render_timeline(ms = this.slot_ms) {
    this.$timeline.html(this.model.bySlots(ms).map((v,k) => this.slots_template({ people: v, ms: k * ms })).join(''));

    (this.$("[data-content]") as any).popover({
      html: true,
      sanitize: false,
      trigger: "hover",
      animation: false
    });
  }

  render() {
    this.$el.css({
      zIndex: 200,
      backgroundColor: "white",
      width: 300,
      top: 80,
      left: 20,
      position: "absolute"
    });
    this.render_timeline(this.slot_ms);
    this.$text_timeline.html(this.model.textTimelineV2(this.slot_ms));
    this.instance.place_chars().then(() => {
      (this.$el as any).draggable();
      (this.$el as any).resizable({
        handles: "ne, se, sw, nw",
        start: function(ev, ui) {},
        stop: function(ev, ui) {},
        resize: (ev, ui) => {
          this.$el.css({
            width: ui.size.width,
            height: ui.size.height
          });
        }
      });
      this.doParse();
    });
    return this;
  }

};

_.extend(ScriptView.prototype, css_editable);

export {ScriptView};
