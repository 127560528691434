import Client from "@searchkit/instantsearch-client";
import Searchkit from "searchkit";

export type NpcHit = {
  name: string;
  name_tokens: any;
  description: string;
  created_at: string;
  uuid: string;
  user_id: string;
  status: string;
  sprites: any;
  viwoc_gid: string;
};

export type AssetHit = {
  group: string;
  name: string;
  name_tokens: any;
  group_tokens: any;
  url: string;
  uuid: string;
  viwoc_gid: string;
};

export type SoundHit = {
  category: string;
  name: string;
  description: string;
  url: string;
  id: string;
  uuid: string;
  duration: number;
  status: string;
  viwoc_gid: string;
};

export type AnchorHit = {
  game_name: string;
  name: string;
  space_name: string;
  viwoc_gid: string;
  uuid: string;
};

export type LocationHit = {
  label: string;
  hub_name: string;
  preview_url: string;
  space_uuid: string;
  viwoc_gid: string;
  uuid: string;
};

export const result_attributes = <T>(hit: T, keys?:string[]): T => Object.entries(hit).reduce((acc, [key, value]) => {
    if (key[0] == '_' || key == 'objectID') return acc;
    if(keys && !keys.includes(key) ) return acc;
    return {...acc, [key]: value};
  }, {} as T);

const search_npcs = new Searchkit({
  connection: {
    host: '/search/index',
  },
  search_settings: {
    search_attributes: [{field: 'name_tokens.analyzed', weight: 4}],
    result_attributes: ['name', 'name_tokens', 'description', 'created_at', 'uuid', 'user_id', 'status', 'sprites', 'viwoc_gid'],
    snippet_attributes: ['name_tokens'],
    highlight_attributes: ['name_tokens'],
    facet_attributes: [ ]
  },
})

// This is the configuration for Searchkit, specifying the fields to attributes used for search, facets, etc.
export const search_assets_config = {
  connection: {
    host: '/search/index',
  },
  search_settings: {
    search_attributes: [{field: 'name_tokens.analyzed', weight: 4}, {field: 'group_tokens.analyzed', weight: 1}],
    result_attributes: ['group', 'name', 'name_tokens', 'group_tokens', 'url', 'uuid', 'viwoc_gid', 'filename'],
    snippet_attributes: ['name_tokens', 'group_tokens'],
    highlight_attributes: ['name_tokens', 'group_tokens'],
    facet_attributes: [
    {
      attribute: 'group_tokens',
      field: 'group_tokens',
      type: 'string'
    }
  ]
  },
};

const search_assets = new Searchkit(search_assets_config as any);

// This is the configuration for Searchkit, specifying the fields to attributes used for search, facets, etc.
// sort by duration
const search_sounds = new Searchkit({
  connection: {
    host: '/search/index',
  },
  search_settings: {
    sorting: { default: {field: 'duration', order: 'desc'}},
    search_attributes: [{field: 'name.analyzed', weight: 4},{field: 'description.analyzed', weight: 4}, {field: 'category.analyzed', weight: 1}],
    result_attributes: ['category', 'name', 'description', 'url', 'id', 'uuid', 'duration', 'status', 'viwoc_gid'],
    snippet_attributes: ['name', 'description'],
    highlight_attributes: ['name', 'description'],
    facet_attributes: [
    {
      attribute: 'category',
      field: 'analyzed',
      type: 'string'
    }
  ]
  },
})


// This is the configuration for Searchkit, specifying the fields to attributes used for search, facets, etc.
const search_anchors = new Searchkit({
  connection: {
    host: '/search/index',
  },
  search_settings: {
    search_attributes: [{field: 'name_tokens.analyzed', weight: 4}, {field: 'space_name_tokens.analyzed', weight: 1}],
    result_attributes: ['game_name', 'name', 'space_name', 'viwoc_gid', 'uuid'],
    snippet_attributes: ['name', 'space_name', 'game_name'],
    highlight_attributes: ['name', 'space_name'],
    facet_attributes: [
    {
      attribute: 'group_tokens',
      field: 'group_tokens',
      type: 'string'
    }
  ]
  },
})

const search_locations = new Searchkit({
  connection: {
    host: '/search/index',
  },
  search_settings: {
    search_attributes: [{field: 'label.analyzed', weight: 4}, {field: 'hub_name.analyzed', weight: 1}],
    result_attributes: ['label', 'hub_name', 'preview_url', 'space_uuid', 'viwoc_gid', 'uuid'],
    snippet_attributes: ['label'],
    highlight_attributes: ['label'],
    facet_attributes: [
      {
        attribute: 'hub_name',
        field: 'hub_name',
        type: 'string'
      },
    ]
  },
})

 
export const searchAnchors = Client(search_anchors);
export const searchAssets = Client(search_assets);
export const searchSounds = Client(search_sounds);
export const searchLocations = Client(search_locations);
export const searchNpcs = Client(search_npcs);
