
import React, { useState, useEffect } from 'react';
import ListErrors from 'components/shared/ListErrors';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GameAttributes } from 'models/game';
import { Spaces } from 'api/agent';
import SpacePreview from 'components/spaces/preview';
import { SpaceAttributes } from 'models/space';

export default function GameForm({className, errors, submitForm, creation_status, game}:{className?:string, errors:string[],submitForm:(any)=>void,creation_status:string,game?:Partial<GameAttributes> & {clone_space?:SpaceAttributes}}){

  const [error, setError] = useState(null);
  const [state,setState] = useState(game || {});
  const [isLoaded,setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    Spaces.all({bag: 'template-spaces'}).then((spaces) => {
      setIsLoaded(true);
      setItems(spaces);
    },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, [])

  return <>
  <div className={className || ''}>
    <ListErrors errors={errors} />

    <form className="row" method="post" onSubmit={ev => {ev.preventDefault();submitForm(state)}}>

      <div className="col-md-6 offset-md-3 col-12">
        <div className="form-group">
          <label className="control-label" htmlFor="space_Name">Name:</label>
          <input className="form-control" type="text" value={state.name} name="space[name]" onChange={(e) => setState({...state, name:e.target.value})} />
        </div>
      </div>

      <div className="col-md-6 offset-md-3 col-12">
        <div className="form-group">
          <label className="control-label" htmlFor="space_description">Description:</label>
          <textarea className="form-control" value={state.description} name="space[description]" onChange={(e) => setState({...state, description:e.target.value})}></textarea>
        </div>
      </div>

      <div className="col-md-6 offset-md-3 col-12">
        <div className="form-group">
          <label className="control-label" htmlFor="space_description">Initial Landscape: {state.clone_space?.name}</label>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          {items.map((item) => {
            return <SpacePreview showUsers={false} space={item} onClick={(e) => setState({...state, clone_space: e})} hideActions={true} className="col-12 col-md-3"></SpacePreview>
          })}
        </div>
      </div>

      <Modal
        open={!!creation_status}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {
          (creation_status == 'creating' ?
          <CircularProgress />
          : 
          <div className="alert alert-danger">
            Error creating
          </div>
          )
        }
      </Modal>

      <div className="col-md-6 offset-md-3 col-12 mt-4">
        <div className="form-group">
          <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
        </div>
      </div>

    </form>
  </div>
</>
}
