import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import { promiseMiddleware} from './middleware';
import reducer from './reducer';

import { routerMiddleware } from 'react-router-redux'
import { createHashHistory as createHistory} from 'history';


type ApplicationState ={
  common: {
    appLoaded: boolean,
    currentUser: any
  },
  creator:{
    file:{}
    frames:Array<{ url:string,mask_url?:string, isLoading?:boolean}>
  }
}

// Build the middleware for intercepting and dispatching navigation actions
const history = createHistory();
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    //@ts-ignore
    return applyMiddleware(myRouterMiddleware, promiseMiddleware, thunk);
  } else {
    // Enable additional logging in non-production environments.
    //@ts-ignore
    return applyMiddleware(myRouterMiddleware, promiseMiddleware, createLogger(), thunk)
  }
};

//@ts-ignore
const store:any= createStore(reducer(history), composeWithDevTools(getMiddleware()));

export {history,store}
