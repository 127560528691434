/*<reference path=”app/javascript/interfaces.d.ts” />*/

import * as _ from 'underscore';
import Cookies from 'js-cookie'
import {dotry} from 'utils/dotry';
import { MapRouter } from './router';
import { MapView } from './views/map_view';
import { RecordingManager } from './managers/recording_manager';
import { SocketManager } from './managers/socket_manager';
import { history } from 'redux/store';
import { MAP_SET_SPACE } from 'constants/actionTypes';
import toastr from 'toastr';

import {Space} from 'models/space';
import verbs, { scriptable_verbs } from 'actions/verbs';
import { MapController} from './controller';
import * as Backbone from 'backbone';
import {Events} from './map_events';
import { PersonaView } from './views/persona_view';
import { NounType } from 'hip/noun';
import { store } from 'redux/store'
import CacheReferences from 'api/world';
import { MAP_SET_RECORDING } from 'constants/actionTypes';
import { AvatarView, AvatarHolder } from './views/avatar_view';
import { isString } from 'util';
import { Avatar } from 'models/avatar';
import { ScriptTarget } from 'models/script';

const nouns:NounType[] = Object.values(require('actions/nouns'));

// TODO implement typed events as explained in https://medium.com/@bterlson/strongly-typed-event-emitters-2c2345801de8
// or use https://github.com/bterlson/strict-event-emitter-types
function install_event_handlers(app:MapController, router:boolean=true) {

  var cache:CacheReferences;

  const dispatcher = app;

  cache = app.cache;

  if(router){
    cache.map_router = new MapRouter({ dispatcher });
    Backbone.history.start();
  }

  dispatcher.on(Events.NAVIGATE, (url, extra) => {
    if(cache.map_router)
      cache.map_router.navigate(url, { trigger: true });
  });

  dispatcher.on(Events.CREATE_ANCHOR, (event) => {
    app.createAnchor(event.name, event.x, event.y, app.space);
  });

  dispatcher.on(Events.ASK_QUESTION, (event) => {
    app.map.current_avatar.ask(event.question, event.answers, event.question_id);
  });

  dispatcher.on(Events.NEW_MODEL, (event) =>{
    app.new_model(event);
  });

  dispatcher.on(Events.EDIT_MODEL, (event) =>{
    app.edit_model(event);
  });

  dispatcher.on(Events.EDIT_CSS, (model) =>{
    app.edit_css(model);
  });

  dispatcher.on(Events.SET_SPACE, async (space:Space, position) => {

    let newLoc = `/spaces/${space.uuid}/${position.x},${position.y}`;
    if(history.location != newLoc){
      history.push(newLoc);
    }
    //store.dispatch({ type: MAP_SET_SPACE, space: space.attributes });

    //app.map?.remove();
    //app.saveNeigh(space);
    //app.space = space;

    //const mv = new MapView({ model: space, dispatcher: app });

    //$('#holder').append(mv.el);
    //app.map = mv;

    //app.map.init_tiles();
    //app.map.reset_npcs();
    //app.map.paint_anchors();

    //dispatcher.trigger(Events.SET_LOCAL_AVATARVIEW, {
    //  x: position.x,
    //  y: position.y,
    //  avatar: window.senyor
    //});

  });

  dispatcher.on(Events.SELECT_AVATAR, function(event) {
    app.map?.selectAvatar(event);
  });

  dispatcher.on(Events.MOVE_USER, function(event) {
    console.log('Moved User', event);
  });

  dispatcher.on(Events.SWITCH_AVATAR, function(event) {
    window.senyor.switch_avatar(event.shift, event.host);
  });

  dispatcher.on(Events.ADD_NPC_VIEW, function(event) {
    app.map.addNpcView(event);
  });

  dispatcher.on(Events.RENDERED_WINDOW_VIEW, function(win) {

    $(win.el).find('.spectrum').spectrum({
      allowEmpty: true,
      showInput: true,
      className: "full-spectrum",
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "rgb",
      localStorageKey: "spectrum.demo",
      move: function(color) {},
      show: function() {},
      hide: function(color) {},
      palette: [ //"rgb(153, 153, 153)","rgb(183, 183, 183)",
        [
          "rgb(0, 0, 0)",
          "rgb(67, 67, 67)",
          "rgb(102, 102, 102)",
          "rgb(204, 204, 204)", //"rgb(239, 239, 239)", "rgb(243, 243, 243)",
          "rgb(217, 217, 217)",
          "rgb(255, 255, 255)"
        ],
        ["rgb(152, 0, 0)",
          "rgb(255, 0, 0)",
          "rgb(255, 153, 0)",
          "rgb(255, 255, 0)",
          "rgb(0, 255, 0)",
          "rgb(0, 255, 255)",
          "rgb(74, 134, 232)",
          "rgb(0, 0, 255)",
          "rgb(153, 0, 255)",
          "rgb(255, 0, 255)"],
        [
          "rgb(230, 184, 175)",
          "rgb(244, 204, 204)",
          "rgb(252, 229, 205)",
          "rgb(255, 242, 204)",
          "rgb(217, 234, 211)",
          "rgb(208, 224, 227)",
          "rgb(201, 218, 248)",
          "rgb(207, 226, 243)",
          "rgb(217, 210, 233)",
          "rgb(234, 209, 220)",
          "rgb(221, 126, 107)",
          "rgb(234, 153, 153)",
          "rgb(249, 203, 156)",
          "rgb(255, 229, 153)",
          "rgb(182, 215, 168)",
          "rgb(162, 196, 201)",
          "rgb(164, 194, 244)",
          "rgb(159, 197, 232)",
          "rgb(180, 167, 214)",
          "rgb(213, 166, 189)",
          "rgb(204, 65, 37)",
          "rgb(224, 102, 102)",
          "rgb(246, 178, 107)",
          "rgb(255, 217, 102)",
          "rgb(147, 196, 125)",
          "rgb(118, 165, 175)",
          "rgb(109, 158, 235)",
          "rgb(111, 168, 220)",
          "rgb(142, 124, 195)",
          "rgb(194, 123, 160)",
          "rgb(166, 28, 0)",
          "rgb(204, 0, 0)",
          "rgb(230, 145, 56)",
          "rgb(241, 194, 50)",
          "rgb(106, 168, 79)",
          "rgb(69, 129, 142)",
          "rgb(60, 120, 216)",
          "rgb(61, 133, 198)",
          "rgb(103, 78, 167)",
          "rgb(166, 77, 121)",

          //"rgb(133, 32, 12)", "rgb(153, 0, 0)", "rgb(180, 95, 6)", "rgb(191, 144, 0)", "rgb(56, 118, 29)",
          //            "rgb(19, 79, 92)", "rgb(17, 85, 204)", "rgb(11, 83, 148)", "rgb(53, 28, 117)", "rgb(116, 27, 71)",
          "rgb(91, 15, 0)",
          "rgb(102, 0, 0)",
          "rgb(120, 63, 4)",
          "rgb(127, 96, 0)",
          "rgb(39, 78, 19)",
          "rgb(12, 52, 61)",
          "rgb(28, 69, 135)",
          "rgb(7, 55, 99)",
          "rgb(32, 18, 77)",
          "rgb(76, 17, 48)"
        ]
      ]
    });
  });

  dispatcher.on(Events.INITIALIZE_HUDVIEW, function({target}) {
    target.render();
    $('#holder').append(target.el);
  });

  dispatcher.on(Events.START_MULTISELECT, function(event) {
    console.log('Start Multi Select', event);
  });

  dispatcher.on(Events.INITIALIZE_PERSONAVIEW, function(event:{target:PersonaView}) {
    app.addPersona(event.target);
    event.target.setHip(verbs, nouns);
  });

  dispatcher.on(Events.TILE_ENTERED, ({tile, tile_view, avatar_view}) => app.triggerTileEvents(tile, avatar_view, tile_view));

  dispatcher.on(Events.TILE_EXIT, ({tile, avatar_view}) => app.stopTileEvents(tile, avatar_view));

  dispatcher.on(Events.PLAY_SCRIPT, ({id, where, repeat}) => app.play_script(id, where, {repeat}));

  dispatcher.on(Events.SCRIPT_PLAYER, ({id, where}) => app.script_player(id, where) );

  dispatcher.on(Events.SET_UUID, function(uuid) {
    window.senyor.user_uuid = uuid;
  });

  dispatcher.on(Events.SHOW_TEXT, ({id, where, thing}) => app.show_text(id, where, thing));

  dispatcher.on(Events.TRANSMUTE, ({id, target}) => {
    if (target.isLocal) {
      cache.SocketManager.localBecome(id);
    }

    let person=target;
    if(!(target instanceof PersonaView)){
      person=app.map.avatar_views.find(p => (p instanceof PersonaView) && (p.user_uuid == target.user_uuid))
    }
    app.transmute(id, person);
  });

  dispatcher.on(Events.VISIBILITY, (event) => {
    app.visibility(event.visibility, event.inn, event.target);
  });

  dispatcher.on(Events.PLAY_VIDEO, ({id, where, thing}) => app.play_video(id, where, thing));

  dispatcher.on(Events.SHOW_PICTURE, ({id, where, thing}) => app.show_picture(id, where, thing));

  dispatcher.on(Events.SHOW_WEB_RESOURCE, ({id, where, thing, promise}) => app.show_web_resource(id, where, thing, promise));

  dispatcher.on(Events.PLAY_SOUND, ({id, thing}) => app.play_sound(id, thing));

  dispatcher.on(Events.PERSONA_WALK, (event) => app.personaWalk(event));

  dispatcher.on(Events.TRAVEL_TO_ANCHOR, ({id}) => app.travel_to_anchor(id));

  dispatcher.on(Events.TRAVEL_TO, (space_id) => app.travel_to(space_id));

  dispatcher.on(Events.PERSONA_SAY, (event) => app.personaSay(event) );

  dispatcher.on(Events.SET_LOCAL_AVATARVIEW, (event) => {
    app.map.addAvatar(event.avatar, event);
    app.local_char = event.avatar;
    cache.SocketManager.setLocalAvatar(event.x, event.y, event.avatar);
    dispatcher.trigger(Events.MOVE_CURRENT, event);
  });

  dispatcher.on(Events.PLACE_AVATAR, ({who,x,y,hip, target}:{who: (AvatarView<Avatar>),x:number,y:number,hip:string, target:(ScriptTarget|AvatarHolder)}) => {
    if(hip == 'script'){
      who.setHip(scriptable_verbs, nouns);
    }
    app.addChar(who, x, y, target)
  });

  dispatcher.on(Events.REMOVE_AVATAR, (event) => app.removeChar(event.who));

  dispatcher.on(Events.TELETRANSPORT_CURRENT, (event) => app.teletransportCurrent(event.x, event.y));

  dispatcher.on(Events.MOVE_CURRENT, (event) => app.moveCurrent(event.x, event.y));

  dispatcher.on(Events.LOCAL_MOVED, (event) => cache.SocketManager.localMoved(event.x, event.y, event.avatar) );

  dispatcher.on(Events.LOCAL_SAYS, (event) => cache.SocketManager.localSays(event.what, event.avatar));

  dispatcher.on(Events.START_BRANCH, ({name, target}) => {
    if(!store.getState().map.recording){
      console.log('not recording')
      return
    }
    const recording_name = store.getState().map.recording_name
    const recorded = cache.recording_manager.stop_recording(target);
    cache.branches[name] = new RecordingManager({dispatcher});
    cache.branches[name].start_recording(name, target);
    store.dispatch({ type: MAP_SET_RECORDING, recording: "branch", name});
  });

  dispatcher.on(Events.START_RECORDING, ({name, target}) => {
    cache.recording_manager.start_recording(name, target);
    store.dispatch({ type: MAP_SET_RECORDING, recording: true, name});
  });

  dispatcher.on(Events.SAVE_RECORDING, ({name, target}) => {
    cache.recording_manager.save_recording(name, target, Object.keys(cache.branches))
  });

  dispatcher.on(Events.STOP_RECORDING, ({target}) => {

    const recording_branch = isString(store.getState().map.recording);

    if(recording_branch){
      const name = store.getState().map.recording_name;
      cache.branches[name].stop_recording(target)
      store.dispatch({ type: MAP_SET_RECORDING, recording: false});
    }else{
      cache.recording_manager.stop_recording(target);
      store.dispatch({ type: MAP_SET_RECORDING, recording: false});
    }
  });

  dispatcher.on(Events.PLAY_SCENE, (event) => {
    app.playScene(event.name, event.target);
  });

  dispatcher.on(Events.NOTIFY, (event) => {
    //jQuery.sticky(event);
    toastr.info('hola')
  });

  dispatcher.on(Events.ARRIVED, (event:{x:number,y:number,avatar_view:PersonaView}) => {
    app.handleArrived(event);
    app.saveCoords(event.x, event.y, event.avatar_view);
  });

  dispatcher.on(Events.INITIALIZE_MAP_VIEW, function({target}:{target:MapView}) {

    app.recording_manager = cache.recording_manager = new RecordingManager({ dispatcher: dispatcher });
    cache.SocketManager = new SocketManager({ dispatcher: dispatcher });
    cache.SocketManager.setSpace(target.space.get('uuid'));
  });

};

export {install_event_handlers};
