import React from "react";
import {useDesignContext} from "contexts/design_context";
import {List, ListItem, ListItemText} from "@material-ui/core";
import i18n from 'i18next';
import {ActionButton} from "./Header";

export default function ZindexPlacementTools({close}){

  const {designController} = useDesignContext();

  return <List>
    <ListItem button onClick={(e) => designController.bringToFront() } component="a">
      <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
        <ActionButton icon="bring-front" tooltipTitle={i18n.t('action.placement')} />
      </ListItemText>
    </ListItem>

    <ListItem button onClick={(e) => designController.sendBackwards()} component="a">
      <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
        <ActionButton icon="send-backward" tooltipTitle={i18n.t('action.placement')} />
      </ListItemText>
    </ListItem>

    <ListItem button onClick={(e) => designController.bringForward() } component="a">
      <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
        <ActionButton icon="bring-forward" tooltipTitle={i18n.t('action.placement')} />
      </ListItemText>
    </ListItem>

    <ListItem button onClick={(e) => designController.sendToBack() } component="a">
      <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
        <ActionButton icon="send-back" tooltipTitle={i18n.t('action.placement')} />
      </ListItemText>
    </ListItem>

  </List>
}
