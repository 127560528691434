import { play_game_url } from 'utils/urls';
import { loadGame, LoadingCallbacks } from 'packs/game';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  loadingProgressStyle: {},
  loading: {
    width: '100%',
    height: 10,
    marginTop: theme.spacing(5),
  },
}));

const LoadingScreen = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [ doLoadGame, setDoLoadGame] = useState(false);
  const [progress, setProgress] = useState(0);
  let uuid = props.match?.params?.uuid;
  let avatar_id = props.match?.params?.avatar_id || 'f6a92135-9e34-406c-820e-66b6b3939496';

  const phaserGameRef = useRef(null); // Ref for the game container div
  const gameRef = useRef(null); // To store the game instance

  const handleStartLoading = () => {
    if (!phaserGameRef.current) return;

    const config = {
      id: uuid,
      path_template: `/#${play_game_url('${id}')}`,
      avatar_id,
      loadingCallbacks: {
        setLoading: (isLoading) => {
          setLoading(isLoading);
        },
        setProgress: (progress) => {
          setProgress(progress);
        },
      },
    };

    loadGame(phaserGameRef.current, config).then((g) => {
      console.log('game:', g);
      gameRef.current = g;
    });
  };

  useEffect(() => {
    setDoLoadGame(true)
    // Clean up the game instance if it exists
    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
      }
    };
  }, [doLoadGame]);

  useEffect(() => {
    // Start loading the game when loading is complete and phaserGameRef is available
    if (doLoadGame && phaserGameRef.current) {
      handleStartLoading();
    }
  }, [doLoadGame]);

  return (
    <>
      <Container className={classes.container} style={{ display: loading ? 'block' : 'none' }}>
        <Typography variant="h4">Loading ...</Typography>
        <LinearProgress className={classes.loading} value={progress * 100} variant="determinate" />
      </Container>

      <div style={{ display: loading ? 'none' : 'block' }}>
        <div ref={phaserGameRef} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  );
};

export default LoadingScreen;

