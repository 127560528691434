import SpacePreview from './preview';
import { Redirect } from "react-router-dom";
import React from 'react';
import {Spaces} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import {DirectUploadProvider} from 'react-activestorage-provider';

import * as ActiveStorage from 'activestorage';
import { GameAttributes } from 'models/game';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
  import { SpaceAttributes } from 'models/space';
type SpaceNewState = {
  name:string,
  description:string,
  width:number,
  height:number,
  tile_width:number,
  tile_height:number,
  space_bg?:string,
  svg_bg?:string,
  created_space?:any,
  creation_status?: string,
  items: SpaceAttributes[],
  clone_space: SpaceAttributes,
};

class SpaceNew extends React.Component<{errors:any[], history:any, match: any},SpaceNewState> {

  static defaultProps = {
  }

  state:SpaceNewState = {
    name:"",
    description:"",
    width: 10,
    height: 10,
    tile_width: 800,
    tile_height: 800,
    space_bg:null,
    svg_bg:null,
    created_space:null,
    creation_status:null,
    items:[],
    clone_space: null,
  }

  componentDidMount(){
    Spaces.all({bag: 'template-spaces'}).then((spaces) => {
      this.setState({...this.state, items: spaces});
    }, (error) => console.log('error'))

  }

  render(){
    if (this.state.created_space) {
      return <Redirect to={`/spaces/${this.state.created_space.uuid}`} />
    }

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">New Space</h1>

          <ListErrors errors={this.props.errors} />
      
          <form className="form-horizontal" method="post" onSubmit={ev => {ev.preventDefault();this.submitForm()}}>
            <div className="form-group">
              <label className="control-label col-12 col-md-4" htmlFor="space_Name">Name:</label>
              <div className="col-md-8">
                <input className="form-control" type="text" value={this.state.name} name="space[name]" onChange={(e) => this.setState({...this.state, name:e.target.value})} />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
              <div className="col-md-8">
                <textarea className="form-control" value={this.state.description} name="space[description]" onChange={(e) => this.setState({...this.state, description:e.target.value})}></textarea>
              </div>
            </div>

            <div className="form-group">
              <div className="col-12">
                <div className="row">
                  {this.state.items.map((item) => {
                    return <SpacePreview showUsers={false} space={item} onClick={(e) => this.setState({...this.state, clone_space: e})} hideActions={true} className="col-12 col-md-3"></SpacePreview>
                  })}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="col-12">
                <a data-bs-target="#advanced" data-bs-toggle="collapse" href="#advanced">Show Advanced</a>
              </div>
            </div>

            <div className="fieldset collapse" id="advanced">
              <div className="form-group">
                <label className="control-label col-12 col-md-4" htmlFor="space_columns">Columns:</label>
                <div className="col-md-8">
                  <input className="form-control" min="5" max="15" type="number" value={this.state.width} name="space_columns" onChange={(e) => this.setState({...this.state, width:parseInt(e.target.value)})}/>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-12 col-md-4" htmlFor="space_rows">Rows:</label>
                <div className="col-md-8">
                  <input className="form-control" min="5" max="15" type="number" value={this.state.height} name="space_rows" onChange={(e) => this.setState({...this.state, height:parseInt(e.target.value)})}/>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-12 col-md-4" htmlFor="space_tile_width:">Tile width:</label>
                <div className="col-md-8">
                  <input className="form-control" min="500" max="2000" type="number" value={this.state.tile_width} name="space_tile_width" onChange={(e) => this.setState({...this.state, tile_width:parseInt(e.target.value)})}/>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-12 col-md-4" htmlFor="space_tile_height:">Tile height:</label>
                <div className="col-md-8">
                  <input className="form-control" min="500" max="2000" type="number" value={this.state.tile_height} name="space_tile_height"  onChange={(e) => this.setState({...this.state, tile_height:parseInt(e.target.value)})}/>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-12 col-md-4" htmlFor="space_tile_height:">Generative Background:</label>
                <div className="col-md-8">
                  <textarea className="form-control" value={this.state.svg_bg} name="svg_bg" onChange={(e) => this.setState({...this.state, svg_bg:e.target.value})}/>
                  <div className="btn btn-info" onClick={(e) => this.generateSvg(parseInt(prompt('How many?')))}>Generate!</div>
                </div>
              </div>

              <DirectUploadProvider
                onSuccess={(signedIds) => {this.setState({...this.state, space_bg: signedIds[0]})}}
                render={({ handleUpload, uploads, ready }) => (
                  <div className="form-group">
                    <label className="control-label col-12 col-md-4" htmlFor="space_bg">Bg</label>
                    <div className="col-md-8">
                      <input className="form-control" accept="image/*" type="file" name="space_bg" disabled={!ready} onChange={e => handleUpload(e.currentTarget.files)}/>
                    </div>

                    {uploads.map(upload => {
                      switch (upload.state) {
                        case 'waiting':
                          return <p key={upload.id}>Waiting to upload {upload.file.name}</p>
                        case 'uploading':
                          return (
                            <p key={upload.id}>
                              Uploading {upload.file.name}: {upload.progress}%
                            </p>
                          )
                        case 'error':
                          return (
                            <p key={upload.id}>
                              Error uploading {upload.file.name}: {upload.error}
                            </p>
                          )
                        case 'finished':
                          return (
                            <p key={upload.id}>Finished uploading {upload.file.name}</p>
                          )
                      }
                    })}
                </div>
                )}
              />
            </div>
            <Modal
              open={!!this.state.creation_status}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {
                (this.state.creation_status == 'creating' ?
                <CircularProgress />
                : 
                <div className="alert alert-danger">
                  Error creating
                </div>
                )
              }
            </Modal>

            <div className="form-group">
              <div className="col-md-8 col-md-offset-4">
                <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }

  get totalHeight(): number {
    return this.state.height*this.state.tile_height
  }
  get totalWidth(): number {
    return this.state.width*this.state.tile_width
  }

  generateSvg(n:number): void {
    this.setState({...this.state, svg_bg: makeSVG(this.totalWidth,this.totalHeight, n)})
  }

  async submitForm(): Promise<any> {

    const space = {
      name: this.state.name,
      game_id: this.props.match.params.uuid,
      description: this.state.description,
      width: this.state.width,
      height: this.state.height,
      tile_width: this.state.tile_width,
      tile_height: this.state.tile_height,
      bg: this.state.space_bg,
      svg_bg: this.state.svg_bg,
    }

    console.log('submit', space);

    this.setState({...this.state, creation_status: 'creating'})

    Spaces.create(space, this.state.clone_space?.uuid).then((e) => {
      this.setState({...this.state, created_space:e, creation_status: null});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}

const CONVENTIONAL_DIRECT_UPLOADS_PATH = '/rails/active_storage/direct_uploads'

const uploadToActiveStorage = (xml:string) => {
  const file = new File([xml], 'background.xml', {type: 'image/svg+xml'})

  const directUpload = new ActiveStorage.DirectUpload( file, CONVENTIONAL_DIRECT_UPLOADS_PATH,this)

  directUpload.create((e) => {
    console.log('Created!', e)
  });
}

const colorPalette = ["#9B2E69", "#D93750", "#E2724F", "#F3DC7B", "#4E9397"];

const rand = (max) => {
  return Math.floor(Math.random() * max);
};

const makeRects = (maxX, maxY, n=500) => {
  let rects = "";
  for (let i = 0; i < n; i++) {
    rects += `
      <rect
        x="${rand(maxX + 50) - 50}"
        y="${rand(maxY + 50) - 50}"
        width="${rand(200) + 20}"
        height="${rand(200) + 20}"
        opacity="0.8${rand(10)}"
        fill="${colorPalette[rand(5)]}"
      />
    `;
  }
  return rects;
};

const makeSVG = (w,h,n=500) => {
  const svg = `<svg width="${w}" height="${h}" id="svg" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="${w}" height="${h}" fill="${
    colorPalette[rand(5)]
  }" fill-opacity="0.5"/>
    ${makeRects(w, h, n)}
  </svg>`;
  return svg;
};

function toDataURI(text) {
   return "data:text/plain;charset=utf-8," + encodeURIComponent(text)
}

export default SpaceNew;
