export const formatNumber = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const leadingZero = number => (number < 10 ? '0' + number : number)

export const secondsToTime = sec => {
    sec = Math.round((sec || 0)*10)/10
    const hours = leadingZero(Math.floor(sec / 3600))
    const minutes = leadingZero(Math.floor((sec - hours * 3600) / 60))
    const seconds = leadingZero(sec - hours * 3600 - minutes * 60)
    if (hours !== '00') {
        return `${hours}:${minutes}:${seconds}`
    }
    return `${minutes}:${seconds}`
}

export default {
    formatNumber,
    secondsToTime,
    leadingZero,
}
