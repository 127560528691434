import { BaseModel } from "./base_model";

type WebResourceAttributes = {
  uuid: string,
  id: string,
  category: string,
  name: string,
  description: string,
  url: string,
  metadata:any,
}

class WebResource extends BaseModel {

  name: any;

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('WebResource');
  }

  _className() {
    return 'WebResource';
  }

  initialize(settings, opts) {
    this.name = settings.name;
    return super.initialize(settings, opts);
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "web_resources", this.id || ''].join('/');
  }

  get uuid():string{
    return this.get('uuid')
  }

  get canEdit():boolean{
    return this.get('canEdit')
  }

};

export { WebResource, WebResourceAttributes };

