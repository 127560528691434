import React, { useState } from 'react';
import BrandNav from './brand_nav';
import Hud from './hud';
import { MODES } from './constants';
import PlayToolboxDrawer from 'components/play_toolbox/play_toolbox_drawer';
import CreateToolboxDrawer from 'components/create_toolbox/create_toolbox_drawer';
import { MapMenu } from './map_menu';
import { connect } from 'react-redux';
import './loaded_content.scss'
import { MAP_SET_MODE } from 'constants/actionTypes';

function LoadedContentComponent(props){

  return <>
    <BrandNav/>
  
    <Hud app={props.controller}>
      <ul className="nav navbar-nav mr-auto">
  
        {props.space?.canEdit && 
          <li className='nav-item'>
            <a className="nav-link" onClick={(e) => props.setOpenDrawer(true)}>Tools</a>
          </li>
        }
        {props.game && props.space?.canEdit &&
          <li className='nav-item'>
            <a className="nav-link" href={`#/games/${props.game.uuid}`}>Back to Game</a>
          </li>
        }
  
        {Boolean(Object.keys(props.people ||{}).length) && 
          <li className='nav-item'>
            <a className="nav-link" onClick={(e) => props.setOpenPeople(true)}>People</a>
          </li>
        }
  
        {props.space?.canEdit && 
          <li className='nav-item'>
            <a className="nav-link" onClick={(e) => props.setOpenConfig(true)}>
              <i className="fal fa-cogs"></i>
            </a>
          </li>
        }
  
        <li className='nav-item'>
          <a className="nav-link" onClick={(e) => props.setOpenOverview(true)}>
            <i className="fal fa-eye"></i>
          </a>
        </li>
  
     </ul>
   </Hud>
  
   {props.mode == MODES.Normal ?
   <PlayToolboxDrawer app={props.controller} canEdit={props.space?.canEdit}/>
   :
   <i className="fa fa-play toggle-play-btn" onClick={(e)=>props.changeMode(MODES.Normal)}></i>
   }
  
  {props.space?.canEdit && <>
    {props.mode == MODES.Editing ?
    <CreateToolboxDrawer app={props.controller}/>
    :
    <i className="fa fa-edit toggle-create-btn" onClick={(e)=>props.changeMode(MODES.Editing)}></i>}
  </>
  }
  
   <MapMenu/>
  </>
  
}

const mapStateToProps = ({map, common}, ownProps) => { 
  ownProps.space = ownProps.space || map.space;
  return {
    ...ownProps, 
    mode: map.mode,
  }
}

const mapDispatchToProps = (dispatch) => ({

  changeMode: (mode) => {
    //return true;
    console.log('triggered', mode)
    dispatch({ type: MAP_SET_MODE, mode });
  }

});


const LoadedContent = connect(mapStateToProps,mapDispatchToProps)(LoadedContentComponent)

export {LoadedContent};


