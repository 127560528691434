import React from 'react';
import { ConnectDropTarget, DropTargetMonitor, DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from "react-dnd-html5-backend";
import { NativeTypes } from 'react-dnd-html5-backend'
import UrlInput from 'shared/components/inputs/url-input'
import { connect } from 'react-redux';
import { CREATOR_LOAD_URL, CREATOR_SET_TRIM, CREATOR_SET_FRAMES } from 'constants/actionTypes';
import { push } from 'connected-react-router'
import './upload.scss'
import { Giffer } from 'spriter/giffer';
import { FPS } from './trimmer';

const MIME_VIDEO = [
    ...[`mp4`, `mpg`, `mpeg`, `m4v`, `x-m4v`, `mov`, `quicktime`].map(t => `video/${t}`),
    `video/*`,
]


export interface TargetBoxProps {
  accepts: string[]
  onDrop: (props: TargetBoxProps, monitor: DropTargetMonitor) => void
  isOver: boolean
  canDrop: boolean
  connectDropTarget: ConnectDropTarget
  children: any[]
}

const Droppable2 = ({ className, accepts, onDrop, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accepts,
    drop: (item, monitor) => {
      if (onDrop) {
        onDrop(item, monitor);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <div
      className={className}
      ref={drop}
      style={{
        border: '2px dashed gray',
        padding: '20px',
        backgroundColor: isActive ? 'lightgreen' : 'white',
        opacity: canDrop ? 1 : 0.5,
      }}
    >
      {children}
    </div>
  );
};


// lets create a new UploadComponent with functional react component
const NewUploadComponent: React.FC<{onFileAccepted:Function, history:any, match:any}> = ({onFileAccepted,match,history}) => {

  const fileInputRef = React.useRef(null);

  const onError = (e) => {
    console.log('ERROR:', e);
  };

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <div className="trimmer-box col-12 col-md-8 offset-md-2">
          <Droppable2
            className="grey-box trimmer-drop"
            accepts={[NativeTypes.FILE]}
            onDrop={(props, monitor) => {
              if (monitor) onFileAccepted(monitor.getItem().files[0]);
            }}
          >
            <div className="d-none banner doing-drop">Release to drop</div>
            <div className="banner to-drop">
              Drag GIF or video file here
              <input
                type="file"
                ref={fileInputRef}
                accept={MIME_VIDEO.join(',')}
                multiple={false}
                onChange={(e) => onFileAccepted(e.target.files[0])}
              />
            </div>
          </Droppable2>

          <div className="grey-box trimmer-url">
            <div className="banner">Use an internet video or GIF</div>
            <div className="input-group">
              <UrlInput
                placeholder={`Enter any media or GIF URL`}
                fileTypes={['youtube', 'vimeo', '.mp4']}
                initialValue={match?.params?.url}
                onError={onError}
                onFileAccepted={(e) => onFileAccepted(e[0])}
                className="inputFile form-control input-lg"
              />
              <div className="input-group-append">
                <span className="input-group-text fab fa-2x fa-youtube"></span>
                <span className="input-group-text fab fa-2x fa-vimeo"></span>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    </div>
  );
};


const mapStateToProps = ({}) => ({
})

const mapDispatchToProps = dispatch => ({
  onFileAccepted: (file) => dispatch( dispatch => {
    dispatch({ type: CREATOR_LOAD_URL, file })

    if(file.name?.match(/.gif$/)) {
      const fr=new FileReader()
      fr.readAsArrayBuffer(file)
      fr.onload = (e) => {
        const giffer = new Giffer(fr.result);

        const duration=Math.round(FPS * giffer.computeTimeMs()/1000);
        const start=0;
        dispatch({ type: CREATOR_SET_TRIM, start, duration })

        const frames=[];

        for(let i=0, nframes=giffer.gif.numFrames(); i<nframes; i++){
          const {x, y, width, height, disposal, dataUrl} = giffer.loadFrame(i,true);;
          frames.push(dataUrl);
        }

        dispatch({ type: CREATOR_SET_FRAMES, frames });

        dispatch(push('/creator/chroma'))
      }
    }else if(file.name?.match(/.png$/)) {
      console.log('TODO split pngs');
      const fr=new FileReader()
      fr.readAsArrayBuffer(file)
      fr.onload = (e) => {
        // TODO  split img in
        dispatch({ type: CREATOR_SET_FRAMES, frames });

        dispatch(push('/creator/chroma'))
      }
    }else{
      dispatch(push('/creator/trimmer'));
    }
  }),
});

const Upload = connect(mapStateToProps, mapDispatchToProps)(NewUploadComponent)

export {Upload}
