import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Box, IconButton } from '@material-ui/core';
import { CloudUpload, Videocam, PlayArrow, Pause, Stop } from '@material-ui/icons';
import { DndProvider, useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import { Videos } from 'api/agent';

const ItemTypes = {
  VIDEO: 'video',
};

const VideoDropZone: React.FC<{ onDrop: (file: File) => void }> = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.VIDEO, NativeTypes.FILE],
    drop: (item: { files: FileList }) => {
      const file = item.files[0];
      if (file && file.type.startsWith('video/')) {
        onDrop(file);
      } else {
        alert('Please upload a valid video file.');
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        padding: 20,
        textAlign: 'center',
        backgroundColor: isOver ? '#e0e0e0' : 'white',
      }}
    >
      <input
        accept="video/*"
        style={{ display: 'none' }}
        id="video-file-upload"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file && file.type.startsWith('video/')) {
            onDrop(file);
          } else {
            alert('Please upload a valid video file.');
          }
        }}
      />
      <label htmlFor="video-file-upload">
        <Button variant="contained" component="span" startIcon={<CloudUpload />}>
          Upload Video
        </Button>
      </label>
      <p>Drag 'n' drop a video file here, or click to select one</p>
    </div>
  );
};

const VideoUploadForm: React.FC<any> = ({onCreated}:any) => {
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [uploading, setUploading] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (videoFile) {
      const objectUrl = URL.createObjectURL(videoFile);
      setVideoSrc(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      setVideoSrc(null);
    }
  }, [videoFile]);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      const handleEnded = () => {
        setIsPlaying(false);
      };
      videoElement.addEventListener('ended', handleEnded);
      return () => {
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [videoSrc]);

  const handleFileUpload = (file: File) => {
    setVideoFile(file);
  };

  const startRecording = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStream(mediaStream);
      const mediaRecorder = new MediaRecorder(mediaStream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks: Blob[] = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setVideoFile(new File([blob], 'recorded_video.webm', { type: 'video/webm' }));
        mediaStream.getTracks().forEach((track) => track.stop());
        setStream(null);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing camera and microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const togglePlayback = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSave = async () => {
    if (!videoFile) return;

    setUploading(true);

    try {
      const response = await Videos.create(prompt('Name?'), videoFile);
      onCreated?.(response);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: 16 }}>
        <VideoDropZone onDrop={handleFileUpload} />

        <Button
          variant="contained"
          onClick={isRecording ? stopRecording : startRecording}
          startIcon={isRecording ? <Stop /> : <Videocam />}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </Button>

        {isRecording && stream && (
          <video
            style={{ width: '100%', maxWidth: '600px' }}
            autoPlay
            muted
            playsInline
            ref={(videoElement) => {
              if (videoElement) {
                videoElement.srcObject = stream;
              }
            }}
          />
        )}

        {videoFile && !isRecording && (
          <Box>
            <Typography variant="subtitle1">{videoFile.name}</Typography>
            <video
              ref={videoRef}
              src={videoSrc || ''}
              controls
              style={{ width: '100%', maxWidth: '600px' }}
            />
            <Box display="flex" alignItems="center" style={{ gap: 8 }}>
              <IconButton onClick={togglePlayback}>
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={uploading}
                startIcon={<CloudUpload />}
              >
                {uploading ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default VideoUploadForm;

