import {linesStyles} from 'components/glitch/utils';
import { fabric } from 'fabric';

import { Gif } from './objects';
import { Line, } from './objects';

import { FabricObject } from './utils';
//import { Code } from './objects/Element';
//import Svg, { ISvgOption } from './objects/Svg';

export interface ObjectSchema {
  create: (...option: any) => fabric.Object;
}

export interface CanvasObjectSchema {
  [key: string]: ObjectSchema;
}

export const createCanvasObject = (objectSchema: CanvasObjectSchema) => objectSchema;

const CanvasObject: CanvasObjectSchema = {
  group: {
    create: ({ objects, ...option }: { objects: FabricObject[] }) => new fabric.Group(objects, option),
  },
  'i-text': {
    create: ({ text, ...option }: { text: string }) => new fabric.IText(text, option),
  },
  textbox: {
    create: ({ text, ...option }: { text: string }) => new fabric.Textbox(text, option),
  },
  triangle: {
    create: (option: any) => new fabric.Triangle(option),
  },
  circle: {
    create: (option: any) => new fabric.Circle(option),
  },
  rect: {
    create: (option: any) => new fabric.Rect(option),
  },
  image: {
    create: ({ element = new Image(), ...option }) => new fabric.Image(element, {
      ...option,
      crossOrigin: 'anonymous',
    }),
  },

  deco: {
    create: ({ element = new Image(), ...option }) => {
      const img = new fabric.Image(element, { ...option, crossOrigin: 'anonymous', })
      return img;
    },
  },

  signpost: {
    create: ( {element, ...option} ) => {
      if(element){
        const img = new fabric.Image(element, { ...option, crossOrigin: 'anonymous', })
        return img;
      }

      const circle = new fabric.Circle({ ...option, ...linesStyles.signpost, })
      return circle;
    }
  },
  platform_line: {
    create: (option) => {
      const line = new fabric.Line(option);
      return line;
    },
  },
  ladder: {
    create: (option) => {
      const line = new fabric.Rect(option);
      return line;
    },
  },
  wall: {
    create: (option) => {
      const line = new fabric.Rect(option);
      return line;
    },
  },

  polygon: {
    create: ({ points, ...option }) => {
      if(option.glitch_type == 'platform_line') {
        const group = new fabric.Group([], { ...option, glitch_type: null });
        points.forEach((point: any, i) => {
          if(i==points.length-1) return;
          const line = new fabric.Line([point.x, point.y, points[i+1].x, points[i+1].y], {
            ...linesStyles.platform_lines,
            ...option,
          });
          group.addWithUpdate(line);
        })
        return group;
      }
      return new fabric.Polygon(points, { ...option, perPixelTargetFind: true, })
    }
  },
  line: {
    create: ({ points, ...option }: { points: any }) => new Line(points, option),
  },
  //element: {
  //  create: ({ code, ...option }:{ code: Code }) => new Element(code, option) as unknown as fabric.Object,
  //},
  //iframe: {
  //  create: ({ src, ...option }: { src: string }) => new Iframe(src, option) as unknown as fabric.Object,
  //},
  //video: {
  //  create: ({ src, file, ...option }: { src: string; file: File }) => new Video(src || file, option) as unknown as fabric.Object,
  //},
  gif: {
    create: (option: any) => new Gif(option) as unknown as fabric.Object,
  },
  //svg: {
  //  create: (option: ISvgOption) => new Svg(option) as unknown as fabric.Object,
  //},
};

export default CanvasObject;
