import { useState } from 'react';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

const ClearableTextField: React.FC<TextFieldProps> = (props) => {
  const {onChange, ...otherProps} = props;

  const handleClear = () => {
    onChange?.({target: {value: ''}} as any);
  };

  return (
    <TextField
      maxRows={4} // Adjust default rows if needed
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          maxHeight: '150px', // Set max height for scrollable content
          overflow: 'auto', // Enable scroll when content exceeds height
        },
      }}
      {...otherProps}
    />
  );
};

export default ClearableTextField;

