
export const white=  '#fff';
export const lila_oscuro= '#541262';
export const lila_oscuro_rgb= [84, 18, 98];
export const lila_claro= '#EFBCD5';

export const primary_00= '#9C94C7';
export const primary_10= '#8379B9';
export const primary_20= '#5F54A0';
export const primary_40= '#473F78';
export const primary_60= '#2E294E';
export const primary_80= '#2E294E';

export const secondary_00= '#EFBCD5';
export const secondary_10= '#E79CC1';
export const secondary_20= '#DB6BA3';
export const secondary_40= '#CF3A85';
export const secondary_60= '#B52C70';
export const secondary_80= '#832052';

