import { BaseModel } from "./base_model";
import { IEventable, InteractionEvent } from "./npc_placement";
import _ from "underscore";
import { v4 as uuidv4 } from 'uuid';

type AnchorAttributes = {
  id: string,
  name: string,
  x: number,
  y: number,
  uuid: string,
  space_id: string,
  events: Array<InteractionEvent>, //{string: string[]} | [string,string,any],
}

class Anchor extends BaseModel implements IEventable {

  constructor(attributes, options) {
    super(attributes,options);
    this.dispatchCreated('Anchor');
  }

  _className() {
    return "Anchor";
  }

  get events():InteractionEvent[] {
    return this.get('events') || []
  }

  /* TODO keep in sync with npc_placement # updateEvent */
  updateEvent(id: string, event:Partial<InteractionEvent>) {
    Object.assign(this.events.find((e) => e.id==id), event)
    this.set({ events: this.events });
  }

  removeEvent(id:string) {
    const evs = this.events.filter((e) => !(e.id == id))
    this.set({ events: evs });
  }

  addEvent(event, command, args, id=null) {
    const evs = this.events;
    evs.push({event, command, args, id: id || uuidv4()});
    this.set({ events: evs });
  }

  initialize(attrs, options) {
    return super.initialize(attrs, options);
  }

  url = () => {
    if(this.get('space_id'))
      return [(window as any).Niconitos.config.api, "spaces", this.get('space_id'), "anchors", this.id || ''].join('/');
    else
      return [(window as any).Niconitos.config.api, "anchors", this.id || ''].join('/');
  }

  get y():number {
    return this.get('y')
  }

  get x():number {
    return this.get('x')
  }

  fullName() {
    return this.get('name')
  }

};

export { Anchor, AnchorAttributes};
