import { BaseModel } from "./base_model";
import * as _ from "underscore";
import { Space, LayerAttributes } from "./space";
import { NpcPlacementAttributes } from "./npc_placement";

type TileAttributes = {
  name: string,
  x: number, 
  y: number, 
  events: {[key:string]:string[]}
  id: string,
  space_id: string,
  public_filename: string,
}

class Tile extends BaseModel {

  space:Space;
  layer:LayerAttributes;

  _className() {
    return 'Tile';
  }

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Tile');
    this.layer =options.layer;
  }

  protectedAttributes() {
    return super.protectedAttributes().concat(["public_filename", "space_id"]);
  }

  initialize(attributes, options) {
    return super.initialize(attributes, options);
  }

  get public_filename() {
    return this.get('public_filename');
  }

  get x() {
    return this.get('x');
  }

  get y() {
    return this.get('y');
  }

  url = () => {
    return [this.space.url(), "tiles", this.id || ''].join('/');
  }


};

export { Tile, TileAttributes};
