import EventEmitter from 'eventemitter3';
import {fabric} from 'fabric';

class LoadManager extends EventEmitter {

  private static _instance: LoadManager;

  public loadQueue:string[] = [];
  public maxLength:number = 0;

  constructor() {
    super();
  }

  public static get instance(): LoadManager {
    if (!LoadManager._instance) {
      LoadManager._instance = new LoadManager();
    }
    return LoadManager._instance;
  }

  public get loading(): boolean {
    return this.loadQueue.length > 0;
  }

  private startProcessing(src:string){
    this.loadQueue.push(src);
    this.maxLength+=1;

    if(this.loadQueue.length === 1){
      this.emit('start');
    }
  }

  public async fetch(src:string, init?: RequestInit): Promise<Response> {
    this.startProcessing(src);

    return fetch(src, init).then((response) => {
      this.markProcessed(src);
      return response;
    }).catch((error) => {
      this.markProcessed(src);
      throw error;
    });
  }

  private markProcessed(src:string){
    const idx = this.loadQueue.indexOf(src);

    if(idx > -1){
      this.loadQueue.splice(this.loadQueue.indexOf(src),1);
    } else {
      console.error('Image not found in loadQueue', src);
    }

    if(this.loadQueue.length > 0){
      this.emit('progress', 100 - (this.loadQueue.length / this.maxLength) * 100, src);
    }else{
      this.maxLength=0;
      this.emit('progress', 0, src);
      this.emit('complete');
    }

  }

  public loadImage(src,crossOrigin=null):Promise<HTMLImageElement>{

    this.startProcessing(src);

    return new Promise((accept, reject) => {
      const img = new Image();
      img.crossOrigin = crossOrigin;
      img.onload = () => {
        this.markProcessed(src);
        accept(img);
      }
      img.onerror = () => {
        this.markProcessed(src);
        reject();
      }
      img.src = src;
    })

  }
  public loadFabricImage2:(src:string)=>Promise<fabric.Image> = (src) => {
    this.startProcessing(src);

    return new Promise((accept,reject) => {
      fabric.Image.fromURL(src, (image:any, error:boolean)=>{
        this.markProcessed(src);

        if(error){
          reject();
          return
        }
        image.needsItsOwnCache=()=>true;
        accept(image);
      }, {crossOrigin: 'anonymous'})
    })

  }

}

export default LoadManager;
