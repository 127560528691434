import { fabric} from "fabric";

const hasTransparency = async (url:string):Promise<boolean> => {
  const c= $('<canvas>')[0] as HTMLCanvasElement;
  const img = await loadImg(url);
  c.width=img.naturalWidth;
  c.height=img.naturalHeight;

  const ctx=c.getContext('2d');
  ctx.drawImage(img,0,0);
  const imgd=ctx.getImageData(0,0, c.width,c.height);

  for(let i=0; i < imgd.data.length; i+=4){
    if(imgd.data[i+3] < 128){
      return true;
    }
  }

  return false;
}

const loadImg = async (url:string):Promise<HTMLImageElement> => new Promise((accept,reject) => {
  const img = new Image();
  img.onload = () => accept(img)
  img.onerror= (e) => reject(e)
  img.src=url;
})

const opaque = async (img:HTMLImageElement):Promise<fabric.Image> => new Promise((accept,reject) => {
  const kanva:HTMLCanvasElement = $(`<canvas width=${img.width} height=${img.height}>`)[0] as HTMLCanvasElement;
  const ctx = kanva.getContext('2d');
  ctx.drawImage(img, 0,0);
  const imgd = ctx.getImageData(0,0,kanva.width,kanva.height);

  for(let i=3; i<imgd.data.length; i+=4){
    imgd.data[i]=255;
  }
  ctx.putImageData(imgd,0,0);
  loadFabricImg(kanva.toDataURL()).then(accept)
})

const loadFabricImg = async (url:string, extraOpts?:any):Promise<fabric.Image> => new Promise((accept,reject) => 
  fabric.Image.fromURL(url, ((img) => {
    accept(img)
  }), Object.assign({crossOrigin: 'anonymous'}, extraOpts))
)

export {hasTransparency, loadImg, loadFabricImg, opaque}


