import React, { Component } from "react";
import Card  from 'react-bootstrap/Card';

type PictureProps = {
  id: string,
  name: string,
  description: string,
  image_file_name: string,
  image_url: string,
}

class Picture extends Component<{picture:PictureProps , onClick?: Function},{isMouseInside:boolean}> { 

  constructor(props:{picture:PictureProps , onClick?: Function}) {
    super(props)
    this.state = { isMouseInside: false };
  }

  render() {
    return (<Card>
      <Card.Header>
        <strong>{this.props.picture.name}</strong>
      </Card.Header>

      <Card.Img variant="top" src={this.props.picture.image_url} />

      <Card.Body onClick={(e) =>this.props.onClick && this.props.onClick()}>
        <Card.Text>{this.props.picture.description}</Card.Text>
      </Card.Body>

      </Card>)
  }
}
export {Picture, PictureProps}
