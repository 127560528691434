import GamePreview from './preview';
import React, { useState, useEffect } from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import { Games } from 'api/agent';
import { GameAttributes } from 'models/game';
import { withRouter } from 'react-router-dom'
import {new_creatix_game_url, game_welcome_url} from 'utils/urls';
import {useSelector} from 'react-redux';
import {T} from 'components/utils/t';

function GameList({ limit, history }) {

  const currentUser = useSelector((state:any) => state.common.currentUser);

  const [games, setGames] = useState<GameAttributes[]>([]);
  const [pagination, setPagination] = useState(null);

  const [showOnlyMine, setShowOnlyMine] = useState(false);
  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);


  useEffect(() => {
    async function fetchGames() {
      if(showOnlyMine){
        const {data,pagination} = await Games.all({ mine: true, q: '*' });
        if(!isMounted)return;
        setGames(data.slice(0, limit || data.length));
        setPagination(pagination);
      } else {
        const {data,pagination} = await Games.all();
        if(!isMounted)return;
        setGames(data.slice(0, limit || data.length));
        setPagination(pagination);
      }
    }

    fetchGames();

  }, [limit, showOnlyMine]);

  const toggleShowOnlyMine = (mine) => setShowOnlyMine(mine);

  const fetchPage = (page) => {
    if(showOnlyMine){
      Games.all({ mine: true, q: '*', page }).then(({data,pagination}) => {
        if(!isMounted)return;
        setGames(data);
        setPagination(pagination);
      });
    } else {
      Games.all({ page }).then(({data,pagination}) => {
        if(!isMounted)return;
        setGames(data);
        setPagination(pagination);
      });
    }
  };

  return (
      <div className="row mt-4">
        <div className="col-12">
          {currentUser &&
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className={`nav-link ${!showOnlyMine ? 'active' : 'inactive'}`} aria-current="page" onClick={() => toggleShowOnlyMine(false)}><T k="games.list.all_games">All Games</T></a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${showOnlyMine ? 'active' : 'inactive'}`} onClick={() => toggleShowOnlyMine(true)}>
                <T k="games.list.my_game">My Games</T>
              </a>
            </li>
          </ul>
        }

        </div>
        {games.map((game) => {
          //const url = play_game_url(game.start_space || game.spaces[0].uuid)
          const url = game_welcome_url(game.uuid)
          return (
            <div className="col-12 col-md-3" key={`container-${game.uuid}`} >
              <GamePreview game={game} currentUser={currentUser} onClick={() => window.open(`/#${url}`, '_blank')}/>
            </div>
          );
        })}

        <div className="col-12">
          {pagination ? <ListPagination pagination={pagination} pager={fetchPage} /> : null}
        </div>

        {currentUser &&
        <div className="col-12 col-md-3">
          <div className="btn btn-primary btn-xl" onClick={() => history.push(new_creatix_game_url())}><T k="games.list.create_game_btn">Create Game</T></div>
        </div>
      }

      </div>
  );
}

export default withRouter(GameList)
