import React from 'react';
import TimelineRuler from "./timeline-ruler";
import { TimelineFramePopover } from "./timeline-frame";
import { RULER_EXTRA_PIXEL, SECONDS_LENGTH, TRACK_HEIGHT } from "./seconds-sizes";
import { Timelined, Frame } from './timeline';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

const duration = (frames:{[name:string]: Frame[]}):number => Object.values(frames).reduce((max,frames) => {
  const longest = last(sortBy(frames, [fr => fr.second + fr.duration]))
  return (longest.second+longest.duration) > max ? (longest.second+longest.duration) : max;
}, 0)

export default class TimelineFrames extends React.Component<Timelined & {seconds_total?: number, updateFrames:Function},Timelined & {seconds_total: number, draggable:any} > {

  constructor(props) {
    super(props);

    this.state = {
      frames: props.frames,
      seconds_total: props.seconds_total || duration(props.frames), 
      layers: props.layers,
      draggable: null
    };
  }

  handleFrameDragStart(e, layerKey, frame) {
    const clientX = e.clientX;
    const newstate = {
      ...this.state, 
      draggable:{
        currentLayer: layerKey,
        currentElement: frame,
        startX: clientX
      }
    };
    this.setState(newstate);
  }

  handleFrameDraggin(e) {
    if(!this.state.draggable) return;
    const currentClientX = e.clientX;
    const moveMouseX = currentClientX - this.state.draggable.startX;
    const layerKey = this.state.draggable.currentLayer;
    var frames = this.state.frames;
    this.state.draggable.currentElement.second = Math.max(this.state.draggable.currentElement.second + moveMouseX/SECONDS_LENGTH, 0);
    frames[layerKey] = frames[layerKey].sort((a,b) => a.second - b.second)

    this.setState({
      ...this.state, 
      frames: frames,
      draggable: {
        currentLayer: layerKey,
        currentElement: this.state.draggable.currentElement,
        startX: currentClientX,
      }
    });
    this.props.updateFrames(frames);
  }

  handleFrameDragEnd() {
    Object.values(this.state.frames).flat(1).forEach((f:any)=>delete f.selected)
    this.setState({
      ...this.state, 
      draggable:null
    });
  }

  get totalWidth():number {
    return RULER_EXTRA_PIXEL + (this.props.seconds_total * SECONDS_LENGTH)
  }

  trackWidth():number{
    return this.state.seconds_total*SECONDS_LENGTH;
  }

  trackHeight(overlapped):number{
    return overlapped.reduce((max, [sec, frames]) => frames.length > max ? frames.length : max, 1) * TRACK_HEIGHT
  }

  render() {
    return (
      <div className="timeline-editor__frames" onMouseUp={() => this.handleFrameDragEnd()}  onMouseLeave={() => this.handleFrameDragEnd()}>
        <TimelineRuler seconds_total={this.state.seconds_total}/>

        {this.state.layers.map((layer) => {

          const overlapped = overlaps(this.state.frames[layer.id]);
          overlapped.forEach((x) => x[1].forEach((x:any)=>delete x.idx));

          return <div className="timeline-editor__frames-container" key={layer.id}>
            <div className="timeline-editor__frames-layer"
              style={{width: this.trackWidth(), height: this.trackHeight(overlapped)}}
              onMouseMove={(e) => this.handleFrameDraggin(e)}>

              {this.state.frames[layer.id] && this.state.frames[layer.id].map((frame, index) => {
                const found=overlapped.find(([sec,frames]) => frames.find(x=>x==frame));
                if(!found)
                  return;
                const [t, slot] = found;
                let idx = slot.indexOf(frame);
                if(idx==-1)return
                (frame as any).idx = idx;

                overlapped.forEach(([sec,frames]) => {
                  if(frames.length==1) return;
                  const found = frames.indexOf(frame);
                  if(found != -1 && found != idx){
                    const ix=frames.findIndex(x => !x?.hasOwnProperty('idx'));
                    if(ix==-1)return
                    const v=frames[ix];
                    frames[ix]=frames[idx];
                    const v2=frames[idx];
                    frames[idx]=frame;
                    frames[found]=v;
                    frames[ix]=v2;
                  }
                });

                return <TimelineFramePopover dragging={!!this.state.draggable} track={idx} key={frame.id} index={index} frame={frame} layerKey={layer.id} dragEvent={this.handleFrameDragStart.bind(this)} />
              }
              )}

            </div>
          </div>
        }
      )}
  </div>
    );
  }
}

type Overlap = {second:number,duration:number};

const overlaps = (frames: Frame[]):Array<[number,Array<Frame>]> => {

  const segments = frames.map((frame) => [frame.second, frame.second+frame.duration]).flat(1).sort((a,b)=>a-b);

  return segments.map((start, i) => [start, overlap_at(start, (segments[i+1] || start) - start, frames).map(x=>x[0])])

}

const overlap_at = (second:number,duration:number,frames:Frame[]):[Frame,Overlap][] => {

  if(duration === 0) return [];

  return frames.reduce((overlapping,frame) => {
    if(frame.second <= second && frame.second + frame.duration > second)
      overlapping.push([frame, {second, duration: (frame.second+frame.duration - second)}])
    else if(frame.second > second && frame.second < second+duration)
      overlapping.push([frame, {second: second+duration-frame.second, duration: (second+duration)-frame.second}])
    return overlapping
  }, [])
}
