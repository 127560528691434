import React from 'react'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import uniqueId from 'lodash/uniqueId'
import debounce from 'lodash/debounce'
import urlUtils from 'shared/util/url-utils'

export const isGiphyUrl = url => (/giphy\.com\/(gifs|stickers)\/.*|gph\.is\/.*/i).test(url) && 'giphy'
export const isVimeoUrl = url => /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)(#.*)?$/.test(url) && 'vimeo'
export const isYoutubeUrl = url => {
    const match = url.match(
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    )
    return (match && match.length > 1) ? 'youtube' : null
}

export const URL_TYPE = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  VIDEO: 'video',
  GIPHY: 'Giphy URL',
  GIPHY_STARTER: 'Giphy Starter URL',
  UNKNOWN: 'Media URL',
}

export const parseUrl = (url:string) => {
  const external = isGiphyUrl(url) || isYoutubeUrl(url) || isVimeoUrl(url)

  if(external == 'giphy')
    url = normalizeGiphy(url)

  if (external) {
    return {
      type: 'url',
      fileType: external,
      urlType: external,
      sourceUrl: url,
      file: url,
      id: uniqueId('url'),
      width: null,
      height: null,
    }
  }else{
    return null;
  }
}

const normalizeGiphy= url => {
  const urlMatch = url.match(/giphy\.com\/(gifs|stickers)\/.*|gph\.is\/.*/i)
  if (urlMatch) {
    const bits = urlMatch[0].replace(/\/+$/, '').split('/')
    const id = bits.pop().split('-').pop()
    return `https://media.giphy.com/media/${id}/giphy.mp4`
  } else {
    return url
  }
}

type UrlInputOpts = {
  initialValue?: string,
  fileTypes: any[],
  className: string,
  placeholder: string,
  onFileAccepted: Function,
  clearOnAcceptTimeout?: number,
  onError?: Function,
  disabled: boolean,
}

export default class UrlInput extends PureComponent<UrlInputOpts> {

  static propTypes = {
    fileTypes: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onFileAccepted: PropTypes.func.isRequired,
    clearOnAcceptTimeout: PropTypes.number,
    onError: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    disabled: false,
  }

  input: any

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  componentDidMount(){
    if(this.props.initialValue){
      this.urlChange(decodeURIComponent(this.props.initialValue))
    }
  }

  timeout:any;

  resetInput() {
    const { clearOnAcceptTimeout } = this.props
    if (clearOnAcceptTimeout) {
      this.timeout = setTimeout(() => {
        if (this.input) {
          this.input.value = ''
        }
      }, clearOnAcceptTimeout)
    }
  }

  urlChange = debounce(url => {
    const { fileTypes, onError, onFileAccepted} = this.props

    if (!url) return

    if (!urlUtils.validate(url)) {
      onError('INVALID_URL', 'Invalid url')
      return
    }

    const parsed = parseUrl(url)

    if (parsed) {
      onFileAccepted([parsed])
      this.resetInput()
      return
    }

    if (!urlUtils.endsWith(url, fileTypes)) {
      onError('INVALID_URL', 'Invalid url')
      return
    }

    const fileType = urlUtils.mediaType(url)
    onFileAccepted([
      {
        type: 'url',
        fileType,
        urlType: url.indexOf('giphy.com') !== -1 ? URL_TYPE.GIPHY : URL_TYPE.UNKNOWN,
        sourceUrl: url,
        file: url,
        preview: url,
        id: uniqueId('url'),
      },
    ])
    this.resetInput()
  }, 200)

  render() {
    const { className, placeholder = '', disabled } = this.props
    return (
      <input
        type="url"
        ref={c => (this.input = c)}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => this.urlChange(e.target.value)}
      />
      )
  }
}
