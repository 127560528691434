import * as _ from 'underscore';
import { dotry, timestamp } from 'utils/dotry';
import { store } from 'redux/store';

import BaseView from 'views/base_view';
import { Sprite, SpriteFrameType } from 'models/sprite';
import { Menu } from 'views/menu';
import { Events } from 'maps/map_events';
import { MapView } from './map_view';
import { MODES } from 'components/world/constants';
import { Keys } from 'constants/keyboard';
import { pick, defaults, isNumber } from 'underscore';
import { Questions } from 'api/agent';
import { Question } from 'models/question';
import { ScriptTarget } from 'models/script';
import { CommandProps, Command } from 'hip/command';
import { AvatarView, charactersToTimeMs } from './avatar_view';
import { Avatar } from 'models/avatar';

const LEFT = 37;
const UP = 38;
const RIGHT = 39;
const DOWN = 40;
const TAB = 9;

type AnimationOpts = {
  width?:number,
  steps: string[],
  frames: {[key:string]: SpriteFrameType},
  imgsrc:string,
  dimensions: {width:number,height:number},
  delay:number
}

class Animation {

  last: number;
  idx: number;
  dimensions: {
    width: number;
    height: number;
  };
  imgsrc: string;
  sprite: SpriteView<any>;
  steps: string[];
  delay: number;
  frames: {
    [key:string]: SpriteFrameType
  };
  width: number;

  constructor(sprite:SpriteView<any>,opts:AnimationOpts){
    this.last=0;
    this.idx=0;
    this.sprite=sprite;
    this.width = opts.width || Object.values(opts.frames)[0].sourceSize.w;

    this.dimensions=opts.dimensions;
    this.imgsrc=opts.imgsrc;
    this.delay=opts.delay;
    this.steps= opts.steps;
    this.frames= opts.frames;
  }

  paint() {
    // TODO here we should implement Retina Scaling like explained in https://stackoverflow.com/questions/16154726
    // that is: backgroundSize: '200px 200px' (hen the backgroundImage is (400px 400px))
    //this.sprite.$inner.css({
    //});
    if (this.steps.length === 1) {
      this.draw(this.frames[this.steps[0]]);
    }
    const now = timestamp();
    if (now > this.last + this.delay) {
      this.last = now;
      this.idx = (this.idx + 1) % this.steps.length;
      this.draw(this.frames[this.steps[this.idx]]);
    }
  }

  protected draw(fr) {

    this.sprite.$outer.css({
      width: fr.sourceSize.w,
      height: fr.sourceSize.h
    });
    const css={
      backgroundColor: 'transparent',
      backgroundImage: 'url(' + this.imgsrc + ')',
      backgroundRepeat: 'no-repeat',
      top: fr.spriteSourceSize.y,
      left: fr.spriteSourceSize.x,
      width: fr.frame.w,
      height: fr.frame.h,
      backgroundPosition: (-fr.frame.x) + "px " + (-fr.frame.y) + "px"
    }
    if(fr.transforms){
      css['transform'] = Object.keys(fr.transforms).reduce((acc, key) => acc + ` ${key}(${fr.transforms[key]})`, '')
    }else{
      css['transform'] = '';
    }

    this.sprite.$inner.css(css);
  }

}

class Animation2 extends Animation {

  constructor(sprite,opts){
    super(sprite,opts)
  }

  draw(fr) {

    let pixel_ratio =  this.width  / fr.frame.w;
    let x = Math.ceil(fr.frame.x * pixel_ratio);
    let y = Math.ceil(fr.frame.y * pixel_ratio);

    let sprite_ratio =  fr.frame.w  / fr.frame.h;

    this.sprite.$outer.css({
      width: this.width,
      height: this.width / (fr.sourceSize.w  / fr.sourceSize.h)
    });
    const css={
      backgroundColor: 'transparent',
      backgroundImage: 'url(' + this.imgsrc + ')',
      backgroundRepeat: 'no-repeat',
      top: 0, //fr.spriteSourceSize.y,
      left: 0, //fr.spriteSourceSize.x,
      width: this.width, //fr.frame.w,
      height: this.width/sprite_ratio, //fr.frame.h,
      backgroundPosition: `-${x}px -${y}px`, //(-fr.frame.x) + "px " + (-fr.frame.y) + "px"
      backgroundSize: `${this.dimensions.width * pixel_ratio}px ${this.dimensions.height * pixel_ratio}px`
    }
    if(fr.transforms){
      css['transform'] = Object.keys(fr.transforms).reduce((acc, key) => acc + ` ${key}(${fr.transforms[key]})`, '')
    }else{
      css['transform'] = ''
    }

    this.sprite.$inner.css(css);
  }

}

class SpriteView<T extends Backbone.Model> extends BaseView<T> {

  throttled_walk = _.throttle(SpriteView.prototype.walk, 300);

  public timeouts:{[x:string]: any}
  //public name: string;

  public questions:{[question:string]: { [answer:string]:CommandProps} }={};

  map: MapView;
  removed: boolean;
  zoomFactor: number=1;
  step_incr: number;

  is_placeable: ((sp:SpriteView<T>)=>void) | false=false;

  animations: {[s:string]: Animation2};
  scripted: {where: ScriptTarget};

  $input: any;
  $form: any;
  $bubble: any;
  $inner: any;
  $content: any;
  $outer: any;
  $other: any;

  movements: {
    up?: Animation,
    down?: Animation,
    left?: Animation,
    right?: Animation
  };

  last_timer: any;

  walk_promise: {
    accept?:()=>void,
    reject?:()=>void,
  };

  is_saying: boolean;

  prefix: string;
  width: number;
  height: number;
  timeout_handler: any;
  callback: Function;

  offset_top: number;
  offset_left: number;
  previousZindex: any;
  map_move_callback: (e: any) => void;
  last_promise: Promise<void>;
  question_promises: any={};
  given_name: string;
  private _name: string;
  
  constructor(options) {
    super(options);
    this.removed = false;
    this.scripted = options.scripted;
    //this.initialize(options)
    this.dispatchCreated('SpriteView');
  }

  classNames() {
    return super.classNames() + " sprite hascontextmenu";
  }

  events() {
    return {
      [Events.ZOOM]: (e) => this.handle_zoom(e),
      "focus input": (e) => this.handle_focus(e),
      "blur input": (e) => this.handle_blur(e),
      "keydown input": (e) => this.handle_keydown(e),
      "submit form": (e) => this.handle_submit(e),
      "click .inner": (e) => this.handle_click(e),
      "click a.answer": (e) => this.handle_answer(e),
    };
  }

  initialize(settings) {
    //super.initialize(settings);
    if(settings.width)
      this.width = settings.width;
    if(settings.sprites)
      this.initialize_animations(settings.sprites)
  }

  initialize_animations(sprites){
    if (!(sprites instanceof Array)) {
      sprites = [sprites];
    }
    this.step_incr = 20;
    this.animations = {};
    this.movements = {};
    this.last_timer = null;
    this.createDivs();
    this.createAnimations(sprites, this.width);
  }

  handle_zoom(e) {
    this.zoomFactor = 1//this.map.zoomFactor;
    //this.$inner.css({ zoom: this.map.zoomFactor });
    this.repaint();
  }

  showAnimationsMenu(x, y, callback, extra) {
    this.setMenu(new Menu({
      x, y, 
      collection: Object.keys(this.animations).concat(extra || []),
      label: animation => animation,
      value: animation => animation,
      dispatcher: this.dispatcher,
      callback: callback
    }));
  }

  async handle_submit(e) {
    try{
      this.say(this.$input.val());
      this.$form.removeClass("showing");
      this.$input.val("");
      this.is_saying = false;
    }catch(e){
      console.error('An error would have submitted:',e);
    }
    return false;
  }

  handle_blur(e) {
    this.$form.removeClass("showing");
    this.is_saying = false;
  }

  handle_answer(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    const question=$(e.target).closest('.question-answers').find('.question').text();
    const answer=$(e.target).text()

    this.receive_answer(question, answer, window.senyor)
    return false;
  }

  handle_click(e) {
    if (this.is_placeable) {
      this.unset_placeable()
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    }
    const mode = store.getState().map.mode;

    if(this.scripted && mode == MODES.Normal){
      e.preventDefault();
      e.stopImmediatePropagation();
      return false
    }

    this.$bubble.stop();
    this.$bubble.fadeTo(100, 1);
    this.select();
    this.$input.focus();
    return false;
  }

  handle_focus(e) {
    this.is_saying = true;
    this.select();
    this.$form.addClass("showing");
  }

  handle_keydown(e) {
    if (this.prefix && e.target.value.indexOf(this.prefix) === 0) {
      return true;
    }
    let dx = 0;
    let dy = 0;
    let step = 80;
    switch (e.keyCode) {
      case LEFT:
        dx = -step;
        break;
      case RIGHT:
        dx = +step;
        break;
      case UP:
        dy = -step;
        break;
      case DOWN:
        dy = +step;
        break;
      case TAB:
        this.dispatcher.trigger(Events.SWITCH_AVATAR, {
          shift: e.shiftKey,
          host: this
        });
        return false;
      default:
        this.$bubble.stop();
        this.$bubble.css({ opacity: 1 });
        clearTimeout(this.last_timer);
        if (!this.is_saying) {
          this.$bubble.fadeTo(100, 1);
          this.is_saying = true;
          this.$form.addClass("showing");
        }
        return true;
    }
    this.throttled_walk(dx.toString(), dy.toString());
    return true;
  }

  get outer_height():number{
    return parseFloat(this.$outer.css('height'))
  }

  render() {
    $(this.el).attr("src", this.model.get("public_filename"));
    //this.width= this.width || parseFloat(this.$outer.css('width'));
    //this.height= this.height || parseFloat(this.$outer.css('height'));
    return this;
  }

  repaint() {
    this.paint(this.left, this.top);
  }

  get position():{x:number,y:number}{
    return {
      x: this.left + this.real_width/2,
      y: this.top + this.real_height/2
    };
  }

  get real_width():number{
    return parseFloat(this.$outer.css('width'))
  }

  get real_height():number{
    return parseFloat(this.$outer.css('height'))
  }

  paint(x, y, which?) {
    this.$outer.css({
      zIndex: 1,
      visibility: "visible",
      width: `${this.width}px`,
      height: `${this.height}px`
    });
    this.$outer.css({ left: x, top: y });
    this.$other.css({ left: x, top: y });

    if (which && this.animations[which]) {
      this.animations[which].paint();
    } else {
      const anims=Object.keys(this.animations);
      if(anims.length){
        (this.animations.down || this.animations[anims[0]])?.paint();
      }else{
        console.log('no animations for sprite');
      }
    }
  }

  createAnimations(sprites, width) {
    for (let sprite of sprites) {
      const atts = sprite instanceof Sprite ? sprite.attributes : sprite;
      const [parsed, filename, dimensions] = [atts.map, atts.public_filename, atts.image_dimensions];

      if (parsed == null) {
        continue;
      }
      for (let name in parsed.sequences) {
        const anim = parsed.sequences[name];
        if(_.isArray(anim)){
          this.createAnimation(name, anim, parsed.frames, 100, filename, dimensions, width);
        }else{
          this.createAnimation(name, anim.frames, parsed.frames, 1000/anim.rate, filename, dimensions, width);
        }
      }
    }

    this.movements = pick(this.animations, ["up", "down", "left", "right"]);

    defaults(this.movements, {
      up: Object.values(this.animations)[0],
      down: Object.values(this.animations)[0],
      left: Object.values(this.animations)[0],
      right: Object.values(this.animations)[0]
    });
  }

  addedToMap(map:MapView, attrs?:any) {
    this.paint(attrs.x, attrs.y);
    if (attrs.css) {
      this.$inner.css(attrs.css);
    }
    this.delegateEvents();
    this.map = map;
    this.removed = false;

  }

  unselect() {
    this.$outer.removeClass("hilited");
    if (this.$input) {
      this.$form.removeClass("showing");
    }
  }

  select() {
    this.$bubble.stop();
    this.$bubble.fadeTo(100, 1);

    //this.map.selectAvatar(this);
    this.dispatcher.trigger(Events.SELECT_AVATAR, this);
    this.$outer.addClass("hilited");
    if (this.$input && !this.$input.is(":focus") && !this.is_saying && this.bubbleIsVisible()) {
      this.$input.focus();
    }
  }

  createAnimation(name, steps, frames, delay, imgsrc, dimensions, width) {
    if (this.animations[name])
      return;

    this.animations[name] = new Animation2(this,{ steps,frames, imgsrc, dimensions, delay, width})
  }

  visibility(mode, time = 1000):Promise<void> {
    this.$inner[mode === 'on' ? 'fadeIn' : 'fadeOut'].call(this.$inner, time);
    return Promise.resolve()
  }

  createDivs() {
    this.$outer = this.$outer || $("<div class=\"outer\"></div>").appendTo(this.$el);
    this.$outer.data('sprite', this);
    this.$outer.css({
      position: "absolute",
      width: this.width,
      height: this.height,
      visibility: "visible",
      display: "block"
    });
    this.$inner = this.$inner ||  $("<div class=\"inner\"></div>").appendTo(this.$outer);
    this.$inner.css({
      position: "relative",
      visibility: "visible",
      display: "block",
      backgroundColor: 'transparent',
      backgroundRepeat: 'no-repeat'
    });
    this.$other = this.$other || $("<div class=\"other\">" + ["click", "hover", "enter"].map(ev =>`<div class='${ev}'/>`).join('') + "</div>").appendTo(this.$el);
    this.$other.css({
      position: "absolute",
      visibility: "visible",
      display: "block",
      background: "transparent"
    });
  }

  get left() {
    return parseInt(this.$outer.css("left"));
  }

  get top() {
    return parseInt(this.$outer.css("top"));
  }

  get x() {
    return this.left + this.width/2
  }

  get y() {
    return this.top + this.$outer.height()/2
  }

  bubbleIsVisible() {
    const offX = this.$input.offset().left - this.map.$viewport.offset().left;
    const offY = this.$input.offset().top - this.map.$viewport.offset().top;
    return (offX > 0) && (offY > 0) && (offX + this.$input.width() < this.map.$viewport.width()) && (offY + this.$input.height() < this.map.$viewport.height());
  }

  setupBubble() {
    if (this.$bubble) {
      return this.$bubble.remove();
    }
    this.$bubble = $(`<div class="bubble" style="position:absolute;z-index:600;opacity:0;">
      <form onsubmit="return false">
        <input type="textarea"></input>
      </form>
      <div class="content"></div>
    </div>`)
    this.$content = this.$bubble.find(".content");
    this.$form = this.$bubble.find("form");
    this.$input = this.$bubble.find("input");
    this.$outer.append(this.$bubble);
  }

  ask(question, answers, question_id=null):Promise<void> {
    this.$bubble.stop();
    this.$bubble.css({ opacity: 1 });

    const p=new Promise<void>((accept,reject) => {

      if(question_id){
        Questions.get(question_id).then((q) => {
          question=q.title;
          answers=q.answers.map((x)=>x.label)
          this.install_question(new Question(q,{dispatcher:this.dispatcher}), {accept,reject})
          this.$content.html(`
            <div class="question-answers">
              <p class="question">${question}</p>
              <ul class="list-unstyled">
                ${answers.map((a) => '<li><a class="answer" href="#">'+a+'</a></li>').join('')}
              </ul>
            </div>
          `);
        })
      }else{
        this.install_question(new Question({title:question, answers:answers.map((label)=>({label, command: null}))},{dispatcher:this.dispatcher}), {accept,reject});
        this.$content.html(`
      <div class="question-answers">
        <p class="question">${question}</p>
        <ul class="list-unstyled">
          ${answers.map((a) => '<li><a class="btn btn-outline-primary answer" href="#">'+a+'</a></li>').join('')}
        </ul>
      </div>
    `);
      }
    })

    return p;

  }

  set name(s:string) {
    this._name=s
  }

  get name():string {
    return this.given_name || (this.model as any)?.name
  }

  install_question(question:Question, promis) {
    if(question){
      this.questions[question.title]=question.answers.reduce((acc,a)=> ({...acc,[a.label]: a.command}), {});
      this.question_promises[question.title]=promis;
    }else{

    }
  }

  receive_answer(question, answer, subject:AvatarView<Avatar>) {
    console.log(`"${this.name}" received answer "${answer}" to question "${question}" from the context of ${subject.name}`)

    const q = this.questions[question];
    if(!q)return

    if(this.question_promises[question])
      this.question_promises[question].accept();

    const cmd = q[answer];
    if(!cmd)return

    if(['play', 'create'].includes(cmd.verb.name) && this.scripted?.where){
      Command.fromJSON(cmd).execute(this.scripted.where)
    }else{
      subject.execute(cmd)
    }

  }

  say(what, shutupTimeout=null):Promise<void> {
    if(!isNumber(shutupTimeout)){
      shutupTimeout = charactersToTimeMs(what);
      console.log(what, 'shutup:'+shutupTimeout);
    }
    this.dispatcher.trigger(Events.SAY, { what: what, timeout: shutupTimeout });
    this.$bubble.stop();
    this.$bubble.css({ opacity: 1 });
    this.$content.html(what);

    if(this.last_timer){
      clearTimeout(this.last_timer);
      this.last_timer=null;
    }

    const p= new Promise<void>((accept,reject) => {
      this.last_timer = setTimeout(() => {
        this.last_promise=null;
        this.last_timer=null;
        accept();
        this.shutup();
      }, shutupTimeout);
    });
    this.last_promise=p;
    return p;
  }

  shutup() {
    this.$bubble.stop();
    this.$bubble.fadeTo(500, 0);
  }

  stop() {
    if(this.timeout_handler)
      clearTimeout(this.timeout_handler);
  }

  play(animation, time = null, fps = 15) {
    clearTimeout(this.timeout_handler);
    if (this.removed) {
      return;
    }
    const anim:Animation2= this.animations[animation];

    if (!anim) return

    if(fps==null) fps=15;
    if(time==null)
      time=1000*anim.steps.length/fps

    const first = timestamp();
    anim.last = first;
    this.doFrameNoMove(anim, time, first, fps);
  }

  doFrameNoMove(animation:Animation2, time, first, fps) {
    animation.paint();
    if (this.removed) {
      return;
    }
    if ((animation.last - first) >= time) {
      this.timeout_handler = null;
    } else {
      this.timeout_handler = setTimeout((() => {
        this.doFrameNoMove(animation, time, first, fps);
      }), 1000 / fps);
    }
  }

  teletransport(newLeft, newTop):Promise<void> {
    newLeft = ((typeof newLeft === "string") ? this.left + parseInt(newLeft, 10) : newLeft);
    newTop = ((typeof newTop === "string") ? this.left + parseInt(newTop, 10) : newTop);
    this.$outer.css("left", Math.round(newLeft) + "px");
    this.$outer.css("top", Math.round(newTop) + "px");
    return Promise.resolve();
  }

  /* An avatar x and y positions, are its $outer element absolute offset coordinates from the $map
   * the left and the top css properties respectively.
   * 
   * So if you want to center the Sprite on the point X,Y, you would need to move its x,y to (X-sprite_width/2, Y-sprite_height/2).
   *
   * newLeft, and newTop are the new left and top css properties
   *
   */
  walk(newLeft, newTop, time=1000, fps=15, noAnim=false):Promise<void> {
    if (this.timeout_handler != null) {
      clearTimeout(this.timeout_handler);
      if(this.last_promise){
        this.last_promise=null;
      }
    }
    const totalFrames = fps * time / 1000;
    newLeft = (typeof newLeft === "string") ? this.left + parseInt(newLeft, 10) : newLeft;
    newTop = (typeof newTop === "string") ? this.top + parseInt(newTop, 10) : newTop;
    const stepLeft = (newLeft - this.left) / totalFrames;
    const stepTop = (newTop - this.top) / totalFrames;

    this.movements.up.last = this.movements.down.last = this.movements.left.last = this.movements.right.last = timestamp();

    const p= new Promise<void>((accept,reject) => {
      this.doFrame(this.left, newLeft, stepLeft, this.top, newTop, stepTop, noAnim, fps, {accept,reject});
    }).then(()=>this.last_promise=this.timeout_handler=null);
    this.last_promise=p;
    return p;
  }

  doFrame(curLeft, newLeft, stepLeft, curTop, newTop, stepTop, noAnim, fps, promis=null) {
    if (this.removed) {
      return promis.reject();
    }
    const inc_x = this.moveSingleVal(curLeft, newLeft, stepLeft);
    const inc_y = this.moveSingleVal(curTop, newTop, stepTop);

    this.$outer.css("left", Math.round(inc_x) + "px");
    this.$outer.css("top", Math.round(inc_y) + "px");

    if (!noAnim) {
      const delta_x = newLeft - inc_x;
      const delta_y = newTop - inc_y;
      if (Math.abs(delta_x) > Math.abs(delta_y)) {
        (delta_x >= 0 ? this.movements.right.paint() : this.movements.left.paint());
      } else {
        (delta_y >= 0 ? this.movements.down.paint() : this.movements.up.paint());
      }
    }

    if (inc_x === newLeft && inc_y === newTop) { // finished animation !
      this.timeout_handler = null;
      promis?.accept();
      if (this.callback)
        this.callback();
      return;
    }

    this.timeout_handler = setTimeout(() => {
      this.doFrame(inc_x, newLeft, stepLeft, inc_y, newTop, stepTop, noAnim, fps, promis);
    }, 1000 / fps);
  }

  moveSingleVal(currentVal, finalVal, frameAmt) {
    if (frameAmt === 0 || currentVal === finalVal) {
      return finalVal;
    }
    currentVal += frameAmt;
    if ((frameAmt > 0 && currentVal >= finalVal) || (frameAmt < 0 && currentVal <= finalVal)) {
      return finalVal;
    }
    return currentVal;
  }

  replay2(actions, context:ScriptTarget):Promise<any> {
    return actions.reduce((promis, [time,action,args]) => {
      return promis.then(()=> {
        return this[action].apply(this, args);
      });
    }, Promise.resolve());
  }

  replay(actions, context:ScriptTarget):Promise<any> {
    return new Promise((accept, reject) => {
      this.timeouts = actions.map(([time,action,args], i) => setTimeout(() => {
        this[action].apply(this, args);
        if(i==actions.length-1)
          accept(this)
      }, time));
    })

  }

  cancel_replay() {
    if(this.timeouts)
      Object.values(this.timeouts).forEach(to => clearTimeout(to));
    this.timeouts = [];
  }

  remove() {
    this.removed = true;
    this.cancel_replay();
    clearTimeout(this.last_timer);
    clearTimeout(this.timeout_handler);
    this.unset_placeable();
    this.map.off(`iconitos:move`, this.map_move_callback)
    return super.remove();
  }

  unset_placeable(save=false) {
    if (!this.is_placeable)
      return;

    this.is_placeable(this);

    $(document).off(`mousemove.${this.cid}`);
    $(document).off(`keydown.${this.cid}`);

    this.is_placeable = false;
    this.$outer.removeClass("playable").removeClass("editable");
    this.$outer.data("npc", null);
  }

  getPosition(e) {
    return {
      left: (e.pageX - this.offset_left)/(this.map.zoomFactor || 1) - this.width/2,
      top:  (e.pageY - this.offset_top )/(this.map.zoomFactor || 1)  - this.outer_height/2
    }
  }

  set_placeable(ev=null):Promise<any> {

    if (this.is_placeable)
      return Promise.reject();

    return new Promise((accept,reject) => {

      this.$outer.addClass("playable").addClass("editable");
      this.$outer.data("npc", this);
      this.is_placeable = accept;

      const offset_top = ev ? ev.pageY : this.map.$content.offset().top;
      const offset_left = ev ? ev.pageX : this.map.$content.offset().left;

      this.offset_top = offset_top - this.$outer.position().top;
      this.offset_left = offset_left - this.$outer.position().left;

      $(document).on(`mousemove.${this.cid}`, (e) => {
        this.$outer.css(this.getPosition(e));

        $(document).on(`keydown.${this.cid}`, (e) => {
          if (e.which == Keys.ESC){
            this.unset_placeable();
            this.remove();
            reject();
          }
        })

      });

      this.previousZindex = this.$outer.css('zIndex');
      this.$outer.css({ zIndex: 1000 })//.draggable();

      this.map_move_callback = (e) => {
        if(!this.is_placeable)
          return

        console.log('handling move', this.offset_top, this.offset_left);

        const offset_top = this.map.$content.offset().top;
        const offset_left = this.map.$content.offset().left;

        this.offset_top = offset_top //- this.$outer.position().top;
        this.offset_left = offset_left //- this.$outer.position().left;

        console.log('handling move', this.offset_top, this.offset_left);
      }

      this.map.on(`iconitos:move`, this.map_move_callback)

    })
  }


};


export { SpriteView};
