import { fabric } from 'fabric';

import { WorkareaLayout, WorkareaObject, FabricImage } from '../utils';
import VideoObject from '../objects/Video';
import BaseHandler from './BaseHandler';
import CanvasController from './CanvasController';

export const createSvgBackground = ({width,height,backgroundColor}) => `
  <svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}">
    <rect fill="${backgroundColor}" stroke-width="0" x="0" y="0" width="100%" height="100%"/>
  </svg>
`; 

class WorkareaHandler extends BaseHandler {

  constructor(handler: CanvasController) {
    super(handler);
    this.initialize();
  }

  /**
   * Initialize workarea
   *
   * @author salgum1114
   */
  public initialize(color='black') {
    const { workareaOption } = this.controller;
    const image = new Image(workareaOption.width, workareaOption.height);
    image.width = workareaOption.width;
    image.height = workareaOption.height;

    //if(!workareaOption.src){
    //  workareaOption.src = `data:image/svg+xml;utf8,${createSvgBackground(workareaOption)}`
    //}

    //this.controller.workarea = new fabric.Rect(workareaOption) as WorkareaObject;
    this.controller.workarea = new fabric.Image(image, workareaOption) as WorkareaObject;
    this.controller.canvas.add(this.controller.workarea);
    this.controller.objects = this.controller.getObjects();
    //this.controller.canvas.centerObject(this.controller.workarea);
    this.controller.canvas.renderAll();
  }

  /**
   * Set the layout on workarea
   * @param {WorkareaLayout} layout
   * @returns
   */
  public setLayout = (layout: WorkareaLayout) => {
    this.controller.workarea.set('layout', layout);
    const { _element, isElement, workareaWidth, workareaHeight } = this.controller.workarea;
    const { canvas } = this.controller;
    let scaleX = 1;
    let scaleY = 1;
    const isFixed = layout === 'fixed';
    const isResponsive = layout === 'responsive';
    const isFullscreen = layout === 'fullscreen';
    if (isElement) {
      if (isFixed) {
        scaleX = workareaWidth / _element.width;
        scaleY = workareaHeight / _element.height;
      } else if (isResponsive) {
        const scales = this.calculateScale();
        scaleX = scales.scaleX;
        scaleY = scales.scaleY;
      } else {
        scaleX = canvas.getWidth() / _element.width;
        scaleY = canvas.getHeight() / _element.height;
      }
    }
    this.controller.getObjects().forEach(obj => {
      const { id, player } = obj as unknown as VideoObject;
      if (id !== 'workarea') {
        const objScaleX = !isFullscreen ? 1 : scaleX;
        const objScaleY = !isFullscreen ? 1 : scaleY;
        const objWidth = obj.width * objScaleX * canvas.getZoom();
        const objHeight = obj.height * objScaleY * canvas.getZoom();
        const el = this.controller.elementHandler.findById(obj.id);
        this.controller.elementHandler.setSize(el, obj);
        if (player) {
          player.setPlayerSize(objWidth, objHeight);
        }
        obj.set({
          scaleX: !isFullscreen ? 1 : objScaleX,
          scaleY: !isFullscreen ? 1 : objScaleY,
        });
      }
    });
    if (isResponsive) {
      const center = canvas.getCenter();
      if (isElement) {
        this.controller.workarea.set({
          scaleX: 1,
          scaleY: 1,
        });
        this.controller.zoomHandler.zoomToPoint(new fabric.Point(center.left, center.top), scaleX);
      } else {
        this.controller.workarea.set({
          width: workareaWidth,
          height: workareaHeight,
        });
        scaleX = canvas.getWidth() / workareaWidth;
        scaleY = canvas.getHeight() / workareaHeight;
        if (workareaHeight >= workareaWidth) {
          scaleX = scaleY;
        } else {
          scaleY = scaleX;
        }
        this.controller.zoomHandler.zoomToPoint(new fabric.Point(center.left, center.top), scaleX);
      }
      canvas.centerObject(this.controller.workarea);
      canvas.renderAll();
      return;
    }
    if (isElement) {
      this.controller.workarea.set({
        width: _element.width,
        height: _element.height,
        scaleX,
        scaleY,
      });
    } else {
      const width = isFixed ? workareaWidth : this.controller.canvas.getWidth();
      const height = isFixed ? workareaHeight : this.controller.canvas.getHeight();
      this.controller.workarea.set({
        width,
        height,
        backgroundColor: 'rgba(255, 255, 255, 1)',
      });
      this.controller.canvas.renderAll();
      if (isFixed) {
        canvas.centerObject(this.controller.workarea);
      } else {
        this.controller.workarea.set({
          left: 0,
          top: 0,
        });
      }
    }
    canvas.centerObject(this.controller.workarea);
    const center = canvas.getCenter();
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.controller.zoomHandler.zoomToPoint(new fabric.Point(center.left, center.top), 1);
    canvas.renderAll();
  };

  public setCursor = (cursor: string) => {
    this.controller.workarea.set('hoverCursor', cursor)
  }

  /**
   * Set the responsive image on Workarea
   * @param {string | File} [source]
   * @param {boolean} [loaded]
   * @returns
   */
  public setResponsiveImage = async (source: string | File, loaded?: boolean) => {
    const imageFromUrl = async (src: string = '') => {
      return new Promise<WorkareaObject>(resolve => {
        fabric.Image.fromURL(src, (img: any) => {
          const { canvas, workarea, editable } = this.controller;
          const { workareaWidth, workareaHeight } = workarea;
          const { scaleX, scaleY } = this.calculateScale(img);
          if (img._element) {
            workarea.set({
              ...img,
              isElement: true,
              selectable: false,
            });
          } else {
            const image = new Image(workareaWidth, workareaHeight);
            workarea.setElement(image);
            workarea.set({
              isElement: false,
              selectable: false,
              width: workareaWidth,
              height: workareaHeight,
            });
          }
          if (editable && !loaded) {
            canvas.getObjects().forEach(obj => {
              const { id, player } = obj as unknown as VideoObject;
              if (id !== 'workarea') {
                const objWidth = obj.width * scaleX;
                const objHeight = obj.height * scaleY;
                const el = this.controller.elementHandler.findById(id);
                this.controller.elementHandler.setScaleOrAngle(el, obj);
                this.controller.elementHandler.setSize(el, obj);
                if (player) {
                  player.setPlayerSize(objWidth, objHeight);
                }
                obj.set({ scaleX: 1, scaleY: 1, });
                obj.setCoords();
              }
            });
          }
          // 파일이 없을 경우 Canvas의 nextWidth, nextHeight 값이 변경되기 전 상태에서 zoomToFit이 동작함
          // 정상 동작 resize event logic => zoomToFit
          // 현재 동작 zoomToFit -> resize event logic
          this.controller.zoomHandler.zoomToFit();
          canvas.centerObject(workarea);
          resolve(workarea);
        });
      });
    };
    const { workarea } = this.controller;
    if (!source) {
      workarea.set({
        src: null,
        file: null,
      });
      return imageFromUrl(source as string);
    }
    if (source instanceof File) {
      return new Promise<WorkareaObject>(resolve => {
        const reader = new FileReader();
        reader.onload = () => {
          workarea.set({
            file: source,
          });
          imageFromUrl(reader.result as string).then(resolve);
        };
        reader.readAsDataURL(source);
      });
    } else {
      workarea.set({
        src: source,
      });
      return imageFromUrl(source);
    }
  };

  setBackgroundColor = (backgroundColor:string) => {
    this.controller.workarea.set({ backgroundColor });
    const {width,height}=this.controller.workareaOption;
    this.controller.workarea.setSrc(`data:image/svg+xml;base64,${btoa(createSvgBackground({width,height,backgroundColor}))}`);
  }

  workareaImageFromUrl = (src: string, loaded=false) => {

    const { canvas, workarea, editable } = this.controller;

    return new Promise<WorkareaObject>(resolve => {
      fabric.Image.fromURL(src, (img: any) => {
        let width = canvas.getWidth();
        let height = canvas.getHeight();
        if (workarea.layout === 'fixed') {
          width = workarea.width * workarea.scaleX;
          height = workarea.height * workarea.scaleY;
        }
        let scaleX = 1;
        let scaleY = 1;
        if (img._element) {
          scaleX = width / img.width;
          scaleY = height / img.height;
          img.set({ originX: 'left', originY: 'top', scaleX, scaleY, });
          workarea.set({
            ...img,
            isElement: true,
            selectable: false,
          });
        } else {
          workarea.setElement(new Image());
          workarea.set({
            width,
            height,
            scaleX,
            scaleY,
            isElement: false,
            selectable: false,
          });
        }
        canvas.centerObject(workarea);
        if (editable && !loaded) {
          const { layout } = workarea;
          canvas.getObjects().forEach(obj => {
            const { id, player } = obj as unknown as VideoObject;
            if (id !== 'workarea') {
              scaleX = layout === 'fullscreen' ? scaleX : obj.scaleX;
              scaleY = layout === 'fullscreen' ? scaleY : obj.scaleY;
              const el = this.controller.elementHandler.findById(id);
              this.controller.elementHandler.setSize(el, obj);
              if (player) {
                const objWidth = obj.width * scaleX;
                const objHeight = obj.height * scaleY;
                player.setPlayerSize(objWidth, objHeight);
              }
              obj.set({ scaleX, scaleY, });
              obj.setCoords();
            }
          });
        }
        const center = canvas.getCenter();
        const zoom = loaded || workarea.layout === 'fullscreen' ? 1 : this.controller.canvas.getZoom();
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        this.controller.zoomHandler.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
        canvas.renderAll();
        resolve(workarea);
      });
    });
  };

  /**
   * Set the image on Workarea
   * @param {string | File} source
   * @param {boolean} [loaded=false]
   * @returns
   */
  setImage = async (source: string | File, loaded = false) => {
    const { canvas, workarea, editable } = this.controller;

    if (workarea.layout === 'responsive') {
      return this.setResponsiveImage(source, loaded);
    }

    if (!source) {
      workarea.set({ src: null, file: null, });
      return this.workareaImageFromUrl(source as string);
    }

    if (source instanceof File) {
      return new Promise<WorkareaObject>(resolve => {
        const reader = new FileReader();
        reader.onload = () => {
          workarea.set({ file: source, });
          this.workareaImageFromUrl(reader.result as string).then(resolve);
        };
        reader.readAsDataURL(source);
      });
    } else {
      workarea.set({ src: source, });
      return this.workareaImageFromUrl(source);
    }
  };

  /**
   * Calculate scale to the image
   *
   * @param {FabricImage} [image]
   * @returns
   */
  public calculateScale = (image?: FabricImage) => {
    const { canvas, workarea } = this.controller;
    const { workareaWidth, workareaHeight } = workarea;
    const { _element } = image || workarea;
    const width = _element?.width || workareaWidth;
    const height = _element?.height || workareaHeight;
    let scaleX = canvas.getWidth() / width;
    let scaleY = canvas.getHeight() / height;
    if (height >= width) {
      scaleX = scaleY;
      if (canvas.getWidth() < width * scaleX) {
        scaleX = scaleX * (canvas.getWidth() / (width * scaleX));
      }
    } else {
      scaleY = scaleX;
      if (canvas.getHeight() < height * scaleX) {
        scaleX = scaleX * (canvas.getHeight() / (height * scaleX));
      }
    }
    return { scaleX, scaleY };
  };
}

export default WorkareaHandler;
