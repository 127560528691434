import SpacePreview from './preview';
import { Redirect } from "react-router-dom";
import React from 'react';
import {Spaces} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import {DirectUploadProvider} from 'react-activestorage-provider';
import { Games } from 'api/agent'

import * as ActiveStorage from 'activestorage';
import { GameAttributes } from 'models/game';
import GameForm from './form';

type GameEditState = {
  game?: GameAttributes,
  creation_status?: string,
  errors:any[],
};

class GameEdit extends React.Component<{match:any},GameEditState> {

  state:GameEditState = {
    errors:null,
    creation_status: null,
  }

  async componentDidMount(){
    if(!this.state.game){
      const game = await Games.show(this.props.match.params.uuid)
      this.setState({...this.state, game});
    }
  }
  render(){
    if (this.state.creation_status == 'created') {
      return <Redirect to={`/games/${this.props.match.params.uuid}`} />
    }

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          {this.state.game && <>
          <h1 className="text-xs-center">Edit Game</h1>
          <GameForm submitForm={this.submitForm.bind(this)} game={this.state.game} errors={this.state.errors} creation_status={this.state.creation_status}></GameForm>
      </>}
        </div>
      </div>
    );
  }

  async submitForm({name, description}): Promise<any> {

    const game = { name, description};

    this.setState({...this.state, creation_status: 'creating'})

    Games.update(this.props.match.params.uuid, game).then((e) => {
      this.setState({...this.state, creation_status: 'created'});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}


export default GameEdit;

