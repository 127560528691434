import React from 'react';

type SoundProps = {
  id: string,
  name: string,
  description: string,
  sound_url: string,
  mime: string,
}

function SoundPreview(props:{sound:SoundProps, onClick?:(sound:SoundProps)=>void}) {
  return <div>
    <p>{props.sound.name}</p>
    <audio controls>
      <source src={props.sound.sound_url} type={props.sound.mime}></source>
    </audio>
  </div>
}

export { SoundProps, SoundPreview };



