export const ART_MODIFIERS = {
  "Medium": `digital painting, watercolor, oil painting, pencil sketch, charcoal, ink`.split(', '),
  //  "Era": `renaissance, baroque, impressionism, modernism, postmodern, contemporary`.split(', '),
  //  "Technique": `pointillism, chiaroscuro, abstract, surrealism, minimalism, cubism`.split(', '),
};
export const ARTIST = {Artist: [
  "Studio Ghibli style",
  "Akira Toriyama style",
  "Katsuhiro Otomo style", 
  "Hugo Pratt style",
  "Moebius (Jean Giraud) style",
  "Yoshitaka Amano (final fantasy) style",
  "Frank Frazetta style",
  "Mike Mignola style",
  "Rebecca Sugar style",
  "Jen Zee style",
]}
export const VISUAL_THEME = {
  "Anime & Manga": `Studio Ghibli style, Shonen action, Shojo fantasy`.split(', '),
  "Literature and Film Genres": `Noir, Western, Victorian, Gothic`.split(', '),
  "Techno-Futuristic": `Cyberpunk, Biopunk, Solarpunk, Techno-dystopian, Vaporwave, Synthwave`.split(', '),
  "Retro & Historical": `80s neon, 70s disco, 50s Americana, Medieval, Ancient`.split(', '),
  //  "Cultural Movements": `Art Deco, Bauhaus, Afrofuturism, Native American`.split(', '),
  "Fantasy Worlds": `High fantasy, Dark fantasy, Mythical, Steampunk, Dieselpunk`.split(', '),
  //  "Music and Subculture": `Punk, Hip hop, K-pop`.split(', '),
  "Pop Culture": `Superhero comic, Pixel art, Claymation, Lowbrow art`.split(', '),
}

export const STYLE_MODIFIERS = {
  "Popular Media": `anime style, comic book style, Disney style, retro video game style`.split(', '),
  //  "Cultural": `Art Nouveau, Art Deco, Eastern, Western, Afrofuturism, Indigenous`.split(', '),
  "Fiction Genres": `fantasy, sci-fi, horror, historical, adventure, noir`.split(', '),
}
export const COLOR_MODIFIERS = {
  "Tone": `pastel, neon, monochrome, sepia, grayscale, natural`.split(', '),
  "Theme": `autumnal, tropical, desert, arctic, nocturnal, twilight`.split(', '),
}
export const EMOTION_MODIFIERS = {
  "Mood": `joyful, somber, eerie, serene, chaotic, whimsical`.split(', '),
  "Atmosphere": `tense, relaxed, gloomy, bright, mysterious, nostalgic`.split(', '),
}
export const TECHNICAL_MODIFIERS = {
  "Resolution": `high-definition, low-poly, pixelated, soft focus, detailed`.split(', '),
  "Orientation": `portrait, landscape, panoramic, square, abstract form`.split(', '),
  "Lighting": `backlit, silhouette, diffused, spotlight, natural light, studio light`.split(', '),
}
export const SIZES = {
  "Horizontal": [3000,1000],
  "Square": [2000,2000],
  "Vertical": [1000,3000],
}
export const CONCEPT_MODIFIERS = {
  "Setting": `urban, rural, space, underwater, fantastical, historical`.split(', '),
  "Time of Day": `dawn, day, dusk, night`.split(', '),
  "Season": `spring, summer, fall, winter`.split(', '),
}

export const PLACES ={
//  "Urban and Modern Environments":{
	 "Bustling metropolis": "bustling metropolis: A sprawling cityscape filled with neon lights and towering skyscrapers",
	 "Rundown subway station": "rundown subway station: A dimly lit, graffiti-covered subway station that holds secrets in its depths",
	 "High-tech laboratory": "high-tech laboratory: A state-of-the-art research facility with futuristic technology and unexpected discoveries",
	 "Rooftop garden": "rooftop garden: A serene escape high above the city, with a surprising twist",
	 "Underground speakeasy": "underground speakeasy: A hidden, prohibition-era bar with mysterious patrons and puzzles",
//  },
//  "Natural and Wild Settings":{
	 "Deep jungle": "deep jungle: Lush, vibrant, and teeming with wildlife, hiding ancient ruins",
	 "Arctic research station": "arctic research station: Isolated and cold, surrounded by ice, harboring a scientific mystery",
	 "Desert oasis": "desert oasis: A seemingly serene spot in the desert that is a nexus for unexpected adventures",
	 "Volcanic island": "volcanic island: A dynamic and dangerous locale with flowing lava and rare phenomena",
	 "Ancient forest": "ancient forest: An old growth forest, filled with mystical creatures and ancient magic",
//  },
//  "Historical and Fantastical Locations":{
	 "Victorian mansion": "victorian mansion: An elaborately decorated house with secret passages and ghostly occurrences",
	 "Pirate cove": "pirate cove: A hidden beach with an abandoned shipwreck and buried treasure",
	 "Medieval village": "medieval village: A small, bustling township with a castle, market, and old-world charms",
	 "Floating city": "floating city: A city suspended in the clouds, accessible by airships and full of sky pirates",
	 "Labyrinthine library": "labyrinthine library: An enormous, ancient library with magical books and hidden doorways",
//  },
//  "Mystical and Otherworldly Scenes":{
	 "Crystal caverns": "crystal caverns: Sparkling caves lined with crystals that possess unique properties",
	 "Dream realm": "dream realm: A surreal, ever-changing landscape governed by the logic of dreams",
	 "Abandoned amusement park": "abandoned amusement park: A once-joyful place now eerie and filled with clues and challenges",
	 "Interdimensional space station": "interdimensional space station: A hub for beings from different dimensions, full of technological puzzles",
	 "Ancient temple": "ancient temple: A mysterious, trap-filled temple dedicated to long-forgotten gods",
//  }
}

