/*<reference path=”app/javascript/interfaces.d.ts” />*/
import * as _ from 'underscore';
import { underscore, camelize } from 'utils/dotry';

import { SpriteView } from './sprite_view';
import { NpcEditView } from './npc_edit_view';
import WindowView from './window_view';
import { ModelFetchOptions } from 'backbone';
import { Menu } from 'views/menu';
import { pick_model } from 'components/shared/pick_model';
import * as Backbone from 'backbone';
import { Events } from 'maps/map_events';
import { Npc } from 'models/npc';
import { store } from 'redux/store';
import { MODES } from 'components/world/constants';
import { ScriptTarget } from 'models/script';
import { AvatarView } from './avatar_view';
import { Avatar } from 'models/avatar';
import { NpcPlacement } from 'models/npc_placement';
  import { MAP_EDIT_NPC_PLACEMENT, MAP_SET_MODE } from 'constants/actionTypes';
  import { ScriptExecutor } from 'models/script_executor';
//Caman = require('caman').Caman is broken in webpack because of fibers dependency, using CDN
//
const _T = require('utils/matrix');

class NpcView extends SpriteView<Npc> implements ScriptTarget {

  static filter_cache = {};

  recording: boolean;
  scenes: {};
  script_avatars: Array<AvatarView<Avatar>>=[];
  executor: ScriptExecutor;
  placement: NpcPlacement;
  avatar_id: string;
  default_play: any;
  is_editable: any;
  $rotatable: any;
  scale_x: number;
  scale_y: number;
  initial_matrix: any;
  $resizable: any;
  space: any;
  script: any;
  repeat_interval: number;

  constructor(settings) {
    super(settings);
    this.width = settings.width
    this.recording = false;
    this.scenes = {};
    this.placement = settings.placement;
    this.placement.on('change', this.handle_placement_change.bind(this));
    this.given_name = settings.name;
    this.avatar_id = "Npc_" + settings.id;
    this.$el.attr('id', `npc_placement-${this.placement.id}`);
    this.$el.data('placement',this);

    if (this.model.get("sprites")) {
      super.initialize({sprites:this.model.get("sprites"), width: settings.width});
    } else {
      this.model.url = () => "/npcs/" + this.model.get("id");
      this.model.fetch(({
        dispatcher: this.dispatcher,
        success: (model, response) => {
          super.initialize({sprites: this.model.get("sprite")});
        },
        error: (model, response) => {
          console.log("ERROR:", response);
        }
      } as ModelFetchOptions));
    }
    this.defaultEvents();
    this.$outer.addClass("playable");
    this.delegateEvents() // This is needed because initialize sets the this.placement, but delegateEvents needs it to be present to work
    this.dispatchCreated('NpcView');
  }


  defaultEvents() {
    try {
      this.default_play = this.placement.events.find(x=>x.event == 'default_play')?.command;
    } catch (error) {
      this.default_play = Object.keys(this.animations)[0];
    }
    const repeat = this.placement.events.find(x=>x.event == 'repeat');
    if (repeat) {
      this.repeat_interval = (setInterval(() => this.play.apply(this, repeat.args), 3000) as unknown) as number;
    }
  }

  get showEditor():boolean {
    return store.getState().map.space.canEdit;
  }

  public events():any {

    if (!this.placement)
      return {};

    let events = this.placement.events;

    if (events && Object.keys(events).length) {
      this.$el.addClass("with_events " + Object.keys(events).reduce((acc,k) => acc + ` ${k}-${events[k][0]}`, ''))
    }

    let npc_events = this.extract_events(events);

    if (this.showEditor)
      return {
        ...npc_events,
        "contextmenu": (e) => {
          if (this.detectOtherNpcs(e)) {
            return false;
          }
          this.$outer.addClass('highlighted');
          this.show_menu(e, {
            remove_cb: () => this.$outer.removeClass('highlighted')
          });

          return false;
        },
        "click": (e) => {
          const mode = store.getState().map.mode;

          if (mode == MODES.Placing && this.is_placeable) {
            const pos = this.getPosition(e);
            this.cloneNpc(pos.left,pos.top).save();
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
          } else if (mode == MODES.PlaceOne && this.is_placeable) {
            const pos = this.getPosition(e);
            this.cloneNpc(pos.left,pos.top).save();

            store.dispatch({ type: MAP_SET_MODE, mode: MODES.Editing });
            this.unset_placeable(false);

            e.preventDefault();
            e.stopImmediatePropagation();
            return false;

          } else if (mode == MODES.Editing) {
            return false;
          } else {
            return true;
          }
        },
        [Events.ZOOM]: e => this.handle_zoom(e),
        "dblclick": e => this.handle_dblclick(e)
      }

    return {
      ...npc_events,
      "contextmenu": (e) => {
        e.preventDefault();
        return false;
      }
    }
  }

  extract_events(evs:{event:string,command:string,args:any[]}[]) {
    return (evs || []).reduce((events:{}, {event, command, args}) => {

      if(event== 'hover')
        event= 'mouseenter'

      events[event + " .playable"] = (ev) => {

        const mode = store.getState().map.mode;

        if (mode !== MODES.Normal)
          return true;

        this[command].apply(this, args);
        ev.preventDefault();
        ev.stopImmediatePropagation();
        return false;
      };
      return events;
    }, {});
  }

  className = 'sprite_view npc_view'

  classNames () {
    let classes = `sprite_view npc_view ${this.cid}`;
    if (this.placement?.events)
      classes += Object.keys(this.placement.events).reduce((memo, k) => (memo + " " + k + " " + this.placement.events[k][0]), "");
    return classes;
  }

  handle_placement_change(e) {
    this.$outer.css(this.placement.css || {});
  }

  detectOtherNpcs(e) {
    let family = document.elementsFromPoint(e.screenX, e.screenY);

    const sprites = $(family).toArray().map((e) => $(e).data('sprite')).filter((x) => Boolean(x) && x.placement);

    if ($(family).filter('.outer').length <= 1 || sprites.length <=1)
      return false;

    this.setMenu(new Menu({
      collection: sprites.reduce((acc, x) => Object.assign(acc, {[x.cid] : `Placement ${x.placement.get('id')}`}), {}),
      x: this.left + e.offsetX,
      y: this.top + e.offsetY,
      dispatcher: this.dispatcher,
      mouseenter_callback: sprites.reduce((acc, x) => Object.assign(acc, {[x.cid] : (y) => {
        $(`.${x.cid} .outer`)
          .animate({ opacity: 0 }, 100)
          .animate({ opacity: 100 }, 100)
          .animate({ opacity: 0 }, 100)
          .animate({ opacity: 100 }, 100)
          .animate({ opacity: 0 }, 100)
          .animate({ opacity: 100 }, 100);
      }}), {}),
      callback: sprites.reduce((acc, x) => Object.assign(acc, {[x.cid]: (y) => $(`.${x.cid}`).find('.outer').data('sprite').show_menu(e) }), {})
    }));

    return true;
  }

  play_script(id) {
    this.dispatcher.trigger(Events.PLAY_SCRIPT, { id: id, where: this });
  }

  do_play(ev) {
    this.play(this.default_play, 3000, 15);
    this.dispatcher.trigger(Events.NPC_PLAYING, this.animations[this.default_play]);
  }

  show_text(id) {
    this.dispatcher.trigger(Events.SHOW_TEXT, { id: id, where: this.$other, thing: this });
  }

  play_sound(id) {
    this.dispatcher.trigger(Events.PLAY_SOUND, { id: id, thing: this });
  }

  play_video(id) {
    this.dispatcher.trigger(Events.PLAY_VIDEO, { id: id, where: this.$other, thing: this });
  }

  show_picture(id) {
    this.dispatcher.trigger(Events.SHOW_PICTURE, { id: id, where: this.$other, thing: this });
  }

  travelTo(id) {
    this.dispatcher.trigger(Events.TRAVEL_TO, { id: id, thing: this });
  }

  travelToAnchor(id) {
    this.dispatcher.trigger(Events.TRAVEL_TO_ANCHOR, { id: id, thing: this });
  }

  moveTo(x, y) {
    this.dispatcher.trigger(Events.MOVE_CURRENT, { x: x, y: y });
  }

  show_menu(ev, opts:any = {}) {
    this.setMenu(new Menu({
      collection: {
        play: "Play",
        info: "Info",
        edit_css: "Filters",
        edit: "Editar Sprite",
        zIndex: "Depth order",
        destroy: "Eliminar",
      },
      remove_cb: opts.remove_cb,
      x: this.left + ev.offsetX,
      y: this.top + ev.offsetY,
      dispatcher: this.dispatcher,

      callback: {
        zIndex: this.zIndex_menu.bind(this),
        play: this.play_menu.bind(this),
        info: (value, label, x, y) => {
          console.log(this.cid, this);
          (window as any)[this.cid] = this;
          const view = new NpcEditView({
            model: this.model,
            placement: this.placement,
            dispatcher: this
          });
          $("#holder").append(view.render().el);
        },

        edit_css: (value, label, x, y) => {

          const schema = {
            hueRotate: {
              min: 0,
              max: 360,
              step: 5,
              process: function(v) {
                return `${v}deg`;
              },
              type: "Range"
            },
            sepia: {
              min: 0,
              max: 100,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            brightness: {
              min: 0,
              max: 300,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            contrast: {
              min: 0,
              max: 500,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            grayscale: {
              min: 0,
              max: 100,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            saturate: {
              min: 0,
              max: 400,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            invert: {
              min: 0,
              max: 200,
              step: 1,
              process: function(v) {
                return `${v}%`;
              },
              type: 'Range'
            },
            blur: {
              min: 0,
              max: 10,
              step: 0.1,
              process: function(v) {
                return `${v}px`;
              },
              type: 'Range'
            },
            dropShadow: {
              type: 'Object',
              title: 'Drop Shadow',
              process: function(v) {
                return `${v.offset_x}px ${v.offset_y}px ${v.blur_radius}px ${v.color}`;
              },
              subSchema: {
                offset_y: {
                  title: 'Offset y',
                  min: -20,
                  max: 20,
                  step: 1,
                  suffix: 'px',
                  type: 'Range'
                },
                offset_x: {
                  title: 'Offset x',
                  min: -20,
                  max: 20,
                  step: 1,
                  suffix: 'px',
                  type: 'Range'
                },
                blur_radius: {
                  title: 'Blur Radius',
                  min: 0,
                  max: 10,
                  step: 0.1,
                  suffix: 'px',
                  type: 'Range'
                },
                color: {
                  title: 'Shadow Color',
                  type: 'Color'
                }
              }
            }
          };

          const process = function(k, v) {
            const base=schema[k];
            return (typeof base.process === "function" ? base.process(v) : void 0) || v;
          };

          const form = new (Backbone as any).Form({
            schema: schema,
            data: {
              hueRotate: 0,
              sepia: 0,
              brightness: 100,
              contrast: 100,
              grayscale: 0,
              saturate: 100,
              invert: 0,
              blur: 0
            }
          });

          const view = new WindowView({ model: form, dispatcher: this.dispatcher });

          const applyFilters = (values) => {
            var filters, k, v;
            filters = ((function() {
              var results;
              results = [];
              for (k in values) {
                v = values[k];
                if (!_.isEmpty(v)) {
                  results.push(`${underscore(k).replace('_', '-')}(${process(k, v)})`);
                }
              }
              return results;
            })()).join(' ');
            this.$outer.css('filter', filters);
            return this.placement.setCss('filter', filters);
          };

          form.on("change", function(changed) {
            return applyFilters(form.getValue());
          });

          view.on("commit", (values) => {
            this.menu.remove();
            applyFilters(values);
            this.placement.save(null, {
              error: (jqXHR, text) => {
                return console.log("AJAX error:", text);
              },
              success: (data, textStatus, jqXHR) => {
                console.log("AJAX success:", textStatus);
                this.delegateEvents();
                return view.remove();
              }
            });
            view.remove();
          });
          $("#holder").append(view.render().el);
        },
        edit: (value, label, x, y) => {
          window.location.href = "/npcs/" + this.model.id;
        },
        destroy: () => {
          this.destroyNpc();
        }
      }
    }));
    return false;
  }

  zIndex_menu(value, label, x, y) {
    this.setMenu(new Menu({
      collection: {
        front: "Bring to front",
        back: "Send to back",
        forward: "Bring forward",
        backward: "Send backward"
      },
      x: x,
      y: y,
      dispatcher: this.dispatcher,
      callback: {

        front: (text, label) => {
          const max_zIndex = Math.max.apply(Math, this.dispatcher.space.npc_placements.map((x) => parseInt(x.getCss('zIndex'))).filter(x => !_.isNaN(x)).concat(0));
          this.placement.setCss('zIndex', max_zIndex + 1);
          this.placement.save();
        },

        back: (text, label) => {
          const min_zIndex = Math.min.apply(Math, this.dispatcher.space.npc_placements.map((x) => parseInt(x.getCss('zIndex'))).filter(x => !_.isNaN(x)).concat(1));
          this.placement.setCss('zIndex', Math.max(min_zIndex - 1, 0));
          this.placement.save();
        },

        forward: (text, label) => {
          const zIndex = parseInt(this.placement.getCss('zIndex'));
          this.placement.setCss('zIndex', _.isNaN(zIndex) ? 1 : zIndex + 1);
          this.placement.save();
        },

        backward: (text, label) => {
          const zIndex = parseInt(this.placement.getCss('zIndex'));
          this.placement.setCss('zIndex', _.isNaN(zIndex) ? 1 : zIndex - 1);
          this.placement.save();
        }
      }
    }));
  }

  addEvent(event, command, args) {
    this.placement.addEvent(event,command, args);

    this.placement.save(null, {
      error: (jqXHR, text) => {
        console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        console.log("AJAX success:", textStatus);
        this.delegateEvents();
      }
    });
  }

  play_menu(value, label, x, y) {
    this.setMenu(new Menu({
      collection: {
        hover: "Hover",
        click: "Click",
        repeat: "Repeat",
        default_play: "Default Play"
      },
      x: x,
      y: y,
      dispatcher: this.dispatcher,
      callback:  {
        hover: this.make_menu('hover',x,y),
        click: this.make_menu('click',x,y),
        repeat: this.make_animations_menu('repeat',x,y),
        default_play: this.make_animations_menu('default_play',x,y),
      }
    }));
  }

  make_animations_menu(even_type,x,y) {
    return (a,b) => {
      this.showAnimationsMenu(x, y, ((id, label) => {
        this.menu.remove();

        const editables = this.map.npcs.filter((npcv) => npcv.is_editable);

        editables.push(this)

        editables.forEach((npcview) => {
          if (id === "nothing") {
            npcview.placement.removeEvent(even_type);
          } else {
            npcview.placement.addEvent(even_type,"play",[id, 3000, 15]);
          }
          npcview.placement.save(null, {
            error: (jqXHR, text) => {
              console.log("AJAX error:", text);
            },
            success: (data, textStatus, jqXHR) => {
              console.log("AJAX success:", textStatus);
              npcview.delegateEvents();
              npcview.defaultEvents();
            }
          });
        })
      }), "nothing");
    }
  }

  make_menu(even_type,x,y) {
    return (a,b) => {

      this.setMenu(new Menu({
        collection: {
          animation: "Animation",
          text: "Text",
          sound: "Sound",
          script: "Script",
          video: "Video",
          travel: "Travel",
          picture: "Picture",
          move: "Move",
          nothing: "Nothing"
        },
        x: x,
        y: y,
        dispatcher: this.dispatcher,
        callback: {
          move: (text, label) => {
            const view = new WindowView({
              model: new (Backbone as any).Form({
                schema: {
                  x: "Text",
                  y: "Text"
                }
              }),
              dispatcher: this
            });

            view.on("commit", (values) => {
              this.menu.remove();
              const evs = this.placement.get("events") || {};
              evs[even_type] = ["moveTo", parseInt(values.x), parseInt(values.y)];
              this.placement.set({ events: evs });
              this.placement.save(null, {
                error: (jqXHR, text) => {
                  console.log("AJAX error:", text);
                },
                success: (data, textStatus, jqXHR) => {
                  console.log("AJAX success:", textStatus);
                  this.delegateEvents();
                  view.remove();
                }
              });
              view.remove();
            });
            $("#holder").append(view.render().el);
          },
          travel: (text, label) => {
            pick_model('Anchor', { dispatcher: this.dispatcher }).then((anchor) => {
              this.menu.remove();
              this.addEvent(even_type, "travelToAnchor",[anchor.get('id')]);
            });
          },

          text: (text, label) => {
            pick_model('Text', { dispatcher: this.dispatcher }).then((text) => {
              this.menu.remove();
              this.addEvent(even_type, "show_text",[text.get('id')]);
            });
          },

          sound: (object_type, label) => {
            pick_model('Sound', { dispatcher: this.dispatcher }).then((sound) => {
              this.menu.remove();
              this.addEvent(even_type, "play_sound",[sound.get('id')]);
            });
          },

          script: (value, label, x, y) => {
            pick_model('Script', { dispatcher: this.dispatcher }).then((script) => {
              this.menu.remove();
              this.addEvent(even_type, "play_script",[script.get('id')]);
            });
          },

          picture: (value, label, x, y) => {
            pick_model('Picture', { dispatcher: this.dispatcher }).then((pic) => {
              this.menu.remove();
              this.addEvent(even_type, "show_picture",[pic.get('id')]);
            });
          },

          video: (object_type, label) => {
            pick_model('Video', { dispatcher: this.dispatcher }).then((video) => {
              this.menu.remove();
              this.addEvent(even_type, "play_video",[video.get('id')]);
            });
          },

          nothing: (object_type, label) => {
            this.menu.remove();
            const evs = this.placement.get("events") || {};
            delete evs[even_type];
            this.placement.set({ events: evs });
            this.placement.save(null, {
              error: (jqXHR, text) => {
                console.log("AJAX error:", text);
              },
              success: (data, textStatus, jqXHR) => {
                console.log("AJAX success:", textStatus);
                this.delegateEvents();
              }
            });
          },
          animation: this.make_animations_menu(even_type, x,y)
        }
      }));
    }
  }

  cloneNpc(x?, y?) {
    const new_place = this.placement.clone();
    new_place.set({
      x: x || (new_place.get("x") + 20),
      y: y || (new_place.get("y") + 20)
    });
    new_place.unset("id");

    const npcviw = new NpcView({
      model: this.model,
      placement: new_place,
      dispatcher: this.dispatcher
    });
    this.map.addNpcView(npcviw);
    npcviw.set_editable();
    return new_place;
  }

  destroyNpc() {
    this.placement.destroy({
      error: (model, jqXHR, text) => {
        console.log("DESTROY error:", text);
      },
      success: (model, textStatus, jqXHR) => {
        this.remove();
        console.log("DESTROY success:", textStatus);
      }
    });
  }

  handle_dblclick(ev) {
    const mode = store.getState().map.mode;

    if (! [MODES.Editing,MODES.Placing].includes(mode))  {
      return;
    }

    if (ev.shiftKey) {
      this.cloneNpc();
    } else if (ev.altKey) {
      this.destroyNpc();
    } else {
      if (mode == MODES.Placing) {
        this.unset_editable(true);

        const npcviw = new NpcView({
          model: this.model,
          placement: this.placement,
          dispatcher: this.dispatcher
        }).render();
        this.dispatcher.trigger(Events.ADD_NPC_VIEW, npcviw);

        this.set_placeable(ev);
      } else if (this.is_editable) {
        this.unset_editable(true);
      } else {
        store.dispatch({ type: MAP_EDIT_NPC_PLACEMENT, npc_placement: this.placement});

        this.set_editable();
        this.unset_placeable(true);
      }
    }
    return false;
  }

  unset_placeable(save) {
    super.unset_placeable(save);

    if (!this.is_placeable)
      return;

    this.placement.set({
      x: this.offset_left,
      y: this.offset_top,
      events: _.extend(this.placement.get('events') || {}, {}),
      css: this.placement.get('css') || {}
    });

    if (save) {
      return this.placement.save(null, {
        error: (jqXHR, text) => {
          return console.log("AJAX error:", text);
        },
        success: (data, textStatus, jqXHR) => {
          return console.log("AJAX success:", textStatus);
        }
      });
    }
  }

  unset_editable(save) {
    if (!this.is_editable) {
      return;
    }
    const rotate_matrix = _T.fromString(this.$rotatable.css("transform"));
    const scale_matrix = _T.scale(this.scale_x || 1, this.scale_y || 1);
    const matrix = _T.AxB_matrix(_T.AxB_matrix(rotate_matrix, scale_matrix), this.initial_matrix);
    this.placement.set({
      x: parseInt(this.$outer.css("left"), 10),
      y: parseInt(this.$outer.css("top"), 10),
      events: _.extend(this.placement.get('events') || {}, {}),
      css: _.extend(this.placement.get('css') || {}, {
        transform: _T.toString(matrix)
      })
    });
    this.is_editable = false;
    this.$outer.removeClass("playable").removeClass("editable");
    this.$outer.data("npc", null);
    this.$outer.draggable("destroy");
    this.$resizable.resizable("destroy");
    this.$rotatable.rotatable("destroy");
    this.$inner.unwrap().unwrap();
    if (save) {
      return this.placement.save(null, {
        error: (jqXHR, text) => {
          return console.log("AJAX error:", text);
        },
        success: (data, textStatus, jqXHR) => {
          return console.log("AJAX success:", textStatus);
        }
      });
    }
  }

  prepare_draggable(){

    const click = { x: 0, y: 0 };
    this.$outer.draggable({
      start: (event) => {
        click.x = event.clientX;
        click.y = event.clientY;
      },

      drag: (event, ui) => {
        const original = ui.originalPosition;
        ui.position = {
          left: (event.clientX - click.x + original.left) / this.map.zoomFactor,
          top:  (event.clientY - click.y + original.top ) / this.map.zoomFactor
        };
      }
    });
  }

  set_editable() {
    const mode = store.getState().map.mode;

    if (! [MODES.Editing,MODES.Placing].includes(mode))  {
      return;
    }
    if (this.is_editable) {
      return;
    }
    this.$outer.addClass("playable").addClass("editable");
    this.$outer.data("npc", this);
    this.is_editable = true;
    this.$outer.css('transform', '');
    this.initial_matrix = _T.fromString(this.$outer.css("transform"));
    this.prepare_draggable();
    this.$rotatable = this.$inner.wrap("<div class='rotatable-wrapper'>").parent();
    this.$rotatable.rotatable();
    this.$resizable = this.$inner.wrap("<div class='resizable-wrapper'>").parent();
    this.$resizable.resizable({
      handles: "ne, se, sw, nw",
      start: function(ev, ui) {},
      stop: function(ev, ui) {},
      resize: (ev, ui) => {
        this.scale_x = ui.size.width / ui.originalSize.width;
        this.scale_y = ui.size.height / ui.originalSize.height;
        this.$resizable.css({
          transform: "scale(" + this.scale_x + "," + this.scale_y + ")"
        });
      }
    });
  }

  remove() {
    this.script_avatars.forEach(ch => ch.remove());
    return super.remove();
  }

  processCssFilters(css) {
    css = _.clone(css);
    let filter = css.filter;
    //"hue-rotate(0deg) sepia(0%) brightness(100%) contrast(100%) grayscale(0%) saturate(100%) invert(0%) blur(0px) drop-shadow(0px 0px 7.5px rgb(255, 0, 0))
    if (false) {
      const filters = {
        dropShadow: function(caman, arg) {},
        // TODO implement drop shadow using caman layers (add layer, brightness=-100, tint, blur, displace)
        //caman.blur(parseFloat(arg))
        contrast: function(caman, arg) {
          return caman.contrast(parseFloat(arg));
        },
        blur: function(caman, arg) {
          return caman.gaussianBlur(parseFloat(arg));
        },
        invert: function(caman, arg) {
          return caman.invert(parseFloat(arg));
        },
        saturate: function(caman, arg) {
          return caman.saturation(parseFloat(arg));
        },
        grayscale: function(caman, arg) {
          return caman.greyscale(parseFloat(arg));
        },
        brightness: function(caman, arg) {
          return caman.brightness(parseFloat(arg));
        },
        sepia: function(caman, arg) {
          return caman.sepia(parseFloat(arg));
        },
        hueRotate: function(caman, arg) {
          if (String(arg).match(/deg */)) {
            return caman.hue(100 * parseFloat(arg) / 360);
          } else {
            return caman.hue(parseFloat(arg));
          }
        }
      };
      const url = 'https://' + window.location.host + this.model.get('sprites')[0].public_filename;
      const results = [];
      let m;
      while (m = filter.match(/^ *([^(]*)\(([^)]*)\)/)) {
        filter = filter.slice(m[0].length);
        results.push(m[0].trim());
      }

      const key = results.sort().join(' ');
      let b64 = NpcView.filter_cache[key];

      if (b64) {
        b64.then((b64) => {
          var anim, name, ref, results;
          ref = this.animations;
          results = [];
          for (name in ref) {
            anim = ref[name];
            results.push(anim.imgsrc = b64);
          }
          return results;
        });
      } else {
        filter = css.filter;
        NpcView.filter_cache[key] = new Promise(function(accept, reject) {
          return window.Caman($('<canvas>')[0], url, function() {
            var results;
            results = [];
            while (m = filter.match(/^ *([^(]*)\(([^)]*)\)/)) {
              filter = filter.slice(m[0].length);
              filters[camelize(m[1])](this, m[2]);
              //"hue-rotate(0deg) sepia(0%) brightness(100%) contrast(100%) grayscale(0%) saturate(100%) invert(0%) blur(0px) drop-shadow(0px 0px 7.5px rgb(255, 0, 0))
              results.push(this.render(function() {
                return accept(this.toBase64());
              }));
            }
            return results;
          });
        });
      }
    }
    // TODO applying the filters in realtime to the sprites is too expensive CPU wise.
    // What we have to do is:
    // make SVG with the image
    // apply the filters
    // render the imaage onto an Canvas
    // getDataUrl() and set that url as the background-image.
    delete css.filter;
    return css;
  }

  // Overload SpriteView.addedToMap 
  addedToMap(map:any, attrs?:any){
    //super.addedToMap(map,attrs)
    this.space = map.space;
    this.paint(this.placement.get("x"), this.placement.get("y"), this.default_play);

    this.width= this.width || parseFloat(this.$inner.css('width'));
    this.height= this.height || parseFloat(this.$inner.css('height'));

    let css;

    if (css = this.placement.get('css')) {
      if (css.filter) {
        css = this.processCssFilters(css);
      }
      this.$outer.css(css);
    }
    this.script = this.placement.get("script");
    this.map = map;
    this.removed = false;
  }

};


export {NpcView};
