import {CSSProperties} from "react";
import {GlitchDeco, GlitchGame, GlitchPlatformLine} from "./space";
import {parseGradients} from "./utils";

export const stageCSS = (gameObject:GlitchGame) => {

  const parsedGradients = parseGradients(gameObject.gradient);
  let backgroundImage=null;

  if(parsedGradients.top && parsedGradients.bottom)
    backgroundImage = `linear-gradient(to bottom, ${parsedGradients.top} 0%, ${parsedGradients.bottom} 100%)`;

  return {
    backgroundImage: backgroundImage,
    position: 'relative',
    width: gameObject.dynamic.r - gameObject.dynamic.l,
    height: gameObject.dynamic.b - gameObject.dynamic.t,
    left: 304,
  } as CSSProperties;
}

export const decoTransform = (deco:GlitchDeco) => {
  return {
    transform: [deco.r ? "rotate("+deco.r+"deg)" : null, deco.h_flip ? "scaleX(-1)" : null].filter(Boolean).join(' '),
    transformOrigin: '50% 50% 0',
  }
}

export const wallCSS = (deco:GlitchDeco, offset:{x:number,y:number}) => {
  return {
    ...decoCSS(deco,offset),
    width: 1,
    top: offset.y + (deco.y),
  } as CSSProperties;
}


export const platformLineCSS = (platformLine:GlitchPlatformLine, offset) => {
  const start_pt = platformLine.endpoints[0]
  const end_pt = platformLine.endpoints[1]
  const length = Math.sqrt(Math.pow(end_pt.x - start_pt.x, 2) + Math.pow(end_pt.y - start_pt.y, 2))
  const angle = Math.atan2(end_pt.y - start_pt.y, end_pt.x - start_pt.x) * 180 / Math.PI
  return {
    position: 'absolute',
    left: offset.x + start_pt.x,
    top: offset.y + start_pt.y,
    width: length,
    height: 1,
    transform: `rotate(${angle}deg)`,
  } as CSSProperties;
}

export const decoCSS = (deco:GlitchDeco, offset:{x:number,y:number}) => {
  return {
    position: 'absolute',
    left: offset.x + (deco.x - deco.w/2),
    top: offset.y + (deco.y - deco.h),
    width: deco.w,
    height: deco.h,
    zIndex: deco.z,
  } as CSSProperties;
}


