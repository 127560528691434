//import superagentPromise from 'superagent-promise';
import superagent from 'superagent';
import {make_tile } from 'shared/util/tiles';
import { AvatarAttributes } from 'models/avatar';
import { ChromaFrame } from 'components/creator/masking/chroma';
import axios from 'axios';

import cacheModule from 'cache-service-cache-module';
import superagentCache from 'superagent-cache-plugin';
import { WebResourceAttributes } from 'models/web_resource';
import { ClazzAttributes } from 'models/clazz';
import { SpaceAttributes } from 'models/space';
import { AnchorAttributes } from 'models/anchor';
import { QuestionAttributes } from 'models/question';
import { NpcAttributes } from 'models/npc';
import { ScriptAttributes } from 'models/script';
import { GameAttributes } from 'models/game';
import { store } from 'redux/store';
import { GlitchGame } from 'components/glitch/space';
import {PaginationInfo} from 'components/shared/list_pagination';
import {activate_url, google_oauth2_url} from 'utils/urls';
import {SoundAttrs} from 'models/sound';
import {VideoAttrs} from 'models/video';
import {SpotAttrs} from 'components/actions_config';
import {PictureAttrs} from 'models/picture';

const to_query = (kv) => Object.keys(kv).reduce((acc,k) => acc.concat(`${encodeURIComponent(k)}=${encodeURIComponent(kv[k])}`), []).join('&')

const cache = new cacheModule({storage: 'session'});

const Swal =require('sweetalert2');
//const superagent = superagentPromise(_superagent, (window as any).Promise);

const API_ROOT = `https://${window.location.host}`;

const CACHE={};

const responseBody = (cache_url=null) =>
  (res) => {
    return res.body;
  }

let token:string=null;

const tokenPlugin = req => {
  const csrf = document.querySelector('[name=csrf-token]').getAttribute('content');

  req.set('X-CSRF-TOKEN', csrf)
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).accept('json').use(tokenPlugin).then(responseBody()),

  get: (url,cachable=false) => {
    if(cachable){
      if(!CACHE[url])
        CACHE[url] = superagent.get(`${API_ROOT}${url}`).accept('json').use(tokenPlugin).then(responseBody(url))
      return CACHE[url]
    }else{
      return superagent.get(`${API_ROOT}${url}`).accept('json').use(tokenPlugin).then(responseBody(url))
    }
  },

  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).accept('json').use(tokenPlugin).then(responseBody()),

  do_post: (url, body?) => {
    return superagent.post(`${API_ROOT}${url}`, body).accept('json').use(tokenPlugin)
  },
  post: (url, body?) => {
    console.warn('post is deprecated because doesnt correctly handle errors');
    return superagent.post(`${API_ROOT}${url}`, body).accept('json').use(tokenPlugin).then(responseBody())
  }
};

const Clazzes = {
  create: (clazz:Partial<ClazzAttributes>):Promise<ClazzAttributes> => {
    return requests.post('/clazzes',{clazz})
  },
  from_svg: (page_id) => requests.post('/clazzes/from_svg',{page_id}),
}

const Items = {
  create: (clazz_id) => {
    return requests.post('/items',{clazz_id})
  },

  user_inventory: () => {
    return requests.get('/items')
  },

}

const GlitchLocations = {

  update: (space_id:string, game:GlitchGame) => {
    return requests.put(`/spaces/${space_id}/glitch/location.json`,{glitch_location: {metadata: game}})
  }

}

const Auth = {
  google: () => {
    // create a form to submit
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = google_oauth2_url();
    form.style.display = 'none';
    // append csrf_token field
    const csrf = document.querySelector('[name=csrf-token]').getAttribute('content');
    const csrf_field = document.createElement('input');
    csrf_field.type = 'hidden';
    csrf_field.name = 'authenticity_token';
    csrf_field.value = csrf;
    form.appendChild(csrf_field);
    document.body.appendChild(form);
    form.submit();
  },

  current: () =>
    requests.get('/user'),

  login: (email, password) =>
    requests.post('/users/sign_in', { user: { email, password } }).then(r => {
      document.querySelector('[name=csrf-token]').setAttribute('content', r.csrf_token)
      return r;
    }),

  logout: () => {
    return superagent.del(`${API_ROOT}/users/sign_out`).accept('json').use(tokenPlugin).then((res) => {
      const csrf_token = res.header['x-csrf-token'];
      document.querySelector('[name=csrf-token]').setAttribute('content', csrf_token);
      return res.body;
    }, 
    (err) => {
      return err;
    });
  },

  register: ({name, surname, nickname, email, password, termsAccepted}) => {
    const user= {
      name,
      last_name: surname,
      nickname,
      email,
      password,
      password_confirmation: password, 
      terms_of_service: termsAccepted
    };

    return new Promise((resolve, reject) => {
      superagent.post(`${API_ROOT}/users`, {user}).accept('json').use(tokenPlugin).then((res) => {
        const csrf_token = res.header['x-csrf-token'];
        document.querySelector('[name=csrf-token]').setAttribute('content', csrf_token);
        resolve({user: res.body, csrf_token});
      }, (err) => {
        reject(err);
      });
    });
  },

  save: user =>
    requests.put('/user', { user }),

  activate: () =>
    requests.post(activate_url(),{})
};

const paginate = (p) => p ? `page=${p}` : '';
const eat_bag = (p) => p ? `bag=${p}` : '';

const Questions = {
  update: (id, question:QuestionAttributes) => requests.put(`/questions/${id}`, {question}),
  create: (question:QuestionAttributes) => {
    const game_id=question.game_id || store.getState().map.space.game_id;
    question.game_id = game_id;
    return requests.post('/questions', {question})
  },
  get: (id):Promise<QuestionAttributes> => requests.get(`/questions/${id}`, true)
}

const Npcs = {
  sample: ():Promise<NpcAttributes> => requests.get(`/npcs/sample-npc/well_known.json`, true), 

  all: ():Promise<NpcAttributes[]> => requests.get(`/npcs.json`, true), 

  get: (id):Promise<NpcAttributes> => requests.get(`/npcs/${id}.json`, true), 

  from_svg: (page_id) => requests.post('/npcs/from_svg',{page_id}),

  create: async (name, inputFrames:Array<ChromaFrame>, inverted) => {

    const e = await $.ajax({
      url: '/npcs',
      type: 'POST',
      data: {
        npc: {name, sprites_attributes: [ { name: 'default' } ]}
      },
      dataType: 'json'
    });

    Swal.fire({ type: 'success', timer: 1000, title: 'created !', text: 'Your Npc has been created.', target: '#holder' });
    const sprite = e.sprites[0];

    createSprite(sprite, inputFrames, inverted);

  },

  create2: (npc:Partial<NpcAttributes>) => requests.post('/npcs',{npc}),

}


const WebResources = {
  show: (uuid): Promise<WebResourceAttributes> => 
    requests.get(`/web_resources/${uuid}`),

  update: (uuid, web_resource) => 
    requests.put(`/web_resources/${uuid}`, {web_resource}),

  destroy: (uuid) => 
    requests.del(`/web_resources/${uuid}`),

  list: (params?:any):Promise<Array<WebResourceAttributes>> => {
    const url = `/web_resources.json?${new URLSearchParams(params).toString()}`
    return requests.get(url);
  },


  all: (filters={}):Promise<WebResourceAttributes[]> =>
    requests.get(`/web_resources.json?${to_query(filters)}`),

  create: (web_resource:Partial<WebResourceAttributes>) =>
    requests.post(`/web_resources.json`, {web_resource}),
}


const Games = {
  show: (uuid): Promise<GameAttributes> => 
    requests.get(`/games/${uuid}`),

  list_spots: (uuid): Promise<{data:Array<SpotAttrs>}> => 
    requests.get(`/games/${uuid}/list_spots`),

  update: (uuid, game) => 
    requests.put(`/games/${uuid}`, {game}),

  list: ():Promise<GameAttributes[]> => 
    requests.get('/games'),

  all: (filters={}):Promise<{data:GameAttributes[], pagination: PaginationInfo}> =>
    requests.get(`/games.json?${to_query(filters)}`),

  clone: (uuid):Promise<GameAttributes> =>
    requests.post(`/games/${uuid}/clone`),

  create: (game) =>
    requests.post('/games', { game }),

  destroy: (uuid) =>
    requests.del(`/games/${uuid}`),
}


const Spaces = {
  show: (uuid): Promise<SpaceAttributes> => 
    requests.get(`/spaces/${uuid}`),

  update_metadata: (uuid, masks, editor) => 
    requests.post(`/spaces/${uuid}/metadata`, {masks, editor}),

  update: (uuid, space) => 
    requests.put(`/spaces/${uuid}`, {space}),

  list: (game_id?):Promise<SpaceAttributes[]> => game_id ? requests.get(`/games/${game_id}/spaces`) : requests.get(`/spaces`),

  all: ({page,bag}:{page?:number, bag?:string}={}):Promise<Array<SpaceAttributes>> =>
    requests.get(`/spaces.json?${[paginate(page),eat_bag(bag)].filter(x=>x.length).join('&')}`),

  create: (space, clone_space_id) =>
    requests.post('/spaces', { space, clone_space_id}),
}

const Texts = {
  create: (text) => requests.do_post('/texts', { text }),
  show: (text_id) =>
    requests.get(`/texts/${text_id}.json`),
}

const Pictures = {
  create: (name, file) => {
    const formData = new FormData();
    if(file instanceof File){
      formData.append('picture[image]', file);
    }else{
      formData.append('picture[remote_image_url]', file);
    }
    formData.append('picture[name]', name);

    return requests.post('/pictures', formData);
  },
  show: (picture_id) =>
    requests.get(`/pictures/${picture_id}.json`),

  list: (params?:any):Promise<Array<PictureAttrs>> => {
    const url = `/pictures.json?${new URLSearchParams(params).toString()}`
    return requests.get(url);
  },

}

const Sounds = {
  show: (sound_id):Promise<SoundAttrs> =>
    requests.get(`/sounds/${sound_id}.json`),

  list: (params?:any):Promise<Array<SoundAttrs>> => {
    const url = `/sounds.json?${new URLSearchParams(params).toString()}`
    return requests.get(url);
  },

  create: (name,file) => {
    const formData = new FormData();
    formData.append('sound[audio]', file);
    formData.append('sound[name]', name);
    return requests.post('/sounds', formData);
  },
}

const Videos = {
  show: (video_id):Promise<VideoAttrs> =>
    requests.get(`/videos/${video_id}.json`),

  list: (params?:any):Promise<Array<VideoAttrs>> => {
    const url = `/videos.json?${new URLSearchParams(params).toString()}`
    return requests.get(url)
  },

  create: (name,file) => {
    const formData = new FormData();
    formData.append('video[video]', file);
    formData.append('video[name]', name);
    return requests.post('/videos', formData);
  },

}

const Anchors = {
  show: (anchor_id):Promise<AnchorAttributes> =>
    requests.get(`/anchors/${anchor_id}.json`),

  update: (space_id, anchor:Partial<AnchorAttributes>):Promise<string> => {
    return requests.put(`/spaces/${space_id}/anchors/${anchor.id}.json`, {anchor})
  },

  create: (space_id, anchor:Partial<AnchorAttributes>):Promise<string> => {
    return requests.post(`/spaces/${space_id}/anchors.json`, {anchor})
  },

  all: (space_id):AnchorAttributes[] =>
    requests.get(`/spaces/${space_id}/anchors.json`),
}

const Scripts = {
  destroy: (script_id):Promise<ScriptAttributes> =>
    requests.del(`/scripts/${script_id}.json`),

  show: (script_id):Promise<ScriptAttributes> =>
    requests.get(`/scripts/${script_id}.json`),

  create: (script:Partial<ScriptAttributes>) => {
    const game_id=script.game_id || store.getState().map.space.game_id;
    script.game_id = game_id;
    return requests.post('/scripts', {script})
  },

  all: (filters={}):Promise<ScriptAttributes[]> =>
    requests.get(`/scripts.json?${to_query(filters)}`),
}

const createSprite = async (sprite, inputFrames:Array<ChromaFrame>, inverted) => {
    const fd = new FormData();
    const [tile, frames, sprite_width, sprite_height] = await make_tile(inputFrames);
    const framesA = Object.keys(frames);
    framesA.forEach((fr) => frames[`${fr}-rev`] = {...frames[fr], transforms: { ...frames[fr].transforms, scaleX: -1}})
    const framesB = framesA.map(fr => `${fr}-rev`)

    const sequences = {
      right: inverted == 'right' ? framesB : framesA,
      left: inverted == 'right' ? framesA : framesB,
    };

    return new Promise((accept,reject) => {
      tile.toBlob(async (blob) => {
        fd.append('sprite[sprite_width]', String(sprite_width));
        fd.append('sprite[sprite_height]', String(sprite_height));
        fd.append('sprite[graphic]', blob, 'tile.png');
        fd.append('sprite[map]', JSON.stringify({ frames, sequences }));

        try{
          const result = await $.ajax({
            url: `/sprites/${sprite.id}.json`,
            type: 'PUT',
            data: fd,
            processData: false,
            contentType: false
          })

          Swal.fire({
            type: 'success',
            timer: 1000,
            title: 'Sprite saved !',
            text: 'Your sprite has been saved.',
            target: '#holder',
          });
        } catch(e){
          Swal.fire({
            type: 'error',
            timer: 1000,
            title: 'Error saving !',
            text: e.message,
            target: '#holder',
          });
        }
      });
    })

}

const Avatars = {

  search: (query:string, pageNumber?:number, cancelToken?:any):Promise<Array<AvatarAttributes>> => {
    const searchUrl = `/avatars/search.json?models=Avatar&query=${query}${pageNumber}`;

    return axios.get(searchUrl, { cancelToken }).then(({data}) => data)

  },

  show: (id):Promise<AvatarAttributes> => requests.get(`/avatars/${id}.json?`),

  all: ({page,bag}:{page?:number, bag?:string}={}):Promise<Array<AvatarAttributes>> =>
    requests.get(`/avatars.json?${[paginate(page),eat_bag(bag)].filter(x=>x.length).join('&')}`),

  create: async (name, inputFrames:Array<ChromaFrame>, inverted) => {

    const e = await $.ajax({
      url: '/avatars',
      type: 'POST',
      data: {
        avatar: {name, sprites_attributes: [ { name: 'default' } ]}
      },
      dataType: 'json'
    });

    Swal.fire({ type: 'success', timer: 1000, title: 'created !', text: 'Your avatar has been created.' });

    const sprite = e.sprites[0];

    return createSprite(sprite, inputFrames, inverted);
  }
}

const Profile = {
  follow: username =>
    requests.post(`/profiles/${username}/follow`),
  get: username =>
    requests.get(`/profiles/${username}`),
  unfollow: username =>
    requests.del(`/profiles/${username}/follow`)
};

const setToken= _token => { token = _token; }

export {
  Npcs,
  Anchors,
  Questions,
  Items,
  Clazzes,
  Pictures,
  Texts,
  Sounds,
  Videos,
  Spaces,
  Games,
  WebResources,
  Scripts,
  Avatars,
  Auth,
  Profile,
  GlitchLocations,
  setToken,
  tokenPlugin,
  requests,
};

