import React, {useEffect, useRef, useState} from "react";
import { Avatar2 } from 'components/avatars/avatar';
import {T} from 'components/utils/t';
import { makeStyles } from '@material-ui/core/styles';

import zutto_tree_3     from 'images/glitch/ilmenskie/zutto_tree_3.svg';
import tree_12          from 'images/glitch/ilmenskie/tree_12.svg';
import tree_7           from 'images/glitch/ilmenskie/tree_7.svg';
import tree_8           from 'images/glitch/ilmenskie/tree_8.svg';
import zutto_platform_1 from 'images/glitch/ilmenskie/zutto_platform_1.svg';
import zutto_cloud_1    from 'images/glitch/ilmenskie/zutto_cloud_1.svg';
import zutto_cloud_2    from 'images/glitch/ilmenskie/zutto_cloud_2.svg';
import ground_2         from 'images/glitch/ilmenskie/ground_2.svg';
import ground_3         from 'images/glitch/ilmenskie/ground_3.svg';
import ground_4         from 'images/glitch/ilmenskie/ground_3.svg';

const prefix = 'js.home.landing1';

const FPS = 10;
const PPS = 500;
const dist = (a,b) => Math.sqrt((b.x-a.x)**2 + (b.y-a.y)**2);
const angle = (a,b) => Math.atan2(b.y-a.y, b.x-a.x)
const norm = (a) => a >= 0 ? a : a+(2*Math.PI)
const direction = (a,b) => ['right','up','left','down'][Math.floor(4*norm(angle(a,b)+Math.PI/4)/(2*Math.PI))];

const lila_oscuro= '#541262';
const lila_claro= '#EFBCD5';

const useStyles = makeStyles((theme) => ({
  background: {
    background: `linear-gradient(${lila_oscuro}, ${lila_claro})`,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 800,
    width: '100%',
    '& .reactAvatar': {
      zIndex: 10,
    },
    '& > img': {
      position: 'absolute',
    },
    '& .zutto_tree_3': {
      width: '17%',
      right: '24.3%',
      bottom: '16%',
      zIndex: 12,
    },
    '& .zutto_tree_3-2': {
      width: '17%',
      left: '24.3%',
      bottom: '16%',
      zIndex: 12,
      transform: 'scaleX(-1)',
    },
    '& .tree_12': {
      width: '11%',
      left: '1.5%',
      bottom: '27.5%',
      zIndex: 2,
    },
    '& .tree_7': {
      width: '9%',
      right: '14.6%',
      bottom: '15%',
      zIndex: 1,
    },
    '& .tree_8': {
      width: '16%',
      right: '1.6%',
      bottom: '14.75%',
      zIndex: 1,
    },
    '& .zutto_platform_1': {
      width: '17%',
      left: '1%',
      bottom: '12.5%',
      zIndex: 1,
    },
    '& .zutto_cloud_1': {
      width: '16%',
      left: '6.94%',
      top: '43.7%',
      zIndex: 0,
    },
    '& .zutto_cloud_2': {
      width: '15%',
      left: '27.7%',
      top: '42.5%',
      zIndex: 0,
    },
    '& .zutto_cloud_1-2': {
      width: '17%',
      right: '27.7%',
      top: '45%',
      zIndex: 0,
    },
    '& .zutto_cloud_2-2': {
      width: '16%',
      right: '6.9%',
      top: '44.37%',
      zIndex: 0,
    },
    '& .ground_2': {
      left: '0%',
      bottom: '0%',
    },
    '& .ground_3': {
      left: '6.9%',
      bottom: '0%',
    },
    '& .ground_4': {
      left: '17.3%',
      bottom: '0%',
    },
    '& .ground_2-1': {
      left: '20%',
      bottom: '0%',
    },
    '& .ground_3-1': {
      left: '30%',
      bottom: '0%',
    },
    '& .ground_4-1': {
      left: '40%',
      bottom: '0%',
    },
    '& .ground_2-2': {
      right: '0%',
      bottom: '0%',
    },
    '& .ground_3-2': {
      right: '6.9%',
      bottom: '0',
    },
    '& .ground_4-2': {
      right: '17.3%',
      bottom: '0',
    },
  },
}));

type Point = {
  x:number,y:number
};

type Movement = {
  now:number,
  origin: Point,
  direction: string,
  destination: Point,
  duration:number,
  start:number,
}

const restrictAvatar = (y, [max,min]) => Math.max(max, Math.min(min, y));

const NiceBg:React.FC<React.HTMLAttributes<HTMLDivElement> & {avatar,avatarSays}> = ({avatar, avatarSays, ...rest}) => {

  const classes = useStyles();

  const [movement, setMovement] = useState({
    origin: { x: window.innerWidth / 2, y: 513 },
    destination: { x: window.innerWidth / 2, y: 513 },
    direction: 'right',
    now: 1000,
    start: 0,
    duration: 1000,
  })

  const raf = useRef(null);
  const avatarRef = useRef(null);

  const t = movement.start ? Math.min(movement.now - movement.start, movement.duration) : 0;
  const x = Math.round(movement.origin.x + (t * (movement.destination.x - movement.origin.x) / movement.duration));

  const paint = (now, movement) => {
    const newMov = { ...movement, now, start: movement.start || now };
    setMovement(newMov);
    const t = movement.start ? Math.min(movement.now - movement.start, movement.duration) : 0;
    const x = Math.round(movement.origin.x + (t * (movement.destination.x - movement.origin.x) / movement.duration));

    if (!(movement.destination.x === x /*&& movement.destination.y === y*/)) {
      raf.current = window.requestAnimationFrame((t)=>paint(t,newMov));
    }else{
      window.cancelAnimationFrame(raf.current);
      console.log('finishing because ', movement.destination.x, x);
    }
    console.log(`painting at ${now}`, movement);
  };

  const y = restrictAvatar(Math.round(movement.origin.y + (t * (movement.destination.y - movement.origin.y) / movement.duration)), [545, 520]);

  const walk = ({ newx, newy }) => {
    const destination = { x: Math.round(newx), y: restrictAvatar(Math.round(newy), [545, 520]) };
    const origin = { x, y };
    const dir = direction(origin, destination) || 'right';

    if (origin.x === destination.x && origin.y === destination.y) return;
    const newMovement = {
      origin,
      destination,
      direction: ['right', 'left'].indexOf(dir) >= 0 ? dir : 'right',
      duration: dist(origin, destination) * 1000 / PPS,
      now: 0,
      start: null,
    };
    console.log('walking to ', newMovement)

    setMovement(newMovement);

    raf.current = window.requestAnimationFrame((t)=>paint(t,newMovement));
  };

  useEffect(()=>{
    return () => {
      if (raf.current) {
        window.cancelAnimationFrame(raf.current);
      }
    }
  },[]);

  const handleClick = (e) => {
    // check if there's an <a> tag in the ancestors of the clicked element and if so, don't walk
    if(e.target.closest('a')) return;
    if(!avatarRef.current) return

    walk({newx:e.pageX - avatarRef.current.clientWidth/2, newy:e.pageY - avatarRef.current.clientHeight})
  }

  return <div className={classes.background} onClick={handleClick}>

    {avatar && (
      <Avatar2 fps={FPS} t={t} ref={avatarRef} animation={movement.direction} avatar={avatar} x={x} y={y}>
        <div className="viwoc-bubble triangle-bottom">
          {avatarSays}
        </div>
      </Avatar2>
    )}

    <img className="highlight zutto_tree_3"     src={zutto_tree_3}></img>
    <img className="highlight zutto_tree_3-2"   src={zutto_tree_3}></img>
    <img className="highlight tree_12"          src={tree_12}></img>
    <img className="highlight tree_7"           src={tree_7}></img>
    <img className="highlight tree_8"           src={tree_8}></img>
    <img className="highlight zutto_platform_1" src={zutto_platform_1}></img>
    <img className="highlight zutto_cloud_1"    src={zutto_cloud_1}></img>
    <img className="highlight zutto_cloud_2"    src={zutto_cloud_2}></img>
    <img className="highlight zutto_cloud_1-2"  src={zutto_cloud_1}></img>
    <img className="highlight zutto_cloud_2-2"  src={zutto_cloud_2}></img>
    <img className="highlight ground_2"         src={ground_2}></img>
    <img className="highlight ground_3"         src={ground_3}></img>
    <img className="highlight ground_4"         src={ground_4}></img>
    <img className="highlight ground_2-1"       src={ground_2}></img>
    <img className="highlight ground_3-1"       src={ground_3}></img>
    <img className="highlight ground_4-1"       src={ground_4}></img>
    <img className="highlight ground_2-2"       src={ground_2}></img>
    <img className="highlight ground_3-2"       src={ground_3}></img>
    <img className="highlight ground_4-2"       src={ground_4}></img>
    {rest.children}
  </div>
}

export default NiceBg;
