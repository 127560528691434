import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch, useSelector} from 'react-redux';
import {InteractionModes} from 'components/canvas/utils/ObjectUtil';
import {setInteractionMode} from 'redux/reducers/editor';
import {Box, Typography} from '@material-ui/core';

const options = [InteractionModes.LINE, InteractionModes.LADDER, InteractionModes.WALL];

const ButtonableMenu = (props) => {
  const {variant,disableElevation,disableFocusRipple,disableRipple,fullWidth,...rest} = props;
  return <Menu {...rest} />
}

export default function SplitButton() {

  const interactionMode = useSelector((state: any) => state.editor.interactionMode);
  const dispatch = useDispatch();
  const active = options.includes(interactionMode);

  const [anchorEl, setAnchorEl] = useState(null); // Anchor for the menu
  const [selectedIndex, setSelectedIndex] = useState(0); // Selected option index

  const handleToggle = () => {
    if(active) {
      dispatch(setInteractionMode(InteractionModes.SELECTION));
      return;
    }
    dispatch(setInteractionMode(options[selectedIndex]));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    dispatch(setInteractionMode(options[index]));
    setAnchorEl(null);
    // Perform any additional actions with the selected option here
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ButtonGroup variant="contained" color={active ? 'primary' : 'default'}>
      <Button onClick={handleToggle}>
        {/* show the icon and below the text using flex stacking vertically */}
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <i className={`fas fa-pen-line`}></i>
          <Typography variant="subtitle1" style={{fontSize: '0.8rem', display: 'inline-block'}}>
            {options[selectedIndex]}
          </Typography>
        </Box>
      </Button>
      <Button
        color={active ? 'primary' : 'default'}
        aria-controls={anchorEl ? 'split-button-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <ArrowDropDownIcon />
      </Button>
      <ButtonableMenu
        id="split-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {options.map((option, index) => (
          <MenuItem key={option} selected={index === selectedIndex} onClick={() => handleMenuItemClick(index)} >
            {option}
          </MenuItem>
        ))}
      </ButtonableMenu>
    </ButtonGroup>
  );
}

