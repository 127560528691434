import React, {FormEvent, useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextField} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {setTitle} from 'redux/reducers/creatix';
import {T} from 'components/utils/t';

export default function Title({handleNext, idx}) {

  const dispatch = useDispatch();
  const title = useSelector((state:any) => state.creatix.title);
  const [t, setT] = useState(title);

  const handleSubmit = (event?: FormEvent<HTMLFormElement>|null) => {
    event?.preventDefault?.();
    dispatch(setTitle(t));
    handleNext(idx + 1);
  }

  return <Dialog
    BackdropProps={{ style: { display: 'none' } }}
    disableEscapeKeyDown
    className="game_welcome step3"
    open={true}
    onClose={(_ev,reason)=>false}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="sm"
  >
    <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
      <T k="creatix.title.title">To explore the game, click or touch the screen, right in the point you want to go.</T>
    </DialogTitle>

    <DialogContent>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <TextField
          label="Game title"
          variant="outlined"
          value={t}
          onChange={(e)=>setT(e.target.value)}
          placeholder="An adventure game in the enchanted forest."
        />
      </form>
    </DialogContent>

    <DialogActions>
      <Button onClick={()=>{handleNext(idx - 1)}} color="primary">
        <T k="creatix.buttons.previous">Previous</T>
      </Button>
    
      <Button onClick={()=>handleSubmit()} color="primary">
        <T k="creatix.buttons.next">Next</T>
      </Button>
    </DialogActions>
  </Dialog>
}

