import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface ProtectedRouteProps extends RouteProps {
  component: any;
  exact?: boolean;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {

  const currentUser = useSelector((state: any) => state.common.currentUser);

  if (!Component) return null;

  if (!Component.sign_in_required)
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  const sign_in_reason_key = Component.sign_in_reason_key

  if(currentUser){
    return <Route {...rest} render={(props) => <Component {...props}/>}/>
  }else{
    return <Route {...rest} render={(props)=> 
      <Redirect to={{ pathname: '/login', state: { from: props.location, sign_in_reason_key  }, }} />
    }/>
  }
};

export default ProtectedRoute;

