import {Button} from '@material-ui/core';
import React from 'react';
import Icon from '../icon/Icon';

export declare type SizeType = 'small' | 'middle' | 'large' | undefined;

export type TooltipPlacement = 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';

declare const ButtonTypes: ["default", "primary", "ghost", "dashed", "link", "text"];
export declare type ButtonType = typeof ButtonTypes[number];
declare const ButtonShapes: ["default", "circle", "round"];
export declare type ButtonShape = typeof ButtonShapes[number];



export type BtnProps2 = {
  onClick?: any
  name?: string,
  id?: string,
  style?: object,
  wrapperStyle?: object,
  wrapperClassName?: string,
  tooltipTitle?: string,
  tooltipPlacement?: TooltipPlacement, //string,
  className?: string,
  icon?: string,
  iconStyle?: object,
  iconClassName?: string,
  iconAnimation?: string,
  visible?: boolean,
  shape?: ButtonShape, //string,
  disabled?: boolean,
  type?: string,
  size?: SizeType, //"small" | "medium",
  loading?:boolean,
  color?: 'default'|'primary'|'secondary'|'inherit';
  children?: React.ReactNode[] | React.ReactNode
}

export default function CommonButton(p:BtnProps2) {
  const props = Object.assign({ type: 'default', visible: true, disabled: false, loading: false, color: 'default'}, p);

  if(!props.visible)
    return null;

  return <Button id={props.id} className={props.className} style={props.style} onClick={props.onClick} variant="contained" color={props.color} disabled={props.disabled}>
        {props.icon && <Icon name={props.icon} style={props.iconStyle} className={props.iconClassName} animation={props.iconAnimation} />}
        {props.children}
      </Button>
}

