import { fabric } from 'fabric';
import throttle from 'lodash/throttle';

import CanvasController from './CanvasController';
import { FabricObject } from '../utils';
import BaseHandler from './BaseHandler';

export type TransactionType =
  | 'add'
  | 'remove'
  | 'moved'
  | 'scaled'
  | 'rotated'
  | 'skewed'
  | 'group'
  | 'ungroup'
  | 'paste'
  | 'bringForward'
  | 'bringToFront'
  | 'sendBackwards'
  | 'sendToBack'
  | 'redo'
  | 'undo';

export interface TransactionTransform {
  scaleX?: number;
  scaleY?: number;
  skewX?: number;
  skewY?: number;
  angle?: number;
  left?: number;
  top?: number;
  flipX?: number;
  flipY?: number;
  originX?: string;
  originY?: string;
}

export interface TransactionEvent {
  json: string;
  type: TransactionType;
}

class TransactionHandler extends BaseHandler {

  redos: TransactionEvent[];
  undos: TransactionEvent[];
  active: boolean = true;
  state: FabricObject[] = [];

  constructor(handler: CanvasController) {
    super(handler);
    //if (this.handler.editable) {
    this.initialize();
    //}
  }

  /**
   * Initialize transaction handler
   *
   */
  public initialize = () => {
    this.redos = [];
    this.undos = [];
    this.state = [];
    this.active = true;
  };

  /**
   * Save transaction
   *
   * @param {TransactionType} type
   * @param {*} [canvasJSON]
   * @param {boolean} [isWorkarea=true]
   */
  public save = (type: TransactionType, canvasJSON?: any, _isWorkarea: boolean = true) => {

    if (this.controller.autosave) {
      this.controller.save()
    }
    if (!this.controller.keyEvent.transaction) {
      return;
    }
    try {
      if (this.state) {
        const json = JSON.stringify(this.state);
        this.redos = [];
        this.undos.push({ type, json, });
      }
      const { objects }: { objects: FabricObject[] } = canvasJSON || this.controller.canvas.toJSON(this.controller.propertiesToInclude);
      this.state = objects.filter(obj => {
        if (obj.id === 'workarea') {
          return false;
        } else if (obj.id === 'grid') {
          return false;
        } else if (obj.superType === 'port') {
          return false;
        }
        return true;
      });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Undo transaction
   *
   */
  public undo = throttle(() => {
    const undo = this.undos.pop();
    if (!undo) {
      return;
    }
    this.redos.push({
      type: 'redo',
      json: JSON.stringify(this.state),
    });
    this.replay(undo);
  }, 100);

  /**
   * Redo transaction
   *
   */
  public redo = throttle(() => {
    const redo = this.redos.pop();
    if (!redo)
      return;
    this.undos.push({ type: 'undo', json: JSON.stringify(this.state), });
    this.replay(redo);
  }, 100);

  /**
   * Replay transaction
   *
   * @param {TransactionEvent} transaction
   */
  public replay = (transaction: TransactionEvent) => {
    const objects = JSON.parse(transaction.json) as FabricObject[];
    this.state = objects;
    this.active = true;
    this.controller.canvas.renderOnAddRemove = false;
    this.controller.clear();
    this.controller.canvas.discardActiveObject();
    fabric.util.enlivenObjects(objects, (enlivenObjects: FabricObject[]) => {
      enlivenObjects.forEach(obj => {
        const targetIndex = this.controller.canvas._objects.length;
        this.controller.canvas.insertAt(obj, targetIndex, false);
      });
      this.controller.canvas.renderOnAddRemove = true;
      this.active = false;
      this.controller.canvas.renderAll();
      this.controller.objects = this.controller.getObjects();
      if (this.controller.onTransaction) {
        this.controller.onTransaction(transaction);
      }
    }, null,);
  };
}

export default TransactionHandler;
