/*<reference path=”app/javascript/interfaces.d.ts” />*/

import Cookies from 'js-cookie'
import { sweet_prompt as prompt } from 'utils/prompt';

import {BaseEvents} from 'utils/base_events';
import {Events as BackboneEvents, ModelFetchOptions} from 'backbone';
import * as _ from 'underscore';
import { MapView} from './views/map_view';
import { dist} from 'utils/dist';
import { EditPictureView } from './views/edit_picture';
import { EditSoundView } from './views/edit_sound';
import { EditVideoView } from './views/edit_video';
import { EditTextView } from './views/edit_text';
import { VideoView } from './views/video_view';
import { PictureView } from './views/picture_view';
import { WebResourceView } from './views/web_resource_view';
import { CssEditorView } from './views/css_editor_view';
import { TextView } from './views/text_view';
import { ScriptView } from './views/script_view';
import { ScriptExecutor } from 'models/script_executor';
import { QuestionAnswersView } from 'maps/views/question_answers';
import { Anchor } from 'models/anchor';
import { Avatar } from 'models/avatar';
import { User } from 'models/user';
import { Npc } from 'models/npc';
import { NpcPlacement } from 'models/npc_placement';
import { Tile } from 'models/tile';
import { Space } from 'models/space';
import { Script, ScriptTarget } from 'models/script';
import { Sprite } from 'models/sprite';
import { Sound } from 'models/sound';
import { Video } from 'models/video';
import { Text } from 'models/text';
import { Picture } from 'models/picture';
import { ModalView } from 'views/modal_view';
import { RecordingManager } from './managers/recording_manager';
import { AvatarView, AvatarHolder } from './views/avatar_view';
import { PersonaView } from './views/persona_view';
import * as Backbone from 'backbone';
import { Events } from './map_events';
import CacheReferences from 'api/world';
import { store } from 'redux/store'
import { MODES } from 'components/world/constants';
import { MAP_SHOW_WEB_RESOURCE } from 'constants/actionTypes';
import { Anchors, WebResources } from 'api/agent';
import { Question } from 'models/question';
import { WebResource } from 'models/web_resource';
import { isYoutubeUrl } from 'shared/components/inputs/url-input';

const request2 = <T>(key, id):Promise<T> => {
  const model = new MODELS[key]({ id }, {});
  return model.fetch().then(() => model)
}

const MODELS:any = { Text, Video, Picture, Anchor, Sprite, Sound, Avatar, User, Npc, NpcPlacement, Tile, Space, Script, Question, WebResource};

class MapController extends BaseEvents {

  public mute:boolean=false;
  public cache:CacheReferences;
  public space:Space;
  public map:MapView;
  public local_char:AvatarView<any>;
  public editing: MODES=MODES.Normal;
  public personas: PersonaView[]; // TODO set type
  public recording_manager: RecordingManager;
  public rootElement: HTMLDivElement;
  public previousProps: any;

  constructor(opts:{el?: HTMLDivElement}={}) {
    super()
    _.extend(this, BackboneEvents);

    this.rootElement = opts.el || ($('#holder')[0] as HTMLDivElement);

    this.cache = new CacheReferences();

    const unsubscribe = store.subscribe(this.handleChange.bind(this));
    this.previousProps = store.getState().map;

  }

  async handleChange () {
    const mapProps = store.getState().map as any;

    if(mapProps.mute != this.previousProps.mute){
      this.mute = mapProps.mute;
    }
    if(mapProps.mode != this.previousProps.mode){
      this.toggleMode(mapProps.mode)
    }
    if(mapProps.fullscreen != this.previousProps.fullscreen){
      this.toggleFullscreen(mapProps.fullscreen)
    }
    if(mapProps.show_anchors != this.previousProps.show_anchors){
      this.toggleAnchors(mapProps.show_anchors)
    }

    if(mapProps.show_zones != this.previousProps.show_zones){
      this.toggleZones(mapProps.show_zones)
    }

    if(mapProps.zoom != this.previousProps.zoom){
      this.map.zoom(mapProps.zoom)
    }

    this.previousProps = mapProps;
  }


  createAnchor(name, x, y, space) {
    const a = new Anchor({ name: name, x: x, y: y, space_id: space.id }, { dispatcher: this });
    a.save(null, {
      error: function(jqXHR, text) {
        console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        a.set("id", textStatus.id);
        console.log("AJAX success:", textStatus);
        this.trigger(Events.CREATE_ANCHOR, a);
      }
    });
  }

  async new_model(thing:{class:string, callback?:(model)=>any}) {
    let title, view;
    const thingy = new MODELS[thing.class]({}, { dispatcher: this });

    switch (thing.class) {
      case 'Picture':
        [view, title] = [ new EditPictureView({ model: thingy, callback: thing.callback }), 'New Picture' ];
        break;
      case 'Sound':
        [view, title] = [ new EditSoundView({ model: thingy, callback: thing.callback }), 'New Sound' ];
        break;
      case 'Video':
        [view, title] =[ new EditVideoView({ model: thingy, callback: thing.callback }), 'New Video' ];
        break;
      case 'Text':
        [view, title] =[ new EditTextView({ model: thingy, callback: thing.callback }), 'New Text' ];
        break;
      case 'Question':
        const {value:question}=await prompt('What Question?');
        const answers=[];
        let ans:any= await prompt('Enter answer (empy if no more answers)');

        while(ans.value.length){
          answers.push(ans.value)
          ans= await prompt('Enter answer (empy if no more answers)');
        }
        [view, title] =[ new QuestionAnswersView({question, answers, callback: thing.callback, dispatcher: this}), 'New QA' ];
        break;
      default:
        throw 'unknown thing';
    }
    const modal = new ModalView({ view: view, dispatcher: this, title: title });
    modal.render().$el.appendTo('.map').modal({show:true});
  }

  async edit_model(things) {

    for(let clazz in things){
      if (!things[clazz]) 
        return;
      
      const {label, id} = things[clazz];

      let title, thingy, view;

      switch(clazz){
        case 'sound':
          thingy = await this.request<Sound>('Sound',id);
          view= new EditSoundView({ model: thingy })
          title = `Edit Sound ${thingy.get('name')}` ;
          break;
        case 'picture':
          thingy = await this.request<Picture>('Picture',id);
          view=new EditPictureView({ model: thingy }); 
          title=  `Edit Picture ${thingy.get('name')}` ;
          break;
        case 'video':
          thingy = await this.request<Video>('Video',id);
          view = new EditVideoView({ model: thingy });
          title= `Edit Video ${thingy.get('name')}`
          break
        case 'text':
          thingy = await this.request<Text>('Text',id);
          view = new EditTextView({ model: thingy });
          title = `Edit Text ${thingy.get('name')}`;
          break;
        case 'question':
          thingy = await this.request<Question>('Question',id);
          view = new QuestionAnswersView({ question: thingy.title, answers: thingy.answers.map((a)=>a.label), id , dispatcher: this});
          title = `Edit Question ${thingy.title}`;
          break;
      }
      if(view)
        this.showViewInModal(view, title);
    }
  }

  showViewInModal(view,title){
    const modal = new ModalView({ view, title, dispatcher: this });
    modal.render().$el.appendTo('.map').modal();
  }

  async travel_to_anchor(anchor_id) {
    if (this.isEditing) {
      return;
    }

    const anchor = await Anchors.show(anchor_id);

    if (this.space.id === anchor.space_id) {
      this.moveCurrent(anchor.x, anchor.y);
    } else {
      const spac = await this.request<Space>("Space", anchor.space_id)
      this.trigger(Events.SET_SPACE, spac, { x: anchor.x, y: anchor.y });
    }
  }

  async travel_to(space_id) {
    if (this.isEditing) {
      return;
    }
    const space = await this.request<Space>("Space", space_id)
    let x=100,y=100;

    if(space.get('metadata')?.initial_point){
      const anchor = await this.request<Anchor>("Anchor", space.get('metadata')?.initial_point)
      x=anchor.get('x');
      y=anchor.get('y');
    }

    this.trigger(Events.SET_SPACE, space, {x,y});
  }

  playScene(scene, who) {
    scene = who.scenes[scene];
    for (let name in scene) {
      const calls = scene[name];
      (who.avatar_views.concat(who).find(x => x.name == name) as AvatarView<Avatar>).replay(calls, who);
    }
  }

  play_sound(id, thing) {
    try {
      console.log("playing ", id, thing.sound.instance.playState);
    } catch (error) {
      console.log("playing ", id, null);
    }
    if (this.mute || this.isEditing) {
      return;
    }
    return this.request("Sound", id).then((sound) => {
      var fun, ref, ref1;
      if (thing.sound !== sound) {
        if ((ref = thing.sound) != null) {
          if ((ref1 = ref.instance) != null) {
            ref1.stop();
          }
        }
      }
      thing.sound = sound;
      fun = function() {
        switch (thing.sound.instance.playState) {
          case createjs.Sound.PLAY_FINISHED:
            return thing.sound.instance.play();
          case createjs.Sound.PLAY_SUCCEEDED:
            return thing.sound.instance.stop();
          default:
            return thing.sound.instance.play();
        }
      };

      if (thing.sound.instance) {
        return fun();
      }
    });
  }

  async play_video(id, where, thing) {
    if (this.mute || this.isEditing || thing.video_view) {
      return;
    }
    const video = await this.request("Video", id)
    thing.video_view = new VideoView({
      model: video,
      dispatcher: this
    });
    thing.video_view.on("remove", function() {
      return thing.video_view = null;
    });

    where.append(thing.video_view.render().el);
  }

  show_picture(id, where, thing) {
    if (this.mute || this.isEditing || thing.picture_view) {
      return;
    }
    return this.request("Picture", id).then((picture) => {
      thing.picture_view = new PictureView({
        model: picture,
        dispatcher: this
      });
      thing.picture_view.on("remove", function() {
        return thing.picture_view = null;
      });
      return where.append(thing.picture_view.render().el);
    });
  }

  async show_web_resource(id, where, thing, promise) {
    if (/*this.isEditing || */thing.picture_view) {
      return;
    }

    if(id.match(/^https?:/)){
      if(isYoutubeUrl(id)){
        const ytid=id.match(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/)[1]
        id = `https://www.youtube.com/embed/${ytid}`
      }
      store.dispatch({ type: MAP_SHOW_WEB_RESOURCE, web_resource: {url: id}, closeWebResource: promise.accept })
    }else if(false){
      const web_resource = await this.request("WebResource", id);
      thing.web_resource = new WebResourceView({
        model: web_resource,
        dispatcher: this
      });
      thing.web_resource_view.on("remove", function() {
        return thing.web_resource_view = null;
      });
      return where.append(thing.web_resource_view.render().el);
    }else{
      const web_resource = await WebResources.show(id);
      store.dispatch({ type: MAP_SHOW_WEB_RESOURCE, web_resource: web_resource, closeWebResource: promise.accept })
    }
  }


  visibility(mode, duration, target) {
    return target.visibility(mode, duration);
  }

  transmute(id, persona_view: PersonaView) {
    const ava = new Avatar({ id: id }, { dispatcher: this });

    ava.fetch(({ dispatcher: this } as ModelFetchOptions)).done(function() {
      persona_view.setModel(ava);
    });
  }

  edit_css(model) {
    const view = new CssEditorView({ model: model, dispatcher: this });
    return $(this.rootElement).append(view.render().el);
  }

  async show_text(id, where, thing) {
    if (this.isEditing || thing.text_view) {
      return;
    }

    const text = await this.request<Text>("Text", id);

    thing.text_view = new TextView({ model: text, dispatcher: this });
    thing.text_view.on("remove", () => thing.text_view = null)
    where.append(thing.text_view.render().el);
    console.log("rendered text:", text);

    setTimeout(() => $(document).one("click", ":not(.text_view) *", (e) => {
      if(thing.text_view && !thing.text_view.is_editable) 
        thing.text_view.remove();
    }), 0);
  }

  request<T>(key, param?, opts:any = {}):Promise<T> {
    if (key === 'get_url') {
      if (opts.fresh) {
        delete this.cache.urls[param];
      }
      if (!this.cache.urls[param]) {
        this.cache.urls[param] = $.get(param);
      }
      return this.cache.urls[param];
    } else if (key in MODELS) {
      const model = new MODELS[key]({ id: param }, { dispatcher: this });
      return model.fetch({ dispatcher: this }).then(() => model)
    } else if (key in this.cache) {
      return this.cache[key];
    } else {
      console.error('Unknown:',key)
    }
  }

  async script_player(id, where:PersonaView) {

    const s = await this.request<Script>("Script", id);
    const script = s//.relativize(where.x, where.y);

    const player = new ScriptExecutor({ model: script, where: where }, { dispatcher: this });

    const playerview = new ScriptView({
      model: script,
      instance: player,
      dispatcher: this,
      where: where
    });

    if(window.location.host.toString().match(/dev\./)){
      (window as any).playerview = playerview;
      console.log('window.playerview=',playerview);
    }

    $(this.rootElement).append(playerview.render().el);
  }

  async play_script(id, where:ScriptTarget, opts) {
    const script = await this.request<Script>("Script", id);
    const script2 = script//.relativize(where.x, where.y);
    this.map.playScript(script2, where.x, where.y, where, opts);
  }

  leaveSpace() {
    if(this.cache.SocketManager)
      this.cache.SocketManager.leave()
  }

  addChar(who:AvatarView<Avatar>, x, y, target:AvatarHolder|ScriptTarget) {
    this.map.addAvatar(who, {x, y});

    if('avatar_views' in target)
      target.avatar_views.push(who);

    if('script_avatars' in target)
      target.script_avatars.push(who);
  }

  handleArrived(data:{x:number,y:number,avatar_view:PersonaView}) {

    this.map.anchor_views.forEach((anchor_view)=>{
      const close= anchor_view.model.events.find((x)=> x.event == 'near' && dist(data, anchor_view.model) < 100)
      if(!close)return;
      anchor_view[close.command].apply(anchor_view.model, close.args);
    });

    const x = data.x - (data.x % this.space.tile_width);
    const y = data.y - (data.y % this.space.tile_height);
    const tile_view = this.map.middleground.findTile(x,y);

    const tile = this.space.tiles.find(t => t.x==x && t.y==y);

    if (tile !== data.avatar_view.tile) {
      if (data.avatar_view.tile) {
        this.trigger(Events.TILE_EXIT, { tile: data.avatar_view.tile, avatar_view: data.avatar_view });
      }
      this.trigger(Events.TILE_ENTERED, { tile, tile_view, avatar_view: data.avatar_view });
      data.avatar_view.tile = tile;
    }
  }

  stopTileEvents(tile, avatar_view) {
    var ref;

    if ((ref = tile.sound) != null ? ref.instance : void 0) {
      tile.sound.instance.stop();
    }
    if (tile.video_view) {
      tile.video_view.remove();
    }
    if (tile.picture_view) {
      tile.picture_view.remove();
    }
    if (tile.text_view) {
      tile.text_view.remove();
    }
  }

  triggerTileEvents(tile, avatar_view, tile_view) {
    const events = tile.get("events") || {};

    if (!events.enter) {
      return;
    }

    switch (events.enter[0]) {
      case "show_text":
        this.trigger(Events.SHOW_TEXT, { id: events.enter[1], where: tile.$other, thing: tile });
        break;
      case "play_sound":
        this.trigger(Events.PLAY_SOUND, { id: events.enter[1], thing: tile });
        break;
      case "play_video":
        this.trigger(Events.PLAY_VIDEO, { id: events.enter[1], where: $(".tile_" + tile.get("x") + "_" + tile.get("y") + " > .other"), thing: tile });
        break;
      case "play_script":
        this.trigger(Events.PLAY_SCRIPT, { id: events.enter[1], where: tile_view, thing: tile });
        break;
      default:
        console.log("DO NOT KNOW HOW TO DO ", events.enter);
    }
  }

  removeChar(who) {
    //return this.map.removeAvatar(who, { });
  }

  get isEditing():boolean {
    return [MODES.Placing, MODES.Editing].indexOf(this.editing) >= 0;
  }

  toggleMode(mode) {
    switch (mode) {
      case MODES.Placing:
        for (let npc of this.map.npcs) {
          npc.unset_editable(false);
        }
        this.editing = MODES.Editing;
        break;
      case MODES.Normal:
        for (let npc of this.map.npcs) {
          npc.unset_placeable(false);
        }
        this.editing = MODES.Normal;
        break;
      default:
        this.editing = MODES.Editing;
    }

    $(this.rootElement).removeClass("editing placing").addClass(this.editing);
  }

  toggleFullscreen(fullscr) {
    if (fullscr) {
      $("#holder")[0].requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  toggleAnchors(mode) {
    if(mode)
      $(this.rootElement).addClass("anchors_visible");
    else
      $(this.rootElement).removeClass("anchors_visible");
  }

  toggleZones(mode) {
    if(mode)
      $(this.rootElement).addClass("zones_visible");
    else
      $(this.rootElement).removeClass("zones_visible");
  }


  teletransportCurrent(x, y) {
    //x = Math.max(Math.min(x, this.map.total_width), 0);
    //y = Math.max(Math.min(y, this.map.total_height), 0);
    x = x * this.map.zoomFactor;
    y = y * this.map.zoomFactor
    this.map.current_avatar.teletransport(x/this.map.zoomFactor, y/this.map.zoomFactor);
    this.map.move_to(x - this.map.$viewport.width() / 2, y - this.map.$viewport.height() / 2);
  }

  moveCurrent(x, y) {
    if(! this.map?.current_avatar)
      return;
    this.map.current_avatar.walk(x, y);
    this.map.move_to(x * this.map.zoomFactor - this.map.$viewport.width() / 2, y*this.map.zoomFactor - this.map.$viewport.height() / 2);
  }

  saveNeigh(space) {
    Cookies.set("space_id", JSON.stringify(space.get('uuid')));
  }

  saveCoords(x, y, char_view) {
    if (char_view.isLocal) {
      Cookies.set("local_char", JSON.stringify({ x: x, y: y }));
    }
  }

  addPersona(persona_view) {
    this.personas || (this.personas = []);
    this.personas.push(persona_view);
  }

  personaWalk(event) {
    const usr = this.personas.find((p) => p.user_uuid === event.uuid);

    if (!usr || usr.isLocal) {
      return;
    }
    usr.walk(event.x, event.y);
  }

  personaSay(event) {
    const usr = this.personas.find((p) => p.user_uuid === event.uuid);
    if (!usr || usr.isLocal) {
      return;
    }
    usr.say(event.message, 3000);
  }

};

_.extend(MapController.prototype, Backbone.Events);

export {MapController, request2};
