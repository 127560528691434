import React from 'react'

class ScrollBox extends React.PureComponent<{className: string, onScroll?:Function}> {

  scrollingBox: HTMLDivElement;
  scrollable: boolean;

  center_elem(elem){
    const $elem = $(elem);
    const pos = $elem.position().left - $elem.parent().position().left;
    this.scrollable=false;
    $elem.closest('.scrolling-box-container').find('.scrolling-box').animate({scrollLeft: pos - ($elem.parent().width() - $elem.width())/2},400,'swing', ()=>setTimeout(()=>this.scrollable=true, 500))
  }

  initialize_scroll_indicators(){
    const $parent = $("> .collection .scrolling-box")

    if($parent.find(".scrolling-wrap")[0].scrollWidth <= $parent.find('.scrolling-wrap').width())
      $("> .collection .left-scroller, > .collection .right-scroller").hide()
  }

  update_scroll_indicators() {
    const $parent = $(".scrolling-box")

    const scleft = $parent.scrollLeft()
    const scright = $parent.find(".scrolling-wrap")[0].scrollWidth - $parent.scrollLeft() - $parent.width()

    if(scleft < 20)
      $("> .collection .left-scroller").fadeOut()
    else
      $("> .collection .left-scroller").fadeIn()

    if(scright < 20)
      $("> .collection .right-scroller").fadeOut()
    else
      $("> .collection .right-scroller").fadeIn()
  }

  scrollDown(direction, e){
    const $parent = $(e.target).closest('.scrolling-box-container').find('.scrolling-box')

    if(direction == 'left'){
      const t = 0.5 * ($parent.find(".scrolling-wrap")[0].scrollWidth - $parent.find(".scrolling-wrap").parent().scrollLeft())
      $parent.animate({scrollLeft: 0}, t)
    }else{
      const t = 0.5 * ($parent.find(".scrolling-wrap")[0].scrollWidth * 2 - $parent.find('.scrolling-wrap').parent().scrollLeft())
      $parent.animate({scrollLeft: $parent.find(".scrolling-wrap")[0].scrollWidth },t)
    }
  }

  scrollUp(direction,e){
    const $parent = $(e.target).closest('.scrolling-box-container').find('.scrolling-box')
    $parent.stop()
  }

  render() {
    const {children} = this.props;

    return (
    <div className={`scrolling-box-container ${this.props.className}`}>
      <div className="indicator carousel-left"
      onMouseDown={(e) => this.scrollDown('left',e)} onMouseUp={(e) => this.scrollUp('left',e)}
      onTouchStart={(e) => this.scrollDown('left',e)} onTouchEnd={(e) => this.scrollUp('left',e)}></div>
    <div className="indicator carousel-right" 
      onMouseDown={(e) => this.scrollDown('right',e)} onMouseUp={(e) => this.scrollUp('right',e)}
      onTouchStart={(e) => this.scrollDown('right',e)} onTouchEnd={(e) => this.scrollUp('right',e)}></div>
      <div className="scrolling-box" onScroll={e => this.scrollable && this.props.onScroll && this.props.onScroll(this.scrollingBox.scrollLeft)} ref={r => this.scrollingBox = r}>
        <div className="scrolling-wrap">
          {children}
        </div>
      </div>
    </div>)

  }

}

export {ScrollBox}
