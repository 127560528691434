import { BaseModel } from "./base_model";
type uuid=string;

type SpriteAttributes = {
  id: uuid
  width: number,
  height: number,
  sprite_width: number,
  sprite_height: number,
  map: SpriteType
  public_filename: string,
  aseprite_url: string,
  aseprite?: AsepriteJson,
  image_dimensions: {
    width: number,
    height: number,
  }
}

interface SpriteAttributesI extends SpriteAttributes {
};

// to reconcile with 
import { SpriteProps } from "components/sprites/sprite";

type SpriteFrameType = {
  frame:{
    x: number,
    y:number,
    w:number,
    h:number
  },
  rotated:boolean, 
  trimmed:boolean,
  sourceSize:{
    w:number,
    h:number
  },
  transforms: {
    scaleX:number, 
    scaleY:number, 
    rotate:number,
    translateX:number,
    translateY:number
  },
  spriteSourceSize:{
    x: number,
    y:number,
    w:number,
    h:number
  },
}

type SpriteType = {
  frames: {
    [key:string]: SpriteFrameType
  },
  sequences: {
    [name:string]: {
      rate: number,
      frames: string[]
    }
  }
}

class Sprite extends BaseModel implements SpriteAttributesI {

  parent: any;

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Sprite');
    this.parent = attributes.parent;
  }

  _className() { return "Sprite"; }

  url = () => {
    return [(window as any).Niconitos.config.api, "sprites", this.id || ''].join('/');
  }

  initialize(atts,opts) {
    return super.initialize(atts,opts);
  }

  get aseprite_url():string {
    return this.get('aseprite_url')
  }

  get uuid():string {
    return this.get('uuid')
  }

  get name():string {
    return this.get('name')
  }

  //@ts-ignore
  get id():uuid{
    return this.get('id')
  }

  get width(): number{
    return this.get('width')
  }

  get height(): number{
    return this.get('height')
  }

  get sprite_width(): number{
    return this.get('sprite_width')
  }

  get sprite_height(): number{
    return this.get('sprite_height')
  }

  get map(): SpriteType{
    return this.get('map')
  }

  get public_filename(): string{
    return this.get('public_filename')
  }

  get image_dimensions(): { width: number, height: number } {
    return this.get('image_dimensions')
  }

};

export { Sprite, SpriteFrameType, SpriteType, SpriteAttributes };
