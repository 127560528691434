import * as _ from 'underscore';
import { EditorView } from './editor';

import { Sound } from 'models/sound';

const RecordRTC = require('recordrtc');

class EditSoundView extends EditorView<Sound> {

  className = 'EditSound'
  stream: MediaStream;
  recorder: any;
  file: any;
  copies= {
    success_title: 'Success!',
    success_subtitle: 'Your sound has been saved',
  }

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    return super.initialize(settings);
  }

  events() {
    return _.extend(super.events(), {
      'click [data-cmd=toggle-recording]': function(e) {
        if (this.recording) {
          this.recording = false;
          this.$('[data-cmd=toggle-recording]').html('Start Recording');
          return this.stopRecording();
        } else {
          this.recording = true;
          this.record();
          return this.$('[data-cmd=toggle-recording]').html('Stop Recording');
        }
      },
      'click [data-cmd=play-recording]': function(e) {
        var url;
        url = URL.createObjectURL(this.file);
        if (!this.audio) {
          this.audio = $("<audio controls></audio>").appendTo(this.$el)[0];
        }
        this.audio.src = url;
        return this.audio.play();
      }
    });
  }

  record() {
    return navigator.mediaDevices.getUserMedia({
      audio: true
    }).then((stream) => {
      this.stream = stream;
      this.recorder = RecordRTC(stream, {
        type: 'audio'
      });
      return this.recorder.startRecording();
    });
  }

  stopRecording() {
    var i, len, ref, track;
    this.recorder.stopRecording(() => {
      this.file = this.recorder.getBlob();
      return this.file.name = `audio.${this.file.type.split('/')[1]}`;
    });
    ref = this.stream.getTracks();
    for (i = 0, len = ref.length; i < len; i++) {
      track = ref[i];
      track.stop();
    }
    return this.stream = null;
  }

  previewImage(url) {
    return console.log('preview', url);
  }

  template() {
    this.document = [];
    this.render$(() => {
      this.form$(".form-horizontal", () => {
        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', 'Name');
          this.div$('.col-md-8', () => {
            this.input$('.form-control', { val: this.model.get('name'), name: 'name' });
          });
        });
        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', 'Description');
          this.div$('.col-md-8', () => {
            this.textarea$('.form-control', { name: 'description' }, () => {
              this.raw(this.model.get('description'));
            });
          });
        });
        this.ul$(".nav.nav-tabs", { role: "tablist" }, () => {
          this.li$(".active", { role: "presentation" }, () => {
            this.a$('.nav-link.active', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#upload" } }, "Upload");
          });
          this.li$(".inactive", { role: "presentation" }, () => {
            this.a$('.nav-link', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#record" } }, "Record");
          });
        });
        this.div$(".tab-content", () => {
          this.div$("#upload.tab-pane.fade.show.active", { role: "tabpanel" }, () => {
            this.render$(this.attachmentInput);
          });
          this.div$("#record.tab-pane.fade", { role: "tabpanel" }, () => {
            this.div$('.btn.btn-info', { data: { cmd: 'toggle-recording' } }, 'Start Recording');
            this.div$('.btn.btn-secondary', { data: { cmd: 'play-recording' } }, 'Play Recording');
          });
        });
        this.div$('.form-group', () => {
          this.div$('.col-8.col-md-8.col-md-offset-4', () => {
            this.button$('.btn.btn-primary', "Enviar");
          });
        });
      });
    });
    return this.document.join('');
  }

  createFormData() {
    var fd;
    fd = new FormData();
    // TODO if tab=Recording and then tab=Upload, we should set @file=null on tab switch
    this.file = this.file || (this.$('input[type=file]')[0] as any).files[0];
    fd.append('sound[sound]', this.file, 'sound.webm');
    fd.append('sound[name]', this.model.get('name'));
    fd.append('sound[description]', this.model.get('description'));
    return fd;
  }

  attachmentInput() {
    return this.div$('.form-group', () => {
      this.label$('.control-label.col-md-4', {
        for: 'sound'
      }, 'Sound');
      return this.div$('.col-md-8.dropzone', () => {
        return this.input$('.form-control', {
          type: 'file',
          name: 'sound'
        });
      });
    });
  }

  render() {
    return super.render();
  }

};

export { EditSoundView};
