import 'phaser'
import MainScene from 'game/scenes/mainScene'
import PreloadScene from 'game/scenes/preloadScene'
import {Avatars} from 'api/agent';
import {GlitchGame} from 'components/glitch/space';
import {SpaceAttributes} from 'models/space';

type scaleMode = 'FIT' | 'SMOOTH';

export const VIWOC_GAME_KEY = 'viwoc-game'
const DEFAULT_WIDTH: number = 1280
const DEFAULT_HEIGHT: number = 720
const MAX_WIDTH: number = DEFAULT_WIDTH * 1.5
const MAX_HEIGHT: number = DEFAULT_HEIGHT * 1.5
let SCALE_MODE: scaleMode = 'SMOOTH' // FIT OR SMOOTH

export const getGameData = async (location: string) => {
  if(location.length < 36){
    const gd= await fetch(`/locations/${location.replace(/^L/, 'G')}.json`).then((response) => response.json())
    return [gd,null]
  }else{
    const space= await fetch(`/spaces/${location}.json`).then((response) => response.json())
    return [space.location.metadata, space]
  }
}

export interface ViwocScene extends Phaser.Scene {
  loadingCallbacks: LoadingCallbacks,
  gameData: GlitchGame,
  space?: SpaceAttributes,
}

export type LoadingCallbacks = {
  setLoading: (boolean) => void,
  setProgress: (number) => void,
}

const loadGame = async (parentElement, {id, path_template, avatar_id, loadingCallbacks}:any & {loadingCallbacks:LoadingCallbacks}) => {

  let [gameData, space] = await getGameData(id)
  let avatar = await Avatars.show(avatar_id || 'f6a92135-9e34-406c-820e-66b6b3939496')

  const config = {
    type: Phaser.WEBGL,
    backgroundColor: '#ffffff',
    parent: parentElement,
    dom: {
      createContainer: true
    },
    loader: {
      crossOrigin: 'anonymous',
    },
    scale: {
      // The game will be scaled manually in the resize()
      mode: Phaser.Scale.NONE,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT
    },
    plugins: {
      //scene: [{ key: 'SpineWebGLPlugin', plugin: SpineWebGLPlugin, start: true, sceneKey: 'spine' }]
    },
    scene: null,
    physics: {
      default: 'matter',
      matter: {
        debug: {
          showBody: true,             // Render the bodies
          showStaticBody: true,       // Render static bodies
          showVelocity: true,         // Render velocity vectors
          showCollisions: true,       // Render collision points and normals
          showSeparation: true,       // Render body separation
          // Additional debug options as needed
        },
        gravity: { y: 1, x: 0 },// Adjust gravity to your game's needs
        setBounds: {
          left: true,
          right: true,
          top: true,
          bottom: true,
          thickness: 64 // The thickness of the boundary walls
        },

      }
    }
  }

  const game = new Phaser.Game(config)

  game.scene.add('PreloadScene', PreloadScene, true, { gameData, space, path_template, avatar, loadingCallbacks})
  game.scene.add('MainScene', MainScene, false)

  // the custom resize function
  const resize = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    let width = DEFAULT_WIDTH
    let height = DEFAULT_HEIGHT
    let maxWidth = MAX_WIDTH
    let maxHeight = MAX_HEIGHT
    let scaleMode = SCALE_MODE

    let scale = Math.min(w / width, h / height)
    let newWidth = Math.min(w / scale, maxWidth)
    let newHeight = Math.min(h / scale, maxHeight)

    let defaultRatio = DEFAULT_WIDTH / DEFAULT_HEIGHT
    let maxRatioWidth = MAX_WIDTH / DEFAULT_HEIGHT
    let maxRatioHeight = DEFAULT_WIDTH / MAX_HEIGHT

    // smooth scaling
    let smooth = 1
    if (scaleMode === 'SMOOTH') {
      const maxSmoothScale = 1.15
      const normalize = (value: number, min: number, max: number) => (value - min) / (max - min);
      if (width / height < w / h) {
        smooth = -normalize(newWidth / newHeight, defaultRatio, maxRatioWidth) / (1 / (maxSmoothScale - 1)) + maxSmoothScale
      } else {
        smooth = -normalize(newWidth / newHeight, defaultRatio, maxRatioHeight) / (1 / (maxSmoothScale - 1)) + maxSmoothScale
      }
    }

    // resize the game
    game.scale.resize(newWidth * smooth, newHeight * smooth)

    // scale the width and height of the css
    game.canvas.style.width = newWidth * scale + 'px'
    game.canvas.style.height = newHeight * scale + 'px'

    // center the game with css margin
    game.canvas.style.marginTop = `${(h - newHeight * scale) / 2}px`
    game.canvas.style.marginLeft = `${(w - newWidth * scale) / 2}px`
  }
  window.addEventListener('resize', event => {
    resize()
  })
  resize();
  (window as any).game = game;
  return game
}

// window.addEventListener('load', ()=> {
//   let location = 'GIF102FDNU11314'
//   const hash = window.location.hash.toString();
// 
//   if(hash.length > 3){
//     location = hash.slice(2)
//   }
// 
//   const p= document.getElementById('phaser-game')
//   if(p)
//     loadGame('phaser-game', location, '/pruebas/game/#/${id}')
// })

export {loadGame}
