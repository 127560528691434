import React from 'react';
import { CHUNK_DIVISIONS, DEFAULT_SECONDS_RULER, SECONDS_CHUNKS, SECONDS_LENGTH, RULER_EXTRA_PIXEL } from "./seconds-sizes";

export default class TimelineRuler extends React.Component<{seconds_total: number}, {secondChunks: string[]}> {

  constructor(props) {
    super(props);
    let secondsLength = props.seconds_total || DEFAULT_SECONDS_RULER;
    this.state = {secondChunks: this.getSecondChunks(secondsLength)};
  }

  getSecondChunks(seconds_total): string[] {
    var list = [];
    var second = SECONDS_CHUNKS;
    do
    {
      let currentMinute = Math.floor(second/60);
      let currentSecond = (second - (currentMinute*60));
      list.push(`${padNumber(currentMinute,2)}:${padNumber(currentSecond,2)}`);
      second = second + SECONDS_CHUNKS;
    }
    while(second <= (seconds_total + SECONDS_CHUNKS))
    return list;
  }

  get totalWidth():number {
    return RULER_EXTRA_PIXEL + (this.props.seconds_total * SECONDS_LENGTH)
  }

  render() {
    return (
      <div className="timeline-editor__frames-ruler"
        style={{width: this.totalWidth}}>

        {this.state.secondChunks.map((chunk)=>
        <div className="timeline-editor__frames-ruler-chunk" key={chunk} style={{width: SECONDS_CHUNKS*SECONDS_LENGTH}}>
          <span>{chunk}</span>
          {Array.from(Array(CHUNK_DIVISIONS)).map((x, i)=> ( <div key={i} className="timeline-editor__frames-ruler__second"></div>))}
        </div>
    )}
  </div>
    );
  }
}

const padNumber = (number, size) => {
  var s = String(number);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}
