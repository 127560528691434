import { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from "react";
import axios from 'axios';
import { NpcProps, Npc } from "./npc";
import { PaginationAttrs } from "shared/components/pagination";
import Pagination from '@material-ui/lab/Pagination';
  import NpcPicker from "./picker";
type StateProps = {
  npcs: Array<NpcProps>,
  pagination?:PaginationAttrs,
  query:string,
  loading:boolean,
  creating:boolean,
  message:string
}

class NpcIndex extends Component<{history:any, xs:number,md:number, onClick?:Function, match:any},StateProps> {

  static defaultProps = {xs: 12, md: 3, onClick: null}
  cancel:any;

  constructor(props) {
    super(props);

    this.state = {
      npcs: [],
      query: '',
      loading: true,
      message: '',
      creating: false
    };
  }

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, npcs: [], loading: false, message: '' } );
    } else {
      this.setState({ query, npcs: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/npcs/search.json?models=Npc&query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? 'There are no more search results. Please try a new search.'
          : '';
        this.setState({
          npcs: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };


  componentDidMount() {
    let url;
    if(this.props.match.params.p)
      url = `/npcs.json?page=${this.props.match.params.p}`
    else
      url = `/npcs.json`

    axios.request<Array<NpcProps>>({url}).then(({data, headers}) => {
      let pagination;
      if(headers['x-pagination']){
        pagination = JSON.parse(headers['x-pagination'])
      }

      this.setState({ npcs: data, pagination })
    });
  }

  render () {
    return (
      this.state.creating ? 
      <>
        <Row>
          <div className="col-12 my-4">
            <div className="btn btn-info" onClick={()=>this.setState({...this.state,creating: false})}>list</div>
            <NpcPicker accept={(npc) => {console.log('npc:', npc)}} reject={(reason) => {console.log(reason)}}/>
          </div>
        </Row>

      </>
      : 
      <>
        <Row>
          <div className="col-12 col-md-6 offset-md-3 my-4">
            <input
              className="form-control form-control-lg"
              type="text"
              value={this.state.query}
              id="search-input"
              placeholder="Search..."
              onChange={this.handleOnInputChange}
            />
            <div className="btn btn-info" onClick={()=>this.setState({...this.state,creating: true})}>Create new</div>
          </div>
        </Row>

        <Row>

          {this.state.npcs.filter(npc => npc.sprites.length && npc.sprites[0]).map(npc => (
            <Col xs={this.props.xs} md={this.props.md} key={npc.id}>
              <Npc {...npc} onClick={(e)=> this.props.onClick(npc)}></Npc>
            </Col>
        ))}

    {this.state.pagination &&
    <Pagination count={10} color="primary"  page={this.state.pagination.current_page} onChange={(e,num)=>this.props.history.push(`/npcs/${num}`)} />
  }
</Row>
      </>
    )
  }

}

export {NpcIndex}

