import {Avatars} from "api/agent"
import {AvatarAttributes} from "models/avatar"
import React, {useEffect} from "react"
import {FC} from "react"
import {AvatarCard} from "./avatar_card"

const AvatarShow: FC<{match: {params: {uuid: string}}}> = (props) => {

  const [avatar, setAvatar] = React.useState<AvatarAttributes | null>(null)

  useEffect(() => {
    Avatars.show(props.match.params.uuid).then(setAvatar)
  }, [props.match.params.uuid])

  return avatar ? <AvatarCard {...avatar} onClick={()=>void 0}/> : <div>Loading...</div>
}


export default AvatarShow

