import * as _ from 'underscore';

import BaseView from 'views/base_view';
import { Video } from 'models/video';

const css_editable = require('modules/css_editable')

class VideoView extends BaseView<Video> {

  toggle_editable: any;
  x: string;
  y: string;

  constructor(options) {
    super(...Array.from(arguments));
    this.dispatchCreated('VideoView');
  }

  initialize(settings) {}

  events() {
    return {
      dblclick: "handle_dblclick",
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "video_view";

  template = _.template(`<div class="video">
  <div class="mymodal-header">
    <button type="button" class="close">&times;</button>
    <h4 class="modal-title">Video <%= model.get("name") %></h4>
  </div>
  <div class="mymodal-body">
    <% if(model.get("embed_full")){ %>
      <%= model.get("embed_full") %>
    <% }else{ %>
      <video id="<%= cid %>" class="video-js vjs-default-skin"  controls=controls  autoplay=autoplay preload="none" width="100%" height="100%" data-setup="{}">
        <source src="<%= model.get("video_url") %>" type="<%= model.get("video_content_type") %>"></source>
      </video>
    <% } %>
  </div>
</div>`);

  handle_dblclick(ev) {
    this.toggle_editable(true);
    ev.preventDefault();
    return false;
  }

  render() {
    const css = _.extend({
      position: "absolute",
      left: this.x + "px",
      top: this.y + "px",
      width: "300px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }, this.model.get("css"));
    this.$el.css(css);
    if (this.model.get("weblink")) {
      this.$el.html(this.model.get("embed"));
    } else {
      this.$el.html(this.template(this));
    }
    return this;
  }
}

_.extend(VideoView.prototype, css_editable);

export { VideoView};
