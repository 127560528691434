import React from 'react'
import './walking_preview.scss'
import { connect } from 'react-redux';
import { CircularProgress, Slider } from '@material-ui/core';
import { CREATOR_VELOCITY_RATIO, CREATOR_FINISHED } from 'constants/actionTypes';
import NamePrompt from './name_prompt';
import { store } from 'redux/store'
import { Avatars, Npcs } from 'api/agent'
import { ChromaFrame } from '../masking/chroma';

type WalkingPreviewProps ={
  match:any,
  removing: boolean,
  frames: Array<ChromaFrame>,
  onFinish: Function,
  inverted: string,
  velocity_ratio: number,
  handleVelocityRatioChange: Function,
}

const PIXELS_PER_FRAME = 5;
const FRAMES_PER_SECOND=25;
const PIXELS_PER_SECOND = PIXELS_PER_FRAME * FRAMES_PER_SECOND;

class WalkingPreviewComponent extends React.PureComponent<WalkingPreviewProps & {history:any}> {

  animationInterval: any;

  constructor(props){
    super(props)
  }

  static defaultProps = {
    frames: [],
  }

  state = {
    current: 0,
    removing: false,
    playing: true,
    direction: 'left',
    namePrompt: false
  }

  selectFrame(frame,idx){
    this.setState({current: idx});
  }

  get currentFrame(){
    return this.props.frames[Math.floor(this.state.current)];
  }

  UNSAFE_componentWillMount(){

    if(this.props.match?.params?.idx){
      setTimeout(()=>
        this.setState({...this.state, current: parseFloat(this.props.match.params.idx), removing: true})
      ,0);
    }

  }

  componentDidUpdate(prevProps) {
    const { playing, current} = this.state;

    if (playing !== prevProps.playing) {
      playing ? this.startPlaying() : this.stopPlaying()
    }
    if (!playing && current !== prevProps.current) {
      this.setState({ current: current })
    }
  }

  get velocity_ratio(){
    return this.props.velocity_ratio || 100;
  }

  startPlaying() {
    this.stopPlaying()
    this.animationInterval = setInterval(() => {
      const { current,direction} = this.state;
      const newFrame = current+(this.velocity_ratio/100);
      const endFrame = this.props.frames.length;
      this.setState({
        current: newFrame >= endFrame ? 0 : newFrame,
      })
    }, 1000 / FRAMES_PER_SECOND)
  }

  stopPlaying() {
    clearInterval(this.animationInterval)
  }

  back(){
    this.props.history.push('/creator/chroma')
  }

  nextStep(){
    this.setState({...this.state, namePrompt:true});
  }

  handleFinish(name, tipo){
    this.setState({...this.state, namePrompt:false});
    this.props.onFinish(name, tipo );
  }

  getStyle(){
    const track_width=$('.alpha-box').width() - $('.alpha-box img').width();
    const position=$('.alpha-box img')[0].offsetLeft || 0;
    let newpos = position + (this.state.direction == 'right' ? +1 : -1)*PIXELS_PER_FRAME;

    if(newpos >= track_width){
      newpos = track_width + (-1 * newpos % track_width);
      setTimeout(()=>this.setState({...this.state, direction: 'left'}),0);
    }else if(newpos <= 0){
      newpos = newpos + PIXELS_PER_FRAME;
      //this.setState({...this.state, direction: 'right'})
      setTimeout(()=>this.setState({...this.state, direction: 'right'}),0);
    }
    const invert=this.state.direction == this.props.inverted;
    const scale = invert ? 'scaleX(-1)' : 'scaleX(1)'
    const translate = `translate(${(this.currentFrame.left || 0)*100 * (invert ? -1 : 1)}%,${(this.currentFrame.top || 0)*100}%)`;

    return {
      left: newpos,
      transform: [scale,translate].join(' ')
    }
  }

  render(){
    return (<div className="walking-preview row mt-4">

      <Slider
        min={25}
        max={250}
        value={this.props.velocity_ratio || 100}
        onChange={(e, val) => this.props.handleVelocityRatioChange(val)}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />

      <div className="alpha-box col-12">
        {(this.currentFrame &&
        <img src={this.currentFrame.mask_url || this.currentFrame.url}
          className="current-frame"
          style={this.getStyle()} >
        </img>)}
      </div>

      <div className="col-12">
        <div className="btn btn-link float-left" onClick={this.back.bind(this)}>
          <i className="fa fa-arrow-left"></i>
          Cambiar video
        </div>
        <div className="btn btn-link float-right" onClick={this.nextStep.bind(this)}>
          Continuar
          <i className="fa fa-arrow-right"></i>
        </div>
      </div>

      <NamePrompt open={this.state.namePrompt} onSave={(name, tipo) => this.handleFinish(name, tipo)} />

    </div>)
  }
}

const mapStateToProps = ({creator}) => ({
  frames: creator.frames,
  inverted: creator.inverted,
  velocity_ratio: creator.velocity_ratio,
})

const mapDispatchToProps = (dispatch) => ({
  handleVelocityRatioChange: (velocity_ratio) => dispatch({type: CREATOR_VELOCITY_RATIO, velocity_ratio:velocity_ratio}),
  onFinish: (name, tipo) => dispatch({type: CREATOR_FINISHED, name, tipo}),
})

const WalkingPreview = connect(mapStateToProps, mapDispatchToProps)(WalkingPreviewComponent)

export {WalkingPreview}

let currentName;

const handleChange = async () => {

  let creatorState = store.getState().creator as any;
  let previousName = currentName;
  currentName = creatorState.name;

  if (previousName === currentName || !currentName || !currentName.length)
    return;

  if(creatorState.type == 'Npc')
    Npcs.create(currentName, creatorState.frames.filter(f => !!f.mask_url), creatorState.inverted)
  else
    Avatars.create(currentName, creatorState.frames.filter(f => !!f.mask_url), creatorState.inverted)
}

const unsubscribe = store.subscribe(handleChange);
