import React, { useState, useEffect } from 'react';
import {addCallback} from 'utils/channels';
import { sdxl_url, transcribe_url} from 'utils/urls';

function VoiceRecorder() {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [transcription, setTranscription] = useState('');

  useEffect(() => {
    // Check for the mediaRecorder instance and clean up if necessary
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [mediaRecorder]); // This ensures useEffect is only re-evaluated when mediaRecorder changes

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks = [];

      recorder.onstart = () => {
        setRecording(true);
        chunks = []; // Reset chunks on new recording
      };

      recorder.ondataavailable = e => chunks.push(e.data);

      recorder.onstop = async () => {
        const blob = new Blob(chunks, { 'type' : recorder.mimeType });
        const audioURL = URL.createObjectURL(blob);
        setAudioURL(audioURL);
        setRecording(false);
        // upload to server as form data
        const formData = new FormData();
        formData.append('audio', blob);

        const transcription = await fetch(transcribe_url(), {
          method: 'POST',
          body: formData
        }).then(response => response.json());

        setTranscription(transcription.transcription);

        const {uuid} = await fetch(sdxl_url(), {
          method: 'POST',
          body: JSON.stringify({prompt: transcription.transcription}),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => response.json());

        addCallback(uuid, (data) => {
          setImageURL(data.output[0]);
        })

      };

      recorder.start();
      setMediaRecorder(recorder);
    } catch (err) {
      console.error('Error starting recording:', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12 mt-4">
          <h1>Voice Recorder</h1>
        </div>

        <div className="col-12 col-md-6">
          <button onClick={startRecording} disabled={recording} className="btn btn-primary">Start Recording</button>
          <button onClick={stopRecording} disabled={!recording} className="btn btn-info">Stop Recording</button>

    {audioURL && <audio src={audioURL} controls />}
    {transcription && <p>{transcription}</p>}
    {imageURL && <img src={imageURL} alt="Generated image" />}
  </div>
</div>
    </div>);
}

export default VoiceRecorder;

