import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, LinearProgress, makeStyles, TextField, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {GlitchGame, PromptGenerator} from 'components/glitch/space';
import {createGlitchGame} from 'components/glitch/utils';
import {CreateGameAttributes } from 'models/game';
import {requests} from 'api/agent';
import { withRouter } from 'react-router-dom';
import {addCallback} from 'utils/channels';
import {edit_space_url, improve_prompt_url, sdxl_url, space_url, sprite_url, suggest_prompts_url} from 'utils/urls';
import {ARTIST, PLACES, STYLE_MODIFIERS} from 'utils/prompt_modifiers';
import {CreateSpaceAttributes} from 'models/space';
import {prefixPrompt, SuggestPromptsResponse} from 'components/panels/generative_ai_panel';
import {PromptSuggestion} from 'redux/reducers/editor';
import {useDispatch, useSelector} from 'react-redux';
import {setBackdrop, setBackground, setPlace} from 'redux/reducers/creatix';
import {T} from 'components/utils/t';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  suggestionButton: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    marginTop: theme.spacing(1),
  }
}));

export const generateAsset:(a:string,b:PromptSuggestion,c:string)=>Promise<any & {url:string, uuid:string}> = async (space_id, value, csrf_token) => {

  let asset_url = sprite_url({space_id: space_id});

  const r = await fetch(asset_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: JSON.stringify({
      prompt: prefixPrompt(value.prompt), 
      params: {...value, sprite: true}
    })
  });
  return await r.json();
}


const createSpace = async (game_uuid, name, {width,height}) => {

  const space:CreateSpaceAttributes = {
    name,
    width, 
    height, 
  }

  try {

    let next_uuid=null;

    if(game_uuid?.length) {
      const createdSpace = await requests.post(`/games/${game_uuid}/spaces.json`, { space });
      if(createdSpace.errors) {
        console.log('Error updating game', createdSpace.data.errors);
      } else {
        console.log('Game updated', createdSpace);
        next_uuid = createdSpace.uuid;
      }
      return [game_uuid, next_uuid];

    } else {

      const game:CreateGameAttributes = {
        name: name,
        spaces_attributes: [space ]
      }
      const createdGame = await requests.post('/games.json', { game });

      if(createdGame.errors) {
        console.log('Error creating game', createdGame.data.errors);
      } else {
        next_uuid = createdGame.start_space
      }
      return [createdGame.uuid, next_uuid];
    }

  } catch (error) {
    console.log('Error creating game', error);
  }

}

const generateAssets = async (prompt, csrf_token) => {
  const url = suggest_prompts_url();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: JSON.stringify({ prompt  })
  });

  const {game_assets} = await resp.json();
  return game_assets;
}
const improvePrompt = async (place, csrf_token) => {
  const url = improve_prompt_url()
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: JSON.stringify({
      variables: {
        prompt: place,
      }
    })
  });

  const {prompt} = await resp.json();
  return prompt;
}

const generateBackground = async (scene, {width,height}, csrf_token) => {
  const url = sdxl_url()

  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: JSON.stringify({
      prompt: scene,
      negativePrompt: 'isometric', 
      params: {
        width,
        height,
      }
    })
  });

  const data = await resp.json();
  return data.uuid;
}

const PlaceEditor = ({available_places, place, setPlace}) => {
  return (
    <Box>
      {Object.entries(available_places).map(([tag,desc]) => (
        <Button key={tag} variant={desc == place ? 'contained' : 'outlined'} onClick={() => setPlace(desc)} color="primary" >
         {tag}
        </Button>
      ))}
    </Box>
  );
};

const TagEditor = ({available_tags, inputText, tags, setTags}) => {
  const [writeYours, setWriteYours] = useState(null);

  const handleTagChange = (tag) => {
    const newTags = tags.includes(tag) ? tags.filter(t => t !== tag) : [...tags, tag];
    setTags(newTags);

    let newText = inputText;
    const tagString = `${tag} ,`; // Add a space for separation
    if (newTags.includes(tag)) {
      newText += tagString;
    } else {
      newText = newText.replace(tagString, '');
    }
  };

  //const doWriteYours = (str:string): void => {
  //  setWriteYours(str);
  //  if(str == null){
  //    newInput = inputText.replace(new RegExp(writeYours), '');
  //  } else {
  //    newInput = inputText.replace(new RegExp(writeYours + '$'), '') + str;
  //  }
  //  //setInputText(newInput);
  //}

  return (
    <Box>
      {available_tags.map((tag) => (
        <Button key={tag} variant={tags.includes(tag) ? 'contained' : 'outlined'} onClick={() => handleTagChange(tag)} color="primary" >
         + {tag}
        </Button>
      ))}
  {/* COMMENTED OUT
      <Button key={'write-yours'} variant={writeYours != null ? 'contained' : 'outlined'} onClick={() => doWriteYours((writeYours!=null) ? null : '')} color="secondary" >
       + {'Write Your Own'}
      </Button>
      {writeYours!=null && <TextField label="Write your own" value={writeYours} onChange={(e) => doWriteYours(e.target.value)} />}
    COMMENTED OUT */}
    </Box>
  );
};

const composeScene = (place, {style:style_tags, artist:artist_tags}) => {
  let scene = place;
  if(artist_tags.length > 0){
    scene = `${scene}. In the style of ${artist_tags.join(',')}`;
  }
  if(style_tags.length > 0){
    scene = `${scene}. ${style_tags.join(',')}`;
  }
  return scene;
};

function Scenario({handleNext, idx, history}) {
  const game_uuid = useSelector((state:any)=>state.creatix.gameId);

  const size = useSelector((state:any)=>state.creatix.size);
  const title = useSelector((state:any)=>state.creatix.title);
  const background = useSelector((state:any)=>state.creatix.background);

  const [loading, setLoading] = useState(0);
  const [assets, setAssets] = useState<SuggestPromptsResponse>({});
  const [error, setError] = useState(null);

  const place = useSelector((state:any)=>state.creatix.place);

  const dispatch = useDispatch();

  const [style_tags, setStyleTags] = useState([]);
  const [artist_tags, setArtistTags] = useState([]);

  const classes = useStyles();

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const [width, height] = size || [2000, 1000];

  const handleSubmit = async (gameId, spaceId, place,{style:style_tags,artist:artist_tags}, props) => {

    const scene = composeScene(place, {style:style_tags, artist:artist_tags});

    // IF background came from an uploaded picture, then we're ready to go !
    if(background){
      handleResult(gameId, spaceId, background,place,{style:style_tags,artist:artist_tags},props)
      return;
    }

    setLoading(1)

    let interval = setInterval(() => {
      setLoading((loading) => {
        if(loading > 120){
          clearInterval(interval);
          setError('Something went wrong, please try again');
          return loading;
        }
        return loading + 1
      });
    }, 1000);

    const uuid = await generateBackground(scene,{width,height},csrf_token);

    addCallback(uuid, (data) => {
      console.log('Got response', data);
      const backg = data.output[0]
      clearInterval(interval);
      handleResult(gameId, spaceId, backg, place, {style:style_tags, artist:artist_tags}, props);
    }, (error) => {
      console.log('Got error', error);
      setError(error);
      clearInterval(interval);
    })

  }

  const improvePromptAndSubmit = async (place) => {

    setLoading(1);

    try {
      const p= await improvePrompt(place,csrf_token);
      const game_assets:SuggestPromptsResponse= await generateAssets(p, csrf_token)
      setAssets(game_assets);

      setTimeout(async ()=>{

        try{
          const [gameId, spaceId] = await createSpace(game_uuid,title || place, {width,height});

          handleSubmit(gameId, spaceId, p, {style:style_tags, artist:artist_tags}, game_assets);

          const promises = Object.entries(game_assets).map(([key, value]) => {
            return generateAsset(spaceId, {title: key, ...value}, csrf_token).then((r) => {
              setAssets({...assets, [key]: r})
              return r;
            }, (err) => {
              setError(err);
            })
          });
          Promise.all(promises).then((data) => { console.log('Got all assets', data); })
        } catch(e){
          console.error('Error creating space', e);
          setError(e);
        }

      }, 500);

    } catch(e){
      console.error('Error fetching prompts', e);
      setError(e);
    }
  }

  const handleResult = async (gameId, spaceId, url, place, tags, props) => {

    dispatch(setBackground(url))
    setLoading(0)
    dispatch(setBackdrop(false));

    const generator:PromptGenerator = {
      prompt: composeScene(place, tags),
      components: {
        place,
        tags,
        style_tags: tags.style || [],
        artist_tags: tags.artist || []
      },
      props,
    };

    const gameData:GlitchGame = createGlitchGame({
      label: title || place,
      bgUrl: url,
      width, 
      height, 
      generator,
    });

    const space = {
      glitch_location_attributes: { metadata: gameData }
    }
    const _updatedSpace = await requests.put(space_url(gameId, spaceId), { space });

    const next_url = edit_space_url(spaceId)
    history.push(next_url);

  };

  return <Dialog
    BackdropProps={{ style: { display: 'none' } }}
    disableEscapeKeyDown
    className="game_welcome step3"
    open={true}
    onClose={()=>void 0}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="lg" // 'xs', 'sm', 'md', 'lg', 'xl'
    fullWidth={true}
    style={{ height: '80%' }}
  >
    <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
      <T k="creatix.scenario.title">To explore the game, click or touch the screen, right in the point you want to go.</T>
    </DialogTitle>

    <DialogContent>
      {!!loading && <LinearProgress value={100*(loading/60)} variant="determinate" />}

  {false && Object.keys(assets).length ? <Box>
    {Object.entries(assets).map(([key, value]) => {
      return value.url ? <img key={key} src={value.url} alt={key} /> : <span key={key}>{value.description}</span>
    })}
  </Box> : null}

      {error && <Alert severity="error">{error}</Alert>}

      {!loading && <Box>
        <TextField
          label="Description of your game"
          variant="outlined"
          value={place}
          onChange={(e) => dispatch(setPlace(e.target.value))}
          fullWidth
          multiline
        />
        {/* a very small link button to improve prompt 
        <Button onClick={() => handleImprove(place)} color="default" size="small" variant="outlined">Improve prompt</Button>
          */}

          {/*<Box mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
	     <Accordion>
	       <AccordionSummary expandIcon={<i className="fa fa-caret-down"></i>} aria-controls="panel1a-content" id="panel1a-header" >
	         <Typography>Negative Prompt</Typography>
	       </AccordionSummary>
	       <AccordionDetails>
	         <TextField
	           fullWidth
	           label="Negative prompt"
	           variant="outlined"
	           margin="normal"
	         />
	       </AccordionDetails>
	     </Accordion>
      </Box>*/}

      <Box mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
        <PlaceEditor available_places={PLACES} place={place} setPlace={(p)=>dispatch(setPlace(p))}/>
      </Box>

      <Box mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Accordion>
          <AccordionSummary expandIcon={<i className="fa fa-caret-down"></i>} aria-controls="panel1a-content" id="panel1a-header" >
            <Typography><T k="creatix.scenario.style_modifiers">Style modifiers</T></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TagEditor inputText={place} available_tags={Object.values(STYLE_MODIFIERS).flat()} tags={style_tags} setTags={setStyleTags}/>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Accordion>
          <AccordionSummary expandIcon={<i className="fa fa-caret-down"></i>} aria-controls="panel1a-content" id="panel1a-header" >
            <Typography><T k="creatix.scenario.style_modifiers2">Style modifiers</T></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TagEditor inputText={place} available_tags={Object.values(ARTIST).flat()} tags={artist_tags} setTags={setArtistTags}/>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
        {composeScene(place, {style:style_tags, artist:artist_tags})}
      </Box>

      </Box>}
    </DialogContent>

    {!loading && <DialogActions>
      <Button onClick={()=>{handleNext(idx - 1)}} color="primary">
        <T k="creatix.buttons.previous">Previous</T>
      </Button>
      <Button onClick={(e)=>{improvePromptAndSubmit(place)}} color="primary">
        <T k="creatix.buttons.next">Next</T>
      </Button>
    </DialogActions>}
  </Dialog>
}

export default withRouter(Scenario);
