import { Redirect } from "react-router-dom";
import React from 'react';
import {Spaces} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import {DirectUploadProvider} from 'react-activestorage-provider';
import { Clazzes } from 'api/agent'

import * as ActiveStorage from 'activestorage';
import { ClazzAttributes } from 'models/clazz';
  import ClazzForm from "./form";
type ClazzNewState = {
  name:string,
  description:string,
  errors:string[],
  created_clazz?: ClazzAttributes,
  creation_status?: string,
};

class ClazzNew extends React.Component<{errors:any[]},ClazzNewState> {

  static defaultProps = {
  }

  state:ClazzNewState = {
    name:"",
    description:"",
    errors:null,
    creation_status: null,
  }

  render(){
    if (this.state.created_clazz) {
      return <Redirect to={`/clazzs/${this.state.created_clazz.id}`} />
    }

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">New Clazz</h1>
          <ClazzForm submitForm={this.submitForm.bind(this)} errors={this.state.errors} creation_status={this.state.creation_status}></ClazzForm>
        </div>
      </div>
    );
  }

  async submitForm({name, description, npc}): Promise<any> {

    const clazz = { name, description, npc_id: npc.id};

    this.setState({...this.state, creation_status: 'creating'})

    Clazzes.create(clazz).then((e) => {
      this.setState({...this.state, created_clazz:e, creation_status: null});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}


export default ClazzNew;
