import React from 'react'
import { fabric} from "fabric";
import { MyFabricCanvas, loadFabricImg, loadImg } from './fabric_remover';
import { ChromaFrame } from './chroma';

const STEP = 1;

enum Direction {
  LEFT= 0,
  UP= 1,
  RIGHT= 2,
  DOWN= 3
};

type PlayerProps = {
  frames: Array<ChromaFrame>,
  reverse:boolean,
  playing:boolean,
  id: string,
  onObjectMoved:Function
}

class PlayerComponent extends React.PureComponent<PlayerProps, {}> {

  the_canvas: HTMLCanvasElement;
  input_canvas: MyFabricCanvas;
  listener: Function;
  canvasWidth: number;
  canvasHeight: number;

  async loadImages(frames):Promise<Array<HTMLImageElement>> {

    this.input_canvas.forEachObject(o => this.input_canvas.remove(o))

    return Promise.all(frames.map((fr):Promise<any> => loadFabricImg(fr.mask_url || fr.url, {visible: false}).then((img)=>{
      (img as any).frame_id = fr.id;
      const ratio = img.width / img.height;

      img.scaleX= this.canvasWidth / img.width;
      img.scaleY= (this.canvasWidth / ratio) / img.height;

      img.left= (fr.left || 0) * img.width;
      img.top= (fr.top || 0) * img.width;
      (img as any).id = fr.id;
      (img as any).src = fr.mask_url || fr.url;

      this.input_canvas.add(img);
      return img
    })))
  }

  componentWillUnmount(){
    fabric.util.removeListener(document.body,'keydown',this.listener);
  }

  async componentDidMount(){
    this.canvasWidth = $(this.the_canvas.parentElement).width();
    const img = await loadImg(this.props.frames[0].url);
    const r = (img.naturalWidth/img.naturalHeight);
    this.canvasHeight = this.canvasWidth/r;

    this.input_canvas = new fabric.Canvas(this.the_canvas, ({
      width: this.canvasWidth,
      height: this.canvasHeight
    } as any)) as MyFabricCanvas;

    (window as any).input_canvas=this.input_canvas;

    this.input_canvas.on('object:moved', (o) => {
        const target:any=o.target;
        const {top,left}=target;
        this.props.onObjectMoved(this.props.id, {top: top/target.width,left: left/target.width})
      }
    );

    this.listener = (options) => {
      if (options.repeat) {
        return;
      }
      const key = options.which || options.keyCode; // key detection
      if (key === 37) { // handle Left key
        this.moveSelected(Direction.LEFT);
      } else if (key === 38) { // handle Up key
        this.moveSelected(Direction.UP);
      } else if (key === 39) { // handle Right key
        this.moveSelected(Direction.RIGHT);
      } else if (key === 40) { // handle Down key
        this.moveSelected(Direction.DOWN);
      }
    }

    fabric.util.addListener(document.body, 'keydown', this.listener);
    
    this.loadImages(this.props.frames)
  }

  moveSelected(direction, amount=STEP) {

    const activeObject = this.input_canvas.getActiveObject() || this.input_canvas.getActiveGroup();

    if (!activeObject)
      return

    switch (direction) {
      case Direction.LEFT:
      activeObject.left=activeObject.left - amount;
        break;
      case Direction.UP:
      activeObject.top=activeObject.top - amount;
        break;
      case Direction.RIGHT:
      activeObject.left=activeObject.left + amount;
        break;
      case Direction.DOWN:
      activeObject.top=activeObject.top + amount;
        break;
    }
    activeObject.setCoords();
    this.input_canvas.renderAll();

  }

  async componentDidUpdate(prevProps, prevState){
    if (! prevProps.frames
       || prevProps.frames.find(({id, mask_url}) => !this.input_canvas.getObjects('image').find((img:any)=>img.src == mask_url))
       || prevProps.frames.length != this.input_canvas.getObjects('image').length){

      console.log('Player reloading frames');
      await this.loadImages(this.props.frames)
    }

    this.input_canvas.forEachObject((img:any,idx) => {
      const fr = this.props.frames.find((x)=>x.id == img.frame_id);
      if(!fr)
        return;

      const newtop= (fr.top || 0)* img.width;
      const newleft= (fr.left || 0)* img.width;

      img.visible= (img.id == this.props.id) ? true : false;

      if(this.props.playing){
        img.top= newtop;
        img.left = this.props.reverse ? -newleft : newleft;
        img.flipX = this.props.reverse;
      }

    })

    this.input_canvas.discardActiveObject();
    this.input_canvas.renderAll();
  }

  render(){

    return (
      <div className="canvas-holder">
        <canvas id="c" ref={node => this.the_canvas = node }/>
      </div>
    )
  }
}

export {PlayerComponent}
