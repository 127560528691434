import {addItem} from "components/canvas/DropHandler";
import {useDesignContext} from "contexts/design_context";
import React, {CSSProperties, useEffect, useState} from "react";
import {useDrag} from "react-dnd";
import { InstantSearch, SearchBox, Hits, InfiniteHits } from "react-instantsearch";
import _ from "underscore";
import {searchNpcs} from "utils/searchkit_client";
import {loadSearchCss} from "./elements_panel";

const NpcsPanel = (props) => {

  const href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"

  useEffect(loadSearchCss(href), []);

  const {designController} = useDesignContext();

  const HitView = ({ hit }) => {

    const svgStyle:React.CSSProperties = {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '10px',
      border: '1px solid black',
      borderRadius: '5px',
    };

    const url = hit.sprites.find(sp => !!sp.public_filename)?.public_filename

    if (!url) return null;

    const obj = {
      ...hit,
      type: 'deco'
    };

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
      type: 'sprite',
      item: obj,
      end: (_item, _monitor) => {
        //const dropResult = monitor.getDropResult<{name:string}>()
        //const coords = monitor.getClientOffset();
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }))

    const style:CSSProperties = {
      opacity: isDragging ? 0.5 : 1,
      cursor: 'move',
      //position: isDragging ? 'fixed' : 'static'
    }

    return <div ref={drag} style={style} draggable={true}>
      <img src={url} style={svgStyle} alt={hit.name} onClick={() => addItem(obj, true, designController)}></img>

      <p>{hit.name}</p>
    </div>
    };

  //const index = (window as any).npcs_index;
  const index = `npcs_${(window as any).rails_env}`;

  return <InstantSearch indexName={index} searchClient={searchNpcs} future={{preserveSharedStateOnUnmount: false}}>
    <SearchBox />
    <InfiniteHits hitComponent={HitView} id="hits-elements"  classNames={{list: 'row', item: 'col-12 col-sm-6 col-md-3', loadMore: 'd-none'}}/>
  </InstantSearch>
};

 
export default NpcsPanel;


