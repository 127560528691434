import React from 'react';
import { Frame } from './timeline';
import { SECONDS_LENGTH, TRACK_HEIGHT } from './seconds-sizes';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

class TimelineFrame extends React.Component<{index:number, layerKey:string, dragEvent:Function, frame:Frame},{}> {

  constructor(props) {
    super(props);
  }

  handleMouseClick(e) {
    this.props.dragEvent(e, this.props.layerKey, this.props.index);
  }

  get width():number{
    return this.props.frame.duration * SECONDS_LENGTH;
  }
  get left():number{
    return this.props.frame.second * SECONDS_LENGTH;
  }

  render() {
    return (
      <span className="timeline-editor__frames-layer__item"
        style={{width: this.width, left: this.left}}
        onMouseDown={(e) => this.handleMouseClick(e)}
      >

        {this.props.frame.name}
      </span>
    );
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

function TimelineFramePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(props.frame.dragging) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const width = props.frame.duration * SECONDS_LENGTH;
  const left= props.frame.second * SECONDS_LENGTH;

  const open = Boolean(anchorEl);

  const handleMouseClick = (e) => {
    props.frame.selected=true
    props.dragEvent(e, props.layerKey, props.frame);
  }

  return (
    <div
      className={`timeline-editor__frames-layer__item ${props.frame.selected ? 'selected' : 'unselected'}`}
      style={{width, left, top: props.track * TRACK_HEIGHT}}
      onMouseDown={(e) => handleMouseClick(e)}>
          
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.frame.name}
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper, }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          {props.frame.name}
        </Typography>
      </Popover>
    </div>
  );
}

export {TimelineFrame, TimelineFramePopover}
