import React, { Component } from "react";
import Card  from 'react-bootstrap/Card';
import { AnchorAttributes } from "models/anchor";

class Anchor extends Component<{anchor:AnchorAttributes , onClick?: Function},{isMouseInside:boolean}> { 

  constructor(props:{anchor:AnchorAttributes , onClick?: Function}) {
    super(props)
    this.state = { isMouseInside: false };
  }

  render() {
    return (<Card>
      <Card.Header>
        <strong>{this.props.anchor.name}</strong>
      </Card.Header>

      <Card.Body onClick={(e) =>this.props.onClick && this.props.onClick()}>
        <Card.Text>x={this.props.anchor.x}, y={this.props.anchor.y}</Card.Text>
      </Card.Body>

      </Card>)
  }
}
export {Anchor}
