import * as _ from "underscore";
import { underscore } from "utils/dotry";
import * as Backbone from "backbone";
class BaseModel extends Backbone.Model {

  dispatcher: any;
  schema: { [name: string]: any; };

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('BaseModel');
  }

  protectedAttributes():string[]{
    return ["created_at", "updated_at", "id"];
  }

  initialize(settings, options) {
    this.dispatcher = options.dispatcher;
  }

  dispatchCreated(className, register?) {
    if(!this.dispatcher)
      return;
    this.dispatcher.trigger("initialize:" + (className || this._className()), {
      target: this,
      register: register
    });
  }

  _className() {
    throw "Doesnt Work";
    return this.constructor.toString().match(/function +([^\( ]*)\(/)[1];
  }

  toJSON() {
    var retval;
    retval = {};
    retval[underscore(this._className())] = _.omit(this.attributes, _.result(this, "protectedAttributes") || []);
    return retval;
  }

  clone() {
    throw "Not Implemented"
    return new BaseModel(this.attributes, {})
  }

};


export { BaseModel};
