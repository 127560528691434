import Controls from '../controls/controls'
import {Physics} from 'phaser'
import MainScene, {CATEGORY_LADDER, CATEGORY_PLATFORM, CATEGORY_PLAYER, CATEGORY_WALL} from 'game/scenes/mainScene'
import {Spot} from '../glitx/sign_post';
import {AvatarAttributes} from 'models/avatar';
import Layer from '../glitx/layer';
import {GlitchSignPoint} from 'components/glitch/space';

const MAX_VELOCITY_X = 10; // Maximum horizontal velocity
const MAX_VELOCITY_Y = 5; // Maximum vertical velocity (for jumping, falling, etc.)

type PlayerProps = {
  spot: Spot,
  texture:string,
  avatar: AvatarAttributes,
}

export default class Player2 extends Physics.Matter.Sprite {

  private _dead: boolean = false
  private _halt: boolean = false
  public touchingPlatform:any = false
  public onLadder:boolean = false
  private isOnGround: boolean = false; // Add a flag to check if the player is on the ground
  public avatar:AvatarAttributes

  // TODO copy player logic from the example:
  // https://phaser.io/sandbox/?src=src/tilemap/collision/matter%20platformer%20modify%20map.js
  // in order to have sensors, acceleration, only jump once, etc

  constructor(scene: Phaser.Scene, {spot,texture, avatar}:PlayerProps) {
    super(scene.matter.world, spot[0], spot[1], texture, null, { label: 'player' })

    this.avatar=avatar

    this.scene = scene

    this.setBounce(0.05)

    this.setVisible(true)

    this.setOrigin(0.5, 0.5)

    const body = this.body as MatterJS.BodyType;
    body.chamfer = { radius: 10 };

    this.avatar.sprites.forEach((sprite, i) => {
      this.anims.createFromAseprite(`avatar-${i}`,null)
    })
    this.setFriction(0.01)
    this.setFrictionAir(0.05)
    this.setDensity(0.05)

    this.setFixedRotation(); // This will lock the rotation

    // Set the collision category for the player
    this.setCollisionCategory(CATEGORY_PLAYER);

    // Set what the player collides with
    this.setCollidesWith([CATEGORY_PLATFORM, CATEGORY_WALL, CATEGORY_LADDER]);

    // Additional player setup like setting the mass or friction
    this.setMass(1);
    scene.add.existing(this)
    const checkCollision = (bodyA: MatterJS.BodyType, bodyB: MatterJS.BodyType) => {
      return (bodyA.label === 'player' &&
        ['platform_line', 'worldFloor'].includes(bodyB.label)) ||
        (['platform_line', 'worldFloor'].includes(bodyA.label) &&
        bodyB.label === 'player')
    }

    // Add collision event listener to detect when the player touches the ground
    this.setOnCollide((data: MatterJS.ICollisionPair) => {
      const { bodyA, bodyB } = data;
      //@ts-ignore
      if(checkCollision(bodyA, bodyB)) {
        console.log('player on platform_line')
        this.isOnGround = true;
        if(this.body.velocity.x > 0.1) {
          this.play('right',true)
        } else if(this.body.velocity.x < -0.1) {
          this.play('right',true)
          this.setFlipX(true)
        }
      }

      if(this.isLadderCollision(bodyA, bodyB)) {
        console.log('player on ladder')
        this.onLadder = true;
        this.matterBody.collisionFilter.mask = CATEGORY_LADDER;

        body.gravityScale.y = 0;
      }
    });

    // Add collision event listener to detect when the player leaves the ground
    this.setOnCollideEnd((data: MatterJS.ICollisionPair) => {
      const { bodyA, bodyB } = data;
      if (checkCollision(bodyA as MatterJS.BodyType, bodyB as MatterJS.BodyType)) {
        setTimeout(() => {
          this.isOnGround = false;
        }, 250)
      }
      if(this.isLadderCollision(bodyA, bodyB)) {
        console.log('player off ladder')
        this.matterBody.collisionFilter.mask = CATEGORY_PLATFORM | CATEGORY_WALL | CATEGORY_LADDER;
        this.onLadder = false;
        body.gravityScale.y = 1;
      }
    });
  }

  kill() {
    this._dead = true

    // animate the camera if the player dies
    this.scene.cameras.main.shake(500, 0.015)
    this.scene.time.addEvent({
      delay: 500,
      callback: () => this.scene.scene.restart()
    })
  }

  isLadderCollision(bodyA, bodyB) {
    return (
      (bodyA.label === 'player' && bodyB.label === 'ladder') ||
      (bodyB.label === 'player' && bodyA.label === 'ladder')
    );
  }

  killEnemy() {
    //this.setVelocityY(-600)
    this.applyForce(new Phaser.Math.Vector2(0, 0.05));
  }

  get mainScene(){
    return (this.scene as MainScene)
  }

  place(spot:GlitchSignPoint, layerGroups:Layer[]) {
    const middleground = layerGroups.find(({layer}) => layer.id == 'middleground');
    console.log('middleground signposts:', middleground.signposts.map(({signpost}) => signpost));
    if(spot.signpost_id){
      const sp = middleground.signposts.find(({signpost}) => signpost.id == spot.signpost_id)
      this.setX(sp.x)
      this.setY(sp.y)
    } else {
      const stageHeight = this.mainScene.gameData.dynamic.b - this.mainScene.gameData.dynamic.t ;
      const heightDiff = stageHeight - middleground.layer.h;

      const [offset_x,offset_y] = [middleground.layer.w/2, heightDiff + middleground.layer.h]
      this.setX(spot.x + offset_x)
      this.setY(spot.y + offset_y)
    }
  }

  get matterBody(): MatterJS.BodyType {
    return this.body as MatterJS.BodyType
  }

  halt() {
    //TODO fix this.body.enable = false
    this._halt = true
  }

  update(cursors: any, controls: Controls) {
    if (this._halt || this._dead) return

    // controls left & right
    if (cursors.left.isDown || controls.leftIsDown) {
      //this.setVelocityX(-500)
      this.applyForce(new Phaser.Math.Vector2({ x: -0.01, y: 0 }));
      if(this.isOnGround){
        this.play('right', true)
        this.setFlipX(true)
      }
    } else if (cursors.right.isDown || controls.rightIsDown) {
      this.applyForce(new Phaser.Math.Vector2({ x: 0.01, y: 0 }));
      if(this.isOnGround){
        this.play('right', true)
        this.setFlipX(false)
      }
    }
    // controls up
    if(this.onLadder) {
      this.setVelocityY(0);
      this.setVelocityX(0);
      // Allow only up and down movement
      if (cursors.up.isDown || controls.upIsDown){
        this.play('climb',true)
        this.setVelocityY(-2); // Adjust the speed as needed
      } else if (cursors.down.isDown){
        this.play('climb',true)
        this.setVelocityY(2);
      }
    } else if(this.isOnGround) {
      if ((cursors.up.isDown || cursors.space.isDown || controls.upIsDown)){
        this.play('jump',true)
        this.applyForce(new Phaser.Math.Vector2({ x: 0, y: -0.02 }));
      }
    }

        // Cap the player's velocity
    const body:any=this.body;
    if (body.velocity.x > MAX_VELOCITY_X) {
        this.setVelocityX(MAX_VELOCITY_X);
    } else if (body.velocity.x < -MAX_VELOCITY_X) {
        this.setVelocityX(-MAX_VELOCITY_X);
    }

    if (body.velocity.y > MAX_VELOCITY_Y) {
        this.setVelocityY(MAX_VELOCITY_Y);
    } else if (body.velocity.y < -MAX_VELOCITY_Y) {
        this.setVelocityY(-MAX_VELOCITY_Y);
    }

  }
}
