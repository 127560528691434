import { fabric } from 'fabric';

class LineObject extends fabric.Line {

  static type= 'line'
  static superType= 'drawing'

  //@ts-ignore
  initialize(points: any, options: any) {
    if (!points) {
      const { x1, x2, y1, y2 } = options;
      points = [x1, y1, x2, y2];
    }
    options = options || {};
    //@ts-ignore
    super.initialize(points, options);
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render(ctx);
  }

  static fromObject(options: any, callback: (a:any)=>fabric.Line):fabric.Line {
    const { x1, x2, y1, y2 } = options;
    return callback(new fabric.Line([x1, y1, x2, y2], options));
  };
};

fabric.Line = fabric.util.createClass(LineObject, {
  type: LineObject.type
});

//@ts-ignore
fabric.Line.fromObject = LineObject.fromObject;

export default fabric.Line;
