import {GlitchGame, GlitchPlatformLine} from 'components/glitch/space'
import MainScene, {CATEGORY_PLATFORM} from 'game/scenes/mainScene';
import {SpaceAttributes} from 'models/space';
import Phaser from 'phaser'
import {performAction} from './deco';
import {SignPostable, Spot} from './sign_post';

class PlatformLine extends Phaser.GameObjects.GameObject implements SignPostable{

  isPlaying:boolean=false;
  line: GlitchPlatformLine;
  start: {name: string; x: number; y: number;};
  end: {name: string; x: number; y: number;};
  collisionHandlers: Array<any> = [];
  collisionEndHandlers: Array<any> = [];

  _loadNextLevel: boolean;
  nextGame: GlitchGame;
  nextSpace: SpaceAttributes;
  nextSpot: Spot;

  constructor(scene: Phaser.Scene, line: GlitchPlatformLine, offset_x: number, offset_y: number) {

    super(scene, 'platform_line')

    this.start = line.endpoints.find(e=>e.name=='start');
    this.end = line.endpoints.find(e=>e.name=='end');

    scene.add.existing(this)
    // Calculate the center of the line
    const centerX = offset_x + (this.start.x + this.end.x) / 2;
    const centerY = offset_y + (this.start.y + this.end.y) / 2;

    // Calculate the length of the line
    const length = Phaser.Math.Distance.Between(this.start.x, this.start.y, this.end.x, this.end.y);
    this.line = line;

    // Calculate the angle of the line
    const angle = Math.atan2(this.end.y - this.start.y, this.end.x - this.start.x);

    this.body = this.scene.matter.add.rectangle(centerX, centerY, length, 1, {
      isStatic: true,
      isSensor: false,
      label: 'platform_line',
      angle: angle,
      collisionFilter: {
        category: CATEGORY_PLATFORM,
      },
      onCollideCallback: ({bodyA, bodyB}) => {
        //console.log('AQUI collision', bodyA, bodyB)
        this.collisionHandlers.forEach(handler => handler({bodyA, bodyB}))
      },
      onCollideEndCallback: ({bodyA, bodyB}) => {
        //console.log('AQUI collision end', bodyA, bodyB)
        this.collisionEndHandlers.forEach(handler => handler({bodyA, bodyB}))
      }
    });

    (line.event_actions||[]).forEach(({event,action,parameters}) => {
      if(event == 'collision-start'){
        this.collisionHandlers.push(({bodyA, bodyB}) => {
          if(bodyA === this.body || bodyB === this.body){
            performAction(this, action,parameters,[offset_x, offset_y])
          }
        })
      }
      if(event == 'collision-end'){
        this.collisionEndHandlers.push(({bodyA, bodyB}) => {
          if(bodyA === this.body || bodyB === this.body){
            performAction(this, action,parameters,[offset_x, offset_y])
          }
        })
      }

    })

  }

  get mainScene(){
    return this.scene as MainScene
  }

  get x(){
    return this.body.position.x
  }

  get y(){
    return this.body.position.y
  }

  get height(){
    return this.body.height
  }

  get width(){
    return this.body.width
  }

  get scaleY(){
    return 1
  }
}

export default PlatformLine
