import React, { Component } from 'react';
import { fabric } from 'fabric';
import { v4 } from 'uuid';
import ResizeObserver from 'resize-observer-polyfill';

import CanvasController, { HandlerOptions } from './handlers/CanvasController';
import { FabricCanvas } from './utils';
import { defaults } from './constants';
import DropHandler from './DropHandler';

export type CanvasProps = HandlerOptions & {
  responsive?: boolean;
  style?: React.CSSProperties;
  ref?: React.RefAttributes<CanvasController>;
  setFabricCanvas?: (x:fabric.Canvas) => void;
  setCanvasController?: (x:CanvasController) => void;
};

interface IState {
  id: string;
  loaded: boolean;
}

class DesignCanvas extends Component<CanvasProps, IState> {

  public controller: CanvasController;
  public canvas: FabricCanvas;

  public container = React.createRef<HTMLDivElement>();
  public frame= React.createRef<HTMLDivElement>();

  private resizeObserver: ResizeObserver;

  static defaultProps: CanvasProps = {
    setFabricCanvas: (x)=>{},
    setController: (x)=>{},
    id: v4(),
    editable: true,
    zoomEnabled: true,
    minZoom: 30,
    maxZoom: 300,
    responsive: true,
    width: 0,
    height: 0,
  };

  state: IState = {
    id: v4(),
    loaded: false,
  };

  componentDidMount() {
    const { editable, canvasOption:opts, width, height, responsive, ...other } = this.props;
    const { id } = this.state;

    const canvasOption = Object.assign({}, defaults.canvasOption, opts, { width, height, selection: editable, });

    this.canvas = new fabric.Canvas(`canvas_${id}`, canvasOption);
    this.props.setFabricCanvas  && this.props.setFabricCanvas(this.canvas)

    if(process.env.NODE_ENV == 'development'){
      (window as any).canvas_guay=this.canvas;
    }
    this.canvas.setBackgroundColor(canvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
    this.canvas.renderAll();

    const {canvas, container, frame}=this;

    this.controller = new CanvasController({
      id,
      width,
      height,
      editable,
      canvas,
      frame: frame.current,
      container: container.current,
      canvasOption,
      ...other
    });

    this.props.setCanvasController && this.props.setCanvasController(this.controller);

    if (this.props.responsive) {
      this.createObserver();
    } else {
      this.handleLoad();
    }
  }

  componentDidUpdate(prevProps: CanvasProps) {
    if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
      this.controller.eventHandler.resize(this.props.width, this.props.height);
    }
    if (this.props.editable !== prevProps.editable) {
      this.controller.editable = this.props.editable;
    }
    if(this.props.onSelect !== prevProps.onSelect) {
      this.controller.onSelect = this.props.onSelect;
    }
    if (this.props.responsive !== prevProps.responsive) {
      if (this.props.responsive) {
        this.destroyObserver();
        this.createObserver();
      } else {
        this.destroyObserver();
      }
    }
    if (JSON.stringify(this.props.canvasOption) !== JSON.stringify(prevProps.canvasOption)) {
      this.controller.setCanvasOption(this.props.canvasOption);
    }
    if (JSON.stringify(this.props.keyEvent) !== JSON.stringify(prevProps.keyEvent)) {
      this.controller.setKeyEvent(this.props.keyEvent);
    }
    if (JSON.stringify(this.props.fabricObjects) !== JSON.stringify(prevProps.fabricObjects)) {
      this.controller.setFabricObjects(this.props.fabricObjects);
    }
    if (JSON.stringify(this.props.workareaOption) !== JSON.stringify(prevProps.workareaOption)) {
      this.controller.setWorkareaOption(this.props.workareaOption);
    }
    if (JSON.stringify(this.props.guidelineOption) !== JSON.stringify(prevProps.guidelineOption)) {
      this.controller.setGuidelineOption(this.props.guidelineOption);
    }
    if (JSON.stringify(this.props.objectOption) !== JSON.stringify(prevProps.objectOption)) {
      this.controller.setObjectOption(this.props.objectOption);
    }
    if (JSON.stringify(this.props.gridOption) !== JSON.stringify(prevProps.gridOption)) {
      this.controller.setGridOption(this.props.gridOption);
    }
    if (JSON.stringify(this.props.propertiesToInclude) !== JSON.stringify(prevProps.propertiesToInclude)) {
      this.controller.setPropertiesToInclude(this.props.propertiesToInclude);
    }
    if (JSON.stringify(this.props.activeSelectionOption) !== JSON.stringify(prevProps.activeSelectionOption)) {
      this.controller.setActiveSelectionOption(this.props.activeSelectionOption);
    }
  }

  componentWillUnmount() {
    this.destroyObserver();
    this.controller.destroy();
  }

  createObserver = () => {
    this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const { width = 0, height = 0 } = (entries[0] && entries[0].contentRect) || {};
      this.controller.eventHandler.resize(width, height);
      this.controller.frameHandler?.recalcPosition();
      if (!this.state.loaded) {
        this.handleLoad();
      }
    });
    this.resizeObserver.observe(this.container.current);
  };

  destroyObserver = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  };

  handleLoad = () => {
    this.setState( { loaded: true, }, () => {
      if (this.props.onLoad) {
        this.props.onLoad(this.controller, this.canvas);
      }
    },
    );
  };

  onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if(this.props.updateParallaxOnScroll)
      this.props.updateParallaxOnScroll(e);
    return true
  }

  render() {
    const { style } = this.props;
    const { id } = this.state;
    const frameStyle = {
      width: this.controller?.workareaOption?.width || 100,
      height: this.controller?.workareaOption?.height || 100,
      top: this.controller?.workarea?.top || 0,
      left: this.controller?.workarea?.left || 0,
    };
    return (
        <div ref={this.container} id={id} className="rde-canvas" style={{ width: '100%', height: '100%', ...style }} onScroll={this.onScroll}>
            {/*
          <div ref={this.frame} id="rde-canvas-frame" className="rde-canvas-frame" style={{width:'100%', height: '100%'}}>
            <div className='frame' style={frameStyle}></div>
            <div className='north' style={{top: 0}}></div>
            <div className='east' style={{right: 0, top:0, height: '100%'}}></div>
            <div className='south' style={{bottom: 0}}></div>
            <div className='west' style={{left: 0, top:0, height: '100%'}}></div>
          </div>
  */}
          <DropHandler>
            <canvas id={`canvas_${id}`} />
          </DropHandler>
        </div>
    );
  }
}

export default DesignCanvas;
