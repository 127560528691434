import React from "react";
import ReactDOM from "react-dom";
import TimelineFrames from "./timeline-frames";
import PropTypes from "prop-types";
import "./timeline.scss";

type Frame = {
  id: string,
  name: string,
  second: number,
  duration: number,
}

type Layer = {
  id: string,
  name: string
}

type Framed = {
  [id:string]: Frame[]
}

type Timelined = {
  layers: Layer[],
  frames: Framed
}

class Timeline extends React.Component<Timelined & {onUpdateFrames:Function},Timelined> {

  constructor(props) {
    super(props);
    this.state = {layers: props.layers, frames: props.frames};
  }

  updateFrames(framesUpdated) {
    this.setState({frames: framesUpdated});
    this.props.onUpdateFrames(framesUpdated);
  }

  render() {
    return <div className="timeline-editor">
      <div className="timeline-editor__container">
        <div className="timeline-editor__layers">
          <div className="timeline-editor__layers-header">Layers</div>
          <ul>
            {this.state.layers.map((layer)=>
            <li key={layer.id}>{layer.name}</li>
            )}
          </ul>
        </div>
        <TimelineFrames frames={this.state.frames} layers={this.state.layers} updateFrames={this.updateFrames.bind(this)} />
      </div>
    </div>
  }
}

export {Timeline, Timelined, Frame, Layer, Framed};
