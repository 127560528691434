import { fabric } from 'fabric';

import CanvasController from './CanvasController';
import { FabricObject, FabricEvent, InteractionModes, InteractionMode } from '../utils';
import VideoObject from '../objects/Video';
import BaseHandler from './BaseHandler';
import {setInteractionMode, setOpenDoors, setOpenProperties} from 'redux/reducers/editor';

let previousTouch;

let ongoingTouches = [];
let lastTapTime = null;
let initialDistance = 0;
let initialZoom = null;
let initialPos:[number,number] = null;
let initialMidpointCanvas = null;
let initialViewportTransform = null;
let initialAngle = 0;
let previousInteractionMode = null;
/**
 * Event Handler Class
 * @author salgum1114
 * @class EventHandler
 */
class EventHandler extends BaseHandler {

  keyCode: number;
  panning: boolean;

  public interactionMode: InteractionMode= InteractionModes.SELECTION;

  constructor(handler: CanvasController) {
    super(handler);
    this.initialize();
  }

  public handleStateChange(newState) {

    if(newState.editor.interactionMode == this.interactionMode)
      return;

    this.interactionMode = newState.editor.interactionMode;
  }

  /**
   * Attch event on document
   *
   */
  public initialize() {
    if (this.controller.editable) {
      this.controller.canvas.on('object:modified', this.modified as any)
      this.controller.canvas.on('object:scaling', this.scaling as any)
      this.controller.canvas.on('object:scaled', this.scaled as any)
      this.controller.canvas.on('object:moving', this.moving as any)
      this.controller.canvas.on('object:moved', this.moved as any)
      this.controller.canvas.on('object:rotating', this.rotating as any)
      this.controller.canvas.on('object:rotated', this.rotated as any)
      this.controller.canvas.on('mouse:wheel', this.mousewheel as any)
      this.controller.canvas.on('mouse:down', this.mousedown as any)
      this.controller.canvas.on('mouse:move', this.mousemove as any)
      this.controller.canvas.on('mouse:up', this.mouseup as any)
      this.controller.canvas.on('mouse:dblclick', this.dblclick as any)
      this.controller.canvas.on('tap:dblclick', this.dblclick as any)
      this.controller.canvas.on('selection:cleared', this.selection as any)
      this.controller.canvas.on('selection:created', this.selection as any)
      this.controller.canvas.on('selection:updated', this.selection as any)
    } else {
      this.controller.canvas.on('mouse:down', this.mousedown as any)
      this.controller.canvas.on('mouse:move', this.mousemove as any)
      this.controller.canvas.on('mouse:out', this.mouseout as any)
      this.controller.canvas.on('mouse:up', this.mouseup as any)
      this.controller.canvas.on('mouse:wheel', this.mousewheel as any)
    }
    this.controller.canvas.wrapperEl.tabIndex = 1000;
    this.controller.canvas.wrapperEl.addEventListener('keydown', this.keydown, false);
    this.controller.canvas.wrapperEl.addEventListener('keyup', this.keyup, false);
    this.controller.canvas.wrapperEl.addEventListener('mousedown', this.onmousedown, false);
    this.controller.canvas.wrapperEl.addEventListener('contextmenu', this.contextmenu, false);
    this.controller.canvas.wrapperEl.addEventListener('touchstart', this.touchstart, false)
    this.controller.canvas.wrapperEl.addEventListener('touchend', this.touchend, false)

    if (this.controller.keyEvent.clipboard) {
      document.addEventListener('paste', this.paste, false);
    }

    if(fabric.isTouchSupported) {
      this.initMultitouch();
    }

  }

  initMultitouch() {

    // Variables to keep track of touches

    // Helper function to copy touch properties
    const copyTouch = (touch) => ({
      identifier: touch.identifier,
      time: new Date().getTime(),
      clientX: touch.clientX,
      clientY: touch.clientY
    });

    // Touch Start Handler
    this.controller.canvas.wrapperEl.addEventListener('touchstart', (e) => {
      e.preventDefault(); // Prevent default to avoid unwanted behaviors like scrolling

      if(lastTapTime) {
        const tapLength = new Date().getTime() - lastTapTime;
        const tapDistance = Math.sqrt(
          Math.pow(e.changedTouches[0].clientX - previousTouch.clientX, 2) +
          Math.pow(e.changedTouches[0].clientY - previousTouch.clientY, 2)
        );
        if(tapLength < 300 && tapDistance < 50) {
          this.controller.canvas.fire('tap:dblclick', { e: e, target: this.controller.canvas.findTarget(e,false) });
          lastTapTime=null;
          return;
        }
      }
      lastTapTime = new Date().getTime();

      // Add all new touches to ongoingTouches
      for (let i = 0; i < e.changedTouches.length; i++) {
	ongoingTouches.push(copyTouch(e.changedTouches[i]));
      }

      console.log(`Touch Start: ${ongoingTouches.length} touch(es) on screen.`);

      if (ongoingTouches.length === 2) {
	// Two fingers are on the screen
	handleTwoFingerStart(e);
      } else if (ongoingTouches.length === 1 && previousInteractionMode) {
        handleTwoFingerEnd(e);
      }

      // Update Fabric.js event (optional)
      this.controller.canvas.fire('touch:gesturestart', { e: e });
    }, false);

    // Touch Move Handler
    this.controller.canvas.wrapperEl.addEventListener('touchmove', (e) => {
      e.preventDefault();

      // Update the positions of ongoing touches
      for (let i = 0; i < e.changedTouches.length; i++) {
        let idx = ongoingTouchIndexById(e.changedTouches[i].identifier);
        if (idx >= 0) {
          ongoingTouches[idx] = copyTouch(e.changedTouches[i]);
        }
      }

      if (ongoingTouches.length === 2) {
        // Two fingers are moving on the screen
        handleTwoFingerMove(e);
      }

      // Update Fabric.js event (optional)
      this.controller.canvas.fire('touch:gesturemove', { e: e });
    }, false);


    // Touch End and Cancel Handler
    const handleTouchEnd = (e) => {
      e.preventDefault();

      for (let i = 0; i < e.changedTouches.length; i++) {
	let idx = ongoingTouchIndexById(e.changedTouches[i].identifier);
	if (idx >= 0) {
	  ongoingTouches.splice(idx, 1); // Remove touch
	}
      }

      console.log(`Touch End: ${ongoingTouches.length} touch(es) remaining.`);

      if (e.type === 'touchend' && ongoingTouches.length === 1) {
	// One finger remains on the screen
	handleOneFingerLeft(e);
      } else if (e.type === 'touchcancel') {
	// Handle touch cancellation if necessary
	handleTouchCancel(e);
      }

      // Update Fabric.js event (optional)
      this.controller.canvas.fire('touch:gestureend', { e: e });
    }

    this.controller.canvas.wrapperEl.addEventListener('touchend', handleTouchEnd, false);
    this.controller.canvas.wrapperEl.addEventListener('touchcancel', handleTouchEnd, false);

    // Helper function to find touch index by identifier
    const ongoingTouchIndexById = (idToFind) => {
      for (let i = 0; i < ongoingTouches.length; i++) {
	const id = ongoingTouches[i].identifier;

	if (id === idToFind) {
	  return i;
	}
      }
      return -1; // Not found
    }

    // Handlers for specific gestures
    const handleTwoFingerEnd = (e) => {
      this.store.dispatch(setInteractionMode(previousInteractionMode));
      previousInteractionMode=null;
    }

    const handleTwoFingerStart = (e) => {
      console.log('Two fingers placed on the screen.');
      previousInteractionMode = this.controller.interactionHandler.interactionMode
      this.controller.interactionHandler.grab();
      // Example: Initialize gesture properties
      const touch1 = ongoingTouches[0];
      const touch2 = ongoingTouches[1];

      const deltaX = touch2.clientX - touch1.clientX;
      const deltaY = touch2.clientY - touch1.clientY;

      initialDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      initialZoom = this.controller.canvas.getZoom();
      initialPos = [touch1.clientX, touch1.clientY];
      initialAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      // calculate the middle point between the two touches
      initialMidpointCanvas = {
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2
      };

      initialViewportTransform = this.controller.canvas.viewportTransform.slice();

      // Store initial gesture data
      //canvas.initialDistance = initialDistance;
      //canvas.initialAngle = initialAngle;
    }

    const _old_handleTwoFingerMove = (e) => {
      console.log('Two fingers moving on the screen.');

      if (ongoingTouches.length !== 2) return;

      const touch1 = ongoingTouches[0];
      const touch2 = ongoingTouches[1];

      const deltaX = touch2.clientX - touch1.clientX;
      const deltaY = touch2.clientY - touch1.clientY;
      const currentDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const currentAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

      // Calculate scale factor
      const scale = currentDistance / initialDistance;
      const newZoom = initialZoom * scale;

      const _rotation = currentAngle - initialAngle;

      const midpoint  = initialMidpointCanvas;
      const movementX = touch1.clientX - initialPos[0];
      const movementY = touch1.clientY - initialPos[1];

      this.controller.canvas.setViewportTransform([
        newZoom,
        0,
        0,
        newZoom,
        movementX,
        movementY
      ]);


      //this.controller.canvas.relativePan({
      //  x: movementX,
      //  y: movementY
      //});

      this.controller.canvas.requestRenderAll()
    }

    const handleTwoFingerMove = (e) => {
      if (ongoingTouches.length !== 2) return;

      const touch1 = ongoingTouches[0];
      const touch2 = ongoingTouches[1];
      if (
        !initialViewportTransform ||
        initialViewportTransform.length !== 6 ||
        typeof initialDistance !== 'number' ||
        initialDistance === 0 ||
        !initialMidpointCanvas ||
        typeof initialMidpointCanvas.x !== 'number' ||
        typeof initialMidpointCanvas.y !== 'number'
      ) {
        console.warn('Initial gesture state is invalid.');
        return;
      }

      // Get canvas coordinates for the touch points
      const currentPoint1 = this.controller.canvas.getPointer(touch1, true);
      const currentPoint2 = this.controller.canvas.getPointer(touch2, true);
      if (
        !currentPoint1 ||
        !currentPoint2 ||
        typeof currentPoint1.x !== 'number' ||
        typeof currentPoint1.y !== 'number' ||
        typeof currentPoint2.x !== 'number' ||
        typeof currentPoint2.y !== 'number'
      ) {
        console.warn('Invalid touch points detected during touchmove.');
        return;
      }

      // Compute current midpoint in canvas coordinates
      const currentMidpointCanvas = {
	x: (currentPoint1.x + currentPoint2.x) / 2,
	y: (currentPoint1.y + currentPoint2.y) / 2,
      };

      // Compute deltas in canvas coordinates
      const deltaX = currentPoint2.x - currentPoint1.x;
      const deltaY = currentPoint2.y - currentPoint1.y;

      // Compute current distance and angle
      const currentDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      if(!currentDistance){
        console.warn('Invalid current distance detected during touchmove.');
        return
      }
      //const currentAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

      // Calculate scale factor
      const scale = currentDistance / initialDistance;
       // Ensure scale is a finite number greater than zero
      if (!isFinite(scale) || scale <= 0) {
        console.warn('Calculated scale is invalid.');
        return;
      }

      // Compute new zoom level
      const newZoom = scale * initialViewportTransform[0];

      // Compute translation to keep the scaling centered on the initial midpoint
      const translationX = currentMidpointCanvas.x - scale * initialMidpointCanvas.x + initialViewportTransform[4];
      const translationY = currentMidpointCanvas.y - scale * initialMidpointCanvas.y + initialViewportTransform[5];

      if (!isFinite(translationX) || !isFinite(translationY)) {
        console.warn('Calculated translation values are invalid.');
        return;
      }

      // Set the new viewport transform
      this.controller.canvas.setViewportTransform([
	newZoom,
	0,
	0,
	newZoom,
	translationX,
	translationY,
      ]);

      this.controller.canvas.requestRenderAll();
    }


    const handleOneFingerLeft = (e) => {
      console.log('One finger left the screen, one finger remains.');
      //this.controller.interactionHandler.selection()
    }

    const handleTouchCancel = (e) => {
      console.log('Touch canceled.');
      //this.controller.interactionHandler.selection()
    }

  }

  /**
   * Detach event on document
   *
   */
  public destroy = () => {
    super.destroy();

    if (this.controller.editable) {
      this.controller.canvas.off({
        'object:modified': this.modified,
        'object:scaling': this.scaling,
        'object:moving': this.moving,
        'object:moved': this.moved,
        'object:rotating': this.rotating,
        'mouse:wheel': this.mousewheel,
        'mouse:down': this.mousedown,
        'mouse:move': this.mousemove,
        'mouse:up': this.mouseup,
        'selection:cleared': this.selection,
        'selection:created': this.selection,
        'selection:updated': this.selection,
      });
    } else {
      this.controller.canvas.off({
        'mouse:down': this.mousedown,
        'mouse:move': this.mousemove,
        'mouse:out': this.mouseout,
        'mouse:up': this.mouseup,
        'mouse:wheel': this.mousewheel,
      });
      this.controller.getObjects().forEach(object => {
        object.off('mousedown', this.controller.eventHandler.object.mousedown as any);
      });
    }
    this.controller.canvas.wrapperEl?.removeEventListener?.('keydown', this.keydown);
    this.controller.canvas.wrapperEl?.removeEventListener?.('keyup', this.keyup);
    this.controller.canvas.wrapperEl?.removeEventListener?.('mousedown', this.onmousedown);
    this.controller.canvas.wrapperEl?.removeEventListener?.('contextmenu', this.contextmenu);

    if (this.controller.keyEvent.clipboard) {
      this.controller.canvas.wrapperEl?.removeEventListener?.('paste', this.paste);
    }
  };

  /**
   * Individual object event
   */
  public object = {
    /**
     * Mouse down event on object
     * @param {FabricEvent} opt
     */
    mousedown: (opt: FabricEvent) => {
      const { target } = opt;
      if (target && target.link && target.link.enabled) {
        if (this.controller.onClick) {
          this.controller.onClick(this.controller.canvas, target);
        }
      }
    },
    /**
     * Mouse double click event on object
     * @param {FabricEvent} opt
     */
    mousedblclick: (opt: FabricEvent) => {
      const { target } = opt;
      if (target) {
        if (this.controller.onDblClick) {
          this.controller.onDblClick(this.controller.canvas, target);
        }
      }
    },
  };

  /**
   * Modified event object
   *
   * @param {FabricEvent} opt
   * @returns
   */
  public modified = (opt: FabricEvent) => {
    const { target } = opt;
    if (!target) {
      return;
    }

    if (this.controller.transactionHandler.active) {
      this.controller.transactionHandler.save('moved');
    }

    if (target.type === 'circle' && target.parentId) {
      return;
    }

    if (this.controller.onModified) {
      this.controller.onModified(target);
    }
  };

  /**
   * Moving event object
   *
   * @param {FabricEvent} opt
   * @returns
   */
  public moving = (opt: FabricEvent) => {
    const { target } = opt as any;
    if (this.interactionMode === InteractionModes.CROP) {
      this.controller.cropHandler.moving(opt as any);
    } else {
      if (this.controller.editable && this.controller.guidelineOption.enabled) {
        this.controller.guidelineHandler.movingGuidelines(target);
      }
      if (target.type === 'activeSelection') {
        const activeSelection = target as fabric.ActiveSelection;
        activeSelection.getObjects().forEach((obj: any) => {
          const left = target.left + obj.left + target.width / 2;
          const top = target.top + obj.top + target.height / 2;
          if (obj.superType === 'element') {
            const { id } = obj;
            const el = this.controller.elementHandler.findById(id);
            // TODO... Element object incorrect position
            this.controller.elementHandler.setPositionByOrigin(el, obj, left, top);
          }
        });
        return;
      }
      if (target.superType === 'element') {
        const { id } = target;
        const el = this.controller.elementHandler.findById(id);
        this.controller.elementHandler.setPosition(el, target);
      }
    }
  };

  /**
   * Moved event object
   *
   * @param {FabricEvent} opt
   */
  public moved = (opt: FabricEvent) => {
    const { target } = opt;
    this.controller.gridHandler.setCoords(target);
    if (this.controller.transactionHandler.active) {
      this.controller.transactionHandler.save('moved');
    }
    if (target.superType === 'element') {
      const { id } = target;
      const el = this.controller.elementHandler.findById(id);
      this.controller.elementHandler.setPosition(el, target);
    }
  };

  /**
   * Scaling event object
   *
   * @param {FabricEvent} opt
   */
  public scaling = (opt: FabricEvent) => {
    const { target } = opt as any;
    if (this.interactionMode === InteractionModes.CROP) {
      this.controller.cropHandler.resize(opt as any);
    }
    // TODO...this.handler.guidelineHandler.scalingGuidelines(target);
    if (target.superType === 'element') {
      const { id, width, height } = target;
      const el = this.controller.elementHandler.findById(id);
      // update the element
      this.controller.elementHandler.setScaleOrAngle(el, target);
      this.controller.elementHandler.setSize(el, target);
      this.controller.elementHandler.setPosition(el, target);
      const video = target as VideoObject;
      //@ts-ignore
      if (video.type === 'video' && video.player) {
        //@ts-ignore
        video.player.setPlayerSize(width, height);
      }
    }
  };

  /**
   * Scaled event object
   *
   * @param {FabricEvent} opt
   */
  public scaled = (_opt: FabricEvent) => {
    if (this.controller.transactionHandler.active) {
      this.controller.transactionHandler.save('scaled');
    }
  };

  /**
   * Rotating event object
   *
   * @param {FabricEvent} opt
   */
  public rotating = (opt: FabricEvent) => {
    const { target } = opt as any;
    if (target.superType === 'element') {
      const { id } = target;
      const el = this.controller.elementHandler.findById(id);
      // update the element
      this.controller.elementHandler.setScaleOrAngle(el, target);
    }
  };

  /**
   * Rotated event object
   *
   * @param {FabricEvent} opt
   */
  public rotated = (_opt: FabricEvent) => {
    if (this.controller.transactionHandler.active) {
      this.controller.transactionHandler.save('rotated');
    }
  };

  /**
   * Moing object at keyboard arrow key down event
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  public arrowmoving = (e: KeyboardEvent) => {
    const activeObject = this.controller.canvas.getActiveObject() as FabricObject;
    if (!activeObject) {
      return false;
    }
    if (activeObject.id === 'workarea') {
      return false;
    }
    if (e.keyCode === 38) {
      activeObject.set('top', activeObject.top - 2);
      activeObject.setCoords();
      this.controller.canvas.renderAll();
      return true;
    } else if (e.keyCode === 40) {
      activeObject.set('top', activeObject.top + 2);
      activeObject.setCoords();
      this.controller.canvas.renderAll();
      return true;
    } else if (e.keyCode === 37) {
      activeObject.set('left', activeObject.left - 2);
      activeObject.setCoords();
      this.controller.canvas.renderAll();
      return true;
    } else if (e.keyCode === 39) {
      activeObject.set('left', activeObject.left + 2);
      activeObject.setCoords();
      this.controller.canvas.renderAll();
      return true;
    }
    if (this.controller.onModified) {
      this.controller.onModified(activeObject);
    }
    return true;
  };

  public enableZooming({e:ev}):boolean {
    // TODO in Mac, enable zoom with 'cmd' instead of ctrlKey
    return this.controller.zoomEnabled && ev.ctrlKey
  }

  /**
   * Zoom at mouse wheel event
   *
   * @param {FabricEvent<WheelEvent>} opt
   * @returns
   */
  public mousewheel = (opt: FabricEvent) => {
    const event = opt as FabricEvent<WheelEvent>;
    if (!this.enableZooming(opt)){
      return;
    }
    const delta = event.e.deltaY;
    let zoomRatio = this.controller.canvas.getZoom();
    if (delta > 0) {
      zoomRatio -= 0.05;
    } else {
      zoomRatio += 0.05;
    }
    this.controller.zoomHandler.zoomToPoint(
      new fabric.Point(this.controller.canvas.getWidth() / 2, this.controller.canvas.getHeight() / 2),
      zoomRatio,
    );
    event.e.preventDefault();
    event.e.stopPropagation();
  };

  /**
   * Mouse down event on object
   *
   * @param {FabricEvent<MouseEvent>} opt
   * @returns
   */
  public mousedown = (opt: FabricEvent) => {

    const event = opt as FabricEvent<MouseEvent>;

    if (event.e.altKey && this.controller.editable && !this.controller.interactionHandler.isDrawingMode()) {
      this.controller.interactionHandler.grab();
      this.panning = true;
      return;
    }

    if (this.interactionMode === InteractionModes.PASTE_STYLE) {
      const { target } = opt;
      this.controller.paste_style(target);
      return;
    }

    if (!this.controller.editable)
      return;

    const { target } = event;

    this.controller.guidelineHandler.viewportTransform = this.controller.canvas.viewportTransform;
    this.controller.guidelineHandler.zoom = this.controller.canvas.getZoom();

    switch(this.interactionMode){
      case InteractionModes.ADD_SPRITE:
        if(!target || target == this.controller.workarea){
          //TODO add sprite
          this.controller.interactionHandler.selection();
        }
        break;
      case InteractionModes.GRAB:
        this.panning = true;
        break;

      case InteractionModes.SPOT:
        this.controller.gameHandler.addSpot(event);
        break;

      case InteractionModes.LADDER:
        this.controller.gameHandler.addLadder(event);
        break;

      case InteractionModes.WALL:
        this.controller.gameHandler.addWall(event);
        break;

      case InteractionModes.SELECTION:
        if (target && target.superType === 'link') {
          target.set({ stroke: target.selectFill || 'green', });
        }
        this.controller.prevTarget = target;
        break;

      case InteractionModes.POLYGON:
        if (target && this.controller.pointArray.length && target.id === this.controller.pointArray[0].id) {
          this.controller.drawingHandler.polygon.generate(this.controller.pointArray);
        } else {
          this.controller.drawingHandler.polygon.addPoint(event);
        }
        break;

      case InteractionModes.LINE:
        if (this.controller.pointArray.length && this.controller.activeLine) {
          this.controller.drawingHandler.line.generate(event);
        } else {
          this.controller.drawingHandler.line.addPoint(event);
        }
        break;
    }
  };

  /**
   * Mouse move event on canvas
   *
   * @param {FabricEvent<MouseEvent>} opt
   * @returns
   */
  public mousemove = (opt: FabricEvent) => {
    const event = opt as FabricEvent<MouseEvent & { movementX: number; movementY: number }>;

    if (this.interactionMode === InteractionModes.GRAB && this.panning) {
      if(opt.e instanceof TouchEvent){
        const touch=opt.e.touches[0];
        if(!previousTouch){
          previousTouch = touch;
        }
        event.e.movementX = touch.screenX - previousTouch.screenX;
        event.e.movementY = touch.screenY - previousTouch.screenY;
        previousTouch = touch;
        console.log('move',{movementX:event.e.movementX, movementY:event.e.movementY},previousTouch.screenX, previousTouch.screenY);
      }
      this.controller.interactionHandler.moving(event.e);
      this.controller.frameHandler?.moving(event.e);
      this.controller.canvas.requestRenderAll();
    }

    if (!this.controller.editable && event.target) {
      if (event.target.superType === 'element') {
        return;
      }
      if (event.target.id !== 'workarea') {
        if (event.target !== this.controller.target) {
          this.controller.tooltipHandler.show(event.target);
        }
      } else {
        this.controller.tooltipHandler.hide(event.target);
      }
    }

    if (this.interactionMode === InteractionModes.POLYGON) {
      if (this.controller.activeLine && this.controller.activeLine.class === 'line') {
        const pointer = this.controller.canvas.getPointer(event.e);
        this.controller.activeLine.set({ x2: pointer.x, y2: pointer.y });
        const points = this.controller.activeShape.get('points');
        points[this.controller.pointArray.length] = { x: pointer.x, y: pointer.y, };
        this.controller.activeShape.set({ points, });
        this.controller.canvas.requestRenderAll();
      }
    } else if (this.interactionMode === InteractionModes.LINE) {
      if (this.controller.activeLine && this.controller.activeLine.class === 'line') {
        const pointer = this.controller.canvas.getPointer(event.e);
        this.controller.activeLine.set({ x2: pointer.x, y2: pointer.y });
      }
      this.controller.canvas.requestRenderAll();
    } else if (this.interactionMode === InteractionModes.ARROW) {
      if (this.controller.activeLine && this.controller.activeLine.class === 'line') {
        const pointer = this.controller.canvas.getPointer(event.e);
        this.controller.activeLine.set({ x2: pointer.x, y2: pointer.y });
      }
      this.controller.canvas.requestRenderAll();
    } else if (this.interactionMode === InteractionModes.LINK) {
      if (this.controller.activeLine && this.controller.activeLine.class === 'line') {
        const pointer = this.controller.canvas.getPointer(event.e);
        this.controller.activeLine.set({ x2: pointer.x, y2: pointer.y });
      }
      this.controller.canvas.requestRenderAll();
    }
    return;
  };

  /**
   * Dblclick event on canvas
   *
   * @param {FabricEvent<MouseEvent>} opt
   * @returns
   */
  public dblclick = (opt: FabricEvent) => {
    const event = opt as FabricEvent<MouseEvent>;
    const { target, e } = event;
    if(['deco', 'platform_line', 'wall', 'ladder'].includes(target?.glitch_type)){
      this.store.dispatch(setOpenProperties(true))
    }
    if(target.glitch_type == 'signpost'){
      this.store.dispatch(setOpenDoors(true))
    }
  };

  /**
   * Mouse up event on canvas
   *
   * @param {FabricEvent<MouseEvent>} opt
   * @returns
   */
  public mouseup = (opt: FabricEvent) => {
    const event = opt as FabricEvent<MouseEvent>;
    if (this.interactionMode === InteractionModes.GRAB) {
      this.panning = false;
      return;
    }
    const { target, e } = event;
    if (this.controller.editable && this.controller.guidelineOption.enabled) {
      this.controller.guidelineHandler.verticalLines.length = 0;
      this.controller.guidelineHandler.horizontalLines.length = 0;
    }
    this.controller.canvas.renderAll();
  };

  /**
   * Mouse out event on canvas
   *
   * @param {FabricEvent<MouseEvent>} opt
   */
  public mouseout = (opt: FabricEvent) => {
    const event = opt as FabricEvent<MouseEvent>;
    if (!event.target) {
      this.controller.tooltipHandler.hide();
    }
  };

  /**
   * Selection event event on canvas
   *
   * @param {FabricEvent} opt
   */
  public selection = (opt: FabricEvent) => {
    let target = opt.target;
    target = target || this.controller.canvas.getActiveObject();
    if (target && target.type === 'activeSelection') {
      target.set({ ...this.controller.activeSelectionOption, });
    }
    if(!target){
      this.store.dispatch(setOpenProperties(false))
      this.store.dispatch(setOpenDoors(false))
    }
    if (this.controller.onSelect) {
      this.controller.onSelect(target);
    }
  };

  /**
   * Called resize event on canvas
   *
   * @param {number} nextWidth
   * @param {number} nextHeight
   * @returns
   */
  public resize = (nextWidth: number, nextHeight: number) => {
    this.controller.canvas.setWidth(nextWidth).setHeight(nextHeight);
    this.controller.canvas.setBackgroundColor(
      this.controller.canvasOption.backgroundColor,
      this.controller.canvas.renderAll.bind(this.controller.canvas),
    );
    if (!this.controller.workarea) {
      return;
    }
    const diffWidth = nextWidth / 2 - this.controller.width / 2;
    const diffHeight = nextHeight / 2 - this.controller.height / 2;
    this.controller.width = nextWidth;
    this.controller.height = nextHeight;
    if (this.controller.workarea.layout === 'fixed') {
      this.controller.canvas.centerObject(this.controller.workarea);
      this.controller.workarea.setCoords();
      if (this.controller.gridOption.enabled) {
        return;
      }
      this.controller.canvas.getObjects().forEach((obj: FabricObject) => {
        if (obj.id !== 'workarea') {
          const left = obj.left + diffWidth;
          const top = obj.top + diffHeight;
          obj.set({
            left,
            top,
          });
          obj.setCoords();
          if (obj.superType === 'element') {
            const { id } = obj;
            const el = this.controller.elementHandler.findById(id);
            // update the element
            this.controller.elementHandler.setPosition(el, obj);
          }
        }
      });
      this.controller.canvas.requestRenderAll();
      return;
    }
    if (this.controller.workarea.layout === 'responsive') {
      const { scaleX } = this.controller.workareaHandler.calculateScale();
      const center = this.controller.canvas.getCenter();
      const deltaPoint = new fabric.Point(diffWidth, diffHeight);
      this.controller.canvas.relativePan(deltaPoint);
      this.controller.zoomHandler.zoomToPoint(new fabric.Point(center.left, center.top), scaleX);
      return;
    }
    const scaleX = nextWidth / this.controller.workarea.width;
    const scaleY = nextHeight / this.controller.workarea.height;
    const diffScaleX = nextWidth / (this.controller.workarea.width * this.controller.workarea.scaleX);
    const diffScaleY = nextHeight / (this.controller.workarea.height * this.controller.workarea.scaleY);
    this.controller.workarea.set({
      scaleX,
      scaleY,
    });
    this.controller.canvas.getObjects().forEach((obj: any) => {
      const { id } = obj;
      if (obj.id !== 'workarea') {
        const left = obj.left * diffScaleX;
        const top = obj.top * diffScaleY;
        const newScaleX = obj.scaleX * diffScaleX;
        const newScaleY = obj.scaleY * diffScaleY;
        obj.set({
          scaleX: newScaleX,
          scaleY: newScaleY,
          left,
          top,
        });
        obj.setCoords();
        if (obj.superType === 'element') {
          const video = obj as VideoObject;
          const { width, height } = obj;
          const el = this.controller.elementHandler.findById(id);
          this.controller.elementHandler.setSize(el, obj);
          //if (video.player) {
          //  //@ts-ignore
          //  video.player.setPlayerSize(width, height);
          //}
          this.controller.elementHandler.setPosition(el, obj);
        }
      }
    });
    this.controller.canvas.renderAll();
  };

  /**
   * Paste event on canvas
   *
   * @param {ClipboardEvent} e
   * @returns
   */
  public paste = (e: ClipboardEvent) => {
    if (this.controller.canvas.wrapperEl !== document.activeElement)
      return false;

    if (e.preventDefault)
      e.preventDefault();

    if (e.stopPropagation)
      e.stopPropagation();

    const clipboardData = e.clipboardData;
    if (!clipboardData.types.length)
      return

    clipboardData.types.forEach(async (clipboardType: string) => {
      if (clipboardType === 'text/plain') {
        const textPlain = clipboardData.getData('text/plain');
        try {
          const objects = JSON.parse(textPlain);
          const {
            gridOption: { grid = 10 },
            isCut,
          } = this.controller;
          const padding = isCut ? 0 : grid;
          if (objects && Array.isArray(objects)) {
            const filteredObjects = objects.filter(obj => obj !== null);
            if (filteredObjects.length === 1) {
              const obj = filteredObjects[0];
              if (typeof obj.cloneable !== 'undefined' && !obj.cloneable) {
                return;
              }
              obj.left = obj.properties.left + padding;
              obj.top = obj.properties.top + padding;
              const createdObj = await this.controller.add(obj, false, true);
              this.controller.canvas.setActiveObject(createdObj as FabricObject);
              this.controller.canvas.requestRenderAll();
            } else {
              const nodes = [] as any[];
              const targets = [] as any[];
              objects.forEach(obj => {
                if (!obj) {
                  return;
                }
                if (obj.superType === 'link') {
                  obj.fromNodeId = nodes[obj.fromNodeIndex].id;
                  obj.toNodeId = nodes[obj.toNodeIndex].id;
                } else {
                  obj.left = obj.properties.left + padding;
                  obj.top = obj.properties.top + padding;
                }
                const createdObj = this.controller.add(obj, false, true);
                if (obj.superType === 'node') {
                  nodes.push(createdObj);
                } else {
                  targets.push(createdObj);
                }
              });
              const activeSelection = new fabric.ActiveSelection(nodes.length ? nodes : targets, {
                canvas: this.controller.canvas,
                ...this.controller.activeSelectionOption,
              });
              this.controller.canvas.setActiveObject(activeSelection);
              this.controller.canvas.requestRenderAll();
            }
            if (this.controller.transactionHandler.active) {
              this.controller.transactionHandler.save('paste');
            }
            this.controller.isCut = false;
            this.controller.copy();
          }
        } catch (error) {
          console.error(error);
          // const item = {
          //     id: uuv4id(),
          //     type: 'textbox',
          //     text: textPlain,
          // };
          // this.handler.add(item, true);
        }
      } else if (clipboardType === 'text/html') {
        // Todo ...
        // const textHtml = clipboardData.getData('text/html');
        // console.log(textHtml);
      } else if (clipboardType === 'Files') {
        // Array.from(clipboardData.files).forEach((file) => {
        //     const { type } = file;
        //     if (type === 'image/png' || type === 'image/jpeg' || type === 'image/jpg') {
        //         const item = {
        //             id: v4(),
        //             type: 'image',
        //             file,
        //             superType: 'image',
        //         };
        //         this.handler.add(item, true);
        //     } else {
        //         console.error('Not supported file type');
        //     }
        // });
      }
    });
    return true;
  };

  /**
   * Keydown event on document
   *
   * @param {KeyboardEvent} e
   */
  public keydown = (e: KeyboardEvent) => {
    if (!Object.keys(this.controller.keyEvent).length) {
      return;
    }
    const { clipboard } = this.controller.keyEvent;
    if (this.controller.interactionHandler.isDrawingMode()) {
      if (this.controller.shortcutHandler.isEscape(e)) {
        // @ts-ignore
        if ([InteractionModes.POLYGON, InteractionModes.LINE].includes(this.interactionMode)) {
          this.controller.drawingHandler.handleFinishDrawing();
        } /*else if (this.interactionMode === 'arrow') {
          this.controller.drawingHandler.arrow.finish();
        } else if (this.interactionMode === 'link') {
          this.controller.linkHandler.finish();
        }*/
      }
      return;
    }
    if (this.controller.shortcutHandler.isW(e)) {
      this.keyCode = e.keyCode;
      this.controller.interactionHandler.grab();
      return;
    }
    if (e.altKey && this.controller.editable) {
      this.controller.interactionHandler.grab();
      return;
    }
    if (this.controller.shortcutHandler.isEscape(e)) {
      if (this.interactionMode === InteractionModes.SELECTION) {
        this.controller.canvas.discardActiveObject();
        this.controller.canvas.renderAll();
      }
      this.controller.tooltipHandler.hide();
    }
    if (this.controller.canvas.wrapperEl !== document.activeElement) {
      return;
    }
    if (this.controller.editable) {
      if (this.controller.shortcutHandler.isQ(e)) {
        this.keyCode = e.keyCode;
      } else if (this.controller.shortcutHandler.isDelete(e)) {
        this.controller.remove();
      } else if (this.controller.shortcutHandler.isArrow(e)) {
        this.arrowmoving(e);
      } else if (this.controller.shortcutHandler.isCtrlA(e)) {
        e.preventDefault();
        this.controller.selectAll();
      } else if (this.controller.shortcutHandler.isCtrlC(e)) {
        e.preventDefault();
        this.controller.copy();
      } else if (this.controller.shortcutHandler.isCtrlV(e) && !clipboard) {
        e.preventDefault();
        this.controller.paste();
      } else if (this.controller.shortcutHandler.isCtrlX(e)) {
        e.preventDefault();
        this.controller.cut();
      } else if (this.controller.shortcutHandler.isCtrlZ(e)) {
        e.preventDefault();
        this.controller.transactionHandler.undo();
      } else if (this.controller.shortcutHandler.isCtrlY(e)) {
        e.preventDefault();
        this.controller.transactionHandler.redo();
      } else if (this.controller.shortcutHandler.isPlus(e)) {
        e.preventDefault();
        this.controller.zoomHandler.zoomIn();
      } else if (this.controller.shortcutHandler.isMinus(e)) {
        e.preventDefault();
        this.controller.zoomHandler.zoomOut();
      } else if (this.controller.shortcutHandler.isO(e)) {
        e.preventDefault();
        this.controller.zoomHandler.zoomOneToOne();
      } else if (this.controller.shortcutHandler.isP(e)) {
        e.preventDefault();
        this.controller.zoomHandler.zoomToFit();
      }
      return;
    }
    return;
  };

  /**
   * Key up event on canvas
   *
   * @param {KeyboardEvent} _e
   */
  public keyup = (e: KeyboardEvent) => {
    if (this.controller.interactionHandler.isDrawingMode()) {
      return;
    }
    if (!this.controller.shortcutHandler.isW(e)) {
      this.controller.interactionHandler.selection();
    }
  };

  /**
   * Context menu event on canvas
   *
   * @param {MouseEvent} e
   */
  public contextmenu = (e: MouseEvent) => {
    e.preventDefault();
    if (this.controller.editable && this.controller.onContext) {
      const target = this.controller.canvas.findTarget(e, false) as FabricObject;
      if (target && target.type !== 'activeSelection') {
        this.controller.select(target);
      }
      this.controller.contextmenuHandler.show(e, target);
    }
  };

  /**
   * Mouse down event on canvas
   *
   * @param {MouseEvent} _e
   */
  public onmousedown = (_e: MouseEvent) => {
    this.controller.contextmenuHandler.hide();
  };

  public touchend = (_e: TouchEvent) => {
    previousTouch= null;
    console.log('touchend')
  }
  public touchstart = (_e: TouchEvent) => {
    previousTouch= _e.touches[0];
    console.log('touchstart', {screenX:previousTouch.screenX, screenY:previousTouch.screenY});
  }
}

export default EventHandler;
