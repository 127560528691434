import { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ArtworkCard from './card'
import React from "react";
import axios from 'axios';
import throttle from "lodash/throttle";

type ArtworkProps =  {
  url: string,
  id: number, 
  status: string, 
  metadata: {
    id: string,
    sfw: "Yes"|"No",
    slug: string,
    tags: string[],
    title: string,
    author: string,
    created: string,
    svg_url: string,
    description: string,
  }
}

class ArtworkIndex extends Component<{xs:number,md:number, onClick?:(x:ArtworkProps)=>void}, {query:string,loading:boolean,message:string, artwork:ArtworkProps[], page:number, prevY:number}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}

  cancel:any;
  observer: IntersectionObserver;
  loadingRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      artwork: [],
      query: '',
      loading: true,
      message: '',
      page: 1,
      prevY: 0
    };
  }
  
  componentWillUnmount(){
    if(this.cancel)
      this.cancel.cancel();
  }

  handleOnInputChange = (event) => {
    if(!event.target)
      return;
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, artwork: [], loading: false, message: '' } );
    } else {
      this.setState({ query, artwork: [], loading: true, message: '', page: 1}, () => {
        this.fetchSearchResults(this.state.page, query);
      });
    }
  }

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/artwork/search.json?query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length ? 'There are no more search results. Please try a new search.' : '';

        this.setState({
          artwork: this.state.artwork.concat(res.data),
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if(axios.isCancel(error)){
          console.log('canceled');
          return;
        }

        if (error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };

  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver( this.handleObserver.bind(this), options);
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.fetchSearchResults(this.state.page+1, this.state.query);
      this.setState({ ...this.state, page: this.state.page+1});
    }
    this.setState({ ...this.state, prevY: y });
  }

  render () {
    const loadingCSS = {
      height: "100px",
      margin: "30px"
    };

    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

    return (
      <>

      <Row>
        <div className="col-12 col-md-6 offset-md-3 my-4">
          <input
            className="form-control form-control-lg"
            type="text"
            value={this.state.query}
            id="search-input"
            placeholder="Search..."
            onChange={this.handleOnInputChange}
          />
        </div>
        </Row>

        <Row>
          {(this.state.artwork || []).map(artwork => (
            <Col xs={this.props.xs} md={this.props.md} key={artwork.id}>
              <ArtworkCard artwork={artwork} onClick={this.props.onClick}/>
            </Col>
          ))}
        </Row>

        <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
          <span style={loadingTextCSS}>Loading...</span>
        </div>
    </>
  )
  }

}

export {ArtworkIndex, ArtworkProps}

