import React, { Component } from "react";
import Card  from 'react-bootstrap/Card';

type TextProps = {
  id: string,
  name: string,
  description: string,
  content: string,
}

class Text extends Component<{text:TextProps , onClick?: Function},{isMouseInside:boolean}> { 

  constructor(props:{text:TextProps , onClick: Function}) {
    super(props)
    this.state = { isMouseInside: false };
  }

  render() {
    return (<Card>
      <Card.Header>
        <strong>{this.props.text.name}</strong>
      </Card.Header>

      <Card.Body onClick={(e) =>this.props.onClick && this.props.onClick()}>
        <Card.Text dangerouslySetInnerHTML={{__html: this.props.text.content}}></Card.Text>
      </Card.Body>

      </Card>)
  }
}

export {Text, TextProps}
