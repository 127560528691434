import { useState, useEffect, useRef, useCallback } from 'react';
import FilePlayer from 'react-player/file';
import YoutubePlayer from 'react-player/youtube';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const CONFIG = {
  youtube: { playerVars: { autoplay: true } },
};

const VideoPreview = ({
  className,
  file,
  fps,
  height,
  startFrame,
  pauseFrame,
  duration,
  isPlaying,
  isProcessing,
  onReady,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [canPlay, setCanPlay] = useState(false);
  const playerRef = useRef(null);
  const [maxTime, setMaxTime] = useState((startFrame + duration) / fps);
  const [initialSeek, setInitialSeek] = useState(startFrame > 0);
  const lastSeek = useRef(Date.now());
  const hasDuration = useRef(false);

  useEffect(() => {
    setMaxTime((startFrame + duration) / fps);
  }, [startFrame, duration, fps]);

  useEffect(() => {
    if (playerRef.current && initialSeek) {
      playerRef.current.seekTo(startFrame / fps, 'seconds');
      setInitialSeek(false);
    }
  }, [initialSeek, startFrame, fps]);

  useEffect(() => {
    if (playerRef.current && pauseFrame !== null) {
      playerRef.current.seekTo(pauseFrame / fps, 'seconds');
    }
  }, [pauseFrame, fps]);

  const onProgress = useCallback(
    ({ playedSeconds }) => {
      if (playedSeconds > maxTime) {
        lastSeek.current = Date.now();
        playerRef.current.seekTo(startFrame / fps, 'seconds');
      }
    },
    [maxTime, startFrame, fps]
  );

  let shouldPlay = isPlaying;
  if (canPlay && !hasDuration.current) {
    shouldPlay = true;
  }

  return (
    <div className={className} style={{ height }}>
      {!isProcessing && (
        file.file.includes('youtube') ? 
        <YoutubePlayer
          controls={false}
          ref={playerRef}
          url={file.file}
          loop={true}
          playing={shouldPlay}
          volume={0}
          height={height}
          width="100%"
          muted={true}
          style={{}}
          progressInterval={50}
          playsinline={true}
          config={CONFIG.youtube}
          onReady={() => {
            setIsLoading(false);
            setCanPlay(true);
          }}
          onDuration={duration => {
            hasDuration.current = true;
            onReady(Math.floor(duration * fps));
          }}
          onProgress={onProgress}
        /> : 
        <FilePlayer
          controls={false}
          ref={playerRef}
          url={file.file}
          loop={true}
          playing={shouldPlay}
          volume={0}
          height={height}
          width="100%"
          muted={true}
          style={{}}
          progressInterval={50}
          playsinline={true}
          onReady={() => {
            setIsLoading(false);
            setCanPlay(true);
          }}
          onDuration={duration => {
            hasDuration.current = true;
            onReady(Math.floor(duration * fps));
          }}
          onProgress={onProgress}
        />)}
      {(isLoading || isProcessing) && <CircularProgress />}
    </div>
  );
};

//VideoPreview.propTypes = {
//  className: PropTypes.string,
//  file: PropTypes.object.isRequired,
//  fps: PropTypes.number,
//  height: PropTypes.number,
//  startFrame: PropTypes.number.isRequired,
//  pauseFrame: PropTypes.number,
//  duration: PropTypes.number.isRequired,
//  isPlaying: PropTypes.bool.isRequired,
//  isProcessing: PropTypes.bool,
//  onReady: PropTypes.func.isRequired,
//};

export default VideoPreview;
