import React from 'react';
import {fabric} from 'fabric';
import { GlitchGame } from './space';
import { sortBy } from 'underscore';
import { drawItem, loadFabricImage } from './utils';

const CANVAS_ID = "un-canvas";

class FabricGlitch extends React.Component<{match?:any},{gameData:GlitchGame}> {

  state ={
    gameData:null
  }

  other: HTMLDivElement;
  canvasEl: HTMLCanvasElement;

  async componentDidMount(){

    const spaceId = this.props.match?.params?.id || 'GM410QQ0CHARO';

    const gameData:GlitchGame = await $.get('/locations/' + spaceId + '.json');

    this.setState({gameData});

    const width= gameData.dynamic.r - gameData.dynamic.l;
    const height= gameData.dynamic.b - gameData.dynamic.t;

    var canvas = new fabric.Canvas(this.canvasEl, {enableRetinaScaling: false});

    console.log('canvas',canvas)

    const layerId='middleground';
    const layer = gameData.dynamic.layers[layerId];

    canvas.setHeight(height);
    canvas.setWidth(width);

    const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];

    for(let item of sortBy(layer.decos,(x)=>x.z)){

      const image:fabric.Image = await loadFabricImage('/glitch/assets/' + item.filename + '.svg');
      const img:HTMLImageElement = image.getElement() as HTMLImageElement;
      const scaleX= item.w/img.naturalWidth;
      const scaleY= item.h/img.naturalHeight;

      image.set({
        item: item,
        originX: 'center',
        originY: 'bottom',
        scaleX,
        scaleY,
        left: item.x + offset_x,
        top:  item.y + offset_y,
        angle: item.r,
        flipX: item.h_flip
      } as any);
      canvas.add(image)
      canvas.calcOffset();
    }

    var selection = new fabric.ActiveSelection(canvas.getObjects(), { canvas });
    canvas.setActiveObject(selection);   //selecting all objects...
    canvas.requestRenderAll();
    canvas.discardActiveObject();

    (window as any).temp1 = canvas;

  }

  render(){
    return <div style={{overflow: 'scroll'}}>
      <canvas id={CANVAS_ID} ref={(e) => this.canvasEl=e}></canvas>
    </div>
  }
}

export {FabricGlitch}
