import React, { useState, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { primary_40, white } from 'stylesheets/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { MapController } from 'maps/controller';
import { ScriptAttributes, Script, parseScriptV2, fromTimeline } from 'models/script';
import './edit_script.scss';
import { store } from 'redux/store';
import { MAP_EDIT_SCRIPT } from 'constants/actionTypes';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ScriptExecutor } from 'models/script_executor';
import { MODES } from 'components/world/constants';
import { connect } from 'react-redux';
import { addCharacter } from 'components/world/drawers';
import { sweet_prompt } from 'utils/prompt';
import { drawer_width } from 'components/create_toolbox/create_toolbox_drawer';

const SLOT_MS=250;

function parse(text,model) {
  try {
    const tl = parseScriptV2(text);
    model.set('version', 2);
    model.set('script', tl);
    return true
  } catch (error) {
    console.log(error);
    return false;
  }
}

function copyTo(elem:HTMLElement, textarea:HTMLTextAreaElement){
  textarea.value = textarea.value.trim() + "\n" + elem.querySelector('strong').innerHTML
}

function EditScriptComponent(props:{mode:string, onClose:Function, controller: MapController, script:Script, player:ScriptExecutor}){

  const [players, setPlayers] = useState(props.script.players);
  const [value, setValue] = useState(props.script.textTimelineV2());
  const [is_valid, setValid] = useState(true);
  const [is_open_help, setOpenHelp] = useState(false);
  const textareaEl = useRef(null);

  async function putCharacter(controller) {
    const {value:name}=await sweet_prompt('Give it name:');

    if(typeof name != 'string' || !name.length)
      return null;

    const view = await addCharacter(controller, name, props.player.where);
    const newPlayers = {...players, [name]: view.model.id};
    setPlayers(newPlayers)
    props.script.players=newPlayers;
  }

  if(!props.script)
    return null

  return <Drawer
    className="edit-script-drawer"
    open={true}
    onClose={(e) => props.onClose()}
    transitionDuration={0}
    anchor='left'
    variant='persistent'
    PaperProps={{ style: { position: 'absolute', width: '30rem', background: primary_40, color: white, left: props.mode == MODES.Normal ? 0 : drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    <List>
      <ListItem button onClick={e=>{store.dispatch({type: MAP_EDIT_SCRIPT, script: undefined})}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

    <div className="row">
      <div className="col-12">
        <a className="btn btn-outline-white" onClick={(e)=>setOpenHelp(!is_open_help)}>Ayuda</a>
        <a className="btn btn-outline-white" onClick={(e)=>props.player.play2()}><i className="fal fa-play"></i></a>
        <a className="btn btn-outline-white" onClick={(e)=>props.player.stop()}><i className="fal fa-stop"></i></a>
      </div>

      <div className={`col-12 ${is_open_help ? '' : 'collapse'}`}>
        <p>Use this text field to write down the actions your characters will do, in the form of a script.</p>
        <p>Like in a theater script, you must write the name of an actor, a colon, and each of the actions she will perform, one per line, starting with a dash.</p>
        <p>Here's an example:</p>
        <p className="mono" onClick={(e)=>copyTo(e.currentTarget as HTMLElement, textareaEl.current)}>
          <strong>{Object.keys(props.script.players)[0]}:</strong>
          <MouseOverPopover className="mr-2 float-right" text={<i className="fa fa-question"></i>}><strong>The character that's going to be performing</strong></MouseOverPopover>
        </p>
        <p className="mono" onClick={(e)=>copyTo(e.currentTarget as HTMLElement, textareaEl.current)}>
          <strong>- say "Hola, que tal ?"</strong>
          <MouseOverPopover className="mr-2 float-right" text={<i className="fa fa-question"></i>}><strong>The character will say "Hola que tal"</strong></MouseOverPopover>
        </p>
        <p className="mono" onClick={(e)=>copyTo(e.currentTarget as HTMLElement, textareaEl.current)}>
          <strong>- walk 10, 5</strong>
          <MouseOverPopover className="mr-2 float-right" text={<i className="fa fa-question"></i>}><strong>The character will walk 10 pixels to the right, and 5 upwards</strong></MouseOverPopover>
        </p>
        <p className="mono" onClick={(e)=>copyTo(e.currentTarget as HTMLElement, textareaEl.current)}>
          <strong>- show_web_resource "https://www.youtube.com/watch?v=v8lPRLF7Vh4"</strong>
          <MouseOverPopover className="mr-2 float-right" text={<i className="fa fa-question"></i>}><strong>The character show an iframe with the contents of the URL</strong></MouseOverPopover>
        </p>
      </div>

    </div>

    <div className="row">
      <div className="col-12">
        {Object.keys(players).map((player) => {
          return <p>{player}</p>
        })}
        <a className="btn btn-outline-white" onClick={(e)=>putCharacter(props.controller)}>Add player</a>
      </div>

      <div className="col-12">
        <textarea ref={textareaEl} style={{background: is_valid ? 'lightgreen' : 'lightcoral'}} onChange={(e)=> {setValue(e.target.value); setValid(parse(e.target.value, props.script))}} value={value}></textarea>
      </div>

      <div className="col-12">
        <a className="btn btn-outline-white" onClick={(e)=>props.script.save()}>Save</a>
        <a className="btn btn-outline-white float-right" onClick={(e)=>props.onClose()}>Cancel</a>
      </div>
    </div>

  </Drawer>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

function MouseOverPopover(props:{text:JSX.Element, children:JSX.Element, className:string}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <span className={props.className}>
      <span
        style={{display: 'inline-block'}}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.text}
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper, }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.children}
      </Popover>
    </span>
  );
}

const mapStateToProps = ({map}, ownProps) => { 
  return {
    ...ownProps, 
    mode: map.mode,
  }
}

const mapDispatchToProps = (dispatch) => ({
});


const EditScript = connect(mapStateToProps,mapDispatchToProps)(EditScriptComponent)

export default EditScript;
