import CanvasController from './CanvasController';
import BaseHandler from './BaseHandler';
import {FabricEvent, FabricObject} from '../utils';
import { v4 } from 'uuid';
import {linesStyles, loadFabricImage2 } from 'components/glitch/utils';
import {GlitchAsset} from 'models/glitch/asset';
import {setGameData, setOpenDoors, setOpenProperties} from 'redux/reducers/editor';
import {store} from 'redux/store';
import {SpotAttrs} from 'components/actions_config';
import {GlitchSignpost} from 'components/glitch/space';

/**
 * Game Handler Class
 *
 * @class GameHandler
 */
class GameHandler extends BaseHandler {

  public handleSelectDoor(spot:SpotAttrs, name:string) {

    const obj:any = this.controller.canvas.getActiveObject();

    if(obj.glitch_type != 'signpost')return;

    const signpost = obj.item as GlitchSignpost;
    const connects = [];

    if(spot){
      connects.push({
        space_id: spot.space.uuid,
        signpost_id: spot.signpost.id,
        label: spot.signpost.name,
        x: spot.signpost.x,
        y: spot.signpost.y,
      })
    }

    obj.item = {
      ...signpost,
      name: name || signpost.name,
      connects
    }
    store.dispatch(setOpenDoors(false));
  }

  public moveItemToLayer(layerId: string) {

    const activeObject = this.controller.canvas.getActiveObject() as FabricObject;
    if (!activeObject)
      return;

    const layer = this.controller.gameData.dynamic.layers[layerId];

    const moveItem = (deco) => {
      if(deco.layer_id == layerId)
        return;

      deco.layer_id = layerId;

      deco.z_idx = layer.z*1000+deco.item.z;
    };

    // If the activeObject is a selection, move all selected objects
    if (activeObject.type === 'activeSelection') {
      const objects = activeObject.getObjects();
      objects.forEach(obj => moveItem(obj));
    } else {
      moveItem(activeObject);
    }

    this.controller.restackItems();
    this.controller.canvas.renderAll();
  }

  public old_moveItemToLayer(layerId: string) {
    const activeObject = this.controller.canvas.getActiveObject() as FabricObject;
    if (!activeObject)
      return;

    const moveItem = (item) => {
      item.layer_id = layerId;

      // now move the object to the top of its new layer
      const objects = this.controller.getObjects().filter((obj) => obj.layer_id === layerId);
      const index = objects.findIndex((obj) => obj.id === item.id);
      const next = objects[index + 1];
      const nextIndex = next ? objects.indexOf(next) : 0;
      // Theres the WorkAreaObject (the background) so position will always be +1
      // The platform lines are always on top
      this.controller.canvas.moveTo(item, nextIndex+1);
    }

    // check if activeObject isthe current selection and if it is, move all selected objects
    if(activeObject.type === 'activeSelection') {
      const objects = activeObject.getObjects();
      objects.forEach(obj => moveItem(obj));
    } else {
      moveItem(activeObject);
    }

  }


  private getMandanga(event):any{
    //const state = this.store.getState();

    //const {layerId } = state.editor;
    //const gameData = this.controller.gameData;
    const { absolutePointer, pointer } = event;
    //const { x, y } = absolutePointer;
    //const pos = pointer;
    const pos = absolutePointer;
    //const c = new fabric.Circle({
    //  radius: 5,
    //  fill: 'red',
    //  left: pos.x,
    //  top: pos.y,
    //});
    //this.controller.canvas.add(c);
    //const pos = fabric.util.transformPoint(pointer, fabric.util.invertTransform(this.controller.canvas.viewportTransform));
    //pos.x -= this.controller.canvas.viewportTransform[4];
    //pos.y -= this.controller.canvas.viewportTransform[5];

    //const layer = gameData.dynamic.layers[layerId];
    //const stageHeight = gameData.dynamic.b - gameData.dynamic.t;
    //const heightDiff = layer.h - stageHeight;
    //const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];
    const id = v4();

    return {
      id,
      item: {
        id, 
        x: pos.x,
        y: pos.y,
        w: 50,
        h: 50,
      },
      left: pos.x,
      top: pos.y,
      //option: {
      //  left: pos.x,
      //  top: pos.y,
      //}
    }
  }

  public addWall(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);

    const opts = {
      ...popts,
      type: 'wall',
      glitch_type: 'wall',
      width : 100,
      height : 100,
      item: {
        ...popts.item,
        type: 'wall'
      },
      ...linesStyles.walls
    }

    this.controller.add(opts, false, false, null, (createdObject) => {
      this.controller.interactionHandler.selection();
      this.controller.canvas.setActiveObject(createdObject);
    })

  }

  public addLadder(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);
    const opts = {
      ...popts,
      type: 'ladder',
      glitch_type: 'ladder',
      width : 100,
      height : 100,
      item: {
        ...popts.item,
        type: 'ladder'
      },
      ...linesStyles.ladders
    }

    this.controller.add(opts, false, false, null, (createdObject) => {
      this.controller.interactionHandler.selection();
      this.controller.canvas.setActiveObject(createdObject);
    })

  }

  public async addSpot(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);
    const {absolutePointer, pointer} = event;

    const {selectedSprite}:{selectedSprite:GlitchAsset} = this.store.getState().editor;
    let {left,top} = popts;
    const img = await loadFabricImage2(selectedSprite.url);
    left += img.width/2;
    //top += img.height/2;

    const decorable = {
      type: 'signpost',
      glitch_type: 'signpost',
      layer_id: 'middleground',
      element: img.getElement(),
      url: selectedSprite.url,
      item: {
        ...selectedSprite,
        ...popts.item,
        type: 'signpost',
      },
      left: absolutePointer.x,
      top: absolutePointer.y,
    }

    const createdObject = await this.controller.add(decorable, false, true, 256)

    this.controller.interactionHandler.selection();
    this.controller.canvas.setActiveObject(createdObject);
    store.dispatch(setOpenDoors(true));

    // TODO some day, recover the fact that the spot is a deco
    return;

    const opts = {
      ...popts,
      type: 'signpost',
      glitch_type: 'signpost',
      width : 50,
      height : 50,
      item: {
        ...popts.item,
        name: name,
        type: 'signpost',
      },
      ...linesStyles.signpost,
    };

    this.controller.add(opts, false, false, null, (createdObject) => {
      this.controller.interactionHandler.selection();
      this.controller.canvas.setActiveObject(createdObject);
    })

  }

  public setOpenProperties = (openProperties) => {
    store.dispatch(setOpenProperties(openProperties));
  }

  constructor(controller: CanvasController) {
    super(controller);
  }

  removeObjectById(id) {
    const newGameData = {
      ...this.controller.gameData,
      dynamic: {
        ...this.controller.gameData.dynamic,
        layers: Object.entries(this.controller.gameData.dynamic.layers).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value,
            decos: value.decos.filter((obj) => obj.id !== id),
            ladders: value.ladders.filter((i) => !i.imported_from_space_id),
            platformLines: value.platformLines.filter((i) => !i.imported_from_space_id),
            walls: value.walls.filter((i) => !i.imported_from_space_id),
          }
          return acc
        }, {})
      }
    };
    this.store.dispatch(setGameData(newGameData));
  }

  setSignPointOnDeco(selectedObject, uuid) {
    //selectedObject.item = selectedObject.item || {};
    selectedObject.item.signpoint = {
      tsid: uuid,
      space_id: uuid,
    }
  }

}

export default GameHandler;
