import { useSelector, useDispatch } from 'react-redux';
import React, {CSSProperties, useEffect, useState} from "react";
import {Button, CircularProgress, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Select, Slider, TextField, Typography} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {sdxl_url, remove_bg_url, sprite_url, spriterr_url, space_ai_generations_url, suggest_prompts_url} from "utils/urls";
import {addCallback, subscribeToResults, waitForResultWithTimeout} from "utils/channels";
import {requests as api_requests} from 'api/agent';
import {Flex} from "components/flex";
import {useDrag} from "react-dnd";
import {PromptGenerator} from "components/glitch/space";
import { addPromptSuggestions, addAiGenerations, PromptSuggestion } from 'redux/reducers/editor';
import {generateAsset} from "components/creatix/scenario";
import {T} from 'components/utils/t';
import ClearableTextField from 'components/clearable_text_field';

export const GeneratedView = ({result, onClickPrompt, onClickAsset}:any & {result:{id:string, prompt:string,url:string},onClickPrompt:(string)=>void}) => {

  const imgStyle:React.CSSProperties = {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    display: 'inline-block',
    marginRight: '10px',
    border: '1px solid black',
    borderRadius: '5px',
  };

  const url = result.url;
  const obj = {
    name: result.id,
    prompt, 
    url,
    type: 'deco',
    option: {
      maxWidth: 256
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'sprite',
    item: obj,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{name:string}>()
      const coords = monitor.getClientOffset();

      console.log('Did drag:', item, dropResult, coords);
      if(!dropResult || !coords)
        return;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const style:CSSProperties = {
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    position: isDragging ? 'fixed' : 'static',
  }

  return <div style={style}>
    <img  draggable={true} ref={drag} src={result.url} style={imgStyle}  data-id={result.id} title={result.prompt} alt={result.prompt} onClick={()=>onClickAsset?.(obj)}></img>
    <a href='#' onClick={e => {e.preventDefault(); onClickPrompt(result.prompt)}} title={result.prompt}>Use prompt</a>
  </div>
  };

export const LOCAL_STORAGE_KEY = 'generative_ai_panel_value';

export const DEFAULT_CONFIG = {
  prompt: "game asset, vector art, centered, one single water lily, on white diffuse background.",
  negativePrompt: "collection, many, multiple, tile, tilemap, tileset",
  model: 'sprite',
  numOutputs: 1,
  width: 512,
  height: 512,
}

export const getDefaultConfig = (generativePrompt:PromptGenerator, key, default_config=DEFAULT_CONFIG) => {
  let storedInLocalStorageValue;
  try{
    storedInLocalStorageValue= JSON.parse(localStorage.getItem(key)) || default_config
  }catch(e){
    storedInLocalStorageValue = DEFAULT_CONFIG
  }

  storedInLocalStorageValue.prompt = generativePrompt?.prompt || storedInLocalStorageValue.prompt;
  return storedInLocalStorageValue;
}

export const AdvancedAiForm = ({value, setValue, setModel, showAdvanced, setShowAdvanced, sizes, maxOutputs}:any) => {

  return <fieldset>
    <legend className="small" onClick={() => setShowAdvanced(!showAdvanced)} style={{ cursor: 'pointer' }}>
      {showAdvanced ? 'Hide' : 'Show'} Advanced settings
    </legend>
    {showAdvanced && (
      <FormGroup>
        <TextField label="Negative prompt" variant="outlined"
          multiline
          placeholder="collection, many, multiple, tile, tilemap, tileset"
          onChange={e => setValue({...value, negativePrompt: e.target.value})}
          value={value.negativePrompt}
        />
        <Select label="Model" variant="outlined" value={value.model} onChange={e => setModel(e.target.value)}>
          <MenuItem value="sdxl">SDXL</MenuItem>
          <MenuItem value="spriterr">Spriterr</MenuItem>
          <MenuItem value="dalle" disabled>Dalle</MenuItem>
          <MenuItem value="sprite">Sprites</MenuItem>
        </Select>

        {sizes ? 
          Object.entries(sizes).map(([k,v]) => (
            <Button key={k} variant={'outlined'} onClick={() => setValue({...value, width: v[0], height: v[1]})} color="primary" >
              {k} <small>({v[0]}x{v[1]})</small>
            </Button>
          ))
          : 
          <>
        <Typography gutterBottom>Width {value.width}</Typography>

        <Slider value={value.width} onChange={(e, v) => setValue({...value, width: v})} valueLabelDisplay="auto" step={64} max={3200} min={64} marks />
        <Typography gutterBottom>Height {value.height}</Typography>
        <Slider value={value.height} onChange={(e, v) => setValue({...value, height: v})} valueLabelDisplay="auto" step={64} max={3200} min={64} marks />
      </>}

      {maxOutputs && <>
        <Typography gutterBottom>Num Outputs</Typography>
        <Slider value={value.num_outputs} onChange={(e, v) => setValue({...value, num_outputs: v})} valueLabelDisplay="auto" step={1} max={maxOutputs} min={1} marks /></>
      }
      </FormGroup>)
    }
  </fieldset>

}

export type SuggestPromptsResponse = {
  [title: string]: {
    description: string,
    url?: string,
    prompt: string
  }
}

export const prefixPrompt = (p:string) => {
  if(!p.match(/^\s*game asset\W/i))
    return `Game asset: ${p}`
  else
    return p
}

export const generateAllAssets = (promptSuggestions:Array<PromptSuggestion>, spaceId:string, csrf_token:string):Promise<any&{uuid:string}>[] => {

  const promises = promptSuggestions.map((v) => {
    return generateAsset(spaceId, v, csrf_token)
  });

  return promises
}

export const SuggestPrompts = ({onSelectPrompt, spaceId}:any) => {

  const [showPrompts, setShowPrompts] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const prompts = useSelector((state:any) => state.editor.prompt_suggestions);

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const onLoadPrompts = async () => {
    setLoading(true);
    const url = suggest_prompts_url({space_id: spaceId});

    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({ space_id: spaceId })
      });

      const {game_assets}:{game_assets:SuggestPromptsResponse} = await resp.json();
      console.log('Got data', game_assets);

      const newPrompts:PromptSuggestion[] = Object.entries(game_assets || {}).map(([title, {prompt}]) => ({title, prompt}));

      dispatch(addPromptSuggestions(newPrompts as any))
      setLoading(false)
    }catch(e){
      console.error('Error fetching prompts', e);
      setLoading(false)
    }

  }

  const handleGenerateAll = () => {

    prompts.forEach((value) => {
      generateAsset(spaceId, value, csrf_token).then((data) => {
        dispatch(addAiGenerations([{id: data.uuid, prompt: value.prompt}]))
      }).catch((e) => {
        console.error('Error generating asset', e)
      })
    });

  }

  const openShowPrompts = (doShowPrompts) => {
    setShowPrompts(doShowPrompts);

    if(doShowPrompts && !prompts.length){
      onLoadPrompts()
    }
  }

  return <fieldset>
    <legend className="small" onClick={() => openShowPrompts(!showPrompts)} style={{ cursor: 'pointer' }}>
      {showPrompts ? 'Hide' : 'Show'} Suggestions
    </legend>

    {showPrompts && (
      <Flex flexDirection="column">
        <Flex.Item key='make-more'>
          {loading ? <CircularProgress /> : <Button onClick={(e)=>{onLoadPrompts()}} title="Load more prompts" variant="outlined" color="primary"
          >Load More Prompts</Button>}
          {prompts.length ? <Button onClick={(e)=>{handleGenerateAll()}} variant="outlined">Create all!</Button> : null}
        </Flex.Item>

        {prompts.map(({title,prompt}, i) => (
          <Flex.Item key={title} >
            <Button onClick={() => {setShowPrompts(false);onSelectPrompt(prompt)}} title={prompt}>
              {title}
            </Button>
          </Flex.Item>
        ))}
      </Flex>
    )}
  </fieldset>
}

const GenerativeAiPanel = (props) => {

  const generativePrompt = useSelector((state:any) => state.editor.generativePrompt)
  const spaceId = useSelector((state:any) => state.editor.spaceId)

  const [requests, setRequests] = useState<Array<{prompt:string, id:string}>>([]);

  const [value, setValue] = useState(getDefaultConfig(generativePrompt, LOCAL_STORAGE_KEY));
  const originalProps:PromptSuggestion[] = Object.entries(generativePrompt?.props||({} as any)).map(([title, {prompt}]) => ({title, prompt}))

  const dispatch = useDispatch();
  const results = useSelector((state:any) => state.editor.ai_generations);

  useEffect(() => {
    if(!spaceId)
      return

    api_requests.get(space_ai_generations_url({space_id: spaceId})).then((response) => {

      const r = response.reduce((acc,item) => {
        if(item.type != 'image_asset')
          return acc;

        const {uuid, urls,prompt} = item;
        return acc.concat(urls.slice(-1).map((url,_idx)=>({url, prompt, id: uuid})))
      },[]);

      dispatch(addAiGenerations(r))

      const p = response.reduce((acc,item) => {
        if((!item.suggestions) || item.type != 'suggest_prompts')
          return acc;

        const {suggestions}:{suggestions:SuggestPromptsResponse} = item as any;
        return acc.concat(Object.entries(suggestions||{}).map(([title, {prompt}]) => ({title, prompt})));
      },[])

      dispatch(addPromptSuggestions(originalProps.concat(p) as any))
    });
  }, [spaceId]);

  useEffect(() => {
    const unsubscribe = subscribeToResults('Space', spaceId, handleResult);
    return unsubscribe;
  }, [spaceId]);

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const removeBg = (url) => {
    fetch(remove_bg_url({space_id: spaceId}), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({url: url})
    }).then(response => response.json())
      .then(data => {
        console.log('Remove BG Success:', data);
        addCallback(data.uuid, handleResult);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleResult = (payload) => {
    if(payload.model == 'sdxl'){
      payload.output.forEach((url) => removeBg(url));
      return;
    }

    const newResults = [{url: payload.output.slice(-1)[0], prompt: payload.prompt, id: payload.uuid}];

    dispatch(addAiGenerations(newResults))

  }

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    const pl = JSON.stringify(value);
    localStorage.setItem(LOCAL_STORAGE_KEY,pl);

    let url;
    switch(value.model){
      case 'sdxl':
      url = sdxl_url({space_id: spaceId});
        break;
      case 'spriterr':
      url = spriterr_url({space_id: spaceId});
        break;
      default:
      url = sprite_url({space_id: spaceId});
    }

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        prompt: prefixPrompt(value.prompt), 
        params: {...value, sprite: true}})
    });

    const data = await resp.json();

    dispatch(addAiGenerations([{id: data.uuid, prompt: value.prompt}]))

    try {
      const payload = await waitForResultWithTimeout(data.uuid, 65);

      if(payload.model == 'sdxl'){
        payload.output.forEach((url) => removeBg(url));
        return;
      }

    } catch (e) {
      console.error(e);
    }
  };

  const GeneratingView = ({request }:{request:{id:string, prompt:string}}) => {
    return <CircularProgress size="100px"></CircularProgress>
  }

  const [showAdvanced, setShowAdvanced] = useState(false);

  const setModel = (v) => {
    let num_outputs=value.num_outputs;
    if(v == 'sprite'){
      num_outputs=1
    }
    setValue({
      ...value,
      model: v,
      num_outputs: num_outputs
    })
  }

  return <>
    <p className="small"><T k="panels.generative_ai_panel.help1">Input a prompt to generate new assets</T></p>
    <p className="small"><T k="panels.generative_ai_panel.help2">Drag and Drop generated assets to add them to the canvas</T></p>
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <ClearableTextField
        fullWidth={true}
        minRows={4}
        label="Your prompt"
        multiline
        variant="outlined"
        value={value.prompt}
        onChange={(e) => setValue({...value, prompt: e.target.value}) }
        placeholder="game asset, vector art, centered, one single water lily, on diffuse background."
      />

  {generativePrompt ? 
      <a className="small" href="#" onClick={(e) => { e.preventDefault(); setValue({...value, prompt: generativePrompt?.prompt})}}>Use original prompt</a> : null}
      
      <AdvancedAiForm value={value}
        setValue={setValue}
        showAdvanced={showAdvanced}
        setShowAdvanced={setShowAdvanced}
        setModel={setModel}
        maxOutputs={4}/>

      <SuggestPrompts onSelectPrompt={(prompt) => setValue({...value, prompt: prompt})} spaceId={spaceId}/>

      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>

    <Grid container spacing={3} style={{marginTop: '1rem'}}>
      {requests.map((req) => (
        <Grid item xs={4} key={req.id}>
          <GeneratingView request={req} />
        </Grid>
      ))}

      {results.map((result) => (
        result.url ? 
        <Grid item xs={4} key={result.id}>
          <GeneratedView result={result} onClickPrompt={(p) => setValue({...value, prompt: p})} />
        </Grid>
          :
        <Grid item xs={4} key={result.id}>
          <GeneratingView request={result} />
        </Grid>
      ))}
    </Grid>
  </>

};
 
export default GenerativeAiPanel;


