import BaseView from 'views/base_view';

import Swal from 'sweetalert2';
//const Swal = require('sweetalert2');

class EditorView<TModel extends Backbone.Model> extends BaseView<TModel> {

  className= ' Editor'
  copies =  {
    success_title: 'Success',
    success_subtitle: 'Success',
  }

  callback: (atts:{[attribute:string]:any}) => void;;

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    this.callback=settings.callback;
    return super.initialize(settings);
  }

  events() {
    return {
      'change input:not([type=file])': function(e) {
        const name = $(e.target).attr('name');
        this.model.set(name, $(e.target).val());
      },
      'change textarea': function(e) {
        const name = $(e.target).attr('name');
        this.model.set(name, $(e.target).val());
      },
      'dragenter .dropzone': function(e) {
        e.preventDefault();
        $(e.target).css({
          border: '1px dashed red'
        });
      },
      'dragleave .dropzone': function(e) {
        $(e.target).css({
          border: '1px dashed blue'
        });
      },
      'dragover .dropzone': function(e) {
        e.preventDefault();
      },
      'drop .dropzone': function(e) {
        var files, reader;
        ({
          originalEvent: {
            dataTransfer: {files}
          }
        } = e);
        this.file = files[0];
        reader = new FileReader();
        reader.onload = (e) => {
          return this.previewImage(e.target.result);
        };
        reader.readAsDataURL(files[0]);
        e.preventDefault();
        e.stopPropagation();
      },
      'submit': function(e) {
        e.preventDefault();
        e.stopPropagation();
        this.save().then(()=>{
          if(this.callback)
            this.callback(this.model.attributes);
        });
      }
    };
  }

  previewImage(url) {
    return console.log('preview', url);
  }

  attachmentInput() {}

  //throw 'Abstract method!'
  template() {
    this.document = [];
    this.render$(() => {
      this.form$(".form-horizontal", () => {
        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', { for: 'name' }, 'Name');
          this.div$('.col-md-8', () => {
            this.input$('.form-control', { value: this.model.get('name'), name: 'name' });
          });
        });
        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', { for: 'description' }, 'Description');
          this.div$('.col-md-8', () => {
            this.textarea$('.form-control', { name: 'description' }, () => {
              this.raw(this.model.get('description'));
            });
          });
        });
        this.render$(this.attachmentInput);
        this.div$('.form-group', () => {
          this.div$('.col-md-8.col-md-offset-4', () => {
            this.button$('.btn.btn-primary', "Enviar");
          });
        });
      });
    });
    return this.document.join('');
  }

  save() {
    const fd = this.createFormData();

    return $.ajax({
      url: this.model.url(),
      type: this.model.get('id') ? 'PUT' : 'POST',
      data: fd,
      dataType: 'json',
      processData: false,
      contentType: false
    }).done((result) => {

      this.model.set(result)

      return Swal.fire({
        icon: 'success',
        timer: 1000,
        title: this.copies.success_title,
        text: this.copies.success_subtitle
      }).then((x) => {
        this.trigger('finished');
      });
    }).fail((err, msg) => {
      return Swal.fire({
        icon: 'error',
        timer: 1000,
        title: 'Error saving !',
        text: msg
      });
    });
  }

  createFormData(): any {
    throw new Error("Method not implemented.");
  }
  render() {
    return super.render();
  }

};

export { EditorView};
