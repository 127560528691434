import { fabric } from 'fabric';
import { v4 } from 'uuid';
import Line from '../objects/Line';
import { FabricEvent, FabricObject, InteractionMode, InteractionModes } from '../utils';
//import { Arrow, Line } from '../objects';
import BaseHandler from './BaseHandler';
import CanvasController from './CanvasController';
import {linesStyles} from 'components/glitch/utils';

class DrawingHandler extends BaseHandler {

  public objectMetadata:any;
  public interactionMode: InteractionMode= InteractionModes.SELECTION;

  constructor(handler: CanvasController) {
    super(handler)
  }

  public handleStateChange(newState) {

    if(newState.editor.interactionMode == this.interactionMode)
      return;

    this.interactionMode = newState.editor.interactionMode;

    switch(this.interactionMode){
      case InteractionModes.POLYGON:
        this.polygon.init();
        break;
      case InteractionModes.LINE:
        this.line.init({glitch_type: 'platform_line', ...linesStyles.platform_lines});
        break;
      default:
        //this.finishDrawing();
        break;
    }
  }

  public handleFinishDrawing() {
    if (this.interactionMode === InteractionModes.POLYGON) {
      this.controller.drawingHandler.polygon.generate(this.controller.pointArray);
      this.controller.drawingHandler.polygon.init({glitch_type: 'platform_line', ...linesStyles.platform_lines})
    } else if (this.interactionMode === InteractionModes.LINE) {
      this.controller.drawingHandler.line.finish();
    } 
  }

  polygon = {
    init: (objectMetadata:any=null) => {
      this.objectMetadata=objectMetadata;
      this.controller.interactionHandler.drawing('polygon');
      this.controller.pointArray = [];
      this.controller.lineArray = [];
      this.controller.activeLine = null;
      this.controller.activeShape = null;
    },

    finish: () => {
      this.controller.pointArray.forEach(point => {
        this.controller.canvas.remove(point);
      });
      this.controller.lineArray.forEach(line => {
        this.controller.canvas.remove(line);
      });
      this.controller.canvas.remove(this.controller.activeLine);
      this.controller.canvas.remove(this.controller.activeShape);
      this.controller.pointArray = [];
      this.controller.lineArray = [];
      this.controller.activeLine = null;
      this.controller.activeShape = null;
      this.controller.canvas.renderAll();
      //this.controller.interactionHandler.selection();
    },

    addPoint: (opt: FabricEvent) => {
      const { e, absolutePointer } = opt;
      const { x, y } = absolutePointer;
      const circle = new fabric.Circle({
        radius: 5,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: x,
        top: y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        hoverCursor: 'pointer',
      }) as FabricObject<fabric.Circle>;

      circle.set({ id: v4(), });

      if (!this.controller.pointArray.length) {
        circle.set({ fill: 'red', });
      }
      const points = [x, y, x, y];
      const line = new fabric.Line(points, {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
      }) as FabricObject<fabric.Line>;

      line.set({ class: 'line', });

      if (this.controller.activeShape) {
        const position = this.controller.canvas.getPointer(e);
        const activeShapePoints = this.controller.activeShape.get('points') as Array<{ x: number; y: number }>;
        activeShapePoints.push({
          x: position.x,
          y: position.y,
        });
        const polygon = new fabric.Polygon(activeShapePoints, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.1,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
        });
        this.controller.canvas.remove(this.controller.activeShape);
        this.controller.canvas.add(polygon);
        this.controller.activeShape = polygon;
        this.controller.canvas.renderAll();
      } else {
        const polyPoint = [{ x, y }];
        const polygon = new fabric.Polygon(polyPoint, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.1,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
        });
        this.controller.activeShape = polygon;
        this.controller.canvas.add(polygon);
      }
      this.controller.activeLine = line;
      this.controller.pointArray.push(circle);
      this.controller.lineArray.push(line);
      this.controller.canvas.add(line);
      this.controller.canvas.add(circle);
    },

    generate: (pointArray: FabricObject<fabric.Circle>[]) => {

      const points = [] as any[];
      const id = v4();

      pointArray.forEach(point => {
        points.push({
          x: point.left,
          y: point.top,
        });
        this.controller.canvas.remove(point);
      });

      this.controller.lineArray.forEach(line => {
        this.controller.canvas.remove(line);
      });


      this.controller.canvas.remove(this.controller.activeShape).remove(this.controller.activeLine);

      const option = {
        id,
        points,
        type: 'polygon',
        stroke: 'rgba(0, 0, 0, 1)',
        strokeWidth: 6,
        fill: 'rgba(0, 0, 0, 0.25)',
        opacity: 1,
        objectCaching: !this.controller.editable,
        name: 'New polygon',
        superType: 'drawing',
        ...this.objectMetadata,
      };

      this.controller.add(option, false);
      this.controller.pointArray = [];
      this.controller.activeLine = null;
      this.controller.activeShape = null;
      this.controller.interactionHandler.selection();
    },
  };

  line = {

    init: (objectMetadata:any=null) => {
      this.objectMetadata=objectMetadata;
      this.controller.interactionHandler.drawing(InteractionModes.LINE);
      this.controller.pointArray = [];
      this.controller.activeLine = null;
    },

    finish: () => {
      this.controller.pointArray.forEach(point => {
        this.controller.canvas.remove(point);
      });
      this.controller.canvas.remove(this.controller.activeLine);
      this.controller.pointArray = [];
      this.controller.activeLine = null;
      this.controller.canvas.renderAll();
      //this.controller.interactionHandler.selection();
    },

    addPoint: (opt: FabricEvent) => {
      const { absolutePointer } = opt;
      const { x, y } = absolutePointer;
      const circle = new fabric.Circle({
        radius: 3,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: x,
        top: y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        hoverCursor: 'pointer',
      });
      if (!this.controller.pointArray.length) {
        circle.set({
          fill: 'red',
        });
      }
      const points = [x, y, x, y];
      this.controller.activeLine = new Line(points, {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
      });
      this.controller.activeLine.set({
        class: 'line',
      });
      this.controller.pointArray.push(circle);
      this.controller.canvas.add(this.controller.activeLine);
      this.controller.canvas.add(circle);
    },

    generate: (opt: FabricEvent) => {
      const { absolutePointer } = opt;
      const { x, y } = absolutePointer;
      let points = [] as number[];
      const id = v4();

      this.controller.pointArray.forEach(point => {
        points = points.concat(point.left, point.top, x, y);
        this.controller.canvas.remove(point);
      });

      this.controller.canvas.remove(this.controller.activeLine);

      const option = {
        id,
        points,
        type: 'line',
        stroke: 'rgba(0, 0, 0, 1)',
        strokeWidth: 3,
        opacity: 1,
        objectCaching: !this.controller.editable,
        name: 'New line',
        superType: 'drawing',
        ...this.objectMetadata,
      };

      this.controller.add(option, false);
      this.controller.pointArray = [];
      this.controller.activeLine = null;
      //this.controller.interactionHandler.selection();
    },
  };

}

export default DrawingHandler;
