
const omggif = require('omggif');

class Giffer {
  gif: any;
  canvas: any;
  ctx: any;
  imageData: any;
  previousData: any;

  constructor(gif_array) {
    this.gif = new omggif.GifReader(new Uint8Array(gif_array));
    this.canvas = $('<canvas>').attr({
      width: this.gif.width,
      height: this.gif.height
    })[0];
    this.ctx = this.canvas.getContext('2d');
    this.imageData = this.ctx.createImageData(this.canvas.width, this.canvas.height);
  }

  computeTimeMs():number {
    let time = 0;
    for(let i=0, nframes=this.gif.numFrames(); i<nframes; i++){
      time += this.gif.frameInfo(i).delay;
    }
    return time;
  }

  loadFrame(i, compute_layers = true) {
    const framePixels = [];

    this.gif.decodeAndBlitFrameRGBA(i, framePixels);

    const {x, y, width, height, disposal} = this.gif.frameInfo(i);

    for (let row = 0; row < height; row++){
      for (let column = 0; column < width; column++){
        const indexOffset = 4 * (x + (y * this.canvas.width));
        const j = indexOffset + (4 * (column + (row * this.canvas.width)));
        if (framePixels[j + 3]) {
          this.imageData.data[j + 0] = framePixels[j + 0];
          this.imageData.data[j + 1] = framePixels[j + 1];
          this.imageData.data[j + 2] = framePixels[j + 2];
          this.imageData.data[j + 3] = framePixels[j + 3];
        }
      }
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.putImageData(this.imageData, 0, 0);
    const dataUrl = this.canvas.toDataURL();
    if (compute_layers) {
      if (disposal === 2) {
        this.ctx.clearRect(x, y, width, height);
        this.imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
      } else if (disposal === 3) {
        this.imageData = this.ctx.createImageData(this.canvas.width, this.canvas.height);
        this.imageData.data.set(this.previousData.data);
      } else {
        this.previousData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
      }
    }
    return {x, y, width, height, disposal, dataUrl};
  }

};

export {Giffer};
