import { BaseModel } from "./base_model";
import { timestamp } from "utils/dotry";
import _ from "underscore";
import { AvatarView } from "maps/views/avatar_view";
import { Events } from "maps/map_events";
import { Script, ScriptTarget } from "./script";
import { Avatar } from "./avatar";
import { request2 } from "maps/controller";

enum PlayStates {
  PLAYING = "playing",
  PAUSED = "paused",
  FINISHED = "finished",
  READY = "ready",
}

type uuid=string;

class ScriptExecutor {

  model: Script;

  playState:PlayStates;

  timeouts: {};
  promises: {}={};
  startTime: number;
  pausedTime: any;
  where: ScriptTarget;
  promise: Promise<any>;
  dispatcher: any;

  constructor(attributes, options) {
    this.dispatcher=options.dispatcher;
    this.initialize(attributes, options);
  }

  initialize(attrs, opts) {
    this.model = attrs.model;
    this.where = attrs.where;

    // TODO implement method that gives the position of a avatar at second T
    // this method can optionally be passed some coordinates in case the
    // script was made play relative to those coordinates
    this.playState = PlayStates.READY;
    this.timeouts = {};
    this.startTime = 0;
  }

  getPosition() {
    if (this.playState === PlayStates.PAUSED) {
      return this.pausedTime;
    } else {
      return timestamp() - this.startTime;
    }
  }

  getDuration() {
    return this.model.duration();
  }

  setPosition(ms) {
    return this.pausedTime = ms;
  }

  stop() {
    this.where.script_avatars.forEach(a => {
      a.stop();
      this.cancel_replay(a.name);
    })
    this.playState = PlayStates.READY;
  }

  /* Version 2: promises */
  async play2() {

    switch (this.playState) {
      case PlayStates.PLAYING:
        console.log("Already playing");
        return false;

      case PlayStates.FINISHED:
        console.log("start over");
        break;

      case PlayStates.READY:
        console.log("starting...");
        break;

      default:
        console.log("bug: unknown state");
    }

    this.stop();
    this.remove_chars();
    await this.place_chars();

    this.startTime = timestamp();
    this.playState = PlayStates.PLAYING;

    this.promise = this.model.script2.reduce((promis, [charName, action, args]) => {
      if(this.playState != PlayStates.PLAYING)
        return;

      const ch = this.where.script_avatars.find(x => x.name === charName);
      return promis.then(()=> {
        return ch[action].apply(ch, this.processArgs(action, args))
      });
    }, Promise.resolve());

    return true;
  }

  processArgs(action, args): any[]{
    switch(action){
      case 'walk':
      case 'teletransport':
        return [this.where.x+args[0], this.where.y+args[1]];
      default:
        return args;
    }
  }

  async play() {

    switch (this.playState) {
      case PlayStates.PLAYING:
        console.log("Already playing");
        return false;

      case PlayStates.PAUSED:
        this.startTime = timestamp() - this.pausedTime;
        const roleplay = this.model.actionsAfter(this.pausedTime);
        for (let charName in roleplay) {
          const actions = roleplay[charName];
          const where = this.where;
          const ch = where.script_avatars.find(x => x.name === charName);
          this.timeouts[charName] = actions.map(([time,action,args]) => {
            const the_char= ch;
            return setTimeout(() => the_char[action].apply(the_char, args), time);
          });
        }
        this.playState = PlayStates.PLAYING;
        return false;

      case PlayStates.FINISHED:
        console.log("start over");
        break;

      case PlayStates.READY:
        console.log("starting...");
        break;

      default:
        console.log("bug: unknown state");
    }

    this.stop();
    this.remove_chars();
    await this.place_chars();

    for (let charName in this.model.script) {
      const actions = this.model.script[charName];
      const where = this.where;
      const ch = where.script_avatars.find((ch) => ch.model.get("name") === charName);
      this.cancel_replay(charName);

      this.timeouts[charName] = _(actions).map(([time,action,args]) => {
        const the_char=ch;
        return setTimeout(() =>
          the_char[action].apply(the_char, args)
        , time);
      });
    }

    this.startTime = timestamp();
    this.playState = PlayStates.PLAYING;
    return true;
  }

  get players():{[player:string]: uuid} {
    return this.model.get('players')
  }

  remove_chars() {
    this.where.script_avatars.forEach(a => a.remove())
    this.where.script_avatars=[];
  }

  async place_chars() {
    for (let name in this.players) {
      const uuid = this.players[name];
      const ava = await request2<Avatar>('Avatar', uuid)
      const ch = new AvatarView({ name, model: ava, dispatcher: this.dispatcher, scripted: {where: this.where}});
      const newLeft = this.where.x- ch.width/2;
      const newTop = this.where.y - ch.$outer.height()/2;
      this.dispatcher.trigger(Events.PLACE_AVATAR, { x: newLeft, y: newTop, who: ch, target: this.where });
    }
  }

  pause() {
    var i, len, ref, timeouts, to, who;
    ref = this.timeouts;
    for (who in ref) {
      timeouts = ref[who];
      for (i = 0, len = timeouts.length; i < len; i++) {
        to = timeouts[i];
        clearTimeout(to);
      }
    }
    this.timeouts = {};
    this.pausedTime = timestamp() - this.startTime;
    this.playState = PlayStates.PAUSED;
  }

  cancel_replay(who) {
    if (!this.timeouts[who])
      return;

    const ref = this.timeouts[who];
    for (let i = 0, len = ref.length; i < len; i++) {
      const to = ref[i];
      clearTimeout(to);
    }
    delete this.timeouts[who];
  }

  resume(){
    this.play()
  }

};


export { ScriptExecutor, PlayStates};
