import React from 'react';
import './space.scss'
import {sortBy} from 'lodash';
import {Player} from '../player';
import { parseGradients, parseFilters, drawItem, loadImage } from '../utils';
import { Space } from '../space';

class GlitchSpace extends React.Component<{history:any, match: any},{}> {

  space: Space;
  player: Player;

  componentDidUpdate(){
    if(this.space){
      const id = this.props.match?.params?.id || 'GLIERMJ93DE1H25';
      this.space.loadSpace(id)
    }
  }

  componentDidMount() {
    let id = this.props.match?.params?.id || 'GLIERMJ93DE1H25';

    if(id[0] == 'L'){
      id = 'G' + id.slice(1)
    }

    this.space = new Space();

    $('.stage').stop(true, false);
    $('.stage').fadeTo(1333, 0.1);
    $('.location').text('');
    $('.player_holder').remove();
    $('.other_player_holders').remove();
    $('.localChat ul').empty();
    $('.exits').empty();


    this.space.init(id).then(()=>{
      this.player = new Player(this.space)
      this.player.init()

    //$(window).bind('resize', () =>{
    //  try {
    //    this.stageHolderWidth = $(STAGE).width();
    //    this.stageHolderHeight = $(STAGE).height();
    //  } catch(er) {
    //  }
    //});

    })
  }

  render(){
    return (
      <div className="glitcher">

        <div className="location_holder">
          <div className="location">
          </div>
        </div>

        <div className="stage_holder">
          <div className="stage">
          </div>
        </div>

        <div className="loading_holder">
          <div className="loading">
            Loading...
            <div className="percent_holder">
              <div className="percent">
              </div>
            </div>
          </div>
        </div>

        <div className="exits">
        </div>

        <div className="debug">
        </div>

        <div className="offScreenBuffer">
        </div>

      </div>)

  }
}

export {Space, GlitchSpace}
