import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, Typography, Tooltip } from '@material-ui/core';
import {Inventory} from 'components/world/inventory';
import { MapController } from 'maps/controller';
import { primary_20, primary_40, white } from 'stylesheets/styles';
import './play_toolbox_drawer.scss'

const drawer_width=45;
const drawer_title_style ={
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};
const btn_style ={
 width: drawer_width,
 height: drawer_width,
}

export default function PlayToolboxDrawer(props:{canEdit:boolean, app: MapController}) {
  const {canEdit, app}=props;

  const [open_inventory, setOpenInventory] = useState(false);
  const [open_notebook, setOpenNotebook] = useState(false);

  return <>
  <Drawer
    className="play-toolbox-drawer play-toolbox-drawer-general"
    anchor='right'
    transitionDuration={0}
    open={true}
    PaperProps={{ style: { position: 'absolute', background: primary_20, color: white } }}
    variant="permanent"
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute' }
    }}
  >
    <List>
      <ListItem button key="inventory" onClick={e=>{setOpenInventory(!open_inventory); setOpenNotebook(false)}} style={btn_style}>
        <Tooltip title="Inventory" placement="left">
          <i className="fal fa-backpack"/>
        </Tooltip>
      </ListItem>

      <ListItem button key="notebook" onClick={e=>{setOpenNotebook(!open_notebook); setOpenInventory(false)}} style={btn_style}>
        <Tooltip title="Notebook" placement="left">
          <i className="fal fa-book"/>
        </Tooltip>
      </ListItem>

      <ListItem button key="help" onClick={e=>{console.log('Help')}} style={btn_style}>
        <Tooltip title="Help" placement="left">
          <i className="fal fa-question-circle"/>
        </Tooltip>
      </ListItem>

      <ListItem button key="comments" onClick={e=>{console.log('Comment')}} style={btn_style}>
        <Tooltip title="Give use feedback" placement="left">
          <i className="fal fa-comment-alt-smile"/>
        </Tooltip>
      </ListItem>

    </List>
  </Drawer>

  <Drawer
    className="play-toolbox-drawer-inventory"
    open={open_inventory}
    onClose={(e) => setOpenInventory(false)}
    transitionDuration={0}
    anchor='right'
    PaperProps={{ style: { position: 'absolute', width: '15rem', background: primary_40, color: white, right: drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    
    <List>
      <ListItem button onClick={e=>{setOpenInventory(!open_inventory); setOpenNotebook(false)}}>
        <i className="fal fa-chevron-right"/>
      </ListItem>

      <ListItem style={drawer_title_style}>
        Inventory
      </ListItem>
    </List>

    {app && <Inventory></Inventory>}
  </Drawer>

  <Drawer
    className="play-toolbox-drawer-notebook"
    open={open_notebook}
    onClose={(e) => setOpenNotebook(false)}
    anchor='right'
    transitionDuration={0}
    PaperProps={{ style: { position: 'absolute', background: primary_40, color: white, right: drawer_width} }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute' }
    }}
  >
    <List>
      <ListItem button onClick={e=>{setOpenNotebook(!open_notebook); setOpenInventory(false)}}>
        <i className="fal fa-chevron-right"/>
      </ListItem>

      <ListItem style={drawer_title_style}>
        Notebook
      </ListItem>

      <ListItem button>
        <i className="fal fa-book mr-2"/>
        <ListItemText primary="The Book of the rainforest" />
      </ListItem>

      <ListItem button>
        <i className="fal fa-play-circle mr-2"/>
        <ListItemText primary="Video on poisonous animals" />
      </ListItem>
    </List>
  </Drawer>
</>
}
