import {Dialog, DialogContent, DialogProps, DialogTitle, Tabs, Tab} from '@material-ui/core';
import TemplatesPanel from 'components/panels/templates_panel';
import ElementsPanel from 'components/panels/elements_panel';
import React, {useState} from 'react';

const SelectTemplate: React.FC<DialogProps & {onClose:()=>void}> = (props:any) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return <Dialog {...props} open={props.open} fullWidth={true} maxWidth="lg">
    <DialogTitle>
      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Templates" />
        <Tab label="Elements" />
      </Tabs>
    </DialogTitle>

    <DialogContent>
      {activeTab === 0 && (
        <TemplatesPanel onSelectSpace={(s)=>props.onClose()} />
      )}
      {activeTab === 1 && (
        <ElementsPanel onSelectElement={(e)=>props.onClose()} />
      )}
    </DialogContent>
  </Dialog>
};

export default SelectTemplate;
