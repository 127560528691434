import React, { useState, useRef, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { primary_40, white } from 'stylesheets/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { MapController } from 'maps/controller';
import { NpcPlacementAttributes, NpcPlacement } from 'models/npc_placement';
import './edit_anchor.scss';
import { store } from 'redux/store';
import { MAP_EDIT_SCRIPT, MAP_EDIT_ANCHOR } from 'constants/actionTypes';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MODES } from 'components/world/constants';
import { connect } from 'react-redux';
import { Events } from 'maps/map_events';
import { sweet_prompt, sweeter_prompt } from 'utils/prompt';
import { drawer_width } from 'components/create_toolbox/create_toolbox_drawer';
import { Anchor } from 'models/anchor';
import { EventableEvent } from 'components/events/render_event_object';

function EditAnchorComponent(props:{mode:string, onClose:Function, controller: MapController, anchor:Anchor}){

  const {anchor}=props;
  const [anchor_attrs, setNpcPlacementAttrs] = useState(JSON.stringify(anchor.attributes));
  const [is_valid, setValid] = useState(true);
  const [is_open_help, setOpenHelp] = useState(false);
  const textareaEl = useRef(null);
  const update = () => setNpcPlacementAttrs(JSON.stringify(anchor.attributes))

  return (anchor ? <Drawer
    className="edit-anchor-drawer"
    open={true}
    onClose={(e) => props.onClose()}
    transitionDuration={0}
    anchor='left'
    variant='persistent'
    PaperProps={{ style: { position: 'absolute', width: '30rem', background: primary_40, color: white, left: props.mode == MODES.Normal ? 0 : drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    <List>
      <ListItem button onClick={e=>{store.dispatch({type: MAP_EDIT_ANCHOR, anchor: undefined})}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

    <div className="row">
      <div className={`col-12 ${is_open_help ? '' : 'collapse'}`}>
        <p>NPCs are fun.</p>
      </div>
    </div>

    <div className="row">
      <div className="col-12 mt-4">
        <form onSubmit={e => false}>
          <fieldset>
            <legend>
              Events:
              <div className="btn btn-outline-white float-right" onClick={(e)=> {anchor.addEvent('repeat', 'play_script',['']);update()}}>Add Event</div>
            </legend>
              {anchor.events.map((ev) => {
                return <EventableEvent
                  key={`${ev.event}-${ev.command}`}
                  controller={props.controller}
                  event={ev}
                  eventable={anchor}
                  onChange={(ev)=>{anchor.updateEvent(ev.id, ev);update()}}
                  onRemove={(ev)=>{anchor.removeEvent(ev.id);update()}}
                  getScriptTarget={()=> $(`#anchor-${anchor.id}`).data('anchor')}>
                </EventableEvent>
              })}
          </fieldset>
        </form>
      </div>

      <div className="col-12 mt-4">
        <a className="btn btn-outline-white" onClick={(e)=>anchor.save()}>Save</a>
        <a className="btn btn-outline-white float-right" onClick={(e)=>props.onClose()}>Cancel</a>
      </div>

    </div>

  </Drawer> : null)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

const mapStateToProps = ({map}, ownProps) => { 
  return {
    ...ownProps, 
    mode: map.mode,
  }
}

const mapDispatchToProps = (dispatch) => ({
});

const EditAnchor = connect(mapStateToProps,mapDispatchToProps)(EditAnchorComponent)

export default EditAnchor;

