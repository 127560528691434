import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  errorList: {
    paddingLeft: theme.spacing(1),
  },
}));

const ListErrors = ({ errors }) => {
  const classes = useStyles();

  if (!errors) {
    return null;
  }

  return (
    <Alert severity="error" className={classes.alert}>
      <AlertTitle>
        <Typography variant="subtitle1">Error</Typography>
      </AlertTitle>
      <List className={classes.errorList}>
        {Array.isArray(errors) 
          ? errors.map((error, i) => <ListItem key={i}>{error}</ListItem>)
          : Object.entries(errors).map(([key, value]) => (
              <ListItem key={key}>{`${key}: ${value}`}</ListItem>
            ))
        }
      </List>
    </Alert>
  );
};

export default ListErrors;
