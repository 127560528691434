import React, {useEffect, useState} from 'react';
import { sortBy} from 'lodash';
import { stageCSS, decoCSS, decoTransform, platformLineCSS, wallCSS } from './utils_html';
import { GlitchGame, GlitchLayer} from './space';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import Draggable from 'react-draggable';

function HtmlDeco({i,deco,layer}) {
  const decoStyle = decoCSS(deco, layerOffset(layer));
  const transform= decoTransform(deco);

  return <Draggable key={`deco-${i}`} bounds="parent">
    <div style={decoStyle}>
      <img  draggable="false" className="box" src={`/glitch/assets/${deco.filename}.svg`} style={{width:'100%', height:'100%',...transform}} data-json={JSON.stringify(deco)}></img>
    </div>
  </Draggable>

}
const layerOffset = (layer) => {
  return {
    x: layer.name == 'middleground' ? layer.w/2 : 0,
    y: layer.name == 'middleground' ? layer.h : 0
  }
}

function HtmlWall({i,wall,layer}) {
  const wallStyle = wallCSS(wall, layerOffset(layer));
  wallStyle.zIndex = 1000;
  wallStyle.border= '1px solid blue';
  const transform= {};//

  return <Draggable key={`wall-${i}`} bounds="parent">
    <div style={wallStyle}>
      <div  draggable="false" className="wall" style={{width:'100%', height:'100%', ...transform}} data-json={JSON.stringify(wall)}></div>
    </div>
  </Draggable>
}

function HtmlLadder({i,ladder,layer}) {
  const ladderStyle = decoCSS(ladder, layerOffset(layer));
  ladderStyle.zIndex = 1000;
  ladderStyle.border= '1px solid green';
  const transform= {};//

  return <Draggable key={`ladder-${i}`} bounds="parent">
    <div style={ladderStyle}>
      <div  draggable="false" className="ladder" style={{width:'100%', height:'100%', ...transform}} data-json={JSON.stringify(ladder)}></div>
    </div>
  </Draggable>
}

function HtmlPlatformLine({i,platformLine,layer}) {
  const platformLineStyle = platformLineCSS(platformLine, layerOffset(layer));
  platformLineStyle.border= '1px solid red';
  platformLineStyle.zIndex = 1000;
  const transform={};

  return <Draggable key={`ladder-${i}`} bounds="parent">
    <div style={platformLineStyle}>
      <div  draggable="false" className="platformLine" style={{width:'100%', height:'100%', ...transform}} data-json={JSON.stringify(platformLine)}></div>
    </div>
  </Draggable>
}

function GlitchHtml({ history, match }) {
  const [gameObject, setGameObject] = useState<GlitchGame|null>(null);
  const [hidden, setHidden] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let id = match?.params?.id || 'GLIERMJ93DE1H25';
      if (id[0] === 'L') {
        id = 'G' + id.slice(1);
      }
      const dataJSON = await $.get('/locations/' + id + '.json');
      setGameObject(dataJSON);
    };
    fetchData();
  }, [match]);

  const display = (name) => {
    return hidden[name] ? 'none' : 'block';
  };

  const sortedLayers = gameObject ? sortBy(Object.values(gameObject.dynamic.layers), (layer) => layer.z) : [];

  const isHidden = (layer) => {
    return hidden[layer];
  };

  const onStart = () => {
    console.log('start');
  };

  const onStop = () => {
    console.log('stop'); // Fixed typo here from 'start' to 'stop'
  };

  if (!gameObject) return <h1>Loading...</h1>;

  const maxZ = sortedLayers.reduce((max, l) => (l.z > max ? l.z : max), sortedLayers[0]?.z || 0);
  const minZ = sortedLayers.reduce((min, l) => (l.z < min ? l.z : min), sortedLayers[0]?.z || 0);
  const zRange = maxZ - minZ;
  const dragHandlers = { onStart, onStop };

  return (
    <div className="glitcher">
      <Drawer
        variant="permanent"
        anchor="left"
        open={true}
        PaperProps={{ style: { position: 'fixed' } }}
        BackdropProps={{ style: { display: 'none' } }}
      >
        <List>
          {sortedLayers.map((layer, index) => (
            <ListItem button key={index} onClick={() => setHidden({ ...hidden, [layer.name]: !isHidden(layer.name) })}>
              <ListItemIcon>
                <i className={`fal ${isHidden(layer.name) ? 'fa-eye-slash' : 'fa-eye'}`} />
              </ListItemIcon>
              <ListItemText primary={layer.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className="location_holder" style={{ overflow: 'scroll' }}>
        <div className="location" style={stageCSS(gameObject)}>
          {sortedLayers.map((layer) => {
            const layerStyle = {
              display: display(layer.name),
              position: 'absolute',
              width: layer.w,
              height: layer.h,
              zIndex: zRange + layer.z,
            };
            //if (layer.name !== 'middleground') return null;

            return (
              <div id={`layer-${layer.name}`} key={`layer-${layer.name}`} style={layerStyle as any}>
                {layer.decos.map((deco, i) => <HtmlDeco key={`deco-${layer.name}-${i}`} deco={deco} layer={layer} i={i} />)}
                {layer.walls.map((wall, i) => <HtmlWall key={`wall-${layer.name}-${i}`} wall={wall} layer={layer} i={i} />)}
                {layer.ladders.map((ladder, i) => <HtmlLadder key={`ladders-${layer.name}-${i}`} ladder={ladder} layer={layer} i={i} />)}
                {layer.platformLines.map((pf, i) => <HtmlPlatformLine key={`ladders-${layer.name}-${i}`} platformLine={pf} layer={layer} i={i} />)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

class GlitchHtml2 extends React.Component<{history:any, match: any},{gameObject:GlitchGame, hidden: any}> {

  state = {
    gameObject: null,
    hidden: {}
  }

  async componentDidMount() {
    let id = this.props.match?.params?.id || 'GLIERMJ93DE1H25';

    if(id[0] == 'L'){
      id = 'G' + id.slice(1)
    }

    const dataJSON = await $.get('/locations/' + id + '.json')
    this.setState({...this.state, gameObject: dataJSON});
  }


  display(name) {
    return this.state.hidden[name] ? 'none' : 'block';
  }


  get sortedLayers():GlitchLayer[] {
    return sortBy(Object.values(this.state.gameObject.dynamic.layers), layer=> layer.z)
  }

  isHidden(layer:string):boolean {
    return this.state.hidden[layer];
  }
  onStart() {
    console.log('start');
  }

  onStop() {
    console.log('start');
  }

  //isDraggable(layer:string):boolean {
  //  return this.state.draggable[layer];
  //}

  render(){

    if(!this.state.gameObject)
      return <h1>Loading...</h1>;

    const maxZ = this.sortedLayers.reduce((max, l) => (l.z > max ? l.z : max), this.sortedLayers[0].z);
    const minZ = this.sortedLayers.reduce((min, l) => (l.z < min ? l.z : min), this.sortedLayers[0].z);
    const zRange = maxZ-minZ;
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

    return (<div className="glitcher">
      <Drawer
        variant="permanent"
        anchor='left'
        open={true}
        PaperProps={{ style: { position: 'fixed' } }}
        BackdropProps={{ style: { display: 'none' } }}
      >
        <List>
          {this.sortedLayers.map((layer) => {
            return <ListItem button onClick={(e) => this.setState({...this.state, hidden: {...this.state.hidden, [layer.name]: !this.isHidden(layer.name)}})}>
              <ListItemIcon><i className={`fal ${this.isHidden(layer.name) ? 'fa-eye-slash' : 'fa-eye'}`}/></ListItemIcon>
              <ListItemText primary={layer.name} />
            </ListItem>
          })}
        </List>
      </Drawer>

      <div className="location_holder" style={{overflow: 'scroll'}}>
        <div className="location" style={stageCSS(this.state.gameObject)}>
          {this.sortedLayers.map((layer) => { 
            const layerStyle = {
              display: this.display(layer.name),
              position: 'absolute',
              width: layer.w,
              height: layer.h,
              zIndex: zRange + layer.z
            } as any;;
            if(layer.name != 'middleground')
              return null

            return <div id={`layer-${layer.name}`} key={`layer-${layer.name}`} style={layerStyle}>
                {layer.decos.map((deco,i) => {
                  return <HtmlDeco key={`deco-${layer.name}-${i}`} deco={deco} layer={layer} i={i}/>
                })}
                {layer.walls.map((wall,i) => {
                  return <HtmlWall key={`wall-${layer.name}-${i}`} wall={wall} layer={layer} i={i}/>
                })}
                {layer.ladders.map((ladder,i) => {
                  return <HtmlLadder key={`ladders-${layer.name}-${i}`} ladder={ladder} layer={layer} i={i}/>
                })}
                {layer.platformLines.map((pf,i) => {
                  return <HtmlPlatformLine key={`ladders-${layer.name}-${i}`} platformLine={pf} layer={layer} i={i}/>
                })}
              </div>
          })}
    </div>
  </div>
</div>)
  }
}

export default GlitchHtml
