import Card from "@material-ui/core/Card";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link, IconButton, makeStyles } from '@material-ui/core';
import React from "react";
import { ArtworkProps } from ".";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    "object-fit": 'contain',
    objectFit: 'contain',
  },
  img: {
    "object-fit": 'contain',
  }
});


const ArtworkCard = (props:{artwork:ArtworkProps, onClick:(x:ArtworkProps)=>void}) => {

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={(e) => props.onClick(props.artwork)}>
        <CardMedia
          className={classes.media}
          image={`https://openclipart.org/image/800px/${props.artwork.metadata.id}`}
          component="img"
          title={props.artwork.metadata.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.artwork.metadata.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.artwork.metadata.description}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
      </CardActions>
    </Card>

  )
}

export default ArtworkCard;
