import Bloodhound from 'typeahead.js';
import { MapRouter } from 'maps/router';
import { SocketManager } from 'maps/managers/socket_manager';
import { RecordingManager } from 'maps/managers/recording_manager';
import { store } from 'redux/store';

const create_suggester = (clazz, in_current_game=false) => {

  return new Bloodhound({
    identify: function(obj) {
      return `${obj.type}-${obj.id}`;
    },
    remote: {
      url: `/search/search?models=${clazz}&query=%QUERY`,
      wildcard: "%QUERY",
      replace: (url,query) => {
        const game_id = in_current_game ? `game_id=${store.getState().map.space.game_id}` : '';
        const q = `/search/search?models=${clazz}&query=${encodeURIComponent(query)}&${game_id}`
        return q;
      }
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    datumTokenizer: (str) => str.label ? Bloodhound.tokenizers.whitespace(str.label) : Bloodhound.tokenizers.whitespace(str)
  });

}

class CacheReferences {

  map_router:MapRouter;

  urls: {[url:string]: any} = {};

  SocketManager: SocketManager;
  recording_manager: RecordingManager;

  branches:{ [name:string]: RecordingManager} = {};

  AvatarSuggestions: Bloodhound;
  NpcSuggestions: Bloodhound;
  PictureSuggestions: Bloodhound;
  ScriptSuggestions: Bloodhound;
  SoundSuggestions: Bloodhound;
  WebResourceSuggestions: Bloodhound;
  SpaceSuggestions: Bloodhound;
  VideoSuggestions: Bloodhound;
  TextSuggestions: Bloodhound;
  AnchorSuggestions: Bloodhound;
  QuestionSuggestions: Bloodhound;
  ItemSuggestions: Bloodhound;
  ClazzSuggestions: Bloodhound;

  constructor(){
    this.AvatarSuggestions = create_suggester('Avatar');
    this.NpcSuggestions = create_suggester('Npc');
    this.PictureSuggestions = create_suggester('Picture');
    this.ScriptSuggestions = create_suggester('Script',true);
    this.SoundSuggestions = create_suggester('Sound');
    this.WebResourceSuggestions = create_suggester('WebResource');
    this.SpaceSuggestions = create_suggester('Space');
    this.VideoSuggestions = create_suggester('Video');
    this.TextSuggestions = create_suggester('Text');
    this.AnchorSuggestions = create_suggester('Anchor');
    this.QuestionSuggestions = create_suggester('Question',true);
    this.ItemSuggestions = create_suggester('Item',true);
    this.ClazzSuggestions = create_suggester('Clazz');

  }
}

export default CacheReferences;
