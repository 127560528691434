import { NounType } from "./noun";
import { Verb, Prepositions } from "./verb";
import _ from "underscore";
import {Command} from './command';
import { AvatarView } from "maps/views/avatar_view";
import { Avatar } from "models/avatar";

class ParsedSentence {

  verb: Verb;

  direct_object: NounType;

  modifiers: Prepositions;
  // modifiers is dictionary of preposition: noun 
  constructor(verb:Verb, direct_object:NounType, modifiers:Prepositions) {
    this.verb = verb;
    this.direct_object = direct_object;
    this.modifiers = modifiers;
  }

  get getDirectObject():string {
    return (this.direct_object.label || this.direct_object.placeholder);
  }

  // return plain text that we should set the input box to if user hits
  // space bar on this sentence.
  getCompletionText() {
    let sentence = this.verb.name;
    if (this.direct_object) {
      sentence = sentence + ' "' + this.getDirectObject + '"';
    }
    return sentence + ' ' + Object.keys(this.modifiers).map((k) => `${k} ${this.modifiers[k] || ''}`).join(' ')
  }

  // returns html formatted sentence for display in suggestion list
  getDisplayText() {
    let sentence = this.verb.name;

    if (this.verb.direct_object?.type) {
      if (this.direct_object) {
        sentence = sentence + ' "' + this.getDirectObject + '"';
      } else {
        sentence = sentence + ` <span class="needarg">(${this.verb.direct_object.label})</span>`;
      }
    }

    for (let x in this.verb.modifiers) {
      if (this.modifiers[x]) {
        sentence = sentence + ` <b>${x} ${this.modifiers[x].label || this.modifiers[x]}</b>`;
      } else {
        sentence = sentence + ` <span class="needarg">(${x} ${this.verb.modifiers[x].name})</span>`;
      }
    }
    return sentence;
  }

  // returns a string describing what the sentence will do if executed
  getDescription() {
    return this.verb.getDescription(this.direct_object.label, this.modifiers);
  }

  async execute(context) {
    if(context instanceof AvatarView){
      return this.verb.execute(this.direct_object, this.modifiers, context);
    }else{
      const c = new Command(this.verb,this.direct_object, this.modifiers);
      context.save_command(context, c);
    }
  }
}

export { ParsedSentence};
