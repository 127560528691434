import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, Typography, Tooltip } from '@material-ui/core';
import { MapController } from 'maps/controller';
import { primary_20, primary_40, white } from 'stylesheets/styles';
import './create_toolbox_drawer.scss'
import { ToolboxDrawer, goToAnchor, goToNpcPlacement, addAnchor, openNpcPicker } from 'components/world/drawers';
import { AnchorAttributes } from 'models/anchor';
import { NpcPlacementAttributes, NpcPlacement } from 'models/npc_placement';
import { MAP_EDIT_NPC_PLACEMENT } from 'constants/actionTypes';
import { connect } from 'react-redux';

const drawer_width=47;

const drawer_title_style ={
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};
const btn_style ={
 width: drawer_width,
 height: drawer_width,
}

function CreateToolboxDrawerComponent(props:{onNpcPlacementClick:(npc_p:NpcPlacement)=>void, app: MapController}) {
  const {app}=props;

  const [open_toolbox, setOpenToolbox] = useState(false);
  const [open_anchors, setOpenAnchors] = useState(false);
  const [open_npcs, setOpenNpcs] = useState(false);
  const anchors:AnchorAttributes[] = app.space?.anchors?.map((a) => a.attributes as AnchorAttributes);
  const npcs:NpcPlacement[] = app.space?.npc_placements;

  return <>
  <Drawer
    className="create-toolbox-drawer create-toolbox-drawer-general"
    anchor='left'
    transitionDuration={0}
    open={true}
    PaperProps={{ style: { position: 'absolute', background: primary_20, color: white } }}
    variant="permanent"
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute' }
    }}
  >
    <List>
      <ListItem button key="toolboxk" onClick={e=>{setOpenToolbox(!open_toolbox); setOpenAnchors(false)}} style={btn_style}>
        <Tooltip title="Toolbox" placement="right">
          <i className="fal fa-toolbox"/>
        </Tooltip>
      </ListItem>

      <ListItem button key="anchors" onClick={e=>{setOpenAnchors(!open_anchors)}} style={btn_style}>
        <Tooltip title="Anchors" placement="right">
          <i className="fal fa-anchor"/>
        </Tooltip>
      </ListItem>

      <ListItem button key="npcs" onClick={e=>{setOpenNpcs(!open_npcs)}} style={btn_style}>
        <Tooltip title="Objects in the scene" placement="right">
          <i className="fal fa-treasure-chest"/>
        </Tooltip>
      </ListItem>

    </List>
  </Drawer>

  <Drawer
    className="create-toolbox-drawer-inventory"
    open={open_toolbox}
    onClose={(e) => setOpenToolbox(false)}
    transitionDuration={0}
    anchor='left'
    PaperProps={{ style: { position: 'absolute', width: '15rem', background: primary_40, color: white, left: drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    
    <List>
      <ListItem button onClick={e=>{setOpenToolbox(!open_toolbox); setOpenAnchors(false)}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

    <ToolboxDrawer setOpenAnchors={console.log} setDialogOpen={console.log} setOpenDrawer={setOpenToolbox} controller={app}></ToolboxDrawer>

  </Drawer>
        
  {open_anchors && <Drawer
    className="create-toolbox-drawer-inventory"
    open={open_anchors}
    onClose={(e) => setOpenAnchors(false)}
    transitionDuration={0}
    anchor='left'
    PaperProps={{ style: { position: 'absolute', width: '15rem', background: primary_40, color: white, left: drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    <List>
      <ListItem button onClick={e=>{setOpenAnchors(!open_anchors)}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

  <Divider />

  <List>
    <ListItem button key="add-anchor" onClick={e=>{setOpenAnchors(false);addAnchor(app)}}>
      <ListItemIcon><i className="fal fa-anchor"></i></ListItemIcon>
      <ListItemText primary="Add anchor" />
    </ListItem>
  </List>

  <Divider />

    <List>
    {anchors?.length ? anchors.map((anchor:AnchorAttributes) => 
    <ListItem button key={anchor.uuid} onClick={e=>{setOpenAnchors(false); goToAnchor(app, anchor)}}>
      <ListItemIcon><i className="fal fa-anchor"/></ListItemIcon>
      <ListItemText primary={anchor.name} />
    </ListItem>
    ) :
    <ListItem button><ListItemText primary={"(no anchors)"}></ListItemText></ListItem>}
  </List>

</Drawer>}

  {open_npcs && <Drawer
    className="create-toolbox-drawer-inventory"
    open={open_npcs}
    onClose={(e) => setOpenNpcs(false)}
    transitionDuration={0}
    anchor='left'
    PaperProps={{ style: { position: 'absolute', width: '15rem', background: primary_40, color: white, left: drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    <List>
      <ListItem button onClick={e=>{setOpenNpcs(!open_npcs)}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

    <Divider/>

  <List>
    <ListItem button key="add-npc" onClick={e=>{setOpenNpcs(false); openNpcPicker(app)}}>
      <ListItemIcon><i className="fal fa-map-marker-edit"/></ListItemIcon>
      <ListItemText primary="Add Object (NPC)" />
    </ListItem>
  </List>

    <Divider/>

    <List>
    {npcs?.length ? npcs.map((npc_p:NpcPlacement) => 
    <ListItem button key={npc_p.uuid} onClick={e=>{setOpenNpcs(false); props.onNpcPlacementClick(npc_p)}}>
      <ListItemIcon><i className="fal fa-anchor"/></ListItemIcon>
      <ListItemText primary={npc_p.npc?.name} />
    </ListItem>
    ) :
    <ListItem button><ListItemText primary={"(no npcs)"}></ListItemText></ListItem>}
  </List>

</Drawer>}


</>
}

const mapStateToProps = ({map, common}, ownProps) => ({
  ...ownProps
})

const mapDispatchToProps = (dispatch) => ({
  onNpcPlacementClick: (npc_placement) => dispatch({ type: MAP_EDIT_NPC_PLACEMENT, npc_placement }),
})

const CreateToolboxDrawer = connect(mapStateToProps, mapDispatchToProps)(CreateToolboxDrawerComponent);
export default CreateToolboxDrawer;

export {drawer_width};
