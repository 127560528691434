import React from 'react';
import { fabric } from 'fabric';
import { GlitchGame, GlitchLayer, GlitchDeco } from './space';
import { sortBy} from 'lodash';
import { loadFabricImage, createLayerImages } from './utils';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Spaces, GlitchLocations } from 'api/agent';
import { isAdmin, UserAttributes } from 'models/user';
import { connect } from 'react-redux';
import {stageCSS} from './utils_html';

class GlitchEditorComponent extends React.Component<{match?:any, currentUser: UserAttributes},{gameData:GlitchGame, hidden: any, editing?:string, space_id?:string, tsid?:string}> {

  layers: { [name:string] :fabric.Canvas}={};
  backgrounds: { [name:string] : string}={};

  state ={
    gameData:null,
    hidden:{},
    editing:null,
    tsid:null,
    space_id:null,
  }

  other: HTMLDivElement;
  canvasEl: HTMLCanvasElement;

  display(name) {
    return this.state.hidden[name] ? 'none' : 'block';
  }

  layerStyle(layer, zRange){
    return {
      display: this.display(layer.name),
      position: 'absolute',
      width: layer.w,
      height: layer.h,
      zIndex: zRange + layer.z,
      pointerEvents: this.isEditing(layer.name) ? 'auto' : 'none',
      opacity: this.editing && !this.isEditing(layer.name) ? 0.5 : 1,
    } as any;
  }

  get editing():boolean {
    return Boolean(this.state.editing)
  }
  isEditing(name) {
    return this.state.editing == name;
  }

  get sortedLayers():GlitchLayer[] {
    return sortBy(Object.values(this.state.gameData.dynamic.layers), layer=> layer.z)
  }

  isHidden(layer:string):boolean {
    return this.state.hidden[layer];
  }

  async componentDidMount(){

    const uuid = this.props.match?.params?.id || 'GM410QQ0CHARO';

    let game_json:GlitchGame;
    let tsid:string=null;

    if(uuid.length < 36){
      game_json = await $.get('/locations/' + uuid + '.json')
    }else{
      const space_json = await Spaces.show(uuid)
      if(space_json.location){
        game_json = space_json.location.metadata
        tsid = space_json.location.tsid
      }
    }

    if(game_json){
      if(false){
        this.backgrounds = await createLayerImages(game_json)
      }
      this.setState({gameData:game_json, tsid, space_id: uuid});
    }

  }

  serializeItems():GlitchGame{

    const retval:GlitchGame = {...this.state.gameData};
    retval.dynamic = {...this.state.gameData.dynamic}
    retval.dynamic.layers = Object.keys(this.layers).reduce((acc,id) => {
      const layer = this.state.gameData.dynamic.layers[id];
      const [offset_x,offset_y] = id == 'middleground' ? [layer.w/2, layer.h] : [0,0];
      acc[id] = {...layer};
      acc[id].decos = this.layers[id].getObjects().map((obj:fabric.Image & {item: GlitchDeco}) => {
        const el:HTMLImageElement = obj.getElement() as HTMLImageElement;
        const x = {...obj.item};
        const j= fabric.util.qrDecompose(obj.calcTransformMatrix())
        x.w = j.scaleX * el.naturalWidth;
        x.h = j.scaleY * el.naturalHeight;
        x.x = obj.left - offset_x;
        x.y = obj.top - offset_y;
        x.r = obj.angle;
        return x;
      });
      return acc;
    }, {})
    return retval;
  }

  async loadLayer(layer, canvasEl:HTMLCanvasElement){
    if(!canvasEl) return;

    if(this.layers[layer.name]){
      if(this.state.editing != layer.name){
        this.layers[layer.name].discardActiveObject()
        this.layers[layer.name].requestRenderAll();
      }
      return;
    }

    var canvas = new fabric.Canvas(canvasEl, {enableRetinaScaling: false});
    this.layers[layer.name]=canvas;
    canvas.on('object:modified', (e) => {
      const {top,left,scaleX,scaleY,angle} =e.target;
      console.log('Modified:',layer.name,(e.target as any).idx,(e.target as any).item,{top,left,scaleX,scaleY,angle}, fabric.util.qrDecompose(e.target.calcTransformMatrix()));
    })

    this.renderLayer(layer,canvas);
  }

  async renderLayer(layer:GlitchLayer, canvas:fabric.Canvas ){
    const width= this.state.gameData.dynamic.r - this.state.gameData.dynamic.l;
    const height= this.state.gameData.dynamic.b - this.state.gameData.dynamic.t;

    const layerId=layer.name;

    canvas.setHeight(height);
    canvas.setWidth(width);

    const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];

    const decos = sortBy(layer.decos,(x)=>x.z);

    for(let idx=0, len=decos.length;idx<len;idx++){

      const item = decos[idx];
      const image:fabric.Image = await loadFabricImage('/glitch/assets/' + item.filename + '.svg');
      const img:HTMLImageElement = image.getElement() as HTMLImageElement;
      const scaleX= item.w/img.naturalWidth;
      const scaleY= item.h/img.naturalHeight;

      image.set({
        idx,
        item,
        originX: 'center',
        originY: 'bottom',
        scaleX,
        scaleY,
        left: item.x + offset_x,
        top:  item.y + offset_y,
        angle: item.r,
        flipX: item.h_flip
      } as any);

      canvas.add(image)
      canvas.calcOffset();
    }

    var selection = new fabric.ActiveSelection(canvas.getObjects(), { canvas });
    canvas.setActiveObject(selection);   //selecting all objects...
    canvas.requestRenderAll();
    canvas.discardActiveObject();

  }

  render(){

    if(!this.state.gameData)
      return <h1>Loading...</h1>;

    const maxZ = this.sortedLayers.reduce((max, l) => (l.z > max ? l.z : max), this.sortedLayers[0].z);
    const minZ = this.sortedLayers.reduce((min, l) => (l.z < min ? l.z : min), this.sortedLayers[0].z);
    const zRange = maxZ-minZ;

    return (<div className="glitcher">
      <Drawer
        variant="permanent"
        anchor='left'
        open={true}
        PaperProps={{ style: { position: 'fixed' } }}
        BackdropProps={{ style: { display: 'none' } }}
      >
        <List>
          {this.sortedLayers.map((layer) => {
            //if(layer.name != 'middleground')
            //  return null
            return <ListItem>
              <ListItemIcon onClick={(e) => this.setState({...this.state, hidden: {...this.state.hidden, [layer.name]: !this.isHidden(layer.name)}})}>
                <i className={`fal ${this.isHidden(layer.name) ? 'fa-eye-slash' : 'fa-eye'}`}/>
              </ListItemIcon>
              <ListItemIcon onClick={(e) => this.setEditing(layer.name)}>
                <i className={`fal fa-edit`} style={{opacity: this.isEditing(layer.name) ? 1 : 0.5}}/>
              </ListItemIcon>
              <ListItemText primary={layer.name} />
            </ListItem>
          })}

    {isAdmin(this.props.currentUser) && <ListItem>
      <ListItemIcon onClick={(e) => this.resetLayers()}>
        <i className={`fal fa-play`}/>
      </ListItemIcon>
      <ListItemText primary="Test Serialization" />
  </ListItem>}


          <ListItem>
            <ListItemIcon onClick={(e) => this.saveGame()}>
              <i className={`fal fa-save`}/>
            </ListItemIcon>
            <ListItemText primary="Save" />
          </ListItem>

        </List>
      </Drawer>

      <div className="location_holder" style={{overflow: 'scroll'}}>
        <div className="location" style={stageCSS(this.state.gameData)}>

          {this.sortedLayers.map((layer) => {
            //if(layer.name != 'middleground')
            //  return null

            return <div id={`layer-${layer.name}`} key={`layer-${layer.name}`} style={this.layerStyle(layer, zRange)}>
              <canvas ref={(e) => this.loadLayer(layer,e)} key={`canvas-${layer.name}`}  id={`canvas-${layer.name}`} style={{width: layer.w, height: layer.h}}></canvas>
              {false && this.isEditing(layer.name) && 
              <img src={this.backgrounds[layer.name]} style={{position: 'absolute', top:0,left:0,width:layer.w, height:layer.h}}></img>
              }
          </div> 
          })}
        </div>

  </div>
</div>)
  }

  saveGame(): void {
    const newData = this.serializeItems();
    GlitchLocations.update(this.state.space_id, newData);
  }

  resetLayers(): void {
    const newData = this.serializeItems();
    this.setState({...this.state, gameData: newData});

    Object.keys(this.layers).forEach((k) => {
      const l = this.layers[k];
      l.clear();
      this.renderLayer(newData.dynamic.layers[k], l);
    })
  }

  async setEditing(layerId: string): Promise<void> {
    if(this.state.editing == layerId){
      this.setState({...this.state, editing: null});
      return;
    }

    this.setState({...this.state, editing: layerId});

    const layer = this.layers[layerId];

    var selection = new fabric.ActiveSelection(layer.getObjects(), { canvas:layer });
    layer.setActiveObject(selection);   //selecting all objects...
    layer.requestRenderAll();
    layer.discardActiveObject();
  }
}

const mapStateToProps = (state,ownProps) => ({
  ...ownProps, 
  currentUser: state.common.currentUser,
});
const GlitchEditorOld= connect(mapStateToProps)(GlitchEditorComponent);

export {GlitchEditorOld}
