import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { spriteOuterStyle, spriteInnerStyle } from 'components/sprites/sprite';
import { AvatarAttributes } from 'models/avatar';
import { SpriteAttributes, SpriteFrameType } from 'models/sprite';
import './react-avatar.scss'

type AvatarProps = {
  fps:number,
  avatar:AvatarAttributes,
  t:number,
  animation:string,
  x:number,
  y:number,
}


class Avatar extends React.Component<AvatarProps & React.HTMLAttributes<HTMLDivElement>,{sprite_loaded:boolean, images:{[name:string]:HTMLImageElement}}> {

  public outer:HTMLDivElement;

  constructor(props){
    super(props)
    this.state = {
      sprite_loaded:false,
      images:{}
    }
  }

  get height() {
    return this.outer.clientHeight;
  }
  get width() {
    return this.outer.clientWidth;
  }

  get currentImage():HTMLImageElement {
    return this.state.images[this.props.animation];
  }

  get currentFrame():SpriteFrameType {
    const sprite= this.currentSprite;
    const frame_idx = this.currentSprite.map.sequences[this.props.animation].frames[this.idx]
    return this.currentSprite.map.frames[frame_idx]
  }

  get idx(){
    return Math.round(this.props.fps*this.props.t/1000) % this.currentSprite.map.sequences[this.props.animation].frames.length;
  }

  get currentSprite():SpriteAttributes {
    return this.props.avatar.sprites.find(sprite => this.props.animation in sprite.map.sequences)
  }

  async UNSAFE_componentWillMount(){
    const images={}
    Promise.all(this.props.avatar.sprites.map(sprite => {
      return new Promise((accept,reject) => {
	const img = new Image();
	Object.keys(sprite.map.sequences).forEach((k) => images[k]=img);
	img.onload = accept;
	img.onerror = reject;
	img.src = sprite.public_filename;
      })
    })).then(()=>{
      this.setState({...this.state, sprite_loaded: true, images});
    })
  }

  render() {
    if(!this.state.sprite_loaded)
      return null;
    return <div className="reactAvatar" style={{top: this.props.y, left: this.props.x, position: 'absolute'}}>
      <div className="reactAvatar-outer" ref={(e)=>this.outer = e} style={spriteOuterStyle(this.currentFrame, 100)} >
	<img className="reactAvatar-inner" src={this.currentSprite.public_filename} style={spriteInnerStyle(this.currentFrame, this.currentImage, 100)}></img>
      </div>

      <div className="reactAvatar-extras">
	{this.props.children}
      </div>
    </div>
  }

}

const Avatar2 = forwardRef<HTMLDivElement, AvatarProps & React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { fps, avatar, t, animation, x, y, children} = props;
  const [spriteLoaded, setSpriteLoaded] = useState(false);
  const [images, setImages] = useState<{ [name: string]: HTMLImageElement }>({});
  const outerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const images = {};
    Promise.all(
      avatar.sprites.map(sprite => {
	return new Promise<void>((accept, reject) => {
	  const img = new Image();
	  Object.keys(sprite.map.sequences).forEach((k) => images[k] = img);
	  img.onload = () => accept();
	  img.onerror = () => reject();
	  img.src = sprite.public_filename;
	});
      })
    ).then(() => {
      setSpriteLoaded(true);
      setImages(images);
    });
  }, [avatar.sprites]);

  //const height = outerRef.current?.clientHeight ?? 0;
  //const width = outerRef.current?.clientWidth ?? 0;

  const currentSprite = avatar.sprites.find(sprite => animation in sprite.map.sequences);

  const idx = (() => {
    if (!currentSprite) return 0;
    return Math.round(fps * t / 1000) % currentSprite.map.sequences[animation].frames.length;
  })();

  const currentFrame = (() => {
    if (!currentSprite) return null; // Or your default frame type
    const frame_idx = currentSprite.map.sequences[animation].frames[idx];
    return currentSprite.map.frames[frame_idx];
  })();

  const currentImage = images[animation];

  if (!spriteLoaded || !currentSprite || !currentFrame || !currentImage) return null;

  return (
    <div ref={ref} className="reactAvatar" style={{ top: y, left: x, position: 'absolute' }}>
      <div className="reactAvatar-outer" ref={outerRef} style={spriteOuterStyle(currentFrame, 100)}>
	<img className="reactAvatar-inner" src={currentSprite.public_filename} style={spriteInnerStyle(currentFrame, currentImage, 100)}></img>
      </div>
      <div className="reactAvatar-extras">
	{children}
      </div>
    </div>
  );
});


export {Avatar, Avatar2}
