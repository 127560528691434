import { computeQuantizedPart, PALETTE, quantizeDepthMap } from "components/depth_map";
import { Flex } from "components/flex";
import { useDesignContext } from "contexts/design_context";
import React, { CSSProperties, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import {useSelector} from "react-redux";
import { addCallback } from "utils/channels";
import { dataURItoBlob, depthmap_url } from "utils/urls";

export default function AiLayersPanel(props) {

  const { gameData, } = useDesignContext();
  const { spaceId } = useSelector((state:any) => state.editor);

  const bgUrl = gameData?.background_image?.url;

  // main image
  const [mainImage, setMainImage] = useState<HTMLImageElement | null>(null);

  // depth map
  const [depthMap, setDepthMap] = useState<HTMLImageElement | null>(null);

  // Refs for canvas and context
  // quantized depth map
  const [quantizedParts, setQuantizedParts] = useState<{ [k: string]: HTMLImageElement }>({});

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const drawImages = (mainImg: HTMLImageElement, depthImg: HTMLImageElement) => {
    if (!mainImg || !depthImg) return;
    // Create off-screen canvases
    const mainCanvas = document.createElement('canvas');
    mainCanvas.width = mainImg.width;
    mainCanvas.height = mainImg.height;
    const mainCtx = mainCanvas.getContext('2d');
    let mainImageData: ImageData | null = null;
    mainCtx.drawImage(mainImg, 0, 0);
    mainImageData = mainCtx.getImageData(0, 0, mainImg.width, mainImg.height);

    const depthCanvas = document.createElement('canvas');
    depthCanvas.width = depthImg.width;
    depthCanvas.height = depthImg.height;
    const depthCtx = depthCanvas.getContext('2d');
    let depthImageData: ImageData | null = null;
    depthCtx.drawImage(depthImg, 0, 0);
    depthImageData = depthCtx.getImageData(0, 0, depthImg.width, depthImg.height);
    return { mainImageData, depthImageData };
  };

  useEffect(() => {
    if (!bgUrl)
      return
    const mainImage = new Image();
    mainImage.crossOrigin = 'anonymous';
    mainImage.onload = () => {
      setMainImage(mainImage);
    }
    mainImage.src = bgUrl;

    getDepthMap(bgUrl);
  }, [bgUrl]);

  useEffect(() => {
    if (!mainImage || !depthMap)
      return;

    let isMounted = true; // Track whether the component is mounted

    new Promise(async (_resolve, _reject) => {
      const resp = await fetch(`/generative_ai?space_id=${spaceId}&type=AiInvokation::AiLayers`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
      }).then(response => response.json());

      if(!isMounted) return;

      if (resp.length) {
        let {files} = resp[resp.length - 1];
        setQuantizedParts(Object.entries(files).reduce((acc, [key, file]:[string,string]) => {
          const img = new Image();
          img.src = file;
          acc[key.split('.')[0]] = img;
          return acc;
        }, {}));
        return
      }

      const { mainImageData, depthImageData } = drawImages(mainImage, depthMap);

      const quantizedMap = quantizeDepthMap(depthImageData);

      const formData = new FormData();
      formData.append('ai_invokation[type]', 'AiInvokation::AiLayers');

      PALETTE.forEach((color,index) => {
        const key = color.join('-');
        const img = computeQuantizedPart(color, quantizedMap, mainImageData);
        const blob = dataURItoBlob(img.src);
        formData.append('ai_invokation[files][]', blob, `${key}.png`);
      });

      const response = await fetch(`/generative_ai?space_id=${spaceId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: formData,
      })
      if (!response.ok) {
        // Handle error response
        const errorData = await response.json();
        console.error('Error:', errorData);
        return;
      }
      // Handle success response
      const data = await response.json();
      console.log('Success:', data);
      setQuantizedParts(Object.entries(data.files).reduce((acc, [key, file]:[string,string]) => {
        const img = new Image();
        img.src = file;
        acc[key.split('.')[0]] = img;
        return acc;
      }, {}));
    })
  } , [mainImage, depthMap]);

  const getDepthMap = async (url) => {
    const dm_url = depthmap_url({ spaceId: spaceId });
    const data = await fetch(dm_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        url: url, 
        slug: 'depthmap',
      })
    }).then((r) => r.json());

    if (data.output) {
      const depthImage = new Image();
      depthImage.crossOrigin = 'anonymous';
      depthImage.onload = () => {
        setDepthMap(depthImage);
      }
      depthImage.src = data.output[0];
    } else {
      addCallback(data.uuid, (data) => {
        const depthImage = new Image();
        depthImage.crossOrigin = 'anonymous';
        depthImage.onload = () => {
          setDepthMap(depthImage);
        }
        depthImage.src = data.output[0];
      }, (error) => {
        console.log('Got error', error);
      })
    }
  }
  const styles = {
    button: {
      marginTop: '10px',
      display: 'block',
      width: '100%',
      padding: '10px',
      color: '#000',
      border: 'none',
      cursor: 'pointer',
    },
  };

  const PaletteView = ({ color }) => {

    const svgStyle: React.CSSProperties = {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '10px',
      border: '1px solid black',
      borderRadius: '5px',
    };

    const img = quantizedParts[color.join('-')];
    const obj = {
      url: img.src,
      type: 'deco',
      center: true };

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
      type: 'sprite',
      item: obj,
      end: (_item, _monitor) => {
        //const dropResult = monitor.getDropResult<{name:string}>()
        //const coords = monitor.getClientOffset();
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }))

    const style: CSSProperties = {
      opacity: isDragging ? 0.5 : 1,
      cursor: 'move',
      //position: isDragging ? 'fixed' : 'static'
    }

    return <div ref={drag} style={style} draggable={true}>
      <img src={img.src} style={svgStyle} ></img>
    </div>
    };


  return <Flex flexDirection="column" >
    {
      bgUrl ?
      <Flex.Item >
        <Flex flexDirection="column" >
          <Flex.Item>Background Image: </Flex.Item>
          < Flex.Item > <img src={bgUrl} style={{ width: '100%' } } /></Flex.Item >

        <Flex.Item style={{ marginTop: '1rem' }} flex="1" >
          {PALETTE.map((color, index) => (
            quantizedParts[color.join('-')] ?
            <PaletteView key={index} color={color} /> : null
          ))}
        </Flex.Item>

      </Flex>
  </Flex.Item> : null}

</Flex>
}
