import { BaseModel } from "./base_model";

import {Sprite, SpriteAttributes} from './sprite'

type AvatarAttributes = {
  user_uuid:string, //this is sometimes injected to track who's this persona
  id: string,
  name: string,
  description:string,
  type: string,
  uuid:string,
  sprites: SpriteAttributes[]
}

class Avatar extends BaseModel {

  constructor(attributes, options) {
    super(attributes,options);
    this.dispatchCreated('Avatar');
  }

  _className() { return 'Avatar'; }

  get uuid():string {
    return this.get('uuid');
  }

  get name():string {
    return this.get('name');
  }

  initialize(settings, opts) {
    super.initialize(settings, opts);
  }

  parse(resp, options) {
    const x = super.parse(resp, options);
    if (resp.sprites) {
      x.sprites = resp.sprites.map(function(s) {
        return new Sprite(s, options);
      });
    }
    return x;
  }

  label() {
    return this.name;
  }

  url = () => {
    return [(window as any).Niconitos.config.api, "avatars", this.id || ''].join('/');
  }

};

Avatar.prototype.schema = {
  name: "Text"
};

export {Avatar, AvatarAttributes};
