import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { lila_oscuro_rgb } from 'stylesheets/styles';
import './backdrop.scss'

// Can't make useStyles to work, and already lost some hours. TODO
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: `rgba(${[...lila_oscuro_rgb, 0.6].join(',')})`
    },
    backdrop_transparent: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'transparent',
    },
  }),
);

export default function GameBackdrop({open,...props}) {
  const classes = useStyles();

  return (
      <Backdrop className={open ? classes.backdrop : classes.backdrop_transparent} open={open}>
	{props.children}
      </Backdrop>
  );
}

