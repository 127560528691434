import { fabric } from 'fabric';

import { FabricImage } from '../utils';
import CanvasController from './CanvasController';
import BaseHandler from './BaseHandler';

class CropHandler extends BaseHandler {

  cropShape: fabric.Object;
  cropObject: FabricImage;

  constructor(handler: CanvasController) {
    super(handler);
    this.cropShape = null;
    this.cropObject = null;
  }

  /**
   * Validate crop type
   *
   * @returns
   */
  public validType() {
    return ['image','textbox', 'svg'].includes(this.controller.canvas.getActiveObject()?.type)
  };

  /**
   * Start crop image
   *
   */
  public start(shape='rect') {
    if (!this.validType())
      return;

    this.controller.interactionMode = InteractionModes.CROP;
    this.cropObject = this.controller.canvas.getActiveObject() as FabricImage;
    this.cropObject.clipPath=null;

    //if(this.cropObject.type != 'textbox')
    //  this.controller.zoomHandler.zoomOneToOne();

    const { left, top } = this.cropObject;

    if(shape=='rect'){
      this.cropShape = new fabric.Rect({
        width: this.cropObject.width,
        height: this.cropObject.height,
        scaleX: this.cropObject.scaleX,
        scaleY: this.cropObject.scaleY,
        originX: 'left',
        originY: 'top',
        left,
        top,
        hasRotatingPoint: false,
        fill: 'rgba(0, 0, 0, 0.2)',
      });
    } else if(shape=='circle'){
      this.cropShape = new fabric.Circle({
        radius: 40,
        left:0,
        top:0,
        hasRotatingPoint: false,
      })
    }

    this.controller.canvas.add(this.cropShape);
    this.controller.canvas.setActiveObject(this.cropShape);
    this.cropObject.selectable = false;
    this.cropObject.evented = false;
    this.controller.canvas.renderAll();
  };

  /**
   * Finish crop image
   *
   */
  public finish_old() {
    const { left, top, width, height, scaleX, scaleY } = this.cropShape;

    const croppedImg = this.cropObject.toDataURL({
      left: left - this.cropObject.left,
      top: top - this.cropObject.top,
      width: width * scaleX,
      height: height * scaleY,
    });

    this.controller.setImage(this.cropObject, croppedImg);
    this.cancel();
  };

  public finish() {
    //this.cropShape.set('fill', '#FFFFFF')
    //this.cropShape.absolutePositioned=true;
    const abs = new fabric.Point(this.cropShape.left, this.cropShape.top)
    const rel = this.cropObject.toLocalPoint(abs, 'top', 'left')

    this.cropShape.clone((clippingPath)=>{
      if(this.cropObject.type == 'textbox'){
        const coords=clippingPath.calcACoords();
        const y=coords.tl.y,
          x=coords.tl.x,
          width=coords.tr.x-coords.tl.x,
          height=coords.bl.y-coords.tl.y;
        this.cropObject.fitBox={x,y,width,height};
      }else{
        clippingPath.top= (rel.y/this.cropObject.scaleY);
        clippingPath.left= (rel.x/this.cropObject.scaleX);
        clippingPath.calcACoords();
        clippingPath.scaleX /= this.cropObject.scaleX;
        clippingPath.scaleY /= this.cropObject.scaleY;
        this.cropObject.clipPath=clippingPath;
      }

      this.cancel(true);
    })
  };


  /**
   * Cancel crop
   */
  public cancel(removeClip=true) {
    this.controller.interactionMode = InteractionModes.SELECTION;
    this.cropObject.selectable = true;
    this.cropObject.evented = true;
    this.controller.canvas.setActiveObject(this.cropObject);
    if(removeClip)
      this.controller.canvas.remove(this.cropShape);
    this.cropShape = null;
    this.cropObject = null;
    this.controller.canvas.renderAll();
  };

  /**
   * Resize crop
   *
   * @param {FabricEvent} opt
   */
  public resize(opt: fabric.IEvent) {
    // TODO handle when canvas is zoomed

    if(this.cropObject.type == 'textbox')
      return;

    const { target, transform: { original, corner }, } = opt;
    const { left, top, width, height, scaleX, scaleY } = target;
    const {
      left: cropLeft,
      top: cropTop,
      width: cropWidth,
      height: cropHeight,
      scaleX: cropScaleX,
      scaleY: cropScaleY,
    } = this.cropObject;

    switch(corner){
      case 'tl':
        if (Math.round(cropLeft) > Math.round(left)) {
          // left
          const originRight = Math.round(cropLeft + cropWidth);
          const targetRight = Math.round(target.getBoundingRect().left + target.getBoundingRect().width);
          const diffRightRatio = 1 - (originRight - targetRight) / cropWidth;
          target.set({
            left: cropLeft,
            scaleX: diffRightRatio > 1 ? 1 : diffRightRatio,
          });
        }
        if (Math.round(cropTop) > Math.round(top)) {
          // top
          const originBottom = Math.round(cropTop + cropHeight);
          const targetBottom = Math.round(target.getBoundingRect().top + target.getBoundingRect().height);
          const diffBottomRatio = 1 - (originBottom - targetBottom) / cropHeight;
          target.set({
            top: cropTop,
            scaleY: diffBottomRatio > 1 ? 1 : diffBottomRatio,
          });
        }
        break;
      case 'bl':
        if (Math.round(cropLeft) > Math.round(left)) {
          // left
          const originRight = Math.round(cropLeft + cropWidth);
          const targetRight = Math.round(target.getBoundingRect().left + target.getBoundingRect().width);
          const diffRightRatio = 1 - (originRight - targetRight) / cropWidth;
          target.set({
            left: cropLeft,
            scaleX: diffRightRatio > 1 ? 1 : diffRightRatio,
          });
        }
        if (Math.round(cropTop + cropHeight * cropScaleY) < Math.round(top + height * scaleY)) {
          // bottom
          const diffTopRatio = 1 - (original.top - cropTop) / cropHeight;
          target.set({
            top: original.top,
            scaleY: diffTopRatio > 1 ? 1 : diffTopRatio,
          });
        }
        break;
      case 'tr':
        if (Math.round(cropLeft + cropWidth * cropScaleX) < Math.round(left + width * scaleX)) {
          // right
          const diffLeftRatio = 1 - (original.left - cropLeft) / cropWidth;
          target.set({
            left: original.left,
            scaleX: diffLeftRatio > 1 ? 1 : diffLeftRatio,
          });
        }
        if (Math.round(cropTop) > Math.round(top)) {
          // top
          const originBottom = Math.round(cropTop + cropHeight);
          const targetBottom = Math.round(target.getBoundingRect().top + target.getBoundingRect().height);
          const diffBottomRatio = 1 - (originBottom - targetBottom) / cropHeight;
          target.set({
            top: cropTop,
            scaleY: diffBottomRatio > 1 ? 1 : diffBottomRatio,
          });
        }
      case 'br':
        if (Math.round(cropLeft + cropWidth * cropScaleX) < Math.round(left + width * scaleX)) {
          // right
          const diffLeftRatio = 1 - (original.left - cropLeft) / cropWidth;
          target.set({
            left: original.left,
            scaleX: diffLeftRatio > 1 ? 1 : diffLeftRatio,
          });
        }
        if (Math.round(cropTop + cropHeight * cropScaleY) < Math.round(top + height * scaleY)) {
          // bottom
          const diffTopRatio = 1 - (original.top - cropTop) / cropHeight;
          target.set({
            top: original.top,
            scaleY: diffTopRatio > 1 ? 1 : diffTopRatio,
          });
        }
        break;
      case 'ml':
        if (Math.round(cropLeft) > Math.round(left)) {
          // left
          const originRight = Math.round(cropLeft + cropWidth);
          const targetRight = Math.round(target.getBoundingRect().left + target.getBoundingRect().width);
          const diffRightRatio = 1 - (originRight - targetRight) / cropWidth;
          target.set({
            left: cropLeft,
            scaleX: diffRightRatio > 1 ? 1 : diffRightRatio,
          });
        }
        break;
      case 'mt':
        if (Math.round(cropTop) > Math.round(top)) {
          // top
          const originBottom = Math.round(cropTop + cropHeight);
          const targetBottom = Math.round(target.getBoundingRect().top + target.getBoundingRect().height);
          const diffBottomRatio = 1 - (originBottom - targetBottom) / cropHeight;
          target.set({
            top: cropTop,
            scaleY: diffBottomRatio > 1 ? 1 : diffBottomRatio,
          });
        }
        break;
      case 'mb':
        if (Math.round(cropTop + cropHeight * cropScaleY) < Math.round(top + height * scaleY)) {
          // bottom
          const diffTopRatio = 1 - (original.top - cropTop) / cropHeight;
          target.set({
            top: original.top,
            scaleY: diffTopRatio > 1 ? 1 : diffTopRatio,
          });
        }
        break;
      case 'mr':
        if (Math.round(cropLeft + cropWidth * cropScaleX) < Math.round(left + width * scaleX)) {
          // right
          const diffLeftRatio = 1 - (original.left - cropLeft) / cropWidth;
          target.set({
            left: original.left,
            scaleX: diffLeftRatio > 1 ? 1 : diffLeftRatio,
          });
        }
        break;
    }
  };

  /**
   * Resize crop
   *
   * @param {FabricEvent} opt
   */
  public moving(opt: fabric.IEvent) {

    // TODO handle when canvas is zoomed
    //
    return;
    if(this.cropObject.type == 'image')
      return;

    const { target } = opt;
    const { left, top, width, height, scaleX, scaleY } = target;
    const {
      left: cropLeft,
      top: cropTop,
      width: cropWidth,
      height: cropHeight,
    } = this.cropObject.getBoundingRect();

    const movedTop = () => {
      if (Math.round(cropTop) >= Math.round(top)) {
        target.set({ top: cropTop, });
      } else if (Math.round(cropTop + cropHeight) <= Math.round(top + height * scaleY)) {
        target.set({ top: cropTop + cropHeight - height * scaleY, });
      }
    };

    if (Math.round(cropLeft) >= Math.round(left)) {
      target.set({ left: Math.max(left, cropLeft), });
      movedTop();
    } else if (Math.round(cropLeft + cropWidth) <= Math.round(left + width * scaleX)) {
      target.set({ left: cropLeft + cropWidth - width * scaleX, });
      movedTop();
    } else if (Math.round(cropTop) >= Math.round(top)) {
      target.set({ top: cropTop, });
    } else if (Math.round(cropTop + cropHeight) <= Math.round(top + height * scaleY)) {
      target.set({ top: cropTop + cropHeight - height * scaleY, });
    }
  };
}

export default CropHandler;
