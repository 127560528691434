import * as _ from 'underscore';
import * as Backbone from 'backbone';
// Taken from: https://medium.com/@bterlson/strongly-typed-event-emitters-2c2345801de8
interface Position { x: number, y: number };
interface IEvents {
  mouseMove: Position,
  done: void
}

// Same as Position, but using indexed access operator
// @ts-ignore
type MouseMovePayloadType = IEvents['mouseMove'];

interface TypedEventEmitter<T> {
  on<K extends keyof T>(s: K, listener: (v: T[K]) => void);
}
declare const myEventEmitter:TypedEventEmitter<IEvents>;

//myEventEmitter.on('mouseMove', function (p) { })
//myEventEmitter.on('done', function () { });

//myEventEmitter.emit('mouseMove', { x: 1, y: 1 });
//myEventEmitter.emit('done', undefined);

class BaseEvents {

  constructor() {
    _.extend(this, Backbone.Events);
  }

  on(eventName: string, callback?: Function, context?: any): any { return; };
  off(eventName?: string, callback?: Function, context?: any): any { return; };
  trigger(eventName: string, ...args: any[]): any { return; };
  bind(eventName: string, callback: Function, context?: any): any { return; };
  unbind(eventName?: string, callback?: Function, context?: any): any { return; };

  once(events: string, callback: Function, context?: any): any { return; };
  listenTo(object: any, events: string, callback: Function): any { return; };
  listenToOnce(object: any, events: string, callback: Function): any { return; };
  stopListening(object?: any, events?: string, callback?: Function): any { return; };
}

export {BaseEvents};
