
const featuresP = fetch("/flipper.json").then(response => response.json());

interface Features {
  ai_layers_panel_show: boolean;
  npc_panel_show: boolean;
  [key: string]: any;
}

export const getFeatures = async (): Promise<Features> => {
  const features = await featuresP;
  return features;
}
export const isFeatureEnabled = async (feature: string) => {
  const features = await featuresP;
  (window as any).features = features;
  return features[feature];
}

(window as any).isFeatureEnabled = isFeatureEnabled;

