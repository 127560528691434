const repeat=5;

const wobble = (scene:Phaser.Scene, target) => {
  // Define the wobble tween
  scene.tweens.add({
    targets: target,
    angle: { from: -10, to: 10 },  // Wobble between -10 and 10 degrees
    duration: 200,                 // Duration of one cycle (in ms)
    yoyo: true,                    // Tween back to the start value
    repeat,
    ease: 'Sine.easeInOut'         // Smooth easing for a natural wobble
  });

  // Optionally, you can add a slight vertical movement to make it more dynamic
  scene.tweens.add({
    targets: target,
    y: '+=10',                     // Move 10 pixels down and up
    duration: 400,                 // Duration of one cycle
    yoyo: true,
    repeat,
    ease: 'Sine.easeInOut'
  });

}

const pulse = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    scale: { from: 1, to: 1.2 },
    duration: 800,
    yoyo: true,
    repeat,
    ease: 'Sine.easeInOut'
  });
}

const tint = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    tint: { from: 0xffffff, to: 0xff0000 },  // Change from white to red
    duration: 500,
    yoyo: true,
    repeat,
  });
}

const shake = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    x: '+=10',
    duration: 50,
    yoyo: true,
    repeat,
    ease: 'Power2'
  });
}

const fade_in_out = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    alpha: { from: 1, to: 0 },
    duration: 1000,
    yoyo: true,
    repeat,
    ease: 'Quad.easeInOut'
  });
}

const bounce = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    y: '+=20',     // Move up and down
    duration: 500,
    yoyo: true,
    repeat,
    ease: 'Bounce.easeOut'
  });
};

const rotate = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    angle: 360,
    duration: 2000,
    repeat,
    ease: 'Linear'
  });
}

const zoom_in_out = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    scaleX: 1.5,
    scaleY: 1.5,
    duration: 1000,
    yoyo: true,
    repeat,
    ease: 'Cubic.easeInOut'
  });
}
  
const wiggle = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    x: '+=5',
    duration: 100,
    yoyo: true,
    repeat,
    ease: 'Sine.easeInOut'
  });
}

const floating = (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    y: '+=10',
    duration: 2000,
    yoyo: true,
    repeat,
    ease: 'Sine.easeInOut'
  });
}

const flash= (scene:Phaser.Scene, target) => {
  scene.tweens.add({
    targets: target,
    alpha: 0,
    duration: 100,
    yoyo: true,
    repeat,
  });
}

export const EffectNames = {
  wobble: 'Wobble',
  pulse: 'Pulse',
  tint: 'Tint',
  shake: 'Shake',
  fade_in_out: 'Fade in/out',
  bounce: 'Bounce',
  rotate: 'Rotate',
  zoom_in_out: 'Zoom in/out',
  wiggle: 'Wiggle',
  floating: 'Floating',
  flash: 'Flash'

} as const;

export type EffectName = typeof EffectNames[keyof typeof EffectNames];

export const effects = { wobble, pulse, tint, shake, fade_in_out, bounce, rotate, zoom_in_out, wiggle, floating, flash };

