import React from 'react';

const T: React.FC<any & { key?:string, k?: string; nohtml?: boolean; children?: any }> = (props) => {
  const { k, key, nohtml, children } = props;
  const opts = { _: children };
  // check that children is a string and NOT a collection of React nodes
  if (typeof children === 'string') {
    opts._ = children;
  } else if(Array.isArray(children)) {
    opts._ = children.filter(s => typeof s === 'string').join('');
  } else {
    opts._ = '';
  }

  let the_key = k || key || '';

  if(!the_key.startsWith('js.')) {
    the_key = `js.${the_key}`;
  }

  // Adding missing phrases
  if (!window.polyglot.phrases[the_key]) {
    window.polyglot.missing = window.polyglot.missing || {};
    window.polyglot.missing[the_key] = opts._;
  }

  // Rendering based on the `nohtml` prop
  if (nohtml) {
    return <>{window.polyglot.t(the_key, opts)}</>;
  }

  return (
    <span
      className="inibon"
      data-inibon-key={`${window.i18n_locale}.${the_key}`}
      dangerouslySetInnerHTML={{ __html: window.polyglot.t(the_key, opts) }}
    ></span>
  );
};

export { T };

