import { MAP_SET_FULLSCREEN, MAP_SET_MODE, MAP_SET_MUTE, MAP_SHOW_ANCHORS, MAP_SET_RECORDING, MAP_SET_ZOOM, MAP_SET_PEOPLE, MAP_EDIT_SCRIPT, MAP_SET_SPACE, MAP_SET_CLICK_AND_MOVE, MAP_SHOW_ZONES, MAP_SET_INVENTORY, MAP_SET_AVATAR, MAP_SHOW_WEB_RESOURCE, MAP_EDIT_NPC_PLACEMENT, MAP_EDIT_ANCHOR } from "constants/actionTypes"
import { MODES } from "components/world/constants"

const DEFAULT_MAP_STATE = {
  mode: MODES.Normal,
  fullscreen: false,
  mute: false,
  clickAndMove: true,
  zoom: 1,
}

export default (state:{[s:string]:any}=DEFAULT_MAP_STATE, action) => {

  switch (action.type) {
    case MAP_EDIT_NPC_PLACEMENT:
      return {
        ...state,
        edit_npc_placement: action.npc_placement,
      }

    case MAP_EDIT_ANCHOR:
      return {
        ...state,
        edit_anchor: action.anchor,
      }

    case MAP_EDIT_SCRIPT:
      return {
        ...state,
        edit_script: action.script,
        player: action.player,
      }

    case MAP_SET_SPACE:
      return {
        ...state,
        space: action.space,
        game: action.game,
      }

    case MAP_SHOW_ZONES:
      return {
        ...state,
        show_zones: action.show_zones,
      }

    case MAP_SHOW_WEB_RESOURCE:
      return {
        ...state,
        show_web_resource: action.web_resource,
        closeWebResource: action.closeWebResource,
      }

    case MAP_SHOW_ANCHORS:
      return {
        ...state,
        show_anchors: action.show_anchors,
      }

    case MAP_SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen,
      }

    case MAP_SET_INVENTORY:
      return {
        ...state,
        inventory: action.payload ? action.payload : null,
      }

    case MAP_SET_AVATAR:
      return {
        ...state,
        avatar: action.payload ? action.payload : null,
      }

    case MAP_SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }

    case MAP_SET_RECORDING:
      return {
        ...state,
        recording: action.recording,
        recording_name: action.name
      }

    case MAP_SET_ZOOM:
      return {
        ...state,
        zoom: Math.min(2, Math.max(0.2, action.zoom)),
      }

    case MAP_SET_CLICK_AND_MOVE:
      return {
        ...state,
        clickAndMove: action.clickAndMove,
      }

    case MAP_SET_MUTE:
      return {
        ...state,
        mute: action.mute,
      }

    case MAP_SET_PEOPLE:
      return {
        ...state,
        people: action.people,
      }

    default:
      return state;
  }
}

