import classnames from "classnames";
import {PanelTitles, useDesignContext} from "contexts/design_context";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPanel} from "redux/reducers/editor";

function PanelItem({ id}: any) {

  const { designController } = useDesignContext()
  const selectedPanel = useSelector((state:any)=>state.editor.selectedPanel);
  const dispatch = useDispatch();
  const classNames = classnames('rde-panel-item', selectedPanel == id ? 'rde-panel-item-selected' : '');

  return (
    <div className={classNames} onClick={() => {

      dispatch(setSelectedPanel(selectedPanel == id ? null : id));
      
      if(selectedPanel != id){
        designController.interactionHandler.selection();
      }
    }} >
      <div>{PanelTitles[id]}</div>
    </div>
  )
}

export default PanelItem
