import {
  CREATOR_LOAD_URL,
  CREATOR_SET_TRIM,
  CREATOR_SET_FRAMES,
  CREATOR_START_REMOVE_BG,
  CREATOR_REMOVED_BG,
  CREATOR_INVERTED_CHANGED,
  CREATOR_VELOCITY_RATIO,
  CREATOR_FINISHED,
  CREATOR_REMOVE_FRAME,
  CREATOR_OBJECT_MOVED
} from 'constants/actionTypes';

import { v4 as uuidv4 } from 'uuid';

const DEFAULT_CREATOR_STATE = {
  velocity_ratio: 100,
  inverted: 'left',
}

export default (state:{[s:string]:any}=DEFAULT_CREATOR_STATE, action) => {
  let idx;

  switch (action.type) {
    case CREATOR_LOAD_URL:
      return {
        ...state,
        file: action.file
      }
    case CREATOR_SET_TRIM:
      return {
        ...state,
        start: action.start,
        duration: action.duration,
      }

    case CREATOR_VELOCITY_RATIO:
      return {
        ...state,
        velocity_ratio: action.velocity_ratio
      }

    case CREATOR_SET_FRAMES:
      return {
        ...state,
        frames: action.frames.map((url, idx) => ({url, id: uuidv4()}))
      }

    case CREATOR_INVERTED_CHANGED:
      return {
        ...state,
        inverted: action.inverted
      }

    case CREATOR_REMOVE_FRAME:
      idx = state.frames.findIndex(f => f.id == action.id)
      return {
        ...state,
        frames: [
          ...state.frames.slice(0,idx),
          ...state.frames.slice(idx+1)
        ]
      }

    case CREATOR_START_REMOVE_BG:
      idx = state.frames.findIndex(f => f.id == action.id)
      return {
        ...state,
        frames: [
          ...state.frames.slice(0,idx),
          {...state.frames[idx], isLoading: true},
          ...state.frames.slice(idx+1)
        ]
      }

    case CREATOR_OBJECT_MOVED:
      idx = state.frames.findIndex(f => f.id == action.id)
      return {
        ...state,
        frames: [
          ...state.frames.slice(0,idx),
          {...state.frames[idx], top: action.top, left: action.left},
          ...state.frames.slice(idx+1)
        ]
      }

    case CREATOR_REMOVED_BG:
      idx = state.frames.findIndex(f => f.id == action.id)
      return {
        ...state,
        frames: [
          ...state.frames.slice(0,idx),
          {
            ...state.frames[idx],
            isLoading: false,
            mask_url: action.mask_url,
            url: action.url
          },
          ...state.frames.slice(idx+1)
        ]
      }

    case CREATOR_FINISHED:
      return {
        ...state,
        name: action.name,
        type: action.tipo,
      }

    default:
      return state;
  }
}



