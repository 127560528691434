import { Redirect } from "react-router-dom";
import React from 'react';
import { connect } from 'react-redux';
import {Spaces, Sounds, Anchors} from 'api/agent';
import {DirectUploadProvider} from 'react-activestorage-provider';

import * as ActiveStorage from 'activestorage';
import { pick_model } from "components/shared/pick_model";
import { SpaceAttributes } from "models/space";
import { MapController } from "maps/controller";
import { TileAttributes } from "models/tile";

type SpaceOverviewState = {
};

class SpaceOverview extends React.Component<{errors:any[], controller: MapController, onClose:Function,space: SpaceAttributes},SpaceOverviewState> {

  static defaultProps = {
  }

  render(){
    const width = 100 / this.props.space.width;
    return (
      <div className="row">
        <div className=" col-12 overview" style={{display: 'flex', flexWrap: 'wrap'}}>
          {/*this is  sort by y, then x*/}
          {this.props.space.tiles.sort((a,b) => (a.y*100000+a.x)-(b.y*100000+b.x)).map((t,i) => 
          <img
            src={t.public_filename}
            data-x={t.x}
            data-y={t.y}
            key={t.id}
            onClick={e => this.goToTile(t)}
            style={{border:'0.1px solid black', flex: `0 0 ${width}%`, maxWidth: `${width}%`}}></img>
          )}
        </div>
      </div>
    );
  }

  goToTile(t:TileAttributes): void {
    this.props.onClose();
    this.props.controller.moveCurrent(t.x,t.y);
  }

}

export {SpaceOverview}
