/*<reference path=”app/javascript/interfaces.d.ts” />*/

import { template, isNumber } from 'underscore';
import { SpriteView } from './sprite_view';
import { QuerySource } from 'hip/query_source';
import { Events } from 'maps/map_events';
import { Sprite } from 'models/sprite';
import {NounType} from 'hip/noun';
import { Keys } from 'constants/keyboard';
import { Avatar } from 'models/avatar';
import { Command, CommandProps } from 'hip/command';

const PIXELS_PER_SEC = 200;
const CHARS_PER_SEC = 10;

interface AvatarHolder {
  avatar_views: AvatarView<Avatar>[]
}

class AvatarView<Avatar> extends SpriteView<Sprite> {

  suggestion_template = template(`<div>
    <% suggestions.forEach(function(suggestion,i){ %>
      <div class="<%= hilited == i ? "hilited" : "" %>">
        <%= suggestion %>
      </div>
    <% }) %>
  </div>`
  );
  isLocal: boolean;
  avatar_id: string;
  $search_box: JQuery<HTMLInputElement>;
  gQs: QuerySource;
  $bubble: any;
  $status_line: any;
  $autocomplete: any;

  constructor(settings?) {
    super(settings);
    this.avatar_id = "Avatar_" + this.model.id;
    let sprites = [this.model.get("sprite") || this.model.get("sprites")].flat();
    super.initialize({sprites: sprites, width: 200});
    this.setupBubble();
    this.$search_box = this.$el.find("input");
    this.prefix = "/";
    this.isLocal = settings.isLocal;
    this.given_name = settings.name;
    this.dispatchCreated('AvatarView');
  }

  play_sound(sound_id){
    this.dispatcher.trigger(Events.PLAY_SOUND, {
      id: sound_id,
      where: this.$other,
      thing: this
    });
  }
  
  show_web_resource(web_resource_id):Promise<void>{

    return new Promise((a,b)=>{
      this.dispatcher.trigger(Events.SHOW_WEB_RESOURCE, {
        id: web_resource_id,
        where: this.$other,
        thing: this, 
        promise: {accept:a,reject:b},
      });
    })

  }

  show_text(text_id){

    this.dispatcher.trigger(Events.SHOW_TEXT, {
      id: text_id,
      where: this.$other,
      thing: this
    });

  }

  show_picture(picture_id){

    this.dispatcher.trigger(Events.SHOW_PICTURE, {
      id: picture_id,
      where: this.$other,
      thing: this
    });

  }

  play_video(video){

    this.dispatcher.trigger(Events.PLAY_VIDEO, {
      id: video,
      where: this.$other,
      thing: this
    });

  }


  execute(cmd_json:CommandProps) {
    const command = Command.fromJSON(cmd_json)
    command.execute(this)
  }

  setModel(model) {
    this.model = model;
    this.initialize_animations([this.model.get("sprite") || this.model.get("sprites")].flat());
    this.delegateEvents();
  }

  setHip(verbs, nouns:NounType[]) {
    if (!this.isLocal) {
      return;
    }
    this.gQs = new QuerySource(verbs, nouns, this);
    this.$bubble.prepend(`<div class="hip status"></div>`);
    this.$status_line = this.$bubble.find(".status");
    this.$bubble.append(`<div class="hip autocomplete"></div>`);
    this.$autocomplete = this.$bubble.find(".autocomplete");
  }

  classNames() {
    return super.classNames() + (this.isLocal ? " isLocal" : "");
  }

  events() {
    return Object.assign(super.events(), {
      "keydown input": e => this.handle_keydown(e),
      "keypress input": e => this.handle_keypress(e),
      "submit form": e => this.handle_submit(e),
      "blur input": e => this.handle_blur(e)
    });
  }

  get coords():{x:number,y:number} {
    return {x:this.left,y:this.top}
  }

  time_to_walk(x,y):[number,number]{
    const newLeft = x- this.width/2;
    const newTop = y - this.$outer.height()/2;

    const px_dist = distance([newLeft,newTop],[this.left,this.top]);
    return [px_dist, pixelsToTimeMs(px_dist)];
  }
  /* OVERRIDES 
   * Read Sprite.walk documentation to understand coordinates
   * in general: (x,y) means the position of a character, (top,left) means its top-left corner offset to the map top-left corner
   */
  walk(x:number, y:number, time:number=null, fps:number=null, noAnim:boolean=false):Promise<void> {
    const newLeft = x- this.width/2;
    const newTop = y - this.$outer.height()/2;

    if(!isNumber(time)){
      const [px_dist,a_time] = this.time_to_walk(x,y);
      time=a_time;
      fps = 100*(px_dist/time)
    }
    fps = fps || 25;

    const promis=super.walk(newLeft, newTop, time, fps, noAnim);

    if (this.isLocal) {
      this.dispatcher.trigger(Events.LOCAL_MOVED, { x, y, avatar: this });
    }
    return promis;
  }

  // OVERRIDES 
  walk_relative(left:string, top:string, time:number=null, fps:number=null, noAnim:boolean=false):Promise<void> {
    console.log('relative', {left,top})
    return this.walk(this.left + this.width/2 + parseFloat(left),this.top + this.$outer.height()/2 + parseFloat(top),time,fps,noAnim);
  }

  updateDisplay() {
    const suggestions = this.gQs.suggestions_html();
    const hilitedSuggestion = this.gQs.hilited_idx;
    const description = this.gQs.getDescriptionText();

    this.$status_line.html(description).show();
    if (!hilitedSuggestion) {
      this.$search_box.css("background-color", "lightgreen");
    } else {
      this.$search_box.css("background-color", "white");
    }
    this.$autocomplete.html(this.suggestion_template({
      suggestions: suggestions,
      hilited: hilitedSuggestion
    })).show();
  }

  handle_blur(e) {
    super.handle_blur(e);
    this.$autocomplete?.hide()
    this.gQs?.clear()
  }

  async handle_submit(event) {
    try{
      if (!this.gQs || (this.$search_box.val() as string).indexOf(this.prefix) !== 0) {
        return super.handle_submit(event);
      }
      const result = await this.gQs.execute(this.map);
      this.$search_box.val("");
      this.gQs.clear();
      this.$autocomplete.hide();
      this.$status_line.hide();
    }catch(e){
      console.error('An error would have submitted:',e);
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    return false;
  }

  handle_keydown(event) {

    const orig_input = event.target.value + String.fromCharCode(event.which);

    if (orig_input.indexOf(this.prefix) !== 0) {
      return super.handle_keydown(event);
    }
    let input = orig_input.substring(this.prefix.length);
    switch (event.which) {
      case Keys.ESC:
        event.target.value = "";
        this.gQs.clear();
        break;
      case Keys.UP:
        this.gQs.indication_up();
        break;
      case Keys.DOWN:
        this.gQs.indication_down();
        break;
      case Keys.ENTER:
        try {
          this.gQs.execute(null);
          this.$search_box.val("");
          this.gQs.clear();
        } catch (error) {
          const e = error;
          console.error('Error executing...', e);
        }
        break;
      case Keys.TAB:
        input = event.target.value;
        if (input.indexOf(this.prefix) === 0) {
          //const the_auto = this.gQs.autocomplete(input.substring(this.prefix.length));
          this.$search_box.val(this.prefix + this.gQs.autocomplete(input.substring(this.prefix.length)));

          if(!this.gQs.hilited_suggestion?.getDirectObject){
            this.setCursorPosition(event.target, `/${this.gQs.hilited_suggestion?.verb?.name} "`.length)
          }

          event.preventDefault();
          event.stopImmediatePropagation();
          this.gQs.updateSuggestionList((this.$search_box.val() as string).substring(this.prefix.length));
          this.updateDisplay();
          return false;
        }
        break;
      case Keys.BACKSPACE:
        return true;
      default:
        return true;
    }
    this.updateDisplay();
    return false;
  }

  setCursorPosition(elem, pos){
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }


  handle_keypress(event) {
    const orig_input = event.target.value + String.fromCharCode(event.which);

    if (orig_input.indexOf(this.prefix) !== 0) {
      this.gQs?.clear();
      return super.handle_keydown(event);
    }

    const input = orig_input.substring(this.prefix.length);

    switch (event.which) {
      case Keys.ESC:
      case Keys.UP:
      case Keys.DOWN:
      case Keys.ENTER:
      case Keys.TAB:
      case Keys.SPACE:
        break;
      default:
        this.gQs?.updateSuggestionList(input)?.then((update) => {
          if (update) this.updateDisplay();
        });
    }
    this.updateDisplay();
  }

};

const distance = ([x,y],[newx,newy]):number => Math.sqrt((newx-x)**2 + (newy - y)**2)
const pixelsToTimeMs = (px_dist):number => 500 + 1000*(px_dist**0.7)/PIXELS_PER_SEC;
const charactersToTimeMs = (words):number => 1000*words.length/CHARS_PER_SEC;

export { AvatarView, pixelsToTimeMs, charactersToTimeMs, distance, AvatarHolder};
