import { BaseModel } from "./base_model";
import _, { sortBy } from "underscore";
import { AvatarView } from "maps/views/avatar_view";
import { Avatar } from "./avatar";
import { ScriptExecutor } from "./script_executor";
import { ModelSaveOptions } from "backbone";
//const TIME_IDX=0;
const ACTION_IDX=1;
const ARGS_IDX=2;

interface ScriptTarget {
  x:number,
  y:number,
  executor: ScriptExecutor, 
  script_avatars: Array<AvatarView<Avatar>>;
  scenes: { [name:string]: ScriptProps }
}

type ScriptAttributes = {
  id: string,
  uuid?: string,
  user_id?: string,
  description?: string,
  name: string,
  branches?: {[branch_name:string]: ScriptProps}, 
  script: ScriptProps, 
  metadata: any, 
  players: { [name:string]: string },
  game_id?: string
}

type Scripted = [/*actor:*/string, /*action:*/string,/*args*/any[]];
type Sloted = [/*slot:*/number,/*actor:*/string, /*action:*/[string,[any]]];
type UnSloted = [/*time:*/number,/*action:*/string, /*args:*/any[]/*(WalkArgs|TeletransportArgs|SayArgs)*/]
type WalkArgs = [number,number]
type TeletransportArgs = [number,number]
type SayArgs = [string,number]

type ScriptProps = {
  [character:string]: UnSloted[],
}

class Script extends BaseModel {

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Script');
  }

  _className() {
    return "Script";
  }

  initialize(attrs, opts) {
    return super.initialize(attrs, opts);
  }

  clone(){
    return new Script(JSON.parse(JSON.stringify(this.attributes)), {dispatcher: this.dispatcher})
  }

  get uuid(): string{
    return this.get('uuid')
  }
  get name(): string {
    return this.get('name')
  }
  get description(): string{
    return this.get('description')
  }

  get script2(): Scripted[]{
    if(this.version == 2){
      return this.get('script');
    }else{
      return sortBy(Object.keys(this.script).map((character) => this.script[character].map((x) => [character,...x])).flat(1) , (x)=>x[1])
        .map(([actor,time,action,args])=> [actor as string,action as string,args as [any]]);
    }
  }

  get script(): ScriptProps{
    return this.get('script')
  }

  get metadata(): any{
    return this.get('metadata')
  }

  set players(players:{ [name:string]: string }) {
    this.set('players', players)
  }

  get version(): number {
    return this.get('version') || 1;
  }

  get players(): { [name:string]: string } {
    return this.get('players') || {}
  }

  relativize2(new_x, new_y) {
    const clon = this.clone();
    const {x:start_x,y:start_y} = this.get('metadata').coords;

    for(let action of clon.script2){
      switch(action[ACTION_IDX]){
        case 'walk':
          const [to_x, to_y] = (action[ARGS_IDX] as any) as WalkArgs;
          action[ARGS_IDX] = [new_x+(to_x-start_x), new_y+(to_y-start_y)];
          break;
        case 'teletransport':
          const [tele_x, tele_y] = (action[ARGS_IDX] as any) as TeletransportArgs;
          action[ARGS_IDX] = [new_x+(tele_x-start_x), new_y+(tele_y-start_y)]
          break;
      }
    }

    return clon;
  }

  relativize(new_x, new_y) {
    if(this.version == 2){
      return this.relativize2(new_x,new_y)
    }
    const clon = this.clone();
    const {x:start_x,y:start_y} = this.get('metadata').coords;

    for(let character in clon.script){
      for(let action of clon.script[character]){
        if(action[ACTION_IDX] == 'walk'){
          const [to_x, to_y] = (action[ARGS_IDX] as any) as WalkArgs;
          action[ARGS_IDX] = [new_x+(to_x-start_x), new_y+(to_y-start_y)]
        }
        if(action[ACTION_IDX] == 'teletransport'){
          const [to_x, to_y] = (action[ARGS_IDX] as any) as TeletransportArgs;
          action[ARGS_IDX] = [new_x+(to_x-start_x), new_y+(to_y-start_y)]
        }
      }
    }

    return clon;
  }

  derelativize(new_x, new_y) {
    const clon = this;
    const {x:start_x,y:start_y} = this.get('metadata').coords;

    for(let character in clon.script){
      for(let action of clon.script[character]){
        if(action[ACTION_IDX] == 'walk'){
          const [to_x, to_y] = (action[ARGS_IDX] as any) as WalkArgs;
          action[ARGS_IDX] = [(to_x+start_x)-new_x, (to_y+start_y)-new_y]
        }
        if(action[ACTION_IDX] == 'teletransport'){
          const [to_x, to_y] = (action[ARGS_IDX] as any) as TeletransportArgs;
          action[ARGS_IDX] = [(to_x+start_x)-new_x, (to_y+start_y)-new_y]
        }
      }
    }

    return clon;
  }

  textTimelineV2(ms=null) {
    let retval='';
    let actor = null;

    if(this.version == 2){
      for(let x of this.script2){
        const [character,action,args]=x;
        if(character != actor){
          actor = character;
          retval += `${actor}:\n`;
        }
        retval = retval + `- ${action} ${(args as any).map(x=>JSON.stringify(x)).join(',')}\n`;
      }

    }else{
      const sorted = sortBy(Object.keys(this.script || {}).map((character) => this.script[character].map((x) => [character,...x])).flat(1) , (x)=>x[1]);

      for(let x of sorted){
        if(x[0] != actor){
          actor = x[0]
          retval += `${actor}:\n`
        }
        retval = retval + `- ${x[2]} ${(x[3] as any[]).map(x=>JSON.stringify(x)).join(',')}\n`;
      }
    }
    return retval;
  }

  textTimeline(ms) {
    return this.bySlots(ms).map((thingies:{[name:string]: [[string,[any]]]}) => {
      return Object.keys(thingies).map((name) => {
        return thingies[name].map(([action, args]) => `"${name}".${action}(${(JSON.stringify(args).slice(1, -1))})`).join('; ');
      }).join('; ');
    }).join("\n");
  }

  bySlots(ms):Array<{[name:string]: Array<[string,[any]]> }> {
    const timed = scriptByTime(this.script, ms);

    return Array.from(Array(Math.ceil(this.duration() / ms))).map((x,idx) => {
      return timed.filter(([slot,actor,action]) => slot == idx).reduce((acc,[slot,actor,action]) => {
        acc[actor] = acc[actor] || [];
        acc[actor].push(action)
        return acc;
      }, {})
    });
  }

  positionsAt(ms): {[name:string]: [number,number]} {

    const max = Object.values(this.script).reduce((max,items)=> items.map(x=>x[0]).sort()[items.length-1] > max ? items.map(x=>x[0]).sort()[items.length-1] : max, 0)

    return Object.keys(this.script).reduce((memo, name) => {
      const actions = this.script[name];
      const walks = actions.filter((v) => v[1].match(/^walk/));
      const args = walks.find((walk, idx) => (ms >= walk[0]) && ms < (walks[idx + 1] || [max])[0]);
      memo[name] = args ? [ args[2][0], args[2][1] ] : actions[0][2];
      return memo;
    }, {});
  }

  duration (){
    return durationMs(this.script)
  }

  actionsAfter(ms) {
    return _(this.script).inject((memo, actions, avatar) => ({
        [avatar]: actions.filter(([time]) => time >= ms).map(([time,action,args]) => [time - ms, action, args]),
        ...memo
      })
    , {});
  }

  url = () => {
    return [(window as any).Niconitos.config.api, "scripts", this.id || ''].join('/');
  }

};

const durationMs = (script:ScriptProps):number => {
  return Object.values(script).reduce((max:number,actions):number => {
    const actor_max = actions.map(action => action[0]).sort((a,b)=>a-b).pop();
    return actor_max > max ? actor_max : max;
  },0)
}


const fromTimeline = (slots:Scripted[], ms):ScriptProps => {
  return slots.reduce((retval, actions, idx) => {
    if (!actions) return retval;

    actions.forEach((action) => {
      let [name, verb, args] = action;
      retval[name] || (retval[name] = []);
      if(verb == 'walk' && _.isString(args[0])){
        verb='walk_relative';
      }
      retval[name].push([idx * ms, verb, args]);
    })
    return retval
  }, {})
}

const scriptByTime = (script,ms):Sloted[] => {

  const results = Object.keys(script).map( (actor) =>
    script[actor].map((action) =>
      [ Math.floor(action[0] / ms), actor, action.slice(1)]
    )
  )

  return _(results).flatten(true).sort((a, b) => (a[0] as number) - (b[0] as number)) as Sloted[]

}

const parseScriptV2 = (txt):Scripted[] => {
  const retval:Scripted[] = [];
  let actor=null;

  txt.split(/\n/).forEach((line) => {
    if(line.match(/^ *$/))
      return;

    if (!line.length) return [];

    const m = line.match(/^\d+$/);

    if (m) {
      //Array.from(Array(parseInt(m)).map(x=>null));
    } else if (line.match(/^[^\- \*]/)) {
      actor=line.trim().replace(/: *$/g,'');
    } else if (line.match(/^[\- \*]/)) {
        const [ , verb, args] = line.match(/^[\- \*]*([^ ]*) (.*)/);
        const name=actor;
        const action = JSON.parse(`[${args}]`);
        retval.push([name, verb, action]);
    } else {
    }
  })
  return retval;
}

const parseScript = (txt):Scripted[] => {
  return txt.split(/\n/).map((line) => {
    if (!line.length) return [];

    const m = line.match(/^\d+$/);

    if (m) {
      return Array.from(Array(parseInt(m)).map(x=>null));
    } else if (line.match(/^"([^\"]*)"\.(\w*)\((.*)\)/)) {
      return line.split(/; */).map((ac) => {
        // @ts-ignore ignore match not being used
        const [match, name, verb, args] = ac.match(/"([^\"]*)"\.(\w*)\((.*)\)/);
        const action = JSON.parse(`[${args}]`);
        return [name, verb, action];
      })
    } else {
      return void 0;
    }
  })
}


export { Script, ScriptTarget, ScriptAttributes, scriptByTime, fromTimeline, parseScript, ScriptProps, parseScriptV2};
