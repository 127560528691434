
import React, { useState } from 'react';
import ListErrors from 'components/shared/ListErrors';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WebResourceAttributes } from 'models/web_resource';

export default function WebResourceForm({errors, submitForm, creation_status, web_resource}:{errors:string[],submitForm:(any)=>void,creation_status:string,web_resource?:Partial<WebResourceAttributes>}){

  const [state,setState] = useState(web_resource || {});

  return <>
  <ListErrors errors={errors} />

  <form className="form-horizontal" method="post" onSubmit={ev => {ev.preventDefault();submitForm(state)}}>
    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="web_resource_Name">Name:</label>
      <div className="col-md-8">
        <input className="form-control" type="text" value={state.name} name="web_resource[name]" onChange={(e) => setState({...state, name:e.target.value})} />
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="web_resource_url">Url:</label>
      <div className="col-md-8">
        <input className="form-control" value={state.url} name="web_resource[url]" onChange={(e) => setState({...state, url:e.target.value})}></input>
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-12 col-md-4" htmlFor="web_resource_description">Description:</label>
      <div className="col-md-8">
        <textarea className="form-control" value={state.description} name="web_resource[description]" onChange={(e) => setState({...state, description:e.target.value})}></textarea>
      </div>
    </div>

    <Modal
      open={!!creation_status}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {
        (creation_status == 'creating' ?
        <CircularProgress />
        : 
        <div className="alert alert-danger">
          Error creating
        </div>
        )
      }
    </Modal>

    <div className="form-group">
      <div className="col-md-8 col-md-offset-4">
        <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
      </div>
    </div>

  </form>
</>
}
