// SelectVideoModal.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {VideoAttrs} from 'models/video';
import {useDispatch, useSelector} from 'react-redux';
import {Videos} from 'api/agent';
import {setVideos} from 'redux/reducers/editor';
import VideoUploadForm from './new';

interface SelectVideoModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (video: VideoAttrs) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    videoItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    videoHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    audioControls: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    audioPlayer: {
      display: 'none',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

const SelectVideoModal: React.FC<SelectVideoModalProps> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const videos:Array<VideoAttrs> = useSelector((state:any) => state.editor?.videos);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeVideo, setActiveVideo] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchVideos();
    }
    // Cleanup on close
    return () => {
      setActiveVideo(null);
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, [open, searchQuery]);

  const refetchVideos = () => {
    Videos.list({ query: searchQuery }).then((videos) => {
      dispatch(setVideos(videos));
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (video: VideoAttrs) => {
    onSelect(video);
  };

  const handlePlay = (video: VideoAttrs) => {
    if (activeVideo === video.uuid) {
      // Stop if already playing
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      setActiveVideo(null);
    } else {
      // Play new audio
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      videoRef.current = document.createElement('video');
      videoRef.current.src = video.url;
      videoRef.current.play();
      videoRef.current.onended = () => setActiveVideo(null);
      setActiveVideo(video.uuid);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if(!videos) return null;

  // Group videos by category
  const groupedVideos = videos.reduce<Record<string, VideoAttrs[]>>((groups, video) => {
    const category = 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(video);
    return groups;
  }, {});

  const OneVideo = ({ video:snd, hit }: { video?: VideoAttrs, hit?:any}) => {

    let video;
    if(snd) {
      video = snd;
    } else {
      const {uuid, name, duration, description, url} = hit;
      video = {uuid, name, duration, description, url};
    }

    return <ListItem button key={video.uuid} onClick={() => handleSelect(video)}>
      <div className={classes.videoItem}>
        <div className={classes.videoHeader}>
          <ListItemText primary={video.name} />
          <IconButton
            edge="end"
            aria-label={activeVideo === video.uuid ? 'Stop preview' : 'Play preview'}
            onClick={(e) => {
              e.stopPropagation();
              handlePlay(video);
            }}
          >
            {activeVideo === video.uuid ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton>
        </div>
        <Typography variant="body2" className={classes.description}>
          ({video.duration} sec.) {video.description}
        </Typography>
      </div>
    </ListItem>
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select a Video</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Your videos" />
          <Tab label="Create video" />
        </Tabs>
        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <>
              <TextField
                label="Search Videos"
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.list}>
                  {Object.entries(groupedVideos)
                    .sort(([a, videosA], [b, videosB]) => videosB.length - videosA.length)
                    .map(([category, videos]) => (
                      <div key={category}>
                        <ListSubheader>{category}</ListSubheader>
                        {videos.map((video) => (
                          <OneVideo key={video.uuid} video={video} />
                        ))}
                      </div>
                    ))}
                  {videos.length === 0 && !loading && (
                    <ListItem>
                      <ListItemText primary="No videos found." />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {activeTab === 1 && (
            <VideoUploadForm onCreated={(video)=>{handleSelect(video)}} />
          )}

        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectVideoModal;

