import * as _ from 'underscore';
import { EditorView } from './editor';
import { Picture } from 'models/picture';

class EditPictureView extends EditorView<Picture> {

  className = ' EditPicture'
  file: any;

  copies= {
    success_title: 'Success!',
    success_subtitle: 'Your picture has been saved',
  }

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    return super.initialize(settings);
  }

  events() {
    return _.extend(super.events(), {});
  }

  previewImage(url) {
    return this.$('.the_preview').attr('src', url);
  }

  createFormData() {
    const fd = new FormData();
    const f = this.file || (this.$('input[type=file]')[0] as any).files[0];
    fd.append('picture[image]', f, f.name);
    fd.append('picture[name]', this.model.get('name'));
    fd.append('picture[description]', this.model.get('description'));
    return fd;
  }

  attachmentInput() {

    this.div$('.form-group', () => {
      this.label$('.control-label.col-md-4', { for: 'attachment' }, 'Picture');
      this.div$('.col-md-8.dropzone', () => {
        this.input$('.form-control', { type: 'file', name: 'attachment' });
      });
    });

    this.div$('.form-group', () => {
      this.label$('.control-label.col-md-4', 'Preview');
      this.div$('.col-md-8.dropzone', () => {
        this.img$('.img-fluid.the_preview', { src: this.model.get('image_url') });
      });
    });
  }

  render() {
    return super.render();
  }

};

export {EditPictureView};
