import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function NamePrompt(props:{onSave:Function, open:boolean}) {

  const [name, setName] = useState<string>('');

  const handleCancel = () => {
    props.onSave(null);
  }

  const handleSave = () => {
    props.onSave(name);
  };

  return (
      <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ponle un nombre a tu escena</DialogTitle>
        <DialogContent>
          <DialogContentText>
Para poder referirnos la escena que vas a crear, dale un nombre:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={(ev) => setName(ev.target.value)}
            value={name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
  );
}


