import _ from 'underscore';

const dotry = function(...args: any[]) {
  return _(Array.prototype.slice.call(arguments,1)).inject(function(memo,v,k){
    if(!memo)
      return null;

    if(_.isArray(v)){
      if(memo[v[0]]){
        return memo[v[0]].apply(memo,v.slice(1))
      }else{
        return null;
      }
    } else if(_.isString(v)){
      return memo[v];
    }
    return null;
  },arguments[0])
};

function underscore(string) {
  return string.replace(/::/g, '/')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/-/g, '_')
    .toLowerCase();
};

function camelize (string) {
  return string.replace(/-+(.)?/g, function(match, chr) {
    return chr ? chr.toUpperCase() : '';
  });
};

const timestamp = Date.now || function(){return (new Date()).getTime()}

export {dotry, underscore, camelize, timestamp};
