import React from 'react'
import { Component } from 'react'
import VideoPreview from './preview/video'
import { connect } from 'react-redux';
import { parseUrl} from 'shared/components/inputs/url-input'
import RangeSlider from 'shared/components/inputs/range-slider'
import { throttle, memoize } from 'underscore'
import { secondsToTime } from 'shared/util/numbers'
import { CREATOR_SET_TRIM, CREATOR_SET_FRAMES, CREATOR_START_REMOVE_BG, CREATOR_REMOVED_BG } from 'constants/actionTypes'
import { push } from 'connected-react-router'
import { trimVideo, removeBackground } from 'shared/util/video-parse'
import { store } from 'redux/store'
import { hasTransparency } from 'shared/util/image-pixels'
//import { URL } from 'url'

export const FPS = 15
const MAX_FRAMES=60;
const MIN_FRAMES = 1
const DEFAULT_FRAMES = 2*FPS;
const SLIDE_DELAY = 800
const PREVIEW_WIDTH = 400;

const checkIfVideoEmbed = ({ type, fileType }) => type === 'url' && (fileType === 'youtube' || fileType === 'vimeo')
const extractTime = ({ type, fileType, file:sourceUrl }:FileProps):number => {
  if(type !== 'url')
    return null;

  const url=new URL(sourceUrl);

  if(fileType === 'youtube'){
    return parseInt(url.searchParams.get('t') || '0');
  } else if(fileType === 'vimeo'){
    return parseInt(url.hash.slice(1).split('&').reduce((acc, x) => ({...acc, [x.split('=')[0]]: x.split('=')[1]}),{})['t'] || '0')
  }
}

type FileProps = {
  file?: string,
  type: string,
  fileType: string
  width: number,
  height: number,
}
type TrimmerProps = {
  match:any,
  isProcessing?:boolean,
  isCompact?:boolean,
  onChange:Function,
  onReady:Function,
  onFinish:Function,
  startFrame?:number,
  nframes?:number,
  file: FileProps
}

type TrimmerState = {
  startFrame:number,
  nframes:number,
  isReady:boolean,
  isVideoEmbed:any,
  isPlaying:boolean,
  pauseFrame:number,
  maxFrames:number,
  file?: FileProps
}

class Trimmer extends Component<TrimmerProps & {history:any, childen:any },TrimmerState> {

  static defaultProps = {
    isCompact: false,
    isProcessing: false,
  }

  constructor(props){
    super(props);
  }

  state = {
    file: null,
    startFrame: 0,
    maxFrames: MAX_FRAMES,
    nframes: DEFAULT_FRAMES,
    pauseFrame: 0,
    isReady: false,
    isPlaying: false,
    isVideoEmbed: false,
  }

  slideTimeout = null
  isReady: any

  componentDidMount() {
    let { startFrame, nframes} = this.props

    let file = this.props.file;

    if(this.props.match?.params?.url){
      file = parseUrl(decodeURIComponent(this.props.match.params.url))
      if(!file)
        file = this.props.file
    }

    if(!file){
      this.props.history.push('/creator/upload')
      return;
    }

    const isVideoEmbed= checkIfVideoEmbed(file);

    if(isVideoEmbed){
      startFrame = extractTime(file) * FPS;
      nframes = DEFAULT_FRAMES;
    }else{
      startFrame = 0;
      nframes = DEFAULT_FRAMES;
    }

    if(file instanceof File){
      file.file = window.URL.createObjectURL(file);
    }

    this.setState({
      file,
      isVideoEmbed,
      startFrame,
      nframes,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    const { startFrame, nframes, isReady, isVideoEmbed } = this.state;

    if (isReady !== prevState.isReady || startFrame !== prevState.startFrame || nframes !== prevState.nframes) {
      onChange({
        startFrame,
        nframes,
        isVideoEmbed,
      })
    }
  }

  startSlideTimeout() {

    clearTimeout(this.slideTimeout)

    this.slideTimeout = setTimeout(() => {
      const { nframes } = this.state
      this.setState({ isPlaying: nframes > 1 })
    }, SLIDE_DELAY)
  }

  onStartChange(startFrame) {
    const { nframes, maxFrames } = this.state
    if (startFrame + nframes < maxFrames) {
      this.setState({
        isPlaying: false,
        pauseFrame: startFrame,
        startFrame,
      })
      this.startSlideTimeout()
    }
  }

  onDurationChange(nframes) {
    const { startFrame, maxFrames } = this.state;
    const isTooLong = startFrame + nframes > maxFrames
    this.setState({
      isPlaying: false,
      pauseFrame: startFrame + nframes,
      startFrame: isTooLong ? maxFrames - nframes : startFrame,
      nframes,
    })
    this.startSlideTimeout()
  }

  onVideoLoaded(maxFrames) {
    if (this.isReady) return
    this.isReady = true

    const { nframes } = this.state

    this.setState({
      isReady: true,
      isPlaying: true,
      nframes: Math.min(nframes, maxFrames),
      maxFrames,
    })

    if(this.props.onReady)this.props.onReady(maxFrames)
  }

  back(){
    this.props.history.push('/creator/upload')
  }

  nextStep(){
    const { startFrame, nframes } = this.state;
    this.props.onFinish({video: this.state.file, start: startFrame / FPS, duration: nframes / FPS});
  }

  render() {
    const { children, isCompact, isProcessing } = this.props;
    const { file, startFrame, pauseFrame, nframes, maxFrames, isPlaying, isReady, isVideoEmbed } = this.state;

    if(!file) return <div></div>;

    const aspectRatio = file.height / file.width;
    const previewHeight = Math.min(600, PREVIEW_WIDTH * aspectRatio) || 400;

    return (
      <div className="trim-container col-12 col-md-6 offset-md-3" data-loading={!isReady || isProcessing}>
        <div className="trim-preview" style={{ height: previewHeight }}>
          <VideoPreview
            className="trim-frame"
            file={file}
            fps={FPS}
            startFrame={startFrame}
            pauseFrame={pauseFrame}
            duration={nframes}
            height={previewHeight}
            isPlaying={isPlaying}
            isProcessing={isProcessing}
            onReady={this.onVideoLoaded.bind(this)}
          />

          <div className="row">
            <div className="col-12">
              Start: {secondsToTime(startFrame / FPS)}    Duration: {secondsToTime(nframes / FPS)}
            </div>
          </div>

          <RangeSlider
            duration={nframes}
            min={0}
            max={maxFrames}
            maxDuration={Math.min(MAX_FRAMES,maxFrames)}
            fps={FPS}
            start={startFrame}
            isReady={true}
            onDurationChange={throttle(this.onDurationChange.bind(this), 1000)}
            onStartChange={throttle(this.onStartChange.bind(this), 1000)}>
          </RangeSlider>

      
          <div className="btn btn-link float-left" onClick={this.back.bind(this)}>
            <i className="fa fa-arrow-left"></i>
            Cambiar video
          </div>

          <div className="btn btn-link float-right" onClick={this.nextStep.bind(this)}>
            Continuar
            <i className="fa fa-arrow-right"></i>
          </div>

        </div>
      {children}
    </div>
)
    }
}

let currentFrames;

const handleChange = async () => {

  let previousFrames = currentFrames;
  currentFrames = (store.getState().creator as any).frames;

  if (previousFrames === currentFrames)
    return;

  if(currentFrames.filter(x => x.isLoading).length >= 2)
    return

  const fr =currentFrames.find(x => !(x.mask_url || x.isLoading))

  if(!fr)
    return;

  const {url} = fr;
  store.dispatch({ type: CREATOR_START_REMOVE_BG, id: fr.id });

  let mask_url;

  if(url.match(/^data:image\/png/)){
    if(await hasTransparency(url)){
      mask_url = url
    } else {
      const o = await removeBackground(url)
      mask_url = o.mask_url;
    }
  }else{
    const o = await removeBackground(url)
    mask_url = o.mask_url;
  }
  store.dispatch({ type: CREATOR_REMOVED_BG, id: fr.id, mask_url, url});
}

const unsubscribe = store.subscribe(handleChange);

const mapStateToProps = ({creator}) => ({
  file: creator.file
})

const mapDispatchToProps = (dispatch) => ({

  onFinish: ({video, start,duration}) => dispatch( dispatch => {

    dispatch({ type: CREATOR_SET_TRIM, start,duration })

    trimVideo(video,start,duration).then(({frames}) => {
      dispatch({ type: CREATOR_SET_FRAMES, frames });
    })

    dispatch(push('/creator/chroma'))
  }),

  onChange: (e) => console.log('Changed', e)
})

export default connect(mapStateToProps,mapDispatchToProps)(Trimmer)
