;(function(Form) {

var template = _.template('\
    <div style="display: none;" class="wy-toolbar"> \
      <a data-wysihtml5-command="bold" title="CTRL+B">bold</a> | \
      <a data-wysihtml5-command="italic" title="CTRL+I">italic</a> | \
      <a data-wysihtml5-command="createLink">insert link</a> | \
      <a data-wysihtml5-command="insertImage">insert image</a> | \
      <a data-wysihtml5-command="formatBlock" data-wysihtml5-command-value="h1">h1</a> | \
      <a data-wysihtml5-command="formatBlock" data-wysihtml5-command-value="h2">h2</a> | \
      <a data-wysihtml5-command="insertUnorderedList">insertUnorderedList</a> | \
      <a data-wysihtml5-command="insertOrderedList">insertOrderedList</a> | \
      <a data-wysihtml5-command="foreColor" data-wysihtml5-command-value="red">red</a> | \
      <a data-wysihtml5-command="foreColor" data-wysihtml5-command-value="green">green</a> | \
      <a data-wysihtml5-command="foreColor" data-wysihtml5-command-value="blue">blue</a> | \
      <a data-wysihtml5-command="insertSpeech">speech</a> \
      <a data-wysihtml5-action="change_view">switch to html view</a> \
       \
      <div data-wysihtml5-dialog="createLink" style="display: none;"> \
        <label> \
          Link: \
          <input data-wysihtml5-dialog-field="href" value="http://"> \
        </label> \
        <a data-wysihtml5-dialog-action="save">OK</a>&nbsp;<a data-wysihtml5-dialog-action="cancel">Cancel</a> \
      </div> \
       \
      <div data-wysihtml5-dialog="insertImage" style="display: none;"> \
        <label> \
          Image: \
          <input data-wysihtml5-dialog-field="src" value="http://"> \
        </label> \
        <label> \
          Align: \
          <select data-wysihtml5-dialog-field="className"> \
            <option value="">default</option> \
            <option value="wysiwyg-float-left">left</option> \
            <option value="wysiwyg-float-right">right</option> \
          </select> \
        </label> \
        <a data-wysihtml5-dialog-action="save">OK</a>&nbsp;<a data-wysihtml5-dialog-action="cancel">Cancel</a> \
      </div> \
    </div> \
    <textarea placeholder="Enter text ..."></textarea>'
  );

  Form.editors.Html = Form.editors.Text.extend({
    tagName: 'div',
    events: { },
    initialize: function(options) {
        Form.editors.Text.prototype.initialize.call(this, options);
    },

    getValue: function(){
      return this.editor.getValue();
    },

    render: function(options) {
      this.$el.html(template());

      if(!this.editor){
        this.editor = new wysihtml5.Editor(this.$('textarea')[0], {
          toolbar:      this.$('.wy-toolbar')[0],
          stylesheets:  "css/stylesheet.css",
          parserRules:  wysihtml5ParserRules
        });
      }

      return this;
    },

});

})(Backbone.Form);
