import React, { useState, useRef, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { primary_40, white } from 'stylesheets/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { MapController } from 'maps/controller';
import { NpcPlacementAttributes, NpcPlacement } from 'models/npc_placement';
import './edit_npc_placement.scss';
import { store } from 'redux/store';
import { MAP_EDIT_NPC_PLACEMENT, MAP_EDIT_SCRIPT } from 'constants/actionTypes';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MODES } from 'components/world/constants';
import { connect } from 'react-redux';
import { Sprite } from 'models/sprite';
import ScriptPreview from 'components/scripts/preview';
import { ScriptAttributes, Script } from 'models/script';
import { SoundPreview, SoundProps } from 'components/sounds/sound';
import { Video } from 'components/videos/video';
import { Picture } from 'components/pictures/picture';
import { Text } from 'components/texts/text';
import { Anchor } from 'components/anchors/anchor';
import * as Agent from 'api/agent';
import { pick_model } from 'components/shared/pick_model';
import { Picker } from 'views/picker';
import { ModalView } from 'views/modal_view';
import { ScriptExecutor } from 'models/script_executor';
import { NpcView } from 'maps/views/npc_view';
import { Events } from 'maps/map_events';
import { sweet_prompt, sweeter_prompt } from 'utils/prompt';
import { drawer_width } from 'components/create_toolbox/create_toolbox_drawer';
import { EventableEvent } from 'components/events/render_event_object';

const SLOT_MS=250;

function EditNpcPlacementComponent(props:{mode:string, onClose:Function, controller: MapController, npc_placement:NpcPlacement}){

  const {npc_placement}=props;
  const [npc_placement_attrs, setNpcPlacementAttrs] = useState(JSON.stringify(npc_placement.attributes));
  const [is_valid, setValid] = useState(true);
  const [is_open_help, setOpenHelp] = useState(false);
  const textareaEl = useRef(null);
  const update = () => setNpcPlacementAttrs(JSON.stringify(npc_placement.attributes))

  return (npc_placement ? <Drawer
    className="edit-npc_placement-drawer"
    open={true}
    onClose={(e) => props.onClose()}
    transitionDuration={0}
    anchor='left'
    variant='persistent'
    PaperProps={{ style: { position: 'absolute', width: '30rem', background: primary_40, color: white, left: props.mode == MODES.Normal ? 0 : drawer_width } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute'}
    }}
  >
    <List>
      <ListItem button onClick={e=>{store.dispatch({type: MAP_EDIT_NPC_PLACEMENT, npc_placement: undefined})}}>
        <i className="fal fa-chevron-left"/>
      </ListItem>
    </List>

    <div className="row">
      <div className={`col-12 ${is_open_help ? '' : 'collapse'}`}>
        <p>NPCs are fun.</p>
      </div>
    </div>

    <div className="row">
      <div className="col-12 mt-4">
        <form onSubmit={e => false}>
          <fieldset>
            <legend>
              Events:
              <div className="btn btn-outline-white float-right" onClick={(e)=> {npc_placement.addEvent('click', 'play_script',['']);update()}}>Add Event</div>
            </legend>
              {npc_placement.events.map((ev) => {
                return <EventableEvent
                  key={`${ev.event}-${ev.command}`}
                  controller={props.controller}
                  event={ev}
                  eventable={npc_placement}
                  onChange={(ev)=>{npc_placement.updateEvent(ev.id, ev);update()}}
                  onRemove={(ev)=>{npc_placement.removeEvent(ev.id);update()}}
                  getScriptTarget={()=> $(`#npc_placement-${npc_placement.id}`).data('placement')}>
                </EventableEvent>
              })}
          </fieldset>
        </form>
      </div>

      <div className="col-12 mt-4">
        <a className="btn btn-outline-white" onClick={(e)=>npc_placement.save()}>Save</a>
        <a className="btn btn-outline-white float-right" onClick={(e)=>props.onClose()}>Cancel</a>
      </div>

    </div>

  </Drawer> : null)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

function MouseOverPopover(props:{text:JSX.Element, children:JSX.Element, className:string}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={props.className}>
      <div
        style={{display: 'inline-block'}}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.text}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper, }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.children}
      </Popover>
    </div>
  );
}

const mapStateToProps = ({map}, ownProps) => { 
  return {
    ...ownProps, 
    mode: map.mode,
  }
}

const mapDispatchToProps = (dispatch) => ({
});


const EditNpcPlacement = connect(mapStateToProps,mapDispatchToProps)(EditNpcPlacementComponent)

export default EditNpcPlacement;

