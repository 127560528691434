
import React, { useState } from "react"
import { SpriteProps, Sprite2 } from "components/sprites/sprite";

const SpriteCard: React.FC<{html?:React.HTMLAttributes<HTMLDivElement>, anim?: string, display_width?: number, hideBtns?: boolean, sprite:SpriteProps, playing?: boolean }> = (props) => {

  let anim:string = props.anim || Object.keys(props.sprite.map.sequences)[0];

  if(!props.sprite.map.sequences[anim])
    anim = Object.keys(props.sprite.map.sequences)[0];

  const [state, setState] = useState({
    currentAnim: anim,
    playing: !!props.playing
  })

  const mouseEnter = (seq) => {
    setState({ currentAnim: seq, playing: true });
  }

  const mouseLeave = (seq) => {
    setState({ currentAnim: seq, playing: false });
  }

  return (
    <div {...props.html}>

      <Sprite2 display_width={props.display_width || 200} sprite={props.sprite} playing={state.playing} name={state.currentAnim}  onMouseEnter={()=>mouseEnter(anim)} onMouseLeave={()=>mouseLeave(anim)}>
      </Sprite2>

    {
      props.hideBtns ? 
      null
      :
      Object.keys(props.sprite.map.sequences).map((seq) => 
      <div className="btn btn-sm btn-outline-secondary" onMouseEnter={()=>mouseEnter(seq)} onMouseLeave={()=>mouseLeave(seq)} key={seq}>{seq}</div>
      )
    }

  </div>
)
}
export {SpriteCard}
