import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import React from 'react';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';

import '../polyfills.js'

import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Router from 'components/Router';
import { store, history } from 'redux/store';
import _ from 'underscore';
import { MAP_SET_AVATAR } from 'constants/actionTypes';
import { AvatarCard } from 'components/avatars/avatar_card';
import { Npc } from "components/npcs/npc";

import { FabricGlitch } from 'components/glitch/fabric';
import { Clazz } from 'components/clazzes/clazz';


document.addEventListener('DOMContentLoaded', function(e) {
  console.log('hello');
  window._ = _;

  $('[data-test]').each(function(e){
    ReactDOM.render(
      <FabricGlitch></FabricGlitch>
      , $(this)[0])
  });


  $('[data-avatar-show]').each(function(e){
    ReactDOM.render(
      <AvatarCard {...$(this).data('avatar-show')} onClick={(e)=>console.log(e)}></AvatarCard>
      , $(this)[0])
  });

  $('[data-npc-show]').each(function(e){
    ReactDOM.render(
      <Npc {...$(this).data('npc-show')} onClick={(e)=>console.log(e)}></Npc>
      , $(this)[0])
  });

  $('[data-clazz-show]').each(function(e){
    ReactDOM.render(
      <Clazz {...$(this).data('clazz-show')} onClick={(e)=>console.log(e)}></Clazz>
      , $(this)[0])
  });

});

(window as any).Swal = require('sweetalert2');

(window as any).setupWorld = function(){

  store.dispatch({ type: MAP_SET_AVATAR, payload: (window as any).avatar });
  const theme = createTheme({
    typography: {
      fontFamily: 'Fredoka One, Arial',
      h1: {
        fontFamily: 'Fredoka One, Arial',
      },
      h2: {
        fontFamily: 'Fredoka One, Arial',
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
    }
  }));


  if(document.getElementById('root')){
    ReactDOM.render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={Router} />
            </Switch>
          </ConnectedRouter>
        </ThemeProvider>
      </Provider>
      , document.getElementById('root'))
  }

};
