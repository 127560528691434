import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {edit_space_url, play_game_url} from 'utils/urls';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

type SpacePreviewProps = {
  space: any;
  onClick: (space: any) => void;
  showUsers?: boolean;
  hideActions?: boolean;
  actions?: React.ReactNode;
  truncNames?: number;
}

const SpacePreview: React.FC<React.HTMLAttributes<HTMLDivElement> & SpacePreviewProps> = (props) => {

  const {children, hideActions, space, className, onClick, showUsers, truncNames} = props;

  const classes = useStyles();

  return (
    <Card className={classes.root + String(className || '')}>
      <CardActionArea onClick={(e) => onClick?.(space)}>
        <CardMedia
          className={classes.media}
          image={space.preview_url}
          title={space.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {truncNames ? space.name.slice(0, truncNames) : space.name}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {space.description}
          </Typography>


          {showUsers && <Typography>{space.current_user_count} users</Typography>}

        </CardContent>
      </CardActionArea>

      {!hideActions &&
        <CardActions>
          {(space.canEdit && space.location) && <Link className="btn btn-outline-warning" to={edit_space_url(space.uuid)}>
              Edit
            </Link> }

            <Link className="btn btn-outline-primary" to={play_game_url(space.uuid)} target="_blank">
              Visit
            </Link>

          </CardActions>
      }

      {children}
    </Card>
  );
}

export default SpacePreview;
