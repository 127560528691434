import * as _ from 'underscore';
import BaseView from 'views/base_view';
import { dotry } from 'utils/dotry';
import { Events } from 'maps/map_events';

import { css_editable} from 'modules/css_editable';

class WindowView extends BaseView<any> {

  dotry = dotry;

  toggle_editable: any;

  events () {
    return {
      dblclick: "handle_dblclick",
      "click .enviar": "handle_submit",
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "window_view";

  template = _.template(`
  <div class="ventanita">
    <div class="modal-header">
      <button type="button" class="close">&times;</button>
      <h4 class="modal-title"><%= dotry(model,"model",["get","name"]) %></h4>
    </div>
  
    <div class="modal-body">
      <button type="button" class="enviar btn btn-primary">Enviar</button>
    </div>
  </div>
`);

  constructor(options) {
    super(...Array.from(arguments));
    this.dispatchCreated('WindowView');
  }

  initialize(settings) {}

  handle_submit(ev) {
    if ((this.model as any).model) {
      (this.model as any).commit();
    } else {
      console.log(this.model);
      window[this.model.cid] = this.model;
    }
    this.trigger("commit", (this.model as any).getValue());
    dotry(this, "model", "model", [
      "save",
      null,
      {
        error: (jqXHR, text) => {
          console.log("AJAX error:", text);
        },
        success: (data, textStatus, jqXHR) => {
          console.log("AJAX success:", textStatus);
          this.dispatcher.trigger("created:" + (this.model as any).model._className(), (this.model as any).model);
        }
      }
    ]);
  }

  handle_dblclick(ev) {
    this.toggle_editable(true);
    ev.preventDefault();
    return false;
  }

  render() {
    const css = _.extend({
      position: "absolute",
      left: "100px",
      top: "100px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }, _.pick(dotry(this, "model", "model", ["get", "css"]) || {}, "width", "height", "top", "left"));
    this.$el.css(css).html(this.template(this));
    this.$(".modal-body").prepend((this.model as any).render().$el);
    this.dispatcher.trigger(Events.RENDERED_WINDOW_VIEW, this);
    this.toggle_editable(false);
    return this;
  }

};

_.extend(WindowView.prototype, css_editable);

export default WindowView;
