import { BaseModel } from "./base_model";
import { Tile, TileAttributes } from "./tile";
import { NpcPlacement , NpcPlacementAttributes} from "./npc_placement";
import { Anchor } from "./anchor";
import { GlitchGame } from "components/glitch/space";
import {GameAttributes} from "./game";

export type LayerAttributes = {
  id:string,
  z:number,
  offset:number,
  filters:{[key:string]: any},

  tile_width:number,
  tile_height:number,
  width:number,
  height:number,
  total_width:number,
  total_height:number,

  tiles: TileAttributes[],
  image_url?: string,
}
export type SpaceAttributes = {
  preview_url: string,
  loading_preview_url: string,
  uuid: string,
  game_id: string,
  game?: GameAttributes,
  name: string,
  description: string,
  tile_width: number,
  tile_height: number,
  metadata:any,
  total_width: number,
  total_height: number,
  background: {
    width: number,
    height: number
  },
  width: number,
  height: number
  canEdit: boolean,
  owner: string,
  start_point: {x:number,y:number}
  tiles: TileAttributes[],
  npc_placements: NpcPlacementAttributes[],
  layers: LayerAttributes[],
  location?: {
    id: string,
    tsid: string,
    metadata: GlitchGame,
    layer_images_map: {[name:string]: string},
  },
}

export type CreateSpaceAttributes = {
  uuid?: string,
  name?: string,
  width: number,
  height: number,
  glitch_location_attributes?: { metadata: GlitchGame }
}

export class Space extends BaseModel {

  name: any;
  anchors:Anchor[];

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Space');
  }

  _className() {
    return 'Space';
  }

  initialize(settings, opts) {
    this.name = settings.name;
    return super.initialize(settings, opts);
  }

  get height_px():number {
    return this.height * this.tile_height;
  }

  get width_px():number {
    return this.width * this.tile_width;
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "spaces", this.id || ''].join('/');
  }

  //get masks():Array<Array<{x:number,y:number}>>{
  get masks():string{
    const md =this.get('metadata');
    if(!md)
      return null
    else
      return md.masks
  }
  get height():number{
    return this.get('height')
  }
  get width():number{
    return this.get('width')
  }

  get tile_height():number{
    return this.get('tile_height')
  }

  get tile_width():number{
    return this.get('tile_width')
  }

  get layers():LayerAttributes[]{
    return this.get('layers')
  }

  get uuid():string{
    return this.get('uuid')
  }

  get canEdit():boolean{
    return this.get('canEdit')
  }

  get tiles():Array<Tile>{
    return this.get('tiles').map(t => new Tile(t,{dispatcher: this.dispatcher}))
  }

  get npc_placements():Array<NpcPlacement>{
    return (this.get('npc_placements') || []).map(n=>new NpcPlacement(n,{dispatcher: this.dispatcher, parse: true}))
  }


};

Space.prototype.schema = {
  name: "Text",
  description: "Text",
  height: "Text",
  width: "Text",
  tile_height: "Text",
  tile_width: "Text",
  x: "Text",
  y: "Text"
};
