import React, { FC } from 'react';
import { Link } from "react-router-dom";
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';

import { Flex } from '../flex';
import Icon from '../icon/Icon';

import CommonButton from 'components/common/Button';
import {PanelKinds, useDesignContext} from 'contexts/design_context';
import Popover from 'components/common/Popover';
import LayerPlacementTools from './LayerPlacementTools';
import {edit_space_url, game_url, new_creatix_space_url, play_game_url} from 'utils/urls';
import {getLayerName, serializeItems} from 'components/glitch/utils';
import ZindexPlacementTools from './ZindexPlacementTools';
import {List, ListItem, ListItemText} from '@material-ui/core';
import {GlitchLocations} from 'api/agent';
import {FabricCanvas, InteractionModes} from './utils';
import {useDispatch, useSelector} from 'react-redux';
import {setAiGenerations, setOpenProperties, setPromptSuggestions, setSelectedPanel, setSpaceId, setSynced} from 'redux/reducers/editor';
import {store} from 'redux/store';

function limitString(name: string, arg1: number){
  if(!name) return '';
  return name.length > arg1 ? name.substring(0,arg1) + '...' : name;
}

const ActionButton = (props) => {
  return <CommonButton className="rde-action-btn" shape="circle" {...props}/>
}

const GameSpaces = ({close:_close}) => {

  const hist = useHistory();
  const dispatch = useDispatch();
  const {game} = useSelector((state:any) => state.editor);

  const handleGoToSpace = (spaceId) => {

    dispatch(setOpenProperties(false));
    dispatch(setSpaceId(spaceId));
    dispatch(setAiGenerations([]));
    dispatch(setPromptSuggestions([] as any));

    hist.push(edit_space_url(spaceId));
  }

  const {space } = useDesignContext();
  return <List>
    {(game.spaces || []).map((s) => {
      return <ListItem key={s.uuid} button onClick={(e) => { if(s.uuid != space.uuid) handleGoToSpace(s.uuid)}} component="a">
        <ListItemText
          primaryTypographyProps={{style: {color: space.uuid === s.uuid ? 'blue' : 'black', cursor: 'pointer'}}}>
          {s.name} 
        </ListItemText>
      </ListItem>
    })}
      <ListItem key={'new'} button onClick={(e) => hist.push(new_creatix_space_url(game.uuid))} component="a">
        <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
          New
        </ListItemText>
      </ListItem>
  </List>
}

const SpaceSignPoint = ({close:_close}) => {

  const {space, selectedObject, designController} = useDesignContext();
  const {game} = useSelector((state: any) => state.editor);

  return <List>
    {(game.spaces || []).map((s) => {
      if(s.uuid==space.uuid) return null;

      return <ListItem key={s.uuid} button onClick={(e) => designController.gameHandler.setSignPointOnDeco(selectedObject, s.uuid)} component="a">
        <ListItemText primaryTypographyProps={{style: { cursor: 'pointer'}}}>
          {s.name} 
        </ListItemText>
      </ListItem>
    })}
    <ListItem key={'new'} button onClick={(e) => designController.gameHandler.setSignPointOnDeco(selectedObject, null)} component="a">
      <ListItemText primaryTypographyProps={{style: {cursor: 'pointer'}}}>
        None
      </ListItemText>
    </ListItem>
  </List>
}

export const saveGame=(fabricCanvas:FabricCanvas):Promise<any> => {
  const {spaceId, background_music, gameData} = store.getState().editor;
  const newData = serializeItems(gameData, fabricCanvas);
  newData.background_music = background_music;
  return GlitchLocations.update(spaceId, newData).then((res) => {
    store.dispatch(setSynced(true));
    return res;
  });
}

const Header:FC<{onChange:Function}> = (props) => {

  const {interactionMode, game, gameData} = useSelector((state: any) => state.editor);

  const context = useDesignContext();
  const {space, designController, selectedObject}=context;
  const dispatch = useDispatch();
  const isCropping = interactionMode === InteractionModes.CROP;

  const {synced,background_music, spaceId} = useSelector((state:any) => state.editor);

  const showGroupOptions =  (selectedObject?.type == 'activeSelection' || selectedObject?.type == 'group') && !isCropping;

  return (
  <Flex className="rde-editor-header-toolbar-container" flex="1">

    {false && !isCropping && <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-history">
      <ActionButton disabled={!designController?.transactionHandler?.undos?.length} onClick={() => designController?.transactionHandler.undo()} tooltipTitle={i18n.t('action.undo')} >
        <Icon name="undo-alt" style={{ marginRight: 8 }} />
      </ActionButton>

      <ActionButton disabled={!designController?.transactionHandler?.redos?.length} onClick={() => designController?.transactionHandler.redo()} tooltipTitle={i18n.t('action.redo')} >
        <Icon name="redo-alt" style={{ marginLeft: 8 }} />
      </ActionButton>
    </Flex.Item>}

      <Flex.Item className="rde-canvas-toolbar rde-breadcrumb">
        {[
          game ? <Link to={game_url(game)}>{game.name ? limitString(game.name,20) : '(none)'}</Link> : null,
          space?.name ? <Popover placement="bottom" title={i18n.t('actiongroup.placement')} content={c=><GameSpaces close={c}/>} wrapComponent="span">
            {space.name ? limitString(space.name,20) : '(none)'}
          </Popover> : null,
          getLayerName(gameData,selectedObject?.layer_id)
        ].filter(Boolean).map((y,i)=> <span key={i}>{y} /&nbsp;</span>)}
      </Flex.Item>

    <Flex.Item className="rde-canvas-toolbar">
      <ActionButton onClick={() => saveGame(context.fabricCanvas)} icon="save" tooltipTitle={i18n.t('action.save')} disabled={synced}/>
      <ActionButton onClick={() => window.open(`/#${play_game_url(spaceId)}`, '_blank')} icon="play" tooltipTitle={i18n.t('action.play')} />
    </Flex.Item>

    {selectedObject && <Flex.Item className="rde-canvas-toolbar">
      <Popover placement="bottom" title={i18n.t('actiongroup.placement')} content={(c)=><LayerPlacementTools close={c}/>} wrapComponent='span'>
        <ActionButton icon="bring-front" tooltipTitle={i18n.t('action.placement')} />
      </Popover>

      <Popover placement="bottom" title={i18n.t('actiongroup.placement')} content={(c)=><ZindexPlacementTools close={c}/>} wrapComponent='span'>
        <ActionButton icon="bring-forward" tooltipTitle={i18n.t('action.placement')} />
      </Popover>
    </Flex.Item>}

    {false && (selectedObject && selectedObject?.type == 'deco' && selectedObject?.layer_id == 'middleground') && !isCropping && <Flex.Item className="rde-canvas-toolbar">
      <Popover placement="bottom" title={i18n.t('actiongroup.signpost')} content={(c)=><SpaceSignPoint close={c}/>} wrapComponent="span">
        <ActionButton icon="person-to-door" tooltipTitle={i18n.t('action.signpoint')} />
      </Popover>
    </Flex.Item>}

    {/* DISABLED color and copy-style */}
    {(false && selectedObject && selectedObject?.type != 'activeSelection') && !isCropping && <Flex.Item className="rde-canvas-toolbar">
      <ActionButton onClick={() => dispatch(setSelectedPanel(PanelKinds.COLOR))} icon="eye-dropper" tooltipTitle={i18n.t('action.colors')} />
      <ActionButton onClick={() => designController?.copy_style(selectedObject)} icon="paint-roller" tooltipTitle={i18n.t('action.copy_style')} />
    </Flex.Item>}

    {(selectedObject?.type == 'activeSelection') && !isCropping && <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-alignment">
      <ActionButton onClick={() => designController?.alignmentHandler.left()} icon="align-left" tooltipTitle={i18n.t('action.align-left')} />
      <ActionButton onClick={() => designController?.alignmentHandler.center()} icon="align-center" tooltipTitle={i18n.t('action.align-center')} />
      <ActionButton onClick={() => designController?.alignmentHandler.right()} icon="align-right" tooltipTitle={i18n.t('action.align-right')} />
    </Flex.Item>}

    {showGroupOptions && <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-group">
      {(selectedObject?.type == 'activeSelection') && <ActionButton onClick={() => designController?.toGroup()} icon="object-group" tooltipTitle={i18n.t('action.object-group')} />}
      {(selectedObject?.type == 'group') && <ActionButton onClick={() => designController?.toActiveSelection()} icon="object-ungroup" tooltipTitle={i18n.t('action.object-ungroup')} />}
    </Flex.Item>}

    {(selectedObject && !isCropping) && <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-operation">
      <ActionButton onClick={() => designController?.duplicate()} icon="clone" tooltipTitle={i18n.t('action.clone')} />
      <ActionButton onClick={() => designController?.remove()} icon="trash" tooltipTitle={i18n.t('action.delete')} />
    </Flex.Item>}


  </Flex>
  );
}

export default Header;
export { ActionButton };

