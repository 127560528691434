import * as Nouns from './nouns';
import { AvatarView } from 'maps/views/avatar_view';
import { Verb } from 'hip/verb';
import { Events } from '../maps/map_events';
import { MapView } from 'maps/views/map_view';
import { store } from 'redux/store'
import { MAP_SET_ZOOM, APP_LOAD, MAP_SET_INVENTORY, MAP_EDIT_SCRIPT } from 'constants/actionTypes';
import { sweet_prompt as prompt } from 'utils/prompt';
import { Questions, Items, Auth } from 'api/agent';
import { Avatar } from 'models/avatar';
import toastr from 'toastr';
  import { NounType } from 'hip/noun';
  import { ScriptExecutor } from 'models/script_executor';
const Swal =require('sweetalert2');

const nouns:NounType[] = Object.values(Nouns);

export const unquote = (str) => String(str).replace(/^"/, '').replace(/"$/, '')

//class Give extends Verb {
//
//  name = "give";
//
//  direct_object = {
//    label: "item",
//    type: Nouns.anItem
//  };
//
//  //modifiers = {
//  //  to: Nouns.someone
//  //};
//
//  getDescription(directObject, mods) {
//    let retval = `Give ${directObject?.label || directObject}`;
//    if(mods.to)
//      retval += ` to ${mods.to}`
//    return retval;
//  }
//
//  async execute(directObject, mods, context) {
//    console.log(`Giving ${directObject.label} to ${mods.to}`)
//    Items.create(directObject.id)
//    //return context.dispatcher.trigger(Events.TELETRANSPORT_CURRENT, { x, y });
//  }
//}

class Create extends Verb {

  name = "create";

  direct_object = {
    label: "item",
    type: Nouns.aClazz
  };

  getDescription(directObject, mods) {
    let retval = `Create ${directObject?.label || directObject}`;
    if(mods.for)
      retval += ` for ${mods.for}`
    return retval;
  }

  async execute(directObject, mods, context) {
    console.log(`Creating ${directObject.label}`)
    Items.create(directObject.id).then((item) => {
      store.dispatch({ type: MAP_SET_INVENTORY, payload: Items.user_inventory() });
      toastr.info(`You got a new ${item.clazz.name}`)
    })

  }

}


class Teletransport extends Verb {

  name = "teletransport";

  direct_object = {
    label: "x,y",
    type: Nouns.coords
  };

  getDescription(directObject, mods) {
    return `Teletransport to ${directObject?.label || directObject}` ;
  }

  async execute(directObject, mods, context) {
    const place = directObject?.label || directObject;
    const matches = place.match(/[+-]?[0-9]+/g);
    const x = (matches[0][0].match(/[+-]/) ? matches[0] : parseInt(matches[0]));
    const y = (matches[1][0].match(/[+-]/) ? matches[1] : parseInt(matches[1]));
    return context.dispatcher.trigger(Events.TELETRANSPORT_CURRENT, { x, y });
  }

};

class Visibility extends Verb {
  name = "visibility";

  direct_object = {
    label: "on/off",
    type: Nouns.visibility
  };

  modifiers = {
    in: Nouns.someTime
  };

  getDescription(directObject, mods) {
    let txt =  (directObject?.label || directObject) == 'on' ? "Appear" : "Disappear";
    if('in' in mods){
      txt = txt + ` in ${mods.in} seconds`
    }
    return txt
  }

  execute(directObject, mods, context) {
    return context.dispatcher.trigger(Events.VISIBILITY, {
      visibility: (directObject?.label || directObject),
      target: context,
      in: mods.in ? parseInt(mods.in.label || mods.in) * 1000 : null
    });
  }

};


class Anchor extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "anchor";

  direct_object = {
    label: "name",
    type: Nouns.quotedString
  };

  getDescription(directObject, mods) {
    return `Create Anchor here named "${unquote(directObject?.label || directObject)}" `;
  }

  execute(directObject, mods, context) {
    let name = directObject?.label || directObject;
    return context.dispatcher.trigger(Events.CREATE_ANCHOR, {
      name: unquote(name),
      x: context.x,
      y: context.y,
      target: context
    });
  }

};

class Ask extends Verb {

  name = "ask";

  direct_object = {
    label: "question",
    type: Nouns.aQuestion
  };

  getDescription(directObject, mods) {
    return `Ask question "${unquote(directObject?.label || directObject)}" `;
  }

  async execute(directObject, mods, context) {

    const question= await Questions.get(directObject?.id)

    return context.dispatcher.trigger(Events.ASK_QUESTION, {
      question: question.title,
      question_id: question.id,
      answers: question.answers.map(x=>x.label),
      target: context
    });
  }

};


class Travel extends Verb {

  name = "travel";

  direct_object = null;

  modifiers = {
    to: Nouns.anAnchor
  };

  getDescription(directObject, mods) {
    return "Travel to " + (mods.to?.label || mods.to) + ".";
  }

  async execute(directObject, mods, context) {
    return context.dispatcher.trigger(Events.TRAVEL_TO_ANCHOR, {
      id: (directObject?.id || mods.to.id || mods.to)
    });
  }

};

class AddChar extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "add";

  direct_object = {
    label: "avatar",
    type: Nouns.aAvatar
  };

  modifiers = {
    at: Nouns.coords
  };

  getDescription(directObject, mods) {
    return `Add avatar "${directObject?.label || directObject}" to the scene`;
  }

  async execute(directObject, mods, context) {
    let x = context.x;
    let y = context.y;

    if (mods.at) {
      const at = mods.at.label || mods.at;
      const matches = at.match(/[+-]?[0-9]+/g);
      x = parseInt(matches[0]);
      y = parseInt(matches[1]);
      if (at[0] !== "@") {
        x += context.x;
        y += context.y;
      }
    }

    const avatar = await context.dispatcher.request('Avatar', directObject?.id)
    const avatarviw = new AvatarView({
      model: avatar,
      dispatcher: context.dispatcher,
      isLocal: true
    });

      avatarviw.setHip(scriptable_verbs,nouns);

    context.dispatcher.trigger(Events.PLACE_AVATAR, {
      who: avatarviw,
      hip: 'script',
      x: x,
      y: y,
      target: context
    });

  }

};

class Animation extends Verb {
  name = "animation";

  direct_object = {
    label: "some animation",
    type: Nouns.anAnimation
  };

  modifiers = {
    for: Nouns.someTime,
    /*fps: Nouns.fps*/
  };

  getDescription(directObject, mods) {
    let dessc =  `Play animation "${directObject?.label || directObject}"`
    if('for' in mods){
      dessc = dessc + ` for ${mods.for?.label || mods.for} seconds`;
    }
    return dessc;
  }

  async execute(directObject, mods, context:AvatarView<Avatar>) {
    const time = mods.for ? parseInt(mods.for?.label || mods.for) * 1000 : null;
    const fps = mods.fps ? parseInt(mods.fps?.label || mods.fps) : null;
    return context.play(directObject?.label || directObject, time, fps);
  }

};

class EditModel extends Verb {
  constructor(opts){
    super(opts)
  }

  name = "edit";

  direct_object = null;

  modifiers = {
    script: Nouns.aScript,
    picture: Nouns.aPicture,
    video: Nouns.aVideo,
    text: Nouns.aText,
    question: Nouns.aQuestion,
  };

  getDescription(directObject, mods) {
    return "Edit " + Object.keys(mods).map((k) => mods[k] ? `${k} ${mods[k].label || mods[k]}` : '').join(' ');;
  }

  async execute(directObject, mods, context) {
    if(mods.script){

      if(true){
        const s= await context.dispatcher.request('Script', mods.script.id);
        const script = s.relativize(context.x, context.y);
        const player = new ScriptExecutor({ model: script, where: context }, { dispatcher: context.dispatcher });
        store.dispatch({ type: MAP_EDIT_SCRIPT, script, player});
      }else{

        context.dispatcher.trigger(Events.SCRIPT_PLAYER, {
          id: mods.script.id,
          where: context
        });
      }

    }else{
      context.dispatcher.trigger(Events.EDIT_MODEL, mods);
    }
  }

};

class NewModel extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "new";

  direct_object = {
    label: "model",
    type: Nouns.aModel
  };

  modifiers = {}; //at : Nouns.coords

  getDescription(directObject, mods) {
    return `Create new ${directObject?.label || directObject}"`;
  }

  async execute(directObject, mods, context) {
    return context.dispatcher.trigger(Events.NEW_MODEL, {
      class: directObject?.label || directObject
    });
  }

};

class Say extends Verb {

  constructor(opts:any={}){
    super(opts)
  }

  name = "say";

  modifiers = {
  };

  direct_object = {
    label: "something",
    type: Nouns.quotedString
  };

  getDescription(directObject, mods) {
    return `Say "${unquote(directObject?.label || directObject)}" `;
  }

  async execute(directObject, mods, context) {
    context.say(directObject?.label||directObject);
  }

};

class Walk extends Verb {

  name = "walk";

  direct_object = {
    label: "x,y",
    type: Nouns.coords
  };

  modifiers = {
    to: Nouns.coords
  };

  getDescription(directObject, mods) {
    return `Go to coordinates "${directObject?.label || directObject}"`;
  }

  async execute(directObject, mods, context) {
    let x,y;

    const at = directObject?.label || directObject;
    const matches = at.match(/[+-]?[0-9]+/g);
    if(matches){
      x = parseInt(matches[0]);
      y = parseInt(matches[1]);
      if (at[0] !== "@") {
        x += context.x;
        y += context.y;
      }

      return context.dispatcher.trigger(Events.MOVE_CURRENT, { x, y});
    }
  }

};

class Visit extends Verb {

  name = "visit";

  direct_object = {
    label: "space",
    type: Nouns.space
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Visit space ${directObject?.label || directObject}`;
  }

  async execute(directObject, mods, context) {
    context.dispatcher.request('Space', directObject.id).then(function(space) {
      context.dispatcher.trigger(Events.SET_SPACE, space, {x: 100, y:100});
    });
  }

};

class Stop extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "stop";

  direct_object = null;

  modifiers = {};

  getDescription(directObject, mods) {
    return "Stop recording scene";
  }

  async execute(directObject, mods, context) {
    return context.dispatcher.trigger(Events.STOP_RECORDING, {
      target: context
    });
  }

  match(input, context) {
    if (!context.recording) {
      return 0;
    }
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class Notify extends Verb {
  name = "notify";

  direct_object = {
    label: "message",
    type: Nouns.quotedString
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Notify msg "${directObject?.label || directObject}".`;
  }

  async execute(directObject, modifiers, context) {
    return context.dispatcher.trigger(Events.NOTIFY, directObject?.label || directObject);
  }

  match(input, context) {
    return ("notify".indexOf(input) !== -1 || input.indexOf("\"") !== -1) ? 1 : 0 ;
  }

};

class Save extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "save";

  direct_object = {
    label: "name",
    type: Nouns.aScene
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Save scene "${unquote(directObject?.label||directObject)}" to server.`;
  }

  async execute(directObject, modifiers, context) {
    return context.dispatcher.trigger(Events.SAVE_RECORDING, {
      name: unquote(directObject?.label || directObject),
      target: context
    });
  }

  match(input, context) {
    if (context.recording) {
      return 0;
    }
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class Record extends Verb {

  name = "record";

  direct_object = {
    label: "name",
    type: Nouns.anyWord
  };

  modifiers = {};

  constructor(opts){
    super(opts)
  }

  getDescription(directObject, mods) {
    return `Start recording scene "${unquote(directObject?.label || directObject)}"`;
  }

  async execute(directObject, modifiers, context) {
    context.dispatcher.trigger(Events.START_RECORDING, {
      name: unquote(directObject?.label || directObject),
      target: context
    });
  }

  match(input, context) {
    if (context.recording) {
      return 0;
    }
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class PlayBranch extends Verb {

  name = "play branch";

  direct_object = {
    label: "branch",
    type: Nouns.anyWord
  };

  modifiers = {};

  constructor(opts:any = {}){
    super(opts)
  }

  getDescription(directObject, mods) {
    return `Continue the script playing branch "${unquote(directObject?.label || directObject)}"`;
  }

  async execute(directObject, modifiers, context) {
    context.dispatcher.trigger(Events.PLAY_BRANCH, {
      name: unquote(directObject?.label || directObject),
      target: context
    });
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class Branch extends Verb {

  name = "branch";

  direct_object = {
    label: "name",
    type: Nouns.anyWord
  };

  modifiers = {};

  constructor(opts){
    super(opts)
  }

  getDescription(directObject, mods) {
    return `Record a branch named "${unquote(directObject?.label || directObject)}"`;
  }

  async execute(directObject, modifiers, context) {
    context.dispatcher.trigger(Events.START_BRANCH, {
      name: unquote(directObject?.label || directObject),
      target: context
    });
  }

  match(input, context) {
    if(!context.recording) return 0;
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};


class Play extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "play";

  direct_object = {
    label: "name",
    type: Nouns.aScript
  };

  modifiers = {
  };

  getDescription(directObject, mods) {
    let retval= `Play script ${directObject?.label || directObject}`;
    if(mods.branch){
      retval += ` at branch ${mods.branch}`
    }
    return retval;
  }

  async execute(directObject, modifiers, context) {
    context.dispatcher.trigger(Events.PLAY_SCRIPT, {
      id: directObject.id,
      where: context,
      trigger: context,
    });
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class ShowPicture extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "show_picture";

  direct_object = {
    label: "name",
    type: Nouns.aPicture
  };

  modifiers = {
    modal: Nouns.trueFalse
  };

  getDescription(directObject, mods) {
    return `Show Picture "${directObject?.label||directObject}"`;
  }

  async execute(directObject, modifiers, context) {
    if(context instanceof AvatarView){
      (context as AvatarView<Avatar>).show_picture(directObject.id);
    }else{
      context.dispatcher.trigger(Events.SHOW_PICTURE, {
        id: directObject.id,
        where: context.$other,
        thing: context
      });
    }

  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class ShowVideo extends Verb {
  constructor(opts){
    super(opts)
  }

  name = "show_video";

  direct_object = {
    label: "name",
    type: Nouns.aVideo
  };

  modifiers = {
    modal: Nouns.trueFalse
  };

  getDescription(directObject, mods) {
    return `Show Video ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {
    if(context instanceof AvatarView){
      (context as AvatarView<Avatar>).play_video(directObject.id);
    }else{
      return context.dispatcher.trigger(Events.PLAY_VIDEO, {
        id: directObject.id,
        where: context.$other,
        thing: context
      });
    }
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};



class PlaySound extends Verb {
  constructor(opts){
    super(opts)
  }

  name = "play_sound";

  direct_object = {
    label: "name",
    type: Nouns.aSound
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Play Sound ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {

    if(context instanceof AvatarView){
      (context as AvatarView<Avatar>).play_sound(directObject.id);
    }else{
      return context.dispatcher.trigger(Events.PLAY_SOUND, {
        id: directObject.id,
        where: context.$other,
        thing: context
      });
    }
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class ShowText extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "show_text";

  direct_object = {
    label: "name",
    type: Nouns.aText
  }

  modifiers = {
    modal: Nouns.trueFalse
  }

  getDescription(directObject, mods) {
    return `Show Text ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {
    if(context instanceof AvatarView){
      (context as AvatarView<Avatar>).show_text(directObject.id);
    }else{

      return context.dispatcher.trigger(Events.SHOW_TEXT, {
	id: directObject.id,
	where: context.$other,
	thing: context
      });
    }
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class ShowWebResource extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "show_web_resource";

  direct_object = {
    label: "name",
    type: Nouns.aWebResource
  };

  modifiers = {
    modal: Nouns.trueFalse
  };

  getDescription(directObject, mods) {
    return `Show Web Resource ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {
    if(context instanceof AvatarView){
      (context as AvatarView<Avatar>).show_web_resource(directObject.id);
    }else{
      return context.dispatcher.trigger(Events.SHOW_WEB_RESOURCE, {
        id: directObject.id,
        where: context.$other,
        thing: context
      });
    }
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};


class Become extends Verb {
  name = "become";

  direct_object = {
    label: "name",
    type: Nouns.aAvatar
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Become avatar ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {
    context.dispatcher.trigger(Events.TRANSMUTE, { id: directObject.id, target: context });
  }

  match(input, context) {
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }


}

class PlayScene extends Verb {

  constructor(opts){
    super(opts)
  }

  name = "playScene";

  direct_object = {
    label: "name",
    type: Nouns.aScene
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Play scene ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, context) {
    return context.dispatcher.trigger(Events.PLAY_SCENE, {
      name: directObject?.label || directObject,
      target: context
    });
  }

  match(input, context) {
    if (context.recording) {
      return 0;
    }
    return (!input || this.name.indexOf(input) !== -1) ? 1 : 0;
  }

};

class Zoom extends Verb {
  name = "zoom";

  direct_object = {
    label: "units",
    type: Nouns.aZoomFactor
  };

  modifiers = {};

  getDescription(directObject, mods) {
    return `Zoom by factor ${directObject?.label||directObject}`;
  }

  async execute(directObject, modifiers, sprite) {
    store.dispatch({ type: MAP_SET_ZOOM, zoom: parseFloat(directObject?.label || directObject)})
  }

};

const editor_verbs = [
  new Anchor({ editor: true }),
  new AddChar({ editor: true }),
  new EditModel({ editor: true }),
  new NewModel({ editor: true }),
  new Stop({ editor: true }),
  new Save({ editor: true }),
  new Record({ editor: true }),
  new Branch({ editor: true }),

  new ShowPicture({ editor: true }),
  new ShowVideo({ editor: true }),
  new ShowText({ editor: true }),
  new ShowWebResource({ editor: true }),
  new PlaySound({ editor: true }),

  new Play({ editor: true }),
  new PlayScene({ editor: true })
];

const verbs = [new Create(), new Ask(),new Teletransport(), new Visibility(), new Travel(), new Animation(), new Say(), new Walk(), new Visit(), new Notify(), new Become(), new Zoom()];

const scriptable_verbs = [new ShowPicture({}), new ShowVideo({}), new ShowText({}), new ShowWebResource({}), new Create(),new Ask(),new Teletransport(), new Visibility(), new Animation(), new Say(), new Walk(), new Become()];

const all= verbs.concat(...editor_verbs);

export default all;

export {
  scriptable_verbs, 
  editor_verbs, 
  verbs,
  all
}
