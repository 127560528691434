import React, {useEffect} from 'react';
import { InstantSearch, SearchBox, Hits, HierarchicalMenu } from 'react-instantsearch';
import {searchAssets} from 'utils/searchkit_client';

const GlitchAssetsList = () => {
  const href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"

  useEffect(() => {
        // Create link element
    const link = document.createElement('link');
    link.href = href;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen,print';

    // Append link element to head
    document.head.appendChild(link);

    // Remove link element on cleanup
    return () => {
      document.head.removeChild(link);
    };
  }, [href]);

  const index = (window as any).assets_index;

  return <div className="container">
    <h1>Glitch Assets</h1>
    <InstantSearch indexName={index} searchClient={searchAssets}>
      <div className="search-panel">
        <div className="search-panel__filters">
          <HierarchicalMenu
            attributes={[
              'categories.level_0',
              'categories.level_1',
              'categories.level_2',
            ]}
          />
        </div>
        <div className="search-panel__results">
          <SearchBox />
          <Hits hitComponent={HitView} />
        </div>
      </div>
    </InstantSearch>
  </div>
};

const HitView = ({ hit }) => {

  const svgStyle:React.CSSProperties = {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    display: 'inline-block',
    marginRight: '10px',
    border: '1px solid black',
    borderRadius: '5px',
  };

  //const url =`/glitch/assets/${hit.name}.svg`;
  const url = hit.url;

  return <div>
    <h4>
      <img src={url} style={svgStyle} alt={hit.name}></img>
      {hit.name_tokens}
    </h4>
  </div>
};


export default GlitchAssetsList;
