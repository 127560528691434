import React from 'react'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import Player from 'react-player'
import { CircularProgress } from '@material-ui/core'

const CONFIG = {
  youtube: { playerVars: { autoplay: true } },
}

type PreviewVideoOpts = {
  className: string,
  file: any,
  fps: number,
  height: number,
  startFrame: number,
  pauseFrame: number,
  duration: number,
  isPlaying: boolean,
  isProcessing: boolean,
  onReady: Function,
}

class VideoPreview extends PureComponent<PreviewVideoOpts> {

  static propTypes = {
    className: PropTypes.string,
    file: PropTypes.object.isRequired,
    fps: PropTypes.number,
    height: PropTypes.number,
    startFrame: PropTypes.number.isRequired,
    pauseFrame: PropTypes.number,
    duration: PropTypes.number.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool,
    onReady: PropTypes.func.isRequired,
  }
  state = {
    isLoading: true,
    canPlay: false,
  }
  maxTime = 0
  initialSeek: boolean
  player: any
  hasDuration: any
  lastSeek: number

  UNSAFE_componentWillMount() {
    this.updateMaxTime()
    const { startFrame } = this.props
    this.initialSeek = startFrame > 0
    this.lastSeek=Date.now()
  }

  componentDidUpdate(prevProps) {
    const { startFrame, duration, pauseFrame, fps, isPlaying } = this.props
    if (startFrame !== prevProps.startFrame || duration !== prevProps.duration) {
      this.updateMaxTime()
    }
    if (isPlaying !== prevProps.isPlaying || pauseFrame !== prevProps.pauseFrame) {
      //clearTimeout(this.player.player.progressTimeout);
      this.player.seekTo(pauseFrame / fps,'seconds');
    }
  }

  updateMaxTime() {
    const { startFrame, duration, fps } = this.props
    this.maxTime = (startFrame + duration) / fps
  }

  //@Throttle(200)
  onProgress({ playedSeconds }) {
    const { startFrame, fps } = this.props

    if (this.initialSeek) {
      this.initialSeek = false
      this.player.seekTo(startFrame / fps,'seconds')
      return;
    }

    if(playedSeconds > this.maxTime /*&& (Date.now() - this.lastSeek) > (this.maxTime-startFrame/fps)/2*/ ) {
      this.lastSeek = Date.now();
      //clearTimeout(this.player.player.progressTimeout);
      //setTimeout(()=>this.player.seekTo(startFrame / fps),0);
      this.player.seekTo(startFrame / fps,'seconds');
    }
  }

  render() {
    const { className, file, fps, height, isPlaying, isProcessing } = this.props
    const { isLoading, canPlay } = this.state
    let shouldPlay = isPlaying
    if (canPlay && !this.hasDuration) {
      shouldPlay = true
    }
    // @ts-ignore
    const onSeek = (e) => {
      // onSeek not available on youtube player
      // https://github.com/CookPete/react-player/issues/356
    }
    return (
      <div className={className} style={{ height }}>
        {!isProcessing && (
          <Player
            controls={false}
            ref={c => (this.player = c)}
            url={file.file}
            loop={true}
            playing={shouldPlay}
            volume={0}
            height={height}
            width="100%"
            muted={true}
            style={{}}
            progressInterval={50}
            playsinline={true}
            config={CONFIG}
            onReady={() => this.setState({ isLoading: false, canPlay: true })}
            onDuration={duration => this.props.onReady(Math.floor(duration * this.props.fps))}
            onProgress={data => this.onProgress(data)}
          />
        )}
    {(isLoading || isProcessing) && <CircularProgress/>}
  </div>
    )
  }
}

export default VideoPreview;
