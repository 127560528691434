import React from 'react';
import { ScriptAttributes, Script } from 'models/script';
import { Scripts } from 'api/agent';
import { Events } from 'backbone';
import {Timeline, Layer, Framed} from 'components/timeline/timeline';
import { pixelsToTimeMs, distance } from 'maps/views/avatar_view';
import last from 'lodash/last';
  import { store } from 'redux/store';
class ScriptShow extends React.Component<{script?:ScriptAttributes, history:any, match: any},{script?:ScriptAttributes}> {

  script: Script;

  async componentDidMount() {
    if(this.props.match?.params?.uuid){
      const script = await Scripts.show(this.props.match?.params?.uuid)
      this.script = new Script(script, {dispatcher: Events})
      this.setState({script})
    }
  }

  get frames():Framed {
    return Object.keys(this.script.script).reduce((memo, actor) => ({
      [actor]: this.script.script[actor].map(([ms, action, args], idx) => {
        let duration;
        if(action.match(/^walk/)) {
          const [newx,newy] = args;
          const [x,y] = this.script.positionsAt(ms)[actor];
          const dist = distance([newx,newy],[x,y])
          duration = Math.max(pixelsToTimeMs(dist)/1000, 10)
          console.log({newx,newy,x,y, dist, duration})
        }else if(action.match(/^say/)) {
          duration = last(args)/1000
        }else{
          duration = 3;
        }
        return {
          id: `${idx}-actor`, 
          name: `${action}(${JSON.stringify(args)})`,
          second: ms/1000,
          duration
        }
      }),
      ...memo
    }), {})
  }

  get isOwner():boolean{
    return store.getState().common.currentUser?.uuid == this.state.script?.user_id
  }

  destroy() {
    Scripts.destroy(this.script.id).then(()=>{
      this.props.history.push(`/games/${this.state.script.game_id}`)
      console.log('success');
    }).catch(()=>{
      console.log('error');
    })
  }
  get layers():Layer[] {
    return Object.keys(this.script.players).map((name) => ({id: name, name}))
  }

  render(){
    const script = this.props?.script || this.state?.script
    const onUpdateFrames = (frames) => {
      console.log(frames)
    }

    if(script){
      return (
        <div className="col-12">
          {(this.isOwner && <div className="btn btn-danger" onClick={this.destroy.bind(this)}>Delete</div>)}
          <Timeline layers={this.layers} frames={this.frames} onUpdateFrames={onUpdateFrames}/>

          <textarea className="form-control" rows={10} defaultValue={this.script.textTimeline(250)}></textarea>
        </div>)

    }else{
      return (<div>Loading...</div>)
    }
  }
}

export {ScriptShow}
