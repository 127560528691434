import {GlitchDecorable} from 'components/glitch/space';
import Phaser from 'phaser';

export default class Decorable extends Phaser.GameObjects.Sprite  {

  public offset_x: number
  public offset_y: number
  public decorable: GlitchDecorable

  constructor(scene: Phaser.Scene, decorable: GlitchDecorable, offset_x: number, offset_y: number) {
    super(scene, decorable.x+offset_x, decorable.y+offset_y, decorable.filename || 'goal')
    this.offset_x = offset_x;
    this.offset_y = offset_y;
    this.decorable = decorable;

    let key;
    if(decorable.sprites){
      key = `${decorable.filename}-0`;
      //const s = 'walk'; //Object.keys(decorable.sprites[0].map.sequences)[0];
      const s = 'right'; //playerData.meta.frameTags.find(({name}) => name == 'right').name;
      const playerData = scene.cache.json.get(key);

      const xx=this.anims.createFromAseprite(key,[s])

      let walkAnimation = this.anims.get(s);
      walkAnimation.repeat = -1;
      this.anims.play('right');
    } else {
      key = decorable.filename;
    }

    //const {height:naturalHeight,width:naturalWidth} = scene.textures.get(key).getSourceImage()

    const flipY = decorable.w < 0;
    const flipX = decorable.h < 0;
    const absWidth = Math.abs(decorable.w);
    const absHeight = Math.abs(decorable.h);

    //this.setOrigin(0.5, 1)
    //this.x -= absWidth/2;
    //this.y -= absHeight/2;
    this.displayWidth=absWidth;
    this.displayHeight=absHeight;

    this.doPosition();

    this.setAngle((decorable.r||0)+360);
    this.setFlipX(flipX || decorable.h_flip);
    this.setFlipY(flipY || decorable.v_flip);
  }

  doPosition(offsetX=0,offsetY=0){
    this.x = this.decorable.x + this.offset_x + offsetX;
    this.y = this.decorable.y + this.offset_y - this.displayHeight/2 + offsetY

    const absWidth = Math.abs(this.decorable.w);
    const absHeight = Math.abs(this.decorable.h);

    //if(this.decorable.sprites){
    //  const key = `${this.decorable.filename}-0`;
    //  const {height:naturalHeight,width:naturalWidth} = this.scene.textures.get(key).getSourceImage()
    //  this.scaleX = absWidth/naturalWidth;
    //  this.scaleY = absHeight/naturalHeight;
    //} else {
      this.displayWidth=absWidth;
      this.displayHeight=absHeight;
    //}
  }

}
