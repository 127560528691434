
import Swal from 'sweetalert2';

const sweet_prompt = (msg) => Swal.fire<string>({
  title: msg,
  input: 'text',
  showCancelButton: true,
})

const sweeter_prompt = async (msg:string):Promise<string> => {
  const result:{value?:string} = await sweet_prompt(msg)
  return result.value as string;
}

export {sweet_prompt, sweeter_prompt}
