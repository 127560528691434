import React from 'react';
import { Redirect } from "react-router-dom";
import { ListPagination } from 'components/shared/list_pagination';
import { WebResources } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { WebResourceAttributes } from 'models/web_resource';
import Link from '@material-ui/core/Link';
import { UserAttributes } from 'models/user';
import { connect } from 'react-redux';

class WebResourceView extends React.Component<{web_resource:WebResourceAttributes},{}> {

  render(){
    return (
      <div
        className="video"
        style={{
          position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0
        }}
      >
        <iframe
          style={{
            position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
          }}
          src={this.props.web_resource.url}
          frameBorder="0"
        />
      </div>
    );
  }

}

export default WebResourceView;

