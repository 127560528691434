import { BaseModel } from "./base_model";
import * as _ from "underscore";

export type SoundAttrs = {
  id:string,
  uuid:string,
  url:string,
  category:string,
  duration:number,
  name:string,
  description:string,
  sound_url: string,
  game_id: string,
}


class Sound extends BaseModel {

  instance: createjs.AbstractSoundInstance;

  public url = () =>{
    return [(window as any).Niconitos.config.api, "sounds", this.id || ''].join('/');
  }

  public schema = {
    name: "Text",
    description: "Text",
    temp_file_key: "Filepicker"
  };

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Sound');

    if (this.id) {
      this.load();
    }
  }

  _className() {
    return "Sound";
  }

  load() {
    if (this.get('sound_url')) {
      if (createjs.Sound.registerSound(this.get("sound_url"), this.get("name"))) {
        return this.handleLoadComplete({
          src: this.get('sound_url')
        });
      } else {
        return createjs.Sound.addEventListener("fileload", _.bind(this.handleLoadComplete, this));
      }
    } else {
      return this.fetch().then(() => {
        return this.load();
      });
    }
  }

  handleLoadComplete(event:any = {}) {
    var ev, i, len, ref;
    if (event.src !== this.get('sound_url')) {
      return;
    }
    this.instance = createjs.Sound.createInstance(this.get("name"));
    ref = ["interrupted", "succeeded", "failed", "complete", "loop"];
    for (i = 0, len = ref.length; i < len; i++) {
      ev = ref[i];
      this.instance.addEventListener(ev, () => {
        return this.trigger(ev, this.instance);
      });
    }
    return this.trigger('Im-ready');
  }

};


export { Sound };
