import * as _ from 'underscore';
import { NpcView } from './npc_view';
import BaseView from 'views/base_view';
import { NpcPlacement } from 'models/npc_placement';
import { Menu } from 'views/menu';
import { pick_model, pick_npc } from 'components/shared/pick_model';
import { Tile } from 'models/tile';
import { Events } from 'maps/map_events';
import { store } from 'redux/store';
import { Npcs } from 'api/agent';
import { Npc } from 'models/npc';
import { LayerAttributes, Space } from 'models/space';
import { ScriptTarget } from 'models/script';
import { AvatarView } from './avatar_view';
import { Avatar } from 'models/avatar';
import { ScriptExecutor } from 'models/script_executor';

class TileView extends BaseView<Tile> implements ScriptTarget {

  $other: JQuery<HTMLElement>;
  script_avatars:AvatarView<Avatar>[];
  executor: ScriptExecutor;
  scenes;

  constructor(options) {
    super(options);
    this.initialize(options);
    this.dispatchCreated('TileView');
  }

  initialize(settings) {
    super.initialize(settings)
    this.render();
    (this.model as any).$other = this.$other = this.$(".other");
  }

  className= "tile hascontextmenu "

  classNames() {
    return super.classNames() + " tile hascontextmenu tile_" + this.model.get("x") + "_" + this.model.get("y");
  }

  get layer():LayerAttributes {
    return this.model.layer;
  }

  get space():Space {
    return this.model.space;
  }

  get showEditor() {
    return store.getState().map.space.canEdit;
  }

  public events() {
    return {
      contextmenu: (e) => {
        if (this.showEditor)
          this.show_menu(e)
        else
          e.preventDefault();
        return false;
      }
    }
  }

  show_npc_dialog(x, y) {

    pick_npc({ dispatcher: this.dispatcher }).then((npc) => {
      this.place_npc(npc.get('id'),null, x, y)
    });

    return false;
  }

  async place_npc(id, label, x, y) {
    this.menu.remove();

    const npc_placement = new NpcPlacement({
      space_id: this.model.space.get("id"),
      npc_id: id,
      x: this.model.x + x,
      y: this.model.y + y
    }, { dispatcher: this.dispatcher });

    const npc = await Npcs.get(id)

    npc_placement.save(null, {
      error: function(jqXHR, text) {
        console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        console.log("AJAX success:", textStatus);
        npc_placement.set(textStatus);

        if (npc) {
          const npcviw = new NpcView({
            model: new Npc(npc,{dispatcher: this.dispatcher}),
            placement: npc_placement,
            dispatcher: this.dispatcher
          });
          this.dispatcher.trigger(Events.ADD_NPC_VIEW, npcviw);
        }else{
          console.log('no npc with id:',id)
        }
        this.menu.remove();
      }
    });
  }

  show_menu(ev) {
    this.setMenu(new Menu({
      collection: {
        play: "Play",
        edit: "Edit Tile",
        add_npc: "Add Npc",
        info: "Info"
      },
      x: ev.offsetX,
      y: ev.offsetY,
      dispatcher: this.dispatcher,
      callback: {
        info: (value, label, x, y) => {
          console.log(this);
          (window as any)[this.cid] = this;
        },
        play: _.bind(this.play_menu, this),
        edit: (value, label, x, y) => {
          this.dispatcher.trigger(Events.NAVIGATE, `/tiles/${this.model.id}/edit`);
        },
        add_npc: (value, label, x, y) => {
          this.show_npc_dialog(x, y);
        }
      }
    }));
    return false;
  }

  play_menu(value, label, x, y) {
    this.setMenu(new Menu({
      collection: {
        enter: "On Enter"
      },
      x: x,
      y: y,
      dispatcher: this.dispatcher,
      callback: (even_type, label) => {
        this.setMenu(new Menu({
          collection: {
            text: "Text",
            sound: "Sound",
            script: "Script",
            video: "Video"
          },
          x: x,
          y: y,
          dispatcher: this.dispatcher,
          callback: {
            text: (text, label) => {
              pick_model('Text', {
                dispatcher: this.dispatcher
              }).then((text) => {
                this.menu.remove();
                this.addEvent(even_type, ["show_text", text.get('id')]);
              });
            },
            sound: (object_type, label) => {
              pick_model('Sound', {
                dispatcher: this.dispatcher
              }).then((sound) => {
                this.menu.remove();
                this.addEvent(even_type, ["play_sound", sound.get('id')]);
              });
            },
            script: (value, label, x, y) => {
              pick_model('Script', {
                dispatcher: this.dispatcher
              }).then((script) => {
                this.menu.remove();
                this.addEvent(even_type, ["play_script", script.get('id')]);
              });
            },
            video: (object_type, label) => {
              pick_model('Video', {
                dispatcher: this.dispatcher
              }).then((video) => {
                this.menu.remove();
                this.addEvent(even_type, ["play_video", video.get('id')]);
              });
            }
          }
        }));
      }
    }));
  }

  addEvent(event, args) {
    const evs = this.model.get("events") || {};
    evs[event] = args;

    this.model.set({ events: evs });

    return this.model.save(null, {
      error: (jqXHR, text) => {
        return console.log("AJAX error:", text);
      },
      success: (data, textStatus, jqXHR) => {
        console.log("AJAX success:", textStatus);
        return this.delegateEvents();
      }
    });
  }

  get x() {
    return this.model.get("x");
  }

  get y() {
    return this.model.get("y");
  }
  get tile_width():number{
    return (this.layer || this.space).tile_width
  }
  get tile_height():number{
    return (this.layer || this.space).tile_height
  }

  render() {
    const width =  this.tile_width+ "px";
    const height = this.tile_height+ "px";

    $(this.el).css({
      width: this.tile_width,
      height: this.tile_height
    }).append($("<img></img>").attr("src", this.model.get("public_filename")).css({ /*width: this.tile_width, height: this.tile_height*/})).append("<div class='other' style='position:absolute;top:0;left:0;'></div>");
    return this;
  }

};

export { TileView};
