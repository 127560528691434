import { fabric } from 'fabric';

import CanvasController from './CanvasController';
import BaseHandler from './BaseHandler';
/**
 * Event Handler Class
 * @author salgum1114
 * @class EventHandler
 */
class FrameHandler extends BaseHandler {
  north: HTMLDivElement;
  east: HTMLDivElement;
  south: HTMLDivElement;
  west: HTMLDivElement;
  frame: HTMLDivElement;
  constructor(handler: CanvasController) {
    super(handler);
    this.initialize();
  }

  public initialize(){
    this.frame = this.controller.frame.children[0] as HTMLDivElement;
    this.north = this.controller.frame.children[1] as HTMLDivElement;
    this.east = this.controller.frame.children[2] as HTMLDivElement;
    this.south = this.controller.frame.children[3] as HTMLDivElement;
    this.west = this.controller.frame.children[4] as HTMLDivElement;
    this.recalcPosition();
  }

  public onZoom(zoomFactor) {
    this.recalcPosition(zoomFactor)
  }

  public recalcPosition(zoomFactor=this.controller.zoom){
    const height = this.controller.workarea.height * zoomFactor;
    const width = this.controller.workarea.width * zoomFactor;
    this.north.style.width = this.south.style.width = `${width}px`;

    const {x,y} = this.calculateTopLeft();
    this.north.style.left = this.south.style.left = `${x}px`;
    this.north.style.height = `${y}px`;
    this.south.style.height = `${Math.max(0, this.controller.container.offsetHeight - (y+height))}px`;

    this.west.style.width = `${x}px`;

    this.east.style.width = `${Math.max(0, this.controller.container.offsetWidth - (x+width))}px`;
    this.east.style.left = `${x+width}px`

    this.frame.style.height = `${height}px`;
    this.frame.style.width = `${width}px`;

    this.frame.style.left = `${x}px`;
    this.frame.style.top = `${y}px`;
  }

  public calculateTopLeft(){
    const pt = new fabric.Point(this.controller.workarea.left, this.controller.workarea.top);
     
    return fabric.util.transformPoint(pt, this.controller.canvas.viewportTransform);
    //return {x: Math.max(x,0), y: Math.max(y,0) }
  }

  public moving = (e: MouseEvent) => {
    if (this.isDrawingMode())
      return;
    this.recalcPosition();
  };

}

export default FrameHandler;
