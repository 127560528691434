import React, {ReactNode, useEffect, useState} from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

import NiceBg from 'components/Home/nice_bg';
import {Avatars} from 'api/agent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    leftSide: {
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    graphicContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightSide: {
      padding: theme.spacing(4),
    },
  })
);

const AccountLayout: React.FC<React.HTMLAttributes<HTMLDivElement> & {nice_content?:React.ReactElement}> = (props) => {

  const classes = useStyles();
  const nice_content:React.ReactElement = props.nice_content;

  const [avatar, setAvatar] = useState(null)
  let isMounted =true;

  useEffect(() => {

    async function fetchAvatars() {
      if (!avatar) {
        const avatars = await Avatars.all({ bag: 'home-avatar' });
        const avatar = avatars[0];
        if(!isMounted)return;
        setAvatar(avatar)
      }
    }
    fetchAvatars();
    return () => { isMounted = false; };

  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={false} sm={false} md={8} className={classes.leftSide}>
        <Box className={classes.graphicContainer} sx={{position: 'relative'}}>
          <NiceBg avatar={avatar} avatarSays={<p>Hola!</p>}>
            {nice_content}
          </NiceBg>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Box className={classes.rightSide}>
          {props.children}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AccountLayout;

