import {GlitchLadder} from 'components/glitch/space';
import Phaser from 'phaser'

import {CATEGORY_LADDER } from 'game/scenes/mainScene';

class Ladder extends Phaser.GameObjects.GameObject {

  ladder: GlitchLadder

  constructor(scene: Phaser.Scene, ladder: GlitchLadder, offset_x: number, offset_y: number) {

    super(scene, 'ladder')

    scene.add.existing(this)

    this.ladder = ladder;

    this.body = this.scene.matter.add.rectangle(ladder.x + offset_x, ladder.y + offset_y-(ladder.h/2), ladder.w, ladder.h, {
      isStatic: true,
      isSensor: true,
      label: 'ladder',
      collisionFilter: {
        category: CATEGORY_LADDER,
      }
    });

  }

}

export default Ladder
