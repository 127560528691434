import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
import CanvasController from './CanvasController';
import BaseHandler from './BaseHandler';

class ContextmenuHandler extends BaseHandler{

  contextmenuEl: HTMLDivElement;

  constructor(handler: CanvasController) {
    super(handler)
    this.initialize();
  }

  /**
   * Initialize contextmenu
   *
   */
  public initialize() {
    this.contextmenuEl = document.createElement('div');
    this.contextmenuEl.id = `${this.controller.id}_contextmenu`;
    this.contextmenuEl.className = 'rde-contextmenu contextmenu-hidden';
    document.body.appendChild(this.contextmenuEl);
  }

  /**
   * Destroy contextmenu
   *
   */
  public destory() {
    super.destroy();
    if (this.contextmenuEl) {
      document.body.removeChild(this.contextmenuEl);
    }
  }

  /**
   * Show context menu
   *
   */
  public show = debounce(async (e, target) => {
    const { onContext } = this.controller;
    while (this.contextmenuEl.hasChildNodes()) {
      this.contextmenuEl.removeChild(this.contextmenuEl.firstChild);
    }
    const contextmenu = document.createElement('div');
    contextmenu.className = 'rde-contextmenu-right';
    const element = await onContext(e, target, this.controller);
    if (!element) {
      return;
    }
    //contextmenu.innerHTML = element;
    this.contextmenuEl.appendChild(contextmenu);
    ReactDOM.render(element, contextmenu);
    this.contextmenuEl.classList.remove('contextmenu-hidden');
    const { clientX: left, clientY: top } = e;
    this.contextmenuEl.style.left = `${left}px`;
    this.contextmenuEl.style.top = `${top}px`;
  }, 100);

  /**
   * Hide context menu
   *
   */
  public hide = debounce(() => {
    if (this.contextmenuEl) {
      this.contextmenuEl.classList.add('contextmenu-hidden');
    }
  }, 100);
}

export default ContextmenuHandler;
