import React, { useState } from 'react';
import { Texts } from 'api/agent';
import ListErrors from 'components/shared/ListErrors';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

type TextNewProps = {
  errors: any[];
};

const TextNew: React.FC<TextNewProps> = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState(null);

  const submitForm = async () => {
    const text = {
      name,
      description,
      content,
    };

    Texts.create(text).then((response) => {
      console.log('response:',response);
    }).catch((e) => {
      console.log('error:',e);
      setErrors(e.response.body)
    });
  };

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3 col-xs-12">
        <h1 className="text-xs-center">New Text</h1>

        {errors ? <ListErrors errors={errors} /> : null }
    
        <form className="form-horizontal" method="post" onSubmit={ev => {ev.preventDefault(); submitForm()}}>
          <div className="form-group">
            <label className="control-label col-12 col-md-4" htmlFor="space_Name">Name:</label>
            <div className="col-md-8">
              <input className="form-control" type="text" value={name} name="space[name]" onChange={(e) => setName(e.target.value)} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
            <div className="col-md-8">
              <textarea className="form-control" value={description} name="space[description]" onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
            <div className="col-md-8">
              <ReactTrixRTEToolbar toolbarId="react-trix-rte-editor" />
              <ReactTrixRTEInput
                toolbarId="react-trix-rte-editor"
                defaultValue="React Trix Rich Text Editor"
                onChange={(e, newValue) => setContent(newValue)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-md-8 col-md-offset-4">
              <input type="submit" name="commit" value="Save" className="btn btn-primary"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TextNew;
