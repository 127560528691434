import * as _ from "underscore";
import { Events } from "maps/map_events";

const css_editable = {

  updateCss: function(newCss) {
    _.extend(this.css, newCss);
    this.$el.css(this.css);
  },

  toggle_editable: function(showCssEditor) {

    const CssEditorView = require('maps/views/css_editor_view');

    if (this.is_editable) {
      this.model.set({
        css: this.css
      });
      this.model.save(null, {
        error: _.bind(function(jqXHR, text) {
          console.log("AJAX error:", text);
        }, this),
        success: _.bind(function(data, textStatus, jqXHR) {
          this.is_editable = false;
          this.$el.removeClass("css_editable");

          //this.$outer.draggable("destroy");
          this.$el.resizable("destroy");
          console.log("AJAX success:", textStatus);
        }, this)
      });
      return;
    }
    this.$el.addClass("css_editable");
    this.is_editable = true;
    if (showCssEditor && (!(this instanceof CssEditorView))) {
      this.dispatcher.trigger(Events.EDIT_CSS, this);
      this.css = this.model.get("css") || {};
    } else {
      this.css = {};
    }
    return this.$el.resizable({
      handles: "ne, se, sw, nw",
      start: function(ev, ui) {},
      stop: function(ev, ui) {},
      resize: (ev, ui) => {
        this.updateCss({
          width: ui.size.width,
          height: ui.size.height
        });
      }
    });
  }
};

export { css_editable };
