import { ChromaFrame } from "components/creator/masking/chroma";

export const uriToImg = (uri) => new Promise<HTMLImageElement>((accept,reject) =>{
  const img = new Image();
  img.onload = (e) => accept(img);
  img.onerror= (e) => reject(e);
  img.src=uri;
})

export const make_tile = async (frames:Array<ChromaFrame>):Promise<[HTMLCanvasElement,any,number,number]> => {

  const img = await uriToImg(frames[0].mask_url);

  const sprite_width=img.naturalWidth, 
        sprite_height=img.naturalHeight;

  const result = {};
  const columns = Math.ceil(Math.sqrt(frames.length));
  const rows = Math.ceil(frames.length / columns);
  const width = columns * sprite_width;
  const height = rows * sprite_height;

  const output:HTMLCanvasElement= document.createElement('canvas');

  Object.assign(output, {
    width: width,
    height: height
  });

  const ctx = output.getContext('2d');

  for (let i = 0, len = frames.length; i < len; i++) {
    const x = (i % columns) * sprite_width;
    const y = Math.floor(i / columns) * sprite_height;

    const img = await uriToImg(frames[i].mask_url);
    ctx.drawImage(img, 0, 0, sprite_width, sprite_height, x, y, sprite_width, sprite_height);

    result[String(i)] = {
      frame: {
        x: x,
        y: y,
        w: sprite_width,
        h: sprite_height
      },
      rotated: false,
      trimmed: true,
      sourceSize: {
        w: sprite_width,
        h: sprite_height
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: sprite_width,
        h: sprite_height
      },
    };

    if(frames[i].left)
      result[String(i)].transforms= {
        translateX: `${(frames[i].left || 0)*100}%`,
        translateY: `${(frames[i].top || 0)*100}%`
      }

  }

  return [output, result,sprite_width,sprite_height];
}


