import { fabric } from 'fabric';
import 'mediaelement';
import 'mediaelement/build/mediaelementplayer.min.css';

declare class MediaElementPlayer {
  constructor(
    id: string,
    options: {
      pauseOtherPlayers: boolean;
      videoWidth: string;
      videoHeight: string;
      success: (mediaeElement: any, originalNode: any, instance: any) => void;
    },
  );
  setPlayerSize(width:number,height:number);
}

import { toObject, FabricElement } from '../utils';

export interface IVideoObject extends FabricElement {
  setSource: (source: string | File) => void;
  setFile: (file: File) => void;
  setSrc: (src: string) => void;
  file?: File;
  src?: string;
  videoElement?: HTMLVideoElement;
  player?: any;
}

class VideoObject extends fabric.Rect {

  static type= 'video'

  static superType= 'element'

  hasRotatingPoint= false
  file:File|null
  src:string|null
  fill:string= 'rgba(255, 255, 255, 0)'
  stroke:string= 'rgba(255, 255, 255, 0)'
  player: MediaElementPlayer;

  container:HTMLDivElement
  editable:boolean
  element:HTMLDivElement
  id:string
  width:number
  height:number
  autoplay:boolean
  muted:boolean
  loop:boolean
  videoElement: HTMLVideoElement;
  //@ts-ignore
  initialize(source: string | File, options: any) {
    options = options || {};
    super.initialize(options);
    if (source instanceof File) {
      //@ts-ignore
      this.set({ file: source, src: null});
    } else {
      //@ts-ignore
      this.set({ file: null, src: source, });
    }
    //@ts-ignore
    this.set({
      fill: 'rgba(255, 255, 255, 0)',
      stroke: 'rgba(255, 255, 255, 0)',
    });
  }

  setSource(source: any) {
    if (source instanceof File) {
      this.setFile(source);
    } else {
      this.setSrc(source);
    }
  }

  setFile(file: File) {
    //@ts-ignore
    this.set({
      file,
      src: null,
    });
    const reader = new FileReader();
    reader.onload = () => {
      //@ts-ignore
      this.player.setSrc(reader.result);
    };
    reader.readAsDataURL(file);
  }

  setSrc(src: string) {
    //@ts-ignore
    this.set({ file: null, src, });
    //@ts-ignore
    this.player.setSrc(src);
  }

  toObject(propertiesToInclude: string[]) {
    return toObject(this, propertiesToInclude, {
      src: this.get('src'),
      file: this.get('file'),
      container: this.get('container'),
      editable: this.get('editable'),
    });
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render(ctx);
    if (!this.element) {
      const { id, scaleX, scaleY, width, height, angle, editable, src, file, autoplay, muted, loop } = this;
      const zoom = this.canvas.getZoom();
      const left = this.calcCoords().tl.x;
      const top = this.calcCoords().tl.y;
      const padLeft = (width * scaleX * zoom - width) / 2;
      const padTop = (height * scaleY * zoom - height) / 2;
      this.videoElement = fabric.util.makeElement('video', {
        id,
        autoplay: editable ? false : autoplay,
        muted: editable ? false : muted,
        loop: editable ? false : loop,
        preload: 'none',
        controls: false,
      }) as HTMLVideoElement;
      this.element = fabric.util.wrapElement(this.videoElement, 'div', {
        id: `${id}_container`,
        style: `transform: rotate(${angle}deg) scale(${scaleX * zoom}, ${scaleY * zoom});
                        width: ${width}px;
                        height: ${height}px;
                        left: ${left + padLeft}px;
                        top: ${top + padTop}px;
                        position: absolute;
                        user-select: ${editable ? 'none' : 'auto'};
                        pointer-events: ${editable ? 'none' : 'auto'};`,
      }) as HTMLDivElement;

      //@ts-ignore
      const container = document.getElementById(this.container);
      container.appendChild(this.element);
      this.player = new MediaElementPlayer(id, {
        pauseOtherPlayers: false,
        videoWidth: '100%',
        videoHeight: '100%',
        success: (_mediaeElement: any, _originalNode: any, instance: any) => {
          if (editable) {
            instance.pause();
          }
        },
      });
      //@ts-ignore
      this.player.setPlayerSize(width, height);
      if (src) {
        this.setSrc(src);
      } else if (file) {
        this.setFile(file);
      }
    }
  }

  static fromObject (options: IVideoObject, callback: (obj: VideoObject) => any) {
    //@ts-ignore
    return callback(new fabric.Video(options.src || options.file, options));
  };

};

fabric.Video = fabric.util.createClass(VideoObject, {
  type: VideoObject.type
});

fabric.Video.fromObject = VideoObject.fromObject;

declare module 'fabric' {
  namespace fabric {
    class Video extends VideoObject {
      constructor(src, options: any)
    }
  }
}

export default fabric.Video;
