import _ from "underscore";
import { ParsedSentence } from "./parsed_sentence";
import * as Backbone from "backbone";
import { NounType } from "./noun";
import { Verb } from "./verb";

class QuerySource {

  verbList: Verb[];
  nounTypeList: NounType[];
  hilited_idx: number;
  context: any;
  suggestion_list: ParsedSentence[];

  constructor(verbList, nounList:NounType[], context) {
    this.verbList = verbList;
    this.nounTypeList = nounList;
    this.hilited_idx = null;
    this.context = context;
    this.suggestion_list = [];
  }

  updateSuggestionList(input):Promise<any> {
    this.suggestion_list = [];
    this.hilited_idx = 0; // hilight the first suggestion by default

    if (!input) {
      this.suggestion_list = this.verbList.map((verb) => {
        if (verb.available && verb.match(input, this.context)) {
          const noun = verb.direct_object?.type || new NounType({label: ''});
          return new ParsedSentence(verb, noun, {});
        }
      }).filter(Boolean);
      return Promise.resolve(false);
    }

    //const words = input.match(/('.*?'|".*?"|\S+)/g);
    //tokenize respecting quoted strings
    const words = input.split('"').map((v,i) => (i%2 ? v : v.split(' '))).flat(1).filter(x => Boolean(x));
    const promises = [];
    this.suggestion_list = this.verbList.map((verb) => {
      if (verb.available && verb.match(words[0], this.context))
        return verb.getCompletions(words.slice(1), this.context, promises)
      else
        return []
    }).flat(1);

    if (this.suggestion_list.length || promises.length) {
      return Promise.all(promises).then((more_suggestions) => this.suggestion_list.push(... _.flatten(more_suggestions)));
    }

    for (let x in this.nounTypeList) {
      const nounType = this.nounTypeList[x];
      if (nounType.match(words[0], this.context)) {
        for (let y in this.verbList) {
          const verb = this.verbList[y];
          const prefix = verb.canPossiblyUseNounType(nounType)
          if (!(verb.available && prefix)) {
            continue;
          }
          const betterSentence = prefix + " " + input;
          const words = betterSentence.split(" ");
          this.suggestion_list = this.suggestion_list.concat(verb.getCompletions(words.slice(1), this.context, promises));
        }
      }
    }

    return Promise.all(promises).then((more_suggestions) => this.suggestion_list.push(... _.flatten(more_suggestions)));
  }

  suggestions_html() {
    return this.suggestion_list.map(sugg => sugg.getDisplayText())
  }

  getDescriptionText() {
    if (this.suggestion_list.length === 0) {
      return "your command, master?";
    }
    if (this.hilited_idx == null) {
      return "Executes your input literally, with no autocompletion.";
    }
    return this.hilited_suggestion.getDescription();
  }

  indication_down() {
    this.hilited_idx = (this.hilited_idx == null ? 0 : this.hilited_idx + 1);
    if (this.hilited_idx >= this.suggestion_list.length) {
      return this.hilited_idx = null;
    }
  }

  indication_up() {
    this.hilited_idx = (this.hilited_idx == null ? 0 : this.hilited_idx - 1);
    if (this.hilited_idx < 0) {
      return this.hilited_idx = null;
    }
  }

  get hilited_suggestion():ParsedSentence {
    if(this.suggestion_list)
      return this.suggestion_list[this.hilited_idx]
    else
      return null
  }

  autocomplete(input) {
    if(this.hilited_idx !== null) {
      return this.hilited_suggestion.getCompletionText() + " ";
    } else {
      return input;
    }
  }

  clear() {
    this.suggestion_list = [];
    return this.hilited_idx = null;
  }

  async execute(context?) {
    if (this.hilited_idx !== null) {
      const result = await this.hilited_suggestion.execute(context || this.context);
      return result;
    }
  }

};

_.extend(QuerySource.prototype, Backbone.Events);

export { QuerySource};
