import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  REGISTER,
  LOAD_FEATURES,
  SET_CSRF,
} from 'constants/actionTypes';

const defaultState = {
  appName: 'Viwoc',
  csrf_token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {

  switch (action.type) {
    case LOAD_FEATURES:
      return { ...state, features: action.payload };
    case SET_CSRF:
      return {
        ...state,
        csrf_token: action.payload || null,
      };

    case APP_LOAD:
      return {
        ...state,
        csrf_token: action.csrf_token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null
      };

    case REDIRECT:
      return { ...state, redirectTo: null };

    case LOGOUT:
      return { ...state, redirectTo: '/', csrf_token: null, currentUser: null };

    case LOGIN:
    case REGISTER:
      return {
        ...state,
        csrf_token: action.error ? state.csrf_token : action.payload.csrf_token,
        currentUser: action.error ? null : action.payload.user,
        initialData: action.authData
      };

    default:
      return state;
  }
};

