import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function NamePrompt(props:{onSave:Function, open:boolean}) {

  const [name, setName] = useState<string>('');
  const [tipo, setTipo] = useState<string>('Avatar');

  const handleCancel = () => {
    props.onSave(null);
  }

  const handleSave = () => {
    props.onSave(name, tipo);
  };

  return (
      <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ponle un nombre a tu personaje</DialogTitle>
        <DialogContent>
          <DialogContentText>
Para poder referirnos al personaje que acabas de crear, dale un nombre, por favor:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={(ev) => setName(ev.target.value)}
            value={name}
            fullWidth
          />

          <ToggleButtonGroup
            value={tipo}
            exclusive
            onChange={(ev,value) => setTipo(value)}
            aria-label="Tipo"
          >
            <ToggleButton value="Npc" aria-label="npc">
              Npc
            </ToggleButton>
            <ToggleButton value="Avatar" aria-label="avatar">
              Avatar
            </ToggleButton>
          </ToggleButtonGroup>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
  );
}

