import * as _ from 'underscore';
import { EditorView } from './editor';
import { Video } from 'models/video';

const RecordRTC = require('recordrtc');

class EditVideoView extends EditorView<Video> {

  className = 'EditVideo'
  recorder: any;
  file: any;

  copies= {
    success_title: 'Success!',
    success_subtitle: 'Your video has been saved',
  }

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    return super.initialize(settings);
  }

  events() {
    return _.extend(super.events(), {
      'click [data-cmd=toggle-recording]': function(e) {
        if (this.recording) {
          this.recording = false;
          this.$('[data-cmd=toggle-recording]').html('Start Recording');
          this.stopRecording();
        } else {
          this.recording = true;
          this.record();
          this.$('[data-cmd=toggle-recording]').html('Stop Recording');
        }
      },
      'click [data-cmd=play-recording]': function(e) {
        var url;
        url = URL.createObjectURL(this.file);
        if (!this.video) {
          this.video = $("<video class=\"img-fluid\" controls></video>").appendTo(this.$el)[0];
        }
        this.video.src = url;
        return this.video.play();
      }
    });
  }

  record() {
    return navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {
      this.recorder = RecordRTC(stream, { type: 'video' });
      this.recorder.startRecording();
    });
  }

  stopRecording() {
    this.recorder.stopRecording(() => {
      this.file = this.recorder.getBlob();
      this.file.name = `video.${this.file.type.split('/')[1]}`;
    });
  }

  previewImage(url) {
    return console.log('preview', url);
  }

  template() {
    this.document = [];
    this.render$(() => {
      this.form$(".form-horizontal", () => {
        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', 'Name');
          this.div$('.col-md-8', () => {
            this.input$('.form-control', { val: this.model.get('name'), name: 'name' });
          });
        });

        this.div$('.form-group', () => {
          this.label$('.control-label.col-md-4', 'Description');
          this.div$('.col-md-8', () => {
            this.textarea$('.form-control', { name: 'description' }, () => {
              this.raw(this.model.get('description'));
            });
          });
        });

        this.ul$(".nav.nav-tabs", { role: "tablist" }, () => {
          this.li$(".nav-item.active", { role: "presentation" }, () => {
            this.a$('.nav-link', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#upload" } }, "Upload");
          });
          this.li$(".nav-item.inactive", { role: "presentation" }, () => {
            this.a$('.nav-link', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#record" } }, "Record");
          });
        });

        this.div$(".tab-content", () => {
          this.div$("#upload.tab-pane.fade.show.active", { role: "tabpanel" }, () => {
            this.render$(this.attachmentInput);
          });
          this.div$("#record.tab-pane.fade", { role: "tabpanel" }, () => {
            this.div$('.btn.btn-info', { data: { cmd: 'toggle-recording' } }, 'Start Recording');
            this.div$('.btn.btn-secondary', { data: { cmd: 'play-recording' } }, 'Play Recording');
          });
        });

        this.div$('.form-group', () => {
          this.div$('.col-md-8.col-md-offset-4', () => {
            this.button$('.btn.btn-primary', "Enviar");
          });
        });
      });
    });
    return this.document.join('');
  }

  createFormData() {
    var fd;
    fd = new FormData();
    // TODO if tab=Recording and then tab=Upload, we should set @file=null on tab switch
    this.file = this.file || (this.$('input[type=file]')[0] as any).files[0];
    fd.append('video[video]', this.file, this.file.name.replace(/;.*$/,''));
    fd.append('video[name]', this.model.get('name'));
    fd.append('video[description]', this.model.get('description'));
    return fd;
  }

  attachmentInput() {
    return this.div$('.form-group', () => {
      this.label$('.control-label.col-md-4', {
        for: 'video'
      }, 'Video');
      return this.div$('.col-md-8.dropzone', () => {
        return this.input$('.form-control', {
          type: 'file',
          name: 'video'
        });
      });
    });
  }

  render() {
    return super.render();
  }

};

export { EditVideoView};
