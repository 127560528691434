import {Router, Events as BackboneEvents, RouterOptions} from 'backbone';
import { pick_model } from 'components/shared/pick_model';
import * as BootstrapHelpers from 'bs_helpers'
//import { TileEditor } from 'tile_editor/editor';
import { Events } from './map_events';

const Swal = require('sweetalert2');

class MapRouter extends Router {

  public dispatcher: BackboneEvents;
  views: {[x:string]: any};
  viewing: any;

  constructor(opts) {
    super(opts as RouterOptions)
  }

  initialize(opts) {
    console.log('initializing Router');
    ({dispatcher: this.dispatcher} = opts);
    this.views = {};
  }

  not_found(path) {
  }

  all() {
    console.log('watching all');
  }

  new_sound() {
    this.dispatcher.trigger(Events.NEW_MODEL, { class: 'Sound' });
  }

  new_picture() {
    this.dispatcher.trigger(Events.NEW_MODEL, { class: 'Picture' });
  }

  new_video() {
    this.dispatcher.trigger(Events.NEW_MODEL, { class: 'Video' });
  }

  new_text() {
    this.dispatcher.trigger(Events.NEW_MODEL, { class: 'Text' });
  }

  show_picker(model) {
    pick_model(model, { dispatcher: this.dispatcher }).then((x) => {
      Swal.fire({
        type: 'success',
        timer: 1000,
        title: `Picked ${x.get('name')}`,
        text: ''
      });
    });
  }

  edit_tile(id) {
    var $modal;
    if (this.views.tile_editor) {
      this.views.tile_editor.remove();
    }
    $modal = BootstrapHelpers.create_modal_fullscreen({
      title: 'Edit tile'
    }).$modal;

    $.get(`/tiles/${id}.json`).then((data, text, xhr) => {
      //this.views.tile_editor = new TileEditor({
      //  dispatcher: this.dispatcher,
      //  fabric: data.fabric,
      //  bg_url: data.bg_url,
      //  url: data.url,
      //  width: data.width,
      //  height: data.height
      //});

      this.views.tile_editor.setupCanvas().then(() => {
        $modal.appendTo($('body')).modal('show');
        this.views.tile_editor.render().$el.appendTo($modal.find('.modal-body'));
      });
    });
  }

  goto_space(id_string) {
    return console.log('going to space', id_string);
  }

};

MapRouter.prototype.routes = {
  '': 'all',
  'picker/:class': 'show_picker',
  'sounds/new': 'new_sound',
  'pictures/new': 'new_picture',
  'videos/new': 'new_video',
  'texts/new': 'new_text',
  'tiles/:id/edit': 'edit_tile',
  'spaces/:id': 'goto_space',
  '*path': 'not_found'
};

export {MapRouter};
