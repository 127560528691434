import React, { useState } from 'react';
import { Dialog, InputBase, makeStyles } from '@material-ui/core';
import SearchkitTest from 'components/searchkit_test';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    width: '60%',
    maxWidth: '600px',
    padding: theme.spacing(2),
  },
  inputRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '2px 4px',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
  },
}));

const SpotlightSearch = ({showSearch, setShowSearch, onHitClick}) => {
  const classes = useStyles();

  const handleClose = () => setShowSearch(false);

  return <Dialog onClose={handleClose} aria-labelledby="spotlight-search" open={showSearch} PaperProps={{ className: classes.dialogPaper }}>
    <div className={classes.inputRoot}>
      <SearchkitTest onHitClick={onHitClick} />
    </div>
  </Dialog>
};

export default SpotlightSearch;

