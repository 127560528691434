import React, { useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { FabricRemover } from './fabric_remover';
import { ChromaFrame } from './chroma';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenRemover = (props:{open:boolean, handleClose:Function, handleSave:Function, frame:ChromaFrame } & React.HTMLAttributes<HTMLDivElement>) => {

  const remover = useRef<FabricRemover>(null);

  const classes = useStyles();

  return (
    <Dialog fullScreen open={props.open} onClose={(e) => props.handleClose(e)} TransitionComponent={Transition} className={props.className}>
      <AppBar className={classes.appBar}>
	<Toolbar className="theToolbar">
          <Button autoFocus color="inherit" onClick={(e) => props.handleSave(remover.current.getResult())}>
	    save
	  </Button>
          <IconButton edge="end" color="inherit" onClick={(e) => props.handleClose(e)} aria-label="close">
	    <CloseIcon />
	  </IconButton>

	</Toolbar>
      </AppBar>

      <FabricRemover ref={remover} frame={props.frame}></FabricRemover>

    </Dialog>
  );
}
