import { BaseModel } from "./base_model";
import { ItemAttributes } from "./item";

const isAdmin = (usr:UserAttributes) => (usr?.roles || []).includes('admin')
const isEditor = (usr:UserAttributes) => (usr?.roles || []).includes('editor')

type UserAttributes = {
  id: number,
  uuid: string,
  name: string,
  surname: string,
  nickname: string,
  email: string,
  roles: string[],
  items: ItemAttributes[],
}
class User extends BaseModel {
  name: any;

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('User');
  }

  _className() {
    return "User";
  }

  initialize(settings, opts) {
    this.name = settings.name;
    return super.initialize(settings, opts);
  }

  label() {
    return this.name;
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "users", this.id || ''].join('/');
  }

};

export { User, UserAttributes, isAdmin, isEditor};
