;(function(Form) {

  Form.editors.Number = Form.editors.Text.extend({
    events: { },

    initialize: function(options) {
      Form.editors.Base.prototype.initialize.call(this, options);

      var schema = this.schema;

      //Allow customising text type (email, phone etc.) for HTML5 browsers
      var type = 'number';

      if (schema && schema.editorAttrs && schema.editorAttrs.type) type = schema.editorAttrs.type;
      if (schema && schema.dataType) type = schema.dataType;

      this.$el.attr('type', type);
    }

  });

  Form.editors.Range = Form.editors.Text.extend({

    className: 'form-control-range ',

    events: _.extend({}, Form.editors.Text.prototype.events, {
      'keypress': 'onKeyPress',
      'change': 'onKeyPress'
    }),

  /**
   * Check value is numeric
   */
  onKeyPress: function(event) {
    var self = this,
        delayedDetermineChange = function() {
          setTimeout(function() {
            self.determineChange();
          }, 0);
        };

    //Allow backspace
    if (event.charCode === 0) {
      delayedDetermineChange();
      return;
    }

    //Get the whole new value so that we can prevent things like double decimals points etc.
    var newVal = this.$el.val()
    if( event.charCode != undefined ) {
      newVal = newVal + String.fromCharCode(event.charCode);
    }

    var numeric = /^[+-]?[0-9]*\.?[0-9]*?$/.test(newVal);

    if (numeric) {
      delayedDetermineChange();
    } else {
      event.preventDefault();
    }
  },



    initialize: function(options) {
      Form.editors.Base.prototype.initialize.call(this, options);

      var schema = this.schema;

      //Allow customising text type (email, phone etc.) for HTML5 browsers
      var type = 'range';

      if (schema && schema.editorAttrs && schema.editorAttrs.type)
        type = schema.editorAttrs.type;

      if (schema && schema.dataType)
        type = schema.dataType;

      this.$el.attr('type', type);

      this.$el.attr('min', _.result(schema, 'min') || 0);
      this.$el.attr('max', _.result(schema, 'max') || 100);
      this.$el.attr('step', _.result(schema,'step') || 1);
  }

});


})(Backbone.Form);
