import BaseView from "./base_view";
import _ from "underscore";

class BaseMenu extends BaseView<any> {

  menu_template :any;
  className = "menu_view";
  memo: any;
  x: any;
  y: any;
  label: (thingy: any) => any;
  remove_cb: any;
  value: (thingy: any) => any;
  col: any;

  constructor(options) {
    super(options);
    this.dispatchCreated('BaseMenu');

    ({
      memo: this.memo,
      x: this.x,
      y: this.y,
      label: this.label = function(thingy) { return thingy.label; },
      remove_cb: this.remove_cb,
      value: this.value = function(thingy) { return thingy.value; }
    } = options);
  }

  initialize(settings) {
    if (_.isArray(settings.collection)) {
      this.col = settings.collection;
    } else if (_.isObject(settings.collection)) {
      this.col = Object.keys(settings.collection).reduce((memo, label) => {
        const value=settings.collection[label];
        memo.push({
          value: value,
          label: label
        });
        return memo;
      }, []);
    }

    if (_.isFunction(settings.mouseenter_callback)) {
      this.on("menu:mouseenter", settings.mouseenter_callback);
    } else if (_.isObject(settings.mouseenter_callback)) {
      this.on("menu:mouseenter", (label, value, x, y) => {
        return settings.mouseenter_callback[value].call(this, value, label, x, y);
      });
    }

    if (_.isFunction(settings.callback)) {
      this.on("menu:selected", settings.callback);
    } else if (_.isObject(settings.callback)) {
      this.on("menu:selected", (label, value, x, y) => {
        this.remove();
        if (this.remove_cb) {
          this.remove_cb();
        }
        settings.callback[value].call(this, value, label, x, y);
      });
    }
  }

  handle_change(ev) {
    var d, v;
    v = $(ev.target).val();
    d = $(ev.target).data("label");
    this.trigger("menu:selected", v, d, this.x, this.y);
    return false;
  }

  render() {
    this.$el.css({
      position: "absolute",
      left: this.x + "px",
      top: this.y + "px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }).html(this.menu_template(this));
    $(document).off(".menu_view");
    $(document).on("click.menu_view", (e) => {
      if ($(e.target).closest(".menu_view").length) {
        return true;
      }
      this.undelegateEvents();
      this.$el.removeData().unbind();
      if (this.remove_cb) {
        this.remove_cb();
      }
      this.remove();
      e.preventDefault();
      $(document).off(e);
      return false;
    });
    return this;
  }

};


export { BaseMenu };
