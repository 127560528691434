import {useDesignContext} from 'contexts/design_context';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Tabs, Tab, Typography, Box } from '@material-ui/core';

import React, {useState} from 'react';
import ActionsConfig from './actions_config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ padding: 16 }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  canvasContainer: {
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  drawerPaper: {
    width: "30rem",
  },
  canvasElement: {
    border: '1px solid #ccc',
  },
}));

export default function ItemPropertiesDrawer(props:any) {

  const classes = useStyles();

  const {selectedObject } = useDesignContext();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const isPanelOpen = selectedObject != null;

  return <Drawer
    anchor="right"
    open={isPanelOpen}
    variant="persistent"
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      orientation="vertical"
      variant="scrollable"
    >
      <Tab label="Properties" />
    </Tabs>

    <TabPanel value={selectedTab} index={0}>
      <Typography variant="h6">Properties</Typography>
      <ActionsConfig />
    </TabPanel>

</Drawer>
}
