import { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from "react";
import axios from 'axios';
import { ClazzProps, Clazz } from "./clazz";
import { PaginationAttrs } from "shared/components/pagination";
import Pagination from '@material-ui/lab/Pagination';

class ClazzIndex extends Component<{history:any, xs:number,md:number, onClick?:Function, match:any}, {clazzes: Array<ClazzProps>, pagination?:PaginationAttrs, query:string,loading:boolean,message:string}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}
  cancel:any;

  constructor(props) {
    super(props);

    this.state = {clazzes: [], query: '', loading: true, message: ''};
  }

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, clazzes: [], loading: false, message: '' } );
    } else {
      this.setState({ query, clazzes: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/clazzes/search.json?models=Clazz&query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? 'There are no more search results. Please try a new search.'
          : '';
        this.setState({
          clazzes: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };


  componentDidMount() {
    let url;
    if(this.props.match.params.p)
      url = `/clazzes.json?page=${this.props.match.params.p}`
    else
      url = `/clazzes.json`

    axios.request<Array<ClazzProps>>({url}).then(({data, headers}) => {
      let pagination;
      if(headers['x-pagination']){
        pagination = JSON.parse(headers['x-pagination'])
      }

      this.setState({ clazzes: data, pagination })
    });
  }

  render () {
    return (
      <>
      <Row>
        <div className="col-12 col-md-6 offset-md-3 my-4">
          <input
            className="form-control form-control-lg"
            type="text"
            value={this.state.query}
            id="search-input"
            placeholder="Search..."
            onChange={this.handleOnInputChange}
          />
        </div>
      </Row>

      <Row>

        {this.state.clazzes.filter(clazz => clazz.sprites.length && clazz.sprites[0]).map(clazz => (
          <Col xs={this.props.xs} md={this.props.md} key={clazz.id}>
            <Clazz {...clazz} onClick={(e)=> this.props.onClick(clazz)}></Clazz>
          </Col>
        ))}

        {this.state.pagination &&
        <Pagination count={10} color="primary"  page={this.state.pagination.current_page} onChange={(e,num)=>this.props.history.push(`/clazzes/${num}`)} />
        }
      </Row>
      </>
    )
  }

}

export {ClazzIndex}

