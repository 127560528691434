import * as _ from 'underscore';

import BaseView from 'views/base_view';
import { Picture } from 'models/picture';

const css_editable = require('modules/css_editable');

class PictureView extends BaseView<Picture> {

  css_editable = {
    color: "Color",
    backgroundColor: "Color",
    fontSize: "Number"
  };

  toggle_editable: any;
  x: string;
  y: string;

  constructor(options) {
    super(options);
    this.dispatchCreated('PictureView');
  }

  events() {
    return {
      dblclick: "handle_dblclick",
      click: function(ev) {
        ev.preventDefault();
        return false;
      },
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  };

  className = "picture_view";

  upload_form_template = _.template(`
  <div class="webcam_holder">
</div>
<input class="shoot" type="button" value="Take picture"/>
<hr/>
<form action='/<%= resources %>/<%= id %>' method="post" enctype="multipart/form-data">
  <input name="_method" type="hidden" value="put">
  <input name="<%= resource %>[image]" type="file">
  <input name="commit" type="submit" value="Update <%= resource %>" class="btn-primary">
</form>`);

  template = _.template(`<div class="picture">
  <div class="mymodal-header">
    <button type="button" class="close">&times;</button>
    <h4 class="modal-title">Picture <%= model.get("name") %></h4>
  </div>
  <div class="mymodal-body">
    <img src="<%= model.get("image_url") %>"></img>
  </div>
</div>`);


  handle_dblclick(ev) {
    this.toggle_editable(true);
    ev.preventDefault();
    return false;
  }

  initialize(settings) {}

  render() {
    var css;
    css = _.extend({
      position: "absolute",
      left: this.x + "px",
      top: this.y + "px",
      width: "300px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    }, this.model.get("css"));
    this.$el.css(css);
    if (this.model.get("weblink")) {
      this.$el.html(this.model.get("embed"));
    } else {
      this.$el.html(this.template(this));
    }
    return this;
  }

};


_.extend(PictureView.prototype, css_editable);

export {PictureView};
