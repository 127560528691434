import BaseView from "./base_view";
import * as _ from "underscore";
import ReactDOM from 'react-dom'
import React from 'react'
import { AvatarSearch } from "components/avatars/search";

const Bloodhound = require('typeahead.js');

const defer = require('utils/defer');

class Picker extends BaseView<any> {

  typeahead_template = _.template(`<div class="media">
    <div class="media-left">
      <div class="holder">
        <% if(typeof public_filename != \'undefined\'){ %>
          <img class="img-fluid white-bg" src="<%= public_filename %>>"></img>
        <% } %>
      </div>
    </div>
  
    <div class="media-body">
      <h4 class="media-heading"><%= name %></h4>
    </div>
  </div>`);

  models: string[];
  promise: any;
  engineOwn: any;
  engineOthers: any;

  constructor(opts) {
    super(opts);

    ({models: this.models = ['Npc,Avatar,Picture,Video,Sound,Anchor']} = opts);

    if (!_.isArray(this.models)) {
      this.models = [this.models];
    }
    this.promise = defer();
  }

  className= ' Picker ';

  template() {
    this.document = [];
    this.render$(() => {
      this.ul$(".nav.nav-tabs", { role: "tablist" }, () => {
        this.li$(".active", { role: "presentation" }, () => {
          this.a$('.nav-link.active',{ href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#mine" } }, "Your");
        });
        this.li$(".inactive", { role: "presentation" }, () => {
          this.a$('.nav-link', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#others" } }, "Other's");
        });
        this.li$(".inactive", { role: "presentation" }, () => {
          this.a$('.nav-link', { href: "javascript:void(0)", "aria-controls": "home", role: "tab", data: { toggle: "tab", target: "#react" } }, "React");
        });
      });
      this.div$(".tab-content", () => {
        this.div$("#mine.tab-pane.fade.show.active", { role: "tabpanel" }, () => {
          this.div$('.row', () => {
            this.div$('.col-12.col-12', () => {
              this.form$('.form-inline', () => {
                this.div$('.form-group', () => {
                  this.label$('.sr-only', { for: "exampleInputAmount" }, 'Search');
                  this.div$('.input-group', () => {
                    this.input$('.search_mine.form-control', { placeholder: "Search", type: "text", style: { width: '100%' } });
                    this.div$('.input-group-addon', () => {
                      this.i$('.fa.fa-search');
                    });
                  });
                });
              });
            });
          });
        });
        this.div$("#others.tab-pane.fade", { role: "tabpanel" }, () => {
          this.div$('.row', () => {
            this.div$('.col-12.col-12', () => {
              this.form$('.form-inline', () => {
                this.div$('.form-group', () => {
                  this.label$('.sr-only', { for: "exampleInputAmount" }, 'Search');
                  this.div$('.input-group', () => {
                    this.input$('.search_others.form-control', { placeholder: "Search", type: "text", style: { width: '100%' } });
                    this.div$('.input-group-addon', () => {
                      this.i$('.fa.fa-search');
                    });
                  });
                });
              });
            });
          });
        });

        this.div$("#react.tab-pane.fade", { role: "tabpanel" }, () => {
        })
      });
    });
    return this.document.join('');
  }

  events() {
    return {
      "typeahead:selected": 'handle_selected'
    };
  }

  handle_selected(obj:any, datum:any) {
    this.promise.resolve(datum);
    this.trigger('finished');
    console.log('Search results:', datum);
  }

  prepareTypeahead(selector = '.search_field', url = "/sprites/search?query=%QUERY", template = this.typeahead_template) {
    var engine;
    engine = new Bloodhound({
      remote: {
        url: url,
        wildcard: "%QUERY"
      },
      datumTokenizer: function(d) {
        return d;
      },
      queryTokenizer: function(d) {
        return d;
      }
    });
    engine.initialize();
    (this.$(selector) as any).typeahead({
      minLength: 3,
      highlight: true
    }, {
      templates: {
        empty: '<div class="empty-message"> No results found </div>',
        suggestion: (obj) => {
          obj.obj = obj;
          return template(obj);
        }
      },
      display: 'name',
      source: engine.ttAdapter(),
      limit: 10
    });
    return engine;
  }

  render() {
    super.render();

    this.engineOwn = this.prepareTypeahead('.search_mine', `/search/search?models=${this.models.join(',')}&mine=true&query=%QUERY`);
    this.engineOthers = this.prepareTypeahead('.search_others', `/search/search?models=${this.models.join(',')}&query=%QUERY`);

    ReactDOM.render(
      <div>
        <AvatarSearch md={6} onClick={(e) => console.log('Hola',e)} />
      </div>,
      this.$('#react')[0],
    )

    return this;
  }

};

export { Picker };
