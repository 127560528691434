import React, { useState, useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import RgbQuant from 'rgbquant';
import {GlitchGame} from './glitch/space';
import {Spaces} from 'api/agent';
import {depthmap_url} from 'utils/urls';
import {addCallback} from 'utils/channels';

export const PALETTE:Array<[number,number,number]> = [
  [92, 79, 157], // background
  [103, 166, 173], // midground
  [251, 233, 163], // midground2
  [198, 76, 82], // foreground
];

export const quantizeDepthMap = (depthData: ImageData) => {
  const quantizer = new RgbQuant({
    colors: 4,
    minHueCols: 4,
    method: 2,
    initColors: 4096,
    dithKern: null,
    dithSerp: false,
    palette: PALETTE,
    reIndex: false,
  });

  // Sample the image data
  quantizer.sample(depthData);

  // Reduce the image colors
  const quantizedPalette = quantizer.palette(true);
  const quantizedPixels = quantizer.reduce(depthData, 2);

  // Map indexed pixels back to RGBA
  const quantizedImageDataArray = new Uint8ClampedArray(quantizedPixels.length * 4);

  for (let i = 0; i < quantizedPixels.length; i++) {
    const paletteIndex = quantizedPixels[i];
    const [r, g, b] = quantizedPalette[paletteIndex];

    quantizedImageDataArray[i * 4] = r;
    quantizedImageDataArray[i * 4 + 1] = g;
    quantizedImageDataArray[i * 4 + 2] = b;
    quantizedImageDataArray[i * 4 + 3] = 255;
  }

  // Create a new ImageData object
  const quantizedImageData = new ImageData(
    quantizedImageDataArray,
    depthData.width,
    depthData.height
  );

  return quantizedImageData;
};

export const createImageFromImageData = (imageData: ImageData): HTMLImageElement => {
  // Step 1: Create an off-screen canvas
  const canvasElement = document.createElement('canvas');
  canvasElement.width = imageData.width;
  canvasElement.height = imageData.height;

  // Step 2: Get the context and put the ImageData onto the canvas
  const ctx = canvasElement.getContext('2d');
  ctx.putImageData(imageData, 0, 0);

  // Step 3: Create an image element and set the src to the canvas data
  const img = new Image();
  img.src = canvasElement.toDataURL();

  return img;
}

export const computeQuantizedPart = (selectedColor: [number, number, number], quantizedDepthMap: ImageData, mainData: ImageData): HTMLImageElement => {
  const width = mainData.width;
  const height = mainData.height;
  const newImageDataArray = new Uint8ClampedArray(mainData.data.length);

  for (let i = 0; i < mainData.data.length; i += 4) {
    const qR = quantizedDepthMap.data[i];
    const qG = quantizedDepthMap.data[i + 1];
    const qB = quantizedDepthMap.data[i + 2];

    if (qR === selectedColor[0] && qG === selectedColor[1] && qB === selectedColor[2]) {
      // Copy the pixel from mainData
      newImageDataArray[i] = mainData.data[i];
      newImageDataArray[i + 1] = mainData.data[i + 1];
      newImageDataArray[i + 2] = mainData.data[i + 2];
      newImageDataArray[i + 3] = mainData.data[i + 3];
    } else {
      // Set pixel to transparent
      newImageDataArray[i] = 0;
      newImageDataArray[i + 1] = 0;
      newImageDataArray[i + 2] = 0;
      newImageDataArray[i + 3] = 0;
    }
  }

  const newImageData = new ImageData(newImageDataArray, width, height);
  const img = createImageFromImageData(newImageData);
  return img;
}
// this component is mounted from react router as:
// <Route exact path="/edge/:id" component={EdgeDetectionComponent}/>
const DepthMapComponent: React.FC = ({ match }:any) => {
  if(!match.params.id) return null;
  // State variables
  // main image
  const [mainImage, setMainImage] = useState<HTMLImageElement | null>(null);
  const [mainData, setMainData] = useState<ImageData | null>(null);

  // depth map
  const [depthMap, setDepthMap] = useState<HTMLImageElement | null>(null);
  const [depthData, setDepthData] = useState<ImageData | null>(null);

  // Refs for canvas and context
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // quantized depth map
  const [quantizedDepthMap, setQuantizedDepthMap] = useState<ImageData | null>(null);
  const [quantizedParts, setQuantizedParts] = useState<{[k:string]:HTMLImageElement}>({});
  const [showParts, setshowParts] = useState<{[k:string]:boolean}>({});

  const fabricCanvasRef = useRef<fabric.Canvas | null>(null);

  const mainCanvas = useRef<HTMLCanvasElement | null>(null);
  const depthCanvas = useRef<HTMLCanvasElement | null>(null);

  const [_gameData, setGameData] = useState<GlitchGame|null>(null);
  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  // load game data for 
  useEffect(() => {
    Spaces.show(match.params.id).then((space_json) => {
      //const game_json = await Games.show(space_json.game.uuid)
      if(space_json.location){
        const glitch_game_json:GlitchGame = space_json.location.metadata
        setGameData(glitch_game_json);
        const mainImageUrl = glitch_game_json.background_image.url;
        const mainImage = new Image();
        mainImage.crossOrigin = 'anonymous';
        mainImage.onload = () => {
          setMainImage(mainImage);
        }
        mainImage.src = mainImageUrl;
        const dm_url = depthmap_url({spaceId: match.params.id});
        fetch(dm_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf_token,
          },
          body: JSON.stringify({
            url: mainImageUrl
          })
        }).then((response) => {
          response.json().then((data) => {
            if(data.output){
              const depthImage = new Image();
              depthImage.crossOrigin = 'anonymous';
              depthImage.onload = () => {
                setDepthMap(depthImage);
              }
              depthImage.src = data.output[0];
            }else{
              addCallback(data.uuid, (data) => {
                const depthImage = new Image();
                depthImage.crossOrigin = 'anonymous';
                depthImage.onload = () => {
                  setDepthMap(depthImage);
                }
                depthImage.src = data.output[0];
              }, (error) => {
                console.log('Got error', error);
              })
            }
          })
        })
      }
    })

  }, [match.params.id]);

  useEffect(() => {
    if (mainImage && depthMap) {
      resizeCanvas();
      const {mainImageData: mainData, depthImageData: depthData} = drawImages(mainImage, depthMap);
  
      if (depthData) {
        const quantizedMap = quantizeDepthMap(depthData);
        setQuantizedDepthMap(quantizedMap);
  
        const newQuantizedParts = {};
        PALETTE.forEach((color) => {
          const key = color.join('-');
          newQuantizedParts[key] = computeQuantizedPart(color, quantizedMap, mainData);
        });
        setQuantizedParts(newQuantizedParts);
      }
    }
  }, [mainImage, depthMap]);

  // Event handler for drag over
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  // Event handler for dropping the main image
  const handleMainImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        const img = new Image();
        img.onload = () => {
          setMainImage(img);
        };
        img.src = result;
      }
    };
    reader.readAsDataURL(file);
  };

  // Event handler for dropping the depth map
  const handleDepthMapDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        const img = new Image();
        img.onload = () => {
          setDepthMap(img);
        };
        img.src = result;
      }
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (!fabricCanvasRef.current) {
      fabricCanvasRef.current = new fabric.Canvas('fabric-canvas', { enableRetinaScaling: false });
      (window as any).fabricCanvas = fabricCanvasRef.current;
    }
  }, []);

  // useEffect to draw images when both are loaded
  useEffect(() => {
    if (mainImage && depthMap) {
      resizeCanvas();
      //drawImages(mainImage, depthMap);
    }
  }, [mainImage, depthMap]);

  // Function to draw images onto canvases
  const drawImages = (mainImg: HTMLImageElement, depthImg: HTMLImageElement) => {
    if (!mainImg || !depthImg) return;
    fabricCanvasRef.current.clear();
    // set width and height
    fabricCanvasRef.current.setWidth(mainImg.width);
    fabricCanvasRef.current.setHeight(mainImg.height);

    // Set canvas dimensions
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = mainImg.width;
      canvas.height = mainImg.height;
    }

    // Create off-screen canvases
    mainCanvas.current = document.createElement('canvas');
    mainCanvas.current.width = mainImg.width;
    mainCanvas.current.height = mainImg.height;
    const mainCtx = mainCanvas.current.getContext('2d');
    let mainImageData:ImageData|null = null;
    if (mainCtx) {
      mainCtx.drawImage(mainImg, 0, 0);
      mainImageData = mainCtx.getImageData(0, 0, mainImg.width, mainImg.height);
      setMainData(mainImageData);
    }

    depthCanvas.current = document.createElement('canvas');
    depthCanvas.current.width = depthImg.width;
    depthCanvas.current.height = depthImg.height;
    const depthCtx = depthCanvas.current.getContext('2d');
    let depthImageData:ImageData|null = null;
    if (depthCtx) {
      depthCtx.drawImage(depthImg, 0, 0);
      depthImageData = depthCtx.getImageData(0, 0, depthImg.width, depthImg.height);
      setDepthData(depthImageData);
    }
    return {mainImageData, depthImageData};
  };

  const showQuantized = () => {
    if (depthData) {
      const quantizedMap = quantizeDepthMap(depthData);
      setQuantizedDepthMap(quantizedMap);
      fabricCanvasRef.current.lowerCanvasEl.getContext('2d').putImageData(quantizedMap, 0, 0);
    }
  };

  const handlePaletteClick = (selectedColor: [number, number, number]) => {
    if (!mainData || !quantizedDepthMap || !fabricCanvasRef.current)
      return;

    const key = selectedColor.join('-');

    fabricCanvasRef.current.clear();

    if(quantizedParts[key]) {
      const img = quantizedParts[key];
      fabricCanvasRef.current.add(new fabric.Image(img));
    }
  };

  // Inline styles
  const styles = {
    dropZone: {
      border: '2px dashed #0087F7',
      padding: '50px',
      width: '100%',
      color: '#0087F7',
      textAlign: 'center' as const,
      margin: '20px',
      cursor: 'pointer',
    },
    content: {
      overflow: 'scroll',
    },
    canvas: {
      border: '1px solid black',
    },
    info: {
      fontSize: '16px',
      marginTop: '10px',
    },
    inputRange: {
      marginTop: '10px',
      width: '100%',
    },
    canvasContainer: {
      width: '100%',
      overflow: 'scroll',
    },
    button: {
      marginTop: '10px',
      display: 'block',
      width: '100%',
      padding: '10px',
      color: '#000',
      border: 'none',
      cursor: 'pointer',
    },
  };

  const resizeCanvas = () => {
    // Get the container's width
    var containerWidth = document.getElementById('canvas-container').clientWidth;

    // Calculate the zoom factor
    var zoom = containerWidth / 3000; // Internal width is 3000
    const canvas = fabricCanvasRef.current;

    // Set the canvas dimensions to match the container
    canvas.setWidth(containerWidth);
    canvas.setHeight(1000 * zoom); // Adjust height proportionally

    // Apply the zoom factor to scale the canvas content
    canvas.setZoom(zoom);

    // Optional: Re-render the canvas
    canvas.renderAll();
  };

  return (
    <div className='row'>
      <div className='col-12 col-md-2'>
        <div
          style={styles.dropZone}
          onDragOver={handleDragOver}
          onDrop={handleMainImageDrop}
        >
          Drop main image here
        </div>
        <div
          style={styles.dropZone}
          onDragOver={handleDragOver}
          onDrop={handleDepthMapDrop}
        >
          Drop depth map here
        </div>
        <button style={styles.button} id='quantMap' onClick={showQuantized}>
          Show quantized depth map
        </button>
        {PALETTE.map((color, index) => (
          <button key={index} style={{ ...styles.button, backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`, }}
            onClick={() => handlePaletteClick(color)} >
            Color {index + 1}
          </button>
        ))}
      </div>

      <div className='col-12 col-md-10' id='content'>
        <div style={styles.canvasContainer} id='canvas-container'>
          <canvas id='fabric-canvas' ref={canvasRef} style={styles.canvas}></canvas>
        </div>
        <div id='info' style={styles.info}></div>
      </div>
    </div>
  );
};

export default DepthMapComponent;

