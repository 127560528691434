import React from 'react'
import './chroma.scss'
import { connect } from 'react-redux';
import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import { ScrollBox } from 'components/shared/scroll-box';
import { FullScreenRemover } from './remover';
import { CREATOR_INVERTED_CHANGED, CREATOR_REMOVED_BG, CREATOR_REMOVE_FRAME, CREATOR_OBJECT_MOVED } from 'constants/actionTypes';
import LinearProgressWithLabel from 'components/shared/linear-progress-with-label';
import { PlayerComponent } from './player';

export type ChromaFrame = {
  id:string,
  url:string,
  mask_url: string,
  isLoading: boolean,
  top:number,
  left:number,
}

type ChromaProps ={
  match:any,
  removing: boolean,
  frames: Array<ChromaFrame>,
  onUserRemask: Function,
  onFrameRemoved: Function,
  onObjectMoved: Function,
  onFinish: Function,
  inverted: string,
  onInvertedChanged: Function,
}

class ChromaComponent extends React.PureComponent<ChromaProps & {history:any}, {current:string, removing:boolean, playing:boolean, direction:string}> {

  image: HTMLImageElement;
  animationInterval: any;
  scrollBox: ScrollBox;

  constructor(props){
    super(props)
  }

  static defaultProps = {
    frames: [],
  }

  state = {
    current: null,
    removing: false,
    playing: false,
    direction: 'left'
  }

  selectFrame(id:string){
    this.setState({...this.state, current: id});
  }

  get currentFrame(){
    return this.props.frames.find(fr => fr.id == this.state.current);
  }

  componentDidMount(){

    if(this.props.match?.params?.id){
      setTimeout(()=>
        this.setState({...this.state, current: this.props.frames[0].id, removing: true})
      ,0);
    }
  }

  componentDidUpdate(prevProps) {
    const { playing, current} = this.state
    if (playing !== prevProps.playing) {
      playing ? this.startPlaying() : this.stopPlaying()
    }
    if (!playing && current !== prevProps.current) {
      this.setState({ ...this.state, current: current })
    }
  }

  startPlaying() {
    const fps = 15//this.props
    this.stopPlaying()
    this.animationInterval = setInterval(() => {
      const { current,direction} = this.state;
      const newFrame = (this.props.frames.findIndex(fr => fr.id == current) + 1) % this.props.frames.length;
      this.setState({
        ...this.state, 
        current: this.props.frames[newFrame].id,
      })
    }, 1000 / fps)
  }

  stopPlaying() {
    clearInterval(this.animationInterval)
  }

  back(){
    this.props.history.push('/creator/trimmer')
  }

  get defaultDirection(){
    return this.props.inverted == 'left' ? 'right' : 'left';
  }

  get showProgress():boolean{
    const unmasked = this.props.frames.filter((frame) => !frame.mask_url).length;
    return unmasked != 0
  }
  get progress():number{
    return 100*(this.props.frames.filter((frame) => frame.mask_url).length / this.props.frames.length)
  }

  get bufferProgress(){
    return 100*(this.props.frames.filter((frame) => (frame.isLoading || frame.mask_url)).length / this.props.frames.length)
  }

  get scaleX(){
    if(this.state.direction == this.props.inverted)
      return 'scaleX(-1)'
    else
      return 'scaleX(1)'
  }

  saveMask([data,mask]){
    this.setState({...this.state,removing:false});
    this.props.onUserRemask({id: this.state.current, mask_url: mask, url: data});
  }

  handleScroll(left:number){
    const idx = Math.floor(left / $(this.scrollBox.scrollingBox).find('img:first').width()) % this.props.frames.length;
    const fr = this.props.frames[idx]
    this.setState({...this.state, current: fr.id});
  }

  nextStep(){
    if(this.props.onFinish)
      this.props.onFinish({});

    this.props.history.push('/creator/walk')
  }

  render(){
    return (<div className="chroma row mt-4">

      <div className="col-12 col-md-3">
        <div className="btn btn-primary-outline"
        onMouseEnter={()=>this.setState({...this.state,playing:true, direction: 'left'})}
        onMouseLeave={()=>this.setState({...this.state,playing:false, direction: this.defaultDirection})}>
          <i className="fa fa-play fa-flip"></i>&nbsp;&nbsp;
          <span className="sr-only">Izquierda</span>
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={this.props.inverted == 'left'}
              onChange={(e) => this.props.onInvertedChanged(this.props.inverted == 'left' ? 'right' : 'left')}
              name="checkedB"
              color="primary"
            />
          }
          label="Cambiar"
        />
        <div className="btn btn-primary-outline"
          onMouseEnter={()=>this.setState({...this.state,playing:true, direction: 'right'})}
          onMouseLeave={()=>this.setState({...this.state,playing:false, direction: this.defaultDirection})}>
          <i className="fa fa-play"></i>&nbsp;&nbsp;
          <span className="sr-only">Derecha</span>
        </div>

        {(this.currentFrame &&
        <FullScreenRemover
          className="fullScreenRemover"
          open={this.state.removing}
          frame={this.currentFrame}
          handleClose={()=>this.setState({...this.state,removing:false})}
          handleSave={this.saveMask.bind(this)}>
        </FullScreenRemover>
        )}

      </div>

      <div className="col-12 col-md-3 order-0 order-md-2">
        <div className="btn btn-block btn-primary-outline" onClick={()=>this.setState({...this.state,removing:true})}>
          <i className="fa fa-edit"></i>&nbsp;&nbsp;
          Retocar
        </div>

      </div>

      <div className="alpha-box col-12 col-md-6 order-0 order-md-1">
        {(this.currentFrame &&
        <img src={this.currentFrame.mask_url || this.currentFrame.url}
          className="current-frame d-none"
          style={{transform: this.scaleX}}
          ref={(i) => this.image=i}>
        </img> )}

    {(this.currentFrame &&
    <PlayerComponent frames={this.props.frames} playing={this.state.playing} reverse={this.state.direction == this.props.inverted} id={this.state.current} onObjectMoved={this.props.onObjectMoved}></PlayerComponent>
    )}

      </div>

      {(this.showProgress &&
      <LinearProgressWithLabel value={this.progress}  valueBuffer={this.bufferProgress} variant="buffer" className="col-12 mt-2 order-0 order-md-3"/>)}

      <ScrollBox className="col-12 mt-2 order-0 order-md-4" ref={(i) => this.scrollBox=i} onScroll={this.handleScroll.bind(this)}>
        {this.props.frames.concat(this.props.frames.slice(0,10)).map((frame, idx) => {

          return <div className={`frame ${frame.id == this.state.current ? 'selected' : ''} ${idx/this.props.frames.length>=1 ? 'overflown' : ''}`} key={(idx < this.props.frames.length ? '' : 'repeat-')+frame.id}>
            {(idx/this.props.frames.length < 1 && <div className="removeFrame" onClick={(e) => this.props.onFrameRemoved({id: frame.id})}>
              <i className={`fa fa-trash`}></i>
          </div>)}
            
            {( frame.isLoading
              ? <CircularProgress size={"8rem"} thickness={3}></CircularProgress>
              : <img src={frame.mask_url || frame.url} onClick={(e) => {this.selectFrame(frame.id); this.scrollBox.center_elem((e.target as any).parentNode)} } ></img>
            )}
          </div>
        })}
      </ScrollBox>

      <div className="col-12 order-4">
        <div className="btn btn-link float-left" onClick={this.back.bind(this)}>
          <i className="fa fa-arrow-left"></i>
          Cambiar video
        </div>
        <div className="btn btn-link float-right" onClick={this.nextStep.bind(this)}>
          Continuar
          <i className="fa fa-arrow-right"></i>
        </div>
      </div>

    </div>)
  }
}

const mapStateToProps = ({creator}) => ({
  frames: creator.frames,
  inverted: creator.inverted
})

const mapDispatchToProps = (dispatch) => ({
  onInvertedChanged: (inverted) =>
    dispatch({ type: CREATOR_INVERTED_CHANGED, inverted }),

  onUserRemask: ({id,mask_url, url}) =>
    dispatch({ type: CREATOR_REMOVED_BG, id,mask_url, url }),

  onFrameRemoved: ({id}) =>
    dispatch({ type: CREATOR_REMOVE_FRAME, id }),

  onObjectMoved: (id, {top,left}) =>
    dispatch({ type: CREATOR_OBJECT_MOVED, id, top, left })
})

const Chroma = connect(mapStateToProps, mapDispatchToProps)(ChromaComponent)

export {Chroma}
