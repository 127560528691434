import BaseView from "./base_view";

class ModalView extends BaseView<any> {

  className = ' modal';

  classNames () {
   return super.classNames() + ' modal';
  }

  view: any;
  footer: any;
  title: any;

  constructor(options) {
    super(options);
    ({view: this.view, footer: this.footer, title: this.title} = options);
    this.view.on('finished', () => {
      return (this.$el as any).modal('hide');
    });
    this.dispatchCreated('ModalView');
  }

  events() {
    return {
      "hide.bs.modal": function(e) {
        console.log('Removing modal view');
        return this.view.remove();
      },
      "click .enviar": function(e) {
        return console.log(e);
      },
      "click .close": function(ev) {
        ev.preventDefault();
        return (this.$el as any).modal('hide');
      }
    };
  };


  initialize(settings) {
    return super.initialize(settings);
  }

  render() {
    this.$el.html(this.template());
    this.$(".modal-body").html(this.view.render().$el);
    this.dispatcher?.trigger('rendered:ModalView', this);
    return this;
  }

  template() {
    this.document = [];
    this.render$(() => {
      this.div$(".modal-dialog.modal-lg", () => {
        this.div$(".modal-content", () => {
          this.div$(".modal-header", () => {
            this.h4$(".modal-title", this.title);
            this.button$('.close', { type: "button" }, () => {
              this.raw('&times;');
            });
          });
          this.div$(".modal-body");
          if (this.footer) {
            this.div$(".modal-footer");
          }
        });
      });
    });
    return this.document.join('');
  }

};


export {ModalView};
