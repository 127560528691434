import * as _ from "underscore";

const pagination_t = _.template(`<nav aria-label="...">
  <ul class="pagination">
    <li class="disabled"><a href="#" aria-label="Previous"><span aria-hidden="true">&laquo;</span></a></li>
    <li class="active"><a href="#">1 <span class="sr-only">(current)</span></a></li>
    <li><a href="#">3</a></li>
  </ul>
</nav>`);

// require attrs:{text, val}
const progress_t = _.template(`<div class="progress">
  <div class="progress-bar progress-bar-striped active" role="progressbar" 
       aria-valuenow="<%= attrs.val %>" aria-valuemin="0" aria-valuemax="100" style="width: <%= attrs.val %>%">
    <span class="sr-only"><%= attrs.text %></span>
  </div>
</div>`);

const progress_modal = function(opts:any = {}, promise) {
  opts = Object.assign({ title: 'Saving...' }, opts);
  let progress = create_progress({}).$progress;

  const $modal = create_modal_sm()
    .body((body) => body.html(progress))
    .header((hdr) => hdr.html(opts.title))
    .$modal.appendTo($('body')).modal('show');

  progress = $modal.find('.progress-bar');

  promise.progress((e) => progress.css({ width: '' + e.loaded / e.total * 100 + '%' }));

  promise.done(function(resp) {
    $modal.find('.modal-body').html($('<h1/>').text('Complete!')[0]);
    return $modal.modal('hide').remove();
  }).fail(function() {
    return $modal.find('.modal-body').html($('<h1/>').text('Error')[0]);
  });
  return $modal;
};

const carousel_t = _.template(`<div id="carousel-example-generic" class="carousel slide" data-bs-ride="carousel">

  <ol class="carousel-indicators">
    <li data-bs-target="#carousel-example-generic" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#carousel-example-generic" data-bs-slide-to="1"></li>
    <li data-bs-target="#carousel-example-generic" data-bs-slide-to="2"></li>
  </ol>

  <div class="carousel-inner" role="listbox">
    <div class="item active">
      <img src="..." alt="...">
      <div class="carousel-caption">
      </div>
    </div>
    <div class="item">
      <img src="..." alt="...">
      <div class="carousel-caption">
      </div>
    </div>
  </div>

  <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>`);

// API:
// create_modal().
//   header(function($header){ $header.append($('<button/>').attr({class:'xyz'}))}).
//   body(function($body){ $body.html('hola ke ases')}.
//   footer(function($footer){ $footer.append($('<button/>').attr({class: 'btn-danger'})).
//   modal()
const create_modal = function(props) {

  const attrs = Object.assign({ id: '', title: '' }, props);
  const $modal = modal_template(attrs);

  return new class Modal {
    dialog= (cb) => {cb($modal.find('.modal-dialog')); return this;}
    header= (cb) => {cb($modal.find('.modal-header')); return this;}
    body= (cb) => {cb($modal.find('.modal-body')); return this;}
    footer= (cb) => {cb($modal.find('.modal-footer')); return this;}
    modal= (cb) => {cb($modal); return this;}
    $modal= $modal
  };
};

// returns $(modal)
const create_modal_fullscreen = (props?) => create_modal(props).modal(($d) => $d.addClass('modal-fullscreen'));

// returns $(modal)
const create_modal_lg = (props?) => create_modal(props).dialog(($d) => $d.addClass('modal-lg'));

// returns $(modal)
const create_modal_sm = (props?) => create_modal(props).dialog(($d) => $d.addClass('modal-sm'));

// progress = Frakie.helpers.create_progress().$progress
// modal = Frakie.helpers.create_modal_sm().body((body) -> body.html progress ).$modal.appendTo($('body'))
// modal.modal 'show'
// $.get('lalala').done((response) ->
//   modal.find('.modal-body')).html $('<h1/>').text('Get complete!')
//   setTimeout (-> modal.remove() ), 2000
// ).fail((response) ->
//   modal.find('.modal-body')).html $('<h1/>').text('Get error :(')
// ).progress (e) -> progress.css width: '' + e.loaded / e.total * 100 + '%'
const create_progress = function(props) {
  var $progress, atts;
  atts = {
    attrs: _.extend({
      text: '',
      val: 0
    }, props)
  };
  $progress = $(progress_t(atts));
  return {
    bar: function(cb) {
      cb($progress.find('.progress-bar'));
      return this;
    },
    $progress: $progress
  };
};

// returns $(progress)
const create_progress_stripped = function(props) {
  return create_progress(props).bar(function($b) {
    $b.addClass('progress-bar-stripped');
  });
};

// Requires: attrs: {id, title}
const modal_template_t = _.template(`<div id="<%= attrs.id %>" class="modal">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title"> <%= attrs.title %> </h4>
      </div>

      <div class="modal-body">
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Close </button>
        <%= attrs.extra_buttons %> 
      </div>

    </div>
  </div>
</div>`);

const accordion_template_t = _.template(`<div id="<%= accordion_id %>" class="panel-group">
  <% for(var k in panels){ %>
    <%= Frakie.helpers.accordion_panel_template_t({accordion_id: accordion_id, title: panels[k].title, body: panels[k].body, tab_id: 'accordion_id_tab_'+k}) %>
  <% } %>
</div>`);

const accordion_panel_template_t = _.template(`<div class="panel panel-default">
  <div class="panel-heading">
    <h4 class="panel-title">
      <a data-bs-toggle="collapse" data-bs-parent="<%= accordion_id %>" href="#<%= tab_id %>">
        <%= title %>
      </a>
    </h4>
  </div>

  <div class="panel-collapse collapse" id="<%= tab_id %>">
    <div class="panel-body">
        <%= body %>
    </div>
  </div>
</div>`);

const panel_template_t = _.template(`<div class="panel panel-default">
  <div class="panel-heading">
    <h4 class="panel-title">
      <a data-bs-toggle="collapse" href="#<%= panel_id %>">
        <%= title %>
      </a>
    </h4>
  </div>

  <div class="panel-collapse collapse" id="<%= panel_id %>">
    <div class="panel-body">
      <%= body %>
    </div>
  </div>
</div>`);

const modal_template = function(props) {
  var atts;
  atts = _.extend({
    attrs: props
  });
  return $(modal_template_t(atts));
};


export {
  modal_template,
  modal_template_t,
  accordion_template_t,
  accordion_panel_template_t,
  panel_template_t,
  pagination_t,
  progress_t,
  progress_modal,
  carousel_t,
  create_modal_lg,
  create_modal_sm,
  create_modal,
  create_modal_fullscreen,
  create_progress,
  create_progress_stripped
};
