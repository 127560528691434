import BaseHandler from "./BaseHandler";
import CanvasController from "./CanvasController";
import { createSvgBackground } from "./WorkareaHandler";

class TemplateHandler extends BaseHandler {

  constructor(handler: CanvasController) {
    super(handler);
  }

  public async load(composition:any, workareaOption=null, setInputVariables=null) {
    let {objects} = composition;
    workareaOption = {
      ...composition.frame,
      left: 0,
      top: 0,
      workareaWidth: composition.frame.width,
      workareaHeight: composition.frame.height,
      backgroundColor: composition.background?.type == 'color' ? composition.background.value : undefined
    };

    if (!objects)
      return

    this.controller.clear(false);

    if(!workareaOption.src && workareaOption.backgroundColor){
      workareaOption.src = `data:image/svg+xml;base64,${btoa(createSvgBackground(workareaOption))}`;
    }
    this.controller.setWorkareaOption(workareaOption)

    const data = objects.filter(obj => Boolean(obj.id));
    await this.controller.importJSON(data);
  }

}

export default TemplateHandler
