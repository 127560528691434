import React, {useEffect} from "react";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch";
import {searchAssets} from "utils/searchkit_client";

const SearchkitTest = ({onHitClick}) => {
  const href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"

  useEffect(() => {
        // Create link element
    const link = document.createElement('link');
    link.href = href;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen,print';

    // Append link element to head
    document.head.appendChild(link);

    // Remove link element on cleanup
    return () => {
      document.head.removeChild(link);
    };
  }, [href]);

  const HitView = ({ hit }) => {

    const svgStyle:React.CSSProperties = {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '10px',
      border: '1px solid black',
      borderRadius: '5px',
    };

    const url =`/glitch/assets/${hit.name}.svg`;

    return <div>
      <img src={url} style={svgStyle} alt={hit.name} onClick={(e)=>onHitClick(hit)}></img>
      <p>{hit.group_tokens} / {hit.name_tokens}</p>
    </div>
    };


  return <InstantSearch indexName="glitch_assets_development" searchClient={searchAssets}>
    <SearchBox />
    <Hits hitComponent={HitView} id="hits"/>
  </InstantSearch>
};

 
export default SearchkitTest;
