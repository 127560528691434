import { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from "react";
import axios from 'axios';
import { TextProps, Text } from "./text";
import { Link } from 'react-router-dom';
import { PaginationAttrs } from "shared/components/pagination";

class TextIndex extends Component<{history:any, match:any,xs:number,md:number, onClick?:Function}, {pagination?: PaginationAttrs, texts: Array<TextProps>, query:string,loading:boolean,message:string}> {

  static defaultProps = {xs: 12, md: 3, onClick: null}
  cancel:any;

  constructor(props) {
    super(props);

    this.state = {texts: [], query: '', loading: true, message: '', pagination: null};
  }

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if ( ! query ) {
      this.setState({ query, texts: [], loading: false, message: '' } );
    } else {
      this.setState({ query, texts: [], loading: true, message: '' }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  fetchSearchResults = (updatedPageNo:number = null, query ) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
    const searchUrl = `/texts/search.json?models=Text&query=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.get(searchUrl, { cancelToken: this.cancel.token, })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? 'There are no more search results. Please try a new search.'
          : '';
        this.setState({
          texts: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: 'Failed to fetch results.Please check network',
          });
        }
      });
  };

  componentDidMount() {
    let url;
    if(this.props.match.params.p)
      url = `/texts.json?page=${this.props.match.params.p}`
    else
      url = `/texts.json`

    axios.request<Array<TextProps>>({url}).then(({data, headers}) =>{
      let pagination;
      if(headers['x-pagination']){
        pagination = JSON.parse(headers['x-pagination'])
      }

      this.setState({ texts: data, pagination })
    });
  }

  render () {
    return (
      <>
      <Row>
        <div className="col-2">
          <Link to="/texts/new" className="btn btn-primary">New Text</Link>
        </div>
      </Row>

      <Row>
        <div className="col-12 col-md-6 offset-md-3 my-4">
          <input
            className="form-control form-control-lg"
            type="text"
            value={this.state.query}
            id="search-input"
            placeholder="Search..."
            onChange={this.handleOnInputChange}
          />
        </div>
      </Row>

      <Row>

      {this.state.texts.map(text => (
        <Col xs={this.props.xs} md={this.props.md} key={text.id}>
          <Text text={text} onClick={(e)=> this.props.onClick(text)}></Text>
        </Col>
      ))}
      </Row>
      </>
    )
  }

}

export {TextIndex}



