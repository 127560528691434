import { Picker } from "views/picker";
import { ModalView } from "views/modal_view";
import { create_modal_lg } from "bs_helpers";
import { AvatarSearch } from "components/avatars/search"
import ReactDOM from 'react-dom'
import React from 'react'
import { NpcSearch } from "components/npcs/search";
import { Avatar } from "models/avatar";
import { Npc } from "models/npc";
import { Sound } from "models/sound";
import { SoundIndex } from "components/sounds";
import { ArtworkIndex } from "components/artwork";
import { Npcs } from "api/agent";
import NpcPicker from "components/npcs/picker";
import { request2 } from "maps/controller";

const pick_model = (model, opts, container='.map') => {

  const p = new Picker({ models: [model], });

  const modal = new ModalView({ view: p, title: `Pick ${model}` });

  (modal.render().$el.appendTo(container) as any).modal('show');

  return p.promise.then((atts) => request2(atts.type, atts.id));
}

const pick_avatar = ({dispatcher}, container='body') => new Promise<Avatar>((accept,reject) => {

  const handleClick = (props) => dispatcher.request('Avatar', props.id).then((ava) => {
    $modal.modal('hide');
    accept(ava)
  })

  const $modal = create_modal_lg().body(($c) => {
    ReactDOM.render(
      <div>
        <AvatarSearch md={6} onClick={(e) => handleClick(e)} />
      </div>,
      $c[0]
    )
  }).$modal.appendTo(container).modal('show').on('hide.bs.modal', (e) => {
    ReactDOM.unmountComponentAtNode($modal[0])
  }).on('hidden.bs.modal', (e) => $modal.modal('dispose'));

})

const pick_npc = ({}, container='body') => new Promise<Npc>((accept,reject) => {
    
  const $modal = create_modal_lg().body(($c) => {
    ReactDOM.render(
      <div>
        <NpcPicker accept={(npc) => {$modal.modal('hide'); accept(npc)}} reject={(reason) => {$modal.modal('hide'); reject(reason)}}/>
      </div>,
      $c[0]
    )
  }).$modal.appendTo(container).modal('show').on('hide.bs.modal', (e) => {
    ReactDOM.unmountComponentAtNode($modal[0])
  }).on('hidden.bs.modal', (e) => $modal.modal('dispose'));

})

const pick_sound = ({dispatcher},container='body') => new Promise<Sound>((accept,reject) => {

  const handleClick = (props) => dispatcher.request('Sound', props.id).then((npc) => {
    $modal.modal('hide');
    accept(npc)
  })
    
  const $modal = create_modal_lg().body(($c) => {
    ReactDOM.render(
      <div>
        <SoundIndex md={6} onClick={(e) => handleClick(e)} />
      </div>,
      $c[0]
    )
  }).$modal.appendTo(container).modal('show').on('hide.bs.modal', (e) => {
    ReactDOM.unmountComponentAtNode($modal[0])
  }).on('hidden.bs.modal', (e) => $modal.modal('dispose'));

})


export { pick_model, pick_avatar, pick_npc, pick_sound};
