;(function(Form) {
  /**
   * Json editor
   */
  Form.editors.Json = Form.editors.Text.extend({

    tagName: 'textarea',

    /**
     * Override Text constructor so type property isn't set (issue #261)
     */
    initialize: function(options) {
      Form.editors.Base.prototype.initialize.call(this, options);

      this.value = _.pp(this.model.get(options.key));
    },

    getValue: function() {
      return JSON.parse(this.value);
    }


  });


})(Backbone.Form);
