import React from 'react';
import { store , history } from 'redux/store';
import { pick_npc, pick_avatar } from "components/shared/pick_model";
import { MAP_SET_MODE, MAP_SHOW_ANCHORS } from "constants/actionTypes";
import { MODES } from "./constants";
import { NpcPlacement, NpcPlacementAttributes } from "models/npc_placement";
import { NpcView } from "maps/views/npc_view";
import { Events } from "maps/map_events";
import { AvatarView } from "maps/views/avatar_view";
import Swal from "sweetalert2";
import { AnchorView } from "maps/views/anchor_view";
import { Anchor, AnchorAttributes } from "models/anchor";
import { useState } from "react";
import { Avatar, AvatarAttributes } from "models/avatar";
import { List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, Typography } from '@material-ui/core';
import NameDialog from './scenes/name_dialog'
import { MapController } from 'maps/controller';

import {scriptable_verbs} from 'actions/verbs';
import * as all_nouns from 'actions/nouns';
import { NounType } from 'hip/noun';

const nouns:NounType[] = Object.values(all_nouns);

const openNpcPicker = (controller) => {

  pick_npc({ dispatcher: controller }, '#holder').then((npc) => {

    store.dispatch({ type: MAP_SET_MODE, mode: MODES.PlaceOne });

    const npc_placement = new NpcPlacement({
      space_id: controller.space.id,
      npc_id: npc.get('id'),
      x: 0,
      y: 0
    }, { dispatcher: controller });

    const npcviw = new NpcView({
      model: npc,
      placement: npc_placement,
      dispatcher: controller
    }).render();

    controller.trigger(Events.ADD_NPC_VIEW, npcviw);

    npcviw.set_placeable();
  });
}

const addCharacter = (controller, name=null, where=null):Promise<AvatarView<Avatar>> =>{

  return pick_avatar({ dispatcher: controller }, '#holder').then((avatar) => {

    const avatarviw = new AvatarView({ name, model: avatar, dispatcher: controller, isLocal: true})
    avatarviw.setHip(scriptable_verbs,nouns);

    controller.trigger(Events.PLACE_AVATAR, {
      who: avatarviw,
      x: 0,
      y: 0,
      target: where || window.senyor
    });

    return avatarviw.set_placeable();
  });

}

const newModel = (controller, model: string) => {
  controller.trigger(Events.NEW_MODEL, { class: model})
}

const addAnchor = async (controller) => {

  const { value: name } = await Swal.fire({ title: 'A name for this anchor?', input: 'text', target: '#holder' });

  store.dispatch({ type: MAP_SHOW_ANCHORS, show_anchors: true });

  const anchorviw = new AnchorView({
    model: new Anchor({x:0,y:0,space_id: controller.space.id, name},{dispatcher: controller}),
    dispatcher: controller
  })

  anchorviw.map = controller.map;
  anchorviw.render().$el.appendTo(controller.map.$sprites)

  anchorviw.set_placeable(model => {
    model.save()
  })
}

const goToAnchor = (controller, anchor: AnchorAttributes): void => {
  controller.trigger(Events.TRAVEL_TO_ANCHOR, {id: anchor.uuid});
}

const goToNpcPlacement = (controller, npc_p: NpcPlacementAttributes): void => {
  controller.trigger(Events.TELETRANSPORT_CURRENT, {x:npc_p.x, y: npc_p.y});
}

const goToPerson = (controller, person: AvatarAttributes): void => {
  const avatar_view:AvatarView<Avatar> = controller.map.avatar_views.find(x => x.user_uuid == person.user_uuid);

  if(avatar_view)
    controller.trigger(Events.TELETRANSPORT_CURRENT, avatar_view.coords);
}

const onSaveDialogName = (controller, name: string) => {
    if(!name)
      return

    controller.trigger(Events.START_RECORDING, {
      name: name,
      target: window.senyor
    })
  }

type DrawersProps = {
  people: {[uuid:string]:AvatarAttributes},
  hasPeople: boolean,
  canEdit: boolean,
  controller:MapController,
  open_drawer:boolean,
  setOpenDrawer:(o:boolean)=>void,
  open_people:boolean,
  setOpenPeople:Function
}

const Drawers = (props:(DrawersProps & React.HTMLAttributes<HTMLDivElement>)) => {
  
  const [save_dialog_open, setDialogOpen] = useState(false);
  const [open_anchors,setOpenAnchors] = useState(false);

  const {open_drawer, setOpenDrawer} = props;
  const {open_people, setOpenPeople} = props;

  return <>
      {props.canEdit && (<>
          <Drawer
            anchor='left'
            open={open_drawer}
            onClose={(e) => setOpenDrawer(false)}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
              container: document.getElementById('holder'),
              style: { position: 'absolute' }
            }}
          >
            <ToolboxDrawer controller={props.controller} setOpenDrawer={setOpenDrawer} setDialogOpen={setDialogOpen} setOpenAnchors={setOpenAnchors}></ToolboxDrawer>

          </Drawer>
        </>)
        }

        {props.hasPeople && (<>
        <Drawer anchor='right'
          open={open_people}
          onClose={(e) => setOpenPeople(false)}
          PaperProps={{ style: { position: 'absolute' } }}
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            container: document.getElementById('holder'),
            style: { position: 'absolute' }
          }}>
            <List>
              {props.people && Object.values(props.people).map((person:AvatarAttributes) => 
                <ListItem button key={person.id} onClick={e=>goToPerson(props.controller, person)}>
                  <ListItemIcon><i className="fal fa-user-astronaut"/></ListItemIcon>
                  <ListItemText primary={person.name} />
                </ListItem>
              )}
            </List>
          </Drawer>
          </>)
        }
        
        {open_anchors && <AnchorsDrawer controller={props.controller} open_anchors={open_anchors} setOpenAnchors={setOpenAnchors} anchors={props.controller?.space?.anchors?.map((a) => a.attributes)}></AnchorsDrawer>}

        <NameDialog open={save_dialog_open} onSave={name => {setDialogOpen(false); onSaveDialogName(props.controller, name)}}/>
  </>
}

function AnchorsDrawer({open_anchors, setOpenAnchors, anchors, controller}){
  return (<>
  <Drawer anchor='left'
    open={open_anchors}
    onClose={(e) => setOpenAnchors(false)}
    PaperProps={{ style: { position: 'absolute' } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: document.getElementById('holder'),
        style: { position: 'absolute' }
    }}>
      <List>

        {anchors?.length ? anchors.map((anchor:AnchorAttributes) => 
        <ListItem button key={anchor.uuid} onClick={e=>{setOpenAnchors(false); goToAnchor(controller, anchor)}}>
          <ListItemIcon><i className="fal fa-anchor"/></ListItemIcon>
          <ListItemText primary={anchor.name} />
        </ListItem>
        ) :
        <ListItem button><ListItemText primary={"(no anchors)"}></ListItemText></ListItem>
      }
    </List>
  </Drawer>
</>)
}

function ToolboxDrawer(props:{controller:MapController, setOpenDrawer:(a:boolean)=>void,setDialogOpen:(a:boolean)=>void,setOpenAnchors:(a:boolean)=>void}){
  return  (<>
  <List>
    <ListItem button key="add-npc" onClick={e=>{props.setOpenDrawer(false); openNpcPicker(props.controller)}}>
      <ListItemIcon><i className="fal fa-map-marker-edit"/></ListItemIcon>
      <ListItemText primary="Add Object (NPC)" />
    </ListItem>

    <ListItem button key="add-character" onClick={e=>{props.setOpenDrawer(false);addCharacter(props.controller)}}>
      <ListItemIcon><i className="fal fa-user-plus" /></ListItemIcon>
      <ListItemText primary="Add Character" />
    </ListItem>

    <ListItem button key="record-scene" onClick={e=>{props.setOpenDrawer(false);props.setDialogOpen(true); }}>
      <ListItemIcon><i className="fal fa-video" /></ListItemIcon>
      <ListItemText primary="Record Scene" />
    </ListItem>

    <ListItem button key="open-editor" onClick={e=>{history.push(`/editor/${props.controller.space.uuid}`) }}>
      <ListItemIcon><i className="fal fa-map" /></ListItemIcon>
      <ListItemText primary="Open Zones Editor" />
    </ListItem>

  </List>

  <Divider />

  <List>
    <ListItem button key="add-anchor" onClick={e=>{props.setOpenDrawer(false);addAnchor(props.controller)}}>
      <ListItemIcon><i className="fal fa-anchor"></i></ListItemIcon>
      <ListItemText primary="Add anchor" />
    </ListItem>

    <ListItem button key="list-anchor" onClick={e=>{props.setOpenDrawer(false);props.setOpenAnchors(true);}}>
      <ListItemIcon><i className="fal fa-anchor"></i></ListItemIcon>
      <ListItemText primary="List anchors" />
    </ListItem>
  </List>

  <Divider />

  <List>
    <ListItem button key="new-image" onClick={e=>{props.setOpenDrawer(false);newModel(props.controller, 'Picture')}}>
      <ListItemIcon><i className="fal fa-images"/></ListItemIcon>
      <ListItemText primary="New Image" />
    </ListItem>

    <ListItem button key="new-sound" onClick={e=>{props.setOpenDrawer(false);newModel(props.controller, 'Sound')}}>
      <ListItemIcon><i className="fal fa-music"/></ListItemIcon>
      <ListItemText primary="New Sound" />
    </ListItem>

    <ListItem button key="new-video" onClick={e=>{props.setOpenDrawer(false);newModel(props.controller, 'Video')}}>
      <ListItemIcon><i className="fal fa-video-plus"/></ListItemIcon>
      <ListItemText primary="New video" />
    </ListItem>

    <ListItem button key="new-text" onClick={e=>{props.setOpenDrawer(false);newModel(props.controller, 'Text')}}>
      <ListItemIcon><i className="fal fa-text"/></ListItemIcon>
      <ListItemText primary="New Text" />
    </ListItem>

  </List>
</>)

}

export {Drawers, ToolboxDrawer, AnchorsDrawer, goToAnchor, goToNpcPlacement, addAnchor, openNpcPicker, addCharacter};
