import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ScrollBox } from 'components/shared/scroll-box';
import { SpriteCard } from 'components/sprites/sprite_card';

export default function Avatar({name, game, avatars, current, avatarClick, setName, selectedSprite, handleNext, setScrollBox}) {
   return <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      className="game_welcome"
      open={true}
      onClose={()=>handleNext(2)}
      aria-labelledby="alert-dialog-title-2"
      aria-describedby="alert-dialog-description-2"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
        <h1 className="text-left mt-2">Welcome to {game.name}, {name}</h1>
      </DialogTitle>
      <DialogContent>
        <p>Please, choose a character to play with</p>
        <ScrollBox className="col-12 mt-2 order-0 order-md-4" ref={setScrollBox}>
          {Boolean(avatars) && avatars.map((avatar, i) => {
            const sprite = avatar.sprites.filter(sp => sp.map?.sequences)[0];
            return (sprite && <SpriteCard
              sprite={sprite}
              anim={'right'}
              hideBtns={true}
              html={{className:`${avatar.id == current ? 'selected' : ''} img`,onClick:(e)=>avatarClick(avatar.id)}}
              key={sprite.id}></SpriteCard>)
          })
          }
        </ScrollBox>
        <div className="row">
          <div className="col-12 col-md-4">
            <p>Add a name for your character</p>
            <input className="form-control" placeholder={name} onChange={(e)=>setName(e.target.value)} value={name}></input>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{if(selectedSprite)handleNext(2)}} color="primary">Choose</Button>
      </DialogActions>
  </Dialog>
  }
