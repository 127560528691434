import _ from 'underscore';
import { dotry } from 'utils/dotry';
import BaseView from 'views/base_view';
import * as Backbone from 'backbone';
import { Npc } from 'models/npc';

/*
  Update form elements based on user input !!

  p.ej cargar cities cuando eliges una Provincia.
  Esto serviria para mostrar
  - selector de Picture si eliges picture on click
  - selector de Sound si eliges sound on click
  - etc
  http://jsfiddle.net/evilcelery/c5QHr/

  Parsing traditional Rails forms for direct use in Backbone:
  (trick recursive parse nested attributes in form inputs names)
  http://blog.rjzaworski.com/2012/01/parsing-forms-with-backbone-js/
 */

class NpcEditView extends BaseView<Npc> {

  placement: any;
  forms: any;
  events() {
    return {
      "click .enviar": "handle_submit",
      "click .close": function(ev) {
        ev.preventDefault();
        this.remove();
        return false;
      }
    }
  }

  className = "npc_edit_view";

  constructor(options?) {
    super(...Array.from(arguments));
    this.dispatchCreated('NpcEditView');
  }

  initialize(settings) {
    var AnEvent;
    console.log(this);
    this.placement = settings.placement;
    AnEvent = Backbone.Model.extend({
      schema: {
        type: {
          type: "Select",
          options: ["default_play", "hover", "click", "repeat"]
        },
        invocation: {
          type: "List",
          itemType: "Text"
        }
      },
      toString: function() {
        return "on " + this.attributes.type + " " + this.attributes.invocation[0] + "(" + this.attributes.invocation.slice(1).join(",") + ")";
      }
    });
    this.forms = new (Backbone as any).Form({ // TODO see how to add Form to type Backbone 
      schema: {
        events: {
          type: "List",
          itemType: "NestedModel",
          model: AnEvent
        }
      },
      data: {
        events: _(this.placement.get("events")).map(function(v, k) {
          return {
            type: k,
            invocation: v
          };
        })
      }
    });
  }

  handle_submit(ev) {
    if ("commit" in this.model) {
      this.forms.commit();
    } else {
      console.log("No commit callback in this.model", this.model);
      window[this.model.cid] = this.model;
    }
    this.trigger("commit", this.forms.getValue());
    dotry(this, "model", "model", [
      "save",
      null,
      {
        error: function(jqXHR, text) {
          console.log("AJAX error:", text);
        },
        success: _.bind(function(data, textStatus, jqXHR) {
          console.log("AJAX success:", textStatus);
          this.dispatcher.trigger("created:" + this.model.model._className(), this.model.model);
        },
          this)
      }
    ]);
  }

  render() {
    var css;
    css = {
      position: "absolute",
      left: "100px",
      top: "100px",
      backgroundColor: "white",
      borderRadius: "5px",
      zIndex: 200
    };
    this.$el.css(css).html(this.template()).find(".formPlaceholder").html(this.forms.render().el);
    (this.$el as any).draggable();
    return this;
  }

  template() {
    this.document = [];
    this.render$(() => {
      this.div$('.npc_editor', () => {
        this.div$(".modal-header", () => {
          this.button$(".close", { type: "button" }, () => {
            this.raw('&times;');
          });
          this.h4$(".modal-title", this.model.get("name"));
        });

        this.div$(".modal-body", () => {
          this.div$(".formPlaceholder");
          this.button$(".enviar", { type: "button" }, 'Enviar');
        });
      });
    });
    return this.document.join('');
  }

};


export {NpcEditView};
