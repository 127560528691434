import React from 'react';
import _ from 'underscore';
import owl from 'images/glitch/ilmenskie/owl_1.svg';
import { MapController } from 'maps/controller';
import { install_event_handlers } from 'maps/events';
import { Space } from 'models/space';
import { Avatar } from 'models/avatar';
import { User } from 'models/user';
import { PersonaView } from 'maps/views/persona_view';
import { Events } from 'maps/map_events';
import { dotry } from 'utils/dotry';
import { connect } from 'react-redux';

import './hud.scss' 
import 'stylesheets/map.scss' 
import 'stylesheets/modal-fullscreen.scss' 
import { Button} from '@material-ui/core';
import { pick_avatar } from 'components/shared/pick_model';
import { MAP_SET_MODE, MAP_SET_MUTE, MAP_SET_FULLSCREEN, MAP_SHOW_ANCHORS, MAP_SET_ZOOM, MAP_SET_CLICK_AND_MOVE, MAP_SHOW_ZONES } from 'constants/actionTypes';
import { MODES } from './constants';

type HudProps = {
  app:MapController,
  mute:boolean,
  clickAndMove:boolean,
  mode:MODES,
  fullscreen:boolean,
  show_anchors:boolean,
  show_zones:boolean,
  recording:boolean,
  recording_name:string | null,
  zoom:number | null,
  currentUser:any,

  onClickAndMove: Function,
  onMute: Function,
  onFullscreen: Function,
  onMode: Function,
  onAnchors: Function,
  onZones: Function,
  onZoom: Function,
}

class Hud extends React.Component<HudProps & React.HTMLAttributes<HTMLDivElement>,{}> {

  constructor(props){
    super(props)
  }

  public get showEditor() {
    return this.props.currentUser
    //let r= window.current_user?.roles
    //return r && r.indexOf('admin') > -1
  }

  async pick_sprite():Promise<void> {
    const avatar = await pick_avatar({dispatcher: this.props.app})
    this.props.app.trigger(Events.TRANSMUTE,{id: avatar.get('id'), target: window.senyor});
  }

  public render() {
    return (
      <div className="hud"  style={{ opacity: "0.9", position: "absolute", top: "0", left: "0", width: "100%", zIndex: 300}}>
        <div className="navbar navbar-light bg-light navbar-expand-lg">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#hud-navbar"  aria-expanded="false">
          <span className='navbar-toggler-icon'></span>
        </button>

        <a className="navbar-brand">
          {this.props.app.space.get('name')}
        </a>
  
        <div id="hud-navbar" className='collapse navbar-collapse in'>

          {(this.props.children)}

          <ul className="nav navbar-nav">

            {this.showEditor && this.props.recording && (<>
            <li className='nav-item'>
              <a className='nav-link' onClick={e=>this.stopRecording()}>
                <i className="recording fal fa-circle" style={{color:this.props.recording ? "red" : "black"}}></i>
                {this.props.recording && <strong className="label label-default"> {this.props.recording_name} </strong> }
              </a>
            </li>
           </>)}

        </ul>

  
        <ul className="nav navbar-nav" style={{marginRight: '4rem'}}>
          <li className='nav-item'>
            <a className="nav-link pick_sprite" onClick={e => this.pick_sprite()}>
              <i className="fal fa-user-ninja" ></i>
            </a>
          </li>

          {this.showEditor && <>

            <li className='nav-item'>
              <a className="nav-link mute" onClick={e => this.props.onMute(! this.props.mute)}>
                {this.props.mute ?
                <i className="fal fa-volume-off"  ></i>
                  :
                <i className="fal fa-volume-up" style={{color:"red"}}></i>
                }
              </a>
            </li>
 
              {/*
            <li className='nav-item'>
              <a className="nav-link anchors" onClick={e => this.props.onAnchors(! this.props.show_anchors)}>
        
                {(this.props.show_anchors ?
                <i className="fal fa-anchor" style={{color:"red" }}></i>
                  :
                  <i className="fal fa-anchor" ></i>
                )}
              </a>
            </li>
                */}

            <li className='nav-item'>
              <a className="nav-link anchors" onClick={e => this.props.onZones(! this.props.show_zones)}>
        
                {(this.props.show_zones ?
                <i className="fal fa-draw-polygon" style={{color:"red" }}></i>
                  :
                  <i className="fal fa-draw-polygon" ></i>
                )}
              </a>
            </li>
 
  
            <li className='nav-item'>
        
              <a className="nav-link mode" onClick={(e) => this.toggleMode()}>
                {(this.props.mode === MODES.Editing && <i className="fal fa-pen-square" style={{color:"red"}}></i>)}
                {(this.props.mode === MODES.Placing && <i className="fal fa-bullseye-pointer" style={{color:"red"}}></i>)}
            {([MODES.Editing,MODES.Placing].indexOf(this.props.mode) == -1 && <i className="fal fa-eye"> </i>)}
              </a>
            </li>
          </>
          }
        </ul>
  
      </div>
    </div>
    </div>
    )
  }

  stopRecording(): void {
    this.props.app.trigger(Events.STOP_RECORDING, { target: this.props.app.recording_manager.who });

    if(!confirm('save?')) return

    this.props.app.trigger(Events.SAVE_RECORDING, {
      name: this.props.recording_name,
      target: this.props.app.recording_manager.who
    });

  }

  toggleMode(): void {
    switch(this.props.mode){
      case MODES.Normal:
      this.props.onMode(MODES.Editing);
        break;
      case MODES.Editing:
      this.props.onMode(MODES.Normal);
        break;
      case MODES.Placing:
      this.props.onMode(MODES.Normal);
        break;
      default:
      this.props.onMode(MODES.Editing);
    }
  }

}

const mapStateToProps = ({map, common}) => ({
  clickAndMove: map.clickAndMove,
  mode: map.mode,
  mute: map.mute,
  fullscreen: map.fullscreen,
  show_anchors: map.show_anchors,
  show_zones: map.show_zones,
  recording: map.recording,
  recording_name: map.recording_name,
  zoom: map.zoom,
  currentUser: common.currentUser,
})

const mapDispatchToProps = (dispatch) => ({

  onMode: (mode) => dispatch({ type: MAP_SET_MODE, mode }),

  onClickAndMove: (clickAndMove) => dispatch({ type: MAP_SET_CLICK_AND_MOVE, clickAndMove }),

  onMute: (mute) => dispatch({ type: MAP_SET_MUTE, mute }),

  onFullscreen: (fullscreen) => dispatch({ type: MAP_SET_FULLSCREEN, fullscreen }),

  onAnchors: (show_anchors) => dispatch({ type: MAP_SHOW_ANCHORS, show_anchors }),

  onZones: (show_zones) => dispatch({ type: MAP_SHOW_ZONES, show_zones }),

  onZoom: (level) => dispatch({ type: MAP_SET_ZOOM, zoom: level }),

})

export default connect(mapStateToProps,mapDispatchToProps)(Hud)
