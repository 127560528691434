import _ from "underscore";
import BaseView from "views/base_view";
import {QuerySource} from '../hip/query_source';
import { Keys } from "constants/keyboard";

class HipView extends BaseView<any> {

  tagName = "div";

  className="hip";

  gQs: QuerySource;
  prefix: any;

  $status_line: JQuery<Element>;
  $search_box: JQuery<Element>;
  $autocomplete: JQuery<Element>;
  main_template: _.CompiledTemplate;
  suggestion_template: _.CompiledTemplate;
  events () {
    return {
      "keydown .search-box": (e) => this.autocomplete_on_tab(e),
      "keyup .search-box": (e) => this.searchBoxQuery(e),
      "focus .search-box": (e) => this.searchBoxQuery(e),
      "blur .search-box": (e) => this.clearBoxQuery(e)
    }
  };

  constructor(options) {
    super(options);
    this.dispatchCreated('HipView');
  }

  initialize(settings) {
    this.main_template = _.template(`
    <div class="status-line">
      Blah!
    </div>
    <input class="search-box" type="text" size="40" />
    <div class="autocomplete-popup">
    </div>`);

    this.suggestion_template = _.template(`
    <div>
      <% _(suggestions).each(function(suggestion,i){ %>
        <div class="<%= hilited == i ? "hilited" : "" %>">
          <%= suggestion %>
        </div>
      <% }) %>
    </div>`);


    this.gQs = new QuerySource(settings.verbs, settings.nouns, settings.context);
    this.render();
    this.prefix = settings.prefix || "";
    this.$status_line = this.$el.find(".status-line");
    this.$search_box = this.$el.find(".search-box");
    this.$autocomplete = this.$el.find(".autocomplete-popup");
    this.$status_line.html("All you gotta do is rub that lamp.");
  }

  render() {
    this.$el.html(this.main_template());
    return this;
  }

  getSelection() {
    return ""; //$("#fake-selection").attr( "value" );
  }

  updateDisplay() {
    const suggestions = this.gQs.suggestions_html();
    const hilitedSuggestion = this.gQs.hilited_idx;
    const description = this.gQs.getDescriptionText();

    this.$status_line.html(description);
    this.$search_box.css("background-color", hilitedSuggestion ? 'white' : 'lightgreen');
    return this.$autocomplete.html(this.suggestion_template({
      suggestions: suggestions,
      hilited: hilitedSuggestion
    })).show();
  }

  setCursorPosition(elem, pos){
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  autocomplete_on_tab(event) {
    if (event.which === Keys.TAB && event.target.value) {
      const input = event.target.value;
      if (input.indexOf(this.prefix) === 0) {
        this.$search_box.val(this.prefix + this.gQs.autocomplete(input.substring(this.prefix.length)));
        if(!this.gQs.hilited_suggestion?.getDirectObject){
          this.setCursorPosition(input, `/${this.gQs.hilited_suggestion?.verb?.name} "`.length)
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      }
    }
  }

  clearBoxQuery(event) {
    this.gQs.clear();
    return this.updateDisplay();
  }

  searchBoxQuery(event) {
    let input = event.target.value;
    if (input.indexOf(this.prefix) !== 0) {
      return true;
    }
    input = input.substring(this.prefix.length);

    switch (event.which) {
      case Keys.ESC:
        event.target.value = "";
        this.gQs.clear();
        break;
      case Keys.UP:
        this.gQs.indication_up();
        break;
      case Keys.DOWN:
        this.gQs.indication_down();
        break;
      case Keys.ENTER:
        this.gQs.execute();
        this.$search_box.val("");
        this.gQs.clear();
        break;
      case Keys.SPACE:
        this.gQs.updateSuggestionList(input);
        break;
      default:
        this.gQs.updateSuggestionList(input);
    }
    return this.updateDisplay();
  }

};

export {HipView};
