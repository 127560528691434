import * as _ from 'underscore';
import { EditorView } from './editor';
import { Text } from "models/text";

class EditTextView extends EditorView<Text> {
  className = 'EditText'

  copies= {
    success_title: 'Success!',
    success_subtitle: 'Your text has been saved',
  }

  constructor(options?) {
    super(options);
  }

  initialize(settings) {
    return super.initialize(settings);
  }

  events() {
    return _.extend(super.events(), {});
  }

  previewImage(url) {
    return console.log('preview', url);
  }

  attachmentInput() {
    this.div$(".wy-toolbar", {
      style: "display: none;"
    }, () => {
      this.a$({
        data: {
          wysihtml5: {
            command: "bold"
          }
        },
        title: "CTRL+B"
      }, "bold");
      this.a$({
        data: {
          wysihtml5: {
            command: "italic"
          }
        },
        title: "CTRL+I"
      }, "italic");
      this.a$({
        data: {
          wysihtml5: {
            command: "createLink"
          }
        }
      }, "insert link");
      this.a$({
        data: {
          wysihtml5: {
            command: "insertImage"
          }
        }
      }, "insert image");
      this.a$({
        data: {
          wysihtml5: {
            command: "formatBlock",
            "command-value": "h1"
          }
        }
      }, "h1");
      this.a$({
        data: {
          wysihtml5: {
            command: "formatBlock",
            "command-value": "h2"
          }
        }
      }, "h2");
      this.a$({
        data: {
          wysihtml5: {
            command: "insertUnorderedList"
          }
        }
      }, "insertUnorderedList");
      this.a$({
        data: {
          wysihtml5: {
            command: "insertOrderedList"
          }
        }
      }, "insertOrderedList");
      this.a$({
        data: {
          wysihtml5: {
            command: "foreColor",
            "command-value": "red"
          }
        }
      }, "red");
      this.a$({
        data: {
          wysihtml5: {
            command: "foreColor",
            "command-value": "green"
          }
        }
      }, "green");
      this.a$({
        data: {
          wysihtml5: {
            command: "foreColor",
            "command-value": "blue"
          }
        }
      }, "blue");
      this.a$({
        data: {
          wysihtml5: {
            command: "insertSpeech"
          }
        }
      }, "speech");
      this.a$({
        data: {
          wysihtml5: {
            action: "change_view"
          }
        }
      }, "switch to html view");
      this.div$({
        data: {
          wysihtml5: {
            dialog: "createLink"
          }
        },
        style: "display: none;"
      }, () => {
        this.label$(() => {
          this.raw("Link:");
          return this.input$({
            data: {
              "wysihtml5-dialog-field": "href"
            },
            value: "http://"
          });
        });
        this.a$({
          data: {
            "wysihtml5-dialog-action": "save"
          }
        }, "OK");
        return this.a$({
          data: {
            "wysihtml5-dialog-action": "cancel"
          }
        }, "Cancel");
      });
      return this.div$({
        data: {
          "wysihtml5-dialog": "insertImage"
        },
        style: "display: none;"
      }, () => {
        this.label$(() => {
          this.raw("Image:");
          return this.input$({
            data: {
              "wysihtml5-dialog-field": "src"
            },
            value: "http://"
          });
        });
        this.label$(() => {
          this.raw("Align: ");
          return this.select$({
            data: {
              "wysihtml5-dialog-field": "className"
            }
          }, () => {
            this.option$({
              value: ""
            }, "default");
            this.option$({
              value: "wysiwyg-float-left"
            }, "left");
            return this.option$({
              value: "wysiwyg-float-right"
            }, "right");
          });
        });
        this.a$({
          data: {
            "wysihtml5-dialog-action": "save"
          }
        }, "OK");
        return this.a$({
          data: {
            "wysihtml5-dialog-action": "cancel"
          }
        }, "Cancel");
      });
    });
    return this.textarea$({
      placeholder: "Enter text ..."
    });
  }

  createFormData() {
    var fd;
    fd = new FormData();
    fd.append('text[name]', this.model.get('name'));
    fd.append('text[description]', this.model.get('description'));
    fd.append('text[content]', this.model.get('content'));
    return fd;
  }

  render() {
    return super.render();
  }

};

export { EditTextView };

