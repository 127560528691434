import * as _ from 'underscore';
import { Script, ScriptProps } from 'models/script';
import { Events } from 'maps/map_events';
import { AvatarView } from 'maps/views/avatar_view';
import { Avatar } from 'models/avatar';
import { PersonaView } from 'maps/views/persona_view';
import { Scripts } from 'api/agent';
import Recorder from 'utils/recorder';

//type RecorderType = {
//  calls: [number,string,any[]]
//  detach: Function
//}

class RecordingManager {

  public dispatcher: Backbone.Events;
  public recording:any;
  public scenes: {};
  public who: PersonaView;
  public recorders: {[key:string]: Recorder};

  constructor(options) {
    this.dispatcher = options.dispatcher;
    this.initialize(options);
  }

  _className() {
    return 'RecordingManager';
  }

  initialize(settings) {
    this.recording = {};
    this.scenes = {};
  }

  generate_script(who) {
  }
  
  save_recording(name, who:PersonaView, branch_keys) {
    if (this.recording.name || !who.scenes[name]) {
      return;
    }

    const script = who.scenes[name];
    const branches:{[branch_name:string]: ScriptProps} = branch_keys.reduce((acc,key)=>({...acc, [key]: who.scenes[key]}),{});
    
    Scripts.create({
      branches,
      script,
      name,
      metadata: this.recording,
      players: who.script_avatars.concat(who).reduce((acc, av) => ({...acc, [av.model.name]: av.model.uuid}), {})
    }).then((success) => {
      console.log('Success saving script')
    },(error) => {
      console.error('Error saving script',error)
    })

  }

  start_recording(name, who:PersonaView) {
    if (this.recording.name) {
      return;
    }
    this.who = who;
    who.recording = true;
    who.scenes[name] = {};

    this.recorders = who.script_avatars.concat(who).reduce((acc, ch:any) => {
      acc[ch.name] = new Recorder(ch, ["play_video", "play_sound", "show_web_resource", "show_text","show_picture", "walk", "ask", "say", "wait", "teletransport"]);
      ch.teletransport(ch.x, ch.y);
      return acc;
    }, {});
    this.recording.name = name;
    this.recording.coords = {
      x: who.x,
      y: who.y
    };
  }

  stop_recording(who:PersonaView) {

    if (!this.recording.name) {
      return;
    }
    who.recording = false;

    const recorded = Object.keys(this.recorders).reduce((acc, key)  => ({...acc, [key]: this.recorders[key].calls}), {});

    who.scenes[this.recording.name] = recorded;

    for (let name in this.recorders)
      this.recorders[name].detach()

    this.recording.name = false;
    return recorded;
  }

};

export { RecordingManager};
