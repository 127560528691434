import { fabric } from 'fabric';
import 'gifler';

class GifObject extends fabric.Object {

  static type= 'gif'
  static superType= 'image'
  gifCanvas= null
  isStarted= false

  //@ts-ignore
  initialize(options: any) {
    options = options || {};
    super.initialize(options);
    this.gifCanvas = document.createElement('canvas');
  }

  drawFrame(ctx: CanvasRenderingContext2D, frame: any) {
    // update canvas size
    this.gifCanvas.width = frame.width;
    this.gifCanvas.height = frame.height;
    // update canvas that we are using for fabric.js
    ctx.drawImage(frame.buffer, -frame.width / 2, -frame.height / 2, frame.width, frame.height);
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render( ctx);
    if (!this.isStarted) {
      this.isStarted = true;
      window.gifler('./images/earth.gif')
        .frames(this.gifCanvas, (_c: CanvasRenderingContext2D, frame: any) => {
          this.isStarted = true;
          this.drawFrame(ctx, frame);
        });
    }
  }

  static fromObject(options: any, callback: (obj: any) => any) {
    return callback(new fabric.Gif(options));
  }

};


fabric.Gif = fabric.util.createClass(GifObject, {
  type: GifObject.type
});

fabric.Gif.fromObject = GifObject.fromObject;

declare module 'fabric' {
  namespace fabric {
    class Gif extends GifObject {
      constructor(options: GifObject)
    }
  }
}
export default fabric.Gif
