import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { NpcSearch } from './search';
import { ArtworkIndex } from 'components/artwork';
import { Npcs } from 'api/agent';
import { Npc } from 'models/npc';
import { MapController } from 'maps/controller';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const handleClickAnimated = (props, accept) => {
  const npc=new Npc({id: props.id}, {})

  npc.fetch().then(() => {
    accept(npc)
  })
}

const handleClickStatic = async (props, accept) => {
  const npc = await Npcs.from_svg(props.id);
  accept(new Npc(npc,{}))
}

type PickerProps = {
  accept:(npc:Npc)=>void,
  reject:(reason:any)=>void
};

export default function NpcPicker(props:PickerProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Animated" {...a11yProps(0)} />
          <Tab label="Static" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <NpcSearch md={6} onClick={(e) => handleClickAnimated(e, props.accept)} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ArtworkIndex onClick={(e) => handleClickStatic(e, props.accept)}/>
      </TabPanel>
    </div>
  );
}
