import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gameId: null,
  background: null,
  gameData: null,
  loading: false,
  backdrop: true,
  scene: '',
  place: '',
  title: '',
  size: null,
  currentStep: 0,
  suggested_prompts: [],
};

// Create slice
const creatixSlice = createSlice({
  name: 'creatix',
  initialState,
  reducers: {
     // Reducer to reset state to initial values
    resetState: (state) => {
      return initialState;  // Resets to initial state
    },
    setSuggestedPrompts: (state, {type, payload}) => {
      state.suggested_prompts = payload;
    }, 
    setGameId: (state, {type, payload}) => {
      state.gameId = payload;
    }, 
    setGameData: (state, {type, payload}) => {
      state.gameData = payload;
    }, 
    setLoading: (state, {type, payload}) => {
      state.loading = payload;
    },
    setBackdrop: (state, {type, payload}) => {
      state.backdrop = payload;
    },
    setScene: (state, {type, payload}) => {
      state.scene = payload;
    },
    setPlace: (state, {type, payload}) => {
      state.place = payload;
    },
    setTitle: (state, {type, payload}) => {
      state.title = payload;
    },
    setSize: (state, {type, payload}) => {
      state.size = payload;
    },
    setBackground: (state, {type, payload}) => {
      state.background = payload;
    }, 
    setCurrentStep: (state, {type, payload}) => {
      state.currentStep = payload;
    },
  }
});

export const {
  setGameData,
  setLoading,
  setBackdrop,
  setScene,
  setPlace,
  setTitle,
  setSize,
  setBackground,
  setCurrentStep,
  setGameId,
  resetState,
} = creatixSlice.actions;

export default creatixSlice.reducer;
