import { fabric } from 'fabric';

import { toObject, FabricElement } from '../utils';

export interface Code {
  html: string;
  css: string;
  js: string;
}

export interface IElementObject extends FabricElement {
  setSource: (source: Code) => void;
  setCode: (code: Code) => void;
  code: Code;
}

const initialCode: Code = {
  html: '',
  css: '',
  js: '',
};

class ElementObject extends fabric.Rect implements IElementObject {

  static type= 'element'
  static superType= 'element'

  styleEl: HTMLStyleElement
  scriptEl:HTMLScriptElement
  element:HTMLDivElement
  container:HTMLDivElement
  editable:boolean
  id:string

  hasRotatingPoint= false
  code:Code = null

  //@ts-ignore
  initialize(code = initialCode, options: any) {
    options = options || {};
    super.initialize(options);
    //@ts-ignore
    this.set({ code, fill: 'rgba(255, 255, 255, 0)', stroke: 'rgba(255, 255, 255, 0)', });
    return this;
  }

  setSource(source: any) {
    this.setCode(source);
  }

  setCode(code = initialCode) {
    //@ts-ignore
    this.set({ code });
    const { css, js, html } = code;
    this.styleEl.innerHTML = css;
    this.scriptEl.innerHTML = js;
    this.element.innerHTML = html;
  }

  toObject(propertiesToInclude: string[]) {
    return toObject(this, propertiesToInclude, {
      code: this.get('code'),
      container: this.get('container'),
      editable: this.get('editable'),
    });
  }

  _render(ctx: CanvasRenderingContext2D) {
    super._render(ctx);
    if (!this.element) {
      const { id, scaleX, scaleY, width, height, angle, editable, code } = this;
      const zoom = this.canvas.getZoom();
      const left = this.calcCoords().tl.x;
      const top = this.calcCoords().tl.y;
      const padLeft = (width * scaleX * zoom - width) / 2;
      const padTop = (height * scaleY * zoom - height) / 2;
      this.element = fabric.util.makeElement('div', {
        id: `${id}_container`,
        style: `transform: rotate(${angle}deg) scale(${scaleX * zoom}, ${scaleY * zoom});
                        width: ${width}px;
                        height: ${height}px;
                        left: ${left + padLeft}px;
                        top: ${top + padTop}px;
                        position: absolute;
                        user-select: ${editable ? 'none' : 'auto'};
                        pointer-events: ${editable ? 'none' : 'auto'};`,
      }) as HTMLDivElement;
      const { html, css, js } = code;
      this.styleEl = document.createElement('style');
      this.styleEl.id = `${id}_style`;
      this.styleEl.type = 'text/css';
      this.styleEl.innerHTML = css;
      document.head.appendChild(this.styleEl);

      this.scriptEl = document.createElement('script');
      this.scriptEl.id = `${id}_script`;
      this.scriptEl.type = 'text/javascript';
      this.scriptEl.innerHTML = js;
      document.head.appendChild(this.scriptEl);

      const container = document.getElementById(this.container as any as string);
      container.appendChild(this.element);
      this.element.innerHTML = html;
    }
  }

  static fromObject(options: {code:Code}| any, callback: (obj: ElementObject)=>any) {
    return callback && callback(new fabric.Element(options.code, options))
  }

};

fabric.Element = fabric.util.createClass(ElementObject, {
  type: ElementObject.type
});

fabric.Element.fromObject = ElementObject.fromObject;

declare module 'fabric' {
  namespace fabric {
    class Element extends ElementObject {
      constructor(code:Code, options)
    }
  }
}

export default fabric.Element
