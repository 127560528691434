import { BaseMenu } from "./base_menu";

class Menu extends BaseMenu {

  constructor(options) {
    super(options);
    this.dispatchCreated('Menu');
  }

  events() {
    return {
      "click .menuitem": "handle_click",
      "mouseenter .menuitem": "handle_mouseenter"
    };
  }

  handle_mouseenter(ev) {
    const v = $(ev.target).data("value");
    const d = $(ev.target).data("label");
    this.trigger("menu:mouseenter", v, d, this.x, this.y);
    return false;
  }

  handle_click(ev) {
    const v = $(ev.target).data("value");
    const d = $(ev.target).data("label");
    this.trigger("menu:selected", v, d, this.x, this.y);
    return false;
  }

  menu_template = () =>{
    this.document = [];
    this.ul$(".contextmenu", () => this.col.map(item =>
        this.li$(() => 
          this.a$('.menuitem', { href: "#", data: { label: this.label(item), value: this.value(item) } }, () => this.raw(this.label(item)))
        )))
    return this.document.join('');
  }

};

export { Menu};
