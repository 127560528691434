import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Box, IconButton, CircularProgress } from '@material-ui/core';
import { CloudUpload, PhotoCamera } from '@material-ui/icons';
import { DndProvider, useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import { Pictures } from 'api/agent';
import {addCallback} from 'utils/channels';

const ItemTypes = {
  IMAGE: 'image',
};

const ImageDropZone: React.FC<{ onDrop: (file: File) => void }> = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.IMAGE, NativeTypes.FILE],
    drop: (item: { files: FileList }) => {
      const file = item.files[0];
      if (file && file.type.startsWith('image/')) {
        onDrop(file);
      } else {
        alert('Please upload a valid image file.');
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        padding: 20,
        textAlign: 'center',
        backgroundColor: isOver ? '#e0e0e0' : 'white',
      }}
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-file-upload"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file && file.type.startsWith('image/')) {
            onDrop(file);
          } else {
            alert('Please upload a valid image file.');
          }
        }}
      />
      <label htmlFor="image-file-upload">
        <Button variant="contained" component="span" startIcon={<CloudUpload />}>
          Upload Image
        </Button>
      </label>
      <p>Drag 'n' drop an image file here, or click to select one</p>
    </div>
  );
};

const PictureUploadForm: React.FC<any> = ({onCreated}:any) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isRemovingBg, setIsRemovingBg] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (imageFile) {
      const objectUrl = URL.createObjectURL(imageFile);
      setImageSrc(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      setImageSrc(null);
    }
  }, [imageFile]);

  const handleFileUpload = (file: File) => {
    setImageFile(file);
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsCameraActive(true);
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const takePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'camera_picture.png', { type: 'image/png' });
            setImageFile(file);
          }
        }, 'image/png');
      }
      setIsCameraActive(false);
      if (videoRef.current.srcObject instanceof MediaStream) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    }
  };

  const handleSave = async () => {
    if (!imageFile) return;

    setUploading(true);

    try {
      const name = prompt('Name?');
      const response = await Pictures.create(name, isRemovingBg == 'removed' ? imageSrc : imageFile);
      onCreated?.(response);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file.');
    } finally {
      setUploading(false);
    }
  };

  const removeBgApi = (data_url, callback, err) => {
    fetch('/generative_ai/remove_bg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({url: data_url})
    }).then(response => response.json())
      .then(data => {
        console.log('Remove BG Success:', data);
        addCallback(data.uuid, callback, err)
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  const removeBg = async () => {
    if (!imageFile) return;

    setIsRemovingBg('removing');

    try {
      const data_url = await toBase64(imageFile);
      removeBgApi(data_url, (data) => {
        setImageSrc(data.output[0]);
      }, (error) => {
        console.error('Error removing background:', error);
      });
    } catch (error) {
      console.error('Error removing background:', error);
      alert('An error occurred while removing the background.');
    } finally {
      setIsRemovingBg('removed');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: 16 }}>
        <ImageDropZone onDrop={handleFileUpload} />

        <Box display="flex" alignItems="center" style={{ gap: 8 }}>

          {(imageFile && !isCameraActive) ? (<>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={uploading}
            startIcon={<CloudUpload />}
          >
            {uploading ? 'Saving...' : 'Save'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{setImageFile(null); startCamera()}}
          >
            {'Retake'}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={removeBg}
            disabled={isRemovingBg == 'removing'}
          >
            {isRemovingBg == 'removing' ? <CircularProgress size={24} /> : 'Remove Background'}
          </Button>

          </>) : <Button
          variant="contained"
          onClick={isCameraActive ? takePicture : startCamera}
          startIcon={<PhotoCamera />}
        >
          {isCameraActive ? 'Take Picture' : 'Start Camera'}
        </Button>
          }
        </Box>


        {(isCameraActive || imageFile == null) && (
          <video
            ref={videoRef}
            style={{ width: '100%', maxWidth: '600px' }}
            autoPlay
            playsInline
          />
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />

        {(imageFile || imageSrc) && !isCameraActive && (
          <Box>
            <Typography variant="subtitle1">{imageFile?.name}</Typography>
            <img
              src={imageSrc || ''}
              alt="Uploaded or captured image"
              style={{ width: '100%', maxWidth: '600px' }}
            />
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default PictureUploadForm;
